// ============================
// CONSTANTS
// Step labels in the Refill / Topup flow
// ============================

const PARTICULARS_STEP = "Particulars";
const PRESCRIPTION_STEP = "Prescription";
const QUANTITY_STEP = "Quantity";
const COLLECTION_STEP = "Collection";
const PAYMENT_STEP = "Payment";

export const REFILL_STEP_LABELS = [
  PARTICULARS_STEP,
  QUANTITY_STEP,
  COLLECTION_STEP,
  PAYMENT_STEP,
];

export const REFILL_REORDER_STEP_LABELS = [
  QUANTITY_STEP,
  COLLECTION_STEP,
  PAYMENT_STEP,
];

export const TOPUP_STEP_LABELS = [
  PARTICULARS_STEP,
  PRESCRIPTION_STEP,
  COLLECTION_STEP,
  PAYMENT_STEP,
];

export const TOPUP_REORDER_STEP_LABELS = [
  PRESCRIPTION_STEP,
  COLLECTION_STEP,
  PAYMENT_STEP,
];

export const VIRTUAL_CONSULT_LABELS = [
  PARTICULARS_STEP,
  COLLECTION_STEP,
  PAYMENT_STEP,
];
