import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.text.primary,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightBold,
    },
    institutionTitle: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    institutionDetail: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
    },
    subtitle: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_semibold,
      paddingBottom: theme.spacing(1.5),
    },
    label: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    detail: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      paddingBottom: theme.spacing(1.5),
    },
    lowerDetail: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
    },
  } satisfies SxObjType;
};

export { sxStyles };
