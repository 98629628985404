import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    paymentPage: {
      zIndex: 100,
    },

    profileHeader: {
      backgroundColor: theme.palette.secondary.main,
      width: "100%",
      display: "flex",
      minHeight: theme.spacing(6),
      "& .MuiCardHeader-root": {
        width: "100%",
      },
    alignItems: "center"
    },

    filterTransactionsBox: {
      padding: theme.spacing(1, 1.5, 0.5),
    },
    cardHeader: {
      "& .MuiCardHeader-root": {
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        width: "80%",
      },
      "& .MuiCardHeader-title": {
        color: theme.palette.text.primary,
        typography: theme.typography.body1_semibold,
        // padding: theme.spacing(1.5),
        wordBreak: "break-word",
        display: "flex",
        minHeight: theme.spacing(6),
        alignItems: "center",
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
