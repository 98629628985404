import { connect } from "react-redux";
import { RootDispatch } from "lib/redux/root/redux.types";
import { setSelectedPastReportIndex } from "ui/mrrp/ducks";
import PastReportCard from "./PastReportCard";

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    onClickPastReport: (selectedIndex: number) => {
      dispatch(setSelectedPastReportIndex(selectedIndex));
    },
  };
};

export default connect(null, mapDispatchToProps)(PastReportCard);
