import { RootState } from "lib/redux/root/redux.types";
import { AUTH_TYPE } from "lib/redux/system/system.types";

const EMPTY_VALUE = "";
const DEFAULT_DEVICE_PLATFORM = "WEB";
const DEFAULT_AUTH_TYPE = "SECURED";

const getDeviceInfo = (state: RootState): string => {
  if (state.system && state.system.deviceInfo) {
    return state.system.deviceInfo;
  } else {
    return EMPTY_VALUE;
  }
};

const getDeviceId = (state: RootState): string => {
  if (state.system && state.system.deviceId) {
    return state.system.deviceId;
  } else {
    return EMPTY_VALUE;
  }
};

const getDevicePublicIp = (state: RootState): string => {
  if (state.system && state.system.devicePublicIp) {
    return state.system.devicePublicIp;
  } else {
    return EMPTY_VALUE;
  }
};

const getDevicePlatform = (state: RootState): string => {
  if (state.system && state.system.devicePlatform) {
    return state.system.devicePlatform;
  } else {
    // default in the event that the value can't be retrieved from redux store
    return DEFAULT_DEVICE_PLATFORM;
  }
};

const getAuthType = (state: RootState): AUTH_TYPE => {
  if (state.system && state.system.authType) {
    return state.system.authType;
  } else {
    // default in the event that the value can't be retrieved from redux store
    return DEFAULT_AUTH_TYPE;
  }
};

export {
  DEFAULT_DEVICE_PLATFORM,
  DEFAULT_AUTH_TYPE,
  getDeviceInfo,
  getDeviceId,
  getDevicePublicIp,
  getDevicePlatform,
  getAuthType,
};
