import { Box, Typography } from "@mui/material";
import IMAGES from "lib/assets/images";
import { sxStyles } from "./PaymentDD.styles";
import { useLocation } from "react-router-dom";
import toTitleCase from "lib/util/StringUtil/toTitleCase/toTitleCase";

const PaymentDD = () => {
  const classes = sxStyles();

  const queryStrings = new URLSearchParams(useLocation().search);

  const isPaymentSuccessful =
    queryStrings.get("statusCode") === "00000" ? true : false;

  const paymentStatusString = `Payment ${
    isPaymentSuccessful ? "Successful" : "Unsuccessful"
  }`;

  return (
    <Box sx={classes.mainContainer}>
      <Box sx={classes.statusContainer}>
        <Box
          src={
            isPaymentSuccessful
              ? IMAGES.general.Successful
              : IMAGES.general.Unsuccessful
          }
          alt={paymentStatusString}
          sx={classes.icon}
          component="img"
        />
        <Typography sx={classes.title}>
          {toTitleCase(paymentStatusString)}
        </Typography>
      </Box>
      <Box sx={classes.noticeContainer}></Box>
    </Box>
  );
};

export default PaymentDD;
