import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    cardDetailWrapper: {
      padding: 0,
      "&:last-child": {
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    cardDetails: {
      minHeight: "100%",
      wordBreak: "break-word",
      padding: theme.spacing(0.5, 1.5),
      display: "flex",
    },
    cardTopLeft: {
      width: "100%",
    },
    cardTopRight: {
      width: 75,
      textAlign: "right",
    },
    cardLeft: {
      width: "100%",
    },
    cardRight: {
      textAlign: "right",
      color: theme.palette.common.black,
      typography: theme.typography.body2_semibold,
      minWidth: 150,
    },
    cardTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.body1_semibold,
      padding: theme.spacing(0.5, 0),
    },
    cardLabel: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    cardValue: {
      color: theme.palette.common.black,
      typography: theme.typography.body2_semibold,
    },
  } satisfies SxObjType;
};

export { sxStyles };
