import * as CompressionModule from "browser-image-compression";
import {
  onDispatchFileUploadErrorCallback,
  onErrorCallback,
  onFileUploadCallback,
} from "./ImageUploader.types";
import {
  getIsLoadingUploadState,
  getSuccessfulUploadState,
  getFileTooBigErrorState,
  getUnexpectedErrorState,
  getInvalidFileFormatErrorState,
  getCorruptedFileFormatErrorState,
} from "./processUploadedFile.states";

// =======================
// FILE UPLOAD HANDLER
// =======================

// threshold used in HealthHub, which we should follow (7 MB)
const HEALTHHUB_MAX_IMAGE_SIZE_MB = 7;
const HEALTHHUB_MAX_IMAGE_SIZE_BYTES =
  HEALTHHUB_MAX_IMAGE_SIZE_MB * 1000 * 1000;

// threshold used in HealthHub, which we should follow (0.3 MB or 300 KB)
const HEALTHHUB_COMPRESS_IMAGE_SIZE_THRESHOLD_MB = 0.3;
const HEALTHHUB_COMPRESS_IMAGE_SIZE_THRESHOLD_BYTES =
  HEALTHHUB_COMPRESS_IMAGE_SIZE_THRESHOLD_MB * 1000 * 1000;

/**
 * Processes the file uploaded into the <input /> field, handles success & error states.
 * The file name, byte data and any obtainable metadata will be set in the file upload
 * callback handler passed as the argument of this function.
 *
 * @param {File} file  The uploaded file object
 * @param {onFileUploadCallback} onFileUpload
 *   Event handler invoked with the appropriate file upload state during different stages
 *   of file upload.
 *
 *  Please see the interface of this callback function, for the full details of what
 *   is passed in the callback params.
 */

const processUploadedFile = async (
  file: File,
  onFileUpload: onFileUploadCallback,
  onError: onErrorCallback,
  onDispatchFileUploadError: onDispatchFileUploadErrorCallback,
) => {
  // Checks if file name has image extension
  if (file.name.match(/.(jpg|jpeg|png|gif|bmp|heic)$/i)) {
    const reader = new FileReader();

    reader.readAsText(file);
    reader.onloadend = async (e) => {
      // !exp checks if an acceptable image file contains svg
      if (e.target?.result?.toString().includes("svg")) {
        onFileUpload(getCorruptedFileFormatErrorState());
        onDispatchFileUploadError(true);
        onError();
      } else {
        try {
          // --- User's uploaded file object
          if (file.size <= HEALTHHUB_MAX_IMAGE_SIZE_BYTES) {
            // --- Start compression, if file is still within size threshold,
            //     until its size is below threshold
            onFileUpload(getIsLoadingUploadState());
            const compressedImageFile = await compressImage(file);

            if (
              compressedImageFile.size <=
              HEALTHHUB_COMPRESS_IMAGE_SIZE_THRESHOLD_BYTES
            ) {
              // --- Start read byte data from compressed image file
              // const reader = new FileReader();
              reader.readAsDataURL(compressedImageFile);

              // --- Has completed reading byte data from compressed image file
              reader.onloadend = readFileOnLoadEnd(file, onFileUpload);
              onDispatchFileUploadError(false);
            } else {
              onFileUpload(
                getFileTooBigErrorState(HEALTHHUB_MAX_IMAGE_SIZE_MB),
              );
              onDispatchFileUploadError(true);
            }
          } else {
            onFileUpload(getFileTooBigErrorState(HEALTHHUB_MAX_IMAGE_SIZE_MB));
            onError();
            onDispatchFileUploadError(true);
          }
        } catch (error) {
          onFileUpload(getUnexpectedErrorState());
          onError();
          onDispatchFileUploadError(true);
        }
      }
    };
  } else {
    onFileUpload(getInvalidFileFormatErrorState());
    onError();
  }
};

// =======================
// FILE COMPRESSION HANDLER
// =======================

/**
 * Compresses the specified image file down to a size that's below the threshold.
 * Under the hood, this library compresses the image for a max of only 10 iterations (by default).
 *
 * @param {File} file  Image file uploaded
 * @returns {Promise<File | Blob>}  A promise that resolves to the compressed image file
 */
const compressImage = async (file: File) => {
  return CompressionModule.default(file, {
    // compresses the image until its size is below this threshold
    // !exp we set the maxSizeMB to be less 0.01 so that the compression module will not compress file above the limit even when rounded. e.g. Limit is 0.2mb, compression target is 0.19mb. possible compressed file sizes: 0.1949mb, 0.1899mb
    // !exp if we set exactly to limit it will possibly throw error when rounding. e.g. limit and compression target is 0.2mb. possible compressed file sizes: 0.1998mb, 0.2019mb(throws error)
    maxSizeMB: HEALTHHUB_COMPRESS_IMAGE_SIZE_THRESHOLD_MB - 0.01,

    maxIteration: 100,

    // use main thread so there's no reliance on web worker
    useWebWorker: false,
  });
};

// =======================
// FILE READING HANDLER
// =======================

/**
 * Reads the file from the specified fileReadEvent, which is an event object that's expected
 * to be provided from FileReader's "onloadend" event.
 *
 * The FileReader only invokes the "onloadend" event when it has completed reading file data.
 *
 * This is an event handler that listens for the "onloadend" event. Hence, it is executed
 * in its own context and requires its own try-catch for exception management.
 *
 * @param {File} file  The uploaded file object
 * @param {onFileUploadCallback} onFileUpload
 *   Event handler invoked with the appropriate file upload state during different stages of file upload.
 *
 * @returns {(fileReadEvent: ProgressEvent<FileReader>) => void}
 *   A callback function for the FileReader "onloadend" event listener that sets the appropriate file upload state.
 */
const readFileOnLoadEnd =
  (file: File, onFileUpload: onFileUploadCallback) =>
  (fileReadEvent: ProgressEvent<FileReader>) => {
    const fileData = fileReadEvent?.target?.result;
    if (fileData) {
      const fileName = file.name;
      const displayableImageFileData = fileData.toString(); // base64 data

      onFileUpload(
        getSuccessfulUploadState(fileName, displayableImageFileData),
      );
    } else {
      onFileUpload(getUnexpectedErrorState());
    }
  };

export { processUploadedFile, readFileOnLoadEnd };
