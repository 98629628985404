import { Box, Skeleton } from "@mui/material";

const PMEntryPageSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Skeleton
        animation="wave"
        variant="text"
        width={150}
        height={40}
        sx={{ margin: "0 auto", marginTop: "64px" }}
      />

      <Skeleton
        animation="wave"
        variant="rectangular"
        width={200}
        height={200}
        sx={{ margin: "0 auto", marginTop: "16px" }}
      />
      <Skeleton
        animation="wave"
        variant="text"
        width={"85%"}
        sx={{ margin: "0 auto", marginTop: "16px" }}
      />
    </Box>
  );
};

export default PMEntryPageSkeleton;
