import axios from "axios";
import { GET_PAYMENT_INFO_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatGetPaymentInfoErrorMessage } from "api/resources/messages/ErrorMessage";
import {
  GetPaymentInfoRequestPayload,
  GetPaymentInfoResponsePayload,
} from "./getPaymentInfo.fromApi.types";

/**
 * Try adding a manual bill and get its information
 *
 * POST
 * Endpoint: secure/payment/GetPaymentInfo
 *
 * @param {GetPaymentInfoRequestPayload} request
 */
const getPaymentInfo = async (
  request: GetPaymentInfoRequestPayload,
): Promise<GetPaymentInfoResponsePayload> => {
  try {
    const response = await axios.post<GetPaymentInfoResponsePayload>(
      GET_PAYMENT_INFO_URL(),
      request,
      getRequestOptions(),
    );
    return parseResponse(response, GET_PAYMENT_INFO_URL());
  } catch (error) {
    const msg = formatGetPaymentInfoErrorMessage(GET_PAYMENT_INFO_URL());
    console.error(msg);
    throw error;
  }
};

export { getPaymentInfo };
