import { Box, Typography, useTheme } from "@mui/material";
import { format } from "date-fns";
import AddressFields from "lib/components/formFields/AddressFields/AddressFields";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { useEffect, useMemo, useState } from "react";
import {
  selectEpicProfileState,
  selectPMSettingsValue,
} from "../PatientMaster/ducks/selectors";
import { setNRICError } from "../PatientMaster/ducks/pmSlice";
import { sxStyles } from "./PersonalDetails.styles";
import { PersonalDetailsProps } from "./PersonalDetails.types";

const nricErrorMessage =
  "Mismatched NRIC. Kindly approach the registration counter at any NUHS institution with your identification documents to update your particulars.";

const PersonalDetails = ({
  showUnsyncParticularsWarning = false,
}: PersonalDetailsProps) => {
  const theme = useTheme();
  const classes = sxStyles(theme);
  const dispatch = useAppDispatch();

  // Redux states
  const { disclaimers } = useAppSelector(selectPMSettingsValue);
  const pmState = useAppSelector(selectEpicProfileState);

  // Local states
  const [isMeansTestingClicked, setIsMeansTestingClicked] = useState(false);
  const [isNricLinkClicked, setIsNricLinkClicked] = useState(false);

  const dateOfBirthObj = getDateFromISOString(pmState.DateOfBirth);
  const formattedDateOfBirth =
    dateOfBirthObj && format(dateOfBirthObj, "d MMM yyyy");

  const passExpiryDateObj = getDateFromISOString(pmState.PassExpiryDate || "");
  const formattedPassExpiryDate =
    passExpiryDateObj && format(passExpiryDateObj, "d MMM yyyy");

  const handleMeansTestingLinkClick = () => {
    setIsMeansTestingClicked(!isMeansTestingClicked);
  };

  const handleNricLinkClick = () => {
    setIsNricLinkClicked(!isNricLinkClicked);
  };

  const isNRICInvalid = useMemo(() => {
    return (
      pmState.Nric !== "" &&
      pmState.Nric !== null &&
      !/[A-Z]/.test(pmState.Nric.slice(-1))
    );
  }, [pmState.Nric]);

  useEffect(() => {
    if (isNRICInvalid) {
      dispatch(setNRICError(true));
    }
  }, [dispatch, isNRICInvalid]);

  return (
    <Box sx={classes.uneditableFieldsContainer}>
      <Typography sx={classes.introText}>
        {disclaimers.personalDetails.description}
      </Typography>

      {showUnsyncParticularsWarning && (
        <SharpNoticePanel bgColor="extremeWarn">
          {disclaimers.unsyncParticulars.description}
        </SharpNoticePanel>
      )}

      <Box sx={classes.fieldsContainer}>
        <Box>
          <Typography sx={classes.formLabel}>NRIC / FIN</Typography>
          <Typography sx={classes.formValue}>
            {pmState.Nric !== "" && pmState.Nric ? pmState.Nric : "-"}
          </Typography>

          {isNRICInvalid && (
            <Typography sx={classes.nricErrorMessage}>
              {nricErrorMessage}
            </Typography>
          )}

          <Typography sx={classes.nricLink} onClick={handleNricLinkClick}>
            Did you change your NRIC / FIN?
          </Typography>
        </Box>

        <Box sx={classes.formContainer}>
          <Typography sx={classes.formLabel}>Name</Typography>
          <Typography sx={classes.formValue}>
            {pmState.Name
              ? pmState.Name.toLocaleUpperCase()
                  .replace(pmState.Title?.toLocaleUpperCase() ?? "", "")
                  .trim()
              : "-"}
          </Typography>
        </Box>

        <Box sx={classes.formContainer}>
          <Typography sx={classes.formLabel}>Sex</Typography>
          <Typography sx={classes.formValue}>
            {pmState.Sex !== "" && pmState.Sex ? pmState.Sex : "-"}
          </Typography>
        </Box>

        <Box sx={classes.formContainer}>
          <Typography sx={classes.formLabel}>Race</Typography>
          <Typography sx={classes.formValue}>
            {pmState.Race !== "" && pmState.Race ? pmState.Race : "-"}
          </Typography>
        </Box>

        <Box sx={classes.formContainer}>
          <Typography sx={classes.formLabel}>Date of Birth</Typography>
          <Typography sx={classes.formValue}>
            {formattedDateOfBirth ?? "-"}
          </Typography>
        </Box>

        <Box sx={classes.formContainer}>
          <Typography sx={classes.formLabel}>Nationality</Typography>
          <Typography sx={classes.formValue}>
            {pmState.Nationality !== "" && pmState.Nationality
              ? pmState.Nationality
              : "-"}
          </Typography>
        </Box>

        {pmState.Nationality === "SINGAPORE CITIZEN" ||
        pmState.ResidentialStatus === "PR" ||
        pmState.ResidentialStatus === "Citizen" ? (
          <Box sx={classes.formContainer}>
            <Typography sx={classes.formLabel}>Residential Status</Typography>
            <Typography sx={classes.formValue}>
              {pmState.ResidentialStatus !== "" && pmState.ResidentialStatus
                ? pmState.ResidentialStatus
                : "-"}
            </Typography>
          </Box>
        ) : (
          <>
            <Box sx={classes.formContainer}>
              <Typography sx={classes.formLabel}>Pass Type</Typography>
              <Typography sx={classes.formValue}>
                {pmState.PassType !== "" && pmState.PassType
                  ? pmState.PassType
                  : "-"}
              </Typography>
            </Box>
            <Box sx={classes.formContainer}>
              <Typography sx={classes.formLabel}>Pass Expiry Date</Typography>
              <Typography sx={classes.formValue}>
                {formattedPassExpiryDate ?? "-"}
              </Typography>
            </Box>
          </>
        )}
      </Box>

      <Box sx={{ px: 1.25 }}>
        <Box mt={1}>
          <Typography sx={classes.subsubtitle}>Registered Address</Typography>
          <Box mt={0.5}>
            <Typography sx={classes.caption}>
              This address is used for{" "}
              <Typography
                component="span"
                sx={classes.link}
                onClick={handleMeansTestingLinkClick}
              >
                Means Testing
              </Typography>{" "}
              purposes.
            </Typography>
          </Box>

          <Box mt={-1} mb={2}>
            <AddressFields
              postalCode={
                pmState.RegisteredPostalCode !== "" &&
                pmState.RegisteredPostalCode
                  ? pmState.RegisteredPostalCode
                  : null
              }
              block={
                pmState.RegisteredBlockNumber !== "" &&
                pmState.RegisteredBlockNumber
                  ? pmState.RegisteredBlockNumber
                  : null
              }
              unitNo={
                pmState.RegisteredUnitNumber !== "" &&
                pmState.RegisteredUnitNumber
                  ? pmState.RegisteredUnitNumber
                  : null
              }
              street={
                pmState.RegisteredStreetName !== "" &&
                pmState.RegisteredStreetName
                  ? pmState.RegisteredStreetName
                  : null
              }
              disabled={true}
              errorPostalCode={false}
              onChangePostalCode={() => {}}
              onChangeBlock={() => {}}
              onChangeUnitNo={() => {}}
              onChangeStreet={() => {}}
              showRequiredText={false}
            />
          </Box>
        </Box>
      </Box>

      <ConfirmationModal
        title={disclaimers.meansTesting.title}
        open={isMeansTestingClicked}
        onClose={() => {}}
        body={MapRawStringToReactElement(
          disclaimers.meansTesting.description ?? "",
        )}
        hideCancelButton={true}
        nextButtonText="OK"
        onClickNext={handleMeansTestingLinkClick}
      />

      <ConfirmationModal
        title={disclaimers.imptToNote.title}
        open={isNricLinkClicked}
        onClose={() => {}}
        body={MapRawStringToReactElement(
          disclaimers.imptToNote.description ?? "",
        )}
        hideCancelButton={true}
        nextButtonText="OK"
        onClickNext={handleNricLinkClick}
      />
    </Box>
  );
};

export default PersonalDetails;
