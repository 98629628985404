import { useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Link,
} from "@mui/material";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { AvailableSlotsListProps } from "./AvailableBundledSlotsList.types";
import { sxStyles } from "./AvailableBundledSlotsList.styles";
import NoSlotsMessage from "../NoSlotsMessage/NoSlotsMessage";
import SeeMoreButton from "lib/components/buttons/SeeMoreButton/SeeMoreButton";
import { AvailableBundledSlot } from "api/appointment/GetAvailableSlots/getAvailableSlots.toUi.types";
import { renderErrorMessage } from "lib/components/error/ErrorDisplay/renderErrorMessage";
import { Link as RouterLink } from "react-router-dom";
import { ACTIONS, PATHS } from "lib/routing";
import { Appointment } from "api/appointment/GetAppointmentList/getAppointmentList.toUi.types";
import { useAppSelector } from "lib/redux/hooks";
import {
  selectAppointmentMeta,
  selectRescheduleLinkedAppointments,
} from "ui/appointment/ducks/selectors";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import IMAGES from "lib/assets/images";

// ==========================
// CONSTANTS
// ==========================

// No. of available slots to show when the page first loads.
const PREVIEW_COUNT = 5;

const DEFAULT_NOSLOT_MESSAGE =
  "Sorry, there are no other available appointment slots.";
// ==========================
// COMPONENT
// ==========================

const AvailableBundledSlotsList = ({
  appointment,
  slots,
  isPreview,
  isDateChanged,
  hasExceedRange,
  hasMoreSlots,
  noSlotMessage,
  currentFlowAction,
  centerContactNo,
  showCenterContactNo,
  isNewAppointment,
  isLiveChatEnabled,
  onSelect,
  onNextPage,
  onShowAll,
}: AvailableSlotsListProps) => {
  const classes = sxStyles();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const isHsgApptNew = useAppSelector(selectAppointmentMeta).isHsgAppt;
  const isHsgApptReschedule = useAppSelector(selectRescheduleLinkedAppointments)
    .selectedLinkedAppointment?.isHsgAppt;

  const onClickLiveChatButton = () => {
    mobileNavigate(
      ACTIONS.LIVECHAT_PAGE_INTERCEPTION(PATHS.APPOINTMENT_MOBILE.path),
    );
  };
  const linkedAppointments = flattenLinkedAppointment(appointment);

  const slotsToShow = filterAvailableSlots(slots, isPreview);

  if (slotsToShow.length === 0) {
    return (
      <>
        <NoSlotsMessage>
          {/* use renderErrorMessage to parse noSlotMessage consider there is usecase when submit request form link is contained in the noSlotMessage */}
          {noSlotMessage
            ? renderErrorMessage(
                noSlotMessage,
                currentFlowAction || "APPOINTMENT_CREATE", // this component can be accessed by redirecting from mobile client create appointment flow hence setting of 'APPOINTMENT_CREATE' if it is falsy
                isLiveChatEnabled || false,
              )
            : DEFAULT_NOSLOT_MESSAGE}
        </NoSlotsMessage>

        {centerContactNo && showCenterContactNo ? (
          <Typography sx={classes.text} variant="body1_regular">
            Alternatively, please call our Contact Centre at{" "}
            {!isLiveChatEnabled ? (
              <>
                <Link href={`tel:${centerContactNo}`} sx={classes.link}>
                  {centerContactNo}
                </Link>{" "}
                or{" "}
                <Link
                  component={RouterLink}
                  sx={classes.link}
                  to={PATHS.APPOINTMENT_RESCHEDULE_FORM.path}
                >
                  submit a request online
                </Link>
              </>
            ) : (
              <>
                <Link href={`tel:${centerContactNo}`}>{centerContactNo}</Link>{" "}
                or{" "}
                <Link
                  component={"span"}
                  onClick={() => {
                    onClickLiveChatButton();
                  }}
                >
                  submit a request online
                </Link>
              </>
            )}
            .
          </Typography>
        ) : null}
      </>
    );
  } else {
    return (
      <Box width="100%">
        <FormControl component="fieldset" sx={classes.layout}>
          <RadioGroup
            aria-label="available-slots"
            name="available-slots"
            value={isDateChanged ? null : selectedIndex}
            onChange={(event) => {
              // index of the currently selected slot
              const indexOfSelected = parseInt(event.target.value);

              // set the state so the appropriate radio will be selected
              setSelectedIndex(indexOfSelected);

              // provide details of selected slot to the onSelect callback
              // Uses first slot as the selected slot
              const selectedBundledSlots = slotsToShow[indexOfSelected];

              onSelect(selectedBundledSlots);
            }}
          >
            {slotsToShow?.map((bundledSlot, index) => {
              return (
                <FormControlLabel
                  sx={classes.listItem}
                  key={index}
                  value={index}
                  label={formatBundledSlots(
                    linkedAppointments,
                    bundledSlot,
                    classes,
                    isNewAppointment ? isHsgApptNew : isHsgApptReschedule,
                  )}
                  control={
                    <Radio
                      icon={
                        <Box
                          component={"img"}
                          src={IMAGES.general.RadioButtonUncheckedIcon}
                          sx={classes.radio}
                        />
                      }
                      checkedIcon={
                        <Box
                          component={"img"}
                          src={IMAGES.general.RadioButtonCheckedIcon}
                          sx={classes.radio}
                        />
                      }
                      sx={classes.radio}
                      color="primary"
                    />
                  }
                />
              );
            })}
          </RadioGroup>
        </FormControl>

        {/* Show More Slots Button */}
        {shouldRenderSeeMoreButton(
          slots?.length,
          isPreview,
          hasExceedRange,
          hasMoreSlots,
        ) ? (
          <SeeMoreButton
            buttonText="Show more time slots"
            onClick={() => {
              if (!isPreview) {
                // if all the slots are already shown, get next page of slots
                onNextPage();
              }
              // user has chosen to show all slots from now onwards
              onShowAll();
            }}
          />
        ) : null}
      </Box>
    );
  }
};

// ==========================
// HELPER METHODS
// ==========================

/**
 * Determines if the Show More button should be displayed or not.
 *
 * @param {number} slotCount
 *   Number of slots from API.
 * @param {boolean} isPreview
 *   Whether we are previewing only the first few slots.
 * @param {boolean} hasExceedRange
 *   Whether the slots alr exceed the min~max date range at reschedule.
 * @param {boolean | null} hasMoreSlotsFromApi
 *   Whether there are still slots to be retrieved from the remote API.
 *
 * @returns {boolean}  True if should display, false otherwise.
 */
const shouldRenderSeeMoreButton = (
  slotCount: number,
  isPreview: boolean,
  hasExceedRange: boolean,
  hasMoreSlotsFromApi: boolean | null,
) => {
  // ignoring a corner case: current API call are all slots in range, has more data, but the first slots
  // onwards are all out of range slots, ideal behaviour is hidding show more button, but now it shows
  if (isPreview && slotCount > PREVIEW_COUNT) {
    // have more than PREVIEW_COUNT slots at first load , "show more slots" will use state data
    return true;
  } else if (!hasExceedRange && hasMoreSlotsFromApi) {
    // able to fetch more useful (in-range) slots from API, "show more slots" will trigger API call
    return true;
  } else {
    // hide if no need to trigger to load more slots
    return false;
  }
};

/**
 * Only returns a list of available slots if all slots should be shown.
 * If some should not be shown, then this method returns a filtered list with
 * a reduced number of slots.
 *
 * @param {AvailableSlot[]} slots  List of available slots
 * @param {boolean} isPreview  True if previewing first few slots, False otherwise (viewing all)
 *
 * @returns {AvailableSlot[]}  Filtered list of available slots
 */
const filterAvailableSlots = (
  slots: AvailableBundledSlot[][],
  isPreview: boolean,
) => {
  if (slots) {
    if (!isPreview) {
      return slots;
    } else {
      return slots.filter((slot, i) => i < PREVIEW_COUNT);
    }
  } else {
    return [];
  }
};

const formatBundledSlots = (
  linkedAppointments: Appointment[],
  bundledSlots: AvailableBundledSlot[],
  classes: any,
  isHsgAppt?: boolean,
) => {
  return bundledSlots.map((slot, index) => {
    return (
      <Box key={index}>
        <Typography sx={classes.listItemDate}>
          {formatDate(slot.date)}
        </Typography>
        <Typography sx={classes.listItemText}>
          {isHsgAppt
            ? bundledSlots[index].resource
            : linkedAppointments[index]
              ? linkedAppointments[index].serviceDisplayName
              : null}
        </Typography>
      </Box>
    );
  });
};

/**
 * Flattens an appointment itself and all of its linked child appointments such that
 * they are strung together into a single list.
 *
 * @param {Appointment | null}  appointment  Linked appointment object
 *
 * @returns {BaseAppointment[]} List of the appointment itself and all of its linked child appointments
 */
const flattenLinkedAppointment = (
  appointment: Appointment | null,
): Appointment[] => {
  if (appointment) {
    const parent: Appointment = appointment;
    let children: Appointment[] = [];

    if (appointment.linkedAppointments) {
      children = appointment.linkedAppointments.map((x) => x);
    }

    return [parent, ...children];
  } else {
    return [];
  }
};

export default AvailableBundledSlotsList;
