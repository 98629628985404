import { Box } from "@mui/material";
import { PageProps } from "./Page.types";

const Page = ({ children }: PageProps) => {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      {children}
    </Box>
  );
};

export default Page;
