import { Box, CircularProgress, Typography } from "@mui/material";
import { getSystemSettings } from "api/shared/GetSystemSettings/getSystemSettings";
import IMAGES from "lib/assets/images";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import VerticalSpreadLayout from "lib/components/layout/VerticalSpreadLayout/VerticalSpreadLayout";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import { PATHS } from "lib/routing";
import {
  navigateToExternalInAppWebView,
  navigateToExternalInMobileBrowser,
} from "lib/routing/navigate/navigate";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { formatPayment } from "lib/util/StringUtil/formatPayment/formatPayment";
import { validateEmailForPayment } from "lib/util/ValidatorUtil/emailValidator/emailValidator";
import { validateName } from "lib/util/ValidatorUtil/nameValidator/nameValidator";
import { validatePhoneNumber } from "lib/util/ValidatorUtil/phoneNumberValidator/phoneNumberValidator";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { initialState } from "ui/payment/ducks";
import StepPageLayout from "../common/StepPageLayout/StepPageLayout";
import { sxStyles } from "./PayorInfo.styles";
import { ContactsValidationStruct, PayorInfoProps } from "./PayorInfo.types";
import { useAppDispatch } from "lib/redux/hooks";
import {
  setMessageToSend,
  setshouldShowBackOnEntryHijack,
} from "lib/redux/navigation/navigationSlice";
import { parseJson } from "lib/util/StringUtil/jsonParser/parseJson";
import AccordionBlock from "lib/components/accordion/AccordionBlock";
import { AxiosError } from "axios";
import { useGetMessageAction } from "lib/routing/messageChannel/hooks/useGetMessageAction";
import { MessageActions } from "lib/routing/messageChannel/messageActions";

const AMEX = "AMEX";
const CREDIT = "CC";
const DEBIT = "DD";

const PayorInfo = ({
  submitPaymentRequestApiStatus,
  amountToPay,
  payorName,
  contact,
  email,
  submitPaymentResponse,
  memberIdentifier,
  onResetDashboardShouldRefresh,
  onResetPaymentApiStatus,
  onSetPaymentPersist,
  onSubmitPaymentRequest,
  onExitCurrentFlow,
  preventDirectBackNavigationState,
  resetPreventDirectBackNavigationState,

  billsToPay,
  allSystemBills,
  allAddedBills,
  isLoadingAvailablePaymentModes,
  hasErroredAvailablePaymentModes,
  errorMessageAvailablePaymentModes,
  IsCcAvailable,
  IsDdAvailable,
  IsAmexAvailable,
  CcDisclaimer,
  DdDisclaimer,
  AmexDisclaimer,
  callAvailablePaymentModes,
}: PayorInfoProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();
  const dispatch = useAppDispatch();

  // Redux States
  const paymentToken = submitPaymentResponse.PaymentToken;
  const merchantRefNumber = submitPaymentResponse.MerchantRefNum;
  const redirectionLink = submitPaymentResponse.RedirectionUrl;

  // Local States
  const [nameOfPayor, setNameOfPayor] = useState<string | null>(payorName);
  const [contactNumber, setContactNumber] = useState<string | null>(contact);
  const [emailAddress, setEmailAddress] = useState<string | null>(email);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState<string | null>(
    null,
  );
  const [isLoadingSystemSetting, setIsLoadingSystemSetting] = useState(false);
  const [isErroredSystemSetting, setIsErroredSystemSetting] = useState(false);
  const [isErroredSystemSettingMessage, setIsErroredSystemSettingMessage] =
    useState("");
  const [cardTokenizationObj, setCardTokenizationObj] = useState<{
    CC: boolean;
    AMEX: boolean;
    DD: boolean;
  }>({ CC: false, AMEX: false, DD: false });
  const [validation, setValidation] = useState<ContactsValidationStruct>({
    payorName: payorName ? validateName(payorName) : true,
    contact: contact ? validatePhoneNumber(contact) : true,
    email: email ? validateEmailForPayment(email) : true,
  });
  // Cancel Request Warning Dialog open & close state and handlers
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  // flag to show transaction tp gateway page
  const [showTransitionToGateway, setShowTransitionToGateway] = useState(false);

  // Handlers
  const handlePayorNameChange = (nameOfPayor: string) => {
    setNameOfPayor(nameOfPayor);
    setValidation({
      ...validation,
      payorName: validateName(nameOfPayor),
    });
  };

  const handleContactNumberChange = (contactNumber: string) => {
    setContactNumber(contactNumber);
    setValidation({
      ...validation,
      contact: validatePhoneNumber(contactNumber),
    });
  };

  const handleEmailChange = (email: string) => {
    setEmailAddress(email);
    setValidation({
      ...validation,
      email: validateEmailForPayment(email),
    });
  };

  const handleOpenWarningModal = () => setOpen(true);
  const handleCloseWarningModal = () => setOpen(false);

  //this method is to redirect to my info to get auth and state
  const fetchSystemSettings = async () => {
    try {
      setIsLoadingSystemSetting(true);
      const systemSettingList = await getSystemSettings({
        category: "OneNUHS",
        subcategory: "Payment",
        codeName: "UseCardTokenisation",
        institutionCode: null,
      });

      const rawValue = systemSettingList.Settings[0].Value;

      const parsedValue: { CC: boolean; AMEX: boolean } = parseJson(rawValue);
      setCardTokenizationObj({
        CC: parsedValue.CC ?? false,
        AMEX: parsedValue.AMEX ?? false,
        DD: false, //DD has no tokenization so it defaults to false
      });
    } catch (error) {
      setIsErroredSystemSetting(true);

      if (error instanceof AxiosError) {
        setIsErroredSystemSettingMessage(error.response?.data.Message);
      }
    } finally {
      setIsLoadingSystemSetting(false);
    }
  };

  const { messageActionObj, consumeMessageActionObj } = useGetMessageAction();

  //open cancellation modal when back navigation
  useEffect(() => {
    if (
      messageActionObj?.action === "backTriggered" ||
      preventDirectBackNavigationState
    ) {
      handleOpenWarningModal();
      resetPreventDirectBackNavigationState();
      consumeMessageActionObj();
    }
    // we only want this hook to fire on back navigation
    // eslint-disable-next-line
  }, [preventDirectBackNavigationState, messageActionObj?.action]);

  // Using hooks to update contact and email after Session storage rehydrate.
  // To avoid using multi-layer update, this component didn't use PatientOrContactDetailSummarySection.
  useEffect(() => {
    if (payorName) handlePayorNameChange(payorName);
    if (contact) handleContactNumberChange(contact);
    if (email) handleEmailChange(email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payorName, contact, email]);

  useEffect(() => {
    fetchSystemSettings();
    dispatch(setshouldShowBackOnEntryHijack(true));
    // on load of the payment page, log page to GA
    logEventToGoogleAnalytics(EVENTS.VIEW_PAYMENT_PAYOR_INFO);

    // allow refreshing payment landing page if payorinfo page is visited
    onResetDashboardShouldRefresh();

    // resetting api flags and session storage persist
    onResetPaymentApiStatus();
    onSetPaymentPersist(initialState.paymentPersist);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // on select of payment mode, submit payment request
    if (selectedPaymentMode === CREDIT || selectedPaymentMode === AMEX) {
      onSubmitPaymentRequest(
        "NUHS",
        selectedPaymentMode,
        amountToPay,
        nameOfPayor,
        contactNumber,
        emailAddress,
        selectedPaymentMode === CREDIT
          ? cardTokenizationObj.CC
          : cardTokenizationObj.AMEX,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMode]);

  // redirect when SubmitPaymentRequest API success
  useEffect(() => {
    if (
      !submitPaymentRequestApiStatus.isLoading &&
      submitPaymentRequestApiStatus.hasErrored === false &&
      redirectionLink
    ) {
      onSetPaymentPersist({
        payorName: nameOfPayor,
        email: emailAddress,
        paymentToken,
        merchantRefNumber,
        requestorMemberIdentifier: memberIdentifier,
        selectedPaymentMode,
      });
      if (selectedPaymentMode === CREDIT || selectedPaymentMode === AMEX) {
        dispatch(setshouldShowBackOnEntryHijack(false));

        dispatch(
          setMessageToSend(
            MessageActions.staticTitle({ title: "Payment", isEnabled: true }),
          ),
        );
        // navigation to common payment gateway (external link)
        navigateToExternalInAppWebView(redirectionLink);
      } else {
        // selectedPaymentMode === DEBIT
        // send redirection link for mobile to intercept,
        // then navigate to receipt page
        dispatch(setshouldShowBackOnEntryHijack(false));
        navigateToExternalInMobileBrowser(redirectionLink);
        navigate(PATHS.PAYMENT_RECEIPT.path);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitPaymentRequestApiStatus]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    callAvailablePaymentModes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAvailablePaymentModes]);

  return (
    <StepPageLayout
      shouldRenderStepHeader={!showTransitionToGateway}
      currentStepIndex={1}
    >
      {isLoadingAvailablePaymentModes ||
      isLoadingSystemSetting ||
      submitPaymentRequestApiStatus.isLoading ? (
        <Box display="flex" align-items="center" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : submitPaymentRequestApiStatus.hasErrored ? (
        <>
          <ErrorDisplayContainer
            errorMessage={submitPaymentRequestApiStatus.errorMessage}
            // No onTryAgain since user probably want to try another payment method at fail
          />
          <Box
            sx={classes.backLink}
            onClick={() => {
              onResetPaymentApiStatus();
              setShowTransitionToGateway(true);
              onSubmitPaymentRequest(
                "NUHS",
                selectedPaymentMode,
                amountToPay,
                nameOfPayor,
                contactNumber,
                emailAddress,
                selectedPaymentMode === CREDIT
                  ? cardTokenizationObj.CC
                  : selectedPaymentMode === AMEX
                    ? cardTokenizationObj.AMEX
                    : cardTokenizationObj.DD,
              );
              navigate(PATHS.PAYMENT_PAYOR_INFO.path);
            }}
          >
            Back
          </Box>
        </>
      ) : hasErroredAvailablePaymentModes ? (
        <ErrorDisplayContainer
          errorMessage={errorMessageAvailablePaymentModes}
          onTryAgain={() => {
            callAvailablePaymentModes();
          }}
        />
      ) : isErroredSystemSetting ? (
        <ErrorDisplay
          errorMessage={isErroredSystemSettingMessage}
          onTryAgain={() => fetchSystemSettings()}
        />
      ) : showTransitionToGateway ? (
        <Box sx={classes.transitionPage}>
          <Typography sx={classes.transitionText} component={"span"}>
            Your Payment is being processed. Please wait.
          </Typography>
          <Box>
            <Box
              src={IMAGES.payment.TransitionToGateway}
              alt="TransitionToGateway"
              sx={classes.transitionImage}
              component="img"
            />
          </Box>
        </Box>
      ) : (
        <VerticalSpreadLayout>
          <Box sx={classes.layout}>
            <AccordionBlock
              summaryNode={
                <Box>
                  <Typography sx={classes.title}>Bill Summary</Typography>
                  <Typography sx={classes.payValue}>
                    Total: {formatPayment(Number(amountToPay))}
                  </Typography>
                </Box>
              }
              detailsNode={
                <>
                  {billsToPay.map((summaryBill, i) => {
                    if (
                      !summaryBill.Selected ||
                      Number(summaryBill.AmountToPay) === 0
                    )
                      return null;

                    const institutionName =
                      allSystemBills.find(
                        (bill) => bill.InvoiceNumber === summaryBill.InvNo,
                      )?.InstitutionName ||
                      allAddedBills.find(
                        (bill) => bill.InvoiceNumber === summaryBill.InvNo,
                      )?.InstitutionName ||
                      null;

                    return (
                      <Box
                        key={summaryBill.InvNo}
                        sx={classes.summaryContainer}
                      >
                        <Box sx={classes.summaryBox}>
                          <Box sx={classes.summaryBoxLeft}>
                            <Typography sx={classes.cardValue}>
                              {institutionName}
                            </Typography>
                            <Typography sx={classes.cardLabel}>
                              Invoice No: {summaryBill.InvNo}
                            </Typography>
                          </Box>
                          <Box sx={classes.summaryBoxRight}>
                            <Typography sx={classes.cardValue}>
                              {formatPayment(Number(summaryBill.AmountToPay))}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </>
              }
              expanded={expanded}
              onExpand={handleExpandClick}
            />

            <Box mt={1} ml={2} mr={2}>
              <Typography sx={classes.title}>Payor Contact </Typography>
              <Box sx={classes.textBox}>
                <SingleLineTextField
                  type="text"
                  maxCharLength={100}
                  value={nameOfPayor}
                  error={!validation.payorName && nameOfPayor !== ""}
                  placeholder="Payor Name"
                  errorText="Enter a valid payor name"
                  handleChange={(event) =>
                    handlePayorNameChange(event.target.value)
                  }
                ></SingleLineTextField>
              </Box>
              <Box sx={classes.textBox}>
                <SingleLineTextField
                  type="number"
                  maxCharLength={8}
                  value={contactNumber}
                  error={!validation.contact && contactNumber !== ""}
                  placeholder="Contact Number"
                  errorText="Enter a valid phone number"
                  handleChange={(event) =>
                    handleContactNumberChange(event.target.value)
                  }
                ></SingleLineTextField>
              </Box>
              <Box sx={classes.textBox}>
                <SingleLineTextField
                  type="text"
                  value={emailAddress}
                  error={!validation.email && emailAddress !== ""}
                  placeholder="Email"
                  errorText="Enter a valid email"
                  handleChange={(event) =>
                    handleEmailChange(event.target.value)
                  }
                ></SingleLineTextField>
              </Box>
            </Box>
            <Box ml={2} mr={2}>
              <Typography sx={classes.title}>Select Payment Mode</Typography>
              <Box sx={classes.sharpnoticepanel}>
                <SharpNoticePanel bgColor="warn">
                  Payment will be made to National University Health System Pte
                  Ltd (NUHS).
                </SharpNoticePanel>
              </Box>
              {validation.payorName &&
              validation.contact &&
              validation.email &&
              contactNumber &&
              emailAddress ? (
                <Box sx={classes.payMethods}>
                  {IsCcAvailable ? (
                    <Box
                      component="img"
                      key="cc"
                      src={IMAGES.payment.VisaMasterEnabled}
                      alt="Visa / Master"
                      onClick={() => {
                        logEventToGoogleAnalytics(EVENTS.SELECT_CREDIT_CARD);
                        setSelectedPaymentMode(CREDIT);
                        setShowTransitionToGateway(true);
                      }}
                    />
                  ) : (
                    <Box
                      component="img"
                      key="cc"
                      src={IMAGES.payment.VisaMasterDisabled}
                      alt="Visa / Master"
                    />
                  )}

                  {IsAmexAvailable ? (
                    <Box
                      component="img"
                      key="amex"
                      src={IMAGES.payment.AmexEnabled}
                      alt="Amex Enabled"
                      onClick={() => {
                        logEventToGoogleAnalytics(EVENTS.SELECT_AMEX);
                        setSelectedPaymentMode(AMEX);
                        setShowTransitionToGateway(true);
                      }}
                    />
                  ) : (
                    <Box
                      component="img"
                      key="amex"
                      src={IMAGES.payment.AmexDisabled}
                      alt="Amex Disabled"
                    />
                  )}

                  {IsDdAvailable ? (
                    <Box
                      component="img"
                      key="dd"
                      src={IMAGES.payment.eNetsEnabled}
                      alt="eNets"
                      onClick={() => {
                        logEventToGoogleAnalytics(EVENTS.SELECT_DIRECT_DEBIT);
                        setSelectedPaymentMode(DEBIT);
                        setShowTransitionToGateway(true);
                        onSubmitPaymentRequest(
                          "NUHS",
                          DEBIT,
                          amountToPay,
                          nameOfPayor,
                          contactNumber,
                          emailAddress,
                          cardTokenizationObj.DD,
                        );
                      }}
                    />
                  ) : (
                    <Box
                      key="dd"
                      src={IMAGES.payment.eNetsDisabled}
                      alt="eNets"
                      component="img"
                    />
                  )}
                </Box>
              ) : (
                <Box sx={classes.payMethods}>
                  <img
                    key="cc"
                    src={IMAGES.payment.VisaMasterDisabled}
                    alt="Visa / Master"
                  />

                  <Box
                    component="img"
                    key="amex"
                    src={IMAGES.payment.AmexDisabled}
                    alt="Amex Disabled"
                  />
                  <Box
                    src={IMAGES.payment.eNetsDisabled}
                    alt="eNets"
                    component="img"
                  />
                </Box>
              )}

              {IsCcAvailable === false ||
              IsAmexAvailable === false ||
              IsDdAvailable === false ? (
                <Box mt={4}>
                  <SharpNoticePanel bgColor="extremeWarn">
                    {CcDisclaimer !== null && IsCcAvailable === false ? (
                      <Box py={0.5}>{CcDisclaimer}</Box>
                    ) : null}
                    {DdDisclaimer !== null && IsDdAvailable === false ? (
                      <Box py={0.5}>{DdDisclaimer}</Box>
                    ) : null}

                    {AmexDisclaimer !== null && IsAmexAvailable === false ? (
                      <Box py={0.5}>{AmexDisclaimer}</Box>
                    ) : null}
                  </SharpNoticePanel>
                </Box>
              ) : null}
            </Box>
          </Box>
          <Box mt={5} mb={2} mx={2}>
            <ButtonsFooter
              hideNextButton={true}
              nextButtonText=""
              cancelButtonText="Cancel"
              onClickCancel={() => {
                logEventToGoogleAnalytics(EVENTS.CANCEL_PAYMENT_PAYOR_INFO);
                handleOpenWarningModal();
              }}
            ></ButtonsFooter>
          </Box>
          <ConfirmationModal
            open={open}
            title="Are you sure?"
            body={
              <p>If you cancel now, you may have to start all over again.</p>
            }
            nextButtonText="No, continue to pay"
            cancelButtonText="Yes, cancel payment"
            showCloseButton={true}
            onClose={handleCloseWarningModal}
            onClickNext={handleCloseWarningModal}
            onClickCancel={() => {
              onExitCurrentFlow();
              dispatch(setshouldShowBackOnEntryHijack(null));
              navigate(PATHS.PAYMENT_MOBILE.path, { replace: true });
            }}
          />
        </VerticalSpreadLayout>
      )}
    </StepPageLayout>
  );
};

export default PayorInfo;
