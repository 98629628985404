import { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SummarySectionFrame from "lib/components/summary/SummarySectionFrame/SummarySectionFrame";
import PatientOrContactDetailSummarySection from "lib/components/summary/PatientOrContactDetailSummarySection/PatientOrContactDetailSummarySection";
import { sxStyles } from "./RefillViewPastRequestDetailPage.styles";
import MedicationRefillDetailSummarySection from "ui/medrefill/components/common/orderSummary/MedicationRefillDetailSummarySection/MedicationRefillDetailSummarySection";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import { PATHS } from "lib/routing";
import { RefillViewPastRequestDetailProps } from "./RefillViewPastRequestDetailPage.types";
import MedicationRefillPrescriptionSummaryDetail from "ui/medrefill/components/common/orderSummary/MedicationRefillPrescriptionSummaryDetail/MedicationRefillPrescriptionSummaryDetail";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import { maskNric } from "ui/appointment/components/common/user/UserProfile/maskNric";

import DeliveryDetailSummarySection from "lib/components/summary/DeliveryDetailSummarySection/DeliveryDetailSummarySection";
import PharmacyDetailSummarySection from "ui/medrefill/components/common/orderSummary/PharmacyDetailSummarySection/PharmacyDetailSummarySection";
import { sameStringTest } from "lib/util/StringUtil/sameStringTest/sameStringTest";

const RefillViewPastRequestDetailPage = ({
  isLoadingPastDetail,
  hasErroredViewPastRequest,
  errorMessageViewPastRequest,
  isLoadingImages,
  hasErroredImages,
  errorMessageImages,
  pastRequstHistory,
  isLoadingFormData,
  hasErroredSystemSettings,
  errorMessageSystemSettings,
  hasErroredMedications,
  errorMessageMedications,
  hasErroredCollectionPaymentDetails,
  errorMessageCollectionPaymentDetails,
  hasErroredDocByCluster,
  errorMessageDocByCluster,
  onLoad,
  pushPastRequestState,
  onReorder,
  canReOrderMedication = false,
}: RefillViewPastRequestDetailProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();

  const [stateCleared, setStateCleared] = useState(false);

  useEffect(() => {
    // Load data every time this component mounts
    onLoad();
    setStateCleared(true);
    logEventToGoogleAnalytics(EVENTS.VIEW_REFILL_PAST_REQUEST_DETAIL);
  }, [onLoad]);

  // do the page redirection to do reordering only after all form data API calls have been finished successfully
  useEffect(() => {
    if (
      !isLoadingFormData &&
      hasErroredSystemSettings === false &&
      hasErroredMedications === false &&
      hasErroredCollectionPaymentDetails === false &&
      hasErroredDocByCluster === false &&
      stateCleared === true
    ) {
      setStateCleared(false);
      if (
        pastRequstHistory.particulars.isPatient === false &&
        pastRequstHistory.particulars.patientNric &&
        pastRequstHistory.particulars.requesterNric &&
        sameStringTest(
          pastRequstHistory.particulars.patientNric,
          pastRequstHistory.particulars.requesterNric,
        )
      ) {
        logEventToGoogleAnalytics(EVENTS.SELECT_REFILL_NEW_REQUEST);
        navigate(PATHS.MED_REFILL_SELECT_INSTITUTIONS.path);
      } else {
        navigate(PATHS.MED_REFILL_QUANTITY.path);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingFormData,
    hasErroredSystemSettings,
    hasErroredMedications,
    hasErroredCollectionPaymentDetails,
    hasErroredDocByCluster,
    navigate,
  ]);

  return (
    <Box mx={2}>
      {isLoadingPastDetail || isLoadingFormData ? (
        <Box display="flex" align-items="center" justifyContent="center" pt={3}>
          <CircularProgress />
        </Box>
      ) : hasErroredViewPastRequest ? (
        <ErrorDisplay
          errorMessage={errorMessageViewPastRequest}
          onTryAgain={() => onLoad()}
        />
      ) : hasErroredImages ? (
        <ErrorDisplay
          errorMessage={errorMessageImages}
          onTryAgain={() => onLoad()}
        />
      ) : hasErroredSystemSettings ? (
        <ErrorDisplay
          errorMessage={errorMessageSystemSettings}
          onTryAgain={() =>
            onReorder(
              pastRequstHistory.institution.facilityId || 0,
              pastRequstHistory.institution.cluster || "",
            )
          }
        />
      ) : hasErroredMedications ? (
        <ErrorDisplay
          errorMessage={errorMessageMedications}
          onTryAgain={() =>
            onReorder(
              pastRequstHistory.institution.facilityId || 0,
              pastRequstHistory.institution.cluster || "",
            )
          }
        />
      ) : hasErroredCollectionPaymentDetails ? (
        <ErrorDisplay
          errorMessage={errorMessageCollectionPaymentDetails}
          onTryAgain={() =>
            onReorder(
              pastRequstHistory.institution.facilityId || 0,
              pastRequstHistory.institution.cluster || "",
            )
          }
        />
      ) : hasErroredDocByCluster ? (
        <ErrorDisplay
          errorMessage={errorMessageDocByCluster}
          onTryAgain={() =>
            onReorder(
              pastRequstHistory.institution.facilityId || 0,
              pastRequstHistory.institution.cluster || "",
            )
          }
        />
      ) : (
        <>
          <Typography sx={classes.title}>Order Summary</Typography>
          <SummarySectionFrame>
            <Typography sx={classes.facilityTitle}>
              Prescribing Institution
            </Typography>
            <Typography sx={classes.facilityDetail}>
              {pastRequstHistory.institution.facilityName}
            </Typography>
          </SummarySectionFrame>
          <SummarySectionFrame>
            <PatientOrContactDetailSummarySection
              title="Patient's Detail"
              upperLabel="Name"
              upperDetail={pastRequstHistory.particulars.patientName || ""}
              lowerLabel="NRIC / Birth Certificate / FIN"
              lowerDetail={maskNric(pastRequstHistory.particulars.patientNric)}
            ></PatientOrContactDetailSummarySection>
          </SummarySectionFrame>
          <SummarySectionFrame>
            <PatientOrContactDetailSummarySection
              title="Contact Details"
              upperLabel="Contact Number"
              upperDetail={pastRequstHistory.particulars.contactNumber}
              lowerLabel="Email"
              lowerDetail={pastRequstHistory.particulars.email}
            ></PatientOrContactDetailSummarySection>
          </SummarySectionFrame>
          <SummarySectionFrame>
            <MedicationRefillDetailSummarySection
              typeId={pastRequstHistory.quantity.typeId}
              quantityDetailList={pastRequstHistory.quantity.details}
            ></MedicationRefillDetailSummarySection>
          </SummarySectionFrame>
          <SummarySectionFrame>
            {pastRequstHistory.collection.typeId === 3 ? (
              <DeliveryDetailSummarySection
                postalCode={pastRequstHistory.collection.postalCode}
                blockNo={pastRequstHistory.collection.blockNo}
                unitNo={pastRequstHistory.collection.unitNo}
                address={pastRequstHistory.collection.address}
                showSpecialRequest={true}
                specialRequest={pastRequstHistory.collection.specialRequest}
              ></DeliveryDetailSummarySection>
            ) : (
              <PharmacyDetailSummarySection
                facilityName={pastRequstHistory.institution.facilityName}
                locationName={pastRequstHistory.collection.locationName}
                specialRequest={pastRequstHistory.collection.specialRequest}
                date={pastRequstHistory.collection.date}
                timeslot={pastRequstHistory.collection.timeslot}
              ></PharmacyDetailSummarySection>
            )}
          </SummarySectionFrame>
          {/* {subsidy && (
            <SummarySectionFrame>
              <PaymentSummarySection
                subsidyDescription={
                  subsidy?.split("|").filter((a) => a !== "") ?? []
                }
                paymentInstructions={paymentInstructions ?? ""}
              />
            </SummarySectionFrame>
          )} */}
          {/* Medication Prescription section */}
          <SummarySectionFrame>
            <MedicationRefillPrescriptionSummaryDetail
              files={pastRequstHistory.prescription.files}
            ></MedicationRefillPrescriptionSummaryDetail>
            {isLoadingImages && (
              <Box sx={classes.overlayCircularProgress}>
                <CircularProgress sx={classes.loadingIcon} />
              </Box>
            )}
          </SummarySectionFrame>
          <Box pb={2} pt={3}>
            <ButtonsFooter
              nextButtonText="Re-order"
              cancelButtonText="Back"
              isDisabled={!canReOrderMedication}
              onClickNext={() => {
                pushPastRequestState(pastRequstHistory);
                onReorder(
                  pastRequstHistory.institution.facilityId || 0,
                  pastRequstHistory.institution.cluster || "",
                );
                logEventToGoogleAnalytics(EVENTS.REORDER_REFILL_PAST_REQUEST);
              }}
              onClickCancel={() => navigate(-1)}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default RefillViewPastRequestDetailPage;
