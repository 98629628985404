import { RootStore } from "lib/redux/root/redux.types";
import { SystemState } from "lib/redux/system/system.types";
import { validateOptionalField } from "lib/util/ValidatorUtil/fieldValidator/validateField";
import { initializeFromMobileIntegration } from "../systemSlice";

const FIELD_NAME_DEVICE_PLATFORM = "devicePlatform";

const dispatchToReducer = async (store: RootStore, systemObj: SystemState) => {
  validateOptionalField(systemObj, FIELD_NAME_DEVICE_PLATFORM);

  await store.dispatch(
    initializeFromMobileIntegration({
      devicePlatform: systemObj.devicePlatform,
      deviceId: systemObj.deviceId,
      deviceInfo: systemObj.deviceInfo,
      devicePublicIp: systemObj.devicePublicIp,
      authType: systemObj.authType,
    }),
  );
};

export { dispatchToReducer };
