/**
 *
 * validate if the input email has the correct email format
 * return true if the input email has correct format, false otherwise
 * has an optional input to allow inputtig null email returns valid, used when email is not required
 *
 * @param {string} email input string to be validated
 * @param {boolean} allowNull allow null email to be valid
 */
export const validateEmail = (email: string | null, allowNull?: boolean) => {
  if (email) {
    // a single dot without [] in RegExp means matches any char except line breaks
    var regexp = new RegExp(
      "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$",
    );
    return regexp.test(email);
  } else {
    return allowNull || false;
  }
};
export const validateEmailForPayment = (
  email: string | null,
  allowNull?: boolean,
) => {
  if (email) {
    // a single dot without [] in RegExp means matches any char except line breaks
    var regexp = new RegExp(
      "^([a-zA-Z0-9]|([\\._%+-]+)[a-zA-Z0-9])+@[a-zA-Z0-9]+([\\.-]?[a-zA-Z0-9]+)*(\\.[a-zA-Z]{2,4})+$",
    );
    return regexp.test(email);
  } else {
    return allowNull || false;
  }
};
