import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    profileHeader: {
      backgroundColor: theme.palette.secondary.main,
      width: "100%",
      display: "flex",
      minHeight: theme.spacing(6),
    },
    root: {
      backgroundColor: theme.palette.secondary.main,
      padding: 0,
      width: "100%",
      "& .MuiCardHeader-avatar": {
        marginRight: theme.spacing(1.5),
      },
      "& .MuiCardHeader-title": {
        color: theme.palette.text.primary,
        typography: theme.typography.body1_semibold,
        padding: theme.spacing(1, 0),
        wordBreak: "break-word",
      },
      "% .MuiCardHeader-subheader": {
        color: theme.palette.grey[600],
        typography: theme.typography.body1_regular,
        display: "none",
      },
    },

    makeNewAppointment: {
      display: "flex",
      alignItems: "center",
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
  } satisfies SxObjType;
};

export { sxStyles };
