import { useEffect, useMemo, useState } from "react";
import {
  Dialog,
  IconButton,
  Box,
  CircularProgress,
  DialogTitle,
  DialogContent,
  Typography,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import IMAGES from "lib/assets/images";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import Dropdown from "lib/components/formInputs/Dropdown/Dropdown";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import { validateNric } from "lib/util/ValidatorUtil/nricValidator/nricValidator";
import { billRefValidator } from "lib/util/ValidatorUtil/billRefValidator/billRefValidator";
import { AddOtherBillDialogProps } from "./AddOtherBillDialog.types";
import { addManualBill } from "api/payment/AddManualBill/addManualBill";
import { AddManualBillResponsePayload } from "api/payment/AddManualBill/addManualBill.fromApi.types";
import { PATHS } from "lib/routing";
import { formatPayment } from "lib/util/StringUtil/formatPayment/formatPayment";
import { sxStyles } from "./AddOtherBillDialog.styles";
import {
  BILL_LIMIT_ERROR,
  MAX_AMOUNT_PAYABLE_PER_TRANSACTION,
  MAX_DIGIT_OF_FRACTIONAL_PART,
  MAX_DIGIT_OF_WHOLE_NUMBER_PART,
} from "../../Constants";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import {
  disableBackButton,
  enableBackButton,
} from "lib/routing/navigate/navigate";

// Hooks
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { addAddedBill, fetchArInstitutions } from "ui/payment/ducks";
import {
  selectPayments,
  selectPaymentsArInstitutions,
} from "ui/payment/ducks/selectors";
import { AxiosError } from "axios";

const AddOtherBillDialog = ({
  open,
  hostedOnBillSummaryPage,
  handleClose,
}: AddOtherBillDialogProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const otherAddedBillList =
    useAppSelector(selectPayments).addedBills.allAddedBills;
  const isLoadingArInstitutions = useAppSelector(
    selectPaymentsArInstitutions,
  ).isLoading;
  const hasErroredArInstitutions = useAppSelector(
    selectPaymentsArInstitutions,
  ).hasErrored;
  const errorMessageArInstitutions = useAppSelector(
    selectPaymentsArInstitutions,
  ).errorMessage;
  const arInstitutionsList = useAppSelector(
    selectPaymentsArInstitutions,
  ).arInstitutionsList.map((institutions) => {
    return {
      id: institutions.ArCode,
      text: institutions.Name,
    };
  });
  // const isPatient = useAppSelector(selectUser).isPatient;
  const allOutstandingBills = useAppSelector(
    selectPayments,
  ).outstandingBills.allOutstandingBills.map((outstandingBills) => {
    return {
      invoiceNumber: outstandingBills.InvoiceNumber,
    };
  });

  // useEffect hook on page load
  useEffect(() => {
    logEventToGoogleAnalytics(EVENTS.VIEW_PAYMENT_ADD_BILL);
    dispatch(fetchArInstitutions());
  }, [dispatch]);

  // user inputs
  const [institutionCode, setInstitutionCode] = useState<string | null>(null);
  const [patientNric, setPatientNric] = useState<string | null>(null);
  const [billReferenceNo, setBillReferenceNo] = useState<string | null>(null);
  const [patientFullname, setPatientFullname] = useState<string | null>(null);
  const [amountToPay, setAmountToPay] = useState<string | null>(null);
  // add bill API
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const [duplicateBillRefNo, setIsDuplicateBillRefNo] = useState(false); // specific validation flag for bill reference number, true if input bill reference number is duplicated, false otherwise
  const [billingSystemIsBFE, setBillingSystemIsBFE] = useState(false);

  // function to reset all selected value before closing
  const closeDialog = () => {
    enableBackButton(); //Special handling for android physical back button
    setIsDuplicateBillRefNo(false);
    setInstitutionCode(null);
    setPatientNric(null);
    setBillReferenceNo(null);
    setPatientFullname(null);
    setAmountToPay(null);
    setHasError(false);
    setBillingSystemIsBFE(false);
    setValidations(initialValidation);
    handleClose();
  };

  const prepareForAddBillApiCall = () => {
    setIsLoading(true);
    setErrMsg(null);
    setHasError(false);
  };

  // helper structure to control if validation message should be displayed, set all as true as no validation should be displayed even if the corresponding values are falsy
  // validation message should be only displayed after user makes change to fill in the fields
  const initialValidation = {
    nric: true,
    billRef: true,
    fullName: true,
    amountToPay: true,
  };
  const [validations, setValidations] = useState<{
    nric: boolean;
    billRef: boolean;
    fullName: boolean;
    amountToPay: boolean;
  }>({ ...initialValidation });

  // helper function to get insitution name based on the insitution code selected
  const getInstitutionNameFromCode = (insitutionCode: string | null) => {
    if (institutionCode === null) return null;
    const filtered = arInstitutionsList.filter((ins) => {
      return ins.id === insitutionCode;
    });
    return filtered[0]?.text ?? null;
  };

  const disableAddBillButton = useMemo(() => {
    if (institutionCode && billReferenceNo) {
      if (!billRefValidator(institutionCode, billReferenceNo)) {
        return true;
      } else if (
        institutionCode === "NUP" &&
        validateNric(patientNric, true) &&
        billReferenceNo &&
        !duplicateBillRefNo
      ) {
        if (billingSystemIsBFE) {
          const amountToPayNum = Number(amountToPay);

          if (
            patientFullname &&
            amountToPayNum > 0 &&
            amountToPayNum <= MAX_AMOUNT_PAYABLE_PER_TRANSACTION
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else if (
        institutionCode !== "NUP" &&
        billReferenceNo &&
        !duplicateBillRefNo
      ) {
        if (patientNric && patientNric !== "") {
          if (!validateNric(patientNric, true)) {
            return true;
          }
        }

        if (billingSystemIsBFE) {
          const amountToPayNum = Number(amountToPay);
          if (
            patientFullname &&
            amountToPayNum > 0 &&
            amountToPayNum <= MAX_AMOUNT_PAYABLE_PER_TRANSACTION
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, [
    amountToPay,
    billReferenceNo,
    billingSystemIsBFE,
    duplicateBillRefNo,
    institutionCode,
    patientFullname,
    patientNric,
  ]);

  // helper function to check if bill reference number is invalid, the validation is made by addManualBill API
  const invalidBillRefNum = (response: AddManualBillResponsePayload) => {
    return response.Code === "PAY0001";
  };

  const getAddManualBill = async () => {
    try {
      prepareForAddBillApiCall();
      setIsDuplicateBillRefNo(false);
      var response = null;
      if (billReferenceNo !== null) {
        if (patientNric !== null) {
          response = await addManualBill(
            institutionCode,
            billReferenceNo.toUpperCase(),
            patientNric.toUpperCase(),
          );
        } else {
          response = await addManualBill(
            institutionCode,
            billReferenceNo.toUpperCase(),
            patientNric,
          );
        }
      } else {
        response = await addManualBill(
          institutionCode,
          billReferenceNo,
          patientNric,
        );
      }
      if (response.Bill !== null) {
        let addManualBillDetails = response.Bill;
        const billingSystem = addManualBillDetails.BillingSystem;
        const invoiceNumber = addManualBillDetails.InvoiceNumber;

        if (
          billingSystem === "BFE" &&
          (addManualBillDetails.AmountDueFromPatient === 0 ||
            addManualBillDetails.AmountDueFromPatient === null)
        ) {
          setBillingSystemIsBFE(true);
          setPatientFullname(addManualBillDetails.PatientName);
        } else {
          setBillingSystemIsBFE(false);
          if (
            otherAddedBillList.find(
              (bill) => bill.InvoiceNumber === invoiceNumber,
            ) === undefined &&
            allOutstandingBills.find(
              (outstandingBill) =>
                outstandingBill.invoiceNumber === invoiceNumber,
            ) === undefined
          ) {
            logEventToGoogleAnalytics(EVENTS.CONFIRM_ADD_BILL_DEFAULT);
            dispatch(
              addAddedBill({
                manualBill: addManualBillDetails,
                institutionName: getInstitutionNameFromCode(institutionCode),
                patientFullname: addManualBillDetails.PatientName,
                amountToPay: formatPayment(
                  addManualBillDetails.AmountDueFromPatient,
                ).slice(2),
              }),
            );
            closeDialog();
            if (!hostedOnBillSummaryPage)
              navigate(PATHS.PAYMENT_BILL_SUMMARY.path);
          } else {
            setIsDuplicateBillRefNo(true);
          }
        }
      } else if (invalidBillRefNum(response)) {
        //code indicating the bill reference number is invalid
        setValidations({ ...validations, billRef: false });
      }
    } catch (error) {
      setHasError(true);
      if (error instanceof AxiosError) {
        setErrMsg(error.response?.data.Message);
      }
      // handleErrorModal(true, null);
    } finally {
      setIsLoading(false);
    }
  };

  const getAddManualBillWithBFE = async () => {
    try {
      prepareForAddBillApiCall();
      var response = null;
      if (billReferenceNo !== null) {
        if (patientNric !== null) {
          response = await addManualBill(
            institutionCode,
            billReferenceNo.toUpperCase(),
            patientNric.toUpperCase(),
          );
        } else {
          response = await addManualBill(
            institutionCode,
            billReferenceNo.toUpperCase(),
            patientNric,
          );
        }
      } else {
        response = await addManualBill(
          institutionCode,
          billReferenceNo,
          patientNric,
        );
      }
      if (response.Bill !== null) {
        let addManualBillDetails = response.Bill;
        const invoiceNumber = addManualBillDetails?.InvoiceNumber || null;
        if (
          otherAddedBillList.find(
            (bill) => bill.InvoiceNumber === invoiceNumber,
          ) === undefined &&
          allOutstandingBills.find(
            (outstandingBill) =>
              outstandingBill.invoiceNumber === invoiceNumber,
          ) === undefined
        ) {
          logEventToGoogleAnalytics(EVENTS.CONFIRM_ADD_BILL_WITH_INFO);
          dispatch(
            addAddedBill({
              manualBill: addManualBillDetails,
              institutionName: getInstitutionNameFromCode(institutionCode),
              patientFullname,
              amountToPay,
            }),
          );
          closeDialog();
          if (!hostedOnBillSummaryPage)
            navigate(PATHS.PAYMENT_BILL_SUMMARY.path);
        } else {
          setIsDuplicateBillRefNo(true);
        }
      } else if (invalidBillRefNum(response)) {
        setValidations({ ...validations, billRef: false });
      }
    } catch (error) {
      setHasError(true);
      if (error instanceof AxiosError) {
        setErrMsg(error.response?.data.Message);
      }
      // handleErrorModal(true, null);
    } finally {
      setIsLoading(false);
    }
  };

  const createViewManualBill = (
    invoiceNumber: string,
    paymentAmount: string,
    patientFullname: string,
    institutionCode: string,
  ) => {
    const test = {
      ArSource: null,
      IsProvisionalBill: null,
      Message: null,
      SortDate: null,
      RequestedDateTime: null,

      InstitutionCode: institutionCode,
      InstitutionName: null,
      VisitType: null,
      VisitDate: null,

      HasInvoiceNo: true,
      InvoiceNumber: invoiceNumber,
      AmountDueFromPatient: Number(paymentAmount),
      PaymentAmount: paymentAmount,
      BillingSystem: "BFE",
      BillType: null,
      BillDate: null,

      DisplayPatientId: null,
      PatientId: null,
      PatientName: patientFullname,
      DownloadId: null,
      InvoiceStatus: null,
    };
    return test;
  };

  const getAddManualBillNoNRIC = async () => {
    try {
      if (
        otherAddedBillList.find(
          (bill) => bill.InvoiceNumber === billReferenceNo,
        ) === undefined &&
        allOutstandingBills.find(
          (outstandingBill) =>
            outstandingBill.invoiceNumber === billReferenceNo,
        ) === undefined
      ) {
        if (patientFullname === "" || patientFullname == null) {
          setBillingSystemIsBFE(true);
          setValidations({
            ...validations,
            nric: true,
            billRef: true,
          });
        } else if (
          billReferenceNo != null &&
          amountToPay != null &&
          institutionCode != null
        ) {
          const manualBill = createViewManualBill(
            billReferenceNo.toLocaleUpperCase(),
            amountToPay,
            patientFullname,
            institutionCode,
          );

          dispatch(
            addAddedBill({
              manualBill,
              institutionName: getInstitutionNameFromCode(institutionCode),
              patientFullname,
              amountToPay,
            }),
          );
          closeDialog();
          if (!hostedOnBillSummaryPage)
            navigate(PATHS.PAYMENT_BILL_SUMMARY.path);
        }
      } else {
        setIsDuplicateBillRefNo(true);
      }
    } catch (error) {
      // handleErrorModal(true, null);
    } finally {
      setIsLoading(false);
    }
  };

  // Special handling to disable physical back button for Andoid otherwise user will be navigated back to services page and not the payment dashboard
  useEffect(() => {
    if (open) {
      disableBackButton();
    }
  }, [open]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={closeDialog}
      aria-labelledby="responsive-dialog-title"
    >
      <IconButton
        aria-label="close"
        sx={classes.closeButton}
        onClick={closeDialog}
      >
        <Box component={"img"} src={IMAGES.general.GeneralCloseIcon} />
      </IconButton>
      {isLoadingArInstitutions ? (
        <Box
          display="flex"
          align-items="center"
          justifyContent="center"
          mt={4}
          p={5}
        >
          <CircularProgress />
        </Box>
      ) : hasErroredArInstitutions ? (
        <ErrorDisplayContainer
          errorMessage={errorMessageArInstitutions}
          onTryAgain={() => {
            dispatch(fetchArInstitutions());
          }}
        />
      ) : (
        <>
          {isLoading ? (
            <Box
              display="flex"
              align-items="center"
              justifyContent="center"
              mt={4}
              p={5}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <DialogTitle id="responsive-dialog-title" sx={classes.title}>
                {"Add Other Bill"}
              </DialogTitle>
              <DialogContent>
                <Box>
                  <Box sx={classes.dropdownBox}>
                    <Box sx={classes.formLabel}>
                      <Dropdown
                        label="Select Institution"
                        value={institutionCode}
                        items={arInstitutionsList}
                        handleChange={(event) => {
                          setInstitutionCode(event.target.value);
                          setBillingSystemIsBFE(false);
                          setPatientFullname(null);
                          setAmountToPay(null);
                          setIsDuplicateBillRefNo(false);
                          setHasError(false);
                          setValidations({
                            ...validations,
                            nric: validateNric(
                              patientNric,
                              true,
                              event.target.value,
                            ),
                            billRef:
                              event.target.value && billReferenceNo
                                ? billRefValidator(
                                    event.target.value,
                                    billReferenceNo,
                                  )
                                : false,
                          });
                        }}
                        data-testid="dropdown"
                      ></Dropdown>
                    </Box>
                  </Box>
                  <Box sx={classes.textBox}>
                    <SingleLineTextField
                      type="text"
                      value={patientNric}
                      placeholder="Patient’s NRIC"
                      handleChange={(event) => {
                        const updated = event.target.value;
                        setPatientNric(updated);
                        setValidations({
                          ...validations,
                          nric: validateNric(updated, true),
                        });
                        setBillingSystemIsBFE(false);
                        setPatientFullname(null);
                        setAmountToPay(null);
                        setIsDuplicateBillRefNo(false);
                        setHasError(false);
                      }}
                      error={(patientNric ? true : false) && !validations.nric}
                      errorText="Enter a valid patient’s NRIC"
                      required={institutionCode === "NUP"}
                    />
                  </Box>

                  <Box sx={classes.textBox}>
                    <SingleLineTextField
                      type="text"
                      value={billReferenceNo}
                      placeholder="Bill Reference Number"
                      handleChange={(event) => {
                        const updated = event.target.value;
                        setBillReferenceNo(updated);
                        setValidations({
                          ...validations,
                          billRef: institutionCode
                            ? billRefValidator(institutionCode, updated)
                            : false,
                        });
                        setBillingSystemIsBFE(false);
                        setPatientFullname(null);
                        setAmountToPay(null);
                        setIsDuplicateBillRefNo(false);
                      }}
                      error={
                        // (billReferenceNo ? true : false) && !billReferenceError
                        (billReferenceNo && institutionCode
                          ? !billRefValidator(institutionCode, billReferenceNo)
                          : false) || !validations.billRef
                      }
                      errorText="Enter a valid bill reference number"
                    />
                    {duplicateBillRefNo ? (
                      <Typography sx={classes.errorText}>
                        This bill is already on your list.
                      </Typography>
                    ) : null}
                    {hasError ? (
                      <Typography sx={classes.errorText}>{errMsg}</Typography>
                    ) : null}
                  </Box>
                </Box>
                <DialogContentText sx={classes.imgTitle}>
                  Where to find Bill Ref. No.
                  <Box
                    component={"img"}
                    mt={1}
                    src={IMAGES.payment.AddOtherBillImage}
                    alt="Add Other Bill"
                  />
                </DialogContentText>

                {billingSystemIsBFE ? (
                  <>
                    <Box my={2}>
                      <SharpNoticePanel bgColor="warn">
                        Please fill in the bill detail(s) so we can process it.
                        Please contact billing institution if you require
                        further information.
                      </SharpNoticePanel>
                    </Box>
                    <Box sx={classes.textBox}>
                      <SingleLineTextField
                        type="text"
                        value={patientFullname}
                        placeholder="Patient’s Full Name"
                        handleChange={(event) => {
                          const updated = event.target.value;
                          setPatientFullname(updated);
                          setValidations({
                            ...validations,
                            fullName: updated ? true : false,
                          });
                        }}
                        error={!validations.fullName}
                        errorText="Enter a valid Patient’s Full Name"
                      />
                    </Box>
                    <Box sx={classes.textBox}>
                      <SingleLineTextField
                        type="decimal"
                        decimalPlaces={MAX_DIGIT_OF_FRACTIONAL_PART}
                        maxCharLength={MAX_DIGIT_OF_WHOLE_NUMBER_PART}
                        value={amountToPay ?? "0.00"}
                        placeholder="Amount ($)"
                        error={
                          Number(amountToPay) >
                          MAX_AMOUNT_PAYABLE_PER_TRANSACTION
                        }
                        errorText={BILL_LIMIT_ERROR}
                        handleChange={(event) => {
                          const updated = event.target.value;
                          setAmountToPay(updated);
                          setValidations({
                            ...validations,
                            amountToPay: Number(amountToPay) > 0,
                          });
                        }}
                      />
                    </Box>
                  </>
                ) : null}
                <DialogActions sx={classes.buttonFooter}>
                  <Box sx={classes.cardButtons}>
                    <Button
                      sx={classes.fullButton}
                      variant="contained"
                      color="primary"
                      disabled={disableAddBillButton}
                      onClick={() => {
                        logEventToGoogleAnalytics(
                          EVENTS.SELECT_ADD_BILL_ADD_OTHER_BILL,
                        );
                        if (
                          (patientNric === "" || patientNric == null) &&
                          institutionCode !== "NUP"
                        ) {
                          // setShowAdditionalFields(true);
                          // setBillingSystemIsBFE(true);
                          getAddManualBillNoNRIC();
                        } else if (billingSystemIsBFE) {
                          getAddManualBillWithBFE();
                        } else {
                          getAddManualBill();
                        }
                      }}
                    >
                      Add bill
                    </Button>
                  </Box>
                  <Box sx={classes.cancelButtons}>
                    <Button
                      sx={classes.cancelButton}
                      variant="outlined"
                      color="primary"
                      fullWidth={true}
                      onClick={closeDialog}
                    >
                      Cancel
                    </Button>
                  </Box>
                </DialogActions>
              </DialogContent>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

export default AddOtherBillDialog;
