import { Dispatch, AnyAction } from "@reduxjs/toolkit";
import {
  getAllByPlatform,
  mapAllByPlatformToFormLabel,
} from "api/mrrp/GetAllByPlatform/getAllByPlatform";
import { getHospital } from "api/mrrp/GetHospital/getHospital";
import { getPaymentStatusInfo } from "api/mrrp/GetPaymentStatusInfo/getPaymentStatusInfo";
import { getReportHistory } from "api/mrrp/GetReportHistory/getReportHistory";
import { ViewReportHistory } from "api/mrrp/GetReportHistory/getReportHistory.fromApi.types";
import { getReportOptions } from "api/mrrp/GetReportOptions/getReportOptions";
import { submitPayment } from "api/mrrp/SubmitPayment/submitPayment";
import { AXIOS_WARNING_CODE } from "api/resources/handlers/interpretCcdpCode/interpretCcdpCode";
import { RootState } from "lib/redux/root/redux.types";
import { ApiEnum } from "./mrrp.types";
import {
  setReportOptionsIsLoading,
  setReportOptionsHasErrored,
  setReportOptionsErrorMessage,
  setPatientRelationships,
  setDepartments,
  setPurposes,
  setMODs,
  setEmptyReportOptions,
  setReportHistory,
  setReportHistoryErrorMessage,
  setReportHistoryHasErrored,
  setReportHistoryIsLoading,
  setHospitalErrorMessage,
  setHospitalHasErrored,
  setHospitalIsLoading,
  setHospitals,
  setFormLabelIsLoading,
  setFormLabelErrorStatus,
  setFormLabels,
  setIsLoadingByApi,
  setErrorStatusByApi,
  setSubmitPaymentResult,
  setGetPaymentStatusInfoResult,
} from "./mrrpSlice";
import { AxiosError } from "axios";
import {
  setOldMemberIdentifier,
  setOldTokenizedId,
} from "lib/redux/user/userSlice";

/**
 * A thunk that fetches report history (past report details) from
 * the remote API. Reason not using hook: need switching between report history
 * page and report detail page while avoid re-calling the API.
 *
 */
export const fetchReportHistory =
  () => async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(setReportHistoryIsLoading(true));
      // if landing page is navigated from receipt page, memberIdentifier is stored in session storage
      const memberIdentifier =
        getState().user.memberIdentifier ||
        getState().mrrp.mrrpPersist.memberIdentifier;
      const oldMemberIdentifier = getState().user.oldMemberIdentifier ?? null;
      const oldTokenizedId = getState().user.oldTokenizedId ?? null;

      const reportHistoryResponseData = await getReportHistory(
        null,
        memberIdentifier,
        oldMemberIdentifier,
        oldTokenizedId,
      );

      if (
        reportHistoryResponseData.Status === "E" &&
        reportHistoryResponseData.Code === "ICON0001"
      ) {
        dispatch(setReportHistoryHasErrored(true));
        dispatch(
          setReportHistoryErrorMessage(reportHistoryResponseData.Message),
        );
        dispatch(setReportHistory([]));
      } else {
        dispatch(
          setReportHistory(
            reportHistoryResponseData.Data.sort(compareCreateTime),
          ),
        );
        dispatch(setReportHistoryHasErrored(false));
        dispatch(setReportHistoryErrorMessage(null));
        dispatch(
          setOldMemberIdentifier(reportHistoryResponseData.OldMemberIdentifier),
        );
        dispatch(setOldTokenizedId(reportHistoryResponseData.OldTokenizedId));
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(setReportHistoryErrorMessage(error.response?.data.Message));
        if (error.code === AXIOS_WARNING_CODE) {
          dispatch(
            setReportHistory(error.response?.data.Data.sort(compareCreateTime)),
          );
          dispatch(setReportHistoryHasErrored(false));
        } else {
          dispatch(setReportHistory([]));
          dispatch(setReportHistoryHasErrored(true));
        }
      } else {
        dispatch(setReportHistory([]));
        dispatch(setReportHistoryHasErrored(true));
      }
    } finally {
      dispatch(setReportHistoryIsLoading(false));
    }
  };

/**
 * Helper function to sort report histories based on CreatDateTime
 * @param {ViewReportHistory} report1
 * @param {ViewReportHistory} report2
 */

const compareCreateTime = (
  report1: ViewReportHistory,
  report2: ViewReportHistory,
) => {
  // compare function to sort report by createTime descendent
  if (report1.CreateDateTime < report2.CreateDateTime) return 1;
  if (report1.CreateDateTime > report2.CreateDateTime) return -1;
  return 0;
};

/**
 * A thunk that fetches hospitals and their avaiable payment modes from
 * the remote API.
 *
 * @param {string | null} hospitalCode  Hospital code to retrieve values for, retrieve for all when null, ADMC | CGH | IMH | KKH | KTPH | NUH | SGH | SKH | TTSH
 *
 */

export const fetchHospital =
  (hospitalCode: string | null) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    const oldTokenizedId = getState().user.oldTokenizedId;
    try {
      dispatch(setHospitalIsLoading(true));
      const hospitalResponseData = await getHospital(
        hospitalCode,
        oldTokenizedId,
      );

      dispatch(setHospitals(hospitalResponseData.Data));
      dispatch(setHospitalHasErrored(false));
      dispatch(setHospitalErrorMessage(null));
      dispatch(setOldTokenizedId(hospitalResponseData.OldTokenizedId ?? null));
    } catch (error) {
      dispatch(setHospitals([]));
      dispatch(setHospitalHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setHospitalErrorMessage(error.response?.data.Message));
      }
    } finally {
      dispatch(setHospitalIsLoading(false));
    }
  };

/**
 * A thunk that fetches report options (dropdowns) from
 * the remote API.
 *
 * @param {string | null} hospitalCode  Hospital code to retrieve values for, retrieve for all when null, ADMC | CGH | IMH | KKH | KTPH | NUH | SGH | SKH | TTSH
 * @param {string | null} reportTypeCode  Hospital code to retrieve values for, retrieve for all when null, ADMC | CGH | IMH | KKH | KTPH | NUH | SGH | SKH | TTSH
 *
 */
export const fetchReportOptions =
  (hospitalCode: string, reportTypeCode: string) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    const oldTokenizedId = getState().user.oldTokenizedId ?? null;
    try {
      dispatch(setReportOptionsIsLoading(true));
      const reportOptionsResponseData = await getReportOptions(
        hospitalCode,
        reportTypeCode,
        oldTokenizedId,
      );

      dispatch(
        setPatientRelationships(reportOptionsResponseData.PatientRelationships),
      );
      if (reportTypeCode) {
        dispatch(setDepartments(reportOptionsResponseData.Departments));
      }
      dispatch(setPurposes(reportOptionsResponseData.Purposes));
      dispatch(setMODs(reportOptionsResponseData.DeliveryModes));
      dispatch(setReportOptionsHasErrored(false));
      dispatch(setReportOptionsErrorMessage(null));
      dispatch(setOldTokenizedId(reportOptionsResponseData.OldTokenizedId));
    } catch (error) {
      dispatch(setEmptyReportOptions());
      dispatch(setReportOptionsHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setReportOptionsErrorMessage(error.response?.data.Message));
      }
    } finally {
      dispatch(setReportOptionsIsLoading(false));
    }
  };

/**
 * A thunk that fetches report labels from
 * the remote API.
 *
 */
export const fetchFormLabels = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(setFormLabelIsLoading(true));
    const formLabelResponseData = await getAllByPlatform();

    const formLabels = mapAllByPlatformToFormLabel(formLabelResponseData);

    dispatch(setFormLabels(formLabels));
    dispatch(
      setFormLabelErrorStatus({ hasErrored: false, errorMessage: null }),
    );
  } catch (error) {
    dispatch(setFormLabels([]));
    if (error instanceof AxiosError) {
      dispatch(
        setFormLabelErrorStatus({
          hasErrored: true,
          errorMessage: error.response?.data.Message,
        }),
      );
    }
  } finally {
    dispatch(setFormLabelIsLoading(false));
  }
};

/**
 * A thunk that submit a payment request to API and get info to proceed to external url
 *
 * @param {string | null} institutionCode  // Payee InstitutionCode registered in CPG, mandatory
 * @param {string | null} paymentMethod // "DD" for direct debit, "CC" for credit card
 * @param {number | null} totalAmout // mandatory
 * @param {string | null} reportNo
 *
 */
export const getSubmitPayment =
  (
    institutionCode: string | null,
    paymentMethod: string | null,
    totalAmount: number | null,
    reportNo: string | null,
  ) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(
        setIsLoadingByApi({ api: ApiEnum.SubmitPayment, isLoading: true }),
      );
      const memberIdentifier = getState().user.memberIdentifier;
      const oldMemberIdentifier = getState().user.oldMemberIdentifier;
      const oldTokenizedId = getState().user.oldTokenizedId;
      const submitPaymentResponseData = await submitPayment({
        InstitutionCode: institutionCode,
        PaymentMethod: paymentMethod,
        TotalAmount: totalAmount,
        ReportNo: reportNo,
        MemberIdentifier: memberIdentifier,
        Remarks: "",
        UseCardTokenization: false,
        OldMemberIdentifier: oldMemberIdentifier,
        OldTokenizedId: oldTokenizedId,
      });

      dispatch(setSubmitPaymentResult(submitPaymentResponseData.Data));
      dispatch(
        setErrorStatusByApi({
          api: ApiEnum.SubmitPayment,
          hasErrored: false,
          errorMessage: null,
        }),
      );
      dispatch(
        setOldMemberIdentifier(submitPaymentResponseData.OldMemberIdentifier),
      );
      dispatch(setOldTokenizedId(submitPaymentResponseData.OldTokenizedId));
    } catch (error) {
      dispatch(setSubmitPaymentResult(null));
      if (error instanceof AxiosError) {
        dispatch(
          setErrorStatusByApi({
            api: ApiEnum.SubmitPayment,
            hasErrored: true,
            errorMessage: error.response?.data.Message,
          }),
        );
      }
    } finally {
      dispatch(
        setIsLoadingByApi({ api: ApiEnum.SubmitPayment, isLoading: false }),
      );
    }
  };

/**
 * A thunk that fetches payment status info after user paid through external url
 *
 * @param {string | null} paymentToken // Result from SubmitPayment API
 * @param {string | null} reportNo // Result from CreateMedicalReport API
 * @param {string | null} memberIdentifier // // Encripted NRIC retrieved from logged in user
 * @param {string | null} reportTypeName
 * @param {string | null} payModeCode // Default to hardcoded "N" for now
 * @param {string | null} emailAddress // This is requestor email and used by email notification to requestor. [PS-Backend] /Payment/SubmitPaymentDetail
 */
export const fetchPaymentStatusInfo =
  (
    paymentToken: string | null,
    reportNo: string | null,
    reportTypeName: string | null,
    memberIdentifier: string | null,
    emailAddress: string | null,
    payModeCode: string | null = "N",
  ) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    const oldMemberIdentifier = getState().user.oldMemberIdentifier;
    const oldTokenizedId = getState().user.oldTokenizedId;
    try {
      dispatch(
        setIsLoadingByApi({
          api: ApiEnum.GetPaymentStatusInfo,
          isLoading: true,
        }),
      );
      const paymentStatusResponseData = await getPaymentStatusInfo({
        TransactionQueryParam: {
          PaymentToken: paymentToken,
          MerchantRefNum: reportNo,
        },
        ReportNo: reportNo,
        ReportTypeName: reportTypeName,
        PayModeCode: payModeCode,
        MemberIdentifier: memberIdentifier,
        EmailAddress: emailAddress,
        OldMemberIdentifier: oldMemberIdentifier,
        OldTokenizedId: oldTokenizedId,
      });

      dispatch(setGetPaymentStatusInfoResult(paymentStatusResponseData.Data));
      dispatch(
        setErrorStatusByApi({
          api: ApiEnum.GetPaymentStatusInfo,
          hasErrored: false,
          errorMessage: null,
        }),
      );
      dispatch(
        setOldMemberIdentifier(paymentStatusResponseData.OldMemberIdentifier),
      );
      dispatch(setOldTokenizedId(paymentStatusResponseData.OldTokenizedId));
    } catch (error) {
      dispatch(setGetPaymentStatusInfoResult(null));
      if (error instanceof AxiosError) {
        dispatch(
          setErrorStatusByApi({
            api: ApiEnum.GetPaymentStatusInfo,
            hasErrored: true,
            errorMessage: error.response?.data.Message,
          }),
        );
      }
    } finally {
      dispatch(
        setIsLoadingByApi({
          api: ApiEnum.GetPaymentStatusInfo,
          isLoading: false,
        }),
      );
    }
  };
