import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  const borderColor = theme.palette.secondary.light;

  return {
    layout: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    listItem: {
      padding: theme.spacing(1, 2),
      marginRight: 0,
      borderBottom: `1px solid ${borderColor}`,
      alignItems: "flex-start",
      "& .MuiRadio-colorPrimary.Mui-checked": {
        color: theme.palette.primary.main,
      },
      "& .MuiFormControlLabel-label": {
        color: theme.palette.grey[400],
        typography: theme.typography.body2_regular,
        marginBottom: theme.spacing(0.5),
      },
    },
    listItemDate: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_bold,
    },

    radio: {
      color: theme.palette.primary.main,
      paddingTop: 0,
      "& .MuiSvgIcon-root": {
        fontSize: size.FONTSIZE_24,
      },
    },
    text: {
      color: theme.palette.text.secondary,
      lineHeight: 1.5,
      padding: theme.spacing(2),
    },
    link: {
      color: theme.palette.primary.main,
      typography: "body1_semibold",
    },
  } satisfies SxObjType;
};

export { sxStyles };
