import { Detail } from "api/medrefill/GetMedicationSummaryDetail/getMedicationSummaryDetail.fromApi.types";
import { MedRefillState } from "ui/medrefill/ducks/medrefill.types";
import { initialState } from "ui/medrefill/ducks";

export const mapPastRequestVirtualConsultDetailToMedrefillState = (
  summaryDetail: Detail | null,
) => {
  let medrefillState: MedRefillState = { ...initialState };
  medrefillState.institution = { ...initialState.institution };
  medrefillState.particulars = { ...initialState.particulars };
  medrefillState.prescription = { ...initialState.prescription };
  medrefillState.quantity = { ...initialState.quantity };
  medrefillState.collection = { ...initialState.collection };
  medrefillState.payment = { ...initialState.payment };
  if (summaryDetail) {
    medrefillState.requestType = summaryDetail.PrescripionType;

    medrefillState.institution.facilityId = summaryDetail.FacilityId;
    medrefillState.institution.facilityName = summaryDetail.Facility;
    medrefillState.institution.cluster = summaryDetail.Cluster;
    medrefillState.institution.clinicId = summaryDetail.ClinicId;

    medrefillState.particulars.requesterId = summaryDetail.RequesterId;
    medrefillState.particulars.requesterNric = summaryDetail.RequesterNric;
    medrefillState.particulars.requesterName = summaryDetail.RequesterName;
    medrefillState.particulars.isPatient = summaryDetail.IsPatient;
    medrefillState.particulars.patientName = summaryDetail.PatientName;
    medrefillState.particulars.patientNric = summaryDetail.PatientNric;
    medrefillState.particulars.contactNumber = summaryDetail.Contact;
    medrefillState.particulars.email = summaryDetail.Email;

    medrefillState.collection.typeId = summaryDetail.CollectionTypeId;
    medrefillState.collection.locationId = summaryDetail.LocationId;
    medrefillState.collection.locationName = summaryDetail.Location;
    medrefillState.collection.date = summaryDetail.CollectionDate;
    medrefillState.collection.timeslot = summaryDetail.TimeSlot;
    medrefillState.collection.blockNo = summaryDetail.BlockNo;
    medrefillState.collection.unitNo = summaryDetail.UnitNo;
    medrefillState.collection.address = summaryDetail.Address;
    medrefillState.collection.postalCode = summaryDetail.PostalCode;
    medrefillState.collection.specialRequest = summaryDetail.SpecialRequest;

    medrefillState.payment.subsidy = summaryDetail.SubsidyId;
    medrefillState.payment.paymentInstructions =
      summaryDetail.PaymentInstructions;
    medrefillState.payment.subsidyDescription =
      summaryDetail.Subsidy?.split("|");
  }
  return medrefillState;
};
