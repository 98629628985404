import React from "react";
import { SelectedMedicationOnlyProps } from "./SelectedMedicationOnly.types";
import { Typography, Box } from "@mui/material";
import { sxStyles } from "./SelectedMedicationOnly.styles";
import SimpleAutocomplete from "../../../common/SimpleAutoComplete/SimpleAutoComplete";
import NumberAndUomDurationFields from "../NumberAndUomDurationFields/NumberAndUomDurationFields";
import MedicationCard from "../MedicationCard/MedicationCard";
import IconLink from "lib/components/links/IconLink/IconLink";

const SelectedMedicationOnly = ({
  medicationNameList,
  durationList,
  uomList,
  medicationName,
  medicationNameFreeSolo,
  number,
  durationId,
  handleMedicationNameChange,
  handleFreeSoloMedicationNameChange,
  handleNumberChange,
  handleDurationIdChange,
  addQuantityDetail,
  removeQuantityDetail,
  quantityDetails,
  prescriptionItemNoValidationMsg,
  numberValidationMsg,
  medicationCardNumberLimitMsg,
}: SelectedMedicationOnlyProps) => {
  const classes = sxStyles();

  return (
    <>
      <Typography sx={classes.title}>Selected medication only</Typography>

      <Box mt={2}>
        <Box sx={classes.textBox}>
          <SimpleAutocomplete
            label="Medication Name"
            items={medicationNameList}
            value={medicationName}
            onSelect={handleMedicationNameChange}
            inputValue={medicationNameFreeSolo}
            onInputChange={handleFreeSoloMedicationNameChange}
            error={prescriptionItemNoValidationMsg !== null}
            helperText={prescriptionItemNoValidationMsg || ""}
            maxCharLength={60}
          />
        </Box>

        <NumberAndUomDurationFields
          numberPlaceholder="Qty/Number"
          number={number}
          uomDurationPlaceholder="Unit of Measurement"
          uomDurationId={durationId}
          handleNumberChange={handleNumberChange}
          handleUomDurationIdChange={handleDurationIdChange}
          durationList={durationList}
          uomList={uomList}
          numberValidationMsg={numberValidationMsg}
        />

        <Box sx={classes.buttonBox}>
          <IconLink
            title={"Add medication"}
            icon="addCircleOutline"
            iconPosition="start"
            disabled={
              (medicationName === null && medicationNameFreeSolo === null) ||
              number === null ||
              durationId === null ||
              durationId === undefined ||
              prescriptionItemNoValidationMsg !== null ||
              numberValidationMsg !== null ||
              prescriptionItemNoValidationMsg !== null
            }
            onClick={() => addQuantityDetail()}
          />
        </Box>

        {medicationCardNumberLimitMsg && (
          <Box>
            <Typography sx={classes.formLabel}>
              {medicationCardNumberLimitMsg}
            </Typography>
          </Box>
        )}

        {quantityDetails.length !== 0 && (
          <Box mt={4}>
            <Typography sx={classes.title}>Added medication(s)</Typography>
            <Box mt={2}>
              {quantityDetails.map((detail, index) => (
                <MedicationCard
                  key={index}
                  index={index}
                  medicationName={detail.prescriptionItemNo}
                  number={detail.number}
                  uom={detail.UOMDuration}
                  removeQuantityDetail={removeQuantityDetail}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SelectedMedicationOnly;
