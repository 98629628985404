import { Theme } from "@mui/material";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = (theme: Theme) => {
  return {
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    contentBox: { padding: theme.spacing(2) },
    buttonBox: {
      px: 2,
      py: 4,
    },
    title: {
      typography: "header4_semibold",
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(1.5),
    },
    body: {
      typography: theme.typography.body2_regular,
    },
    alternativeText: {
      marginTop: theme.spacing(2),
      typography: theme.typography.body2_regular,
    },
    list: {
      listStyleType: "disc",
      listStylePosition: "outside",
      ml: 5,
    },
    listItem: {
      display: "list-item",
      typography: theme.typography.body2_regular,
      pl: 0,
    },
    listItemText: {
      ml: "calc(-0.5em + 10px)",
    },
    boxBG: {
      backgroundColor: "#F6F6F6",
      padding: theme.spacing(2.5, 2, 2.5, 2),
    },
  } satisfies SxObjType;
};

export { sxStyles };
