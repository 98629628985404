import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";

import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { RootState } from "lib/redux/root/redux.types";

import ConfirmationBundledLinkedApptPage from "./ConfirmationBundledLinkedApptPage";
import { handleErrorModal } from "lib/redux/notifications";
import { createLinkedAppointment } from "api/appointment/CreateLinkedAppointment/createLinkedAppointment";

const selectSlotDateTime = (state: RootState): Date => {
  if (state.appointments.create.selectedAvailableSlotDateTime) {
    const slotDateTime = getDateFromISOString(
      state.appointments.create.selectedAvailableSlotDateTime,
    );

    if (slotDateTime) {
      return slotDateTime;
    }
  }

  return new Date();
};

const mapStateToProps = (state: RootState) => {
  return {
    memberIdentifier: state.user.memberIdentifier,
    relationType: state.user.relationType,
    institution: state.appointments.create?.selectedInstitution || "",
    service: state.appointments.create?.selectedService || "",
    slotDateTime: selectSlotDateTime(state),
    slotDetails: state.appointments.create.createLinkedAppointment.newSlotList,

    slotId: state.appointments.create?.selectedAvailableSlotId,
    extensionData: state.appointments.create.selectedAvailableSlotExtensionData,
    targetSystem: state.appointments.appointmentMeta.targetSystem,
    institutionCode: state.appointments.appointmentMeta.institutionCode,
    appointmentId: state.appointments.appointmentMeta.appointmentId,
    location: state.appointments.appointmentMeta.location,
    appointmentType: state.appointments.appointmentMeta.appointmentType,
    calendarTitle: state.appointments.appointmentMeta.calendarTitle,
    calendarLocation: state.appointments.appointmentMeta.calendarLocation,
    zoneInfo: state.appointments.appointmentMeta.zoneInfo,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onBook: async (
      memberIdentifier: string | null,
      institutionCode: string | null,
      slotIdList: (string | null)[],
    ) => {
      return await createLinkedAppointment(
        memberIdentifier,
        institutionCode,
        slotIdList,
      );
    },
    handleErrorModal: (
      open: boolean,
      message: string | null,
      buttonText?: string | null,
    ) => {
      dispatch(handleErrorModal({ open, message, buttonText }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmationBundledLinkedApptPage);
