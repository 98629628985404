import { OverlayColorType } from "./ImageUploader.types";
import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = (colorType: OverlayColorType) => {
  const overlayZIndex = 10; // any number larger than default
  const borderRadius = theme.spacing(1);

  // --- Set Color Tone of the Overlays
  let colorTone: string | null = null;
  if (colorType === "DEFAULT") {
    colorTone = theme.palette.primary.main;
  } else if (colorType === "ALTERNATE") {
    colorTone = theme.palette.grey[400];
  } else {
    colorTone = theme.palette.primary.main;
  }

  return {
    clearButton: {
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 100, // so it floats above the general upload button click area
      padding: theme.spacing(1),
      // "&.MuiSvgIcon-root": {
      //   color: theme.palette.warning.main,
      //   fontSize: size.FONTSIZE_24,
      //   borderRadius: "50%",
      //   backgroundColor: theme.palette.common.white,
      // },
    },
    uploadButton: {
      display: "flex",
      flexDirection: "column",
      height: "150px", // fixed, constant, height for all uploaders
      width: "100%",
      border: `solid ${colorTone} 1px`,
      backgroundColor: theme.palette.common.white,
      borderRadius,
      ":hover": {
        backgroundColor: theme.palette.common.white,
      },
      "&.Mui-disabled": {
        backgroundColor: theme.palette.common.white,
        border: `solid ${theme.palette.grey[100]} 1px`,
      },
      "&.MuiButton-label": {
        display: "flex",
        flexDirection: "column",
      },
    },
    uploadButtonDisabled: {
      border: `solid ${theme.palette.grey[100]} 1px`,
    },

    overlayCameraIcon: {
      color: colorTone,
      zIndex: overlayZIndex,
    },
    overlayErrorCameraIcon: {
      color: colorTone,
      zIndex: overlayZIndex,
    },

    overlayText: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
      textAlign: "center",
      marginTop: theme.spacing(1),
      textTransform: "none",
      zIndex: overlayZIndex,
    },
    errorText: {
      fontSize: size.FONTSIZE_14,
      color: theme.palette.error.light,
      textTransform: "none",
    },
    uploadedImage: {
      // stretches to fit the encompassing button
      position: "absolute",
      maxHeight: "100%",
      maxWidth: "100%",
      borderRadius,
      zIndex: 11,
    },
    loadingIcon: {
      width: "30%",
    },
  } satisfies SxObjType;
};

export { sxStyles };
