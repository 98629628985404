import { SxObjType } from "lib/theme/types/SxObjType.type";
import { Theme } from "@mui/material";

const sxStyles = (theme: Theme) => {
  return {
    shouldSubmitRequestDisclaimerText: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
      margin: theme.spacing(0, 1),
      "& a": {
        color: theme.palette.primary.main,
      },
    },
    cardTopContainer: {
      display: "flex",
      position: "relative",
    },
    addToCalendarContainer: {
      position: "absolute",
      top: 0,
      right: 0,
      p: theme.spacing(2, 1, 0.5),
    },
    actionMsg: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
      margin: theme.spacing(0, 1),
    },

    reminderText: {
      m: 1,
      background: theme.palette.info.light,
    },
  } satisfies SxObjType;
};

export { sxStyles };
