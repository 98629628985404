import { Theme } from "@mui/material";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = (theme: Theme) => {
  return {
    mainContainer: {
      // pt: 3,
      marginLeft: 2,
      marginRight: 2,
      // marginTop: 3,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    preferredAddressText: {
      typography: "body1_regular",
      color: theme.palette.grey[400],
    },
    subtitle: {
      color: theme.palette.text.primary,
      typography: "header4_semibold",
    },

    postalCodeContainer: {
      display: "flex",
      marginTop: 3,
    },
    postalCodeInputContainer: {
      width: "50%",
      marginRight: 2,
    },
    getAddressButton: {
      flex: 1,
    },

    streetContainer: {
      marginTop: 4,
    },
    blockUnitMainContainer: {
      marginTop: 4,
      display: "flex",
    },
    blockUnitContainer: {
      width: "50%",
      marginRight: 1.25,
    },
    dateDisclaimer: {
      typography: "body2_regular",
      mb: 2,
      color: theme.palette.grey[400],
    },
    datepickerContainer: {
      flexGrow: 1,
      marginTop: 3,
      "& #helper-text": {
        padding: 0.5,
      },
    },
    datePickerBox: {
      display: "flex",
      alignItems: "center",
    },
    saveButton: {
      mt: 1,
      mb: 4,
    },
  } satisfies SxObjType;
};
export { sxStyles };
