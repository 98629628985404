import React from "react";
import { CollectionRadioProps } from "./CollectionRadio.types";
import { Box, FormControlLabel, Radio } from "@mui/material";
import { sxStyles } from "./CollectionRadio.styles";
import IMAGES from "lib/assets/images";

const CollectionRadio = ({ value, label }: CollectionRadioProps) => {
  const classes = sxStyles();

  if (value && label) {
    // a radio button must always have a value and display label
    return (
      <FormControlLabel
        value={value}
        control={
          <Radio
            icon={
              <Box
                component={"img"}
                src={IMAGES.general.RadioButtonUncheckedIcon}
                sx={classes.radio}
              />
            }
            checkedIcon={
              <Box
                component={"img"}
                src={IMAGES.general.RadioButtonCheckedIcon}
                sx={classes.radio}
              />
            }
          />
        }
        label={label}
        sx={classes.listItem}
      />
    );
  } else {
    return <></>;
  }
};

export default CollectionRadio;
