import React from "react";
import { Box, Typography } from "@mui/material";
import { sxStyles } from "./QuantityBody.styles";
import CommonRadioButton from "lib/components/formInputs/CommonRadioButton/CommonRadioButton";
import { QuantityBodyProps } from "./QuantityBody.types";
import { IterableElement } from "lib/data/data.types";
import AllBalanceForSelectedDuration from "../AllBalanceForSelectedDuration/AllBalanceForSelectedDuration";
import SelectedMedicationOnly from "../SelectedMedicationOnly/SelectedMedicationOnly";

const quantityChoice: IterableElement[] = [
  { id: 1, text: "All my balance" },
  { id: 2, text: "All my balance for a selected duration" },
  { id: 3, text: "Selected medication only" },
];

const QuantityBody = ({
  durationChoice,
  uomChoice,
  medicationNameList,
  selectedQuantiyTypeId,
  setSelectedQuantiyTypeId,
  number,
  setNumber,
  UOMDurationId,
  setUOMDurationId,
  prescriptionItemNo,
  prescriptionItemNoFreeSolo,
  setPrescriptionItemNo,
  setPrescriptionItemNoFreeSolo,
  quantityDetails,
  addQuantityDetail,
  removeQuantityDetail,
  prescriptionItemNoValidationMsg,
  numberValidationMsg,
  medicationCardNumberLimitMsg,
}: QuantityBodyProps) => {
  const classes = sxStyles();

  return (
    <Box mt={3} ml={2} mr={2}>
      <Typography sx={classes.title}>
        How much medication would you like to refill?
      </Typography>

      <Box mt={1}>
        <CommonRadioButton
          radioButtonSelectionList={quantityChoice}
          selectedRadioButton={
            selectedQuantiyTypeId ? selectedQuantiyTypeId.toString() : null
          }
          handleRadioButtonChange={(selected) =>
            setSelectedQuantiyTypeId(parseInt(selected || ""))
          }
        ></CommonRadioButton>
      </Box>

      <Box mt={2}>
        {selectedQuantiyTypeId === 2 ? (
          <AllBalanceForSelectedDuration
            number={number}
            durationId={UOMDurationId}
            handleNumberChange={setNumber}
            handleDurationIdChange={setUOMDurationId}
            durationChoice={durationChoice}
            numberValidationMsg={numberValidationMsg}
          />
        ) : selectedQuantiyTypeId === 3 ? (
          <SelectedMedicationOnly
            medicationNameList={medicationNameList}
            durationList={durationChoice}
            uomList={uomChoice}
            medicationName={prescriptionItemNo}
            medicationNameFreeSolo={prescriptionItemNoFreeSolo}
            number={number}
            durationId={UOMDurationId}
            handleMedicationNameChange={setPrescriptionItemNo}
            handleFreeSoloMedicationNameChange={setPrescriptionItemNoFreeSolo}
            handleNumberChange={setNumber}
            handleDurationIdChange={setUOMDurationId}
            addQuantityDetail={addQuantityDetail}
            removeQuantityDetail={removeQuantityDetail}
            quantityDetails={quantityDetails}
            prescriptionItemNoValidationMsg={prescriptionItemNoValidationMsg}
            numberValidationMsg={numberValidationMsg}
            medicationCardNumberLimitMsg={medicationCardNumberLimitMsg}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default QuantityBody;
