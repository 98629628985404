import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ViewFormLabel } from "api/mrrp/GetAllByPlatform/getAllByPlatform.fromApi.types";
import { ViewHospital } from "api/mrrp/GetHospital/getHospital.fromApi.types";
import { ViewPaymentStatusInfo } from "api/mrrp/GetPaymentStatusInfo/getPaymentStatusInfo.fromApi.types";
import { ViewSubmitPayment } from "api/mrrp/SubmitPayment/submitPayment.fromApi.types";
import { ViewReportHistory } from "api/mrrp/GetReportHistory/getReportHistory.fromApi.types";
import {
  ViewDepartment,
  ViewMOD,
  ViewPatientRelationship,
  ViewPurpose,
} from "api/mrrp/GetReportOptions/getReportOptions.fromApi.types";
import { initialState } from "./initialState";
import {
  PaymentMode,
  MrrpParticularsState,
  MrrpReportDetailState,
  MrrpCollectionState,
  ApiEnum,
  MrrpPersist,
} from "./mrrp.types";
import { ViewMN } from "api/mrrp/CreateMedicalReport/createMedicalReport.fromApi.types";
import { RootState } from "lib/redux/root/redux.types";

/**
 * The slice reducer for the part of state that represents the user's mrrp information.
 */
const mrrpSlice = createSlice({
  name: "mrrp",
  initialState,
  reducers: {
    clearState(state) {
      const patientName = state.particulars.patientName;
      const patientNric = state.particulars.patientNric;
      state.landing = initialState.landing;
      state.hospital = initialState.hospital;
      state.paymentMode = initialState.paymentMode;
      state.particulars = {
        ...initialState.particulars,
        patientName,
        patientNric,
      };
      state.reportDetail = initialState.reportDetail;
      state.collection = initialState.collection;
      state.formLabel = initialState.formLabel;
      state.reportHistory = initialState.reportHistory;
      state.reportOptions = initialState.reportOptions;
      state.receiptPageLanding = initialState.receiptPageLanding;
    },
    setExpandedPastReportListFlag(state, action: PayloadAction<boolean>) {
      state.landing.expandedPastReportListFlag = action.payload;
    },
    setPatient(
      state,
      action: PayloadAction<{
        name: string | null;
        nric: string | null;
      }>,
    ) {
      state.particulars.patientName = action.payload.name;
      state.particulars.patientNric = action.payload.nric;
    },

    // getHospital and select one hospital to make new request
    setHospitalIsLoading(state, action: PayloadAction<boolean>) {
      state.hospital.isLoading = action.payload;
    },
    setHospitalHasErrored(state, action: PayloadAction<boolean | null>) {
      state.hospital.hasErrored = action.payload;
    },
    setHospitalErrorMessage(state, action: PayloadAction<string | null>) {
      state.hospital.errorMessage = action.payload;
    },
    resetGetHospitalApiStatus(state) {
      state.hospital.isLoading = initialState.hospital.isLoading;
      state.hospital.hasErrored = initialState.hospital.hasErrored;
      state.hospital.errorMessage = initialState.hospital.errorMessage;
    },
    setSelectedHospital(
      state,
      action: PayloadAction<{
        hospitalCode: string | null;
        hospitalName: string | null;
      }>,
    ) {
      state.hospital.selectedHospitalCode = action.payload.hospitalCode;
      state.hospital.selectedHospitalName = action.payload.hospitalName;
    },
    setPaymentMode(state, action: PayloadAction<PaymentMode | null>) {
      state.paymentMode = action.payload;
    },
    setHospitals(state, action: PayloadAction<ViewHospital[]>) {
      state.hospital.allHospitals = action.payload;
    },

    setParticulars(state, action: PayloadAction<MrrpParticularsState>) {
      state.particulars = action.payload;
    },
    setReportDetails(state, action: PayloadAction<MrrpReportDetailState>) {
      state.reportDetail = action.payload;
    },
    setCollection(state, action: PayloadAction<MrrpCollectionState>) {
      state.collection = action.payload;
    },

    // getAllByPlatform (get form labels)
    setFormLabelIsLoading(state, action: PayloadAction<boolean>) {
      state.formLabel.apiStatus.isLoading = action.payload;
    },
    setFormLabelErrorStatus(
      state,
      action: PayloadAction<{
        hasErrored: boolean | null;
        errorMessage: string | null;
      }>,
    ) {
      state.formLabel.apiStatus.hasErrored = action.payload.hasErrored;
      state.formLabel.apiStatus.errorMessage = action.payload.errorMessage;
    },
    resetFormLableApiStatus(state) {
      state.formLabel.apiStatus.isLoading =
        initialState.formLabel.apiStatus.isLoading;
      state.formLabel.apiStatus.hasErrored =
        initialState.formLabel.apiStatus.hasErrored;
      state.formLabel.apiStatus.errorMessage =
        initialState.formLabel.apiStatus.errorMessage;
    },
    setFormLabels(state, action: PayloadAction<ViewFormLabel[]>) {
      state.formLabel.labels = action.payload;
    },

    // getReportHistory and select one report to view detail
    setReportHistoryIsLoading(state, action: PayloadAction<boolean>) {
      state.reportHistory.isLoading = action.payload;
    },
    setReportHistoryHasErrored(state, action: PayloadAction<boolean | null>) {
      state.reportHistory.hasErrored = action.payload;
    },
    setReportHistoryErrorMessage(state, action: PayloadAction<string | null>) {
      state.reportHistory.errorMessage = action.payload;
    },
    setSelectedPastReportIndex(state, action: PayloadAction<number | null>) {
      state.reportHistory.selectedPastReportIndex = action.payload;
    },
    setReportHistory(state, action: PayloadAction<ViewReportHistory[]>) {
      state.reportHistory.allPastReports = action.payload;
    },

    // getReportOptions
    setReportOptionsIsLoading(state, action: PayloadAction<boolean>) {
      state.reportOptions.isLoading = action.payload;
    },
    setReportOptionsHasErrored(state, action: PayloadAction<boolean | null>) {
      state.reportOptions.hasErrored = action.payload;
    },
    setReportOptionsErrorMessage(state, action: PayloadAction<string | null>) {
      state.reportOptions.errorMessage = action.payload;
    },
    resetGetReportOptionsApiStatus(state) {
      state.reportOptions.isLoading = initialState.reportOptions.isLoading;
      state.reportOptions.hasErrored = initialState.reportOptions.hasErrored;
      state.reportOptions.errorMessage =
        initialState.reportOptions.errorMessage;
    },
    setPatientRelationships(
      state,
      action: PayloadAction<ViewPatientRelationship[]>,
    ) {
      state.reportOptions.patientRelationships = action.payload;
    },
    setDepartments(state, action: PayloadAction<ViewDepartment[]>) {
      state.reportOptions.departments = action.payload;
    },
    setPurposes(state, action: PayloadAction<ViewPurpose[]>) {
      state.reportOptions.purposes = action.payload;
    },
    setMODs(state, action: PayloadAction<ViewMOD[]>) {
      state.reportOptions.deliveryModes = action.payload;
    },
    setEmptyReportOptions(state) {
      state.reportOptions.patientRelationships = [];
      state.reportOptions.departments = [];
      state.reportOptions.purposes = [];
      state.reportOptions.deliveryModes = [];
    },

    // submit payment
    setIsLoadingByApi(
      state,
      action: PayloadAction<{ api: ApiEnum; isLoading: boolean }>,
    ) {
      const apiName = action.payload.api;
      state.apiStatus[apiName].isLoading = action.payload.isLoading;
    },
    setErrorStatusByApi(
      state,
      action: PayloadAction<{
        api: ApiEnum;
        hasErrored: boolean | null;
        errorMessage: string | null;
      }>,
    ) {
      const apiName = action.payload.api;
      state.apiStatus[apiName].hasErrored = action.payload.hasErrored;
      state.apiStatus[apiName].errorMessage = action.payload.errorMessage;
    },
    resetApiStatus(state) {
      state.apiStatus = initialState.apiStatus;
    },
    setCreateMedicalReportResult(state, action: PayloadAction<ViewMN | null>) {
      if (action.payload != null) {
        state.payment.createMedicalReport = action.payload;
      }
    },
    setSubmitPaymentResult(
      state,
      action: PayloadAction<ViewSubmitPayment | null>,
    ) {
      if (action.payload != null) {
        state.payment.submitPayment = action.payload;
      }
    },
    setMrrpPerist(state, action: PayloadAction<MrrpPersist>) {
      state.mrrpPersist = action.payload;
    },
    setGetPaymentStatusInfoResult(
      state,
      action: PayloadAction<ViewPaymentStatusInfo | null>,
    ) {
      if (action.payload != null) {
        state.payment.paymentStatusInfo = action.payload;
      }
    },
    setMedicalReportReceiptPageLanding(state, action: PayloadAction<boolean>) {
      state.receiptPageLanding = action.payload;
    },
    setMRRPAppRating(state, action: PayloadAction<boolean>) {
      state.appRating = action.payload;
    },
  },
});

export const {
  clearState,
  setExpandedPastReportListFlag,
  setPatient,
  setHospitalIsLoading,
  setHospitalHasErrored,
  setHospitalErrorMessage,
  resetGetHospitalApiStatus,
  setSelectedHospital,
  setPaymentMode,
  setHospitals,
  setParticulars,
  setReportDetails,
  setCollection,
  setFormLabelIsLoading,
  setFormLabelErrorStatus,
  resetFormLableApiStatus,
  setFormLabels,
  setReportHistoryIsLoading,
  setReportHistoryHasErrored,
  setReportHistoryErrorMessage,
  setSelectedPastReportIndex,
  setReportHistory,
  setReportOptionsIsLoading,
  setReportOptionsHasErrored,
  setReportOptionsErrorMessage,
  resetGetReportOptionsApiStatus,
  setPatientRelationships,
  setDepartments,
  setPurposes,
  setMODs,
  setEmptyReportOptions,
  // TODO: use below 3 dispatch for all api
  setIsLoadingByApi,
  setErrorStatusByApi,
  resetApiStatus,
  setCreateMedicalReportResult,
  setSubmitPaymentResult,
  setMrrpPerist,
  setGetPaymentStatusInfoResult,
  setMedicalReportReceiptPageLanding,
  setMRRPAppRating,
} = mrrpSlice.actions;

export const selectMrrp = (state: RootState) => state.mrrp;

export default mrrpSlice.reducer;
