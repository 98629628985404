import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    radio: {
      color: theme.palette.primary.main,
    },
    listItem: {
      marginRight: 0,
      "& .MuiSvgIcon-root": {
        fontSize: size.FONTSIZE_24,
      },
      "& .MuiFormControlLabel-label": {
        color: theme.palette.grey[400],
        fontSize: size.FONTSIZE_14,
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
