import { connect } from "react-redux";
import { RootDispatch, RootState } from "lib/redux/root/redux.types";
import MrrpPage from "./MrrpPage";
import {
  setExpandedPastReportListFlag,
  clearState,
  setReportHistory,
} from "ui/mrrp/ducks";
import { fetchReportHistory } from "ui/mrrp/ducks";

const mapStateToProps = (state: RootState) => {
  return {
    isLoadingReportHistory: state.mrrp.reportHistory.isLoading,
    hasErroredReportHistory: state.mrrp.reportHistory.hasErrored,
    errorMessageReportHistory: state.mrrp.reportHistory.errorMessage,
    allPastReports: state.mrrp.reportHistory.allPastReports,
    expandedPastReportListFlag: state.mrrp.landing.expandedPastReportListFlag,
  };
};

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    setExpandedPastReportListFlag: (flag: boolean) => {
      dispatch(setExpandedPastReportListFlag(flag));
    },
    clearPastReports: () => {
      dispatch(setReportHistory([]));
    },
    callGetReportHistory: () => {
      dispatch(clearState());
      dispatch(fetchReportHistory());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MrrpPage);
