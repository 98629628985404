import { ListItemButton, Box, Typography } from "@mui/material";
import { SelectionListItemProps } from "./SelectionListItem.types";
import { sxStyles } from "./SelectionListItem.styles";
import IMAGES from "lib/assets/images";

const SelectionListItem = ({ itemName, onClick }: SelectionListItemProps) => {
  const classes = sxStyles();
  return (
    <ListItemButton sx={classes.listItem} onMouseDown={onClick}>
      <Box sx={classes.button}>
        <Typography sx={classes.listTitle}>{itemName}</Typography>
        <Box component={"img"} src={IMAGES.general.ArrowForward} />
      </Box>
    </ListItemButton>
  );
};

export default SelectionListItem;
