import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { sxStyles } from "./CommonRadioButton.styles";
import { CommonRadioButtonProps } from "./CommonRadioButton.types";
import IMAGES from "lib/assets/images";

const CommonRadioButton = ({
  radioButtonSelectionList,
  selectedRadioButton,
  handleRadioButtonChange,
}: CommonRadioButtonProps) => {
  const theme = useTheme();
  const classes = sxStyles(theme);

  return (
    <RadioGroup
      value={selectedRadioButton}
      onChange={(event) => {
        handleRadioButtonChange(event.target.value);
      }}
    >
      {radioButtonSelectionList?.map((choice) => {
        return (
          <Box key={choice.id}>
            <FormControlLabel
              sx={classes.radioButtonItem}
              key={choice.id}
              value={String(choice.id)}
              label={choice.text}
              control={
                <Radio
                  icon={
                    <Box
                      component={"img"}
                      src={IMAGES.general.RadioButtonUncheckedIcon}
                      sx={classes.radioIcon}
                    />
                  }
                  checkedIcon={
                    <Box
                      component={"img"}
                      src={IMAGES.general.RadioButtonCheckedIcon}
                      sx={classes.radioIcon}
                    />
                  }
                />
              }
            />
            {choice.disclaimerText && (
              <Typography sx={classes.disclaimerText}>
                {choice.disclaimerText}
              </Typography>
            )}
          </Box>
        );
      })}
    </RadioGroup>
  );
};

export default CommonRadioButton;
