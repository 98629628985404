import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { GET_KIOSK_QUESTIONNAIRE_URL } from "../../resources/configs/URL";
import { formatGetKioskQuestionnaireErrorMessage } from "../../resources/messages/ErrorMessage";
import { GetKioskQuestionnairePayload } from "./getKioskQuestionnaire.fromApi.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";

/**
 * Retrieves the kiosk questionnaire displayed during the appointment registration flow,
 * just before clicking to register for an appointment.
 *
 * POST
 * Endpoint: /appointment/GetKioskQuestionnaire
 *
 * @param {string | null} memberIdentifier  Member Identifier of the person we are making this request for
 * @param {string | null} regInstitutionCode  Reg Code of the institution of the service tied to this appointment
 * @param {string | null} targetSystem  Source system of this institution
 * @param {string | null} visitType visitType of this appointment
 *
 * @returns {GetKioskQuestionnairePayload} Response payload from the remote API
 */
const getKioskQuestionnaire = async (
  memberIdentifier: string | null,
  regInstitutionCode: string | null,
  institutionCode: string | null,
  targetSystem: string | null,
  clinicCode: string | null,
  visitType: string | null,
): Promise<GetKioskQuestionnairePayload> => {
  try {
    const response = await axios.post<GetKioskQuestionnairePayload>(
      GET_KIOSK_QUESTIONNAIRE_URL(),
      createRequestPayload(
        memberIdentifier,
        regInstitutionCode,
        institutionCode,
        targetSystem,
        clinicCode,
        visitType,
      ),
      getRequestOptions(),
    );
    return parseResponse(response, GET_KIOSK_QUESTIONNAIRE_URL());
  } catch (error) {
    const msg = formatGetKioskQuestionnaireErrorMessage(
      GET_KIOSK_QUESTIONNAIRE_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  memberIdentifier: string | null,
  regInstitutionCode: string | null,
  institutionCode: string | null,
  targetSystem: string | null,
  clinicCode: string | null,
  visitType: string | null,
) => {
  return {
    MemberIdentifier: memberIdentifier,
    RegInstitutionCode: regInstitutionCode,
    InstitutionCode: institutionCode,
    TargetSystem: targetSystem,
    ClinicCode: clinicCode,
    VisitType: visitType,
  };
};

export { getKioskQuestionnaire };
