import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    layout: {
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(1, 2, 1),
    },
    currentDateAndTimeLabel: {
      lineHeight: 1,
      color: theme.palette.error.main,
      typography: theme.typography.body2_regular,
    },
    currentDateAndTimeText: {
      paddingTop: theme.spacing(1),
      color: theme.palette.error.main,
      typography: theme.typography.body2_semibold,
    },
    currentDateTimeBox: {
      margin: theme.spacing(2, 2, 1, 2),
    },
  } satisfies SxObjType;
};
export { sxStyles };
