import { connect } from "react-redux";
import { MedRefillState } from "ui/medrefill/ducks/medrefill.types";
import {
  updateState,
  updateReorderFlag,
  updateRequestType,
  setViewPastDetail,
  setPastRequestMedicationImages,
  setSystemSettingsHasErrored,
  setMedicationsHasErrored,
  setCollectionPaymentDetailsHasErrored,
  setDocumentByClusterHasErrored,
  setClinicsHasErrored,
  setViewPastDetailsHasErrored,
  setPastRequestMedicationImagesHasErrored,
} from "ui/medrefill/ducks/medrefillSlice";
import { RootState, RootDispatch } from "lib/redux/root/redux.types";
import {
  fetchMedicationSummaryDetail,
  fetchSystemSettings,
  fetchMedications,
  fetchCollectionPaymentDetails,
  fetchMedicationImages,
  fetchDocumentByCluster,
  fetchClinics,
} from "ui/medrefill/ducks/thunks";
import TopupViewPastRequestDetailPage from "./TopupViewPastRequestDetailPage";
import { mapPastRequestTopupDetailToMedrefillState } from "lib/util/MedrefillMappingUtil/mapPastRequestTopupDetailToMedrefillState";
import { initialState as medRefillInitialState } from "ui/medrefill/ducks";

const mapStateToProps = (state: RootState) => {
  return {
    isLoadingPastDetail: state.medRefill.viewPastRequest.isLoading,

    hasErroredViewPastRequest: state.medRefill.viewPastRequest.hasErrored,
    errorMessageViewPastRequest: state.medRefill.viewPastRequest.errorMessage,

    isLoadingImages: state.medRefill.pastRequestMedicationImages.isLoading,

    hasErroredImages: state.medRefill.pastRequestMedicationImages.hasErrored,
    errorMessageImages:
      state.medRefill.pastRequestMedicationImages.errorMessage,

    pastRequstHistory: mapPastRequestTopupDetailToMedrefillState(
      state.medRefill.viewPastRequest.detail,
      state.medRefill.pastRequestMedicationImages.images || [],
    ),

    isLoadingFormData:
      state.medRefill.systemSettings.isLoading ||
      state.medRefill.medications.isLoading ||
      state.medRefill.collectionAndPayments.isLoading ||
      state.medRefill.documentByCluster.isLoading ||
      state.medRefill.clinics.isLoading,

    hasErroredSystemSettings: state.medRefill.systemSettings.hasErrored,
    errorMessageSystemSettings: state.medRefill.systemSettings.errorMessage,

    hasErroredMedications: state.medRefill.medications.hasErrored,
    errorMessageMedications: state.medRefill.medications.errorMessage,

    hasErroredCollectionPaymentDetails:
      state.medRefill.collectionAndPayments.hasErrored,
    errorMessageCollectionPaymentDetails:
      state.medRefill.collectionAndPayments.errorMessage,

    hasErroredDocByCluster: state.medRefill.documentByCluster.hasErrored,
    errorMessageDocByCluster: state.medRefill.documentByCluster.errorMessage,

    hasErroredClinics: state.medRefill.clinics.hasErrored,
    errorMessageClinics: state.medRefill.clinics.errorMessage,

    canReOrderMedication:
      state.medRefill.viewPastRequest.detail?.CanReOrderMedication,
  };
};

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    pushPastRequestState: (pastRequestState: MedRefillState) => {
      dispatch(
        updateState({
          ...pastRequestState,
          collection: {
            ...pastRequestState.collection,
            date: medRefillInitialState.collection.date,
            timeslot: medRefillInitialState.collection.timeslot,
          },
        }),
      );
    },
    onLoad: () => {
      dispatch(setViewPastDetail(null));
      dispatch(setPastRequestMedicationImages(null));
      dispatch(setViewPastDetailsHasErrored(null));
      dispatch(setPastRequestMedicationImagesHasErrored(null));
      dispatch(setSystemSettingsHasErrored(null));
      dispatch(setMedicationsHasErrored(null));
      dispatch(setCollectionPaymentDetailsHasErrored(null));
      dispatch(setDocumentByClusterHasErrored(null));
      dispatch(setClinicsHasErrored(null));
      dispatch(fetchMedicationSummaryDetail());
      dispatch(fetchMedicationImages());
    },
    onReorder: (facilityId: number, cluster: string) => {
      dispatch(updateRequestType("TOPUP"));
      dispatch(updateReorderFlag(true));
      dispatch(fetchSystemSettings(cluster));
      dispatch(fetchMedications(cluster));
      dispatch(fetchCollectionPaymentDetails(cluster, facilityId));
      dispatch(fetchDocumentByCluster(cluster));
      dispatch(fetchClinics(facilityId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopupViewPastRequestDetailPage);
