import { color } from "lib/theme/color";
import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    cardPanel: {
      boxShadow: "0px 0px 6px " + color.RGBA_52,
      margin: theme.spacing(1.5, 0, 1),
    },
    cardDetailWrapper: {
      padding: 0,
      "&:last-child": {
        // paddingBottom: theme.spacing(2.5),
      },
    },
    cardHeader: {
      display: "flex",
      background: theme.palette.secondary.main,
      padding: theme.spacing(1, 1.5),
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      alignItems: "center",
    },
    cardHeaderLeft: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      width: "100%",
    },
    cardHeaderRight: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      width: "30px",
      textAlign: "right",
    },
    cardDetails: {
      minHeight: "100%",
      wordBreak: "break-word",
      padding: theme.spacing(0.5),
      display: "flex",
      margin: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
      alignItems: "start",
    },

    cardLeft: {
      width: "48%",
      marginRight: "2%",
    },
    cardRight: {
      textAlign: "left",
      color: theme.palette.common.black,
      typography: theme.typography.body2_semibold,
      width: "48%",
      marginLeft: "2%",
    },
    cardTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.caption1_semibold,
      paddingBottom: theme.spacing(0.75),
    },
    cardLabel: {
      color: theme.palette.grey[600],
      typography: theme.typography.caption1_semibold,
    },

    cardAmount: {
      color: theme.palette.warning.main,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      width: "100%",
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      color: theme.palette.primary.main,
      padding: 0,
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
      "& .MuiSvgIcon-root": {
        fontSize: 28,
      },
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    cardSummaryDetailWrapper: {
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
      padding: theme.spacing(0, 0.5),
      margin: theme.spacing(1, 1),
      "&:last-child": {
        borderBottom: 0,
      },
    },
    cardSummaryDetails: {
      wordBreak: "break-word",
      padding: theme.spacing(0, 0.5),
      display: "flex",
      margin: theme.spacing(0.75, 1),
    },
  } satisfies SxObjType;
};

export { sxStyles };
