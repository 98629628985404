import { ServicesPageHeaderProps } from "./ServicesPageHeader.types";
import SelectionListingHeader from "../../../../common/selection-listing/SelectionListingHeader/SelectionListingHeader";
import SelectionListingHeaderText from "../../../../common/selection-listing/SelectionListingHeaderText/SelectionListingHeaderText";

const ServicesPageHeader = ({ location }: ServicesPageHeaderProps) => {
  return (
    <SelectionListingHeader>
      <SelectionListingHeaderText label="Location" text={location} />
    </SelectionListingHeader>
  );
};

export default ServicesPageHeader;
