import { RegisteredAppointmentCardProps } from "./RegisteredAppointmentCard.types";
import { sxStyles } from "./RegisteredAppointmentCard.styles";
import { CardHeader, CardContent, Box, Typography } from "@mui/material";
import {
  formatDate,
  formatOptions,
} from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

const RegisteredAppointmentCard = ({
  appointment,
}: RegisteredAppointmentCardProps) => {
  const classes = sxStyles();

  const {
    datetime,
    institutionName,
    location,
    serviceDisplayName,
    clinician,
    specialty,
    visitType,
  } = appointment;

  const specialtyOrServiceDisplayName = specialty || serviceDisplayName;

  return (
    <>
      <Box display="flex" position="relative" sx={classes.card}>
        <CardHeader
          avatar={null}
          title={
            datetime
              ? formatDate(formatDateToISO(datetime), formatOptions.monthYear)
              : null
          }
          subheader={
            datetime
              ? formatDate(formatDateToISO(datetime), formatOptions.day)
              : null
          }
          action={
            datetime
              ? formatDate(
                  formatDateToISO(datetime),
                  formatOptions.dayOfWeek,
                ).toUpperCase()
              : null
          }
          sx={classes.cardHeader}
        />

        <CardContent sx={classes.cardDetailWrapper}>
          <Box sx={classes.cardDetails}>
            <Typography sx={classes.cardTitle}>{institutionName}</Typography>
            <Typography sx={classes.cardContent}>
              {datetime
                ? formatDate(
                    formatDateToISO(datetime),
                    formatOptions.timeWithPeriod,
                  )
                : null}
            </Typography>
            {specialtyOrServiceDisplayName && (
              <Typography sx={classes.cardContent} component="h5">
                {specialtyOrServiceDisplayName}
              </Typography>
            )}
            {clinician && (
              <Typography sx={classes.cardContent}>{clinician}</Typography>
            )}
            {location && (
              <Typography sx={classes.cardContent}>{location}</Typography>
            )}
            {visitType && (
              <Typography sx={classes.cardContent}>{visitType}</Typography>
            )}
          </Box>
        </CardContent>
      </Box>
    </>
  );
};

export default RegisteredAppointmentCard;
