import { useEffect } from "react";
import { Box, CircularProgress, Typography, Button } from "@mui/material";
import { sxStyles } from "./PaidBills.styles";
import ProfileHeaderContainer from "../common/ProfileHeader/ProfileHeaderContainer";
import PaidBillCard from "./PaidBillCard/PaidBillCard";
import IMAGES from "lib/assets/images";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import AnnouncementsPanelSkeleton from "lib/components/notice/AnnouncementPanel/AnnouncementPanelSkeleton";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";

// Hooks
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { setExpandedPaidBillsListFlag, setPaidBills } from "ui/payment/ducks";
import { fetchPaidBills } from "ui/payment/ducks";
import { PAYMENT_BILL_PAIDBILLS_TEXT_KEY } from "api/shared/GetDocumentByType/getDocumentByType.mapper";
import {
  selectPayments,
  selectPaymentsPaidBills,
} from "ui/payment/ducks/selectors";
import { selectUser } from "lib/redux/user/selectors";

const DEFAULT_NUMBER_OF_PAID_BILLS_TO_SHOW = 4;

const PaidBills = () => {
  const classes = sxStyles();
  const dispatch = useAppDispatch();

  const isLoadingPaidBills = useAppSelector(selectPaymentsPaidBills).isLoading;
  const hasErroredPaidBills = useAppSelector(
    selectPaymentsPaidBills,
  ).hasErrored;
  const errorMessagePaidBills = useAppSelector(
    selectPaymentsPaidBills,
  ).errorMessage;
  const allPaidBills = useAppSelector(selectPaymentsPaidBills).allPaidBills;
  const expandedPaidBillsListFlag = useAppSelector(
    selectPaymentsPaidBills,
  ).expandedPaidBillsListFlag;
  const memberIdentifier = useAppSelector(selectUser).memberIdentifier;
  const isLoadingDisclaimer =
    useAppSelector(selectPayments).documentByType.isLoading;
  const disclaimer =
    useAppSelector(selectPayments).documentByType.documentByTypeMap[
      PAYMENT_BILL_PAIDBILLS_TEXT_KEY
    ];
  // log page to GA on mount
  useEffect(() => {
    logEventToGoogleAnalytics(EVENTS.VIEW_PAYMENT_BILL_SUMMARY);
  }, []);

  useEffect(() => {
    if (allPaidBills.length === 0) dispatch(fetchPaidBills());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPaidBills]);

  const numberToShow = !expandedPaidBillsListFlag
    ? DEFAULT_NUMBER_OF_PAID_BILLS_TO_SHOW
    : allPaidBills.length;
  return (
    <>
      <ProfileHeaderContainer title="'s Bills" />
      {isLoadingPaidBills ? (
        <Box
          display="flex"
          align-items="center"
          justifyContent="center"
          mt={4}
          p={5}
        >
          <CircularProgress />
        </Box>
      ) : hasErroredPaidBills ? (
        <ErrorDisplayContainer
          errorMessage={errorMessagePaidBills}
          onTryAgain={() => {
            dispatch(setPaidBills([]));
            dispatch(fetchPaidBills());
          }}
        />
      ) : allPaidBills && allPaidBills.length < 1 ? (
        <Box align-items="center" justifyContent="center" mt={0}>
          <Box sx={classes.noRecordBox}>
            <Box sx={classes.noRecordImage}>
              <img src={IMAGES.payment.NoPaidBills} alt="No Paid Bills" />
            </Box>
            <Box sx={classes.noRecordTitle}>
              You have no fully-paid bills history.
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={classes.paidBillCard}>
          <Box m={2}>
            {/* <RoundedNoticePanel bgColor="warn">
              This list of bills shown here only covers the past 6 months'
              records
            </RoundedNoticePanel> */}
            {isLoadingDisclaimer ? (
              <AnnouncementsPanelSkeleton />
            ) : disclaimer ? (
              <SharpNoticePanel bgColor="info" showIcon={false}>
                {disclaimer}
              </SharpNoticePanel>
            ) : null}
          </Box>
          <Box sx={classes.card}>
            <Typography sx={classes.cardTitle}>Fully-Paid Bills</Typography>
            {allPaidBills.slice(0, numberToShow).map((bill, index) => {
              return (
                <Box>
                  <PaidBillCard
                    key={bill.InvoiceNumber}
                    paidBill={bill}
                    memberIdentifier={memberIdentifier}
                  />
                </Box>
              );
            })}
          </Box>

          {!expandedPaidBillsListFlag &&
            allPaidBills.length > DEFAULT_NUMBER_OF_PAID_BILLS_TO_SHOW && (
              <Box sx={classes.viewPastRequestTextBox}>
                <Button
                  sx={classes.button}
                  variant="text"
                  onClick={() => {
                    logEventToGoogleAnalytics(
                      EVENTS.SELECT_VIEW_MORE_PAID_BILLS,
                    );
                    dispatch(setExpandedPaidBillsListFlag(true));
                  }}
                  endIcon={
                    <Box
                      component={"img"}
                      src={IMAGES.general.ArrowRightIcon}
                    />
                  }
                >
                  View more
                </Button>
              </Box>
            )}
        </Box>
      )}
    </>
  );
};

export default PaidBills;
