import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    root: {
      margin: theme.spacing(1.5, 0),
      boxShadow: theme.shadows[2],
      background: theme.palette.common.white,
    },
    detailsLayout: {
      padding: theme.spacing(2),
    },
    titleText: {
      color: theme.palette.text.primary,
      fontSize: size.LIST_TITLEFONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
    },
    
    
  } satisfies SxObjType;
};

export { sxStyles };
