import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    multilineTextField: {
      "& .MuiInputBase-root": {
        height: "auto",
        padding: theme.spacing(1.5),
      },
      "&.MuiFormControl-root": {
        height: "auto",
      },
    },
    text: {
      color: theme.palette.grey[400],
      fontSize: size.FONTSIZE_16,
    },
    cssOutlinedInput: {
      borderRadius: theme.spacing(1),
      lineHeight: "1.5",
    },
    cssFocused: {},
    textFieldLabel: {
      color: theme.palette.primary.main,
      typography: theme.typography.body1_regular,
    },
  } satisfies SxObjType;
};

export { sxStyles };
