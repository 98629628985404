import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests";
import { GET_LOCATIONS_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import {
  GetLocationsResponsePayload,
  PrescriptionType,
} from "./getLocations.types";
import { formatGetLocationsErrorMessage } from "api/resources/messages/ErrorMessage";

/**
 * Fetches the collection locations for this particular institution
 *
 * POST
 * Endpoint: /GetLocations
 *
 * @param {number | null} facilityId Identifier for mapping facility in database
 * @param {LocationType} locationType  Type of collection location this location supports
 * @param {PrescriptionType} prescriptionType  Type of prescription
 */
const getLocations = async (
  facilityId: number | null,
  locationType: string | null,
  prescriptionType: PrescriptionType,
  oldTokenizedId: string | null,
): Promise<GetLocationsResponsePayload> => {
  try {
    const response = await axios.post<GetLocationsResponsePayload>(
      GET_LOCATIONS_URL(),
      createRequestPayload(
        facilityId,
        locationType,
        prescriptionType,
        oldTokenizedId,
      ),
      getRequestOptions(),
    );
    return parseResponse(response, GET_LOCATIONS_URL());
  } catch (error) {
    const msg = formatGetLocationsErrorMessage(GET_LOCATIONS_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  facilityId: number | null,
  locationType: string | null,
  prescriptionType: PrescriptionType,
  oldTokenizedId: string | null,
) => {
  return {
    FacilityId: facilityId,
    LocationType: locationType,
    PrescriptionType: prescriptionType,
    OldTokenizedId: oldTokenizedId,
  };
};

export { getLocations };
