/**
 * Helper function to compare 2 strings if they are same.
 * Test is case insensitive and will trim whitespace
 * @param firstString
 * @param secondString
 * @returns true if same, otherwise false
 */

export const sameStringTest = (
  firstString: string | null,
  secondString: string | null,
) => {
  if (firstString && secondString)
    return (
      firstString.trim().toUpperCase() === secondString.trim().toUpperCase()
    );
  return false;
};
