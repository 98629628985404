import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    otherProvidersTitle: {
      typography: theme.typography.body1_semibold,
      color: theme.palette.grey[400],
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
  } satisfies SxObjType;
};

export { sxStyles };
