import { connect } from "react-redux";
import { RootState } from "lib/redux/root/redux.types";
import AvailableSlotsSection from "./AvailableSlotsSection";
import { parseISO } from "date-fns";

const mapStateToProps = (state: RootState) => {
  const memberIdentifier = state.user.memberIdentifier;

  if (
    state.appointments.rescheduleLinkedAppointment.selectedLinkedAppointment
  ) {
    // rescheduling a selected linked appointment, so will be using the appointment stored in selectedLinkedAppointment
    const linkedAppointment =
      state.appointments.rescheduleLinkedAppointment.selectedLinkedAppointment;

    return {
      memberIdentifier,
      visitTypeId: linkedAppointment.visitTypeId,
      appointmentId: linkedAppointment.appointmentId,

      targetSystem: linkedAppointment.sourceSystem,
      institutionCode: linkedAppointment.institutionCode,
      departmentCode: linkedAppointment.departmentCode,
      slotIdList: [linkedAppointment.slotId],
      isBundledLinkedAppointments: false,
      providerId: null,
      expectedOrderDate: linkedAppointment.expectedOrderDate
        ? parseISO(linkedAppointment.expectedOrderDate)
        : null,
    };
  }

  // Flow for single appointment(non-linked)
  return {
    memberIdentifier,
    appointmentId: state.appointments.appointmentMeta.appointmentId,
    visitTypeId: state.appointments.appointmentMeta.visitTypeId,
    targetSystem: state.appointments.appointmentMeta.targetSystem,
    institutionCode: state.appointments.appointmentMeta.institutionCode,
    departmentCode: state.appointments.appointmentMeta.departmentCode,
    slotIdList: [state.appointments.appointmentMeta.slotId],
    isBundledLinkedAppointments: false,
    expectedOrderDate: parseISO(
      state.appointments.appointmentMeta.expectedOrderDate ?? "",
    ),
  };
};

export default connect(mapStateToProps)(AvailableSlotsSection);
