import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_semibold,
      paddingBottom: theme.spacing(2),
    },
    subtitle: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_semibold,
      paddingBottom: theme.spacing(2),
    },
    contentBox: {
      borderBottom: `0px solid ${theme.palette.secondary.light}`,
      display: "flex",
      flexDirection: "column",
    },

    topupReason: {
      fontSize: size.FONTSIZE_14,
      color: theme.palette.grey[400],
      fontWeight: theme.typography.fontWeightRegular,
      paddingBottom: theme.spacing(2),
    },

    prescriptionPhotoFrame: {
      display: "flex",
      flexDirection: "column",
      margin: theme.spacing(0.5),
    },
    photo: {
      width: "100%",
      borderRadius: theme.spacing(0.5),
      height: "150px",
      objectFit: "contain",
      border: `1px solid ${theme.palette.grey[100]}`,
    },
    photoCaption: {
      fontSize: size.FONTSIZE_12,
      color: theme.palette.grey[400],
      textAlign: "center",
      margin: theme.spacing(0.5, 0),
    },
    medicationDetail: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      marginBottom: theme.spacing(1),
    },
    medicationDetailValue: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      marginLeft: theme.spacing(0.5),
    },
  } satisfies SxObjType;
};

export { sxStyles };
