import { useState, useEffect } from "react";
import { PrescriptionType } from "api/medrefill/GetLocations/getLocations.types";
import { Location } from "api/medrefill/GetLocations/getLocations.types";
import { getLocations } from "api/medrefill/GetLocations/getLocations";
import { AxiosError } from "axios";

const useGetLocations = (
  pagePlag: boolean,
  facilityId: number | null,
  locationType: string | null,
  prescriptionType: PrescriptionType,
  oldTokenizedId: string | null,
): [
  Location[] | null,
  boolean,
  boolean | null,
  string | null,
  string | null,
] => {
  const [data, setData] = useState<Location[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [oldTokenizedIdFromRes, setOldTokenizedIdFromRes] =
    useState(oldTokenizedId);

  useEffect(() => {
    (async () => {
      if (locationType) {
        // only if the user has selected a particular locationType.
        // This value is NULL if not selected yet, hence no need to perform API call.
        setIsLoading(true);

        try {
          const responseData = await getLocations(
            facilityId,
            locationType,
            prescriptionType,
            oldTokenizedId,
          );

          setData(responseData.Locations);
          setHasErrored(false);
          setErrorMessage(null);
          setOldTokenizedIdFromRes(responseData.OldTokenizedId);
        } catch (error) {
          setData(null);
          setHasErrored(true);
          if (error instanceof AxiosError) {
            setErrorMessage(error.response?.data.Message);
          }
        } finally {
          setIsLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId, locationType, prescriptionType, pagePlag]);

  return [data, isLoading, hasErrored, errorMessage, oldTokenizedIdFromRes];
};

export { useGetLocations };
