/**
 * accept a number as argument and return a well formated payment string
 * @param paymentAmount number to be used to format the payment string
 */
export const formatPayment = (paymentAmount: number | null) => {
  if (paymentAmount) {
    if (paymentAmount < 0) {
      return "$ 0.00";
    }
    const prefix = paymentAmount > 0 ? "$ " : "-$ ";
    const amount = Math.abs(paymentAmount);
    return `${prefix}${(Math.round(amount * 100) / 100).toFixed(2)}`;
  } else {
    return "$ 0.00";
  }
};
