import { useState } from "react";
import { Typography, Button, Box } from "@mui/material";
import { UpdateContactProps } from "./UpdateContact.types";
import { sxStyles } from "./UpdateContact.styles";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import { validatePhoneNumber } from "lib/util/ValidatorUtil/phoneNumberValidator/phoneNumberValidator";
import { updateHandphoneNumber } from "api/appointment/UpdateHandphoneNumber/updateHandphoneNumber";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { disableBackButton } from "lib/routing/navigate/navigate";
import { selectPersistDetail } from "ui/appointment/ducks/selectors";
import { handleErrorModal } from "lib/redux/notifications";
import { setHideContactField } from "ui/appointment/ducks/appointmentsSlice";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { AxiosError } from "axios";

const UpdateContact = ({
  memberIdentifier,
  contact,
  regInstitutionCode,
  encounterId,
  setContact,
}: UpdateContactProps) => {
  const classes = sxStyles();
  const persistDetail = useAppSelector(selectPersistDetail);
  const hideContactField = persistDetail?.hideContactField || false;

  const dispatch = useAppDispatch();

  // State handler for call to /UpdateHandphoneNumber
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [open, setOpen] = useState(false);
  const handleCloseConfirmation = () => {
    disableBackButton(); // need to add disableBack action because ConfirmationModal renenables Back button when closed
    setOpen(false);
  };

  // hide contact field, currently it is triggered after successful submission of contact
  const handleHideContactField = () => {
    dispatch(setHideContactField(true));
  };

  const sendUpdateHandPhoneNumberRequest = async () => {
    setIsSubmitting(true);
    try {
      await updateHandphoneNumber(
        memberIdentifier,
        contact,
        regInstitutionCode,
        encounterId,
      ); // Make the API call

      dispatch(handleErrorModal({ open: false, message: null }));
      handleHideContactField();
      setOpen(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(
          handleErrorModal({
            open: true,
            message: error.response?.data.Message,
          }),
        );
      } else {
        dispatch(
          handleErrorModal({
            open: true,
            message: "An error occurred while updating phone number.",
          }),
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {!hideContactField && (
        <>
          <Typography sx={classes.title}>Key in your mobile number</Typography>
          <Box sx={classes.contactBox}>
            <Box sx={classes.textBox}>
              <SingleLineTextField
                placeholder="Mobile Number"
                type="number"
                maxCharLength={8}
                value={contact}
                handleChange={(event) => setContact(event.target.value)}
                error={
                  (contact ? true : false) && !validatePhoneNumber(contact)
                }
                errorText="Enter a valid mobile number"
                required={false}
              />
            </Box>

            <Box
              sx={{
                ...classes.textBox,
                ...classes.buttonPadding,
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                disabled={!validatePhoneNumber(contact) || isSubmitting}
                onClick={() => {
                  logEventToGoogleAnalytics(
                    EVENTS.CLICK_APPT_REGISTER_SUBMIT_MOBILE_NO,
                  );
                  sendUpdateHandPhoneNumberRequest();
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
          <Typography sx={classes.explaination}>
            An SMS will be sent to your mobile number when you are third in
            queue for consultation.
          </Typography>
        </>
      )}

      <ConfirmationModal
        title="Your mobile number has been updated."
        open={open}
        nextButtonText="OK"
        hideCancelButton={true}
        onClickNext={() => handleCloseConfirmation()}
        onClose={() => handleCloseConfirmation()}
      />
    </>
  );
};

export default UpdateContact;
