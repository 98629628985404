import { Box, Button, Typography } from "@mui/material";
import { NonVCQueueDetailProps } from "./NonVCQueueDetail.types";
import { sxStyles } from "./NonVCQueueDetail.styles";
import { PATHS } from "lib/routing/paths/paths";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "lib/redux/hooks";
import { updateDetailsForCheckInAppointment } from "ui/appointment/ducks/appointmentsSlice";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import IMAGES from "lib/assets/images";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import {
  formatDate,
  formatOptions,
} from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

const NonVCQueueDetail = ({
  queueNumber,
  currentStation,
  showStatus,
  queueStatus,
  refreshAt,
  statusMessage,
  showPatientWaiting,
  patientWaiting,
  showRightArrow = false,
  institutionName,
  requireCheckIn,
  iHaveArrivedMessage,
  appointmentId,
  institutionCode,
  regInstitutionCode,
  sourceSystem,
  clinicCode,
  consultType,
  regAppointmentId,
  currentClinicCode,
  lateThresholdMessage,
  encounterId,
  appointmentTime,
}: NonVCQueueDetailProps) => {
  const classes = sxStyles({
    colorType: queueStatus?.toLowerCase() || "default",
    isIHADisabled: !!lateThresholdMessage,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <Box>
      <Box sx={classes.institutionNameBox}>
        <Typography sx={classes.institutionNameBoxText}>
          {institutionName}
        </Typography>
      </Box>

      {!requireCheckIn ? (
        <Box sx={classes.queueNumberBox}>
          <Box sx={{ mt: 1 }}>
            <Typography sx={classes.detailLabel}>Queue #</Typography>
            <Typography sx={classes.queueNumber}>
              {queueNumber ?? "-"}
            </Typography>
          </Box>
        </Box>
      ) : !lateThresholdMessage && iHaveArrivedMessage ? (
        <Box sx={classes.queueNumberBox}>
          <SharpNoticePanel bgColor="warn" showIcon>
            <Typography typography={"caption1_bold"}>
              {iHaveArrivedMessage}
            </Typography>
          </SharpNoticePanel>
        </Box>
      ) : null}

      <Box display="flex">
        <Box width="100%">
          <Box sx={classes.currentStationTextBox}>
            <Typography sx={classes.detailLabel}>
              Current Queue Status for
            </Typography>
            <Typography sx={classes.currentStationText}>
              {currentStation}
            </Typography>
          </Box>
          <Box sx={classes.queueFooterBox}>
            <Box sx={classes.queueFooterBoxSpace}>
              <Typography sx={classes.detailLabel}>Refreshed at</Typography>
              <Typography sx={classes.contentText}>
                {refreshAt ??
                  formatDate(
                    formatDateToISO(new Date()),
                    formatOptions.timeWithPeriod,
                  )}
              </Typography>
            </Box>

            {showPatientWaiting && !requireCheckIn ? (
              <Box sx={classes.queueFooterBoxSpace}>
                <Typography sx={classes.detailLabel}>
                  Patient(s) Before You
                </Typography>
                <Typography sx={classes.contentText}>
                  {patientWaiting}
                </Typography>
              </Box>
            ) : null}

            {showStatus ? (
              <Box sx={classes.queueStatusBox}>
                <Typography sx={classes.detailLabel}>Status</Typography>
                <Typography sx={classes.queueStatusText}>
                  {queueStatus}
                </Typography>
              </Box>
            ) : null}
          </Box>

          {statusMessage && (
            <Typography sx={classes.messageText}>{statusMessage}</Typography>
          )}
        </Box>
        {showRightArrow ? (
          <Box sx={classes.queueDetailArrow}>
            <Box component={"img"} src={IMAGES.general.ArrowForward} />
          </Box>
        ) : null}
      </Box>

      {lateThresholdMessage && (
        <Box sx={{ my: 2 }}>
          <SharpNoticePanel bgColor="warn" showIcon>
            <Typography typography={"caption1_bold"}>
              {lateThresholdMessage}
            </Typography>
          </SharpNoticePanel>
        </Box>
      )}

      {requireCheckIn && (
        <Box sx={classes.topButtonLayout}>
          <Button
            variant="contained"
            sx={classes.button}
            onClick={(event) => {
              // stopPropagation prevents the parent component's event from bubbling
              event.stopPropagation();
              if (!lateThresholdMessage) {
                logEventToGoogleAnalytics(
                  EVENTS.SELECT_I_HAVE_ARRIVED_APPOINTMENT_LANDING,
                );
                dispatch(
                  updateDetailsForCheckInAppointment({
                    appointmentId: appointmentId ?? "",
                    institutionCode: institutionCode ?? "",
                    regInstitutionCode: regInstitutionCode ?? "",
                    sourceSystem,
                    clinicCode,
                    queueNumber: queueNumber ?? "",
                    institutionName,
                    consultType,
                    regAppointmentId,
                    currentClinicCode,
                    encounterId,
                    appointmentTime,
                  }),
                );
                //goes to kioskquestionnaire page
                navigate(PATHS.APPOINTMENT_CHECKIN.path);
              }
            }}
          >{`I have arrived`}</Button>
        </Box>
      )}
    </Box>
  );
};

export default NonVCQueueDetail;
