import { Box, FormLabel, Typography } from "@mui/material";
import Dropdown from "lib/components/formInputs/Dropdown/Dropdown";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import React from "react";
import MedicationCard from "./MedicationCard/MedicationCard";
import { sxStyles } from "./TopupPrescriptionBody.styles";
import { TopupPrescriptionBodyProps } from "./TopupPrescriptionBody.types";
import IconLink from "lib/components/links/IconLink/IconLink";

const TOPUP_DISCLAIMER_TEXT =
  "Please upload your medication image with the label if possible (JPG, PNG). <br>You may request for up to 6 medications per submission.";

const TopupPrescriptionBody = ({
  durationChoice,
  uomChoice,
  topupReasonChoice,
  clinicChoice,
  prescribingClinic,
  reasonForTopup,
  topupOtherReason,
  facilityName,
  medicationList,
  medicationCardDataList,
  medicationNameList,
  prescriptionDisclaimerText,
  onImageProcessing,
  setPrescribingClinic,
  setReasonForTopup,
  setTopupOtherReason,
  setMedicationCardData,
  setMedicationNameList,
  onClickAddMedicationCard,
  onClickDeleteMedicationCard,
  disableMedicationCardRegion = false,
}: TopupPrescriptionBodyProps) => {
  const classes = sxStyles();

  return (
    <Box m={2}>
      <Box mt={1} mb={2}>
        <FormLabel sx={classes.formTitle}>
          Medication Prescription Form
        </FormLabel>
      </Box>
      <Box mt={2} mb={2} sx={classes.formBoxShadow}>
        <Typography sx={classes.formContent}>
          Prescribing Institution
        </Typography>
        <Typography sx={classes.formLabel}>{facilityName}</Typography>
      </Box>
      <Box mt={3} mb={2} sx={classes.textBox}>
        {clinicChoice && clinicChoice.length > 1 ? (
          <Box>
            <Box sx={classes.formLabel}>
              <Dropdown
                label="Prescribing Clinic"
                value={prescribingClinic}
                items={clinicChoice}
                handleChange={(event) =>
                  setPrescribingClinic(event.target.value)
                }
              ></Dropdown>
            </Box>
          </Box>
        ) : clinicChoice.length === 1 ? (
          <Box sx={classes.formLabel}>
            <Box sx={classes.formBoxShadow}>
              <Typography sx={classes.formContent}>
                Prescribing Clinic
              </Typography>
              <Typography sx={classes.formLabel}>
                {clinicChoice[0].text}
              </Typography>
            </Box>
          </Box>
        ) : null}
      </Box>

      <Box mt={2} mb={2} sx={classes.textBox}>
        <Dropdown
          label="Reason for Repeat Prescription"
          value={reasonForTopup}
          items={topupReasonChoice}
          handleChange={(event) => setReasonForTopup(event.target.value)}
        />
        <Box sx={classes.textBox}>
          {reasonForTopup === 4 ? (
            <Box>
              <SingleLineTextField
                type="text"
                value={topupOtherReason || ""}
                error={topupOtherReason?.length === 0}
                placeholder="Repeat Prescription Other Reason"
                errorText="Enter Repeat Prescription Other Reason"
                handleChange={(event) =>
                  setTopupOtherReason(event.target.value)
                }
                maxCharLength={150}
              />
            </Box>
          ) : null}
        </Box>
      </Box>

      <Box mt={3} mb={2}>
        <FormLabel sx={classes.formTitle}>Details of Medication</FormLabel>
        <Box mt={1}>
          <Typography sx={classes.formContent} component={"span"}>
            {MapRawStringToReactElement(TOPUP_DISCLAIMER_TEXT)}
          </Typography>
        </Box>
      </Box>

      <Box mt={3} mb={2}>
        {medicationCardDataList.map((medicationCardData, currentIndex) => {
          return (
            <MedicationCard
              key={currentIndex}
              index={currentIndex}
              title={`Medication ${currentIndex + 1}`}
              medicationList={medicationList}
              medicationCardData={medicationCardData}
              medicationName={medicationNameList[currentIndex]}
              durationList={durationChoice}
              uomList={uomChoice}
              hideDeleteIcon={medicationCardDataList.length > 1 ? false : true}
              setMedicationCardData={(currentMedicationCardData, setName) => {
                setMedicationCardData(
                  currentMedicationCardData,
                  currentIndex,
                  setName,
                );
              }}
              setMedicationName={(newMedicationName) => {
                setMedicationNameList(newMedicationName, currentIndex);
              }}
              onClickDeleteMedicationCard={onClickDeleteMedicationCard}
              onImageProcessing={(loading) => {
                onImageProcessing(loading, currentIndex);
              }}
              disabled={disableMedicationCardRegion}
            />
          );
        })}
        {medicationCardDataList && medicationCardDataList.length < 6 ? (
          <Box sx={classes.buttonBox}>
            <IconLink
              title={"Add another medication"}
              icon="addCircleOutline"
              iconPosition="start"
              disabled={disableMedicationCardRegion}
              onClick={
                !disableMedicationCardRegion
                  ? onClickAddMedicationCard
                  : () => {}
              }
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default TopupPrescriptionBody;
