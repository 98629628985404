import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { CategoryListValue, SystemSettings } from "./mhm.types";

/**
 * The slice reducer for the part of state that represents the user's mhm information.
 */
const mhmSlice = createSlice({
  name: "myHealthMap",
  initialState,
  reducers: {
    setSelectedCategory(state, action: PayloadAction<string | null>) {
      state.selectedCategory.code = action.payload;
    },

    // For getSystemSettings API call
    setSystemSettingsIsLoading(state, action: PayloadAction<boolean>) {
      state.systemSettings.isLoading = action.payload;
    },
    setSystemSettingsHasErrored(state, action: PayloadAction<boolean>) {
      state.systemSettings.hasErrored = action.payload;
    },
    setSystemSettingsErrorMessage(state, action: PayloadAction<string | null>) {
      state.systemSettings.errorMessage = action.payload;
    },
    setSystemSettingsValue(state, action: PayloadAction<SystemSettings>) {
      state.systemSettings.systemSettingMap = action.payload;
    },
    setShowPreventiveCare(state, action: PayloadAction<boolean>) {
      state.showMyChartPreventiveCare = action.payload;
    },
    setCategoryListValue(state, action: PayloadAction<CategoryListValue>) {
      state.categoryList.value = action.payload;
    },
    setCategoryListIsLoading(state, action: PayloadAction<boolean>) {
      state.categoryList.isLoading = action.payload;
    },
    setCategoryListHasErrored(state, action: PayloadAction<boolean>) {
      state.categoryList.hasErrored = action.payload;
    },
    setCategoryListErrorMessage(state, action: PayloadAction<string | null>) {
      state.categoryList.errorMessage = action.payload;
    },
  },
});

export const {
  setSelectedCategory,
  setSystemSettingsIsLoading,
  setSystemSettingsHasErrored,
  setSystemSettingsErrorMessage,
  setSystemSettingsValue,
  setShowPreventiveCare,
  setCategoryListValue,
  setCategoryListIsLoading,
  setCategoryListHasErrored,
  setCategoryListErrorMessage,
} = mhmSlice.actions;

export default mhmSlice.reducer;
