import { format } from "date-fns";

// Standardised Date Format (compatible with current backend DateTime format)
export const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

/**
 * Formats a date to a standardised format
 *
 * @param {Date} date  Javascript date object
 *
 * @returns Formatted date string, in the ISO format "yyyy-MM-dd'T'HH:mm:ss".
 */
export const formatDateToISO = (date: Date | null | undefined) => {
  try {
    if (date) {
      return format(date, DATE_FORMAT);
    } else {
      return "";
    }
  } catch (error) {
    console.error(`Date '${date}' could not be formatted. Error: `, error);
    return "";
  }
};
