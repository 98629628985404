/**
 * Replaces every character from a start index (inclusive) to an end
 * index (exclusive) with the specified input character.
 *
 * Example usage:
 * - mask("S1234567A", "X", 1, 5); // returns "SXXXX567A"
 *
 * @param {string} input  Data to mask
 * @param {string} maskChar  Character to mask data with
 * @param {number} start  Position to start masking
 * @param {number} end Input string to strip
 *
 * @returns {string}  The masked string
 */
export const mask = (
  input: string,
  maskChar: string,
  start: number,
  end: number,
) => {
  try {
    let inputChars = input.split(""); // chop into individual characters

    const resultChars = inputChars.map((originalChar, i) => {
      if (i >= start && i < end) {
        return maskChar;
      } else {
        return originalChar;
      }
    });

    return resultChars.join("");
  } catch (error) {
    // return the input itself since this might not have been compatible
    // with this function, e.g. input is a number or object, or the
    // provided index was out of bounds.
    return input;
  }
};
