import { Theme } from "@mui/material";
import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = (theme: Theme) => {
  return {
    cardLeftPanel: {
      margin: theme.spacing(0, 0.25, 0, 1),
      "& .MuiFormControlLabel-root": {
        marginRight: 0,
      },
    },
    cardRightPanel: {
      width: "100%",
      padding: theme.spacing(0, 0, 2.5),
    },
    checkboxField: {
      margin: theme.spacing(0),
      "& .MuiFormControlLabel-root": {
        marginRight: 0,
        padding: 0,
      },
      "& .MuiCheckbox-root": {
        padding: theme.spacing(0, 1.125),
      },
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: theme.palette.primary.main,
      },
    },
    cardDetails: {
      wordBreak: "break-word",
      padding: theme.spacing(0, 1.25, 0, 0),
      display: "flex",
      alignItems: "start",
      "&:last-child": {
        borderBottom: `0px solid ${theme.palette.secondary.light}`,
      },
    },
    cardValueDetails: {
      wordBreak: "break-word",
      padding: theme.spacing(0, 1.5, 0, 0),
      display: "flex",
      alignItems: "end",
      "&:last-child": {
        borderBottom: `0px solid ${theme.palette.secondary.light}`,
      },
    },
    cardLeft: {
      width: "100%",
    },
    cardRight: {
      textAlign: "right",
      color: theme.palette.common.black,
      typography: theme.typography.body2_semibold,
      minWidth: 110,
      "& .MuiInputLabel-outlined": {
        color: theme.palette.warning.main,
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: theme.spacing(-1),
      },
      "& .MuiOutlinedInput-input": {
        padding: "10.5px 10px",
      },
      "& legent": {
        top: 0,
      },
    },
    dollarSign: {
      padding: theme.spacing(0.7, 0.5),
      display: "inline-block",
    },
    cardTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.body1_semibold,
      paddingBottom: theme.spacing(0.75),
    },
    cardLabel: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      padding: theme.spacing(0.5, 0),
    },
    cardValue: {
      color: theme.palette.common.black,
      typography: theme.typography.body1_semibold,
      padding: theme.spacing(0.5, 0),
    },

    cardValueBox: {
      color: theme.palette.warning.main,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      display: "flex",
      alignItems: "end",
      width: "150px",
      marginTop: theme.spacing(1),
      float: "right",
      "& .MuiOutlinedInput-root": {
        borderRadius: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5),
      },
      "& .MuiOutlinedInput-input": {
        color: theme.palette.warning.main,
        fontSize: size.TITLE_FONTSIZE,
        fontWeight: theme.typography.fontWeightMedium,
        textAlign: "right",
      },
      "& div ": {
        "& div:nth-child(2)": {
          position: "absolute",
          right: 0,
        },
      },
    },
    cardPanel: {
      display: "flex",
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
      padding: theme.spacing(1, 0),
      margin: theme.spacing(1, 0),
      "&:last-child": {
        borderBottom: `0px solid ${theme.palette.secondary.light}`,
      },
    },
    cardRow: {
      width: "100%",
      display: "flex",
      alignItems: "start",
    },
    cardRowLabel: {
      width: "65%",
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      padding: theme.spacing(0.5, 0),
    },
    cardRowValue: {
      color: theme.palette.common.black,
      typography: theme.typography.body2_semibold,
      padding: theme.spacing(0.5, 0),
      width: "100%",
      textAlign: "right",
      wordBreak: "break-all",
    },
  } satisfies SxObjType;
};

export { sxStyles };
