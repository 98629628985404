import axios from "axios";
import { GET_AR_INSTITUTIONS_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { GetArInstitutionsResponsePayload } from "./getArInstitutions.fromApi.types";

/**
 * Fetches the list of institutions for Add Other Bills
 *
 * POST
 * Endpoint: secure/data/GetArInstitutions
 *
 */
const getArInstitutions =
  async (): Promise<GetArInstitutionsResponsePayload> => {
    try {
      const response = await axios.post<GetArInstitutionsResponsePayload>(
        GET_AR_INSTITUTIONS_URL(),
        createRequestPayload(),
        getRequestOptions(),
      );
      return parseResponse(response, GET_AR_INSTITUTIONS_URL());
    } catch (error) {
      throw error;
    }
  };

const createRequestPayload = () => {
  return {};
};

export { getArInstitutions };
