import { Button, Container, Typography } from "@mui/material";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { getEventsListing } from "lib/util/GoogleAnalyticsUtil/getEventsListing";

const Admin = () => {
  return (
    <>
      <Container sx={{ pt: 2 }}>
        <Typography>Download excel file with events tracker listing</Typography>
        <Button
          onClick={() => {
            getEventsListing(EVENTS);
          }}
        >
          Download
        </Button>
      </Container>
    </>
  );
};

export default Admin;
