import { RootState } from "lib/redux/root/redux.types";

export const selectPM = (state: RootState) => state.pm;

export const selectPMSettings = (state: RootState) => state.pm.systemSettings;
export const selectPMSettingsValue = (state: RootState) =>
  state.pm.systemSettings.value;

export const selectAuthType = (state: RootState) => state.system;

export const selectEpicProfile = (state: RootState) => state.pm.epicProfile;
export const selectEpicProfileState = (state: RootState) =>
  state.pm.epicProfile.value;
export const selectOldEpicProfileState = (state: RootState) =>
  state.pm.epicProfile.oldValue;
export const selectEpicProfileTempState = (state: RootState) =>
  state.pm.epicProfile.tempValue;

export const selectMyInfoProfile = (state: RootState) => state.pm.myInfoProfile;
export const selectMyInfoProfileState = (state: RootState) =>
  state.pm.myInfoProfile.value;

export const selectPMGeneralErrors = (state: RootState) =>
  state.pm.general.errors;

export const selectPMGeneral = (state: RootState) => state.pm.general;
