import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  const cardFooterTextColor = theme.palette.grey[400];

  return {
    cardFooterText: {
      color: cardFooterTextColor,
      typography: theme.typography.body2_regular,
      padding: theme.spacing(0, 2, 1, 2),
    },
    shouldSubmitRequestDisclaimerText: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
      margin: theme.spacing(0, 1),
      "& a": {
        color: theme.palette.primary.main,
      },
    },
    actionMsg: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
      margin: theme.spacing(0, 1),
    },
    rvText: {
      background: theme.palette.secondary.light,
    },
    reminderText: {
      background: theme.palette.info.light,
    },
  } satisfies SxObjType;
};

export { sxStyles };
