import React from "react";
import { PATHS } from "lib/routing";
import TopupPrescriptionContainer from "../../prescription/TopupPrescriptionContainer";

const TopupPrescriptionPage = () => {
  return (
    <TopupPrescriptionContainer
      pathToNextStep={PATHS.MED_TOPUP_COLLECTION.path}
    />
  );
};

export default TopupPrescriptionPage;
