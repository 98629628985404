import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    image: {
      width: "100%",
      flexDirection: "column",
      justifyContent: "flex-end",
      "& img": {
        width: "100%",
        objectFit: "cover",
      },
    },
    bannerButtonContainer: {
      background: theme.palette.common.white,
      textAlign: "center",
      margin: `0px ${theme.spacing(2)}`,
      marginTop: "-5px",
    },
    title: {
      typography: theme.typography.header4_semibold,
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(1.5),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  } satisfies SxObjType;
};

export { sxStyles };
