import React from "react";
import { Box, Typography } from "@mui/material";
import { PharmacyOperatingHoursProps } from "./PharmacyOperatingHours.types";
import { sxStyles } from "./PharmacyOperatingHours.styles";
import {
  computeMonToFriLabel,
  computeSatLabel,
  computeSunPublicHolidayLabel,
} from "./computeOperatingHours";

const PharmacyOperatingHours = ({ location }: PharmacyOperatingHoursProps) => {
  const classes = sxStyles();

  if (
    location &&
    location.OperatingHours &&
    location.OperatingHours.length >= 1 // there are operating hours to show
  ) {
    const operatingHours = location.OperatingHours;

    return (
      <Box mt={3} mb={2}>
        <Typography sx={classes.formTitle}>Pharmacy Operating Hours</Typography>
        <Box mt={2} mb={2} p={1} sx={classes.pickupHrs}>
          <Box sx={classes.halfCard}>
            <Typography sx={classes.formContent}>Mon-Fri:</Typography>
            <Typography sx={classes.formContentVal}>
              {computeMonToFriLabel(operatingHours)}
            </Typography>
          </Box>
          <Box sx={classes.halfCard}>
            <Typography sx={classes.formContent}>Sat:</Typography>
            <Typography sx={classes.formContentVal}>
              {computeSatLabel(operatingHours)}
            </Typography>
          </Box>
          <Box sx={classes.halfCard}>
            <Typography sx={classes.formContent}>Sun and PH:</Typography>

            <Typography sx={classes.formContentVal}>
              {computeSunPublicHolidayLabel(operatingHours)}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return null;
  }
};

export default PharmacyOperatingHours;
