import { RootState } from "lib/redux/root/redux.types";

export const selectMedRefill = (state: RootState) => state.medRefill;

export const selectIsPayInstructionEnabled = (state: RootState) =>
  state.medRefill.collectionAndPayments.isPayInstructionEnabled;

export const selectIsMultiplePayment = (state: RootState) =>
  state.medRefill.collectionAndPayments.isMultiplePayment;

export const selectPayment = (state: RootState) => state.medRefill.payment;

export const selectViewPastRequestDetailSubsidy = (state: RootState) =>
  state.medRefill.viewPastRequest.detail?.Subsidy;

export const selectViewPastRequestDetailPaymentInstructions = (
  state: RootState,
) => state.medRefill.viewPastRequest.detail?.PaymentInstructions;

export const selectFileUpload = (state: RootState) =>
  state.medRefill.fileUpload;
