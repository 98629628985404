import { useState } from "react";
import { Box, Button, Skeleton } from "@mui/material";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { AvailableSlotsSectionProps } from "./AvailableBundledSlotsSection.types";
import { useGetAvailableSlots } from "../../../../hooks/useGetAvailableSlots";
import Datepicker from "lib/components/formInputs/Datepicker/Datepicker";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import { sxStyles } from "./AvailableBundledSlotsSection.styles";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import AvailableBundledSlotsListContainer from "../AvailableBundledSlotsList/AvailableBundledSlotsListContainer";
import { AvailableBundledSlot } from "api/appointment/GetAvailableSlots/getAvailableSlots.toUi.types";
import { isJHC } from "ui/appointment/helper/isJHC/isJHC";
import { color } from "lib/theme/color";

// ==========================
// CONSTANTS
// ==========================

// Initial state of selected slot
const INITIAL_SELECTED_SLOT = [
  {
    id: "",
    date: "",
    extensionData: null,
    resource: null,
    departmentName: null,
    institutionCode: null,
  },
];

// ==========================
// COMPONENT
// ==========================

const AvailableBundledSlotsSection = ({
  isSameDayBooking,
  memberIdentifier,
  visitTypeId,
  slotIdList,
  institutionCode,
  minStartDate,
  maxEndDate,
  shouldGetAvailableSlots,
  isBundledLinkedAppointments,
  isHsgSubsequent,
  isUrti,
  centerContactNo,
  onSelect,
  onCancel,
  isNewAppointment,
}: AvailableSlotsSectionProps) => {
  const classes = sxStyles();

  /* State */
  const [isPreview, setIsPreview] = useState(true); // controls whether should show all slots retrieved from API or not
  const [isDateChanged, setIsDateChanged] = useState(false); // indicated whether a new date is picked to fetch slots
  const [paginationIndex, setPaginationIndex] = useState(0); // controls the current page index of slots
  const [selectedSlot, setSelectedSlot] = useState<AvailableBundledSlot[]>(
    INITIAL_SELECTED_SLOT,
  );
  const [selectedDate, setSelectedDate] = useState<Date>(
    getDefaultDate(minStartDate),
  );
  const minDate = formatDateToISO(minStartDate);
  const maxDate = formatDateToISO(maxEndDate);

  const showCenterContactNo = isJHC(institutionCode);
  /* Hook to fetch available slots data */
  const {
    availableBundledSlots,
    isLoading,
    hasErrored,
    errorMessage,
    isLiveChatEnabled,
    hasMoreSlots,
    noSlotMessage,
  } = useGetAvailableSlots({
    pageFlag: true,
    isNewAppointment,
    shouldGetAvailableSlots,
    paginationIndex,
    startDate: selectedDate,
    memberIdentifier,
    visitTypeId,
    institutionCode,
    endDate: maxDate,
    isDateChanged,
    isBundledLinkedAppointments,
    slotIdList: slotIdList,
    answerToUrtiTriage: isUrti,
    isHsgSubsequent,
  });
  //filter slots according to data range for reschedule flow
  const slotsInRange = availableBundledSlots
    ? availableBundledSlots.filter((slotsArray) => {
        if (!minDate && !maxDate) {
          // this is not reschedule, no time period restriction
          return true;
        } else {
          const slotDateObj = getDateFromISOString(slotsArray[0].date);
          var maxEndNextDate = getNextDate(maxEndDate);
          return (
            minStartDate &&
            maxEndDate &&
            slotDateObj &&
            minStartDate <= slotDateObj &&
            slotDateObj < maxEndNextDate
          );
        }
      })
    : availableBundledSlots;

  const hasExceedRange = availableBundledSlots
    ? availableBundledSlots.length > slotsInRange.length
    : false;

  const onNext = () => {
    onSelect(selectedSlot);
  };

  const disableNextButton =
    selectedSlot[0].id === null || selectedSlot[0].id === "";
  const hideNextButton = slotsInRange?.length === 0 && isSameDayBooking;

  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {/* Select a Date/Time section (when needed) */}
        {isSameDayBooking ? null : (
          <Box>
            <Box sx={classes.datepicker}>
              <Datepicker
                label="Date"
                autoOk={false}
                disablePast={true}
                value={selectedDate}
                onSelect={(date) => {
                  setSelectedDate(date);
                  setIsDateChanged(true);
                  setSelectedSlot(INITIAL_SELECTED_SLOT);
                }}
                minDate={minDate}
                maxDate={maxDate}
                required={false} //it is mandatory, but we don't want to show the *required text here
              />
            </Box>
          </Box>
        )}

        {(isPreview || isDateChanged) && isLoading ? (
          <SlotsSkeleton />
        ) : hasErrored ? (
          <ErrorDisplayContainer errorMessage={errorMessage} />
        ) : (
          <AvailableBundledSlotsListContainer
            slots={slotsInRange}
            isPreview={isPreview}
            isNewAppointment={isNewAppointment}
            isDateChanged={isDateChanged}
            hasExceedRange={hasExceedRange}
            hasMoreSlots={hasMoreSlots}
            noSlotMessage={noSlotMessage}
            onSelect={(selectedBundledSlots: AvailableBundledSlot[]) => {
              setSelectedSlot(selectedBundledSlots);
              setIsDateChanged(false);
            }}
            onNextPage={() => {
              setPaginationIndex(paginationIndex + 1);
              setIsDateChanged(false);
            }}
            onShowAll={() => setIsPreview(false)}
            centerContactNo={centerContactNo}
            isLiveChatEnabled={isLiveChatEnabled}
            showCenterContactNo={showCenterContactNo}
          />
        )}
      </Box>

      <Box sx={{ padding: 2 }}>
        {/* Confirm Selection */}
        {isNewAppointment ? (
          <ButtonsFooter
            isDisabled={disableNextButton}
            hideNextButton={hideNextButton}
            nextButtonText={"Next"}
            cancelButtonText={"Back"}
            onClickNext={onNext}
            onClickCancel={onCancel}
          />
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Button sx={{ mb: 2 }} onClick={onCancel}>
              Back
            </Button>
            {!hideNextButton && (
              <Button
                onClick={onNext}
                variant="outlined"
                disabled={disableNextButton}
              >
                Next
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

// ==========================
// HELPER METHODS
// ==========================

/**
 * Obtains the default date the datepicker should start at
 *
 * @param {Date | undefined | null} startDate  (Optional) A defined minimum start date
 * @returns {Date}  The default date to set in the datepicker
 */
const getDefaultDate = (startDate: Date | undefined | null) => {
  const today = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
  );

  try {
    if (startDate) {
      if (startDate.getTime() > today.getTime()) {
        // min date is later than today, hence we should restrict the beginning range of dates
        return startDate;
      } else {
        return today;
      }
    } else {
      // no min date given, means that the default should be today's date
      return today;
    }
  } catch (error) {
    return today;
  }
};

/**
 * Returns the next date for a truty given date, or max date in javascript optherwise
 * won't mutate the passed in date
 *
 * @param {Date | undefined | null} maxDate  The date which needs to be get next date for
 * @returns {Date}  The next date / max date in javascript
 */
const getNextDate = (maxDate: Date | null | undefined) => {
  if (maxDate) {
    var nextDate = new Date(maxDate.getTime());
    nextDate.setDate(nextDate.getDate() + 1);
    return nextDate;
  } else {
    return new Date(8640000000000000); // max date in javascript
  }
};

const SlotsSkeleton = () => {
  return (
    <>
      <Box
        pl={5}
        py={1}
        style={{ borderBottom: "1px solid " + color.SECONDARY_LIGHT }}
      >
        <Skeleton animation="wave" width="40%" />
        <Skeleton animation="wave" width="70%" />
        <Skeleton animation="wave" width="40%" />
        <Skeleton animation="wave" width="70%" />
      </Box>
      <Box
        pl={5}
        py={1}
        style={{ borderBottom: "1px solid " + color.SECONDARY_LIGHT }}
      >
        <Skeleton animation="wave" width="40%" />
        <Skeleton animation="wave" width="70%" />
        <Skeleton animation="wave" width="40%" />
        <Skeleton animation="wave" width="70%" />
      </Box>
    </>
  );
};

export default AvailableBundledSlotsSection;
