import React from "react";
import { sxStyles } from "./MedicationRefillDetailSummarySection.styles";
import { MedicationRefillDetailSummarySectionProps } from "./MedicationRefillDetailSummarySection.types";
import { Typography, Box } from "@mui/material";

const MedicationRefillDetailSummarySection = ({
  typeId,
  quantityDetailList,
}: MedicationRefillDetailSummarySectionProps) => {
  const classes = sxStyles();
  return (
    <>
      <Typography sx={classes.title}>Medication Refill For</Typography>

      {typeId === 3 && quantityDetailList ? (
        <Box display="flex" flexDirection="column">
          {quantityDetailList.map((detail) => (
            <Box
              sx={classes.medicationDetailBoxOption3}
              key={
                (detail.prescriptionItemNo || "") +
                (detail.number || "") +
                (detail.UOMDuration || "")
              }
            >
              <Box width="75%">
                <Typography sx={classes.medicationDetail}>
                  {detail.prescriptionItemNo}
                </Typography>
              </Box>
              <Typography sx={classes.medicationDetailValue}>
                {detail.number + " " + detail.UOMDuration}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : typeId === 2 &&
        quantityDetailList &&
        quantityDetailList.length > 0 ? (
        <Box
          sx={classes.medicationDetailBoxOption1And2}
          key={
            (quantityDetailList[0].number || "") +
            (quantityDetailList[0].UOMDuration || "")
          }
        >
          <Box width="75%">
            <Typography sx={classes.medicationDetail}>
              An amount for a selected duration
            </Typography>
          </Box>
          <Typography sx={classes.medicationDetailValue}>
            {quantityDetailList[0].number +
              " " +
              quantityDetailList[0].UOMDuration}
          </Typography>
        </Box>
      ) : (
        typeId === 1 && (
          <Box sx={classes.medicationDetailBoxOption1And2}>
            <Typography sx={classes.medicationDetail}>
              All my balance
            </Typography>
          </Box>
        )
      )}
    </>
  );
};

export default MedicationRefillDetailSummarySection;
