import { useState, useEffect } from "react";
import { validatePatientMrn } from "api/mrrp/ValidatePatientMrn/validatePatientMrn";
import { store } from "lib/redux/root/rootStore";
import { AxiosError } from "axios";

/**
 * A custom hook that validate whether patient record exist in the selected hospital from
 * the remote API and returns a boolean in Data part.
 *
 * @param {string | null} hospitalCode the code of the hospital to validate with
 * @param {boolean} validating a pageFlag to control the calling of API
 * @returns {[boolean, boolean, boolean | null, string | null]}
 *   0 - Data as boolean result
 *   1 - True if the data fetch is ongoing, false if its completed.
 *   2 - True if the data fetch completed with errors, false otherwise.
 *   3 - Error message returned from API call
 */
const useValidatePatientMrn = (
  hospitalCode: string | null,
  validating: boolean,
): [boolean, boolean, boolean | null, string | null] => {
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const nonEmptyHospitalCode = hospitalCode || "";
  const memberIdentifier = store.getState().user.memberIdentifier;
  const oldMemberIdentifier = store.getState().user.oldMemberIdentifier;
  const oldTokenizedId = store.getState().user.oldTokenizedId;

  useEffect(() => {
    if (validating) {
      (async () => {
        setIsLoading(true);
        try {
          const responseData = await validatePatientMrn(
            nonEmptyHospitalCode,
            memberIdentifier,
            oldMemberIdentifier,
            oldTokenizedId,
          );

          setData(responseData.Data);
          setHasErrored(false);
          setErrorMessage(null);
        } catch (error) {
          setHasErrored(true);
          if (error instanceof AxiosError) {
            setErrorMessage(error.response?.data.Message);
          }
        } finally {
          setIsLoading(false);
        }
      })();
    }
    // only listen on validationActive flag for calling
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validating]);

  return [data, isLoading, hasErrored, errorMessage];
};

export { useValidatePatientMrn };
