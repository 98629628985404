import { useState, useEffect } from "react";
import { getFacilities } from "api/medrefill/GetFacilities/getFacilities";
import { Facility } from "api/medrefill/GetFacilities/getFacilities.fromApi.types";
import { MedInstitution, MedRefillRequestType } from "../ducks/medrefill.types";
import { AxiosError } from "axios";

const useGetFacilities = (
  pageFlag: boolean,
  prescriptionType: MedRefillRequestType,
  oldTokenizedId: string | null,
): [
  Facility[] | null,
  MedInstitution[] | null,
  boolean,
  boolean | null,
  string | null,
  string | null,
] => {
  const [data, setData] = useState<Facility[] | null>(null);
  const [mappedData, setMappedData] = useState<MedInstitution[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [oldTokenizedIdFromRes, setOldTokenizedIdFromRes] = useState<
    string | null
  >(oldTokenizedId);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const responseData = await getFacilities(
          prescriptionType,
          oldTokenizedId,
        );

        const responseMappedData = responseData.Facilities.map((element) => {
          return {
            displayName: element.FacilityName,
            category: element.Type,
            id: element.FacilityId,
          };
        });
        setData(responseData.Facilities);
        setMappedData(responseMappedData);
        setHasErrored(false);
        setErrorMessage(null);
        setOldTokenizedIdFromRes(responseData.OldTokenizedId);
      } catch (error) {
        setData(null);
        setHasErrored(true);
        if (error instanceof AxiosError) {
          setErrorMessage(error.response?.data.Message);
        }
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescriptionType, pageFlag]);

  return [
    data,
    mappedData,
    isLoading,
    hasErrored,
    errorMessage,
    oldTokenizedIdFromRes,
  ];
};

export { useGetFacilities };
