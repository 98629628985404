import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    personalParticularTitle: {
      color: theme.palette.text.primary,
      fontSize: size.LIST_TITLEFONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      margin: theme.spacing(2, 0),
      lineHeight: "19px",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1.5),
    },
    formSameLine: {
      margin: theme.spacing(2, 0, 0),
    },
  } satisfies SxObjType;
};
export { sxStyles };
