import { useEffect, useState } from "react";
import StepPageLayoutContainer from "ui/medrefill/components/common/StepPageLayout/StepPageLayoutContainer";
import {
  TopupPrescriptionProps,
  MedicationCardData,
} from "./TopupPrescription.types";
import TopupPrescriptionBody from "./TopupPrescriptionBody";
import StepPageButtonsFooter from "../../common/StepPageButtonsFooter/StepPageButtonsFooter";
import { useNavigate } from "react-router-dom";
import {
  setFileUploadDetails,
  setFileUploadPrescriptionError,
} from "ui/medrefill/ducks/medrefillSlice";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectFileUpload } from "ui/medrefill/ducks/selectors";
import { getInitialUploadState } from "lib/components/upload/ImageUploader/processUploadedFile.states";

const TopupPrescription = ({
  isEditablePage = false,
  pathToNextStep,
  defaultPrescribingClinic,
  defaultReasonForTopup,
  defaultTopupOtherReason,
  institutionState,
  medicationList,
  defaultMedicationCardDataList,
  onSelectPrescription,
  durationList,
  uomList,
  topupReasonList,
  clinicList,
  documentList,
  preventDirectBackNavigationState,
  resetPreventDirectBackNavigationState,
}: TopupPrescriptionProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { fileUploadPrescriptionErrorArray, fileUploadDetails } =
    useAppSelector(selectFileUpload);

  useEffect(() => {
    if (clinicList.length === 1) {
      setPrescribingClinic(Number(clinicList[0].id));
    }
  }, [clinicList]);

  // --- STATES FOR FIELDS ABOVE
  const [prescribingClinic, setPrescribingClinic] = useState<number>(
    defaultPrescribingClinic,
  );
  const [reasonForTopup, setReasonForTopup] = useState<number | null>(
    defaultReasonForTopup,
  );
  const [topupOtherReason, setTopupOtherReason] = useState<string | null>(
    defaultTopupOtherReason,
  );

  // DISCLAIMER TEXT
  const PRES_DISC_TYPE = "RefillPrescriptionDetailDisclaimerText";
  const PRES_DISC_CONTENT = documentList.filter(
    (doc) => doc.type === PRES_DISC_TYPE,
  );

  // finds the 1st occurrence of the topup reason text
  const topupReasonText =
    topupReasonList.find((doc) => doc.id === reasonForTopup)?.text || null;

  // --- STATES FOR MEDICATIONS BELOW
  // Represents the initial, default state of the medication cards

  let initialMedicationCardDataListState: MedicationCardData[] =
    defaultMedicationCardDataList.map((cardData) => {
      // for past history, if UOM has been deleted and not applicable anymore, it should be cleared away
      if (
        cardData.UOMId &&
        !uomList.map((uom) => Number(uom.id)).includes(cardData.UOMId)
      ) {
        cardData.UOMId = null;
        cardData.UOM = null;
      }
      return {
        ...cardData,
        quantityValidationMsg: validateNumberAccordingToDuration(
          cardData.quantity,
          cardData.UOM,
        ),
      };
    });

  if (
    !defaultMedicationCardDataList ||
    defaultMedicationCardDataList.length === 0
  ) {
    // if is some null, undefined or empty list value, then it means that the user hasn't set any prescription sheet yet

    initialMedicationCardDataListState = [
      {
        medicationName: null,
        quantity: null,
        UOMId: null, // TODO: Satheesh, pls add for UOM dropdown
        UOM: null, // TODO: Satheesh, pls add for UOM dropdown
        fileName: null,
        fileData: null,
        quantityValidationMsg: null,
      },
    ];
  }

  const [medicationCardDataList, setMedicationCardDataList] = useState<
    MedicationCardData[]
  >(initialMedicationCardDataListState);

  const [medicationNameList, setMedicationNameList] = useState<
    (string | null)[]
  >(initialMedicationCardDataListState.map((med) => med.medicationName));

  const [isLoading, setIsLoading] = useState<boolean[]>(
    new Array(medicationCardDataList.length).fill(false),
  );

  const medicationCardDataListWithFilter = medicationCardDataList.filter(
    (file, index) =>
      (file.medicationName !== null ||
        (medicationNameList[index] != null &&
          medicationNameList[index] !== "")) &&
      file.quantity !== null &&
      file.UOMId !== null &&
      file.UOMId !== undefined,
  );

  const disableNext = () => {
    let disableNext = true;
    if (prescribingClinic) {
      if (
        isLoading.every((loading) => loading === false) &&
        //either the prescription mandatory fields (medication name, quantity and UOM) are completely empty or completely filled in
        medicationCardDataList.every(
          (d, index) =>
            ((d.medicationName === null || d.medicationName === "") &&
              d.quantity === null &&
              (d.UOMId === null || d.UOMId === undefined) &&
              medicationNameList[index] === null) ||
            medicationNameList[index] === null ||
            medicationNameList[index] === "" ||
            ((d.medicationName !== null ||
              (d.medicationName === null &&
                medicationNameList[index] != null &&
                medicationNameList[index] !== "")) &&
              d.quantity !== null &&
              d.UOMId !== null &&
              d.UOMId !== undefined &&
              d.quantityValidationMsg === null),
        )
      ) {
        if (reasonForTopup === 4) {
          if (topupOtherReason) {
            disableNext = false;
          }
        } else if (reasonForTopup) {
          disableNext = false;
        }
      }
    }
    if (JSON.stringify(fileUploadPrescriptionErrorArray).includes("true")) {
      disableNext = true;
    }
    return disableNext;
  };

  const saveFormState = () => {
    // save all without doing filter if it is back navigation, else save the filtered list
    const cardDataList = preventDirectBackNavigationState
      ? medicationCardDataList
      : medicationCardDataListWithFilter;
    onSelectPrescription({
      refillOriginalPrescriptionDate: null,
      files: cardDataList.map((med, index) => {
        return {
          front: {
            fileName: med.fileName,
            fileData: med.fileData,
          },
          back: {
            fileName: null,
            fileData: null,
          },
          prescription: "Medication " + (index + 1).toString(),
          topUp: {
            quantity: med.quantity,
            UOMId: med.UOMId,
            UOM: med.UOM,
            medicationName: med.medicationName,
          },
        };
      }),
      topupReason: reasonForTopup,
      topupReasonText: topupReasonText,
      topupOtherReason: topupOtherReason,
      clinicId: prescribingClinic,
    });
  };

  //save form state when back navigation
  useEffect(() => {
    if (!isEditablePage && preventDirectBackNavigationState) {
      saveFormState();
      resetPreventDirectBackNavigationState();
      navigate(-1);
    }
    // we only want this hook to fire on back navigation
    // eslint-disable-next-line
  }, [preventDirectBackNavigationState]);

  return (
    <StepPageLayoutContainer
      editableFlag={isEditablePage}
      isNextButtonDisabled={false}
      currentStepIndex={1}
      shouldShowButtonsFooter={false}
      pathToNextStep={pathToNextStep ?? ""}
      onNextStep={() => {}}
    >
      <TopupPrescriptionBody
        durationChoice={durationList}
        uomChoice={uomList}
        topupReasonChoice={topupReasonList}
        clinicChoice={clinicList}
        prescribingClinic={prescribingClinic}
        reasonForTopup={reasonForTopup}
        topupOtherReason={topupOtherReason}
        medicationList={medicationList.map((med) => med.MedicationName)}
        facilityName={institutionState.facilityName}
        prescriptionDisclaimerText={PRES_DISC_CONTENT}
        onImageProcessing={(loading, currentIndex) => {
          const prevList = [...isLoading];
          prevList[currentIndex] = loading;
          setIsLoading(prevList);
        }}
        setPrescribingClinic={(currentValue) =>
          setPrescribingClinic(currentValue)
        }
        setReasonForTopup={(currentValue) => setReasonForTopup(currentValue)}
        setTopupOtherReason={(currentValue) =>
          setTopupOtherReason(currentValue)
        }
        medicationCardDataList={medicationCardDataList}
        setMedicationCardData={(
          newMedicationCardData,
          currentIndex,
          setName,
        ) => {
          // Clone the state of the current list of medication data
          const prevListOfMedicationCardData = [...medicationCardDataList];

          // Set the object with the new user input value
          // Only AutoSelect corresponding UOMId when setting name from AutoComplete selection list
          if (setName) {
            let uomId = medicationList.find(
              (med) =>
                med.MedicationName === newMedicationCardData.medicationName,
            )?.UomId;
            if (uomId !== undefined) {
              newMedicationCardData.UOMId = uomId;
            }
          }
          // Select UOM value according to UOMId
          let UOM =
            uomList.find((doc) => doc.id === newMedicationCardData.UOMId)
              ?.text ||
            durationList.find((doc) => doc.id === newMedicationCardData.UOMId)
              ?.text ||
            null;
          newMedicationCardData.UOM = UOM;

          // Validate quantity according to quantity value and UOM value
          let msg = validateNumberAccordingToDuration(
            newMedicationCardData.quantity,
            newMedicationCardData.UOM,
          );
          newMedicationCardData.quantityValidationMsg = msg;

          prevListOfMedicationCardData[currentIndex] = newMedicationCardData;

          // Finally, set the current state to the new list
          setMedicationCardDataList(prevListOfMedicationCardData);
        }}
        medicationNameList={medicationNameList}
        setMedicationNameList={(newMedicationName, currentIndex) => {
          const prevListOfMedicationName = [...medicationNameList];
          prevListOfMedicationName[currentIndex] = newMedicationName;

          setMedicationNameList(prevListOfMedicationName);
        }}
        onClickAddMedicationCard={() => {
          // Clone the state of the current list of medication data
          const prevListOfMedicationCardData = [...medicationCardDataList];

          // Set the object with an empty medication card
          prevListOfMedicationCardData.push({
            medicationName: null,
            quantity: null,
            UOMId: null,
            UOM: null,
            fileName: null,
            fileData: null,
            quantityValidationMsg: null,
          });

          // Finally, set the current state to the new list
          setMedicationCardDataList(prevListOfMedicationCardData);

          const prevMedicationNameList = [...medicationNameList];
          prevMedicationNameList.push(null);
          setMedicationNameList(prevMedicationNameList);

          const prevLoading = [...isLoading];
          prevLoading.push(false);
          setIsLoading(prevLoading);
          //file upload error state
          dispatch(
            setFileUploadPrescriptionError([
              ...fileUploadPrescriptionErrorArray,
              [false],
            ]),
          );

          //file upload details state
          dispatch(
            setFileUploadDetails([
              ...fileUploadDetails,
              [{ ...getInitialUploadState() }],
            ]),
          );
        }}
        onClickDeleteMedicationCard={(index: number) => {
          // Clone the state of the current list of medication data
          const prevListOfMedicationCardData = [...medicationCardDataList];

          // Set the object with an empty medication card
          if ([...medicationCardDataList].length !== 1) {
            prevListOfMedicationCardData.splice(index, 1);
          }

          const prevListOfMedicationName = [...medicationNameList];
          if ([...prevListOfMedicationName].length !== 1) {
            prevListOfMedicationName.splice(index, 1);
          }
          setMedicationNameList(prevListOfMedicationName);

          for (var i: number = 0; i < prevListOfMedicationName.length; i++) {
            prevListOfMedicationCardData[i].medicationName =
              prevListOfMedicationName[i];
          }
          setMedicationCardDataList(prevListOfMedicationCardData);

          const prevLoading = [...isLoading];
          if ([...isLoading].length !== 1) {
            prevLoading.splice(index, 1);
          }
          setIsLoading(prevLoading);
          //file upload error state
          const newFileUploadPrescriptionError = [
            ...fileUploadPrescriptionErrorArray,
          ];
          newFileUploadPrescriptionError.splice(index, 1);
          dispatch(
            setFileUploadPrescriptionError([...newFileUploadPrescriptionError]),
          );

          //file upload details state
          const newFileUploadDetails = [...fileUploadDetails];
          newFileUploadDetails.splice(index, 1);
          dispatch(setFileUploadDetails([...newFileUploadDetails]));
        }}
        disableMedicationCardRegion={
          isLoading.find((x) => x === true) !== undefined
        }
      ></TopupPrescriptionBody>

      {/* NEXT PAGE CONTROLS */}
      <StepPageButtonsFooter
        type={isEditablePage ? "EDIT" : "NEXT_STEP"}
        isNextButtonDisabled={disableNext()}
        pathToNextStep={pathToNextStep}
        onNextStep={() => {
          saveFormState();
        }}
      />
    </StepPageLayoutContainer>
  );
};

export default TopupPrescription;

const validateNumberAccordingToDuration = (
  number: number | null,
  UOMDuration: string | null,
) => {
  if (number === null) {
    return null;
  }
  if (UOMDuration === "Day(s)" && number > 365) {
    return "Day(s) cannot be more than 365";
  } else if (UOMDuration === "Week(s)" && number > 52) {
    return "Week(s) cannot be more than 52";
  } else if (UOMDuration === "Month(s)" && number > 12) {
    return "Month(s) cannot be more than 12";
  } else if (number <= 0) {
    return "Invalid input";
  } else return null;
};
