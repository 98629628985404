/**
 *
 * validate if the input name has the correct name format
 * return true if the input name has correct format, false otherwise
 *
 * @param {string} name input string to be validated
 */
export const validateName = (name: string | null) => {
  if (name) {
    var regexp = new RegExp(
      /^[a-zA-Z\s][a-zA-Z0-9\s\\/`'´\u2018\u2019.,:_\-\\]*$/,
    );
    return regexp.test(name);
  } else {
    return false;
  }
};
