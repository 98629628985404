import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectMobileMessage } from "lib/redux/navigation/selectors";
import { parseJson } from "lib/util/StringUtil/jsonParser/parseJson";
import { useCallback, useEffect, useState } from "react";
import { MessageActionType } from "../messageActions.types";
import { setMobileMessage } from "lib/redux/navigation/navigationSlice";

export const useGetMessageAction = () => {
  const dispatch = useAppDispatch();
  const mobileMessage = useAppSelector(selectMobileMessage);

  const [messageActionObj, setMessagActionObj] =
    useState<MessageActionType | null>(null);

  const consumeMessageActionObj = useCallback(() => {
    setMessagActionObj(null);
  }, []);

  useEffect(() => {
    if (mobileMessage) {
      const parsedMsg: MessageActionType = parseJson(mobileMessage);
      setMessagActionObj(parsedMsg);
      dispatch(setMobileMessage(""));
    }
  }, [dispatch, mobileMessage]);

  return { messageActionObj, consumeMessageActionObj };
};
