import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    linkedCardDivider: {
      borderBottom: `2px solid ${theme.palette.grey[100]}`,
    },
  } satisfies SxObjType;
};
export { sxStyles };
