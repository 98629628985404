// ============================
// CONSTANTS
// Step labels in the Payment flow
// ============================

const OVERVIEW_STEP = "Overview";
const PAYMENT_STEP = "Payment";
const RECEIPT_STEP = "Receipt";

export const PAYMENT_STEP_LABELS = [OVERVIEW_STEP, PAYMENT_STEP, RECEIPT_STEP];
