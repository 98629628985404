import { connect } from "react-redux";
import { RootState } from "lib/redux/root/redux.types";
import AvailableBundledSlotsSection from "./AvailableBundledSlotsSection";

const mapStateToProps = (state: RootState) => {
  const memberIdentifier = state.user.memberIdentifier;
  if (
    state.appointments.rescheduleLinkedAppointment.selectedLinkedAppointment
  ) {
    // rescheduling a selected linked appointment, so will be using the appointment stored in selectedLinkedAppointment
    const linkedAppointment =
      state.appointments.rescheduleLinkedAppointment.selectedLinkedAppointment;

    const parentVisitToSearchParam = [linkedAppointment.slotId];

    // Flow for multiple linked appointments
    if (linkedAppointment.linkedAppointments) {
      const childrenVisitToSearchParams =
        linkedAppointment.linkedAppointments?.map((appt) => {
          if (appt.slotId) {
            return appt.slotId;
          } else {
            return null;
          }
        });

      return {
        memberIdentifier,
        visitTypeId: linkedAppointment.visitTypeId,
        appointmentId: linkedAppointment.appointmentId,
        targetSystem: linkedAppointment.sourceSystem,
        institutionCode: linkedAppointment.institutionCode,
        departmentCode: linkedAppointment.departmentCode,
        slotIdList: [
          ...parentVisitToSearchParam,
          ...childrenVisitToSearchParams,
        ],
        isBundledLinkedAppointments: true,
        expectedOrderDate: null,
      };
    } else {
      // Flow for single linked appointment
      return {
        memberIdentifier,
        visitTypeId: null,
        appointmentId: linkedAppointment.appointmentId,
        targetSystem: linkedAppointment.sourceSystem,
        institutionCode: linkedAppointment.institutionCode,
        departmentCode: linkedAppointment.departmentCode,
        slotIdList: [...parentVisitToSearchParam],
        isBundledLinkedAppointments: true,
        expectedOrderDate: null,
      };
    }
  } else if (state.appointments.appointmentMeta.isHsgAppt) {
    return {
      memberIdentifier,
      appointmentId: state.appointments.appointmentMeta.appointmentId,
      visitTypeId: state.appointments.appointmentMeta.visitTypeId,
      targetSystem: state.appointments.appointmentMeta.targetSystem,
      institutionCode: state.appointments.appointmentMeta.institutionCode,
      departmentCode: state.appointments.appointmentMeta.departmentCode,
      isBundledLinkedAppointments: true,
      slotIdList: null,
      expectedOrderDate: null,
    };
  }
  return {
    memberIdentifier,
    appointmentId: state.appointments.appointmentMeta.appointmentId,
    visitTypeId: state.appointments.appointmentMeta.visitTypeId,
    targetSystem: state.appointments.appointmentMeta.targetSystem,
    institutionCode: state.appointments.appointmentMeta.institutionCode,
    departmentCode: state.appointments.appointmentMeta.departmentCode,
    slotIdList: null,
    isBundledLinkedAppointments: false,
    expectedOrderDate: new Date(),
  };
};

export default connect(mapStateToProps)(AvailableBundledSlotsSection);
