import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    cardDivider: {
      margin: theme.spacing(0, 1),
      borderTop: `1px solid ${theme.palette.grey[100]}`,
    },
  } satisfies SxObjType;
};

export { sxStyles };
