import { useEffect, useState } from "react";
import StepPageLayoutContainer from "ui/medrefill/components/common/StepPageLayout/StepPageLayoutContainer";
import { validateName } from "lib/util/ValidatorUtil/nameValidator/nameValidator";
import { validateNric } from "lib/util/ValidatorUtil/nricValidator/nricValidator";
import { validatePhoneNumber } from "lib/util/ValidatorUtil/phoneNumberValidator/phoneNumberValidator";
import { validateEmail } from "lib/util/ValidatorUtil/emailValidator/emailValidator";
import { ParticularsProps } from "./Particulars.types";
import ParticularsBody from "./ParticularsBody";
import { ParticularsValidationStruct } from "./ParticularsBody.types";
import StepPageButtonsFooter from "../StepPageButtonsFooter/StepPageButtonsFooter";
import { sameStringTest } from "lib/util/StringUtil/sameStringTest/sameStringTest";
import { maskNric } from "ui/appointment/components/common/user/UserProfile/maskNric";

const Particulars = ({
  pathToNextStep,
  isNormalFlow,
  defaultPatientName,
  defaultPatientNric,
  defaultRequesterName,
  defaultRequesterNric,
  defaultContactNumber,
  defaultEmail,
  defaultIsPatient,
  profileContactNumber,
  profileEmail,
  onSelectParticulars,
}: ParticularsProps) => {
  const [patientName, setPatientName] = useState<string | null>(
    defaultPatientName || "",
  );
  const [patientNric, setPatientNric] = useState<string | null>(
    defaultPatientNric || "",
  );
  const [contactNumber, setContactNumber] = useState<string | null>(
    defaultContactNumber,
  );
  const [email, setEmail] = useState<string | null>(defaultEmail);

  const [checkedIsPatient, setCheckedIsPatient] = useState(defaultIsPatient);

  const [validations, setValidations] = useState<ParticularsValidationStruct>({
    patientName: true,
    patientNric: true,
    contact: true,
    email: true,
  });

  useEffect(() => {
    if (!defaultContactNumber) {
      setContactNumber(profileContactNumber || "");
    }
    if (!defaultEmail) {
      setEmail(profileEmail);
    }
  }, [profileContactNumber, profileEmail, defaultContactNumber, defaultEmail]);

  const handlePatientNameChange = (name: string) => {
    setPatientName(name);
    setValidations({
      ...validations,
      patientName: validateName(name),
    });
  };

  const handlePatientNricChange = (nric: string) => {
    setPatientNric(nric);
    setValidations({
      ...validations,
      patientNric: validateNric(nric),
    });
  };

  const handleContactNumberChange = (contactNumber: string) => {
    setContactNumber(contactNumber);
    setValidations({
      ...validations,
      contact: validatePhoneNumber(contactNumber),
    });
  };

  const handleEmailChange = (email: string) => {
    setEmail(email);
    setValidations({
      ...validations,
      email: validateEmail(email),
    });
  };

  const handleChecked = (checked: boolean) => {
    setCheckedIsPatient(checked);
    //the contact and email should be loaded with patient profile data again when switch is toggled
    setContactNumber(profileContactNumber || "");
    setEmail(profileEmail || "");

    //when the form is switched from patient to requestor,
    //the input fields should be cleared
    //name and nric set to empty string instead of NULL to avoid UI-bug
    if (!checked) {
      setPatientName("");
      setPatientNric("");
    } else {
      setPatientName("");
      setPatientNric("");
      setContactNumber(profileContactNumber || "");
      setEmail(profileEmail || "");
    }

    // reset validation states
    setValidations({
      patientName: true,
      patientNric: true,
      contact: true,
      email: true,
    });
  };

  const disableNext = () => {
    let disableNext = true;
    if (checkedIsPatient) {
      if (contactNumber && validations.contact && email && validations.email) {
        disableNext = false;
      }
    } else {
      if (
        patientName &&
        patientNric &&
        contactNumber &&
        email &&
        validations.patientName &&
        validations.patientNric &&
        validations.contact &&
        validations.email &&
        defaultRequesterNric &&
        !sameStringTest(maskNric(patientNric), maskNric(defaultRequesterNric))
      ) {
        disableNext = false;
      }
    }
    return disableNext;
  };

  return (
    <StepPageLayoutContainer
      editableFlag={false}
      isNextButtonDisabled={false}
      currentStepIndex={0}
      shouldShowButtonsFooter={false}
      pathToNextStep={pathToNextStep ?? ""}
      onNextStep={() => {}}
    >
      <ParticularsBody
        disableToggleAndPatientFields={!isNormalFlow}
        patientName={patientName || ""}
        patientNric={patientNric || ""}
        requesterName={defaultRequesterName}
        requesterNric={defaultRequesterNric}
        contactNumber={contactNumber || ""}
        email={email || ""}
        userIsPatient={isNormalFlow}
        isPatient={checkedIsPatient}
        validation={validations}
        onChangePatientName={(currentValue) =>
          handlePatientNameChange(currentValue)
        }
        onChangePatientNric={(currentValue) =>
          handlePatientNricChange(currentValue)
        }
        onChangeContactNumber={(currentValue) =>
          handleContactNumberChange(currentValue)
        }
        onChangeEmail={(currentValue) => handleEmailChange(currentValue)}
        onChangeIsPatient={() => handleChecked(!checkedIsPatient)}
      />

      {/* NEXT PAGE CONTROLS */}
      <StepPageButtonsFooter
        type="NEXT_STEP"
        isNextButtonDisabled={disableNext()}
        pathToNextStep={pathToNextStep}
        onNextStep={() => {
          onSelectParticulars({
            requesterId: null, // TODO: Find out if is from API, and remove or set accordingly
            requesterNric: defaultRequesterNric,
            requesterName: defaultRequesterName,
            patientName,
            patientNric,
            contactNumber,
            isPatient: checkedIsPatient,
            email,
            postalCode: null,
            unitNo: null,
            blockNo: null,
            address: null,
          });
        }}
      />
    </StepPageLayoutContainer>
  );
};

export default Particulars;
