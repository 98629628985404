import * as TITLES from "../../routing/pageTitles/pageTitles";
import { Events } from "./logEvent.types";

/**
 * can log up to 500 different Analytics Event types in the app.
 * There is no limit on the total volume of events the app logs.
 * Note that event names are case-sensitive and that logging two events whose names differ only in case
 * will result in two distinct events.
 * Note: event names have a 40 character limit on firebase console
 */

export const EVENTS = {
  // =====================
  // GENERAL EVENTS
  // =====================
  SELECT_LIVECHAT_APPOINTMENT_MODULE: {
    eventName: "select_livechat_appointment_module",
    params: {
      eventType: "Click",
      screenTitle: "Error Modal",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  // ======================
  // APPOINTMENT MODULE
  // ======================

  VIEW_APPOINTMENTS_LANDING: {
    eventName: "view_appointments_landing",
    params: {
      eventType: "View",
      screenTitle: "Appointments List",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_APPOINTMENTS_UPCOMING: {
    eventName: "view_appointments_upcoming",
    params: {
      eventType: "View",
      screenTitle: "Upcoming Appointments",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_APPOINTMENTS_REGISTERED: {
    eventName: "view_appointments_registered",
    params: {
      eventType: "View",
      screenTitle: "Registered Appointments",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_APPOINTMENTS_MISSED: {
    eventName: "view_appointments_missed",
    params: {
      eventType: "View",
      screenTitle: "Missed Appointments",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_APPOINTMENTS_OPEN: {
    eventName: "view_appointments_open",
    params: {
      eventType: "View",
      screenTitle: "Open Appointments",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_APPOINTMENT_INSTITUTION: {
    eventName: "view_appointment_institution",
    params: {
      eventType: "View",
      screenTitle: "Make an Appointment - Select a Healthcare Institution",
      action: "Make an Appointment",
      intent: "Select a Healthcare Institution",
      subIntent: null,
    },
  },

  VIEW_APPOINTMENT_SERVICE: {
    eventName: "view_appointment_service",
    params: {
      eventType: "View",
      screenTitle: "Make an Appointment - Select a Service",
      action: "Make an Appointment",
      intent: "Select a Service",
      subIntent: null,
    },
  },

  VIEW_APPOINTMENT_TRIAGE: {
    eventName: "view_appointment_triage",
    params: {
      eventType: "View",
      screenTitle: "Make an Appointment - Triage",
      action: "Make an Appointment",
      intent: "Triage",
      subIntent: null,
    },
  },

  VIEW_APPOINTMENT_SAMEDAY: {
    eventName: "view_appointment_sameday",
    params: {
      eventType: "View",
      screenTitle:
        "Make an Appointment - Select a time slot - Same-day appointment",
      action: "Make an Appointment",
      intent: "Select a time slot",
      subIntent: "Same-day appointment",
    },
  },

  VIEW_APPOINTMENT_ADVANCE: {
    eventName: "view_appointment_advance",
    params: {
      eventType: "View",
      screenTitle:
        "Make an Appointment - Select a time slot - Appointment in advance",
      action: "Make an Appointment",
      intent: "Select a time slot",
      subIntent: "Appointment in advance",
    },
  },

  VIEW_APPOINTMENT_DETAILS: {
    eventName: "view_appointment_details",
    params: {
      eventType: "View",
      screenTitle: "Make an Appointment - Details",
      action: "Make an Appointment",
      intent: "Proceed after selecting a time slot",
      subIntent: null,
    },
  },

  VIEW_APPOINTMENT_CONFIRMATION: {
    eventName: "view_appointment_confirmation",
    params: {
      eventType: "View",
      screenTitle: "Make an Appointment - Confirmation",
      action: "Make an Appointment",
      intent: "Confirmation",
      subIntent: null,
    },
  },

  VIEW_APPOINTMENT_FORM: {
    eventName: "view_appointment_form",
    params: {
      eventType: "View",
      screenTitle: "Make an Appointment - Web Form",
      action: "Make an Appointment",
      intent: "Web Form",
      subIntent: null,
    },
  },

  VIEW_APPOINTMENT_MAMMOGRAM: {
    eventName: "view_appointment_mammogram",
    params: {
      eventType: "View",
      screenTitle: "Make an Appointment - Mammogram Request Form",
      action: "Make an Appointment",
      intent: "Mammogram Request",
      subIntent: null,
    },
  },

  VIEW_RESCHEDULE_SAMEDAY: {
    eventName: "view_reschedule_sameday",
    params: {
      eventType: "View",
      screenTitle:
        "Reschedule an Appointment - Select new time slot - Same-day appointment",
      action: "Reschedule an Appointment",
      intent: "Select new time slot",
      subIntent: "Same-day appointment",
    },
  },

  VIEW_RESCHEDULE_ADVANCE: {
    eventName: "view_reschedule_advance",
    params: {
      eventType: "View",
      screenTitle:
        "Reschedule an Appointment - Select new time slot - Appointment in advance",
      action: "Reschedule an Appointment",
      intent: "Select new time slot",
      subIntent: "Appointment in advance",
    },
  },

  VIEW_RESCHEDULE_DETAILS: {
    eventName: "view_reschedule_details",
    params: {
      eventType: "View",
      screenTitle: "Reschedule an Appointment - Details",
      action: "Reschedule an Appointment",
      intent: "Proceed after selecting a time slot",
      subIntent: null,
    },
  },

  VIEW_RESCHEDULE_CONFIRMATION: {
    eventName: "view_reschedule_confirmation",
    params: {
      eventType: "View",
      screenTitle: "Reschedule an Appointment - Confirmation",
      action: "Reschedule an Appointment",
      intent: "Confirmation",
      subIntent: null,
    },
  },

  VIEW_RESCHEDULE_FORM: {
    eventName: "view_reschedule_form",
    params: {
      eventType: "View",
      screenTitle: "Reschedule an Appointment - Web Form",
      action: "Reschedule an Appointment",
      intent: "Web Form",
      subIntent: null,
    },
  },

  VIEW_CANCEL_CONFIRMATION: {
    eventName: "view_cancel_confirmation",
    params: {
      eventType: "View",
      screenTitle: "Cancel an Appointment - Confirmation",
      action: "Cancel an Appointment",
      intent: "Confirmation",
      subIntent: null,
    },
  },

  VIEW_CANCEL_FORM: {
    eventName: "view_cancel_form",
    params: {
      eventType: "View",
      screenTitle: "Cancel an Appointment - Web Form",
      action: "Cancel an Appointment",
      intent: "Web Form",
      subIntent: null,
    },
  },

  VIEW_REGISTRATION_TRIAGE: {
    eventName: "view_registration_triage",
    params: {
      eventType: "View",
      screenTitle: "Registration - Triage Questions",
      action: "Register an Appointment",
      intent: "Triage Questions",
      subIntent: null,
    },
  },

  VIEW_REGISTRATION_QUEUECHIT: {
    eventName: "view_registration_queuechit",
    params: {
      eventType: "View",
      screenTitle: "Registration - Queue Chit and Journey",
      action: "Register an Appointment",
      intent: "Queue Chit and Journey",
      subIntent: null,
    },
  },

  SELECT_OK_I_HAVE_ARRIVED: {
    eventName: "select_ok_i_have_arrived",
    params: {
      eventType: "Click",
      screenTitle: "Registration - I have arrived",
      action: "Successfully called I have arrived",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_OK_I_HAVE_ARRIVED_UNSUCCESSFUL: {
    eventName: "select_ok_i_have_arrived_unsuccessful",
    params: {
      eventType: "Click",
      screenTitle: "Registration - I have arrived",
      action: "Unsuccessfully called I have arrived",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_I_HAVE_ARRIVED_APPOINTMENT_LANDING: {
    eventName: "select_i_have_arrived_appointment_landing",
    params: {
      eventType: "Click",
      screenTitle: "Appointment Landing",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  SELECT_CREATE_APPOINTMENT_PARTICULARS_CREATE_NOW: {
    eventName: "click_create_appt_particulars_create_now",
    params: {
      eventType: "Click",
      screenTitle: "Appointment Institution Selection",
      action: "click create now on modal of appointment selection page",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_CREATE_APPOINTMENT_PARTICULARS_OFFLINE_REQUEST: {
    eventName: "click_create_appt_particulars_offline_re",
    params: {
      eventType: "Click",
      screenTitle: "Appointment Institution Selection",
      action:
        "click make offline request on modal of appointment selection page",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_APPT_REBOOK_APPT: {
    eventName: "click_appt_rebook_appt",
    params: {
      eventType: "Click",
      screenTitle: "Appointment",
      action: "click re-book appointment for missed appointment",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_APPT_BOOK_NEXT_APPT: {
    eventName: "click_appt_book_next_appt",
    params: {
      eventType: "Click",
      screenTitle: "Appointment",
      action: "click book appointment for open appointment",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_APPT_SHARE_VIDEO_LINK: {
    eventName: "click_appt_share_video_link",
    params: {
      eventType: "Click",
      screenTitle: "Appointment",
      action: "click share video link on vcp appointment",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_APPT_LEARN_USE_VIDEO_CONSULT: {
    eventName: "click_appt_learn_use_video_consult",
    params: {
      eventType: "Click",
      screenTitle: "Appointment",
      action: "click learn to use video consult link on vcp appointment",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_APPT_TEST_SETUP: {
    eventName: "click_appt_test_setup",
    params: {
      eventType: "Click",
      screenTitle: "Appointment",
      action: "click test your setup link on vcp appointment",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_APPT_OTHER_PROVIDERS_APPT: {
    eventName: "click_appt_other_providers_appt",
    params: {
      eventType: "Click",
      screenTitle: "Appointment",
      action: "click appointments link in other providers footer",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_APPT_REGISTER_SUBMIT_MOBILE_NO: {
    eventName: "click_appt_register_submit_mobile_no",
    params: {
      eventType: "Click",
      screenTitle: "Appointment",
      action: "click submit for appointment mobile number after registration",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_CLOSE_APPT_QUESTIONNAIRE_ERROR: {
    eventName: "click_close_appt_questionnaire_error",
    params: {
      eventType: "Click",
      screenTitle: "Appointment",
      action: "click close modal for appointment questionnaire error",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_SHOW_BARCODE_Q_JOURNEY: {
    eventName: "click_show_barcode_q_journey",
    params: {
      eventType: "Click",
      screenTitle: "Appointment",
      action: "click button to show barcode",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_APPT_CANCEL_HEALTHMATTERS_BACK: {
    eventName: "click_appt_cancel_healthmatters_back",
    params: {
      eventType: "Click",
      screenTitle: "Appointment",
      action: "click back button on 'Your health matters' popup cancel",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_APPT_CANCEL_HEALTHMATTERS_PROCEED: {
    eventName: "click_appt_cancel_healthmatters_proceed",
    params: {
      eventType: "Click",
      screenTitle: "Appointment",
      action: "click proceed button on 'Your health matters' popup cancel",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_APPT_RESCH_HEALTHMATTERS_BACK: {
    eventName: "click_appt_resch_healthmatters_back",
    params: {
      eventType: "Click",
      screenTitle: "Appointment",
      action: "click cancel button on 'Your health matters' popup resch",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_APPT_RESCH_HEALTHMATTERS_PROCEED: {
    eventName: "click_appt_resch_healthmatters_proceed",
    params: {
      eventType: "Click",
      screenTitle: "Appointment",
      action: "click proceed button on 'Your health matters' popup resch",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_APPT_SUBMIT_A_REQUEST_ONLINE: {
    eventName: "click_appt_submit_a_request_online",
    params: {
      eventType: "Click",
      screenTitle: "Appointment",
      action: "click submit a request online hyperlink on services page",
      intent: null,
      subIntent: null,
    },
  },

  // ============================
  // MEDREFILL MODULE REFILL FLOW
  // ============================

  SELECT_REFILL_NEW_REQUEST: {
    eventName: "select_refill_new_request",
    params: {
      eventType: "Click",
      screenTitle: "Medications - Select Request",
      action: "Make Medication Refill Request",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_MY_CHART: {
    eventName: "click_medication_view_medication_record",
    params: {
      eventType: "Click",
      screenTitle: "Medications - Select Request",
      action: "select 'View Medication Record' icon",
      intent: "Select My Chart",
      subIntent: null,
    },
  },

  VIEW_NEW_REFILL_INSTITUTIONS: {
    eventName: "view_new_refill_institution",
    params: {
      eventType: "View",
      screenTitle: "Medication Refill - Select Prescribing Institution",
      action: "Make Medication Refill Request",
      intent: "Select Prescribing Institution",
      subIntent: null,
    },
  },

  VIEW_NEW_REFILL_PARTICULARS: {
    eventName: "view_new_refill_particulars",
    params: {
      eventType: "View",
      screenTitle: "Medication Refill - Particulars",
      action: "Make Medication Refill Request",
      intent: "Particulars",
      subIntent: null,
    },
  },

  VIEW_NEW_REFILL_PRESCRIPTION: {
    eventName: "view_new_refill_prescription",
    params: {
      eventType: "View",
      screenTitle: "Medication Refill - Prescription",
      action: "Make Medication Refill Request",
      intent: "Prescription",
      subIntent: null,
    },
  },

  VIEW_NEW_REFILL_QUANTITY: {
    eventName: "view_new_refill_quantity",
    params: {
      eventType: "View",
      screenTitle: "Medication Refill - Quantity",
      action: "Make Medication Refill Request",
      intent: "Quantity",
      subIntent: null,
    },
  },

  VIEW_NEW_REFILL_COLLECTION: {
    eventName: "view_new_refill_collection",
    params: {
      eventType: "View",
      screenTitle: "Medication Refill - Collection",
      action: "Make Medication Refill Request",
      intent: "Collection",
      subIntent: null,
    },
  },

  VIEW_NEW_REFILL_PAYMENT: {
    eventName: "view_new_refill_payment",
    params: {
      eventType: "View",
      screenTitle: "Medication Refill - Payment",
      action: "Make Medication Refill Request",
      intent: "Payment",
      subIntent: null,
    },
  },

  VIEW_NEW_REFILL_ORDER_SUMMARY: {
    eventName: "view_new_refill_order_summary",
    params: {
      eventType: "View",
      screenTitle: "Medication Refill - Order Summary",
      action: "Make Medication Refill Request",
      intent: "Order Summary",
      subIntent: null,
    },
  },

  NEW_REFILL_SUBMIT: {
    eventName: "new_refill_submit",
    params: {
      eventType: "Click",
      screenTitle: "Medication Refill - Order Summary",
      action: "Make Medication Refill Request",
      intent: "Request Submitted Successfully",
      subIntent: null,
    },
  },

  VIEW_PAST_REQUEST: {
    eventName: "view_past_request",
    params: {
      eventType: "View",
      screenTitle: "Medication - View Past Requests",
      action: "View Medication Past Requests",
      intent: null,
      subIntent: null,
    },
  },

  VIEW_REFILL_PAST_REQUEST_DETAIL: {
    eventName: "view_refill_past_request_detail",
    params: {
      eventType: "View",
      screenTitle: "Medication Refill - View Past Requests - Details",
      action: "View Past Request Details",
      intent: null,
      subIntent: null,
    },
  },

  REORDER_REFILL_PAST_REQUEST: {
    eventName: "reorder_past_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Refill - View Past Requests - Details",
      action: "Repeat past medication refill request",
      intent: null,
      subIntent: null,
    },
  },

  REORDER_LATEST_REFILL_PAST_REQUEST: {
    eventName: "reorder_latest_refill_past_request",
    params: {
      eventType: "Click",
      screenTitle: "Medications - Select Request",
      action: "Repeat latest past medication refill request",
      intent: null,
      subIntent: null,
    },
  },

  VIEW_REORDER_REFILL_PRESCRIPTION: {
    eventName: "view_reorder_refill_prescription",
    params: {
      eventType: "View",
      screenTitle: "Medication Refill - Prescription",
      action: "Repeat past medication refill request",
      intent: "Prescription",
      subIntent: null,
    },
  },

  VIEW_REORDER_REFILL_QUANTITY: {
    eventName: "view_reorder_refill_quantity",
    params: {
      eventType: "View",
      screenTitle: "Medication Refill - Quantity",
      action: "Repeat past medication refill request",
      intent: "Quantity",
      subIntent: null,
    },
  },

  VIEW_REORDER_REFILL_COLLECTION: {
    eventName: "view_reorder_refill_collection",
    params: {
      eventType: "View",
      screenTitle: "Medication Refill - Collection",
      action: "Repeat past medication refill request",
      intent: "Collection",
      subIntent: null,
    },
  },

  VIEW_REORDER_REFILL_PAYMENT: {
    eventName: "view_reorder_refill_payment",
    params: {
      eventType: "View",
      screenTitle: "Medication Refill - Payment",
      action: "Repeat past medication refill request",
      intent: "Payment",
      subIntent: null,
    },
  },

  VIEW_REORDER_REFILL_ORDER_SUMMARY: {
    eventName: "view_reorder_refill_order_summary",
    params: {
      eventType: "View",
      screenTitle: "Medication Refill - Order Summary",
      action: "Repeat past medication refill request",
      intent: "Order Summary",
      subIntent: null,
    },
  },

  REORDER_REFILL_SUBMIT: {
    eventName: "reorder_refill_submit",
    params: {
      eventType: "Click",
      screenTitle: "Medication Refill - Order Summary",
      action: "Repeat past medication refill request",
      intent: "Request Submitted Successfully",
      subIntent: null,
    },
  },

  SUBMIT_ANOTHER_REFILL_REQUEST: {
    eventName: "submit_another_refill_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Refill - Order Summary",
      action: "select 'submit another request' button",
      intent: null,
      subIntent: null,
    },
  },

  CANCEL_REQUEST_AT_REFILL_PARTICULARS: {
    eventName: "cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Refill - Particulars",
      action: "select 'CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  CANCEL_REQUEST_AT_REFILL_PRESCRIPTION: {
    eventName: "cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Refill - Prescription",
      action: "select 'CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  CANCEL_REQUEST_AT_REFILL_QUANTITY: {
    eventName: "cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Refill - Quantity",
      action: "select 'CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  CANCEL_REQUEST_AT_REFILL_COLLECTION: {
    eventName: "cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Refill - Collection",
      action: "select 'CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  CANCEL_REQUEST_AT_REFILL_PAYMENT: {
    eventName: "cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Refill - Payment",
      action: "select 'CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  CONFIRM_CANCEL_REQUEST_AT_REFILL_PARTICULARS: {
    eventName: "confirm_cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Refill - Particulars",
      action: "select 'Yes, CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  CONFIRM_CANCEL_REQUEST_AT_REFILL_PRESCRIPTION: {
    eventName: "confirm_cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Refill - Prescription",
      action: "select 'Yes, CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  CONFIRM_CANCEL_REQUEST_AT_REFILL_QUANTITY: {
    eventName: "confirm_cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Refill - Quantity",
      action: "select 'Yes, CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  CONFIRM_CANCEL_REQUEST_AT_REFILL_COLLECTION: {
    eventName: "confirm_cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Refill - Collection",
      action: "select 'Yes, CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  CONFIRM_CANCEL_REQUEST_AT_REFILL_PAYMENT: {
    eventName: "confirm_cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Refill - Payment",
      action: "select 'Yes, CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },
  // ==========================
  // MEDREFILL MODULE TOPUP FLOW
  // =========================

  SELECT_TOPUP_NEW_REQUEST: {
    eventName: "select_topup_new_request",
    params: {
      eventType: "Click",
      screenTitle: "Medications - Select Request",
      action: "Make Medication Top-Up Request",
      intent: null,
      subIntent: null,
    },
  },

  VIEW_NEW_TOPUP_INSTITUTIONS: {
    eventName: "view_new_topup_institution",
    params: {
      eventType: "View",
      screenTitle: "Medication Top-Up - Select Prescribing Institution",
      action: "Make Medication Top-Up Request",
      intent: "Select Prescribing Institution",
      subIntent: null,
    },
  },

  VIEW_NEW_TOPUP_PARTICULARS: {
    eventName: "view_new_topup_particulars",
    params: {
      eventType: "View",
      screenTitle: "Medication Top-Up - Particulars",
      action: "Make Medication Top-Up Request",
      intent: "Particulars",
      subIntent: null,
    },
  },

  VIEW_NEW_TOPUP_PRESCRIPTION: {
    eventName: "view_new_topup_prescription",
    params: {
      eventType: "View",
      screenTitle: "Medication Top-Up - Prescription",
      action: "Make Medication Top-Up Request",
      intent: "Prescription",
      subIntent: null,
    },
  },

  VIEW_NEW_TOPUP_COLLECTION: {
    eventName: "view_new_topup_collection",
    params: {
      eventType: "View",
      screenTitle: "Medication Top-Up - Collection",
      action: "Make Medication Top-Up Request",
      intent: "Collection",
      subIntent: null,
    },
  },

  VIEW_NEW_TOPUP_PAYMENT: {
    eventName: "view_new_topup_payment",
    params: {
      eventType: "View",
      screenTitle: "Medication Top-Up - Payment",
      action: "Make Medication Top-Up Request",
      intent: "Payment",
      subIntent: null,
    },
  },

  VIEW_NEW_TOPUP_ORDER_SUMMARY: {
    eventName: "view_new_topup_order_summary",
    params: {
      eventType: "View",
      screenTitle: "Medication Top-Up - Order Summary",
      action: "Make Medication Top-Up Request",
      intent: "Order Summary",
      subIntent: null,
    },
  },

  NEW_TOPUP_SUBMIT: {
    eventName: "new_topup_submit",
    params: {
      eventType: "Click",
      screenTitle: "Medication Top-Up - Order Summary",
      action: "Make Medication Top-Up Request",
      intent: "Request Submitted Successfully",
      subIntent: null,
    },
  },

  VIEW_TOPUP_PAST_REQUEST_DETAIL: {
    eventName: "view_topup_past_request_detail",
    params: {
      eventType: "View",
      screenTitle: "Medication Top-Up - View Past Requests - Details",
      action: "View Past Request Details",
      intent: null,
      subIntent: null,
    },
  },

  REORDER_TOPUP_PAST_REQUEST: {
    eventName: "reorder_past_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Top-Up - View Past Requests - Details",
      action: "Repeat past medication topup request",
      intent: null,
      subIntent: null,
    },
  },

  REORDER_LATEST_TOPUP_PAST_REQUEST: {
    eventName: "reorder_latest_topup_past_request",
    params: {
      eventType: "Click",
      screenTitle: "Medications - Select Request",
      action: "Repeat latest past medication topup request",
      intent: null,
      subIntent: null,
    },
  },

  VIEW_REORDER_TOPUP_PRESCRIPTION: {
    eventName: "view_reorder_topup_prescription",
    params: {
      eventType: "View",
      screenTitle: "Medication Top-Up - Prescription",
      action: "Repeat past medication topup request",
      intent: "Prescription",
      subIntent: null,
    },
  },

  VIEW_REORDER_TOPUP_COLLECTION: {
    eventName: "view_reorder_topup_collection",
    params: {
      eventType: "View",
      screenTitle: "Medication Top-Up - Collection",
      action: "Repeat past medication topup request",
      intent: "Collection",
      subIntent: null,
    },
  },

  VIEW_REORDER_TOPUP_PAYMENT: {
    eventName: "view_reorder_topup_payment",
    params: {
      eventType: "View",
      screenTitle: "Medication Top-Up - Payment",
      action: "Repeat past medication topup request",
      intent: "Payment",
      subIntent: null,
    },
  },

  VIEW_REORDER_TOPUP_ORDER_SUMMARY: {
    eventName: "view_reorder_topup_order_summary",
    params: {
      eventType: "View",
      screenTitle: "Medication Top-Up - Order Summary",
      action: "Repeat past medication topup request",
      intent: "Order Summary",
      subIntent: null,
    },
  },

  REORDER_TOPUP_SUBMIT: {
    eventName: "reorder_topup_submit",
    params: {
      eventType: "Click",
      screenTitle: "Medication Top-Up - Order Summary",
      action: "Repeat past medication topup request",
      intent: "Request Submitted Successfully",
      subIntent: null,
    },
  },

  SUBMIT_ANOTHER_TOPUP_REQUEST: {
    eventName: "submit_another_topup_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Top-Up - Order Summary",
      action: "select 'submit another request' button",
      intent: null,
      subIntent: null,
    },
  },

  CANCEL_REQUEST_AT_TOPUP_PARTICULARS: {
    eventName: "cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Top-Up - Particulars",
      action: "select 'CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  CANCEL_REQUEST_AT_TOPUP_PRESCRIPTION: {
    eventName: "cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Top-Up - Prescription",
      action: "select 'CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  CANCEL_REQUEST_AT_TOPUP_COLLECTION: {
    eventName: "cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Top-Up - Collection",
      action: "select 'CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  CANCEL_REQUEST_AT_TOPUP_PAYMENT: {
    eventName: "cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Top-Up - Payment",
      action: "select 'CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  CONFIRM_CANCEL_REQUEST_AT_TOPUP_PARTICULARS: {
    eventName: "confirm_cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Top-Up - Particulars",
      action: "select 'Yes, CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  CONFIRM_CANCEL_REQUEST_AT_TOPUP_PRESCRIPTION: {
    eventName: "confirm_cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Top-Up - Prescription",
      action: "select 'Yes, CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  CONFIRM_CANCEL_REQUEST_AT_TOPUP_COLLECTION: {
    eventName: "confirm_cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Top-Up - Collection",
      action: "select 'Yes, CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  CONFIRM_CANCEL_REQUEST_AT_TOPUP_PAYMENT: {
    eventName: "confirm_cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Top-Up - Payment",
      action: "select 'Yes, CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  VIEW_TOPUP_PAST_REQUEST: {
    eventName: "view_topup_past_request",
    params: {
      eventType: "View",
      screenTitle: "Medication Top-Up - Past request",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_REFILL_PAST_REQUEST: {
    eventName: "view_refill_past_request",
    params: {
      eventType: "View",
      screenTitle: "Medication Refill - Past request",
      action: null,
      intent: null,
      subIntent: null,
    },
  },
  // ==========================
  // MEDREFILL MODULE VIRTUAL CONSULT FLOW (VCP)
  // =========================
  VIEW_NEW_VIRTUAL_CONSULT_PARTICULARS: {
    eventName: "view_new_virtual_consult_particulars",
    params: {
      eventType: "View",
      screenTitle: "Medication Virtual Consult - Particulars",
      action: "Make New Medication Virtual Consult Order Request",
      intent: "Particulars",
      subIntent: null,
    },
  },
  VIEW_NEW_VIRTUAL_CONSULT_COLLECTION: {
    eventName: "view_new_virtual_consult_collection",
    params: {
      eventType: "View",
      screenTitle: "Medication Virtual Consult - Collection",
      action: "Make New Medication Virtual Consult Order Request",
      intent: "Collection",
      subIntent: null,
    },
  },
  VIEW_NEW_VIRTUAL_CONSULT_PAYMENT: {
    eventName: "view_new_virtual_consult_payment",
    params: {
      eventType: "View",
      screenTitle: "Medication Virtual Consult - Payment",
      action: "Make New Medication Virtual Consult Order Request",
      intent: "Payment",
      subIntent: null,
    },
  },
  VIEW_NEW_VIRTUAL_CONSULT_ORDER_SUMMARY: {
    eventName: "view_new_virtual_consult_summary",
    params: {
      eventType: "View",
      screenTitle: "Medication Virtual Consult - Order Summary",
      action: "Make New Medication Virtual Consult Order Request",
      intent: "Order Summary",
      subIntent: null,
    },
  },
  CANCEL_REQUEST_AT_VIRTUAL_CONSULT_PAYMENT: {
    eventName: "cancel_request",
    params: {
      eventType: "Click",
      screenTitle: "Medication Virtual Consult- Payment",
      action: "select 'CANCEL REQUEST' button",
      intent: null,
      subIntent: null,
    },
  },

  VIEW_VIRTUAL_CONSULT_PAST_REQUEST_DETAIL: {
    eventName: "view_virtual_consult_past_request_detail",
    params: {
      eventType: "View",
      screenTitle: "Medication Virtual Consult- View Past Requests - Details",
      action: "View Past Request Details",
      intent: null,
      subIntent: null,
    },
  },

  CONFIRM_CANCEL_REQUEST_AT_VIRTUALCONSULT_PARTICULARS: {
    eventName: "confirm_cancel_request_at_virtualconsult_particulars",
    params: {
      eventType: "View",
      screenTitle: "Medication Virtual Consult- Particulars",
      action: null,
      intent: null,
      subIntent: null,
    },
  },
  // ======================
  // MY HEALTH MAP MODULE (MHM)
  // ======================
  MHM_ENTRY_POINT: {
    eventName: "my_health_map_entry_point",
    params: {
      eventType: "View",
      screenTitle: "My Health Map - Entry Point",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_ABOUT_MHM: {
    eventName: "view_about_my_health_map",
    params: {
      eventType: "View",
      screenTitle: "My Health Map - About MHM",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_ENROLLMENT_FORM: {
    eventName: "view_mhm_enrollment_form",
    params: {
      eventType: "View",
      screenTitle: "My Health Map - Enrollment Form",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_MHM_TERMS_AND_CONDITIONS: {
    eventName: "view_mhm_terms_and_conditions",
    params: {
      eventType: "View",
      screenTitle: "My Health Map - Terms and Conditins",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_MHM_DASHBOARD: {
    eventName: "view_mhm_dashboard",
    params: {
      eventType: "View",
      screenTitle: "My Health Map - MHM Dashboard",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_MHM_PROGRAM_TEMPLATE: {
    eventName: "view_mhm_program_{0}",
    params: {
      eventType: "View",
      screenTitle: "My Health Map - Program Dashboard - {0}",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_MHM_PROGRAM_QUESTIONNAIRE_TEMPLATE: {
    eventName: "view_mhm_program_questionnaire{0}",
    params: {
      eventType: "View",
      screenTitle: "My Health Map - Program Questionnaire - {0}",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  REQUEST_TO_ENROLL_MHM: {
    eventName: "request_to_enroll_into_mhm",
    params: {
      eventType: "Click",
      screenTitle: "My Health Map - About MHM",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  SKIP_ENROLLMENT_TO_MHM: {
    eventName: "skip_enrollment_to_mhm",
    params: {
      eventType: "Click",
      screenTitle: "My Health Map - About MHM",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  SUBMIT_MHM_ENROLLMENT: {
    eventName: "submit_mhm_enrollment",
    params: {
      eventType: "Click",
      screenTitle: "My Health Map - Enrollment Form",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  CANCEL_MHM_ENROLLMENT: {
    eventName: "cancel_mhm_enrollment",
    params: {
      eventType: "Click",
      screenTitle: "My Health Map - Enrollment Form",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  SUBMIT_MHM_QUESTIONNAIRE_TEMPLATE: {
    eventName: "submit_mhm_program_questionnaire",
    params: {
      eventType: "Click",
      screenTitle: "My Health Map - Program Questionnaire - {0}",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  CANCEL_MHM_QUESTIONNAIRE_TEMPLATE: {
    eventName: "cancel_program_questionnaire",
    params: {
      eventType: "Click",
      screenTitle: "My Health Map - Program Questionnaire - {0}",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  NEW_USER_SUCCESSFUL_ENROLLMENT: {
    eventName: "new_user_successful_enrollment",
    params: {
      eventType: "Click",
      screenTitle: "My Health Map - MHM Dashboard",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  NEW_USER_SUCCESSFUL_SUBMIT_QUESTIONNAIRE_TEMPLATE: {
    eventName: "new_user_successful_enrollment_and_submit_questionnaire",
    params: {
      eventType: "Click",
      screenTitle: "My Health Map - Program Questionnaire - {0}",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  RETURNED_USER_SUCCESSFUL_SUBMIT_QUESTIONNAIRE_TEMPLATE: {
    eventName: "returning_user_successful_submit_questionnaire",
    params: {
      eventType: "Click",
      screenTitle: "My Health Map - Program Questionnaire - {0}",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  SELECT_MHM_PREVENTIVE_CARE: {
    eventName: "click_mhm_preventive_care",
    params: {
      eventType: "Click",
      screenTitle: "My Health Map landing",
      action: "selection of preventive care in mhm",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_HEALTHIER_DINING_OPTIONS: {
    eventName: "click_healthier_eating_options",
    params: {
      eventType: "Click",
      screenTitle: "My Health Map landing/HT Self-Serve Resources",
      action: "selection of healthier dining options",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_SCREENING_CLINIC_NEAR_YOU: {
    eventName: "click_screening_clinic_near_you",
    params: {
      eventType: "Click",
      screenTitle: `${TITLES.HT.SELF_SERVE_RESOURCES}/${TITLES.MHM.MAIN}`,
      action: "click screening clinic near you",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_HT_MHM: {
    eventName: "click_ht_mhm",
    params: {
      eventType: "Click",
      screenTitle: `${TITLES.HT.HEALTH_TOGETHER}/${TITLES.HT.SELF_HELP_TOOLS}`,
      action: "click healthTogether myhealthmap",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_HT_COMMUNITY_CARE_PROGRAMMES: {
    eventName: "click_ht_community_care_programmes",
    params: {
      eventType: "Click",
      screenTitle: TITLES.HT.HEALTH_TOGETHER,
      action: "click healthTogether community care programmes",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_HEALTH_LIBRARY_OTHER_ARTICLES: {
    eventName: "click_health_library_other_articles",
    params: {
      eventType: "Click",
      screenTitle: TITLES.HT.HEALTH_LIBRARY,
      action: "click health library other articles",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_HT_EVENTS: {
    eventName: "click_ht_events",
    params: {
      eventType: "Click",
      screenTitle: TITLES.HT.HEALTH_TOGETHER,
      action: "click healthTogether events",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_HT_SELF_ASSESSMENT_TOOLS: {
    eventName: "click_ht_self_assessment_tools",
    params: {
      eventType: "Click",
      screenTitle: TITLES.HT.HEALTH_TOGETHER,
      action: "click healthTogether self assessment tools",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_HT_SELF_SERVE_RESOURCES: {
    eventName: "click_ht_self_serve_resources",
    params: {
      eventType: "Click",
      screenTitle: TITLES.HT.HEALTH_TOGETHER,
      action: "click healthTogether self serve resources",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_HT_HEALTH_LIBRARY: {
    eventName: "click_ht_health_library",
    params: {
      eventType: "Click",
      screenTitle: TITLES.HT.HEALTH_TOGETHER,
      action: "click healthTogether health library",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_SELF_SERVE_CHAMP: {
    eventName: "click_self_serve_champ",
    params: {
      eventType: "Click",
      screenTitle: TITLES.HT.HEALTH_TOGETHER,
      action: "click self serve champ",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_CCP_BOOK_AN_APPT_WITH_US: {
    eventName: "click_ccp_book_an_appt_with_us",
    params: {
      eventType: "Click",
      screenTitle: TITLES.HT.COMMUNITY_CARE_PROGRAMMES,
      action: "click ccp book an appointment with us",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_CCP_VIEW_OTHER_COMM_PROGRAMMES: {
    eventName: "click_ccp_view_other_comm_programmes",
    params: {
      eventType: "Click",
      screenTitle: TITLES.HT.COMMUNITY_CARE_PROGRAMMES,
      action: "click view other comm programmes",
      intent: null,
      subIntent: null,
    },
  },

  // ======================
  // PAYMENT MODULE
  // ======================
  VIEW_PAYMENT_LANDING: {
    eventName: "view_payment_landing",
    params: {
      eventType: "View",
      screenTitle: "Payments",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_PAYMENT_OUTSTANDING_BILL_DETAILS: {
    eventName: "view_payment_outstanding_bill_details",
    params: {
      eventType: "View",
      screenTitle: "Outstanding Bill Details",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_PAYMENT_BILL_SUMMARY: {
    eventName: "view_payment_bill_summary",
    params: {
      eventType: "View",
      screenTitle: "Payments (Bill Summary)",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_PAYMENT_PAYOR_INFO: {
    eventName: "view_payment_payor_info",
    params: {
      eventType: "View",
      screenTitle: "Payments (Payor Information)",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_PAYMENT_RECEIPT: {
    eventName: "view_payment_receipt",
    params: {
      eventType: "View",
      screenTitle: "Receipt",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_PAYMENT_ADD_BILL: {
    eventName: "view_payment_add_bill",
    params: {
      eventType: "View",
      screenTitle: "Add Other Bill",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_PAYMENT_ADDED_BILL_ENTER_INFO: {
    eventName: "view_payment_added_bill_enter_info",
    params: {
      eventType: "View",
      screenTitle: "Add Other Bill",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_PAYMENT_ADDED_BILL_DETAILS: {
    eventName: "view_payment_added_bill_details",
    params: {
      eventType: "View",
      screenTitle: "Added Bill Details",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_PAYMENT_PAID_BILLS: {
    eventName: "view_payment_paid_bills",
    params: {
      eventType: "View",
      screenTitle: "Fully-Paid Bills",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_PAYMENT_TRANSACTION_HISTORY: {
    eventName: "view_payment_transaction_history",
    params: {
      eventType: "View",
      screenTitle: "Transaction History",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  VIEW_PAYMENT_TRANSACTION_FILTER: {
    eventName: "view_payment_transaction_filter",
    params: {
      eventType: "View",
      screenTitle: "Transaction Filter (Dialog without title)",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  SELECT_MAKE_PAYMENT_LANDING: {
    eventName: "select_make_payment_landing",
    params: {
      eventType: "Click",
      screenTitle: "Payments",
      action: "Click make payment button on landing page",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_PAID_BILLS_LANDING: {
    eventName: "select_paid_bills",
    params: {
      eventType: "Click",
      screenTitle: "Payments",
      action: "select paid bills in footers in payment landing page",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_ADD_OTHER_BILL_LANDING: {
    eventName: "select_add_other_bill_landing",
    params: {
      eventType: "Click",
      screenTitle: "Payments",
      action: "select add other bill in footers in payment landing page",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_ADD_BILL_ADD_OTHER_BILL: {
    eventName: "select_add_bill_add_other_bill",
    params: {
      eventType: "Click",
      screenTitle: "Add Other Bill",
      action: null,
      intent: null,
      subIntent: null,
    },
  },

  SELECT_PAY_BILL_SUMMARY: {
    eventName: "select_pay_bill_summary",
    params: {
      eventType: "Click",
      screenTitle: "Payments",
      action: "Select pay button on bill summary page",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_OK_SUCCESSFUL_RECEIPT: {
    eventName: "select_ok_successful_receipt",
    params: {
      eventType: "Click",
      screenTitle: "Receipt",
      action: "Click ok button on receipt page",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_BACK_TO_HOME_UNSUCCESSFUL_RECEIPT: {
    eventName: "select_back_to_home_unsuccessful_receipt",
    params: {
      eventType: "Click",
      screenTitle: "Receipt",
      action: "Click back to home button on receipt page",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_BACK_TO_PAYMENT_PENDING_RECEIPT: {
    eventName: "select_back_to_payment_pending_receipt",
    params: {
      eventType: "Click",
      screenTitle: "Receipt",
      action: "Click back to payment button on receipt page",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_PAY_OUTSTANDING_BILL_UNEDITED: {
    eventName: "select_pay_outstanding_bill_unedited",
    params: {
      eventType: "Click",
      screenTitle: "Outstanding Bill Details",
      action: "make payment for outstanding bill without changing amount",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_PAY_OUTSTANDING_BILL_EDITED: {
    eventName: "select_pay_outstanding_bill_edited",
    params: {
      eventType: "Click",
      screenTitle: "Outstanding Bill Details",
      action: "make payment for outstanding bill after changing amount",
      intent: null,
      subIntent: null,
    },
  },

  CANCEL_PAYMENT_PAYOR_INFO: {
    eventName: "cancel_payment_payor_info",
    params: {
      eventType: "Click",
      screenTitle: "Payment (Payor Info)",
      action: "click cancel button on payor info page",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_ADD_OTHER_BILL_BILL_SUMMARY: {
    eventName: "select_add_other_bill_bill_summary",
    params: {
      eventType: "Click",
      screenTitle: "Payments (Bill Summary)",
      action: "select add other bill at bills summary page",
      intent: null,
      subIntent: null,
    },
  },

  CONFIRM_ADD_BILL_DEFAULT: {
    eventName: "confirm_add_bill_default",
    params: {
      eventType: "Click",
      screenTitle: "Add Other Bill",
      action: "confirm add other bill default",
      intent: null,
      subIntent: null,
    },
  },

  CONFIRM_ADD_BILL_WITH_INFO: {
    eventName: "confirm_add_bill_with_info",
    params: {
      eventType: "Click",
      screenTitle: "Add Other Bill",
      action: "confirm add other bill with info",
      intent: null,
      subIntent: null,
    },
  },

  CANCEL_ADD_BILL: {
    eventName: "confirm_add_bill_with_info",
    params: {
      eventType: "Click",
      screenTitle: "Add Other Bill",
      action: "confirm add other bill with info",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_CREDIT_CARD: {
    eventName: "select_credit_card",
    params: {
      eventType: "Click",
      screenTitle: "Payment (Payor Info)",
      action: "select credit card (Visa/MasterCard) option at payor info page",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_AMEX: {
    eventName: "select_amex",
    params: {
      eventType: "Click",
      screenTitle: "Payment (Payor Info)",
      action: "select amex card option at payor info page",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_DIRECT_DEBIT: {
    eventName: "select_direct_debit",
    params: {
      eventType: "Click",
      screenTitle: "Payment (Payor Info)",
      action: "select direct debit (eNETS) option at payor info page",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_VIEW_MORE_PAID_BILLS: {
    eventName: "select_view_more_paid_bills",
    params: {
      eventType: "Click",
      screenTitle: "Fully-Paid Bills",
      action: "select view more button on paid bills page",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_DOWNLOAD_BILL_PAID_BILLS: {
    eventName: "select_download_bill_paid_bills",
    params: {
      eventType: "Click",
      screenTitle: "Fully-Paid Bills",
      action: "select download bill button on paid bills page",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_TRANSACTION_HISTORY: {
    eventName: "select_transaction_history",
    params: {
      eventType: "Click",
      screenTitle: "Payments",
      action: "select transaction history in footers in payment landing page",
      intent: null,
      subIntent: null,
    },
  },

  EXPAND_TRANSACTION_HISTORY: {
    eventName: "expand_transaction_history",
    params: {
      eventType: "Click",
      screenTitle: "Transaction History",
      action: "click ^ to expand one Transaction History entry",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_LOAD_MORE_TRANSACTION_HISTORY: {
    eventName: "select_load_more_transaction_history",
    params: {
      eventType: "Click",
      screenTitle: "Transaction History",
      action: "click ^ to expand one Transaction History entry",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_FILTER_TRANSACTION_HISTORY: {
    eventName: "select_filter_transaction_history",
    params: {
      eventType: "Click",
      screenTitle: "Transaction History",
      action: "click Filter Transaction icon",
      intent: null,
      subIntent: null,
    },
  },

  FILTER_TRANSACTION_HISTORY_PROVIDED_RANGE: {
    eventName: "filter_transaction_history_provided_range",
    params: {
      eventType: "Click",
      screenTitle: "Filter Transaction",
      action: "click Apply Filter button after selecting month range",
      intent: null,
      subIntent: null,
    },
  },

  FILTER_TRANSACTION_HISTORY_CUSTOM_RANGE: {
    eventName: "filter_transaction_history_custom_range",
    params: {
      eventType: "Click",
      screenTitle: "Filter Transaction",
      action: "click Apply Filter button after selecting custom range",
      intent: null,
      subIntent: null,
    },
  },

  CANCEL_FILTER_TRANSACTION_HISTORY: {
    eventName: "filter_transaction_history_custom_range",
    params: {
      eventType: "Click",
      screenTitle: "Filter Transaction",
      action: "click Apply Filter button after selecting custom range",
      intent: null,
      subIntent: null,
    },
  },

  // ====================
  // MRRP Module
  // ====================
  SELECT_NEW_MEDICAL_REPORT_REQUEST: {
    eventName: "click_medical_report_make_new_request",
    params: {
      eventType: "Click",
      screenTitle: TITLES.MRRP.MOBILE,
      action: "click Make New Request",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_MEDICAL_REPORT_SELF_COLLECTION: {
    eventName: "click_medical_report_self_collection",
    params: {
      eventType: "Click",
      screenTitle: TITLES.MRRP.NEW_REQUEST_COLLECTION,
      action: "selection of self collection medical report",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_MEDICAL_REPORT_REGISTERED_MAIL: {
    eventName: "click_medical_report_registered_mail",
    params: {
      eventType: "Click",
      screenTitle: TITLES.MRRP.NEW_REQUEST_COLLECTION,
      action: "selection of registered mail medical report",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_MEDICAL_REPORT_DOWNLOAD_ON_APP: {
    eventName: "click_medical_report_download_on_app",
    params: {
      eventType: "Click",
      screenTitle: TITLES.MRRP.NEW_REQUEST_COLLECTION,
      action: "selection of download on app medical report",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_MEDICAL_REPORT_DOWNLOAD_REPORT: {
    eventName: "click_medical_report_download_report",
    params: {
      eventType: "Click",
      screenTitle: TITLES.MRRP.PAST_REPORT_DETAIL,
      action: "selection of download report",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_PAYMENT_OTHER_ENQUIRIES: {
    eventName: "click_payment_other_enquiries",
    params: {
      eventType: "Click",
      screenTitle: TITLES.PAYMENT.MAIN,
      action: "click payment other enquires",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_REQUEST_HARD_COPY_BILLS: {
    eventName: "click_request_hard_copy_bills",
    params: {
      eventType: "Click",
      screenTitle: TITLES.PAYMENT.OTHER_ENQUIRIES,
      action: "click request hard copy bills",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_SIGN_UP_AUTO_PAY: {
    eventName: "click_sign_up_auto_pay",
    params: {
      eventType: "Click",
      screenTitle: TITLES.PAYMENT.OTHER_ENQUIRIES,
      action: "click sign up auto pay",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_SEND_HOSPITAL_BILL_ENQUIRY: {
    eventName: "click_send_hospital_bill_enquiry",
    params: {
      eventType: "Click",
      screenTitle: TITLES.PAYMENT.OTHER_ENQUIRIES,
      action: "click send hospital bill enquiry",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_SEND_POLYCLINIC_BILL_ENQUIRY: {
    eventName: "click_send_polyclinic_bill_enquiry",
    params: {
      eventType: "Click",
      screenTitle: TITLES.PAYMENT.OTHER_ENQUIRIES,
      action: "click send polyclinic bill enquiry",
      intent: null,
      subIntent: null,
    },
  },

  // ====================
  // Patient Master Module
  // ====================
  SELECT_PM_VERIFY_PARTICULARS_PROCEED_VERIFY: {
    eventName: "click_verify_particulars_proceed_verify",
    params: {
      eventType: "Click",
      screenTitle: TITLES.PROFILE.PM_ENTRY,
      action: "selection of proceed to verify particulars",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_PM_VERIFY_PARTICULARS_DO_IT_LATER: {
    eventName: "click_verify_particulars_do_it_later",
    params: {
      eventType: "Click",
      screenTitle: TITLES.PROFILE.PM_ENTRY,
      action: "selection of do it later",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_PM_VERIFY_PARTICULARS_GET_ADDRESS: {
    eventName: "click_verify_particulars_get_address",
    params: {
      eventType: "Click",
      screenTitle: TITLES.PROFILE.MAIN,
      action: "selection of get address",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_PM_VERIFY_PARTICULARS_MAILING_ADDRESS_EXPIRY: {
    eventName: "click_verify_particulars_mail_address_ex",
    params: {
      eventType: "Click",
      screenTitle: TITLES.PROFILE.MAIN,
      action: "selection of mailing address expiry",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_PM_VERIFY_PARTICULARS_EMAIL_ADDRESS: {
    eventName: "click_verify_particulars_email_address",
    params: {
      eventType: "Change",
      screenTitle: TITLES.PROFILE.MAIN,
      action: "change of email address",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_PM_VERIFY_PARTICULARS_PHONE_NUMBER: {
    eventName: "click_verify_particulars_phone_number",
    params: {
      eventType: "Change",
      screenTitle: TITLES.PROFILE.MAIN,
      action: "change of phone number",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_PM_VERIFY_PARTICULARS_CONFIRM_AND_SAVE: {
    eventName: "click_verify_particulars_confirm_and_save",
    params: {
      eventType: "Click",
      screenTitle: TITLES.PROFILE.MAIN,
      action: "selection of confirm and save",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_PM_VERIFY_PARTICULARS_PROCEED_UPDATE: {
    eventName: "click_verify_particulars_proceed_update",
    params: {
      eventType: "Click",
      screenTitle: TITLES.PROFILE.MYINFO_VERIFY,
      action: "selection of proceed update on comparison page",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_PM_VERIFY_PARTICULARS_OK_UNABLE_UPDATE: {
    eventName: "click_verify_particulars_ok_unable_update",
    params: {
      eventType: "Click",
      screenTitle: TITLES.PROFILE.MAIN,
      action: "selection of ok on unable to update modal",
      intent: null,
      subIntent: null,
    },
  },

  ERROR_PM_MISMATCH_NAME: {
    eventName: "error_pm_mismatch_name",
    params: {
      eventType: "Error",
      screenTitle: TITLES.PROFILE.MAIN,
      action: "error on patientMaster mismatch name with myInfo",
      intent: null,
      subIntent: null,
    },
  },

  ERROR_PM_MISMATCH_GENDER: {
    eventName: "error_pm_mismatch_gender",
    params: {
      eventType: "Error",
      screenTitle: TITLES.PROFILE.MAIN,
      action: "error on patientMaster mismatch gender with myInfo",
      intent: null,
      subIntent: null,
    },
  },

  ERROR_PM_MISMATCH_DOB: {
    eventName: "error_pm_mismatch_dob",
    params: {
      eventType: "Error",
      screenTitle: TITLES.PROFILE.MAIN,
      action: "error on patientMaster mismatch dateOfBirth with myInfo",
      intent: null,
      subIntent: null,
    },
  },

  ERROR_PM_MISMATCH_NAME_DOB_GENDER: {
    eventName: "error_pm_mismatch_name_dob_gender",
    params: {
      eventType: "Error",
      screenTitle: TITLES.PROFILE.MAIN,
      action:
        "error on patientMaster mismatch name, dateOfBirth, gender with myInfo",
      intent: null,
      subIntent: null,
    },
  },

  ERROR_PM_MISMATCH_NAME_DOB: {
    eventName: "error_pm_mismatch_name_dob",
    params: {
      eventType: "Error",
      screenTitle: TITLES.PROFILE.MAIN,
      action:
        "error on patientMaster mismatch name and dateOfBirth with myInfo",
      intent: null,
      subIntent: null,
    },
  },

  ERROR_PM_MISMATCH_NAME_GENDER: {
    eventName: "error_pm_mismatch_name_gender",
    params: {
      eventType: "Error",
      screenTitle: TITLES.PROFILE.MAIN,
      action: "error on patientMaster mismatch name and gender with myInfo",
      intent: null,
      subIntent: null,
    },
  },

  ERROR_PM_MISMATCH_DOB_GENDER: {
    eventName: "error_pm_mismatch_dob_gender",
    params: {
      eventType: "Error",
      screenTitle: TITLES.PROFILE.MAIN,
      action:
        "error on patientMaster mismatch dateOfBirth and gender with myInfo",
      intent: null,
      subIntent: null,
    },
  },

  SELECT_PM_SYNC_PARTICULARS_PROCEED: {
    eventName: "click_sync_particulars_proceed",
    params: {
      eventType: "Click",
      screenTitle: TITLES.PROFILE.MAIN,
      action: "selection of proceed on sync particulars modal",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_PROFILE_RETRIEVE_MYINFO_SINGPASS: {
    eventName: "click_profile_retrieve_myinfo_singpass",
    params: {
      eventType: "Click",
      screenTitle: TITLES.PROFILE.MAIN,
      action: "click profile retrieve myInfo singpass",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_CREATE_PM_CONFIRM_AND_SAVE: {
    eventName: "click_create_pm_confirm_and_save",
    params: {
      eventType: "Click",
      screenTitle: TITLES.PROFILE.MAIN,
      action: "click confirm and save to create pm",
      intent: null,
      subIntent: null,
    },
  },

  CLICK_UPDATE_PM_CONFIRM_AND_SAVE: {
    eventName: "click_update_pm_confirm_and_save",
    params: {
      eventType: "Click",
      screenTitle: TITLES.PROFILE.MAIN,
      action: "click confirm and save to update pm",
      intent: null,
      subIntent: null,
    },
  },
} satisfies Events;
