import { connect } from "react-redux";
import AddedBillDetail from "./AddedBillDetail";
import { RootDispatch, RootState } from "lib/redux/root/redux.types";
import { deleteAddedBillByInvNo, setBillsToPay } from "ui/payment/ducks";
import { PaymentBillsToPayState } from "ui/payment/ducks/payment.types";

// helper function to get amount to pay from state if have saved input
const getAmountToPayFromState = (
  billsToPay: PaymentBillsToPayState[],
): number | null => {
  if (billsToPay.length === 0) {
    // don't have saved input on bills to pay yet in this flow
    return null;
  } else {
    // only one bill will be present in this flow if billsToPay has element
    const rawAmountToPay = billsToPay[0].AmountToPay;
    return Number(rawAmountToPay);
  }
};

const mapStateToProps = (state: RootState) => {
  let billInvoiceNumber = state.payments.addedBills.selectedAddedBillsIndex;
  let allAddedBills = state.payments.addedBills.allAddedBills;
  let selectedAddedBill =
    allAddedBills.find((bill) => bill.InvoiceNumber === billInvoiceNumber) ||
    null;

  return {
    billDetails: selectedAddedBill,
    amountToPayStateValue: getAmountToPayFromState(state.payments.billsToPay),
  };
};

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    setBillToPay: (payBill: PaymentBillsToPayState) => {
      dispatch(setBillsToPay([payBill]));
    },
    deleteThisBill: (refNo: string | null) => {
      dispatch(deleteAddedBillByInvNo(refNo));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddedBillDetail);
