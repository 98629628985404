import { Box, Typography } from "@mui/material";
import { StatusCardProps } from "./StatusCard.types";
import { sxStyles } from "./StatusCard.styles";
import IMAGES from "lib/assets/images";

const StatusCard = ({
  paymentStatusCode,
  statusMessage,
  toEmail,
}: StatusCardProps) => {
  const classes = sxStyles();
  const paymentStatusString =
    "Payment " +
    (paymentStatusCode === "S"
      ? "Successful"
      : paymentStatusCode === "F"
        ? "Unsuccessful"
        : "Processing");
  const paymentStatusSvg =
    paymentStatusCode === "S"
      ? IMAGES.general.Successful
      : paymentStatusCode === "F"
        ? IMAGES.general.Unsuccessful
        : IMAGES.general.Pending;

  return (
    <Box sx={classes.statusSection}>
      <>
        {paymentStatusCode === "S" ? (
          <Box
            src={paymentStatusSvg}
            alt={paymentStatusString}
            sx={classes.successIcon}
            component={"img"}
          />
        ) : (
          <Box
            src={paymentStatusSvg}
            alt={paymentStatusString}
            sx={classes.statusIcon}
            component={"img"}
          />
        )}

        <Typography sx={classes.title}>{paymentStatusString}</Typography>
        <Typography sx={classes.content}>
          {paymentStatusCode === "S" ? (
            <>We will send this receipt to {toEmail}</>
          ) : paymentStatusCode !== "S" && paymentStatusCode !== "F" ? (
            <>
              You can check the payment status within the next 3 working days by
              going to 'Payment' {">"} 'App Payment Status'.
            </>
          ) : null}
        </Typography>
      </>
    </Box>
  );
};

export default StatusCard;
