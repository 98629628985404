import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { GET_DOCUMENT_BY_TYPE_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { formatGetDocumentByTypePayloadErrorMessage } from "api/resources/messages/ErrorMessage";
import { GetDocumentByTypePayload } from "./getDocumentByType.fromApi.types";

/**
 * Fetches the Document based on a certain module and type
 *
 * POST
 * Endpoint: /GetDocumentByType
 *
 * @param {string | null} module a specific code to group Document
 * @param {string | null} documentType a specific code to group Document
 */
const getDocumentByType = async (
  module: string | null,
  documentType: string | null,
): Promise<GetDocumentByTypePayload> => {
  try {
    const response = await axios.post<GetDocumentByTypePayload>(
      GET_DOCUMENT_BY_TYPE_URL(),
      createRequestPayload(module, documentType),
      getRequestOptions(),
    );
    return parseResponse(response, GET_DOCUMENT_BY_TYPE_URL());
  } catch (error) {
    const msg = formatGetDocumentByTypePayloadErrorMessage(
      GET_DOCUMENT_BY_TYPE_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  module: string | null,
  documentType: string | null,
) => {
  return {
    Module: module,
    DocumentType: documentType,
  };
};

export { getDocumentByType };
