import { Box, Button, List, ListItem, Typography } from "@mui/material";
import IMAGES from "lib/assets/images";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { setFromInitialProfile } from "lib/redux/navigation";
import {
  setProfileAccessEntryPoint,
  setShouldDirectToMyInfo,
} from "lib/redux/user/userSlice";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { useEffect } from "react";
import { selectPMSettings, selectPMSettingsValue } from "../../ducks/selectors";
import { fetchSystemSettings } from "../../ducks/thunks";
import { sxStyles } from "./PMEntryPage.styles";
import { ACTIONS, PATHS } from "lib/routing";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import { selectSystem } from "lib/redux/system/selectors";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import {
  initialState,
  setEpicProfileState,
  setHasFetchedEpicProfile,
  setMyInfoProfileState,
} from "../../ducks";
import PMEntryPageSkeleton from "./skeletons/PMEntryPageSkeleton";
import { fetchUserParticulars } from "lib/redux/user";
import { useGetMessageAction } from "lib/routing/messageChannel/hooks/useGetMessageAction";

const pageTitle = "Verify your particulars ";
const nextButtonText = "Proceed to verify";
const cancelButtonText = "Do it later";

const PMEntryPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = sxStyles();

  // Redux states
  const { disclaimers } = useAppSelector(selectPMSettingsValue);
  const { isLoading, hasErrored, errorMessage } =
    useAppSelector(selectPMSettings);
  const { devicePlatform } = useAppSelector(selectSystem);
  const { messageActionObj, consumeMessageActionObj } = useGetMessageAction();

  const onClickNextHandler = () => {
    dispatch(setHasFetchedEpicProfile(false));
    dispatch(setEpicProfileState(initialState.epicProfile.value));
    dispatch(setMyInfoProfileState(initialState.myInfoProfile.value));
    dispatch(setFromInitialProfile(location.pathname));
    dispatch(fetchUserParticulars({ mapAddress: false }));

    dispatch(
      setShouldDirectToMyInfo({
        shouldDirectToMyInfo: true,
        cancellationPath: location.pathname, //problem to be discussed
      }),
    );

    logEventToGoogleAnalytics(
      EVENTS.SELECT_PM_VERIFY_PARTICULARS_PROCEED_VERIFY,
    );

    navigate(PATHS.MY_INFOPROFILE.path);
  };

  const onClickCancelHandler = () => {
    logEventToGoogleAnalytics(EVENTS.SELECT_PM_VERIFY_PARTICULARS_DO_IT_LATER);
    mobileNavigate(`${ACTIONS.PM_SYNC_DO_IT_LATER}&remindmelater=1`); //remindmelater param set to true so that mobile will not redirect user to pmEntryPage until specified duration has elapsed.
  };

  useEffect(() => {
    dispatch(fetchSystemSettings());
    dispatch(setProfileAccessEntryPoint("PM ENTRY"));

    // action needed for mobile when android physical back button is clicked
    if (devicePlatform === "MOBILE_ANDROID_WEB") {
      mobileNavigate(`${ACTIONS.PM_SYNC_DO_IT_LATER_ANDROID}&remindmelater=0`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (messageActionObj?.action === "backTriggered") {
      logEventToGoogleAnalytics(
        EVENTS.SELECT_PM_VERIFY_PARTICULARS_DO_IT_LATER,
      );
      mobileNavigate(`${ACTIONS.PM_SYNC_DO_IT_LATER}&remindmelater=1`);
      consumeMessageActionObj();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageActionObj?.action]);

  return (
    <>
      {isLoading ? (
        <PMEntryPageSkeleton />
      ) : hasErrored ? (
        <Box style={{ marginTop: "64px" }}>
          <ErrorDisplay
            errorMessage={errorMessage}
            onTryAgain={() => dispatch(fetchSystemSettings())}
            showDoItLater={true}
          />
        </Box>
      ) : (
        <Box sx={classes.mainContainer}>
          <Box>
            <Typography sx={classes.title}>{pageTitle}</Typography>
            <Box sx={classes.imageContainer}>
              <Box
                component={"img"}
                src={IMAGES.pm.PMSyncParticulars}
                alt="Sync Particulars"
                sx={classes.image}
              />
            </Box>
            <List sx={classes.listContainer}>
              {disclaimers.verifyYourParticulars.description?.map((item) => {
                return (
                  <ListItem key={item}>
                    <Box
                      component={"img"}
                      src={IMAGES.general.TickIcon}
                      sx={classes.tickImage}
                      alt="verifyYourParticulars tick icon"
                    />
                    <Typography sx={classes.verifyYourParticularsDescription}>
                      {item}
                    </Typography>
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box sx={classes.bottomContainer}>
            <Button fullWidth onClick={onClickNextHandler}>
              {nextButtonText}
            </Button>
            <Button variant="text" fullWidth onClick={onClickCancelHandler}>
              {cancelButtonText}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PMEntryPage;
