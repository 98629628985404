import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    cardDivider: {
      margin: theme.spacing(0, 1),
      borderTop: `1px solid ${theme.palette.grey[100]}`,
    },
    cardLink: {
      color: theme.palette.primary.main,
      typography: theme.typography.body2_semibold,
      margin: theme.spacing(2, 0),
      display: "flex",
      alignItems: "center",
    },
    cardLinkIcon: {
      paddingRight: theme.spacing(1),
    },
  } satisfies SxObjType;
};

export { sxStyles };
