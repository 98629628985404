import { Box, CircularProgress, Typography } from "@mui/material";
import Page from "lib/components/layout/Page/Page";
import DeliveryDetailSummarySection from "lib/components/summary/DeliveryDetailSummarySection/DeliveryDetailSummarySection";
import PatientOrContactDetailSummarySection from "lib/components/summary/PatientOrContactDetailSummarySection/PatientOrContactDetailSummarySection";
import SummarySectionFrame from "lib/components/summary/SummarySectionFrame/SummarySectionFrame";
import { PATHS } from "lib/routing";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { maskNric } from "ui/appointment/components/common/user/UserProfile/maskNric";
import StepPageButtonsFooter from "../../common/StepPageButtonsFooter/StepPageButtonsFooter";
import AttachmentSummarySection from "./AttachmentSummarySection/AttachmentSummarySection";
import ReportDetailsSummarySection from "./ReportDetailsSummarySection/ReportDetailsSummarySection";
import { sxStyles } from "./RequestSummary.styles";
import { RequestSummaryProps } from "./RequestSummay.types";
import { AxiosError } from "axios";
import { useAppDispatch } from "lib/redux/hooks";
import {
  setOldMemberIdentifier,
  setOldTokenizedId,
} from "lib/redux/user/userSlice";

const RequestSummary = ({
  summary,
  memberIdentifier,
  isSelf,
  oldMemberIdentifier,
  oldTokenizedId,
  onBook,
  onSetApiResult,
  handleErrorModal,
}: RequestSummaryProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  // Request Handler
  const sendCreateMedicalReportRequest = useCallback(async () => {
    try {
      // make the booking
      setIsLoading(true);
      const response = await onBook(
        summary.hospital.selectedHospitalCode,
        memberIdentifier,
        isSelf,
        summary.particulars.patientName,
        summary.particulars.contactNumber,
        summary.particulars.alternativeNumber,
        summary.collection.blockNo,
        summary.collection.floorAndUnit,
        summary.collection.address,
        summary.collection.postalCode,
        summary.particulars.email,
        summary.reportDetail.reportTypeCode,
        summary.reportDetail.reportType,
        summary.reportDetail.reportFees,
        summary.reportDetail.clinicalDepartmentCode,
        summary.reportDetail.reportFileByte,
        summary.reportDetail.reportFileName,
        summary.particulars.relationshipCodeWithPatient,
        summary.collection.deliveryModeCode,
        summary.reportDetail.purposeOfApplication,
        summary.reportDetail.visitDate,
        oldMemberIdentifier,
        oldTokenizedId,
      );

      dispatch(setOldMemberIdentifier(response.OldMemberIdentifier));
      dispatch(setOldTokenizedId(response.OldTokenizedId));

      if (summary.collection.deliveryModeCode === "SC") {
        logEventToGoogleAnalytics(EVENTS.SELECT_MEDICAL_REPORT_SELF_COLLECTION);
      }

      if (summary.collection.deliveryModeCode === "DA") {
        logEventToGoogleAnalytics(EVENTS.SELECT_MEDICAL_REPORT_DOWNLOAD_ON_APP);
      }

      if (summary.collection.deliveryModeCode === "RM") {
        logEventToGoogleAnalytics(EVENTS.SELECT_MEDICAL_REPORT_REGISTERED_MAIL);
      }

      onSetApiResult(response.Data);
      navigate(PATHS.MRRP_NEW_REQUEST_PAYMENT.path);
    } catch (error) {
      if (error instanceof AxiosError) {
        handleErrorModal(true, error.response?.data.Message);
      }
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary, memberIdentifier, onBook, handleErrorModal]);

  const classes = sxStyles();
  const selectedHospital = summary.hospital.selectedHospitalName;
  const selectedHospitalCode = summary.hospital.selectedHospitalCode ?? "";
  const formLabelSelected = summary.formLabel.labels.find((label) => {
    return label.institutions.includes(selectedHospitalCode);
  });
  const formLabelDefault = summary.formLabel.labels.find((label) => {
    return label.institutions.includes("Default");
  });
  const formLabel = formLabelSelected ?? formLabelDefault;
  const nameLabel = formLabel?.requestorName ?? "Name";
  const relationshipLabel = formLabel?.relationship ?? "Relationship";
  const contactNoLabel = formLabel?.contactNo ?? "Contact Number";

  return (
    <Page>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ p: 2, pt: 3 }}>
            <Typography sx={classes.title}>Report Summary</Typography>

            {/* Institution */}
            <SummarySectionFrame>
              <Typography sx={classes.institutionTitle}>Institution</Typography>
              <Typography sx={classes.institutionDetail}>
                {selectedHospital}
              </Typography>
            </SummarySectionFrame>

            {/* Patient's Details */}
            <SummarySectionFrame>
              <PatientOrContactDetailSummarySection
                title="Patient's Details"
                upperLabel="Name"
                upperDetail={summary.particulars.patientName}
                lowerLabel="NRIC / Birth Certificate / FIN"
                lowerDetail={maskNric(summary.particulars.patientNric)}
              />
            </SummarySectionFrame>

            {/* Requestor Details */}
            <SummarySectionFrame>
              <PatientOrContactDetailSummarySection
                title="Requestor's Details"
                upperLabel={nameLabel}
                upperDetail={summary.particulars.requestorName}
                lowerLabel={relationshipLabel}
                lowerDetail={summary.particulars.relationshipWithPatient}
                showLowerLabel={!isSelf}
              />
            </SummarySectionFrame>

            {/* Report Details */}
            <SummarySectionFrame>
              <ReportDetailsSummarySection
                labels={formLabel}
                reportDetails={summary.reportDetail}
              />
            </SummarySectionFrame>

            {/* Attachement */}
            {summary.reportDetail.reportFileName &&
              summary.reportDetail.reportFileByte && (
                <SummarySectionFrame>
                  <AttachmentSummarySection
                    fileName={summary.reportDetail.reportFileName}
                    fileByte={summary.reportDetail.reportFileByte}
                    fileMIMEType={summary.reportDetail.reportFileMIMEType}
                  />
                </SummarySectionFrame>
              )}

            {/* Mode of Delivery */}
            <SummarySectionFrame>
              <DeliveryDetailSummarySection
                postalCode={summary.collection.postalCode}
                blockNo={summary.collection.blockNo}
                unitNo={summary.collection.floorAndUnit}
                address={summary.collection.address}
                showSpecialRequest={false}
                deliveryMode={summary.collection.deliveryMode}
              />
            </SummarySectionFrame>

            {/* Contact Details */}
            <SummarySectionFrame>
              <Typography sx={classes.subtitle}>Contact Details</Typography>
              <Typography sx={classes.label}>{contactNoLabel}</Typography>
              <Typography sx={classes.detail}>
                {summary.particulars.contactNumber}
              </Typography>
              {summary.particulars.alternativeNumber ? (
                <>
                  <Typography sx={classes.label}>Alternative Number</Typography>
                  <Typography sx={classes.detail}>
                    {summary.particulars.alternativeNumber}
                  </Typography>
                </>
              ) : null}
              <Typography sx={classes.label}>Email</Typography>
              <Typography sx={classes.lowerDetail}>
                {summary.particulars.email}
              </Typography>
            </SummarySectionFrame>
          </Box>

          <StepPageButtonsFooter
            nextButtonText="Proceed to payment"
            isNextButtonDisabled={false}
            onNextStep={sendCreateMedicalReportRequest}
          />
        </>
      )}
    </Page>
  );
};

export default RequestSummary;
