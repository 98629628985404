import { RootState, RootDispatch } from "lib/redux/root/redux.types";
import { connect } from "react-redux";
import {
  updateRefillInsUserPreference,
  updateTopupInsUserPreference,
} from "lib/redux/medUserPreference";
import { OwnProps } from "./InstitutionsPage.types";
import InstitutionsPage from "./InstitutionsPage";
import {
  updateFacility,
  fetchSystemSettings,
  fetchMedications,
  fetchClinics,
} from "ui/medrefill/ducks";
import {
  fetchCollectionPaymentDetails,
  fetchDocumentByCluster,
} from "ui/medrefill/ducks/thunks";
import {
  clearState,
  updateRequestType,
  setSystemSettingsHasErrored,
  setMedicationsHasErrored,
  setCollectionPaymentDetailsHasErrored,
  setClinicsHasErrored,
  setDocumentByClusterHasErrored,
} from "ui/medrefill/ducks/medrefillSlice";
import { setUserProfileHasErrored } from "lib/redux/user";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  return {
    lastFiveSearchedKeywords:
      ownProps.prescriptionType === "REFILL"
        ? state.medUserPreference.refill.lastFiveInstitutionSearchedKeywords
        : state.medUserPreference.topup.lastFiveInstitutionSearchedKeywords,

    isLoadingFormData:
      state.medRefill.systemSettings.isLoading ||
      state.medRefill.medications.isLoading ||
      state.medRefill.collectionAndPayments.isLoading ||
      state.medRefill.clinics.isLoading ||
      state.medRefill.documentByCluster.isLoading,

    hasErroredSystemSettings: state.medRefill.systemSettings.hasErrored,
    errorMessageSystemSettings: state.medRefill.systemSettings.errorMessage,

    hasErroredMedications: state.medRefill.medications.hasErrored,
    errorMessageMedications: state.medRefill.medications.errorMessage,

    hasErroredCollectionPaymentDetails:
      state.medRefill.collectionAndPayments.hasErrored,
    errorMessageCollectionPaymentDetails:
      state.medRefill.collectionAndPayments.errorMessage,

    hasErroredClinics: state.medRefill.clinics.hasErrored,
    errorMessageClinics: state.medRefill.clinics.errorMessage,

    hasErroredDocByCluster: state.medRefill.documentByCluster.hasErrored,
    errorMessageDocByCluster: state.medRefill.documentByCluster.errorMessage,
  };
};

const mapDispatchToProps = (dispatch: RootDispatch, ownProps: OwnProps) => {
  return {
    onLoad: () => {
      dispatch(clearState());
      dispatch(setSystemSettingsHasErrored(null));
      dispatch(setMedicationsHasErrored(null));
      dispatch(setCollectionPaymentDetailsHasErrored(null));
      dispatch(setClinicsHasErrored(null));
      dispatch(setDocumentByClusterHasErrored(null));
      dispatch(setUserProfileHasErrored(null));
      dispatch(updateRequestType(ownProps.prescriptionType));
    },
    onSelect: (facilityId: number, facilityName: string, cluster: string) => {
      dispatch(updateFacility({ facilityId, facilityName, cluster }));
      dispatch(fetchSystemSettings(cluster));
      dispatch(fetchMedications(cluster));
      dispatch(fetchCollectionPaymentDetails(cluster, facilityId));
      dispatch(fetchClinics(facilityId));
      dispatch(fetchDocumentByCluster(cluster));
    },
    onSaveInstitutionKeyword: (keyword: string) => {
      if (ownProps.prescriptionType === "REFILL") {
        dispatch(updateRefillInsUserPreference(keyword));
      } else {
        dispatch(updateTopupInsUserPreference(keyword));
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionsPage);
