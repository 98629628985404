import { FormControl, RadioGroup, Box, Typography } from "@mui/material";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import RadioButton from "lib/components/formInputs/FullRadioButton/FullRadioButton";
import { KioskQuestionProps } from "./KioskQuestion.types";
import { sxStyles } from "./KioskQuestion.styles";

const ANSWER_YES = "Yes";
const ANSWER_NO = "No";

const KioskQuestion = ({
  questionNumber,
  questionText,
  questionAns,
  handleQuestionnaireChange,
}: KioskQuestionProps) => {
  const classes = sxStyles();
  return (
    <FormControl component="fieldset" fullWidth>
      <Box sx={classes.formSameLine}>
        <Typography sx={classes.questionNo}>
          {questionNumber + 1 + "."}
        </Typography>
        <Box sx={{ ...classes.fullWidth }}>
          <Typography sx={classes.questionBody}>
            {MapRawStringToReactElement(questionText.replace(/\\n/g, "<br/>"))}
          </Typography>
          <RadioGroup
            name={"q" + questionNumber}
            value={questionAns}
            onChange={(event) => {
              const selectedValue = event.target.value;
              if (selectedValue === "true") {
                handleQuestionnaireChange(true);
              } else {
                handleQuestionnaireChange(false);
              }
            }}
          >
            <Box sx={classes.radioButtons}>
              <Box sx={{ mr: "10px" }}>
                <RadioButton
                  checked={questionAns === true}
                  label={ANSWER_YES}
                  value={true}
                />
              </Box>
              <Box>
                <RadioButton
                  checked={questionAns === false}
                  label={ANSWER_NO}
                  value={false}
                />
              </Box>
            </Box>
          </RadioGroup>
        </Box>
      </Box>
    </FormControl>
  );
};

export default KioskQuestion;
