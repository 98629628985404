import axios from "axios";
import { ADD_MANUAL_BILL_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatAddManualBillErrorMessage } from "api/resources/messages/ErrorMessage";
import { AddManualBillResponsePayload } from "./addManualBill.fromApi.types";

/**
 * Try adding a manual bill and get its information
 *
 * POST
 * Endpoint: secure/payment/AddManualBill
 *
 * @param {string  | null} institutionCode
 * @param {string | null} invoiceNumber
 * @param {string | null} memberIdentifier Encrypted NRIC. e.g. "d030644b48cdbf04f4f87e6dfd02939f" (this one is invalid)
 */
const addManualBill = async (
  institutionCode: string | null,
  invoiceNumber: string | null,
  memberIdentifier: string | null,
): Promise<AddManualBillResponsePayload> => {
  try {
    const response = await axios.post<AddManualBillResponsePayload>(
      ADD_MANUAL_BILL_URL(),
      createRequestPayload(institutionCode, invoiceNumber, memberIdentifier),
      getRequestOptions(),
    );
    return parseResponse(response, ADD_MANUAL_BILL_URL());
  } catch (error) {
    const msg = formatAddManualBillErrorMessage(ADD_MANUAL_BILL_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  institutionCode: string | null,
  invoiceNumber: string | null,
  memberIdentifier: string | null,
) => {
  return {
    InstitutionCode: institutionCode,
    InvoiceNumber: invoiceNumber,
    MemberIdentifier: memberIdentifier,
  };
};

export { addManualBill };
