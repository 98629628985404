/**
 * The function `toTitleCase` takes a string as input and returns the same string with each word
 * capitalized, except for articles and hyphenated words.
 * @param {string} str - The `str` parameter is a string that represents a sentence or a phrase.
 * @returns a string with each word capitalized, except for articles (such as "a", "an", and "the") and
 * words that contain hyphens.
 */
function toTitleCase(str: string) {
  // List of articles that will not be uppercase
  const articles = ["a", "an", "the", "to"];

  const words = str.toLowerCase().split(" ");

  // Capitalize each word except articles
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (!word.includes("-") && !articles.includes(word)) {
      words[i] = capitalizeWord(word);
    } else if (articles.includes(word)) {
      words[i] = word.toLowerCase();
    } else {
      const hyphenatedWords = word.split("-");
      for (let j = 0; j < hyphenatedWords.length; j++) {
        const hyphenatedWord = hyphenatedWords[j];
        if (!articles.includes(hyphenatedWord)) {
          hyphenatedWords[j] = capitalizeWord(hyphenatedWord);
        }
      }
      words[i] = hyphenatedWords.join("-");
    }
  }

  // Join the words back into a string
  return words.join(" ");
}

function capitalizeWord(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export default toTitleCase;
