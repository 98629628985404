import { Detail } from "api/medrefill/GetMedicationSummaryDetail/getMedicationSummaryDetail.fromApi.types";
import { MedImage } from "api/medrefill/GetMedicationImages/getMedicationImages.fromApi.types";
import { MedRefillState } from "ui/medrefill/ducks/medrefill.types";
import { initialState } from "ui/medrefill/ducks";
import { UploadState } from "lib/components/upload/ImageUploader/ImageUploader.types";

export const mapPastRequestTopupDetailToMedrefillState = (
  summaryDetail: Detail | null,
  images: MedImage[] | null,
) => {
  let medrefillState: MedRefillState = { ...initialState };
  medrefillState.institution = { ...initialState.institution };
  medrefillState.particulars = { ...initialState.particulars };
  medrefillState.prescription = { ...initialState.prescription };
  medrefillState.quantity = { ...initialState.quantity };
  medrefillState.collection = { ...initialState.collection };
  medrefillState.payment = { ...initialState.payment };
  medrefillState.fileUpload = { ...initialState.fileUpload };
  if (summaryDetail) {
    medrefillState.requestType = summaryDetail.PrescripionType;

    medrefillState.institution.facilityId = summaryDetail.FacilityId;
    medrefillState.institution.facilityName = summaryDetail.Facility;
    medrefillState.institution.cluster = summaryDetail.Cluster;
    medrefillState.institution.clinicId = summaryDetail.ClinicId;

    medrefillState.particulars.requesterId = summaryDetail.RequesterId;
    medrefillState.particulars.requesterNric = summaryDetail.RequesterNric;
    medrefillState.particulars.requesterName = summaryDetail.RequesterName;
    medrefillState.particulars.isPatient = summaryDetail.IsPatient;
    medrefillState.particulars.patientName = summaryDetail.PatientName;
    medrefillState.particulars.patientNric = summaryDetail.PatientNric;
    medrefillState.particulars.contactNumber = summaryDetail.Contact;
    medrefillState.particulars.email = summaryDetail.Email;

    medrefillState.prescription.topupReason = summaryDetail.TopupReasonId;
    medrefillState.prescription.topupReasonText = summaryDetail.TopupReason;
    medrefillState.prescription.topupOtherReason =
      summaryDetail.TopupOtherReason;
    medrefillState.prescription.files =
      images?.map((image) => {
        return {
          front: {
            fileName: image.FileName,
            fileData: image.FileData,
          },
          back: {
            fileData: null,
            fileName: null,
          },
          topUp: {
            quantity: image.TopupQuantity,
            UOMId: image.TopupQuantityUOMId,
            UOM: image.TopupQuantityUOM,
            medicationName: image.MedicationName,
          },
          prescription: null,
        };
      }) || [];

    if (images && images.length > 0) {
      var errorArray: boolean[][] = [];
      var detailsArray: UploadState[][] = [];
      images?.forEach((image) => {
        errorArray.push([false]);
        detailsArray.push([
          {
            hasUploadedSuccessfully: true,
            isLoading: false,
            fileName: image.FileName,
            fileData: image.FileData,
            errorMessage: null,
          },
        ]);
      });
      medrefillState.fileUpload.fileUploadPrescriptionErrorArray = errorArray;
    }

    medrefillState.collection.typeId = summaryDetail.CollectionTypeId;
    medrefillState.collection.locationId = summaryDetail.LocationId;
    medrefillState.collection.locationName = summaryDetail.Location;
    medrefillState.collection.date = summaryDetail.CollectionDate;
    medrefillState.collection.timeslot = summaryDetail.TimeSlot;
    medrefillState.collection.blockNo = summaryDetail.BlockNo;
    medrefillState.collection.unitNo = summaryDetail.UnitNo;
    medrefillState.collection.address = summaryDetail.Address;
    medrefillState.collection.postalCode = summaryDetail.PostalCode;
    medrefillState.collection.specialRequest = summaryDetail.SpecialRequest;

    medrefillState.payment.subsidy = summaryDetail.SubsidyId;
    medrefillState.payment.paymentInstructions =
      summaryDetail.PaymentInstructions;
    medrefillState.payment.subsidyDescription =
      summaryDetail.Subsidy?.split("|");
  }
  return medrefillState;
};
