// ==========================
// Error message formatters
//
// Formats developer-facing error messages from all API calls.
// ==========================

// ==================
// COMMON
// ==================

export const formatPayloadUndefinedErrorMessage = (URL: string) => {
  return `Response payload from "${URL}" was found to be undefined`;
};

export const formatPayloadNullErrorMessage = (URL: string) => {
  return `Response payload from "${URL}" was found to be null`;
};

export const formatGetSystemSettingsErrorMessage = (URL: string) => {
  return `An error occurred while fetching Settings from "${URL}"`;
};

export const formatGetBarCodeImageErrorMessage = (URL: string) => {
  return `An error occurred while fetching barcode image for this NRIC from "${URL}"`;
};

export const formatGetDocumentByClusterPayloadErrorMessage = (URL: string) => {
  return `An error occurred while fetching Content from "${URL}"`;
};

export const formatGetDocumentByTypePayloadErrorMessage = (URL: string) => {
  return `An error occurred while fetching Document by Type from "${URL}"`;
};

export const formatGetUserProfileErrorMessage = (URL: string) => {
  return `An error occurred while fetching User Profile from "${URL}"`;
};

export const formatGetDependentProfilesErrorMessage = (URL: string) => {
  return `An error occurred while fetching Dependent Profiles from "${URL}"`;
};

export const formatGetPatientProfileErrorMessage = (URL: string) => {
  return `An error occurred while fetching Dependent Patient Profile from "${URL}"`;
};

export const formatUpdateUserProfileErrorMessage = (URL: string) => {
  return `An error occurred while updating User Profile from "${URL}"`;
};

export const formatValidateConsentErrorMessage = (URL: string) => {
  return `An error occurred while getting user consent from "${URL}"`;
};

// =============================
// API-specific for Appointments
// =============================

export const formatGetAppointmentListErrorMessage = (URL: string) => {
  return `An error occurred while fetching appointment list from "${URL}"`;
};

export const formatCreateAppointmentErrorMessage = (URL: string) => {
  return `An error occurred while creating an appointment from "${URL}"`;
};

export const formatGetSlotSearchDatesErrorMessage = (URL: string) => {
  return `An error occurred while fetching allowed slot search dates from "${URL}"`;
};

export const formatGetAvailableSlotsErrorMessage = (URL: string) => {
  return `An error occurred while fetching available slots from "${URL}"`;
};

export const formatRescheduleAppointmentErrorMessage = (URL: string) => {
  return `An error occurred while rescheduling an appointment from "${URL}"`;
};

export const formatRescheduleLinkedAppointmentErrorMessage = (URL: string) => {
  return `An error occurred while rescheduling a linked appointment from "${URL}"`;
};

export const formatCreateLinkedAppointmentErrorMessage = (URL: string) => {
  return `An error occurred while creating a linked appointment from "${URL}"`;
};

export const formatCancelAppointmentErrorMessage = (URL: string) => {
  return `An error occurred while canceling an appointment from "${URL}"`;
};

export const formatSubmitAppointmentRequestErrorMessage = (URL: string) => {
  return `An error occurred while submitting this appointment request to "${URL}"`;
};

export const formatCreateMammogramAppointmentRequestErrorMessage = (
  URL: string,
) => {
  return `An error occurred while submitting this appointment request to create a mammogram appointment "${URL}"`;
};

export const formatGetInstitutionsByClustersAndGroupsErrorMessage = (
  URL: string,
) => {
  return `An error occurred while fetching list of institutions for appointment booking from "${URL}"`;
};

export const formatGetServiceListErrorMessage = (URL: string) => {
  return `An error occurred while fetching list of services for a particular clinic from "${URL}"`;
};

export const formatGetAppointmentStatusErrorMessage = (URL: string) => {
  return `An error occurred while fetching appointment status for a particular user from "${URL}"`;
};

export const formatGetHealthPlanCompletionDateErrorMessage = (URL: string) => {
  return `An error occurred while fetching health plan completion date for a particular user from "${URL}"`;
};

export const formatGetPopHealthStatusErrorMessage = (URL: string) => {
  return `An error occurred while fetching pop health status for a particular user from "${URL}"`;
};

export const formatGetTriageQuestionnaireErrorMessage = (URL: string) => {
  return `An error occurred while fetching triage questionnaire from "${URL}"`;
};

export const formatSubmitTriageQuestionnaireErrorMessage = (URL: string) => {
  return `An error occurred while submitting this triage questionnaire request to "${URL}"`;
};

export const formatGetKioskQuestionnaireErrorMessage = (URL: string) => {
  return `An error occurred while fetching kiosk questionnaire from "${URL}"`;
};

export const formatGetMammogramQuestionnaireErrorMessage = (URL: string) => {
  return `An error occurred while fetching mammogram questionnaire from "${URL}"`;
};

export const formatActualiseAppointmentErrorMessage = (URL: string) => {
  return `An error occurred while actualising an appointment from "${URL}"`;
};

export const formatGetQueueStatusAndJourneyErrorMessage = (URL: string) => {
  return `An error occurred while fetching queue status and journey from "${URL}"`;
};

export const formatUpdateHandphoneNumberErrorMessage = (URL: string) => {
  return `An error occurred while updating phone number for queue chit from "${URL}"`;
};

export const formatCheckInErrorMessage = (URL: string) => {
  return `An error occurred while checkin an appointment from "${URL}"`;
};

// =============================
// API-specific for Encounter (Virtual Consult Platform)
// =============================

export const formatAttendConsultationErrorMessage = (URL: string) => {
  return `An error occurred while starting consultation at "${URL}"`;
};

export const formatRequeueForConsultationErrorMessage = (URL: string) => {
  return `An error occurred while requeuing for an appointment from "${URL}"`;
};

// =============================
// API-specific for Medrefill
// =============================

export const formatGetFacilitiesErrorMessage = (URL: string) => {
  return `An error occurred while fetching facilites from "${URL}"`;
};

export const formatSubmitMedicationErrorMessage = (URL: string) => {
  return `An error occurred while submitting medication request from "${URL}"`;
};

export const formatGetMedicationsErrorMessage = (URL: string) => {
  return `An error occurred while fetching medications from "${URL}"`;
};

export const formatGetClinicsErrorMessage = (URL: string) => {
  return `An error occurred while fetching clinics from "${URL}"`;
};

export const formatGetCollectionPaymentsErrorMessage = (URL: string) => {
  return `An error occurred while fetching collection and payment details from "${URL}"`;
};

export const formatGetMedicationSummaryDetailErrorMessage = (URL: string) => {
  return `An error occurred while fetching medication summary detail from "${URL}"`;
};

export const formatGetMedicationImagesErrorMessage = (URL: string) => {
  return `An error occurred while fetching medication images from "${URL}"`;
};

export const formatGetLocationsErrorMessage = (URL: string) => {
  return `An error occurred while fetching collection location details from "${URL}"`;
};

export const formatGetMedicationSummaryErrorMessage = (URL: string) => {
  return `An error occurred while fetching medication summaries from "${URL}"`;
};

export const formatGetMostRecentMedicationSummaryErrorMessage = (
  URL: string,
) => {
  return `An error occurred while fetching most recent medication summaries from "${URL}"`;
};

// =============================
// API-specific for MRRP
// =============================

export const formatGetReportHistoryErrorMessage = (URL: string) => {
  return `An error occurred while fetching report history from "${URL}"`;
};

export const formatGetConfigurationErrorMessage = (URL: string) => {
  return `An error occurred while fetching configurations from "${URL}"`;
};

export const formatGetDepartmentErrorMessage = (URL: string) => {
  return `An error occurred while fetching department from "${URL}"`;
};

export const formatGetHospitalErrorMessage = (URL: string) => {
  return `An error occurred while fetching hospital from "${URL}"`;
};

export const formatGetReportOptionsErrorMessage = (URL: string) => {
  return `An error occurred while fetching repot options of application from "${URL}"`;
};

export const formatValidatePatientMrnErrorMessage = (URL: string) => {
  return `An error occurred while validating patient MRN from "${URL}"`;
};

export const formatCreateMedicalReportErrorMessage = (URL: string) => {
  return `An error occurred while creating medical report from "${URL}"`;
};

export const formatDownloadMedicalReportErrorMessage = (URL: string) => {
  return `An error occurred while downloading medical report from "${URL}"`;
};

export const formatSubmitPaymentErrorMessage = (URL: string) => {
  return `An error occurred while submitting payment info from "${URL}"`;
};

export const formatGetPaymentStatusInfoErrorMessage = (URL: string) => {
  return `An error occurred while getting payment status info from "${URL}"`;
};

export const formatGetAllByPlatformErrorMessage = (URL: string) => {
  return `An error occurred while getting all by platform from "${URL}"`;
};

// =============================
// API-specific for MHM
// =============================

export const formatGetCarePlanPackageEnrollmentErrorMessage = (URL: string) => {
  return `An error occurred while fetching care plan package enrollment detail from "${URL}"`;
};
export const formatEnrollInCarePlanErrorMessage = (URL: string) => {
  return `An error occurred while enroll in care plan from "${URL}"`;
};
export const formatGetCarePlanCategoryListErrorMessage = (URL: string) => {
  return `An error occurred while fetching care plan category list from "${URL}"`;
};

export const formatGetCarePlanListErrorMessage = (URL: string) => {
  return `An error occurred while fetching care plan list from "${URL}"`;
};

export const formatGetQuestionnaireByCategoryErrorMessage = (URL: string) => {
  return `An error occurred while fetching questionnaire from "${URL}"`;
};

export const formatUpdateQuestionnaireByCategoryErrorMessage = (
  URL: string,
) => {
  return `An error occurred while updating questionnaire answers from "${URL}"`;
};

// =============================
// API-specific for Payments
// =============================

export const formatAddManualBillErrorMessage = (URL: string) => {
  return `An error occurred while adding manual bill from "${URL}"`;
};
export const formatGetPdfBillErrorMessage = (URL: string) => {
  return `An error occurred while getting bill PDF from "${URL}"`;
};
export const formatGetPaymentInfoErrorMessage = (URL: string) => {
  return `An error occurred while getting payment info from "${URL}"`;
};
export const formatSubmitPaymentRequestErrorMessage = (URL: string) => {
  return `An error occurred while submitting payment request from "${URL}"`;
};
export const formatGetOutstandingBillsErrorMessage = (URL: string) => {
  return `An error occurred while fetching outstanding bills from "${URL}"`;
};
export const formatGetPaidBillsHistoryErrorMessage = (URL: string) => {
  return `An error occurred while fetching paid bills from "${URL}"`;
};
export const formatGetBillSummaryDetailsErrorMessage = (URL: string) => {
  return `An error occurred while fetching bill details from "${URL}"`;
};
export const formatGetTransactionHistoryErrorMessage = (URL: string) => {
  return `An error occurred while fetching transaction history from "${URL}"`;
};
export const formatGetArInstitutionsErrorMessage = (URL: string) => {
  return `An error occurred while fetching list of institutions for add other bills from "${URL}"`;
};
export const formatGetReceiptPdfErrorMessage = (URL: string) => {
  return `An error occurred while getting receipt PDF from "${URL}"`;
};
export const formatGetAvailablePaymentModesErrorMessage = (URL: string) => {
  return `An error occurred while getting available payment modes from "${URL}"`;
};

// =============================
// API-specific for MyInfo
// =============================
export const formatGetMyInfoAuthUrlErrorMessage = (URL: string) => {
  return `An error occurred while fetching My Info auth from "${URL}"`;
};

export const formatRetrieveMyInfoProfileErrorMessage = (URL: string) => {
  return `An error occurred while fetching My Info Profile from "${URL}"`;
};

// =============================
// API-specific for PatientMaster
// =============================
export const formatCreatePatientMasterProfileErrorMessage = (URL: string) => {
  return `An error occurred while creating Patient Master from "${URL}"`;
};

export const formatGetPatientMasterProfileErrorMessage = (URL: string) => {
  return `An error occurred while fetching Patient Master from "${URL}"`;
};

export const formatUpdatePatientMasterProfileErrorMessage = (URL: string) => {
  return `An error occurred while updating Patient Master from "${URL}"`;
};

// =============================
// API-specific for Health Together
// =============================
export const formatEnrollInHealthTogetherCarePlanErrorMessage = (
  URL: string,
) => {
  return `An error occurred while enrolling in Health Together from "${URL}"`;
};

// ============================
// API-specific for Data
// ============================
export const formatGetAnnouncementsErrorMessage = (URL: string) => {
  return `An error occurred while fetching announcements from "${URL}"`;
};
export const formatGetStaticContentsByModuleErrorMessage = (URL: string) => {
  return `An error occurred while fetching static contents from "${URL}"`;
};

export const formatGetLiveChatFlagErrorMessage = (URL: string) =>
  `An error occurred while fetching live chat flag from "${URL}"`;

// =============================
// External API
// =============================
export const formatGetAddressByPostalCodeErrorMessage = (URL: string) => {
  return `An error occurred while fetching address by postal code from "${URL}"`;
};
