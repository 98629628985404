import { Dispatch, AnyAction } from "@reduxjs/toolkit";
import { getOutstandingBills } from "api/payment/GetOutstandingBills/getOutstandingBills";
import { ViewOutstandingBills } from "api/payment/GetOutstandingBills/getOutstandingBills.fromApi.types";
import { getPaidBillsHistory } from "api/payment/GetPaidBillsHistory/getPaidBillsHistory";
import { ViewPaidBillsHistory } from "api/payment/GetPaidBillsHistory/getPaidBillsHistory.fromApi.types";
import { getTransactionHistory } from "api/payment/GetTransactionHistory/getTransactionHistory";
import { getBillSummaryDetails } from "api/payment/GetBillSummaryDetails/getBillSummaryDetails";
import { RootState } from "lib/redux/root/redux.types";
import {
  setOutstandingBills,
  setOutstandingBillsErrorMessage,
  setOutstandingBillsHasErrored,
  setOutstandingBillsIsLoading,
  setPaidBills,
  setPaidBillsErrorMessage,
  setPaidBillsHasErrored,
  setPaidBillsIsLoading,
  setTransactionHistory,
  setTransactionHistoryErrorMessage,
  setTransactionHistoryHasErrored,
  setTransactionHistoryIsLoading,
  setBillDetailsIsLoading,
  setBillDetailsHasErrored,
  setBillDetailsErrorMessage,
  setBillDetails,
  setErrorStatusByApi,
  setIsLoadingByApi,
  setGetPaymentInfoResult,
  setSubmitPaymentRequestResult,
  setArInstitutionsList,
  setArInstitutionsHasErrored,
  setArInstitutionsErrorMessage,
  setArInstitutionsIsLoading,
  setAvailablePaymentModes,
  setAvailablePaymentModesHasErrored,
  setAvailablePaymentModesErrorMessage,
  setAvailablePaymentModesIsLoading,
  setDocumentByTypeInitializeErrorMessage,
  setDocumentByTypeInitializeHasErrored,
  setDocumentByTypeInitializeIsLoading,
  setDocumentByTypeInitializeValue,
} from "./paymentSlice";
import { SubmitPaymentRequestBillDetails } from "api/payment/SubmitPaymentRequest/submitPaymentRequest.fromApi.types";
import { submitPaymentRequest } from "api/payment/SubmitPaymentRequest/submitPaymentRequest";
import { getPaymentInfo } from "api/payment/GetPaymentInfo/getPaymentInfo";
import { ApiEnum, PaymentBillsToPayState } from "./payment.types";
import { ViewManualBill } from "api/payment/AddManualBill/addManualBill.fromApi.types";
import { getArInstitutions } from "api/payment/GetArInstitutions/getArInstitutions";
import { ViewArInstitutions } from "api/payment/GetArInstitutions/getArInstitutions.fromApi.types";
import { getAvailablePaymentModes } from "api/payment/GetAvailablePaymentModes/getAvailablePaymentModes";
import { getDocumentByType } from "api/shared/GetDocumentByType/getDocumentByType";
import { DocumentByTypeMap } from "ui/appointment/ducks/appointments.types";
import { AxiosError } from "axios";

/**
 * A thunk that fetches outstanding bills (past report details) from
 * the remote API. Reason not using hook: need switching between outstanding bills
 * page and outstanding bill detail page while avoid re-calling the API.
 *
 */
export const fetchOutstandingBills =
  () => async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(setOutstandingBillsIsLoading(true));
      const memberIdentifier = getState().user.memberIdentifier;
      const outstandingBillsResponseData = await getOutstandingBills(
        null,
        memberIdentifier,
      );

      dispatch(
        setOutstandingBills(
          outstandingBillsResponseData.Bills.sort(compareCreateTime),
        ),
      );
      dispatch(setOutstandingBillsHasErrored(false));
      dispatch(setOutstandingBillsErrorMessage(null));
    } catch (error) {
      dispatch(setOutstandingBills([]));
      dispatch(setOutstandingBillsHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setOutstandingBillsErrorMessage(error.response?.data.Message));
      } else {
        dispatch(setOutstandingBillsErrorMessage(null));
      }
    } finally {
      dispatch(setOutstandingBillsIsLoading(false));
    }
  };

/**
 * Helper function to sort outstanding bills based on VisitDate
 * @param {ViewOutstandingBills} bill1
 * @param {ViewOutstandingBills} bill2
 */

const compareCreateTime = (
  bill1: ViewOutstandingBills,
  bill2: ViewOutstandingBills,
) => {
  if (bill1.SortDate < bill2.SortDate) return 1;
  if (bill1.SortDate > bill2.SortDate) return -1;
  return 0;
};

/**
 * A thunk that fetches paid bills (paid bills details) from
 * the remote API. Reason not using hook: need switching between paid bills
 * page and paid bill detail page while avoid re-calling the API.
 *
 */
export const fetchPaidBills =
  () => async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(setPaidBillsIsLoading(true));
      const memberIdentifier = getState().user.memberIdentifier;
      const paidBillsResponseData = await getPaidBillsHistory(
        "NUHS",
        memberIdentifier,
      );
      dispatch(
        setPaidBills(paidBillsResponseData.Bills.sort(compareVisitTime)),
      );
      dispatch(setPaidBillsHasErrored(false));
      dispatch(setPaidBillsErrorMessage(null));
    } catch (error) {
      dispatch(setPaidBills([]));
      dispatch(setPaidBillsHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setPaidBillsErrorMessage(error.response?.data.Message));
      }
    } finally {
      dispatch(setPaidBillsIsLoading(false));
    }
  };

/**
 * Helper function to sort paid bills based on VisitDate
 * @param {ViewPaidBillsHistory} bill1
 * @param {ViewPaidBillsHistory} bill2
 */

const compareVisitTime = (
  bill1: ViewPaidBillsHistory,
  bill2: ViewPaidBillsHistory,
) => {
  if (bill1.SortDate < bill2.SortDate) return 1;
  if (bill1.SortDate > bill2.SortDate) return -1;
  return 0;
};

/**
 * A thunk that fetches transaction history from
 * the remote API. Reason not using hook: need switching between transaction history
 * page and transaction history detail page while avoid re-calling the API.
 *
 * @param {string | null} startDate // Result from SubmitPaymentRequest API
 * @param {string | null} endDate // Result from SubmitPaymentRequest API
 * @param {string | null} pageNumber // TODO: Same as merchantRefNum
 */

export const fetchTransactionHistory =
  (
    startDate: string | null,
    endDate: string | null,
    pageNumber: string | null,
  ) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(setTransactionHistoryIsLoading(true));
      // const memberIdentifier = getState().user.memberIdentifier;

      const transactionHistoryResponseData = await getTransactionHistory({
        StartDate: startDate,
        EndDate: endDate,
        PageNumber: pageNumber,
      });
      dispatch(
        setTransactionHistory(transactionHistoryResponseData.PaymentHistory),
      );
      dispatch(setTransactionHistoryHasErrored(false));
      dispatch(setTransactionHistoryErrorMessage(null));
    } catch (error) {
      dispatch(setTransactionHistory([]));
      dispatch(setTransactionHistoryHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(
          setTransactionHistoryErrorMessage(error.response?.data.Message),
        );
      }
    } finally {
      dispatch(setTransactionHistoryIsLoading(false));
    }
  };

/**
 * A thunk that fetches paid bills (paid bills details) from
 * the remote API. Reason not using hook: need switching between paid bills
 * page and paid bill detail page while avoid re-calling the API.
 *
 */
export const fetchBillDetails =
  (billReferenceNumber: string | null, institutionCode: string | null) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(setBillDetailsIsLoading(true));
      const memberIdentifier = getState().user.memberIdentifier;
      const billDetailsResponseData = await getBillSummaryDetails(
        memberIdentifier,
        billReferenceNumber,
        institutionCode,
      );
      dispatch(setBillDetails(billDetailsResponseData.BillDetails));
      dispatch(setBillDetailsHasErrored(false));
      dispatch(setBillDetailsErrorMessage(null));
    } catch (error) {
      dispatch(setBillDetails(null));
      dispatch(setBillDetailsHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setBillDetailsErrorMessage(error.response?.data.Message));
      }
    } finally {
      dispatch(setBillDetailsIsLoading(false));
    }
  };

/**
 * A thunk that submit a payment request to API and get info to proceed to external url
 *
 * @param {string | null} institutionCode // Payee InstitutionCode registered in CPG, mandatory
 * @param {string | null} paymentMethod // "DD" for direct debit, "CC" for credit card
 * @param {number | null} totalAmount // mandatory
 *
 * @param {string | null} payorName
 * @param {string | null} mobileNo
 * @param {string | null} email
 *
 * @param {string | null} billingSystem
 * @param {string | null} visitDate
 * @param {boolean | null} isProvisionalBill
 * @param {string | null} billType
 * @param {string | null} requestedDateTime
 */
export const getSubmitPaymentRequest =
  (
    institutionCode: string | null,
    paymentMethod: string | null,
    totalAmount: number | null,
    payorName: string | null,
    mobileNo: string | null,
    email: string | null,
    systemSetting?: boolean | null,
  ) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(
        setIsLoadingByApi({
          api: ApiEnum.SubmitPaymentRequest,
          isLoading: true,
        }),
      );
      const billsToPay = getState().payments.billsToPay;
      const allOutstandingBills: ViewOutstandingBills[] =
        getState().payments.outstandingBills.allOutstandingBills;
      const allAddedBills: ViewManualBill[] =
        getState().payments.addedBills.allAddedBills;
      const submitPayentRequestResponse = await submitPaymentRequest({
        InstitutionCode: institutionCode,
        PaymentMethod: paymentMethod,
        TotalAmount: totalAmount,
        Remarks: "",
        UseCardTokenization: systemSetting || false,
        PayorInfo: {
          PayorName: payorName,
          MobileNo: mobileNo,
          Email: email,
        },
        Details: formatGetSubmitPaymentRequestDetails(
          billsToPay,
          allOutstandingBills,
          allAddedBills,
        ),
      });
      dispatch(setSubmitPaymentRequestResult(submitPayentRequestResponse));
      dispatch(
        setErrorStatusByApi({
          api: ApiEnum.SubmitPaymentRequest,
          hasErrored: false,
          errorMessage: null,
        }),
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(
          setErrorStatusByApi({
            api: ApiEnum.SubmitPaymentRequest,
            hasErrored: true,
            errorMessage: error.response?.data.Message,
          }),
        );
      }
    } finally {
      dispatch(
        setIsLoadingByApi({
          api: ApiEnum.SubmitPaymentRequest,
          isLoading: false,
        }),
      );
    }
  };

/**
 * helper function for getSubmitPaymentRequest thunk, prepare the parameter to be passed
 * to submitPaymentRequest API details prop.
 *
 * @param loginUserIdentifier
 * @param billsToPay
 * @param outstandingBills
 * @param allAddedBills
 *
 * @returns a list of SubmitPaymentRequestBillDetails
 */
const formatGetSubmitPaymentRequestDetails = (
  billsToPay: PaymentBillsToPayState[],
  outstandingBills: ViewOutstandingBills[],
  addedBills: ViewManualBill[],
): SubmitPaymentRequestBillDetails[] => {
  let allBills: (ViewOutstandingBills | ViewManualBill)[] = [];
  allBills = allBills.concat(outstandingBills);
  allBills = allBills.concat(addedBills);
  if (billsToPay.length > 1) {
    // paying multiple bills, from bill summary page
    const detailsList = allBills.map((bill, index) => {
      let remarks = {
        BillingSystem: bill.BillingSystem,
        VisitDate: bill.VisitDate,
        IsProvisionalBill: bill.IsProvisionalBill,
        BillType: bill.BillType,
        RequestedDateTime: bill.RequestedDateTime,
      };
      return {
        ReferenceNumber: bill.InvoiceNumber,
        TargetIdentifier: bill.PatientId,
        PatientName: bill.PatientName,
        InstitutionCode: bill.InstitutionCode,
        InstitutionName: bill.InstitutionName,
        Amount: Number(billsToPay[index].AmountToPay),
        Remarks: JSON.stringify(remarks),
        InvoiceStatus: bill.InvoiceStatus ?? null,
      };
    });
    return detailsList.filter(
      (_, index) =>
        billsToPay[index].Selected &&
        Number(billsToPay[index].AmountToPay) !== 0,
    );
  } else if (
    billsToPay.length === 1 &&
    Number(billsToPay[0].AmountToPay) !== 0
  ) {
    // paying single bill
    const oneBillList = allBills.filter(
      (bill) => bill.InvoiceNumber === billsToPay[0].InvNo,
    );
    const bill = oneBillList[0];
    let remarks = {
      BillingSystem: bill.BillingSystem,
      VisitDate: bill.VisitDate,
      IsProvisionalBill: bill.IsProvisionalBill,
      BillType: bill.BillType,
      RequestedDateTime: bill.RequestedDateTime,
    };
    return [
      {
        ReferenceNumber: bill.InvoiceNumber,
        TargetIdentifier: bill.PatientId,
        PatientName: bill.PatientName,
        InstitutionCode: bill.InstitutionCode,
        InstitutionName: bill.InstitutionName,
        Amount: Number(billsToPay[0].AmountToPay),
        Remarks: JSON.stringify(remarks),
        InvoiceStatus: bill.InvoiceStatus ?? null,
      },
    ];
  } else {
    // paying no bill, not a real senario, putted to avoid typescript error
    return [];
  }
};

/**
 * A thunk that fetches payment status info after user paid through external url
 *
 * @param {string | null} paymentToken // Result from SubmitPaymentRequest API
 * @param {string | null} merchantRefNum // Result from SubmitPaymentRequest API
 * @param {string | null} receiptNumber // Same as merchantRefNum
 */
export const fetchPaymentInfo =
  (
    paymentToken: string | null,
    merchantRefNum: string | null,
    receiptNumber: string | null,
  ) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(
        setIsLoadingByApi({ api: ApiEnum.GetPaymentInfo, isLoading: true }),
      );
      const paymentInfoResponse = await getPaymentInfo({
        TransactionQueryParam: {
          PaymentToken: paymentToken,
          MerchantRefNum: merchantRefNum,
        },
        ReceiptNumber: receiptNumber,
      });
      dispatch(setGetPaymentInfoResult(paymentInfoResponse));
      dispatch(
        setErrorStatusByApi({
          api: ApiEnum.GetPaymentInfo,
          hasErrored: false,
          errorMessage: null,
        }),
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(
          setErrorStatusByApi({
            api: ApiEnum.GetPaymentInfo,
            hasErrored: true,
            errorMessage: error.response?.data.Message,
          }),
        );
      }
    } finally {
      dispatch(
        setIsLoadingByApi({ api: ApiEnum.GetPaymentInfo, isLoading: false }),
      );
    }
  };

/**
 * A thunk that fetches ArInstitutions for Add manual bills
 *
 */
export const fetchArInstitutions =
  () => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setArInstitutionsIsLoading(true));
      const arInstitutionsResponseData = await getArInstitutions();
      dispatch(
        setArInstitutionsList(
          arInstitutionsResponseData.Institutions.sort(
            compareArInstitutionsName,
          ),
        ),
      );
      dispatch(setArInstitutionsHasErrored(false));
      dispatch(setArInstitutionsErrorMessage(null));
    } catch (error) {
      dispatch(setArInstitutionsList([]));
      dispatch(setArInstitutionsHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setArInstitutionsErrorMessage(error.response?.data.Message));
      }
    } finally {
      dispatch(setArInstitutionsIsLoading(false));
    }
  };

/**
 * Helper function to sort ArInstitutions Name based on alphabetically order
 * @param {ViewArInstitutions} report1
 * @param {ViewArInstitutions} report2
 */

const compareArInstitutionsName = (
  report1: ViewArInstitutions,
  report2: ViewArInstitutions,
) => {
  // compare function to sort ArInstitutions by alphabetically order
  if (report1.Name < report2.Name) return -1;
  if (report1.Name > report2.Name) return 1;
  return 0;
};

/**
 * A thunk that fetches AvailablePaymentModes for Payment Submission
 *
 */
export const fetchAvailablePaymentModes =
  () => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setAvailablePaymentModesIsLoading(true));
      const availablePaymentModesResponseData =
        await getAvailablePaymentModes();
      dispatch(
        setAvailablePaymentModes({
          IsCcAvailable:
            availablePaymentModesResponseData.IsCcAvailable ?? false,
          IsDdAvailable:
            availablePaymentModesResponseData.IsDdAvailable ?? false,
          IsAmexAvailable:
            availablePaymentModesResponseData.IsAmexAvailable ?? false,
          CcDisclaimer: availablePaymentModesResponseData.CcDisclaimer ?? "",
          DdDisclaimer: availablePaymentModesResponseData.DdDisclaimer ?? "",
          AmexDisclaimer:
            availablePaymentModesResponseData.AmexDisclaimer ?? "",
        }),
      );
      dispatch(setAvailablePaymentModesHasErrored(false));
      dispatch(setAvailablePaymentModesErrorMessage(null));
    } catch (error) {
      dispatch(
        setAvailablePaymentModes({
          IsCcAvailable: false,
          IsDdAvailable: false,
          IsAmexAvailable: false,
          CcDisclaimer: null,
          DdDisclaimer: null,
          AmexDisclaimer: null,
        }),
      );
      dispatch(setAvailablePaymentModesHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(
          setAvailablePaymentModesErrorMessage(error.response?.data.Message),
        );
      }
    } finally {
      dispatch(setAvailablePaymentModesIsLoading(false));
    }
  };

/**
 * A thunk that fetches disclaimer texts, such as pending submission status text
 *
 */
export const fetchDocumentForAllTypes =
  () => async (dispatch: Dispatch<AnyAction>) => {
    let map: DocumentByTypeMap = {};
    try {
      dispatch(setDocumentByTypeInitializeIsLoading(true));
      const response = await getDocumentByType("Payments", null);
      if (response.Document.length !== 0) {
        //store all document byt type as key and value pair to make the lookup easier, and avoid multiples call of API
        for (var document of response.Document) {
          map[document.Type] = document.Contents;
        }
        dispatch(setDocumentByTypeInitializeValue(map));
        dispatch(setDocumentByTypeInitializeHasErrored(false));
        dispatch(setDocumentByTypeInitializeErrorMessage(null));
      } else {
        dispatch(setDocumentByTypeInitializeValue(map));
        dispatch(setDocumentByTypeInitializeHasErrored(true));
        dispatch(setDocumentByTypeInitializeErrorMessage(response.Message));
      }
    } catch (error) {
      dispatch(setDocumentByTypeInitializeValue(map));
      dispatch(setDocumentByTypeInitializeHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(
          setDocumentByTypeInitializeErrorMessage(error.response?.data.Message),
        );
      }
    } finally {
      dispatch(setDocumentByTypeInitializeIsLoading(false));
    }
  };
