import {
  Box,
  Button,
  Link,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import { sxStyles } from "./CommunityCarePage.styles";
import BannerCard from "lib/components/cards/BannerCard/BannerCard";
import { navigateToExternalInMobileBrowser } from "lib/routing/navigate/navigate";
import IMAGES from "lib/assets/images";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";

const NUHSCC_URL = "https://form.gov.sg/6239c7ac339cfd00134d299d";
const CCPROG_URL =
  "https://www.nuhs.edu.sg/Care-in-the-Community/Enabling-Community-Care/Health-Together/Community-Care-Programmes/Pages/default.aspx";

const CommunityCarePage = () => {
  const theme = useTheme();
  const classes = sxStyles(theme);

  return (
    <Box sx={classes.mainContainer}>
      <Box>
        <BannerCard
          bannerImage={IMAGES.healthTogetherMHM.CommunityCareBannerImage}
        />
        <Box sx={classes.contentBox}>
          <Typography sx={classes.title}>NUHS Community Health Post</Typography>
          <Typography sx={classes.body}>
            Providing healthcare support in your neighbourhood. Our NUHS
            Community Care Team is here to guide you and your loved ones towards
            better health. Our Care Services include:
          </Typography>

          <List sx={classes.list}>
            <ListItem sx={classes.listItem}>
              <Typography component="span" sx={classes.listItemText}>
                Health checks and managing your chronic conditions
              </Typography>
            </ListItem>
            <ListItem sx={classes.listItem}>
              <Typography component="span" sx={classes.listItemText}>
                Planning your personal health goals with you and how to achieve
                them
              </Typography>
            </ListItem>
            <ListItem sx={classes.listItem}>
              <Typography component="span" sx={classes.listItemText}>
                Connecting you with other care services and resources in the
                community
              </Typography>
            </ListItem>
          </List>
        </Box>
        <Box sx={classes.boxBG}>
          <Button
            fullWidth
            onClick={() => {
              logEventToGoogleAnalytics(EVENTS.CLICK_CCP_BOOK_AN_APPT_WITH_US);
              navigateToExternalInMobileBrowser(NUHSCC_URL);
            }}
          >
            Book an appointment with us
          </Button>
          <Typography sx={classes.alternativeText}>
            Alternatively, contact us at{" "}
            <Link href="tel:69953619">6995 3619</Link> or{" "}
            <Link href="mailto:carehub@nuhs.edu.sg">carehub@nuhs.edu.sg</Link>
          </Typography>
          <Typography sx={classes.alternativeText}>
            Business Hours: <br /> Mondays to Thursdays, 8:00 am - 5:30 pm{" "}
            <br /> Fridays, 8:00 am - 5:00 pm
            <br /> Excluding Public Holidays
          </Typography>
        </Box>
      </Box>

      <Box sx={classes.buttonBox}>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => {
            logEventToGoogleAnalytics(
              EVENTS.CLICK_CCP_VIEW_OTHER_COMM_PROGRAMMES,
            );
            navigateToExternalInMobileBrowser(CCPROG_URL);
          }}
        >
          View other community programmes
        </Button>
      </Box>
    </Box>
  );
};

export default CommunityCarePage;
