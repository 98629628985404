import { ApiEnum, PaymentState } from "./payment.types";

// Initial state representing the time when mrrp is not initialized yet
export const initialState: PaymentState = {
  documentByType: {
    isLoading: false,
    hasErrored: false,
    errorMessage: null,
    documentByTypeMap: {},
  },

  outstandingBills: {
    shouldUpdate: true,
    isLoading: false,
    hasErrored: null,
    errorMessage: null,
    selectedOutstandingBillsIndex: null,
    allOutstandingBills: [],
    expandedOutstandingBillsListFlag: false,
  },

  addedBills: {
    selectedAddedBillsIndex: null,
    allAddedBills: [],
  },

  billDetails: {
    isLoading: true,
    hasErrored: null,
    errorMessage: null,
    billDetails: null,
  },

  paidBills: {
    isLoading: true,
    hasErrored: null,
    errorMessage: null,
    selectedPaidBillsIndex: null,
    allPaidBills: [],
    expandedPaidBillsListFlag: false,
  },

  transactionHistory: {
    isLoading: true,
    hasErrored: null,
    errorMessage: null,
    selectedTransactionHistoryIndex: null,
    allTransactionHistory: [],
    expandedTransactionHistoryListFlag: false,
  },

  billsToPay: [],

  payment: {
    submitPaymentRequest: {
      PaymentMode: null,
      MerchantRefNum: null,

      PaymentToken: null,
      Expires: null,

      RedirectionUrl: null,
      ModuleCallbackUrl: null,
    },
    paymentInfo: {
      StatusMessage: null,
      TotalAmount: null,
      PaymentStatus: null,
      InstitutionCode: null,
      InstitutionName: null,
      PaymentCardNumber: null,
      ReceiptNumber: null,
      ReceiptDate: null,
      PayorDetails: {
        MobileNo: null,
        PayorName: null,
        Email: null,
      },
      PaymentDetails: [],
      EnetsMessage: null,
    },
  },

  receiptPageLanding: true,

  apiStatus: {
    [ApiEnum.SubmitPaymentRequest]: {
      isLoading: null,
      hasErrored: null,
      errorMessage: null,
    },
    [ApiEnum.GetPaymentInfo]: {
      isLoading: null,
      hasErrored: null,
      errorMessage: null,
    },
  },

  paymentPersist: {
    payorName: null,
    email: null,
    paymentToken: null,
    merchantRefNumber: null,
    requestorMemberIdentifier: null,
    selectedPaymentMode: null,
  },

  arInstitutions: {
    arInstitutionsList: [],
    isLoading: false,
    hasErrored: null,
    errorMessage: null,
  },

  availablePaymentModes: {
    IsCcAvailable: false,
    IsDdAvailable: false,
    IsAmexAvailable: false,
    CcDisclaimer: null,
    DdDisclaimer: null,
    AmexDisclaimer: null,
    isLoading: false,
    hasErrored: null,
    errorMessage: null,
  },
};
