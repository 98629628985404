import React from "react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import theme from "./theme";

interface CustomThemeProviderProps {
  children: React.ReactNode; // accepts any React component
}

/**
 * Application's custom theme provider.
 * Uses the theme customized for this entire application.
 *
 * @param {React.ReactNode} children  Any React component which will receive the provided props from the Material UI theme provider
 */
const CustomThemeProvider = ({ children }: CustomThemeProviderProps) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default CustomThemeProvider;
