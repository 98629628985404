import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { sxStyles } from "./IconTextButton.styles";
import { IconTextButtonProps } from "./IconTextButtonProps.types";

/**
 * The `IconTextButton` component is a TypeScript React component that renders a button with an icon
 * and text label.
 * @param {IconTextButtonProps} `icon`: The source URL of the icon image.
 * @param {IconTextButtonProps} `label`: An array of string where each element will be rendered in a new line
 * @param {IconTextButtonProps} `onClick`: A callback function triggered on click of component
 * @param {IconTextButtonProps} `ariaLabel`: This should be the label of button for ease of location in debugging and unit testing
 * @returns The `IconTextButton` component is returning a JSX element.
 */
const IconTextButton = ({
  icon,
  label,
  ariaLabel,
  isDisabled,
  onClick,
}: IconTextButtonProps) => {
  const theme = useTheme();
  const classes = sxStyles();

  return (
    <IconButton
      aria-label={ariaLabel}
      sx={classes.mainContainer}
      onClick={onClick}
    >
      <Box
        component={"img"}
        src={icon}
        alt={`${label ? label.join(" ") : ""}${
          label && label.length > 0 ? " " : null
        }icon`}
        sx={classes.iconContainer}
      />

      {label?.map((label) => (
        <Typography
          sx={{
            ...classes.text,
            ...(isDisabled ? { color: theme.palette.grey[100] } : {}),
          }}
          key={label}
          noWrap
        >
          {label?.trim()}
        </Typography>
      ))}
    </IconButton>
  );
};

export default IconTextButton;
