import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    formSection: {
      marginTop: theme.spacing(11.5),
    },
    buttonsFooter: {
      padding: theme.spacing(2),
    },
  } satisfies SxObjType;
};

export { sxStyles };
