import { useEffect, useState } from "react";
import { Typography, Box, CircularProgress, Link } from "@mui/material";
import CommonRadioButton from "lib/components/formInputs/CommonRadioButton/CommonRadioButton";
import { sxStyles } from "./SelectHospital.styles";
import { IterableElement } from "lib/data/data.types";
import VerticalSpreadLayout from "lib/components/layout/VerticalSpreadLayout/VerticalSpreadLayout";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import { SelectHospitalProps } from "./SelectHospital.types";
import Page from "lib/components/layout/Page/Page";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import { ViewHospital } from "api/mrrp/GetHospital/getHospital.fromApi.types";
import { useValidatePatientMrn } from "ui/mrrp/hooks/useValidatePatientMrn";
import { navigateToExternalInMobileBrowser } from "lib/routing/navigate/navigate";
import SimpleCheckbox from "lib/components/formInputs/Checkbox/SimpleCheckbox";

const hospitalLinks = {
  NTFGH:
    "https://www.ntfgh.com.sg/for-patients-and-visitors/Pages/Request-for-Medical-Report.aspx#FAQ",
  JCH: "https://www.jch.com.sg/For-Patients-and-Visitors/Pages/Request-for-Medical-Report.aspx#FAQ",
  JMC: "https://www.nuhs.edu.sg/For-Patients-Visitors/JMC/Pages/Request-for-Medical-Report.aspx#FAQ",
  NUH: "https://www.nuh.com.sg/patients-visitors/Pages/Medical-Report-Request.aspx/1000",
  AH: "https://www.ah.com.sg/Pages/For%20Patients/Request-for-Medical-Report.aspx",
};

const SelectHospital = ({
  userState,
  isLoadingHospital,
  hasErroredHospital,
  errorMessageHospital,
  allHospitals,
  selectedHospitalCode: hospitalCode,
  isLoadingReportOptions,
  hasErroredReportOptions,
  errorMessageReportOptions,
  formLabelAPIStatus,
  onLoad,
  onRedirect,
  onGetUserParticulars,
  onSetPatient,
  onGetHospital,
  onSaveHospital,
  onGetReportOptions,
  onGetFormLabels,
}: SelectHospitalProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();

  useEffect(() => {
    // on page load
    onLoad();
    onGetHospital();
    onGetUserParticulars(userState.isPatient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoad]);

  const [selectedHospitalCode, setSelectedHospitalCode] = useState<
    string | null
  >(hospitalCode);
  const [checkedTnC, setCheckedTnC] = useState(false);

  // =================================
  // Validate patient Mrn hook section
  // =================================
  const [validating, setValidating] = useState(false);
  const [
    validationResult,
    isLoadingValidation,
    hasErroredValidation,
    errorMessageValidation,
  ] = useValidatePatientMrn(selectedHospitalCode, validating);
  const [openPatientNotFound, setOpenPatientNotFound] = useState(false);

  // Process result after validation
  useEffect(() => {
    if (!isLoadingValidation && hasErroredValidation === false) {
      if (validationResult) {
        // if patient is valid, call getReportOptions and GetAllByPlatform APIs to prepare for next page
        onGetReportOptions(selectedHospitalCode);
        onGetFormLabels();
      } else {
        // validation has failed, open patient not found modal
        setOpenPatientNotFound(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validating, isLoadingValidation, validationResult, onGetReportOptions]);

  useEffect(() => {
    if (isLoadingValidation === false) {
      setValidating(false);
    }
  }, [isLoadingValidation]);

  // =================================
  // Get report options and form labels section
  // =================================

  // Process result after getting report options and form labels
  useEffect(() => {
    if (
      isLoadingReportOptions === false &&
      hasErroredReportOptions === false &&
      formLabelAPIStatus.isLoading === false &&
      formLabelAPIStatus.hasErrored === false
    ) {
      // getReportOptions and getAllByPlatform are successful. Ready to go to next page
      // set patient name and NRIC in particulars state and reset API flags
      if (userState.isPatient === true) {
        onSetPatient(userState.requestorName, userState.requestorNric);
      } else {
        onSetPatient(userState.patientName, userState.patientNric);
      }
      onRedirect();
      navigate(PATHS.MRRP_NEW_REQUEST_VERIFY_PARTICULARS.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingReportOptions,
    hasErroredReportOptions,
    formLabelAPIStatus,
    onGetReportOptions,
  ]);

  // Helper function for Next button logic
  const disableNext = () => {
    return (
      !selectedHospitalCode ||
      isLoadingValidation ||
      isLoadingReportOptions ||
      !checkedTnC
    );
  };

  return (
    <Page>
      {isLoadingHospital === true ||
      userState.isLoading === true ||
      validating ||
      isLoadingValidation === true ||
      isLoadingReportOptions === true ||
      formLabelAPIStatus.isLoading === true ? (
        <Box display="flex" align-items="center" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : hasErroredHospital ? (
        <ErrorDisplayContainer
          errorMessage={errorMessageHospital}
          onTryAgain={() => {
            onGetHospital();
          }}
        />
      ) : userState.hasErrored ? (
        <ErrorDisplayContainer
          errorMessage={userState.errorMessage}
          onTryAgain={() => {
            onGetUserParticulars(userState.isPatient);
          }}
        />
      ) : hasErroredValidation ? (
        <ErrorDisplayContainer
          errorMessage={errorMessageValidation}
          onTryAgain={() => {
            setValidating(true);
          }}
        />
      ) : hasErroredReportOptions ? (
        <ErrorDisplayContainer
          errorMessage={errorMessageReportOptions}
          onTryAgain={() => {
            onGetReportOptions(selectedHospitalCode);
          }}
        />
      ) : formLabelAPIStatus.hasErrored ? (
        <ErrorDisplayContainer
          errorMessage={formLabelAPIStatus.errorMessage}
          onTryAgain={() => {
            onGetFormLabels();
          }}
        />
      ) : (
        <>
          <VerticalSpreadLayout>
            <Box mt={2.5} mx={2}>
              <Typography sx={classes.title}>Select Hospital</Typography>
              <Typography sx={classes.disclaimer}>
                More Institutions will come online with this service in future.
              </Typography>
              <CommonRadioButton
                radioButtonSelectionList={mapper(allHospitals)}
                selectedRadioButton={selectedHospitalCode}
                handleRadioButtonChange={(code) => {
                  setSelectedHospitalCode(code);
                }}
              />
            </Box>
            <Box>
              {selectedHospitalCode ? (
                <Box sx={classes.termsBox}>
                  <SimpleCheckbox
                    preventLabelClick={true}
                    checked={checkedTnC}
                    value="TnC"
                    label={
                      <Box mt={-2}>
                        <Typography display="inline" sx={classes.text}>
                          I have read and agreed to the{" "}
                        </Typography>
                        <Link
                          onClick={() => {
                            navigateToExternalInMobileBrowser(
                              hospitalLinks[
                                selectedHospitalCode as keyof typeof hospitalLinks
                              ],
                            );
                          }}
                          sx={classes.TermsAndConditionsLink}
                        >
                          Terms and Conditions
                        </Link>
                      </Box>
                    }
                    handleChange={(event) =>
                      setCheckedTnC(event.target.checked)
                    }
                  />
                </Box>
              ) : null}

              <Box px={2} pb={4.5}>
                <ButtonsFooter
                  nextButtonText="Make new request"
                  cancelButtonText={""}
                  hideCancelButton={true}
                  isDisabled={disableNext()}
                  onClickNext={() => {
                    const index = allHospitals.findIndex(
                      (h) => h.Code === selectedHospitalCode,
                    );
                    onSaveHospital(
                      selectedHospitalCode,
                      allHospitals[index]?.Description,
                      allHospitals[index]?.PaymentMode,
                    );
                    // call validation API to check patient validity
                    // other API call will be based on the result of validation
                    setValidating(true);
                  }}
                />
              </Box>

              <ConfirmationModal
                open={openPatientNotFound}
                title="No Record of Patient"
                body="Patient is not found in the hospital records."
                showCloseButton={true}
                titleBold={false}
                nextButtonText="OK"
                onClickNext={() => setOpenPatientNotFound(false)}
                hideCancelButton={true}
                onClose={() => setOpenPatientNotFound(false)}
              />
            </Box>
          </VerticalSpreadLayout>
        </>
      )}
    </Page>
  );
};

export default SelectHospital;

const mapper = (hospitals: ViewHospital[]): IterableElement[] => {
  return hospitals.map(
    (hospital): IterableElement => ({
      id: hospital.Code || "",
      text: hospital.Description || "",
    }),
  );
};
