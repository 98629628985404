import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  setBypassEpicProfile,
  setEpicProfileState,
  setEpicProfileTempState,
  setHasFetchedEpicProfile,
  setMyInfoProfileState,
  setPreferredDateError,
} from "../PatientMaster/ducks/pmSlice";
import { setMyInfoCodeVerifier } from "lib/redux/user/userSlice";
import {
  selectEpicProfile,
  selectEpicProfileState,
  selectEpicProfileTempState,
  selectMyInfoProfile,
  selectMyInfoProfileState,
  selectPMGeneral,
  selectPMGeneralErrors,
  selectPMSettings,
  selectPMSettingsValue,
} from "../PatientMaster/ducks/selectors";
import {
  fetchEpicProfile,
  fetchMyInfoProfile,
  fetchSystemSettings,
} from "../PatientMaster/ducks/thunks";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { getMyInfoAuthUrl } from "api/myInfo/GetMyInfoAuthUrl/getMyInfoAuthUrl";
import { updatePatientMasterProfile } from "api/patientMaster/UpdatePatientMasterProfile/updatePatientMasterProfile";
import { updateUserProfile } from "api/shared/UpdateUserProfile/updateUserProfile";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import { handleErrorModal } from "lib/redux/notifications/notificationsSlice";
import { fetchUserParticulars, setShouldDirectToMyInfo } from "lib/redux/user";
import { selectMyInfoCodeVerifier, selectUser } from "lib/redux/user/selectors";
import { ACTIONS, PATHS } from "lib/routing";
import {
  mobileNavigate,
  navigateToExternalInAppWebView,
} from "lib/routing/navigate/navigate";
import PersonalDetails from "../PersonalDetails/MyInfoPersonalDetails";
import { createPatientMasterProfile } from "api/patientMaster/CreatePatientMasterProfile/createPatientMasterProfile";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import ContactDetails from "../ContactDetails/ContactDetails";
import ExitProfileDialog from "../MyProfile/ExitProfileDialog/ExitProfileDialog";
import ProfileImageUploader from "../ProfileImageUploader/ProfileImageUploader";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import {
  formatDate,
  formatOptions,
} from "lib/util/DateTimeUtil/formatDate/formatDate";
import { convertISOStringToCustomDateString } from "lib/util/DateTimeUtil/formatISOStringToCustomDateString/formatISOStringToCustomDateString";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import {
  accordionSection1Title,
  accordionSection2Title,
} from "../MyProfile/MyProfile";
import { sxStyles } from "../MyProfile/MyProfile.styles";
import AccordionBlock from "lib/components/accordion/AccordionBlock";
import {
  PatientMasterCreateUpdateProfile,
  PreferredMailingAddPage,
} from "api/patientMaster/patientMasterProfile.types";
import { UserProfile } from "api/shared/UpdateUserProfile/updateUserProfile.types";
import { AxiosError } from "axios";
import { useGetMessageAction } from "lib/routing/messageChannel/hooks/useGetMessageAction";
import { selectNavigation } from "lib/redux/navigation/selectors";
import { setCustomisedBack } from "lib/redux/navigation";
import { PM_ERROR_422 } from "api/patientMaster/GetPatientMasterProfile/getPatientMasterProfile";

export const MyInfoProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const classes = sxStyles(theme);

  const { messageActionObj, consumeMessageActionObj } = useGetMessageAction();

  // Redux States
  const myInfoParams = useAppSelector(selectMyInfoProfile);
  const myInfoData = useAppSelector(selectMyInfoProfileState);
  const codeVerifier = useAppSelector(selectMyInfoCodeVerifier);
  const epicProfileParams = useAppSelector(selectEpicProfile);
  const epicProfileData = useAppSelector(selectEpicProfileState);
  const PMAStates = useAppSelector(selectEpicProfileTempState);
  const preventDirectBackNavigationState =
    useAppSelector(selectNavigation).customisedBack;
  const {
    isLoading: pmSettingsIsLoading,
    hasErrored: pmSettingsHasErrored,
    errorMessage: pmSettingsErrorMessage,
  } = useAppSelector(selectPMSettings);
  const { bypassEpicProfile } = useAppSelector(selectPMGeneral);
  const uuid = useAppSelector(selectUser)?.myChartUuid || null;
  const userProfile = useAppSelector(selectUser);
  const { errorMessages } = useAppSelector(selectPMSettingsValue);
  const checkErrors = useAppSelector(selectPMGeneralErrors);

  //local states for MailingAddressStartDate logic
  const [changeStartDate, setChangeStartDate] = useState<boolean>(false);

  // Local states
  const [isExpandAll, setIsExpandAll] = useState<boolean | null>(null);
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([
    "block1",
    "block2",
  ]);
  const expandedAccordionsMemo = useMemo(
    () => expandedAccordions,
    [expandedAccordions],
  );

  const hasEditedPMA = useMemo(() => {
    if (PMAStates) {
      if (
        PMAStates.PreferredPostalCode !== epicProfileData.PreferredPostalCode ||
        PMAStates.PreferredStreetName !== epicProfileData.PreferredStreetName ||
        PMAStates.PreferredBlockNumber !==
          epicProfileData.PreferredBlockNumber ||
        PMAStates.PreferredFloorNumber !==
          epicProfileData.PreferredFloorNumber ||
        PMAStates.PreferredUnitNumber !== epicProfileData.PreferredUnitNumber ||
        PMAStates.MailingAddressExpiryDate !==
          epicProfileData.MailingAddressExpiryDate
      ) {
        setChangeStartDate(true);
        return true; //only if PMAState is not undefined and the values are different, return true
      } else {
        setChangeStartDate(false);
        return false;
      }
    } else {
      return false;
    }
  }, [
    PMAStates,
    epicProfileData.MailingAddressExpiryDate,
    epicProfileData.PreferredBlockNumber,
    epicProfileData.PreferredFloorNumber,
    epicProfileData.PreferredPostalCode,
    epicProfileData.PreferredStreetName,
    epicProfileData.PreferredUnitNumber,
  ]);

  // States for MyInfoAuthUrl API
  const [isLoadingMyInfoAuthUrl, setIsLoadingMyInfoAuthUrl] = useState(false);
  const [hasErroredMyInfoAuthUrl, setHasErroredMyInfoAuthUrl] = useState<{
    errored: boolean;
    message: string | null;
  }>({ errored: false, message: null });

  // States for the profile update API call
  const [updateIsLoading, setUpdateIsLoading] = useState(false);
  const [openUpdateSuccessfulDialog, setOpenUpdateSuccessfulDialog] =
    useState(false);

  const [authCode, setAuthCode] = useState<string | null>(null);
  const [redirecturl, setRedirecturl] = useState<string | null>(null);

  const [profilePhoto, setProfilePhoto] = useState(userProfile.photo);
  const [shouldRetrieveMyInfoData, setShouldRetrieveMyInfoData] =
    useState(false);

  const [errorModalPopUp, setErrorModalPopUp] = useState(false);
  const [pwrErrorModalPopUp, setpwrErrorModalPopUp] = useState(false);

  const [isUnsavedOpen, setIsUnsavedOpen] = useState(false);

  const handleOnClickExpandAll = () => {
    setIsExpandAll(!isExpandAll);
  };

  //this useEffect is to fetch PM System Settings
  useEffect(() => {
    if (bypassEpicProfile) {
      return;
    }

    dispatch(fetchSystemSettings()); //retrieve tnc, disclaimers, error messages etc
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //this is for handling of unsaved changes pop up window when 'BACK TO YOUR PARTICULARS' button is clicked
  const handleUnsavedPopupOpen = () => {
    setIsUnsavedOpen(true);
  };

  const handleUnsavedPopupClose = () => {
    setIsUnsavedOpen(false);
  };

  //this is for handling of unsaved changes pop up window when 'DO IT LATER' button is clicked
  const handleUnsavedCancelClick = () => {
    dispatch(setHasFetchedEpicProfile(false));
    dispatch(setEpicProfileTempState(undefined));
    dispatch(setBypassEpicProfile(false));
    resetPreventDirectBackNavigationState();
    if (
      userProfile.cancellationPath === PATHS.PM_ENTRY.path ||
      userProfile.cancellationPath === PATHS.PM_ENTRY_MOBILE.path
    ) {
      mobileNavigate(ACTIONS.PM_SYNC_DO_IT_LATER);
    } else {
      navigate(PATHS.MY_PROFILE_MOBILE.path); //check the diff between 'mobile' and no 'mobile'
    }
  };

  //this is for the reset of back button
  const resetPreventDirectBackNavigationState = () => {
    dispatch(setCustomisedBack(false));
    consumeMessageActionObj();
  };

  //this is a back button hijack, to customize the back button (instead of traditional back function) according to different pages/functions
  useEffect(() => {
    if (
      messageActionObj?.action === "backTriggered" ||
      preventDirectBackNavigationState
    ) {
      if (hasErroredMyInfoAuthUrl.errored || myInfoParams.hasErrored) {
        //upon clicking on the customized back
        dispatch(setHasFetchedEpicProfile(false));
        dispatch(setBypassEpicProfile(false));
        resetPreventDirectBackNavigationState();
        redirectToMyInfo();
      } else if (
        pmSettingsHasErrored ||
        userProfile.hasErrored ||
        epicProfileParams.hasErrored
      ) {
        if (
          userProfile.cancellationPath === PATHS.PM_ENTRY.path ||
          userProfile.cancellationPath === PATHS.PM_ENTRY_MOBILE.path
        ) {
          //upon clicking on the customized back
          dispatch(setHasFetchedEpicProfile(false));
          dispatch(setBypassEpicProfile(false));
          resetPreventDirectBackNavigationState();
          mobileNavigate(ACTIONS.PM_SYNC_DO_IT_LATER);
        } else if (userProfile.profileAccessEntryPoint === "MY HEALTH MAP") {
          //upon clicking on the customized back
          dispatch(setHasFetchedEpicProfile(false));
          dispatch(setBypassEpicProfile(false));
          resetPreventDirectBackNavigationState();
          mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH);
        } else {
          //upon clicking on the customized back
          dispatch(setHasFetchedEpicProfile(false));
          dispatch(setBypassEpicProfile(false));
          resetPreventDirectBackNavigationState();
          navigate(PATHS.MY_PROFILE_MOBILE.path); //check the diff between 'mobile' and no 'mobile'
        }
      } else {
        resetPreventDirectBackNavigationState();
        handleUnsavedPopupOpen();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageActionObj?.action, preventDirectBackNavigationState]);

  // help2 is used to wait for rehydration of userState. This will give it time for state to hydrate before setShouldRetrieve, which will trigger process of getting
  const [helper2, setHelper2] = useState(false);
  //this useEffect is for users who accept the Singpass page
  useEffect(() => {
    if (bypassEpicProfile) {
      return;
    }

    if (userProfile.shouldDirectToMyInfo) {
      dispatch(
        setShouldDirectToMyInfo({
          shouldDirectToMyInfo: false,
          cancellationPath: userProfile.cancellationPath ?? undefined,
        }),
      );
      redirectToMyInfo();
    }
    // when user enters the page after accessing MyInfoPage
    else if (location.search) {
      const params = new URLSearchParams(location.search);
      const authCode = params.get("code");
      const redirecturl = params.get("redirecturl");

      // if user accepts consent on the MyInfoPage
      if (authCode && redirecturl) {
        setAuthCode(authCode);
        setRedirecturl(redirecturl);

        if (!helper2) {
          setHelper2(true);
        } else {
          setShouldRetrieveMyInfoData(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, helper2, userProfile.profileAccessEntryPoint]);

  //  state rehydration takes time, cancellation path is set to initial value first before setting to last value being set again
  //  so when user clicks cancel button at MyInfo site and redirection is triggered to load the page again with incomplete query strings param,
  //  userState.cancellationPath is still falsy since it is initial value.
  // significance of helper state is to prevent hitting else block 'navigate(PROFILE_CANCEL_ACTION)' directly, since it is not reversible
  const [helper, setHelper] = useState(false);
  //this useEffect is for users who cancel the Singpass page
  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const authCode = params.get("code");

      // when user clicks cancel button at MyInfo site
      if (!authCode) {
        if (userProfile.cancellationPath) {
          navigate(userProfile.cancellationPath, { replace: true });
        } else if (!helper) {
          setHelper(true);
        } else {
          mobileNavigate(ACTIONS.PM_SYNC_DO_IT_LATER);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, userProfile.cancellationPath]);

  //this useEffect is for retrieving of myInfoData
  useEffect(() => {
    dispatch(
      fetchMyInfoProfile(
        codeVerifier,
        authCode,
        redirecturl,
        shouldRetrieveMyInfoData,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode, redirecturl, shouldRetrieveMyInfoData]);

  //this useEffect is for setting shouldRetrieveMyInfoData to false after retrieving, so it doesn't retrieve again
  useEffect(() => {
    if (shouldRetrieveMyInfoData && myInfoParams.hasErrored !== null) {
      setShouldRetrieveMyInfoData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfoParams.hasErrored]);

  //this useEffect is for retrieving the epic profile only when myInfoProfile is retrieved
  useEffect(() => {
    if (
      myInfoParams.hasErrored === false &&
      myInfoData.Nric !== null &&
      !epicProfileParams.hasFetchedEpicProfile
    ) {
      dispatch(
        fetchEpicProfile({
          myInfoName: myInfoData.Name
            ? myInfoData.Name.toLocaleUpperCase()
            : null,
          myInfoDoB: myInfoData.DateOfBirth
            ? convertISOStringToCustomDateString(myInfoData.DateOfBirth)
            : null,
          myInfoSex: myInfoData.Sex ? myInfoData.Sex.toLocaleUpperCase() : null,
          uuid,
          codeVerifier,
        }),
      );
      dispatch(setMyInfoProfileState(myInfoData));
      dispatch(
        setShouldDirectToMyInfo({
          shouldDirectToMyInfo: false,
          cancellationPath: userProfile.cancellationPath ?? undefined,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    myInfoParams.hasErrored,
    myInfoData,
    epicProfileParams.hasFetchedEpicProfile,
  ]);

  useEffect(() => {
    if (
      expandedAccordionsMemo.includes("block1") &&
      expandedAccordionsMemo.includes("block2")
    ) {
      setIsExpandAll(true);
    } else if (expandedAccordionsMemo.length === 0) {
      setIsExpandAll(false);
    }
  }, [expandedAccordionsMemo]);

  useEffect(() => {
    if (isExpandAll === true) {
      setExpandedAccordions(["block1", "block2"]);
    } else if (isExpandAll === false) {
      setExpandedAccordions([]);
    }
  }, [isExpandAll]);

  //this useEffect is to determine whether user is first time or recurring once Epic Profile is fetched (has an epic profile or not)
  useEffect(() => {
    if (
      !myInfoParams.hasErrored &&
      myInfoData.Nric != null &&
      myInfoData.Nric !== "" &&
      !epicProfileParams.hasErrored &&
      epicProfileParams.hasFetchedEpicProfile &&
      !epicProfileParams.isLoading &&
      !myInfoParams.isLoading
    ) {
      if (!epicProfileParams.hasEpicProfile && !epicProfileData.Nric) {
        //first time user

        dispatch(
          setEpicProfileState({
            ...epicProfileData,
            Name: myInfoData.Name,
            Nric: myInfoData.Nric,
            Sex: myInfoData.Sex,
            DateOfBirth: myInfoData.DateOfBirth,
            Nationality: myInfoData.Nationality,
            NationalityCode: myInfoData.NationalityCode,
            Race: myInfoData.Race,
            RaceCode: myInfoData.RaceCode,
            ResidentialStatus: myInfoData.ResidentialStatus,
            ResidentialStatusCode: myInfoData.ResidentialStatusCode,
            PassType: myInfoData.PassType,
            PassTypeCode: myInfoData.PassTypeCode,
            PassExpiryDate: myInfoData.PassExpiryDate,
            RegisteredPostalCode: myInfoData.RegisteredPostalCode,
            RegisteredStreetName: myInfoData.RegisteredStreetName,
            RegisteredBlockNumber: myInfoData.RegisteredBlockNumber,
            RegisteredUnitNumber: myInfoData.RegisteredUnitNumber,
            PreferredPostalCode: "",
            PreferredStreetName: "",
            PreferredBlockNumber: "",
            PreferredUnitNumber: "",
            MailingAddressExpiryDate: "",
            ContactNumber: "",
            Email: "",
            HomeNumber: "",
            WorkNumber: "",
          }),
        );
      } else {
        if (epicProfileData.PwrFlag === true) {
          setpwrErrorModalPopUp(true);
        } else if (
          epicProfileData.MismatchStatus !== null &&
          epicProfileData.MismatchStatus !== 0
        ) {
          //if any of name, nric, dob, gender mismatch, then enable ErrorModal
          setErrorModalPopUp(true);
        } else if (hasEpicAndMyInfoDiffRequiredDetails()) {
          //nationality code, reg street name, reg block, reg unit, reg postal code
          navigate(PATHS.MYINFO_AND_PROFILE_COMPARE.path); //go to compare page
        } else {
          //race code, passtype code and passexpiry date if passtype exist, residential status code if it exist
          if (
            epicProfileData.MailingAddressExpiryDate &&
            formatDate(formatDateToISO(new Date()), "yyyy-MM-dd") >
              formatDate(
                formatDateToISO(
                  new Date(epicProfileData.MailingAddressExpiryDate),
                ),
                "yyyy-MM-dd",
              )
          ) {
            dispatch(
              setEpicProfileState({
                ...epicProfileData,
                Nric: myInfoData.Nric,
                Race: myInfoData.Race,
                RaceCode: myInfoData.RaceCode,
                ResidentialStatus: myInfoData.ResidentialStatus,
                ResidentialStatusCode: myInfoData.ResidentialStatusCode,
                PassType: myInfoData.PassType,
                PassTypeCode: myInfoData.PassTypeCode,
                PassExpiryDate: myInfoData.PassExpiryDate,
                PreferredPostalCode: "",
                PreferredStreetName: "",
                PreferredBlockNumber: "",
                PreferredUnitNumber: "",
                MailingAddressExpiryDate: "",
              }),
            );
            dispatch(setPreferredDateError(false));
          } else {
            dispatch(
              setEpicProfileState({
                ...epicProfileData,
                Nric: myInfoData.Nric,
                Race: myInfoData.Race,
                RaceCode: myInfoData.RaceCode,
                ResidentialStatus: myInfoData.ResidentialStatus,
                ResidentialStatusCode: myInfoData.ResidentialStatusCode,
                PassType: myInfoData.PassType,
                PassTypeCode: myInfoData.PassTypeCode,
                PassExpiryDate: myInfoData.PassExpiryDate,
              }),
            );
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    myInfoParams.hasErrored,
    myInfoData.Nric,
    userProfile,
    epicProfileParams.hasEpicProfile,
    epicProfileParams.hasFetchedEpicProfile,
    epicProfileParams.hasErrored,
    epicProfileParams.isLoading,
    myInfoParams.isLoading,
  ]);

  //this method is to compare whether the patient has a different Reg Add field and Nationality from Myinfo with Epic
  const hasEpicAndMyInfoDiffRequiredDetails = () => {
    if (
      epicProfileData.NationalityCode !== myInfoData.NationalityCode ||
      epicProfileData.RegisteredPostalCode !==
        myInfoData.RegisteredPostalCode ||
      epicProfileData.RegisteredStreetName !==
        myInfoData.RegisteredStreetName ||
      epicProfileData.RegisteredBlockNumber !==
        myInfoData.RegisteredBlockNumber ||
      epicProfileData.RegisteredUnitNumber !== myInfoData.RegisteredUnitNumber
    ) {
      return true;
    }
    return false;
  };

  //this method is to redirect to my info to get auth and state
  const redirectToMyInfo = async () => {
    try {
      setIsLoadingMyInfoAuthUrl(true);
      dispatch(
        setShouldDirectToMyInfo({
          shouldDirectToMyInfo: false,
          cancellationPath: userProfile.cancellationPath ?? undefined,
        }),
      );
      const response = await getMyInfoAuthUrl(null, null);

      dispatch(setMyInfoCodeVerifier(response.CodeVerifier));
      setHasErroredMyInfoAuthUrl({ errored: false, message: null });
      navigateToExternalInAppWebView(
        response.MyInfoAuthUrl,
        userProfile.cancellationPath,
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        setHasErroredMyInfoAuthUrl({
          errored: true,
          message: error.response?.data.Message,
        });
      } else {
        setHasErroredMyInfoAuthUrl({ errored: true, message: null });
      }
    } finally {
    }
  };

  const updateProfilePayload = (
    hasEditedPMA: boolean,
    PMAStates?: PreferredMailingAddPage,
  ) => {
    var PMpayload: PatientMasterCreateUpdateProfile | null = null;
    var Userpayload: UserProfile | null = null;
    if (hasEditedPMA && PMAStates) {
      PMpayload = {
        Name: epicProfileData.Name ? epicProfileData.Name : null,
        Title: epicProfileData.Title ? epicProfileData.Title : "",
        Sex: epicProfileData.Sex ? epicProfileData.Sex : null,
        DateOfBirth: convertISOStringToCustomDateString(
          epicProfileData.DateOfBirth,
        )
          ? convertISOStringToCustomDateString(epicProfileData.DateOfBirth)
          : null,
        Nationality: epicProfileData.Nationality
          ? epicProfileData.Nationality
          : null,
        NationalityCode: epicProfileData.NationalityCode
          ? epicProfileData.NationalityCode
          : null,
        Race: epicProfileData.Race ? epicProfileData.Race : null,
        RaceCode: epicProfileData.RaceCode ? epicProfileData.RaceCode : null,
        DocumentTypeCode:
          epicProfileData.ResidentialStatusCode === "C" ||
          epicProfileData.ResidentialStatusCode === "P"
            ? epicProfileData.ResidentialStatusCode
              ? epicProfileData.ResidentialStatusCode
              : null
            : epicProfileData.PassTypeCode
              ? epicProfileData.PassTypeCode
              : null,
        DocumentTypeDisplay:
          epicProfileData.ResidentialStatusCode === "C" ||
          epicProfileData.ResidentialStatusCode === "P"
            ? epicProfileData.ResidentialStatus
              ? epicProfileData.ResidentialStatus
              : null
            : epicProfileData.PassType
              ? epicProfileData.PassType
              : null,
        DocumentTypeExpiry: convertISOStringToCustomDateString(
          epicProfileData.PassExpiryDate || null,
        )
          ? convertISOStringToCustomDateString(
              epicProfileData.PassExpiryDate || null,
            )
          : null,
        RegisteredPostalCode: epicProfileData.RegisteredPostalCode
          ? epicProfileData.RegisteredPostalCode
          : "",
        RegisteredStreetName: epicProfileData.RegisteredStreetName
          ? epicProfileData.RegisteredStreetName
          : "",
        RegisteredBlockNumber: epicProfileData.RegisteredBlockNumber
          ? epicProfileData.RegisteredBlockNumber
          : "",
        RegisteredFloorNumber: epicProfileData.RegisteredUnitNumber
          ? epicProfileData.RegisteredUnitNumber?.split("-")[0]
              .trim()
              .replaceAll("#", "")
          : "",
        RegisteredUnitNumber: epicProfileData.RegisteredUnitNumber
          ? epicProfileData.RegisteredUnitNumber.split("-")[1]?.trim()
          : "",
        PreferredPostalCode: PMAStates.PreferredPostalCode,
        PreferredStreetName: PMAStates.PreferredStreetName,
        PreferredBlockNumber: PMAStates.PreferredBlockNumber,
        PreferredFloorNumber: PMAStates.PreferredFloorNumber,
        PreferredUnitNumber: PMAStates.PreferredUnitNumber,
        MailingAddressStartDate: !PMAStates.PreferredPostalCode
          ? null
          : !changeStartDate
            ? epicProfileData.MailingAddressStartDate
              ? convertISOStringToCustomDateString(
                  epicProfileData.MailingAddressStartDate,
                )
              : convertISOStringToCustomDateString(formatDateToISO(new Date()))
            : convertISOStringToCustomDateString(formatDateToISO(new Date())),
        MailingAddressExpiryDate: convertISOStringToCustomDateString(
          PMAStates.MailingAddressExpiryDate,
        )
          ? convertISOStringToCustomDateString(
              PMAStates.MailingAddressExpiryDate,
            )
          : null,
        ContactNumber: epicProfileData.ContactNumber
          ? epicProfileData.ContactNumber
          : "",
        Email: epicProfileData.Email ? epicProfileData.Email : "",
        HomeNumber: epicProfileData.HomeNumber
          ? epicProfileData.HomeNumber
          : "",
        WorkNumber: epicProfileData.WorkNumber
          ? epicProfileData.WorkNumber
          : "",
        SMSNumber: epicProfileData.SMSContactNumber
          ? epicProfileData.SMSContactNumber
          : "",
      };
      Userpayload = {
        Name: epicProfileData.Name,
        Nric: epicProfileData.Nric,
        DateOfBirth: epicProfileData.DateOfBirth,
        ContactNumber: epicProfileData.ContactNumber,
        Email: epicProfileData.Email,
        Photo: userProfile.photo,
        BuildingName: userProfile.buildingName,
        RegisteredAddress: {
          PostalCode: epicProfileData.RegisteredPostalCode,
          Block: epicProfileData.RegisteredBlockNumber,
          Street: epicProfileData.RegisteredStreetName,
          Unit: epicProfileData.RegisteredUnitNumber,
        },
        PreferredAddress: {
          PostalCode: PMAStates.PreferredPostalCode,
          Block: PMAStates.PreferredBlockNumber,
          Street: PMAStates.PreferredStreetName,
          Unit: PMAStates.PreferredCombinedUnitNumber,
        },
        HasSyncedParticulars: true,
        HasSubscribed: true,
        UsePreferredAddress: userProfile.usePreferredAddress,
        MyInfoCodeVerifier: codeVerifier,
      };
    } else {
      PMpayload = {
        Name: epicProfileData.Name ? epicProfileData.Name : null,
        Title: epicProfileData.Title ? epicProfileData.Title : "",
        Sex: epicProfileData.Sex ? epicProfileData.Sex : null,
        DateOfBirth: convertISOStringToCustomDateString(
          epicProfileData.DateOfBirth,
        )
          ? convertISOStringToCustomDateString(epicProfileData.DateOfBirth)
          : null,
        Nationality: epicProfileData.Nationality
          ? epicProfileData.Nationality
          : null,
        NationalityCode: epicProfileData.NationalityCode
          ? epicProfileData.NationalityCode
          : null,
        Race: epicProfileData.Race ? epicProfileData.Race : null,
        RaceCode: epicProfileData.RaceCode ? epicProfileData.RaceCode : null,
        DocumentTypeCode:
          epicProfileData.ResidentialStatusCode === "C" ||
          epicProfileData.ResidentialStatusCode === "P"
            ? epicProfileData.ResidentialStatusCode
              ? epicProfileData.ResidentialStatusCode
              : null
            : epicProfileData.PassTypeCode
              ? epicProfileData.PassTypeCode
              : null,
        DocumentTypeDisplay:
          epicProfileData.ResidentialStatusCode === "C" ||
          epicProfileData.ResidentialStatusCode === "P"
            ? epicProfileData.ResidentialStatus
              ? epicProfileData.ResidentialStatus
              : null
            : epicProfileData.PassType
              ? epicProfileData.PassType
              : null,
        DocumentTypeExpiry: convertISOStringToCustomDateString(
          epicProfileData.PassExpiryDate || null,
        )
          ? convertISOStringToCustomDateString(
              epicProfileData.PassExpiryDate || null,
            )
          : null,
        RegisteredPostalCode: epicProfileData.RegisteredPostalCode
          ? epicProfileData.RegisteredPostalCode
          : "",
        RegisteredStreetName: epicProfileData.RegisteredStreetName
          ? epicProfileData.RegisteredStreetName
          : "",
        RegisteredBlockNumber: epicProfileData.RegisteredBlockNumber
          ? epicProfileData.RegisteredBlockNumber
          : "",
        RegisteredFloorNumber: epicProfileData.RegisteredUnitNumber
          ? epicProfileData.RegisteredUnitNumber?.split("-")[0]
              .trim()
              .replaceAll("#", "")
          : "",
        RegisteredUnitNumber: epicProfileData.RegisteredUnitNumber
          ? epicProfileData.RegisteredUnitNumber.split("-")[1]?.trim()
          : "",
        PreferredPostalCode: epicProfileData.PreferredPostalCode
          ? epicProfileData.PreferredPostalCode
          : "",
        PreferredStreetName: epicProfileData.PreferredStreetName
          ? epicProfileData.PreferredStreetName
          : "",
        PreferredBlockNumber: epicProfileData.PreferredBlockNumber
          ? epicProfileData.PreferredBlockNumber
          : "",
        PreferredFloorNumber: epicProfileData.PreferredUnitNumber
          ? epicProfileData.PreferredUnitNumber?.split("-")[0]
              .trim()
              .replaceAll("#", "")
          : "",
        PreferredUnitNumber: epicProfileData.PreferredUnitNumber
          ? epicProfileData.PreferredUnitNumber?.split("-")[1]?.trim()
          : "",
        MailingAddressStartDate: !epicProfileData.PreferredPostalCode
          ? null
          : !changeStartDate
            ? epicProfileData.MailingAddressStartDate
              ? convertISOStringToCustomDateString(
                  epicProfileData.MailingAddressStartDate,
                )
              : convertISOStringToCustomDateString(formatDateToISO(new Date()))
            : convertISOStringToCustomDateString(formatDateToISO(new Date())),
        MailingAddressExpiryDate: convertISOStringToCustomDateString(
          epicProfileData.MailingAddressExpiryDate,
        )
          ? convertISOStringToCustomDateString(
              epicProfileData.MailingAddressExpiryDate,
            )
          : null,
        ContactNumber: epicProfileData.ContactNumber
          ? epicProfileData.ContactNumber
          : "",
        Email: epicProfileData.Email ? epicProfileData.Email : "",
        HomeNumber: epicProfileData.HomeNumber
          ? epicProfileData.HomeNumber
          : "",
        WorkNumber: epicProfileData.WorkNumber
          ? epicProfileData.WorkNumber
          : "",
        SMSNumber: epicProfileData.SMSContactNumber
          ? epicProfileData.SMSContactNumber
          : "",
      };
      Userpayload = {
        Name: epicProfileData.Name,
        Nric: epicProfileData.Nric,
        DateOfBirth: epicProfileData.DateOfBirth,
        ContactNumber: epicProfileData.ContactNumber,
        Email: epicProfileData.Email,
        Photo: userProfile.photo,
        BuildingName: userProfile.buildingName,
        RegisteredAddress: {
          PostalCode: epicProfileData.RegisteredPostalCode,
          Block: epicProfileData.RegisteredBlockNumber,
          Street: epicProfileData.RegisteredStreetName,
          Unit: epicProfileData.RegisteredUnitNumber,
        },
        PreferredAddress: {
          PostalCode: epicProfileData.PreferredPostalCode,
          Block: epicProfileData.PreferredBlockNumber,
          Street: epicProfileData.PreferredStreetName,
          Unit: epicProfileData.PreferredUnitNumber,
        },
        HasSyncedParticulars: true,
        HasSubscribed: true,
        UsePreferredAddress: userProfile.usePreferredAddress,
        MyInfoCodeVerifier: codeVerifier,
      };
    }
    return { PMpayload, Userpayload };
  };

  //this method is to update profile on both EPIC and our Consumer profile data
  const updateProfile = async () => {
    logEventToGoogleAnalytics(
      EVENTS.SELECT_PM_VERIFY_PARTICULARS_CONFIRM_AND_SAVE,
    );

    if (epicProfileData.MailingAddressExpiryDate) {
      logEventToGoogleAnalytics(
        EVENTS.SELECT_PM_VERIFY_PARTICULARS_MAILING_ADDRESS_EXPIRY,
      );
    }

    if (epicProfileData.Email) {
      logEventToGoogleAnalytics(
        EVENTS.SELECT_PM_VERIFY_PARTICULARS_EMAIL_ADDRESS,
      );
    }

    if (epicProfileData.ContactNumber) {
      logEventToGoogleAnalytics(
        EVENTS.SELECT_PM_VERIFY_PARTICULARS_PHONE_NUMBER,
      );
    }

    setUpdateIsLoading(true);
    const payloads = updateProfilePayload(hasEditedPMA, PMAStates);
    try {
      if (epicProfileParams.hasEpicProfile) {
        logEventToGoogleAnalytics(EVENTS.CLICK_UPDATE_PM_CONFIRM_AND_SAVE);
        await updatePatientMasterProfile(payloads.PMpayload, true);
      } else {
        logEventToGoogleAnalytics(EVENTS.CLICK_CREATE_PM_CONFIRM_AND_SAVE);
        await createPatientMasterProfile(payloads.PMpayload);
      }

      try {
        await updateUserProfile(payloads.Userpayload);
      } catch (error) {}

      mobileNavigate(ACTIONS.PROFILE_UPDATE_ACTION);
      // !exp setTimeout is needed when we are sending multiple actions to mobile for interception
      // !exp opening the confirmation modal sends an action by default to disable back button navigation
      setTimeout(() => setOpenUpdateSuccessfulDialog(true), 1000);
    } catch (error) {
      dispatch(
        handleErrorModal({
          open: true,
          message:
            "We are unable to update your particulars. You may wish to try again later.",
        }),
      );
    } finally {
      setUpdateIsLoading(false);
    }
  };

  //this is to disable 'CONFIRM AND SAVE' button should any field encounter error
  const disableNext = useMemo(() => {
    if (
      checkErrors.hasEmailErrored ||
      checkErrors.hasHomeNumberErrored ||
      checkErrors.hasMobileErrored ||
      checkErrors.hasSMSMobileErrored ||
      checkErrors.hasWorkNumberErrored ||
      checkErrors.hasPostalCodeErrored ||
      checkErrors.hasBlockErrored ||
      checkErrors.hasStreetErrored ||
      checkErrors.hasDateErrored ||
      checkErrors.hasNRICErrored
    )
      return true;
    return false;
  }, [checkErrors]);

  //the methods below are for the change to update redux store for contact details
  const handleContactChange = (contact: string | null) => {
    dispatch(
      setEpicProfileState({
        ...epicProfileData,
        ContactNumber: contact,
      }),
    );
  };

  //the methods below are for the change to update redux store for contact details
  const handleSMSContactChange = (smsContact: string | null) => {
    dispatch(
      setEpicProfileState({
        ...epicProfileData,
        SMSContactNumber: smsContact,
      }),
    );
  };

  const handleEmailChange = (email: string | null) => {
    dispatch(
      setEpicProfileState({
        ...epicProfileData,
        Email: email,
      }),
    );
  };

  const handleHomeNumberChange = (homeNumber: string | null) => {
    dispatch(
      setEpicProfileState({
        ...epicProfileData,
        HomeNumber: homeNumber,
      }),
    );
  };

  const handleWorkNumberChange = (workNumber: string | null) => {
    dispatch(
      setEpicProfileState({
        ...epicProfileData,
        WorkNumber: workNumber,
      }),
    );
  };

  //this method is to render ErrorModal body text according if there is any crucial detail mismatched
  const errorModalBody = () => {
    //Name, DoB, Sex
    if (
      epicProfileData.MismatchStatus !== null &&
      epicProfileData.MismatchStatus !== 0
    ) {
      if (epicProfileData.MismatchStatus === 1) {
        logEventToGoogleAnalytics(EVENTS.ERROR_PM_MISMATCH_DOB);
        return errorMessages.syncParticularsFailed.description?.replace(
          "{{parameters}}",
          "Date of Birth.",
        );
      } else if (epicProfileData.MismatchStatus === 2) {
        logEventToGoogleAnalytics(EVENTS.ERROR_PM_MISMATCH_GENDER);
        return errorMessages.syncParticularsFailed.description?.replace(
          "{{parameters}}",
          "Gender.",
        );
      } else if (epicProfileData.MismatchStatus === 3) {
        logEventToGoogleAnalytics(EVENTS.ERROR_PM_MISMATCH_DOB_GENDER);
        return errorMessages.syncParticularsFailed.description?.replace(
          "{{parameters}}",
          "Date of Birth and Gender.",
        );
      } else if (epicProfileData.MismatchStatus === 4) {
        logEventToGoogleAnalytics(EVENTS.ERROR_PM_MISMATCH_NAME);
        return errorMessages.syncParticularsFailed.description?.replace(
          "{{parameters}}",
          "Name.",
        );
      } else if (epicProfileData.MismatchStatus === 5) {
        logEventToGoogleAnalytics(EVENTS.ERROR_PM_MISMATCH_NAME_DOB);
        return errorMessages.syncParticularsFailed.description?.replace(
          "{{parameters}}",
          "Name and Date of Birth.",
        );
      } else if (epicProfileData.MismatchStatus === 6) {
        logEventToGoogleAnalytics(EVENTS.ERROR_PM_MISMATCH_NAME_GENDER);
        return errorMessages.syncParticularsFailed.description?.replace(
          "{{parameters}}",
          "Name and Gender.",
        );
      } else if (epicProfileData.MismatchStatus === 7) {
        logEventToGoogleAnalytics(EVENTS.ERROR_PM_MISMATCH_NAME_DOB_GENDER);
        return errorMessages.syncParticularsFailed.description?.replace(
          "{{parameters}}",
          "Name, Date of Birth and Gender.",
        );
      } else {
        return errorMessages.syncParticularsFailed.description?.replace(
          "{{parameters}}",
          "",
        );
      }
    }
  };

  return (
    <>
      {userProfile.isLoading ||
      updateIsLoading ||
      isLoadingMyInfoAuthUrl ||
      myInfoParams.isLoading ||
      epicProfileParams.isLoading ||
      pmSettingsIsLoading ||
      userProfile.shouldDirectToMyInfo ? (
        <Box display="flex" align-items="center" justifyContent="center" pt={4}>
          <CircularProgress />
        </Box>
      ) : pmSettingsHasErrored ? (
        <ErrorDisplay
          errorMessage={pmSettingsErrorMessage}
          onTryAgain={() => dispatch(fetchSystemSettings())}
        />
      ) : userProfile.hasErrored ? (
        <ErrorDisplay
          errorMessage={userProfile.errorMessage}
          onTryAgain={() =>
            dispatch(
              fetchUserParticulars({ mapAddress: false, retrievePhoto: true }),
            )
          }
        />
      ) : hasErroredMyInfoAuthUrl.errored ? (
        <ErrorDisplay
          errorMessage={hasErroredMyInfoAuthUrl.message}
          onTryAgain={redirectToMyInfo}
        />
      ) : myInfoParams.hasErrored ? (
        <ErrorDisplay
          errorMessage={myInfoParams.errorMessage}
          onTryAgain={redirectToMyInfo}
        />
      ) : epicProfileParams.hasErrored ? (
        <ErrorDisplay
          errorMessage={epicProfileParams.errorMessage}
          onTryAgain={
            epicProfileParams.errorMessage?.includes(PM_ERROR_422)
              ? undefined
              : () => {
                  dispatch(setHasFetchedEpicProfile(false));
                  dispatch(
                    fetchEpicProfile({
                      myInfoName: myInfoData.Name
                        ? myInfoData.Name.toLocaleUpperCase()
                        : null,
                      myInfoDoB: myInfoData.DateOfBirth
                        ? convertISOStringToCustomDateString(
                            myInfoData.DateOfBirth,
                          )
                        : null,
                      myInfoSex: myInfoData.Sex
                        ? myInfoData.Sex.toLocaleUpperCase()
                        : null,
                      uuid,
                      codeVerifier,
                    }),
                  );
                }
          }
        />
      ) : (
        !myInfoParams.hasErrored &&
        !userProfile.hasErrored &&
        !epicProfileParams.hasErrored && (
          <Box sx={classes.mainContainer}>
            <Box sx={classes.contenContainer}>
              <ProfileImageUploader
                photo={profilePhoto}
                onUpload={(fileData) => {
                  setProfilePhoto(fileData);
                }}
              />

              <Box sx={classes.expandAllButtonContainer}>
                <Button
                  variant="text"
                  sx={classes.expandAllButton}
                  onClick={handleOnClickExpandAll}
                >
                  {isExpandAll ? "Collapse all" : "Expand all"}
                </Button>
              </Box>

              <AccordionBlock
                expanded={expandedAccordionsMemo.includes("block1")}
                onExpand={() => {
                  if (expandedAccordionsMemo.includes("block1")) {
                    setExpandedAccordions(
                      expandedAccordionsMemo.filter(
                        (block) => block !== "block1",
                      ),
                    );
                  } else {
                    setExpandedAccordions([
                      ...expandedAccordionsMemo,
                      "block1",
                    ]);
                  }
                }}
                summaryNode={
                  <Box sx={classes.summaryContainer}>
                    <Typography sx={classes.summaryTitle}>
                      {accordionSection1Title}
                    </Typography>
                    {userProfile.lastRetrievedMyInfoProfileOn?.trim() && (
                      <Typography sx={classes.summaryValue}>
                        {`Last synced on ${formatDate(
                          userProfile.lastRetrievedMyInfoProfileOn,
                          formatOptions.myInfoRetrieved,
                        )}.`}
                      </Typography>
                    )}
                  </Box>
                }
                detailsNode={<PersonalDetails />}
              />

              <AccordionBlock
                expanded={expandedAccordionsMemo.includes("block2")}
                onExpand={() => {
                  if (expandedAccordionsMemo.includes("block2")) {
                    setExpandedAccordions(
                      expandedAccordionsMemo.filter(
                        (block) => block !== "block2",
                      ),
                    );
                  } else {
                    setExpandedAccordions([
                      ...expandedAccordionsMemo,
                      "block2",
                    ]);
                  }
                }}
                summaryNode={
                  <Box sx={classes.summaryContainer}>
                    <Typography sx={classes.summaryTitle}>
                      {accordionSection2Title}
                    </Typography>
                    <Typography sx={classes.summaryValue}>
                      Update email address, phone numbers and preferred local
                      mailing address.
                    </Typography>
                  </Box>
                }
                detailsNode={
                  <>
                    <ContactDetails
                      contactDisclaimer={
                        epicProfileParams.hasEpicProfile ? (
                          <>
                            Tap 'Add' if you prefer to receive{" "}
                            <Typography
                              component={"span"}
                              sx={{
                                fontWeight: theme.typography.fontWeightBold,
                              }}
                            >
                              {" "}
                              Appointment SMS reminders{" "}
                            </Typography>{" "}
                            through a different number.
                          </>
                        ) : null
                      }
                      contact={epicProfileData.ContactNumber ?? ""}
                      smsContact={epicProfileData.SMSContactNumber}
                      email={epicProfileData.Email ?? ""}
                      homeNumber={epicProfileData.HomeNumber ?? ""}
                      workNumber={epicProfileData.WorkNumber ?? ""}
                      hasEpicProfile={epicProfileParams.hasEpicProfile}
                      handleContactChange={handleContactChange}
                      handleSMSContactChange={handleSMSContactChange}
                      handleEmailChange={handleEmailChange}
                      handleHomeNumberChange={handleHomeNumberChange}
                      handleWorkNumberChange={handleWorkNumberChange}
                      hasEditedPMA={hasEditedPMA}
                      PMAStates={PMAStates}
                    />
                  </>
                }
              />
            </Box>

            <Box sx={classes.buttonsFooterContainer}>
              <ButtonsFooter
                nextButtonText={"Confirm and save"}
                cancelButtonText={"Cancel"}
                isDisabled={disableNext}
                onClickNext={updateProfile}
                onClickCancel={handleUnsavedPopupOpen}
              />
            </Box>

            {/* Successful Update */}
            <ExitProfileDialog
              open={openUpdateSuccessfulDialog}
              profileAccessEntryPoint={userProfile.profileAccessEntryPoint}
            />

            {/* Error in Name, Date of Birth or Gender */}
            <ConfirmationModal
              title={errorMessages.syncParticularsFailed.title}
              hideCancelButton={true}
              open={errorModalPopUp}
              nextButtonText={"OK"}
              body={MapRawStringToReactElement(errorModalBody() ?? "")}
              onClickNext={() => {
                logEventToGoogleAnalytics(
                  EVENTS.SELECT_PM_VERIFY_PARTICULARS_OK_UNABLE_UPDATE,
                );
                setErrorModalPopUp(false);
                dispatch(setHasFetchedEpicProfile(false));
                if (userProfile.profileAccessEntryPoint === "MY HEALTH MAP") {
                  mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH);
                } else {
                  if (
                    userProfile.cancellationPath === PATHS.PM_ENTRY.path ||
                    userProfile.cancellationPath === PATHS.PM_ENTRY_MOBILE.path
                  ) {
                    mobileNavigate(ACTIONS.PM_SYNC_DO_IT_LATER);
                  } else {
                    navigate(PATHS.MY_PROFILE_MOBILE_ERROR.path); //check the diff between 'mobile' and no 'mobile'
                  }
                }
              }}
              onClose={() => {
                setErrorModalPopUp(false);
              }}
            />

            {/* PWRFlag is true */}
            <ConfirmationModal
              title={"Oops..."}
              hideCancelButton={true}
              open={pwrErrorModalPopUp}
              nextButtonText={"OK"}
              body={
                "There seems to be an issue syncing your information from Myinfo. To update your details, please approach our patient service associate with your identification documents during your next visit."
              }
              onClickNext={() => {
                logEventToGoogleAnalytics(
                  EVENTS.SELECT_PM_VERIFY_PARTICULARS_OK_UNABLE_UPDATE,
                );
                setpwrErrorModalPopUp(false);
                dispatch(setHasFetchedEpicProfile(false));
                mobileNavigate(ACTIONS.PM_SYNC_DO_IT_LATER);
              }}
              onClose={() => {
                setpwrErrorModalPopUp(false);
              }}
            />

            {/* Unsaved changes popup */}
            <ConfirmationModal
              title={"Unsaved Changes"}
              open={isUnsavedOpen}
              onClose={() => {}}
              body={MapRawStringToReactElement(
                "Are you sure you want to leave without saving the changes?",
              )}
              hideCancelButton={false}
              nextButtonText={"Back to My Particulars"}
              onClickNext={handleUnsavedPopupClose}
              cancelButtonText={"Do it later"}
              onClickCancel={handleUnsavedCancelClick}
            />
          </Box>
        )
      )}
    </>
  );
};

export default MyInfoProfile;
