import { Theme } from "@mui/material";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = (theme: Theme) => {
  return {
    noticeContainer: {
      pt: 1,
      pl: 2,
      pr: 2,
    },
  } satisfies SxObjType;
};
export { sxStyles };
