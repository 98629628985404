import React from "react";
import { sxStyles } from "./GroupedDropdown.styles";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  // OutlinedInput,
  MenuItem,
  ListSubheader,
} from "@mui/material";
import { GroupedDropdownProps } from "./GroupedDropdown.types";
import CustomSvgIcon from "../Dropdown/DropdownCustomSvg";

const GroupedDropdown = ({
  required = true,
  name,
  value,
  disabled = false,
  listOfItems,
  categories,
  label,
  handleChange,
}: GroupedDropdownProps) => {
  const classes = sxStyles();

  return (
    <>
      <FormControl variant="outlined" fullWidth sx={classes.menulist}>
        <InputLabel disabled={disabled} id="demo-simple-select-outlined-label">
          {label}
        </InputLabel>
        <Select
          label={label} //this not showing up
          fullWidth
          variant="outlined"
          value={value || ""}
          notched={false}
          disabled={disabled}
          onChange={handleChange as any}
          name={name}
          sx={classes.menuItemContainer}
          IconComponent={CustomSvgIcon}
        >
          {categories.map((category, i) => {
            const list = listOfItems[i];
            let items = list.map((e, j) => {
              return (
                <MenuItem key={j} value={e.id} sx={classes.menuIem}>
                  {e.text}
                </MenuItem>
              );
            });

            return [
              <ListSubheader key={i} sx={classes.subItem}>
                {category}
              </ListSubheader>,
              items,
            ];
          })}
        </Select>
      </FormControl>

      {required && (
        <Box m={0} sx={classes.errText}>
          *Required
        </Box>
      )}
    </>
  );
};

export default GroupedDropdown;
