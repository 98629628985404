import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_semibold,
    },
    fileNameLabel: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      "& .MuiTypography-colorTextSecondary": {
        color: theme.palette.grey[400],
      },
    },
    fileName: {
      color: theme.palette.primary.main,
      typography: theme.typography.body2_bold,
      "& .MuiTypography-colorTextSecondary": {
        color: theme.palette.grey[400],
      },
    },
    attachmentJpegFrame: {
      display: "flex",
      flexDirection: "column",
      margin: theme.spacing(0.5),
    },
    jpeg: {
      width: "100%",
      borderRadius: theme.spacing(0.5),
      height: "150px",
      objectFit: "contain",
      border: `1px solid ${theme.palette.grey[100]}`,
    },
  } satisfies SxObjType;
};

export { sxStyles };
