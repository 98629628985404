import { connect } from "react-redux";
import { RootDispatch, RootState } from "lib/redux/root/redux.types";
import MedicalReportReceipt from "./MedicalReportReceipt";
import {
  fetchPaymentStatusInfo,
  setMedicalReportReceiptPageLanding,
} from "ui/mrrp/ducks";
import { ApiEnum } from "ui/mrrp/ducks/mrrp.types";
import { resetApiStatus } from "ui/mrrp/ducks";

const mapStateToProps = (state: RootState) => {
  return {
    receiptPageLanding: state.mrrp.receiptPageLanding,
    getReportStatusInfoApiStatus:
      state.mrrp.apiStatus[ApiEnum.GetPaymentStatusInfo],
    paymentStatusInfo: state.mrrp.payment.paymentStatusInfo,
    mrrpPersist: state.mrrp.mrrpPersist,
  };
};

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    onSetReceiptPageLanding: (arg: boolean) => {
      dispatch(setMedicalReportReceiptPageLanding(arg));
    },
    onGetPaymentStatusInfo: (
      paymentToken: string | null,
      reportNo: string | null,
      reportTypeName: string | null,
      memberIdentifier: string | null,
      emailAddress: string | null,
    ) => {
      dispatch(
        fetchPaymentStatusInfo(
          paymentToken,
          reportNo,
          reportTypeName,
          memberIdentifier,
          emailAddress,
          "N",
        ),
      );
    },
    onResetPaymentApiStatus: () => {
      dispatch(resetApiStatus());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MedicalReportReceipt);
