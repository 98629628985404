import { Box, Typography, useTheme } from "@mui/material";
import { sxStyles } from "./BannerSection.styles";
import IMAGES from "lib/assets/images";

const BANNER_BODY = `Health Together is a community initiative by the NUHS and its partners to support health and wellness needs of residents in the western region of Singapore.`;
const BANNER_BODY2 = `Residents can receive health and wellness tips, self-help tools, invitations to community events and health screenings based on their needs, as well as a range of resources such as useful directories to available community programmes around their neighbourhood.`;
const BannerSection = () => {
  const theme = useTheme();
  const classes = sxStyles(theme);

  return (
    <Box>
      <Box
        component="img"
        sx={classes.image}
        src={IMAGES.healthTogetherMHM.HTLandingBannerImage}
        alt="Health Together page banner"
      />

      <Typography sx={classes.bannerTextBody}>
        {BANNER_BODY}
        <br />
        <br />
        {BANNER_BODY2}
      </Typography>
    </Box>
  );
};

export default BannerSection;
