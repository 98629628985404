import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { RESCHEDULE_LINKED_APPOINTMENT_URL } from "../../resources/configs/URL";
import { formatRescheduleLinkedAppointmentErrorMessage } from "../../resources/messages/ErrorMessage";
import { RescheduleLinkedAppointmentPayload } from "./rescheduleLinkedAppointment.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";

/**
 * Reschedules a linked appointment
 *
 * POST
 * Endpoint: /RescheduleLinkedAppointment
 *
 * @param {string | null} memberIdentifier  Member Identifier of the person we are making this request for
 * @param {string | null} targetSystem  Source system from which these slots are from
 * @param {string | null} institutionCode  Code of the institution of the appointment to reschedule
 * @param {AppointmentDataObjectRequest[]} appointmentDataObjectRequests  List of appointment data objects that represent
 * @param {string | null} departmentName Department Name of the appointment
 *   each linked appointment slot to be rescheduled
 *
 * @returns {RescheduleLinkedAppointmentPayload} Response payload from the remote API
 */
const rescheduleLinkedAppointment = async (
  memberIdentifier: string | null,
  institutionCode: string | null,
  slotIdList: (string | null)[],
): Promise<RescheduleLinkedAppointmentPayload> => {
  try {
    const response = await axios.post<RescheduleLinkedAppointmentPayload>(
      RESCHEDULE_LINKED_APPOINTMENT_URL(),
      createRequestPayload(memberIdentifier, institutionCode, slotIdList),
      getRequestOptions(),
    );
    return parseResponse(response, RESCHEDULE_LINKED_APPOINTMENT_URL());
  } catch (error) {
    const msg = formatRescheduleLinkedAppointmentErrorMessage(
      RESCHEDULE_LINKED_APPOINTMENT_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  memberIdentifier: string | null,
  institutionCode: string | null,
  slotIdList: (string | null)[],
) => {
  return {
    MemberIdentifier: memberIdentifier,
    InstitutionCode: institutionCode,
    SlotIdList: slotIdList,
  };
};

export { rescheduleLinkedAppointment };
