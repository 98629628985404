import { Typography, Box } from "@mui/material";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { sxStyles } from "./DeliveryDetailSummarySection.styles";
import { DeliveryDetailSummarySectionProps } from "./DeliveryDetailSummarySection.types";
import {
  formatDate,
  formatOptions,
} from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

const DeliveryDetailSummarySection = ({
  postalCode,
  blockNo,
  unitNo,
  address,
  showSpecialRequest,
  specialRequest = null,
  date = null,
  timeslot = null,
  deliveryMode = null,
}: DeliveryDetailSummarySectionProps) => {
  const classes = sxStyles();
  const dateObject = date ? getDateFromISOString(date) : null;

  return (
    <>
      <Typography sx={classes.title}>Deliver to</Typography>

      <Box>
        <Box display="flex">
          <Box width="50%">
            <Typography sx={classes.homeDeliveryLabel}>Postal Code</Typography>
            <Typography sx={classes.homeDeliveryDetail}>
              {postalCode}
            </Typography>
          </Box>
          <Box width="25%">
            <Typography sx={classes.homeDeliveryLabel}>Block</Typography>
            <Typography sx={classes.homeDeliveryDetail}>{blockNo}</Typography>
          </Box>
          <Box width="25%">
            <Typography sx={classes.homeDeliveryLabel}>Unit</Typography>
            <Typography sx={classes.homeDeliveryDetail}>{unitNo}</Typography>
          </Box>
        </Box>
        <Typography sx={classes.homeDeliveryLabel}>Street</Typography>
        <Typography sx={classes.homeDeliveryDetail}>{address}</Typography>
      </Box>

      {date && (
        <Box pb={2}>
          <Typography sx={classes.contentLabel}>
            Preferred delivery date
          </Typography>
          <Typography sx={classes.contentDetail}>
            {dateObject
              ? formatDate(
                  formatDateToISO(dateObject),
                  formatOptions.dayMonthYear,
                )
              : null}
          </Typography>
        </Box>
      )}

      {timeslot && (
        <Box pb={2}>
          <Typography sx={classes.contentLabel}>
            Preferred delivery time
          </Typography>
          <Typography sx={classes.contentDetail}>{timeslot}</Typography>
        </Box>
      )}

      {showSpecialRequest && (
        <>
          <Typography sx={classes.contentLabel}>
            Special Request (if any)
          </Typography>
          <Typography sx={classes.contentDetail}>
            {specialRequest ?? "-"}
          </Typography>
        </>
      )}

      {deliveryMode && (
        <>
          <Typography sx={classes.contentLabel}>Mode of Delivery</Typography>
          <Typography sx={classes.contentDetail}>{deliveryMode}</Typography>
        </>
      )}
    </>
  );
};

export default DeliveryDetailSummarySection;
