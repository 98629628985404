import axios from "axios";
import { RETRIEVE_MY_INFO_PROFILE_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { RetrieveMyInfoProfileResponsePayload } from "./retrieveMyInfoProfile.fromApi.types";
import { formatRetrieveMyInfoProfileErrorMessage } from "api/resources/messages/ErrorMessage";

/**
 * Retrieve MyInfo data
 *
 * POST
 * Endpoint: secure/myinfo/RetrieveMyInfoProfile
 *
 * @param {string | null} CodeVerifier encrypted token passing from call back url of MyInfo user consent page
 * @param {string | null} authCode  encrypted token passing from call back url of MyInfo user consent page
 */
const retrieveMyInfoProfile = async (
  codeVerifier: string | null,
  authCode: string | null,
  redirectUrl: string | null,
): Promise<RetrieveMyInfoProfileResponsePayload> => {
  try {
    const response = await axios.post<RetrieveMyInfoProfileResponsePayload>(
      RETRIEVE_MY_INFO_PROFILE_URL(),
      createRequestPayload(codeVerifier, authCode, redirectUrl),
      getRequestOptions(),
    );
    return parseResponse(response, RETRIEVE_MY_INFO_PROFILE_URL());
  } catch (error) {
    const msg = formatRetrieveMyInfoProfileErrorMessage(
      RETRIEVE_MY_INFO_PROFILE_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  codeVerifier: string | null,
  authCode: string | null,
  redirectUrl: string | null,
) => {
  return {
    CodeVerifier: codeVerifier,
    AuthCode: authCode,
    RedirectUrl: redirectUrl,
  };
};

export { retrieveMyInfoProfile };
