import { Box } from "@mui/material";
import { SelectionInstructionsProps } from "./SelectionInstructions.types";
import { sxStyles } from "./SelectionInstructions.styles";

const SelectionInstructions = ({
  text,
  children,
}: SelectionInstructionsProps) => {
  const classes = sxStyles();
  return (
    <Box>
      <Box sx={classes.layout}>{text}</Box>

      {children ? <Box sx={classes.layout}>{children}</Box> : null}
    </Box>
  );
};

export default SelectionInstructions;
