import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_semibold,
      paddingBottom: theme.spacing(1.5),
    },
    contentDetail: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    lists: {
      listStyle: "disc inside",
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      marginLeft: theme.spacing(-1),
      "& li::marker": {
        fontSize: size.FONTSIZE_10,
      },
    },
    listsitem: {
      display: "list-item",
      marginTop: theme.spacing(-2),
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
  } satisfies SxObjType;
};

export { sxStyles };
