import { getLiveChatFlag } from "api/data/GetLiveChatFlag/getLiveChatFlag";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";

interface useGetLiveChatType {
  isLiveChatEnabled: boolean;
  isLiveChatLoading: boolean;
  hasLiveChatErrored: boolean;
  liveChatErrorMessage: string | null;
}

const useGetLiveChatFlag = (errorCode: string = ""): useGetLiveChatType => {
  const [isLiveChatEnabled, setIsLiveChatEnabled] = useState(false);
  const [isLiveChatLoading, setIsLiveChatLoading] = useState(false);
  const [hasLiveChatErrored, setHasLiveChatErrored] = useState(false);
  const [liveChatErrorMessage, setLiveChatErrorMessage] = useState<
    string | null
  >(null);

  useEffect(() => {
    (async () => {
      setIsLiveChatLoading(true);

      try {
        const responseData = await getLiveChatFlag(errorCode);
        setIsLiveChatEnabled(responseData.IsLiveChatEnabled);
        setHasLiveChatErrored(false);
        setLiveChatErrorMessage(null);
      } catch (error) {
        setIsLiveChatEnabled(false);
        setHasLiveChatErrored(true);
        if (error instanceof AxiosError) {
          setLiveChatErrorMessage(error.response?.data.Message);
        } else {
          setLiveChatErrorMessage(null);
        }
      } finally {
        setIsLiveChatLoading(false);
      }
    })();
  }, [errorCode]);

  return {
    isLiveChatEnabled,
    isLiveChatLoading,
    hasLiveChatErrored,
    liveChatErrorMessage,
  };
};

export { useGetLiveChatFlag };
