import React from "react";
import { Typography, Box, ListItem, List } from "@mui/material";
import { sxStyles } from "./PaymentSummarySection.styles";
import { PaymentSummarySectionProps } from "./PaymentSummarySection.types";

const PaymentSummarySection = ({
  subsidyDescription,
  paymentInstructions,
}: PaymentSummarySectionProps) => {
  const classes = sxStyles();

  return (
    <>
      <Typography sx={classes.title}>Selected payment mode</Typography>

      <Box pl={-3} mb={-1.25}>
        <List sx={classes.lists}>
          {subsidyDescription?.map((description) => {
            return <ListItem sx={classes.listsitem}>{description}</ListItem>;
          })}
        </List>
      </Box>

      {paymentInstructions && paymentInstructions !== "" && (
        <Box pb={0.5} mt={1}>
          <Typography sx={classes.contentDetail}>
            {paymentInstructions}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default PaymentSummarySection;
