import React from "react";
import { RadioGroup, Typography, Box } from "@mui/material";
import { sxStyles } from "../../AppointmentForm.styles";
import { ReferredBy } from "api/appointment/CreateAppointment/FormData/createAppointmentRequestFormData";
import { CreateApptFormFieldProps } from "./CreateApptFormField.types";
import nameof from "ts-nameof.macro";
import Dropdown from "lib/components/formInputs/Dropdown/Dropdown";
import MultiLineTextField from "lib/components/formInputs/MultiLineTextField/MultiLineTextField";
import RadioButton from "lib/components/formInputs/FullRadioButton/FullRadioButton";

const DUMMY_REFERREDBY = ReferredBy;

const CreateApptFormField = ({
  specialtyList,
  name,
  createApptFormField,
  handleChange,
}: CreateApptFormFieldProps) => {
  const classes = sxStyles();

  return (
    <>
      <Box sx={classes.layout}>
        <Typography variant="subtitle1" sx={classes.intensionText}>
          I want to <strong>book</strong> an appointment at:
        </Typography>
        <Typography variant="subtitle1" sx={classes.intensionLocationText}>
          {name}
        </Typography>
      </Box>

      {specialtyList.length > 0 && (
        <Box sx={classes.textBox}>
          <Dropdown
            name={nameof(createApptFormField.specialty)}
            label="Specialty"
            value={createApptFormField.specialty}
            items={specialtyList}
            handleChange={handleChange}
          />
        </Box>
      )}

      {/* <FormControl component="fieldset" fullWidth> */}
      <Typography sx={classes.formLabel}>I was referred by </Typography>
      <RadioGroup
        name={nameof(createApptFormField.referredBy)}
        value={createApptFormField.referredBy}
        onChange={handleChange}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "200px",
              mt: 1.5,
              ml: 3,
            }}
          >
            {DUMMY_REFERREDBY.map((choice) => (
              <Box
                key={choice.id}
                display="flex"
                flexDirection="column"
                maxWidth="200px"
                mb={1}
              >
                <RadioButton
                  checked={createApptFormField.referredBy === choice.value}
                  label={choice.label}
                  value={choice.value}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </RadioGroup>
      <Box sx={classes.errText}>*Required</Box>
      {/* </FormControl> */}

      {/* <FormControl component="fieldset" fullWidth> */}
      <Typography sx={classes.multiLineTextFieldLabel}>
        Please provide a brief description of your condition or symptoms.
      </Typography>
      <MultiLineTextField
        name={nameof(createApptFormField.description)}
        placeholder="Description"
        value={createApptFormField.description}
        rowNumber={3}
        maxNumber={5}
        maxCharLength={200}
        handleChange={handleChange}
      />
      {/* </FormControl> */}
    </>
  );
};

export default CreateApptFormField;
