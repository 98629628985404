import { ApiEnum, MrrpState } from "./mrrp.types";

// Initial state representing the time when mrrp is not initialized yet
export const initialState: MrrpState = {
  landing: {
    expandedPastReportListFlag: false,
  },
  hospital: {
    isLoading: true,
    hasErrored: null,
    errorMessage: null,
    selectedHospitalCode: null,
    selectedHospitalName: null,
    allHospitals: [],
  },
  particulars: {
    patientName: null,
    patientNric: null,
    requestorName: null,
    relationshipCodeWithPatient: null,
    relationshipWithPatient: null,
    contactNumber: null,
    alternativeNumber: null,
    email: null,
  },
  reportDetail: {
    visitDate: null,
    clinicalDepartmentCode: null,
    clinicalDepartment: null,
    purposeCodeOfApplication: null,
    purposeOfApplication: null,
    reportTypeCode: null,
    reportType: null,
    reportDescription: null,
    reportFees: null,
    reportFileName: null,
    reportFileByte: null,
    reportFileMIMEType: null,
  },
  collection: {
    deliveryModeCode: null,
    deliveryMode: null,
    postalCode: null,
    blockNo: null,
    floorAndUnit: null,
    address: null,
    errorPostalCode: false,
  },
  formLabel: {
    apiStatus: {
      isLoading: null,
      hasErrored: null,
      errorMessage: null,
    },
    labels: [],
  },
  paymentMode: null,

  reportHistory: {
    isLoading: true,
    hasErrored: null,
    errorMessage: null,
    selectedPastReportIndex: null,
    allPastReports: [],
  },

  reportOptions: {
    isLoading: false,
    hasErrored: null,
    errorMessage: null,
    patientRelationships: [],
    departments: [],
    purposes: [],
    deliveryModes: [],
  },

  payment: {
    createMedicalReport: {
      mnProcessEndDt: null,
      mnRptStatus: null,
      mnProcessStDt: null,
      mnReportNo: null,
      mnStrErrorMsg: null,
    },
    submitPayment: {
      PaymentMode: null,
      MerchantRefNum: null,
      PaymentToken: null,
      UpdatePaymentTokenStatus: null,
      Expires: null,
      RedirectionUrl: null,
      ModuleCallbackUrl: null,
    },
    paymentStatusInfo: {
      PaymentStatus: null,
      TransactionStatus: null,
      InstitutionCode: null,
      InstitutionName: null,
      TransactionDateTime: null,
      TransactionRefNo: null,
      ReportNo: null,
      AmountDeducted: null,
    },
  },

  receiptPageLanding: true,

  apiStatus: {
    [ApiEnum.SubmitPayment]: {
      isLoading: null,
      hasErrored: null,
      errorMessage: null,
    },
    [ApiEnum.GetPaymentStatusInfo]: {
      isLoading: null,
      hasErrored: null,
      errorMessage: null,
    },
  },

  mrrpPersist: {
    paymentToken: null,
    reportNo: null,
    reportTypeLabel: null,
    reportType: null,
    memberIdentifier: null,
    emailAddress: null,
    selectedPaymentMode: null,
  },
  appRating: false,
};
