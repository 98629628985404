import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { SUBMIT_MEDICATION_URL } from "../../resources/configs/URL";
import { formatSubmitMedicationErrorMessage } from "../../resources/messages/ErrorMessage";
import {
  SubmitMedicationRequestPayload,
  SubmitMedicationResponsePayload,
} from "./submitMedication.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";

/**
 * Submits a MedRefill medication request
 *
 * POST
 * Endpoint: /medrefill/SubmitMedication
 *
 * @param {SubmitMedicationRequestPayload} req  Request body
 *
 * @returns {SubmitMedicationResponsePayload} Response payload from the remote API
 */
const submitMedication = async (
  req: SubmitMedicationRequestPayload,
): Promise<SubmitMedicationResponsePayload> => {
  try {
    const response = await axios.post<SubmitMedicationResponsePayload>(
      SUBMIT_MEDICATION_URL(),
      req,
      getRequestOptions(),
    );
    return parseResponse(response, SUBMIT_MEDICATION_URL());
  } catch (error) {
    const msg = formatSubmitMedicationErrorMessage(SUBMIT_MEDICATION_URL());
    console.error(msg);
    throw error;
  }
};

export { submitMedication };
