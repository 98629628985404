import { connect } from "react-redux";
import { RootState } from "lib/redux/root/redux.types";
import UserProfile from "./UserProfile";
import { Dispatch } from "react";
import { AnyAction } from "redux";
import { setIsCreateFromAppointmentLanding } from "ui/appointment/ducks/appointmentsSlice";

const mapStateToProps = (state: RootState) => {
  return {
    name: state.user.patientName,
    maskedNric: state.user.patientNric,
    photo: state.user.photo,
    isLoading: state.user.isLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setIsCreateFromAppointmentLanding: (
      isCreateFromAppointmentLanding: boolean,
    ) => {
      setIsCreateFromAppointmentLanding(isCreateFromAppointmentLanding);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
