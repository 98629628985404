import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    mainContainer: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      pb: theme.spacing(2),
    },
    title: {
      typography: theme.typography.header4_semibold,
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(1.5),
    },
    skeleton: {
      margin: "0 auto",
      marginTop: 2,
      borderRadius: size.BORDER_RADIUS,
    },
  } satisfies SxObjType;
};

export { sxStyles };
