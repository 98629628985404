import { Box, Button, useTheme } from "@mui/material";
import { sxStyles } from "./MiniButton.styles";

const MiniButton = ({
  children,
  icon,
  onClick,
}: {
  children: React.ReactNode;
  icon?: string;
  onClick: () => void;
}) => {
  const theme = useTheme();
  const classes = sxStyles(theme);
  return (
    <Button
      sx={classes.button}
      variant={"outlined"}
      onClick={onClick}
      startIcon={<Box component={"img"} src={icon} />}
    >
      {children}
    </Button>
  );
};

export default MiniButton;
