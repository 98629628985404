import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    background: {
      backgroundColor: theme.palette.secondary.main,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 2,
    },
    profileAvatar: {
      width: 50,
      height: 50,
      border: `1px solid ${theme.palette.secondary.light}`,
    },
    cameraAvatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      border: `1px solid ${theme.palette.secondary.light}`,
    },
    fileInputField: {
      display: "none",
    },

    errorText: {
      fontSize: size.FONTSIZE_14,
      color: theme.palette.error.light,
      textTransform: "none",
      textAlign: "center",
    },
  } satisfies SxObjType;
};
export { sxStyles };
