import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    textBox: {
      margin: theme.spacing(5, 0, 1),
    },
    textBoxes: {
      display: "flex",
      margin: theme.spacing(2, 0, 0),
      // "& .MuiFormControl-root": {
      //   margin: theme.spacing(0, 1, 0, 0),
      //   "& .MuiOutlinedInput-notchedOutline": {
      //     border: `1px solid ${theme.palette.grey[100]}`,
      //     "& legend": {
      //       width: "80%",
      //     },
      //   },
      // },
      // "& .MuiInputBase-root": {
      //   borderRadius: theme.spacing(0.5),
      //   lineHeight: "initial",
      //   "& .MuiOutlinedInput-notchedOutline": {
      //     border: `1px solid ${theme.palette.grey[100]}`,
      //   },
      //   "& .MuiSelect-iconOutlined": {
      //     top: 0,
      //   },
      // },
      // "& .MuiInputLabel-outlined": {
      //   color: theme.palette.primary.main,
      //   fontSize: size.FONTSIZE_16,
      //   whiteSpace: "nowrap",
      //   width: "75%",
      //   overflow: "hidden",
      //   textOverflow: "ellipsis",
      //   marginTop: "-2px",
      //   lineHeight: 1.1,
      // },
    },
    qtyBox: {
      width: "50%",
      marginRight: theme.spacing(1),
    },
    selectBox: {
      width: "100%",
      maxWidth: "70%",
      "& .MuiInputLabel-outlined": {
        maxWidth: "calc(100% - 60px)",
      },
    },
    card: {
      marginBottom: theme.spacing(2),
    },
    mediaContent: {
      padding: theme.spacing(1),
    },
    cardHeader: {
      padding: theme.spacing(1),
      "& .MuiCardHeader-subheader": {
        color: theme.palette.grey[400],
        typography: theme.typography.body2_semibold,
      },
    },
    media: {
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(1),
      paddingTop: theme.spacing(0),
      width: "100%",
      color: theme.palette.grey[100],
      textAlign: "center",
    },
    deleteIcon: {
      color: theme.palette.error.main,
      fontWeight: theme.typography.fontWeightMedium,
      "& .MuiSvgIcon-root": {
        fontSize: size.FONTSIZE_24,
      },
      padding: theme.spacing(0.3, 0.5),
    },
  } satisfies SxObjType;
};

export { sxStyles };
