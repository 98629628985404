import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests";
import { GET_SYSTEMSETTINGS_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { GetSystemSettingsPayload } from "./getSystemSettings.fromApi.types";
import { formatGetSystemSettingsErrorMessage } from "api/resources/messages/ErrorMessage";

/**
 * Fetches the SystemSettings for a certain institution cluster based on a certain Category and Subcategory
 *
 * POST
 * Endpoint: /GetSystemSettings
 *
 * @param {string} category Category of SystemSettings it belongs to
 * @param {string} subcategory SubCategory of SystemSettings it belongs to
 * @param {string} codeName a specific code to identify a group of hospitals or polyclinics
 * @param {string} institutionCode a specific code to identify an institution
 */
const getSystemSettings = async ({
  category,
  subcategory,
  codeName,
  institutionCode,
}: {
  category: string | null;
  subcategory: string | null;
  codeName: string | null;
  institutionCode: string | null;
}): Promise<GetSystemSettingsPayload> => {
  try {
    const response = await axios.post<GetSystemSettingsPayload>(
      GET_SYSTEMSETTINGS_URL(),
      createRequestPayload(category, subcategory, codeName, institutionCode),
      getRequestOptions(),
    );
    return parseResponse(response, GET_SYSTEMSETTINGS_URL());
  } catch (error) {
    const msg = formatGetSystemSettingsErrorMessage(GET_SYSTEMSETTINGS_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  category: string | null,
  subcategory: string | null,
  codeName: string | null,
  institutionCode: string | null,
) => {
  return {
    Category: category,
    SubCategory: subcategory,
    CodeName: codeName,
    InstitutionCode: institutionCode,
  };
};

export { getSystemSettings };
