// navigation
import {
  mobileNavigate,
  navigateToExternalInMobileBrowser,
} from "lib/routing/navigate/navigate";

// styles
import { sxStyles } from "./VcpCard.styles";

// assets
import IMAGES from "lib/assets/images";

// components
import { Box } from "@mui/material";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import { formatSharingUrl } from "lib/mobileIntegration/shareUrl/formatSharingUrl";
import { VcpCardProps } from "./VcpCard.types";
import VCPLink from "ui/appointment/components/common/VCPLink/VCPLink";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";

const VcpCard = ({ disclaimerList, appointment }: VcpCardProps) => {
  const classes = sxStyles();

  const VCP_ZOOM_URL = appointment.participantURL || "";
  const inValidZoomURL = appointment.zoomLinkStatus === "E";
  const zoomLinkErrorMessage = appointment.zoomLinkErrorMessage;
  const isShowShareVCLinks = appointment.showShareVCLinks;

  // Links and Disclaimer texts
  const VCP_LEARN_TEXT =
    disclaimerList["TUTORIAL_HYPERLINK"]?.DisplayValue || "";
  const VCP_LEARN_URL = disclaimerList["TUTORIAL_HYPERLINK"]?.Value || "";
  const VCP_TEST_TEXT = disclaimerList["SANDBOX_HYPERLINK"]?.DisplayValue || "";
  const VCP_TEST_URL = disclaimerList["SANDBOX_HYPERLINK"]?.Value || "";

  return (
    <>
      <Box sx={classes.cardDivider}>
        {/* Share video consult link */}

        {isShowShareVCLinks === true ? (
          !inValidZoomURL ? (
            <Box sx={{ my: 2 }}>
              <VCPLink
                iconSrc={IMAGES.general.Share}
                iconAlt="Share my video consult details"
                linkDisplayText="Share my video consult details"
                handleClick={() => {
                  logEventToGoogleAnalytics(EVENTS.CLICK_APPT_SHARE_VIDEO_LINK);
                  mobileNavigate(formatSharingUrl(VCP_ZOOM_URL));
                }}
              />
            </Box>
          ) : (
            <SharpNoticePanel bgColor="extremeWarn">
              {zoomLinkErrorMessage}
            </SharpNoticePanel>
          )
        ) : null}

        {/* Learn to use video consult */}
        <VCPLink
          iconSrc={IMAGES.general.ExternalLinkIcon}
          iconAlt="Learn to use video consult"
          linkDisplayText={VCP_LEARN_TEXT}
          handleClick={() => {
            logEventToGoogleAnalytics(
              EVENTS.CLICK_APPT_LEARN_USE_VIDEO_CONSULT,
            );
            navigateToExternalInMobileBrowser(VCP_LEARN_URL);
          }}
        />

        {/* Test your set up */}
        <VCPLink
          iconSrc={IMAGES.general.ExternalLinkIcon}
          iconAlt="Test your setup"
          linkDisplayText={VCP_TEST_TEXT}
          handleClick={() => {
            logEventToGoogleAnalytics(EVENTS.CLICK_APPT_TEST_SETUP);
            navigateToExternalInMobileBrowser(VCP_TEST_URL);
          }}
        />
      </Box>
    </>
  );
};

export default VcpCard;
