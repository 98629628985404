import { Box, Skeleton } from "@mui/material";
import React from "react";
import CardWrapper from "ui/appointment/components/common/cards/CardWrapper/CardWrapper";
import { sxStyles } from "./BillCardSkeleton.styles";

const BillCardSkeleton = () => {
  const classes = sxStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      align-items="center"
      justifyContent="center"
      m={2}
    >
      <Skeleton
        role="progressbar"
        animation="wave"
        sx={classes.skeletonHeader}
        data-testid="card-skeleton"
      />
      <Box mb={2}>
        <CardWrapper>
          <Box display={"flex"} p={1}>
            <Box sx={classes.leftSkeletonContainer}>
              <Skeleton
                animation="wave"
                sx={classes.leftSkeleton}
                role="progressbar"
              />
              <Skeleton
                animation="wave"
                sx={classes.leftSkeleton}
                role="progressbar"
              />
            </Box>
            <Box sx={classes.rightSkeletonContainer}>
              {Array(3)
                .fill("")
                .map((element, index) => {
                  return (
                    <Skeleton
                      sx={classes.rightSkeleton}
                      animation="wave"
                      key={`BillSkeleton1${index}`}
                      role="progressbar"
                    />
                  );
                })}
            </Box>
          </Box>
        </CardWrapper>
      </Box>
      <Box mb={2}>
        <CardWrapper>
          <Box display={"flex"} p={1}>
            <Box sx={classes.leftSkeletonContainer}>
              <Skeleton
                animation="wave"
                sx={classes.leftSkeleton}
                role="progressbar"
              />
              <Skeleton
                animation="wave"
                sx={classes.leftSkeleton}
                role="progressbar"
              />
            </Box>
            <Box sx={classes.rightSkeletonContainer}>
              {Array(3)
                .fill("")
                .map((element, index) => {
                  return (
                    <Skeleton
                      sx={classes.rightSkeleton}
                      animation="wave"
                      key={`BillSkeleton2${index}`}
                      role="progressbar"
                    />
                  );
                })}
            </Box>
          </Box>
        </CardWrapper>
      </Box>
    </Box>
  );
};

export default BillCardSkeleton;
