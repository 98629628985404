import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { CHECK_IN_URL } from "../../resources/configs/URL";
import { formatCheckInErrorMessage } from "../../resources/messages/ErrorMessage";
import { CheckInPayload } from "./checkIn.fromApi.types";
import { KioskQuestionnaireResponse } from "./checkIn.fromUi.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";

/**
 * CheckIn an appointment
 *
 * POST
 * Endpoint: /CheckIn
 *
 * @param {string | null} memberIdentifier  Member Identifier of the person we are making this request for
 * @param {string} appointmentId  ID of the appointment
 * @param {string | null} regInstitutionCode  Reg Institution code of this appointment (e.g. "NUP")
 *
 * @returns {CheckInPayload} Response payload from the remote API
 */
const checkIn = async (
  memberIdentifier: string | null,
  regAppointmentId: string,
  encounterId: string | null,
  currentAppointmentTime: string | null,
  kioskQuestionnaireResponse: KioskQuestionnaireResponse,
): Promise<CheckInPayload> => {
  try {
    const response = await axios.post<CheckInPayload>(
      CHECK_IN_URL(),
      createRequestPayload(
        memberIdentifier,
        regAppointmentId,
        encounterId,
        currentAppointmentTime,
        kioskQuestionnaireResponse,
      ),
      getRequestOptions(),
    );
    return parseResponse(response, CHECK_IN_URL());
  } catch (error) {
    const msg = formatCheckInErrorMessage(CHECK_IN_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  memberIdentifier: string | null,
  regAppointmentId: string,
  encounterId: string | null,
  currentAppointmentTime: string | null,
  kioskQuestionnaireResponse: KioskQuestionnaireResponse,
) => {
  return {
    MemberIdentifier: memberIdentifier,
    RegAppointmentId: regAppointmentId,
    EncounterId: encounterId,
    AppointmentTime: currentAppointmentTime,
    QuestionnaireResponse: kioskQuestionnaireResponse,
  };
};

export { checkIn };
