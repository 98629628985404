import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.text.primary,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightBold,
      paddingTop: theme.spacing(3),
    },
    facilityTitle: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    facilityDetail: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
    },

    overlayCircularProgress: {
      backgroundColor: theme.palette.common.white,
      margin: "auto",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 5,
      display: "flex",
      alignItems: "center",
    },
    loadingIcon: {
      margin: "50px auto",
    },
  } satisfies SxObjType;
};

export { sxStyles };
