import { Box, Typography } from "@mui/material";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import { sxStyles } from "./BannerCard.styles";
import { BannerCardProps } from "./BannerCard.types";

const BannerCard = ({
  bannerTitle,
  bannerImage,
  buttonText,
  onClickButton,
}: BannerCardProps) => {
  const classes = sxStyles();
  return (
    <>
      <Box>
        {bannerTitle && (
          <Typography sx={classes.title}>{bannerTitle}</Typography>
        )}

        <Box sx={classes.image}>
          <img
            src={bannerImage}
            alt={bannerTitle ? `${bannerTitle} banner` : "banner image"}
          />
        </Box>

        {buttonText && onClickButton && (
          <Box sx={classes.bannerButtonContainer}>
            <ButtonsFooter
              nextButtonText={buttonText ?? ""}
              onClickNext={onClickButton}
              hideCancelButton={true}
              cancelButtonText={""}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default BannerCard;
