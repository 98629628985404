import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    dialog: {
      color: theme.palette.grey[600],
      "& .MuiDialog-paper": {
        margin: theme.spacing(0),
        width: "100%",
        "& .MuiDialogContent-root": {
          padding: theme.spacing(1, 1, 0, 1),
          "& .MuiIconButton-root": {
            padding: 0,
            margin: 0,
            opacity: 0.6,
          },
          "& .Carousel-indicators-200": {
            marginTop: theme.spacing(0.5),
          },
        },
      },
    },
    photo: {
      width: "100%",
      height: "100%",
    },
    title: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_semibold,
      paddingBottom: theme.spacing(1),
    },
    photoPairTitle: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      textAlign: "center",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(0),
      top: theme.spacing(0),
      color: theme.palette.grey[600],
      padding: theme.spacing(0.5),
      zIndex: 100,
      "& .MuiSvgIcon-root": {
        fontSize: size.FONTSIZE_24,
      },
    },
    photoWrapper: {
      "& .react-transform-component": {
        margin: "0 auto",
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
