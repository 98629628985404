import { Box } from "@mui/material";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";

const RescheduleNotice = () => {
  return (
    <Box>
      <Box sx={{ m: 2 }}>
        <SharpNoticePanel bgColor="warn">
          Choose your preferred time slot from the available options below.
        </SharpNoticePanel>
      </Box>
    </Box>
  );
};

export default RescheduleNotice;
