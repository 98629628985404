import { PopHealthStatus } from "../getPopHealthStatus.toUi.types";
import { GetPopHealthStatus } from "../getPopHealthStatus.fromApi.types";

/**
 * Maps fetched GetAppointmentStatus API payload to a schema that the
 * UI components can consume.
 *
 * @param {GetAppointmentStatusPayload | null} payload
 *   The payload which will be transformed
 * @returns {AppointmentStatus}
 *   AppointmentStatus data in a schema the UI can support
 */
const mapApiDataToProps = (
  payload: GetPopHealthStatus | null,
): PopHealthStatus => {
  if (!payload) {
    return {
      isEnroled: null,
      enrolmentDate: "",
      hciCode: "",
      isHpCompleted: null,
      subsequentStartDate: null,
      subsequentEndDate: null,
      healthPlanCompletionDate: null,
      popOutMessage: "",
    };
  } else {
    return {
      isEnroled: payload.IsEnroled,
      enrolmentDate: payload.EnrolmentDate,
      hciCode: payload.HciCode,
      subsequentStartDate: payload.SubsequentStartDate,
      subsequentEndDate: payload.SubsequentEndDate,
      healthPlanCompletionDate: payload.HealthPlanCompletionDate,
      isHpCompleted: payload.IsHpCompleted,
      popOutMessage: payload.PopOutMessage,
    };
  }
};

export { mapApiDataToProps };
