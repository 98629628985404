import React, { useState } from "react";
import { Typography, Box, CircularProgress } from "@mui/material";
import SummarySectionFrame from "../../../../../../lib/components/summary/SummarySectionFrame/SummarySectionFrame";
import PatientOrContactDetailSummarySection from "../../../../../../lib/components/summary/PatientOrContactDetailSummarySection/PatientOrContactDetailSummarySection";
import { sxStyles } from "./OrderSummary.styles";
import { OrderSummaryProps } from "./OrderSummary.types";
import MedicationRefillDetailSummarySection from "../MedicationRefillDetailSummarySection/MedicationRefillDetailSummarySection";
import MedicationTopupDetailSummarySection from "../MedicationTopupDetailSummarySection/MedicationTopupDetailSummarySection";
import { useNavigate } from "react-router-dom";
import { ACTIONS, PATHS } from "lib/routing";
import MedicationRefillPrescriptionSummaryDetail from "../MedicationRefillPrescriptionSummaryDetail/MedicationRefillPrescriptionSummaryDetail";
import OrderSummarySubmissionButtonsContainer from "./OrderSummarySubmissionButtons/OrderSummarySubmissionButtonsContainer";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { MedRefillRequestType } from "ui/medrefill/ducks/medrefill.types";
import { maskNric } from "ui/appointment/components/common/user/UserProfile/maskNric";
import DeliveryDetailSummarySection from "lib/components/summary/DeliveryDetailSummarySection/DeliveryDetailSummarySection";
import PharmacyDetailSummarySection from "../PharmacyDetailSummarySection/PharmacyDetailSummarySection";
import RefillOrTopupSubmitRequestConfirmationModalContainer from "../../confirmationModals/RefillOrTopupSubmitRequestConfirmationModal/RefillOrTopupSubmitRequestConfirmationModalContainer";
import VirtualConsultSubmitRequestConfirmationModal from "ui/medrefill/components/virtualconsult/OrderSummary/SubmitRequestConfirmationModal/SubmitRequestConfirmationModal";
import PaymentSummarySection from "./PaymentSummarySection/PaymentSummarySection";
import { useAppSelector } from "lib/redux/hooks";
import { selectPayment } from "ui/medrefill/ducks/selectors";
import CancelRequestConfirmationModal from "../../confirmationModals/CancelRequestConfirmationModal/CancelRequestConfirmationModal";

// ============================
// Google Analytics Logging Event
// ============================

const logViewEventToGoogleAnalytics = (
  requestType: MedRefillRequestType,
  reorderFlag: boolean,
) => {
  if (requestType === "REFILL") {
    if (reorderFlag === false) {
      logEventToGoogleAnalytics(EVENTS.VIEW_NEW_REFILL_ORDER_SUMMARY);
    } else {
      logEventToGoogleAnalytics(EVENTS.VIEW_REORDER_REFILL_ORDER_SUMMARY);
    }
  } else if (requestType === "TOPUP") {
    if (reorderFlag === false) {
      logEventToGoogleAnalytics(EVENTS.VIEW_NEW_TOPUP_ORDER_SUMMARY);
    } else {
      logEventToGoogleAnalytics(EVENTS.VIEW_REORDER_TOPUP_ORDER_SUMMARY);
    }
  } else {
    logEventToGoogleAnalytics(EVENTS.VIEW_NEW_VIRTUAL_CONSULT_ORDER_SUMMARY);
  }
};

const OrderSummary = ({
  summary,
  preventDirectBackNavigationState,
  onSelectParticulars,
  resetPreventDirectBackNavigationState,
}: OrderSummaryProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();

  const { subsidyDescription, paymentInstructions } =
    useAppSelector(selectPayment);

  logViewEventToGoogleAnalytics(summary.requestType, summary.reorder);

  // State of Editable Fields
  const [editContactOrEmail, setEditContactOrEmail] = useState(false);
  const [contact, setContact] = useState(summary.particulars.contactNumber);
  const [email, setEmail] = useState(summary.particulars.email);

  // Show loading during submission
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Submission Success Dialog open & close state and handlers
  const [requesterId, setRequesterId] = useState<string | null>(null);
  const [submitSuccessOpen, setOpenSubmitSuccessModal] = useState(false);
  const handleOpenSubmitSuccessModal = (referenceId: string | null) => {
    setRequesterId(referenceId);
    setOpenSubmitSuccessModal(true);
  };

  // Cancel Request Warning Dialog open & close state and handlers
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const handleOpenWarningModal = () => setWarningModalOpen(true);
  const handleCloseWarningModal = () => setWarningModalOpen(false);

  // Event Handlers
  const saveContactAndEmailChange = (
    updatedContact: string,
    updatedEmail: string,
  ) => {
    setContact(updatedContact);
    setEmail(updatedEmail);
    setEditContactOrEmail(false);
  };

  //save form state when back navigation
  React.useEffect(() => {
    if (preventDirectBackNavigationState) {
      onSelectParticulars({
        ...summary.particulars,
        contactNumber: contact,
        email: email,
      });
      resetPreventDirectBackNavigationState();
      navigate(-1);
    }
    // we only want this hook to fire on back navigation
    // eslint-disable-next-line
  }, [preventDirectBackNavigationState]);

  React.useEffect(() => {
    onSelectParticulars({
      ...summary.particulars,
      contactNumber: contact,
      email: email,
    });
  }, [email, contact, onSelectParticulars, summary.particulars]);

  return (
    <>
      {isSubmitting ? (
        <Box display="flex" align-items="center" justifyContent="center" pt={4}>
          <CircularProgress />
        </Box>
      ) : (
        <Box m={2} mt={3}>
          <Typography sx={classes.title}>Order Summary</Typography>
          {summary.requestType === "VIRTUALCONSULT" && (
            <Typography
              sx={classes.subtitle}
            >{`Ordered by ${summary.particulars.requesterName}`}</Typography>
          )}

          <SummarySectionFrame>
            <Typography sx={classes.facilityTitle}>
              Prescribing Institution
            </Typography>
            <Typography sx={classes.facilityDetail}>
              {summary.institution.facilityName}
            </Typography>
          </SummarySectionFrame>

          <SummarySectionFrame>
            <PatientOrContactDetailSummarySection
              title="Patient's Detail"
              upperLabel="Name"
              upperDetail={
                summary.particulars.isPatient
                  ? summary.particulars.requesterName || ""
                  : summary.particulars.patientName || ""
              }
              lowerLabel="NRIC / Birth Certificate / FIN"
              // mask nric here so that when doing reordering, no clear text nric is displayed
              lowerDetail={
                summary.particulars.isPatient
                  ? maskNric(summary.particulars.requesterNric) || ""
                  : maskNric(summary.particulars.patientNric) || ""
              }
            ></PatientOrContactDetailSummarySection>
          </SummarySectionFrame>

          <SummarySectionFrame
            editable={true}
            onClickEdit={() => setEditContactOrEmail(true)}
            editing={editContactOrEmail}
          >
            <PatientOrContactDetailSummarySection
              title="Contact Details"
              upperLabel="Contact Number"
              upperDetail={contact}
              lowerLabel="Email"
              lowerDetail={email}
              editable={editContactOrEmail}
              validateAsPhoneAndEmail={true}
              setEditable={setEditContactOrEmail}
              handleChanges={saveContactAndEmailChange}
            ></PatientOrContactDetailSummarySection>
          </SummarySectionFrame>

          {summary.requestType === "REFILL" ? (
            <>
              <SummarySectionFrame
                editable={true}
                onClickEdit={() => {
                  navigate(PATHS.MED_REFILL_QUANTITY_EDIT.path);
                }}
              >
                <MedicationRefillDetailSummarySection
                  typeId={summary.quantity.typeId || 3}
                  quantityDetailList={summary.quantity.details}
                ></MedicationRefillDetailSummarySection>
              </SummarySectionFrame>
            </>
          ) : summary.requestType === "TOPUP" ? (
            <SummarySectionFrame
              editable={true}
              onClickEdit={() => {
                navigate(PATHS.MED_TOPUP_PRESCRIPTION_EDIT.path);
              }}
            >
              <MedicationTopupDetailSummarySection
                topupReasonText={
                  summary.prescription.topupReason !== 4
                    ? summary.prescription.topupReasonText
                    : summary.prescription.topupOtherReason
                }
                files={summary.prescription.files}
              />
            </SummarySectionFrame>
          ) : null}

          <SummarySectionFrame
            editable={true}
            onClickEdit={() => {
              if (summary.requestType === "REFILL") {
                navigate(PATHS.MED_REFILL_COLLECTION_EDIT.path);
              } else if (summary.requestType === "TOPUP") {
                navigate(PATHS.MED_TOPUP_COLLECTION_EDIT.path);
              } else {
                navigate(
                  PATHS.MED_VIRTUALCONSULT_ORDER_MEDICATION_COLLECTION_EDIT
                    .path,
                );
              }
            }}
          >
            {summary.collection.typeId === 3 ? (
              <DeliveryDetailSummarySection
                postalCode={summary.collection.postalCode}
                blockNo={summary.collection.blockNo}
                unitNo={summary.collection.unitNo}
                address={summary.collection.address}
                showSpecialRequest={true}
                specialRequest={summary.collection.specialRequest}
              />
            ) : (
              <PharmacyDetailSummarySection
                facilityName={summary.institution.facilityName}
                locationName={summary.collection.locationName}
                specialRequest={summary.collection.specialRequest}
                date={summary.collection.date}
                timeslot={summary.collection.timeslot}
              />
            )}
          </SummarySectionFrame>

          <SummarySectionFrame
            editable={true}
            onClickEdit={() => {
              if (summary.requestType === "REFILL") {
                navigate(PATHS.MED_REFILL_PAYMENT_EDIT.path);
              } else if (summary.requestType === "TOPUP") {
                navigate(PATHS.MED_TOPUP_PAYMENT_EDIT.path);
              } else {
                navigate(
                  PATHS.MED_VIRTUALCONSULT_ORDER_MEDICATION_PAYMENT_EDIT.path,
                );
              }
            }}
          >
            <PaymentSummarySection
              subsidyDescription={subsidyDescription}
              paymentInstructions={paymentInstructions}
            />
          </SummarySectionFrame>

          {/* Render Prescription Details */}
          {summary.requestType === "REFILL" ? (
            <SummarySectionFrame
              editable={true}
              onClickEdit={() =>
                navigate(PATHS.MED_REFILL_COLLECTION_EDIT.path)
              }
            >
              <MedicationRefillPrescriptionSummaryDetail
                files={summary.prescription.files}
              ></MedicationRefillPrescriptionSummaryDetail>
            </SummarySectionFrame>
          ) : null}

          <Box mt={2} mb={3}>
            {/* Form Submission Buttons that perform the API call */}
            <OrderSummarySubmissionButtonsContainer
              summary={{
                ...summary,
                particulars: {
                  ...summary.particulars,
                  contactNumber: contact,
                  email: email,
                },
              }}
              reorderFlag={summary.reorder}
              isDisabled={editContactOrEmail}
              setIsSubmitting={setIsSubmitting}
              onSubmittedMedication={(referenceId) =>
                handleOpenSubmitSuccessModal(referenceId)
              }
              onCancelRequest={handleOpenWarningModal}
            />
          </Box>

          {/* Confirmation Modals */}
          {summary.requestType === "VIRTUALCONSULT" ? (
            <VirtualConsultSubmitRequestConfirmationModal
              open={submitSuccessOpen}
              requesterId={requesterId}
            />
          ) : (
            <RefillOrTopupSubmitRequestConfirmationModalContainer
              open={submitSuccessOpen}
              requesterId={requesterId}
              onClickConfirm={() => {
                if (summary.requestType === "REFILL") {
                  logEventToGoogleAnalytics(
                    EVENTS.SUBMIT_ANOTHER_REFILL_REQUEST,
                  );
                } else {
                  logEventToGoogleAnalytics(
                    EVENTS.SUBMIT_ANOTHER_TOPUP_REQUEST,
                  );
                }
                //summary value will be used for submitRequest with contact and email property value replaced by state in this component
                navigate(PATHS.MEDICATIONS_MOBILE.path);
              }}
              onClickCancel={() => {
                // fire action for mobile client to redirect to mobile dashboard
                mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH);

                // internally redirect to medrefill landing page
                navigate(PATHS.MEDICATIONS_MOBILE.path, { replace: true });
              }}
            />
          )}

          <CancelRequestConfirmationModal
            preventDirectCancel={warningModalOpen}
            onClickCancel={handleCloseWarningModal}
          />
        </Box>
      )}
    </>
  );
};

export default OrderSummary;
