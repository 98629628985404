import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    linkbox: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(-2),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
    },
    button: {
      padding: theme.spacing(0),
      margin: theme.spacing(2, 0, 0),
    },
    icon: {
      paddingLeft: theme.spacing(1),
      width: "20px",
    },
  } satisfies SxObjType;
};

export { sxStyles };
