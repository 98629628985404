import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    skeletons: {
      marginLeft: "7%",
      marginRight: "auto",
    },
  } satisfies SxObjType;
};

export { sxStyles };
