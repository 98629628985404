import { moduleType } from "./../../../api/data/GetAnnouncements/getAnnouncements.types";
import { Dispatch, AnyAction } from "@reduxjs/toolkit";
import { RootState } from "lib/redux/root/redux.types";
import {
  initialState,
  initializeAppointments,
  setInitializeIsLoading,
  setInitializeHasErrored,
  setMammogramQuestionIsLoading,
  setMammogramQuestionHasErrored,
  initializeMammogramQuestions,
  setQueueInitializeIsLoading,
  setQueueInitializeHasErrored,
  updateQueueDetailsForActualiseAppointment,
  setTriageInitializeIsLoading,
  initializeTriageQuestionList,
  setTriageInitializeHasErrored,
  setInitializeErrorMessage,
  setTriageInitializeErrorMessage,
  setMammogramQuestionErrorMessage,
  setQueueInitializeErrorMessage,
  setSystemSettingsInitializeValue,
  setSystemSettingsInitializeErrorMessage,
  setSystemSettingsInitializeIsLoading,
  setSystemSettingsInitializeHasErrored,
  setDocumentByTypeInitializeIsLoading,
  setDocumentByTypeInitializeValue,
  setDocumentByTypeInitializeHasErrored,
  setDocumentByTypeInitializeErrorMessage,
  setSystemSettingsInitializeValues,
} from "./";
import { getAppointmentList } from "api/appointment/GetAppointmentList/getAppointmentList";
import { getMammogramQuestionnaire } from "api/appointment/GetMammogramQuestionnaire/getMammogramQuestionnaire";
import { getTriageQuestionnaire } from "api/appointment/GetTriageQuestionnaire/getTriageQuestionnaire";
import { getQueueStatusAndJourney } from "api/appointment/GetQueueStatusAndJourney/getQueueStatusAndJourney";
import { getSystemSettings } from "api/shared/GetSystemSettings/getSystemSettings";
import { getDocumentByType } from "api/shared/GetDocumentByType/getDocumentByType";
import { DocumentByTypeMap, SettingsList } from "./appointments.types";
import {
  setAnnouncementsErrorMessage,
  setAnnouncementsHasErrored,
  setAnnouncementsIsLoading,
  setAnnouncementsMessage,
  setHasAnnouncement,
  setTeleconsultMessage,
} from "./appointmentsSlice";
import { getAnnouncements } from "api/data/GetAnnouncements/getAnnouncements";
import { AxiosError } from "axios";

// === Appointments Thunks ===
//
// Thunks are action creators that return a function instead of an action object.
//
// They are used here to obtain access to the global Redux store so that lookups to
// appointment data can be made.
// They are also used to dispatch regular synchronous actions before / after asynchronous
// operations (e.g. API call) have completed.
//

/**
 * Fetches appointments from the remote API and initializes appointments data
 * for the entire application. Loading / error states are also handled here.
 */
const fetchAppointments =
  () => async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(setInitializeIsLoading(true));
      const memberIdentifier = getState().user.memberIdentifier;
      const relationType = getState().user.relationType;

      const response = await getAppointmentList(memberIdentifier, relationType);

      dispatch(initializeAppointments(response.payload));
      dispatch(setInitializeHasErrored(false));
      dispatch(setInitializeErrorMessage(response.message));

      dispatch(setTeleconsultMessage(response.teleconsultMessage || ""));
    } catch (error) {
      dispatch(initializeAppointments(initialState.init));
      dispatch(setInitializeHasErrored(true));

      if (error instanceof AxiosError) {
        dispatch(setInitializeErrorMessage(error.response?.data.Message));
        dispatch(
          setTeleconsultMessage(error.response?.data.TeleconsultMessage || ""),
        );
      } else {
        dispatch(setInitializeErrorMessage(null));
      }
    } finally {
      dispatch(setInitializeIsLoading(false));
    }
  };

/**
 * Fetches Mammogram Questions from the remote API and initialize
 * Loading /error states handled.
 */
const fetchMammogramQuestionnaire =
  () => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setMammogramQuestionIsLoading(true));
      const mammogramQuestionPayLoad = await getMammogramQuestionnaire();

      dispatch(
        initializeMammogramQuestions({
          questions: mammogramQuestionPayLoad.Questions,
        }),
      );
      dispatch(setMammogramQuestionHasErrored(false));
      dispatch(setMammogramQuestionErrorMessage(null));
    } catch (error) {
      dispatch(initializeMammogramQuestions({ questions: [] }));
      dispatch(setMammogramQuestionHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(
          setMammogramQuestionErrorMessage(error.response?.data.Message),
        );
      } else {
        dispatch(setMammogramQuestionErrorMessage(null));
      }
    } finally {
      dispatch(setMammogramQuestionIsLoading(false));
    }
  };

/*
 * Fetches triage questionnaire from the remote API and initializes triage data.
 * Loading / error states are also handled here.
 */
const fetchTriageQuestionnaire =
  (questionnaireId: string | null) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setTriageInitializeIsLoading(true));
      const response = await getTriageQuestionnaire(questionnaireId);

      dispatch(initializeTriageQuestionList(response.Questions));
      dispatch(setTriageInitializeHasErrored(false));
      dispatch(setTriageInitializeErrorMessage(null));
    } catch (error) {
      dispatch(initializeTriageQuestionList([]));
      dispatch(setTriageInitializeHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setTriageInitializeErrorMessage(error.response?.data.Message));
      } else {
        dispatch(setTriageInitializeErrorMessage(null));
      }
    } finally {
      dispatch(setTriageInitializeIsLoading(false));
    }
  };

/*
 * Fetches queue status and journey list from the remote API and initializes triage data.
 * Loading / error states are also handled here.
 */
const fetchQueueStatusAndJourney =
  ({
    appointmentId,
    encounterId,
    regAppointmentId,
    appointmentTime,
    vcSupportStatus,
  }: {
    appointmentId: string;
    encounterId: string | null;
    regAppointmentId: string | null;
    appointmentTime: string | null;
    vcSupportStatus: string | null;
  }) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(setQueueInitializeIsLoading({ appointmentId, isLoading: true }));
      const memberIdentifier = getState().user.memberIdentifier;
      const response = await getQueueStatusAndJourney({
        memberIdentifier,
        regAppointmentId,
        encounterId,
        appointmentTime,
        vcSupportStatus,
      });

      dispatch(
        updateQueueDetailsForActualiseAppointment({
          appointmentId,
          currentStation: response.CurrentStation,
          queueNumber: response.MobileRegQNo,
          queueStatus: response.StatusText,
          statusMessage: response.StationMessage,
          patientWaiting: response.PatientWaiting,
          journeyList: response.Journey,
          showStatusText: response.ShowStatusText,
          showPatientWaiting: response.ShowPatientWaiting,
          canProceedToVirtualConsult:
            response.VirtualConsultDetail?.CanProceedToVirtualConsult || false,
          encounterAction:
            response.VirtualConsultDetail?.EncounterActions || [],
          hintMsg: response.VirtualConsultDetail?.HintMsg || null,
          requireCheckIn: response.RequireCheckIn,
          ihaveArrivedMessage: response.IhaveArrivedMessage,
          currentClinicCode: response.CurrentClinicCode,
          meetingId: response.VirtualConsultDetail?.MeetingId || null,
          meetingIdFormat:
            response.VirtualConsultDetail?.MeetingIdFormat || null,
          passcode: response.VirtualConsultDetail?.Passcode || null,
          lateThresholdMessage: response.LateThresholdMessage || null,
        }),
      );
      dispatch(
        setQueueInitializeHasErrored({ appointmentId, hasErrored: false }),
      );
      dispatch(
        setQueueInitializeErrorMessage({ appointmentId, errorMessage: null }),
      );
    } catch (error) {
      dispatch(
        updateQueueDetailsForActualiseAppointment({
          appointmentId,
          currentStation: null,
          queueNumber: null,
          queueStatus: null,
          statusMessage: null,
          patientWaiting: null,
          journeyList: null,
          showStatusText: false,
          showPatientWaiting: false,
          canProceedToVirtualConsult: false,
          encounterAction: [],
          hintMsg: null,
          requireCheckIn: false,
          ihaveArrivedMessage: null,
          currentClinicCode: null,
          meetingId: null,
          meetingIdFormat: null,
          passcode: null,
          lateThresholdMessage: null,
        }),
      );
      dispatch(
        setQueueInitializeHasErrored({ appointmentId, hasErrored: true }),
      );
      if (error instanceof AxiosError) {
        dispatch(
          setQueueInitializeErrorMessage({
            appointmentId,
            errorMessage: error.response?.data.Message,
          }),
        );
      } else {
        dispatch(
          setQueueInitializeErrorMessage({
            appointmentId,
            errorMessage: null,
          }),
        );
      }
    } finally {
      dispatch(
        setQueueInitializeIsLoading({ appointmentId, isLoading: false }),
      );
    }
  };

const fetchSystemSettings =
  (
    category: string | null,
    subcategory: string | null,
    codeName: string | null,
    institutionCode: string | null,
  ) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setSystemSettingsInitializeIsLoading(true));
      const response = await getSystemSettings({
        category,
        subcategory,
        codeName,
        institutionCode,
      });

      let map: SettingsList = {};

      for (let setting of response.Settings) {
        const { CodeName, Value, DisplayValue, Category, AltValue } = setting;
        map[CodeName] = {
          Category,
          Value,
          DisplayValue,
          AltValue: AltValue ?? null,
        };
      }

      dispatch(setSystemSettingsInitializeValues(map));
      if (codeName !== "NUPSDASlotMessage") {
        dispatch(setSystemSettingsInitializeValue(response.Settings[0].Value));
      }

      dispatch(setSystemSettingsInitializeHasErrored(false));
      dispatch(setSystemSettingsInitializeErrorMessage(null));
    } catch (error) {
      dispatch(setSystemSettingsInitializeValue(null));
      dispatch(setSystemSettingsInitializeHasErrored(true));

      if (error instanceof AxiosError) {
        dispatch(
          setSystemSettingsInitializeErrorMessage(error.response?.data.Message),
        );
      } else {
        dispatch(setSystemSettingsInitializeErrorMessage(null));
      }
    } finally {
      dispatch(setSystemSettingsInitializeIsLoading(false));
    }
  };

const fetchDocumentForAllTypes =
  () => async (dispatch: Dispatch<AnyAction>) => {
    let map: DocumentByTypeMap = {};
    try {
      dispatch(setDocumentByTypeInitializeIsLoading(true));
      const response = await getDocumentByType("Appointments", null);
      if (response.Document.length !== 0) {
        //store all document by type as key and value pair to make the lookup easier, and avoid multiples call of API
        for (var document of response.Document) {
          map[document.Type] = document.Contents;
        }
        dispatch(setDocumentByTypeInitializeValue(map));
        dispatch(setDocumentByTypeInitializeHasErrored(false));
        dispatch(setDocumentByTypeInitializeErrorMessage(null));
      }
    } catch (error) {
      dispatch(setDocumentByTypeInitializeValue(map));
      dispatch(setDocumentByTypeInitializeHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(
          setDocumentByTypeInitializeErrorMessage(error.response?.data.Message),
        );
      } else {
        dispatch(setDocumentByTypeInitializeErrorMessage(null));
      }
    } finally {
      dispatch(setDocumentByTypeInitializeIsLoading(false));
    }
  };

const fetchAnnouncements =
  (module: moduleType) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setAnnouncementsIsLoading(true));
      const response = await getAnnouncements(module);
      dispatch(setAnnouncementsMessage(response.Announcement));
      dispatch(setHasAnnouncement(response.HasAnnouncement));
      dispatch(setAnnouncementsHasErrored(false));
      dispatch(setAnnouncementsErrorMessage(null));
    } catch (error) {
      dispatch(setAnnouncementsMessage(null));
      dispatch(setHasAnnouncement(false));
      dispatch(setAnnouncementsHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setAnnouncementsErrorMessage(error.response?.data.Message));
      }
    } finally {
      dispatch(setAnnouncementsIsLoading(false));
    }
  };

export {
  fetchAppointments,
  fetchMammogramQuestionnaire,
  fetchTriageQuestionnaire,
  fetchQueueStatusAndJourney,
  fetchSystemSettings,
  fetchDocumentForAllTypes,
  fetchAnnouncements,
};
