import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    buttonBox: {
      display: "grid",
      justifyContent: "flex-end",
    },

    fileNameLabel: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      "& .MuiTypography-colorTextSecondary": {
        color: theme.palette.grey[400],
      },
    },
    fileName: {
      color: theme.palette.primary.main,
      typography: theme.typography.body2_bold,
      "& .MuiTypography-colorTextSecondary": {
        color: theme.palette.grey[400],
      },
    },
    boxRow: {
      display: "flex",
    },
    boxLeft: {
      width: "90%",
      padding: theme.spacing(0, 0.5),
    },
    boxRight: {
      width: "10%",
      display: "flex",
      flexDirection: "column",
    },
    deleteIcon: {
      color: theme.palette.error.main,
      fontWeight: theme.typography.fontWeightMedium,
      "& .MuiSvgIcon-root": {
        fontSize: size.FONTSIZE_24,
      },
      padding: theme.spacing(1, 0.5),
      alignSelf: "flex-end",
    },

    errText: {
      color: theme.palette.error.main,
      typography: theme.typography.caption1_regular,
      margin: theme.spacing(-0.5, 0, 0, 0.5),
    },
    attachmentJpegFrame: {
      display: "flex",
      flexDirection: "column",
      margin: theme.spacing(1, 0.5),
    },
    jpeg: {
      width: "100%",
      borderRadius: theme.spacing(0.5),
      height: "150px",
      objectFit: "contain",
      border: `1px solid ${theme.palette.grey[100]}`,
    },
  } satisfies SxObjType;
};

export { sxStyles };
