import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    buttonBox: {
      display: "grid",
      justifyContent: "flex-end",
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
    },

    formLabel: {
      color: "red",
      typography: theme.typography.body2_bold,
      padding: theme.spacing(0.5, 0, 1, 0.5),
    },

    textBox: {
      margin: theme.spacing(1, 0, 2),
      "& .MuiInputBase-root": {
        borderRadius: theme.spacing(0.5),
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.grey[100]} !important`,
        },
      },
      "& .MuiInputLabel-outlined": {
        color: theme.palette.primary.main,
        fontSize: size.FONTSIZE_16,
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
