import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests";
import { GET_DOCUMENT_BY_CLUSTER_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { formatGetDocumentByClusterPayloadErrorMessage } from "api/resources/messages/ErrorMessage";
import { GetDocumentByClusterPayload } from "./getDocumentByCluster.fromApi.types";

/**
 * Fetches the Clinics for a certain institution cluster
 *
 * POST
 * Endpoint: /GetClinics
 *
 * @param {string} cluster a specific code to identify a group of Clinics
 * @param {string} module
 */
const getDocumentByCluster = async (
  cluster: string,
  module: string,
): Promise<GetDocumentByClusterPayload> => {
  try {
    const response = await axios.post<GetDocumentByClusterPayload>(
      GET_DOCUMENT_BY_CLUSTER_URL(),
      createRequestPayload(cluster, module),
      getRequestOptions(),
    );
    return parseResponse(response, GET_DOCUMENT_BY_CLUSTER_URL());
  } catch (error) {
    const msg = formatGetDocumentByClusterPayloadErrorMessage(
      GET_DOCUMENT_BY_CLUSTER_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  cluster: string | null,
  module: string | null,
) => {
  return {
    Cluster: cluster,
    Module: module,
  };
};

export { getDocumentByCluster };
