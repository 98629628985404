import { Box, Skeleton } from "@mui/material";
import { color } from "lib/theme/color";

const AvailableSlotsSectionSkeletons = () => {
  return (
    <>
      <Box
        sx={{
          pl: 5,
          py: 1,
          borderBottom: "1px solid " + color.SECONDARY_LIGHT,
        }}
      >
        <Skeleton animation="wave" width="70%" />
      </Box>
      <Box
        sx={{
          pl: 5,
          py: 1,
          borderBottom: "1px solid " + color.SECONDARY_LIGHT,
        }}
      >
        <Skeleton animation="wave" width="70%" />
      </Box>
      <Box
        sx={{
          pl: 5,
          py: 1,
          borderBottom: "1px solid " + color.SECONDARY_LIGHT,
        }}
      >
        <Skeleton animation="wave" width="70%" />
      </Box>
      <Box
        sx={{
          pl: 5,
          py: 1,
          borderBottom: "1px solid " + color.SECONDARY_LIGHT,
        }}
      >
        <Skeleton animation="wave" width="70%" />
      </Box>
      <Box
        sx={{
          pl: 5,
          py: 1,
          borderBottom: "1px solid " + color.SECONDARY_LIGHT,
        }}
      >
        <Skeleton animation="wave" width="70%" />
      </Box>
    </>
  );
};

export default AvailableSlotsSectionSkeletons;
