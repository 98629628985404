import { color } from "lib/theme/color";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    cardBackground: {
      backgroundColor: theme.palette.common.white,
      boxShadow: "0px 4px 16px " + color.RGBA_52_16,
      borderRadius: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1.5),
    },
    deleteIconButton: {
      "& svg": {
        fontSize: 25,
        color: "red",
      },
    },
    label: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
    },
    text: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_semibold,
      wordBreak: "break-word",
    },
  } satisfies SxObjType;
};

export { sxStyles };
