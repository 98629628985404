import React from "react";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { Typography } from "@mui/material";
import { sxStyles } from "./SubmitRequestConfirmationModal.styles";
import { SubmitRequestConfirmationModalProps } from "./SubmitRequestConfirmationModal.types";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { ACTIONS } from "lib/routing";

const SubmitRequestConfirmationModal = ({
  open,
  requesterId,
}: SubmitRequestConfirmationModalProps) => {
  const classes = sxStyles();

  const handleClickDone = () => {
    mobileNavigate(ACTIONS.BACK_ACTION);
  };

  return (
    <ConfirmationModal
      open={open}
      title="Order submitted successfully!"
      titleBold={false}
      hideCancelButton={true}
      body={
        <Typography sx={classes.confirmationText}>
          Order ID: {requesterId} <br /> Your order will be processed
          accordingly. <br />
          <br />
          Pharmacy staff will contact you if the order cannot be processed or if
          clarification is required.
        </Typography>
      }
      nextButtonText="Done"
      onClose={() => {}}
      onClickNext={handleClickDone}
    ></ConfirmationModal>
  );
};

export default SubmitRequestConfirmationModal;
