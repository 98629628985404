import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box, CircularProgress, Button } from "@mui/material";
import { PATHS, ACTIONS } from "lib/routing";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { ConfirmedBundledLinkedApptsProps } from "./ConfirmedBundledLinkedAppts.types";
import { Appointment } from "api/appointment/GetAppointmentList/getAppointmentList.toUi.types";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import { sxStyles } from "./ConfirmedBundledLinkedAppts.styles";
import { rescheduleLinkedAppointment } from "api/appointment/RescheduleLinkedAppointment/rescheduleLinkedAppointment";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import BundledLinkedApptCard from "../BundledLinkedApptCard/BundledLinkedApptCard";
import { isJHC } from "ui/appointment/helper/isJHC/isJHC";
import { CONNECT_TO_LIVE_AGENT_BUTTON_TEXT } from "lib/components/navigation/AppRouteRenderer/ErrorModal/ErrorModal";
import { formatAddToCalendarURI } from "lib/mobileIntegration/addToCalendar/formatUrl";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { AxiosError } from "axios";

const ConfirmedBundledLinkedAppts = ({
  fromMobile,
  institutionCode,
  visitedSlotsPage,
  isLoadingAppointments,
  hasErroredAppointments,
  errorMessageAppointments,
  appointment,
  newSlotDateTimeList,
  memberIdentifier,
  isLoadingPreConsultMsg,
  preConsultMsg,
  fetchAppointments,
  clearVisitedSlotsPageFlag,
  handleErrorModal,
  fetchPreConsultMsg,
}: ConfirmedBundledLinkedApptsProps) => {
  const classes = sxStyles();
  const navigate = useNavigate();
  const linkedAppointments = flattenLinkedAppointment(appointment);

  // State management
  const [isLoadingReschedule, setIsLoadingReschedule] = useState(false);
  const [openRescheduleConfirmation, setOpenConfirmation] = useState(false);
  const [rescheduleAfterCare, setRescheduleAfterCare] = useState("");
  const handleOpenConfirmation = () => setOpenConfirmation(true);
  const handleCloseConfirmation = () => setOpenConfirmation(false);

  // Fetch appointments on loading to get the corresponding appointment details
  useEffect(() => {
    if (fromMobile && !visitedSlotsPage) {
      fetchAppointments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check isLoadingPreConsultMsg to ensure only fetch once in the rescheduling flow
  useEffect(() => {
    if (isLoadingPreConsultMsg === null) {
      fetchPreConsultMsg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPreConsultMsg]);

  // Request handler
  const sendRescheduleLinkedAppointmentRequest = async () => {
    // TODO: enhance defensive programming practice for all JSON.parse usage here, and add corresponding unit tests
    try {
      logEventToGoogleAnalytics(EVENTS.VIEW_RESCHEDULE_CONFIRMATION);
      setIsLoadingReschedule(true);

      const slotIdList: (string | null)[] = newSlotDateTimeList.map(
        (slot, index) => {
          if (slot) {
            // user picked this slot to reschedule to for this appointment
            return slot.slotId;
          } else {
            // user did not pick a slot to reschedule to for this appointment, so use original appointment object's metadata
            return null;
          }
        },
      );

      const response = await rescheduleLinkedAppointment(
        memberIdentifier,
        appointment?.institutionCode || "",
        slotIdList,
      );

      mobileNavigate(ACTIONS.APP_RATING);

      if (response.PatientInstruction) {
        setRescheduleAfterCare(response.PatientInstruction || "");
      }
      handleOpenConfirmation();
      clearVisitedSlotsPageFlag();
    } catch (error) {
      if (error instanceof AxiosError) {
        handleErrorModal(
          true,
          error.response?.data.Message,
          error.response?.data.IsLiveChatEnabled
            ? CONNECT_TO_LIVE_AGENT_BUTTON_TEXT
            : undefined,
        );
      } else {
        handleErrorModal(true, null);
      }
    } finally {
      setIsLoadingReschedule(false);
    }
  };

  const isNextDisabled =
    newSlotDateTimeList.every((x) => x === null) || isLoadingReschedule;
  const handleOnClickProceed = () => {
    sendRescheduleLinkedAppointmentRequest();
  };
  const handleOnClickBack = () => {
    clearVisitedSlotsPageFlag();
    navigate(-1);
  };

  return (
    <>
      {isLoadingAppointments ||
      isLoadingPreConsultMsg ||
      isLoadingReschedule ? (
        <Box display="flex" align-items="center" justifyContent="center" pt={4}>
          <CircularProgress />
        </Box>
      ) : hasErroredAppointments ? (
        <ErrorDisplayContainer
          errorMessage={errorMessageAppointments}
          onTryAgain={() => fetchAppointments()}
        />
      ) : (
        <>
          <Typography sx={classes.title}>
            Confirm your appointment changes
          </Typography>{" "}
          {/* Suggestion Text */}
          {!newSlotDateTimeList.every((item) => item === null) ? (
            <Box sx={classes.suggestion}>
              <SharpNoticePanel bgColor="warn">
                {preConsultMsg && MapRawStringToReactElement(preConsultMsg)}
                {isJHC(institutionCode) ||
                institutionCode === "NUH" ||
                institutionCode === "AH"
                  ? null
                  : "Remember to remove your old appointment from your calendar."}{" "}
              </SharpNoticePanel>
            </Box>
          ) : null}
          {/* Linked Appointment Status Cards */}
          {linkedAppointments.map((appt, index) => {
            let slot = newSlotDateTimeList[index];

            let slotDateTime = slot ? slot.datetime : null;

            return (
              <BundledLinkedApptCard
                key={index}
                slot={slot}
                appointment={appt}
                newDateTime={slotDateTime}
                onSelect={() => {}}
              />
            );
          })}
          {/* Page Footer */}
          <Box
            sx={{ p: 2.5, display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Button onClick={handleOnClickBack}>Back</Button>
            <Button
              variant="outlined"
              disabled={isNextDisabled}
              onClick={handleOnClickProceed}
            >
              Proceed to reschedule
            </Button>
          </Box>
          {/* Save to Calendar Modal */}
          <ConfirmationModal
            open={openRescheduleConfirmation}
            title="Appointment Changed"
            body={
              // after care message
              MapRawStringToReactElement(
                rescheduleAfterCare === ""
                  ? linkedAppointments[0]?.reminder ?? ""
                  : rescheduleAfterCare,
              )
            }
            nextButtonText="Refill medication"
            cancelButtonText="Go to Appointment"
            additionalButtonText="Add to calendar"
            hideAdditionalButton={false}
            onClickNext={() => {
              mobileNavigate(ACTIONS.MOBILE_MEDICATION_LANDING);
              setOpenConfirmation(true);
            }}
            onClickCancel={() => {
              navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
            }}
            onClickAdditional={() => {
              const firstAppointment = linkedAppointments[0];

              // ensure user saves the most relevant datetime in the calendar
              let calendarDateTime = firstAppointment?.datetime;
              const slotDateTime = getDateFromISOString(
                newSlotDateTimeList[0] ? newSlotDateTimeList[0].datetime : null,
              );
              if (slotDateTime) {
                calendarDateTime = slotDateTime;
              }

              mobileNavigate(
                formatAddToCalendarURI(
                  firstAppointment.appointmentId || "",
                  calendarDateTime,
                  firstAppointment.calendarLocation || "",
                  firstAppointment.institutionName,
                  firstAppointment.calendarTitle,
                ),
              );
              navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
            }}
            onClose={handleCloseConfirmation}
          />
        </>
      )}
    </>
  );
};

/**
 * Flattens an appointment itself and all of its linked child appointments such that
 * they are strung together into a single list.
 *
 * @param {Appointment | null}  appointment  Linked appointment object
 *
 * @returns {BaseAppointment[]} List of the appointment itself and all of its linked child appointments
 */
const flattenLinkedAppointment = (
  appointment: Appointment | null,
): Appointment[] => {
  if (appointment) {
    const parent: Appointment = appointment;
    let children: Appointment[] = [];

    if (appointment.linkedAppointments) {
      children = appointment.linkedAppointments.map((x) => x);
    }

    return [parent, ...children];
  } else {
    return [];
  }
};

export default ConfirmedBundledLinkedAppts;
