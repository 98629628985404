import React from "react";
import { PATHS } from "lib/routing";
import ParticularsContainer from "../../../common/particulars/ParticularsContainer";

const RefillParticularsPage = () => {
  return (
    <ParticularsContainer pathToNextStep={PATHS.MED_REFILL_QUANTITY.path} />
  );
};

export default RefillParticularsPage;
