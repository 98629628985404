import React from "react";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import { OrderSummarySubmissionButtonsProps } from "./OrderSummarySubmissionButtons.types";
import { MedRefillRequestType } from "ui/medrefill/ducks/medrefill.types";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { AxiosError } from "axios";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectUser } from "lib/redux/user/selectors";
import {
  setOldMemberIdentifier,
  setOldTokenizedId,
} from "lib/redux/user/userSlice";

// ============================
// Google Analytics Logging Event
// ============================

const logViewEventToGoogleAnalytics = (
  requestType: MedRefillRequestType,
  reorderFlag: boolean,
) => {
  if (requestType === "REFILL") {
    if (reorderFlag === false) {
      logEventToGoogleAnalytics(EVENTS.NEW_REFILL_SUBMIT);
    } else {
      logEventToGoogleAnalytics(EVENTS.REORDER_REFILL_SUBMIT);
    }
  } else {
    if (reorderFlag === false) {
      logEventToGoogleAnalytics(EVENTS.NEW_TOPUP_SUBMIT);
    } else {
      logEventToGoogleAnalytics(EVENTS.REORDER_TOPUP_SUBMIT);
    }
  }
};

const OrderSummarySubmissionButtons = ({
  summary,
  reorderFlag,
  userState,
  isDisabled,
  setIsSubmitting,
  onSubmitMedication,
  onSubmittedMedication,
  onCancelRequest,
  handleErrorModal,

  appointmentId,
  regAppointmentId,
  targetSystem,
}: OrderSummarySubmissionButtonsProps) => {
  const dispatch = useAppDispatch();

  // Redux states
  const { oldMemberIdentifier, oldTokenizedId } = useAppSelector(selectUser);

  logViewEventToGoogleAnalytics(summary.requestType, reorderFlag);

  const sendSubmitAppointmentRequest = async () => {
    try {
      setIsSubmitting(true);
      const apptId =
        targetSystem === "NGEMR" && regAppointmentId
          ? regAppointmentId
          : appointmentId;
      const response = await onSubmitMedication(
        userState,
        summary,
        apptId,
        targetSystem,
        oldMemberIdentifier,
        oldTokenizedId,
      ); // Make the request

      setIsSubmitting(false);
      onSubmittedMedication(response.ReferenceId);
      dispatch(setOldMemberIdentifier(response.OldMemberIdentifier));
      dispatch(setOldTokenizedId(response.OldTokenizedId));
    } catch (error) {
      setIsSubmitting(false);
      if (error instanceof AxiosError) {
        handleErrorModal(true, error.response?.data.Message);
      }
    }
  };

  return (
    <ButtonsFooter
      isDisabled={isDisabled}
      nextButtonText="Submit request"
      cancelButtonText="Cancel request"
      onClickNext={sendSubmitAppointmentRequest}
      onClickCancel={onCancelRequest}
    />
  );
};

export default OrderSummarySubmissionButtons;
