import { Step, StepConnector, StepLabel, Stepper } from "@mui/material";
import { StepIndicatorProps } from "./StepIndicator.types";
import { sxStyles } from "./StepIndicator.styles";

const StepIndicator = ({ steps, currentStepIndex }: StepIndicatorProps) => {
  const classes = sxStyles();
  return (
    <Stepper
      alternativeLabel
      activeStep={computeCurrentStepIndex(currentStepIndex, steps.length)}
      sx={classes.stepperRoot}
      connector={<StepConnector sx={classes.stepConnectorAlternativeLabel} />}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            sx={classes.stepLabelContainer}
            StepIconProps={{
              sx: classes.stepIconRoot,
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

/**
 * Resolves to the appropriate step index. Prevents the index from going out of range.
 *
 * @param {number} index  Index of the current step
 * @param {number} max  Maximum allowable step index
 * @returns {number}  Step Index
 */
export const computeCurrentStepIndex = (index: number, max: number) => {
  if (index < 0) {
    return 0;
  } else {
    return Math.min(index, max);
  }
};

export default StepIndicator;
