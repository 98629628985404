import { color } from "lib/theme/color";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    mainContainer: {
      paddingTop: theme.spacing(2),
    },
    introContainer: {
      margin: theme.spacing(0, 2),
      marginBottom: 2.5,
    },
    introTitle: {
      typography: theme.typography.header4_semibold,
      color: theme.palette.text.primary,
    },
    introBody: {
      marginTop: theme.spacing(2),
      typography: theme.typography.body2_regular,
      color: theme.palette.grey[400],
    },
    preventiveCareContainer: {
      margin: theme.spacing(0, 2),
      marginTop: theme.spacing(2.5),
    },
    preventativeCareButtonContainer: { marginBottom: 1 },
    preventiveCareDisclaimer: {
      typography: theme.typography.body2_regular,
      color: theme.palette.grey[400],
    },
    cardsIntro: {
      margin: theme.spacing(0, 2),
      marginTop: theme.spacing(2.25),
      typography: theme.typography.body2_regular,
      color: theme.palette.grey[400],
    },
    cardWrapper: {
      margin: theme.spacing(2, 1.25),
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    footerContainer: {
      margin: theme.spacing(0, 2),
      paddingBottom: theme.spacing(6.5),
    },
    footerTitle: {
      typography: theme.typography.header4_semibold,
      color: theme.palette.text.primary,
    },

    cardIcon: {
      margin: theme.spacing(1, 1, 0, 1),
      width: theme.spacing(5),
      height: theme.spacing(5),
    },

    subtitle: {
      padding: theme.spacing(0.5, 1),
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      minHeight: theme.spacing(5.5),
    },
    status: {
      padding: theme.spacing(0, 1, 1),
      typography: theme.typography.body2_regular,
    },

    semiCard: {
      width: "48%",
      float: "left",
      boxShadow: "0px 0px 6px " + color.RGBA_52,
      margin: "8px 1%",
    },
  } satisfies SxObjType;
};

export { sxStyles };
