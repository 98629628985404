import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";
import { handleErrorModal } from "lib/redux/notifications";
import ClickHereLink from "../../../links/ClickHereLink/ClickHereLink";

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onClick: () => {
      dispatch(handleErrorModal({ open: false, message: null }));
    },
  };
};

export default connect(null, mapDispatchToProps)(ClickHereLink);
