import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { sxStyles } from "./RegisteredAppointmentArea.styles";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing/paths/paths";
import { RegisteredAppointmentAreaProps } from "./RegisteredAppointmentArea.types";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import RegisteredAppointmentCard from "./RegisteredAppointmentCard/RegisteredAppointmentCard";
import NonVCQueueDetail from "../../common/registration-queue/NonVCQueueDetail/NonVCQueueDetail";
import VCPQueueDetail from "../../common/registration-queue/VCPQueueDetail/VCPQueueDetail";
import QueueCardSkeleton from "./QueueCardSkeleton";
import {
  formatDate,
  formatOptions,
} from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { useAppSelector } from "lib/redux/hooks";
import { selectApptDetail } from "ui/appointment/ducks/selectors";

const RegisteredAppointmentArea = ({
  appointment,
  currentStation,
  queueStatus,
  patientWaiting,
  statusMessage,
  queueNumber,
  isLoading,
  hasErrored,
  errorMessage,
  showStatus,
  showPatientWaiting,
  canProceedToVirtualConsult,
  memberIdentifier,
  queueStatusFlag,
  encounterAction,
  hintMsg,
  requireCheckIn,
  iHaveArrivedMessage,
  currentClinicCode,
  lateThresholdMessage,
  onClick,
  onRefresh,
  resetErrorState,
  onRefreshAll,
}: RegisteredAppointmentAreaProps) => {
  const classes = sxStyles();
  const navigate = useNavigate();
  const apptDetail = useAppSelector(selectApptDetail);
  const [firstTimeLoading, setFirstTimeLoading] = useState(true);

  const [refreshAt, setRefreshAt] = useState(
    formatDate(formatDateToISO(new Date()), formatOptions.timeWithPeriod),
  );
  useEffect(() => {
    onRefresh({
      appointmentId: appointment.appointmentId || "",
      encounterId: apptDetail?.encounterId || appointment.encounterId,
      appointmentTime: appointment.appointmentStartDateTime || "",
      vcSupportStatus: appointment.vcSupportStatus || "",
      regAppointmentId: appointment.regAppointmentId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueStatusFlag, apptDetail?.encounterId]);

  // update refreshing time only after completion of fetching queue status
  useEffect(() => {
    if (!isLoading && hasErrored === false) {
      setRefreshAt(
        formatDate(formatDateToISO(new Date()), formatOptions.timeWithPeriod),
      );
      setFirstTimeLoading(false);
      resetErrorState(appointment.appointmentId);
    }
  }, [isLoading, hasErrored, resetErrorState, appointment]);

  return (
    <>
      {isLoading && firstTimeLoading ? (
        <QueueCardSkeleton />
      ) : hasErrored ? (
        <Box sx={classes.errorContainer}>
          <ErrorDisplay
            errorMessage={errorMessage}
            showErrorImage={false}
            showTitle={true}
          />
        </Box>
      ) : (
        <Box sx={classes.QueueDetailBox}>
          <Box
            sx={classes.borderBox}
            onClick={() => {
              if (
                !appointment.isVirtualConsultAppointment &&
                appointment.regInstitutionCode &&
                appointment.institutionCode &&
                appointment.sourceSystem
              ) {
                onClick(
                  appointment.appointmentId,
                  appointment.regAppointmentId,
                  appointment.regInstitutionCode,
                  appointment.institutionCode,
                  appointment.sourceSystem,
                  appointment.clinicCode,
                  appointment.actualizeStatus,
                  appointment.mobileRegQNo,
                  appointment.institutionName,
                  appointment.consultType,
                  apptDetail?.encounterId || appointment.encounterId,
                  appointment.appointmentStartDateTime || "",
                  appointment.vcSupportStatus || "",
                );
                navigate(PATHS.APPOINTMENT_QUEUECHIT_JOURNEY.path);
              }
            }}
          >
            {appointment.isVirtualConsultAppointment ? (
              <VCPQueueDetail
                appointment={appointment}
                onRefreshAll={onRefreshAll}
                institutionName={appointment.institutionName}
                institutionCode={appointment.institutionCode}
                regInstitutionCode={appointment.regInstitutionCode}
                appointmentId={appointment.appointmentId}
                memberIdentifier={memberIdentifier}
                targetSystem={appointment.sourceSystem}
                currentAppointmentTime={appointment.datetime}
                patientWaiting={patientWaiting}
                refreshAt={refreshAt}
                showPatientWaiting={showPatientWaiting}
                vcErrorMsg={appointment.zoomLinkErrorMessage || ""}
                participantURL={appointment.participantURL || ""}
                encounterStatusCode={appointment.encounterStatusCode || null}
                encounterStatus={appointment.encounterStatusDisplay || ""}
                canProceedToVirtualConsult={canProceedToVirtualConsult}
                isLoggingProceedToVideoConsultRequired={
                  appointment.isLoggingProceedToVideoConsultRequired || false
                }
                isShowShareVCLinks={appointment.showShareVCLinks || false}
                encounterAction={encounterAction}
                hintMsg={hintMsg}
                regAppointmentId={appointment.regAppointmentId}
                queueNumber={appointment.mobileRegQNo || ""}
                meetingId={appointment.formattedZoomMeetingId || null}
                passcode={appointment.zoomMeetingPassword || null}
                clinicCode={appointment.clinicCode || null}
              />
            ) : (
              <NonVCQueueDetail
                institutionName={appointment.institutionName}
                currentStation={currentStation}
                queueNumber={queueNumber}
                queueStatus={queueStatus}
                patientWaiting={patientWaiting}
                refreshAt={refreshAt}
                statusMessage={statusMessage}
                showStatus={showStatus}
                showPatientWaiting={showPatientWaiting}
                showRightArrow={true}
                requireCheckIn={requireCheckIn}
                iHaveArrivedMessage={iHaveArrivedMessage}
                regAppointmentId={appointment.regAppointmentId}
                appointmentId={appointment.appointmentId}
                institutionCode={appointment.institutionCode}
                regInstitutionCode={appointment.regInstitutionCode}
                sourceSystem={appointment.sourceSystem}
                clinicCode={appointment.clinicCode}
                consultType={appointment.consultType}
                currentClinicCode={currentClinicCode}
                lateThresholdMessage={lateThresholdMessage}
                encounterId={appointment.encounterId}
                appointmentTime={appointment.appointmentStartDateTime}
              />
            )}
          </Box>
          {!appointment.isVirtualConsultAppointment ? (
            <Box>
              <RegisteredAppointmentCard appointment={appointment} />
              {appointment.linkedAppointments?.map((appt) => {
                return (
                  <Box key={appt.appointmentId}>
                    <RegisteredAppointmentCard appointment={appt} />
                  </Box>
                );
              })}
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
};

export default RegisteredAppointmentArea;
