import { connect } from "react-redux";
import { RootState } from "lib/redux/root/redux.types";
import SummaryPageLayout from "./SummaryPageLayout";
import { Dispatch, AnyAction } from "redux";
import { updateParticulars } from "ui/medrefill/ducks";
import { MedRefillParticularsState } from "ui/medrefill/ducks/medrefill.types";
import { setCustomisedBack } from "lib/redux/navigation";

const mapStateToProps = (state: RootState) => {
  return {
    summary: state.medRefill,
    preventDirectBackNavigationState: state.navigation.customisedBack,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onSelectParticulars: (particularsState: MedRefillParticularsState) => {
      dispatch(updateParticulars(particularsState));
    },
    resetPreventDirectBackNavigationState: () => {
      dispatch(setCustomisedBack(false));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryPageLayout);
