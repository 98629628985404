import { encounterStatusCode } from "../../../../../../api/appointment/GetAppointmentList/getAppointmentList.toUi.types";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = (encounterStatusCode: encounterStatusCode | null) => {
  let colorTone: string | null = null;
  if (encounterStatusCode === "ENDED") {
    colorTone = theme.palette.success.main;
  } else if (encounterStatusCode === "NOSHOW") {
    colorTone = theme.palette.warning.main;
  } else {
    colorTone = theme.palette.text.primary;
  }

  return {
    detailLabel: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    currentStationText: {
      color: theme.palette.text.primary,
      typography: theme.typography.caption1_semibold,
    },
    queueFooterBox: {
      display: "inline-block",
      width: "100%",
    },
    queueFooterBoxSpace: {
      width: "50%",
      float: "left",
      margin: theme.spacing(1, 0),
      minHeight: theme.spacing(4.125),
    },
    queueStatusBox: {
      width: "100%",
      margin: theme.spacing(1, 0),
    },
    contentTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.body1_semibold,
    },
    contentText: {
      color: theme.palette.text.primary,
      typography: theme.typography.body1_bold,
    },
    consultDetailsTitle: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_semibold,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    consultDetails: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    consultId: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_semibold,
    },
    refreshedText: {
      color: theme.palette.text.primary,
      typography: theme.typography.body1_semibold,
    },
    queueStatusText: {
      color: ` ${colorTone} `,
      typography: theme.typography.body1_semibold,
    },
    vcpInstitutionNameBox: {
      textAlign: "left",
    },
    cardDivider: {
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      padding: theme.spacing(1, 0, 0),
      width: "100%",
      mt: 2,
    },
    cardLink: {
      color: theme.palette.primary.main,
      typography: theme.typography.body2_semibold,
      margin: theme.spacing(2, 0),
      display: "flex",
      alignItems: "center",
    },
    cardLinkIcon: {
      paddingRight: theme.spacing(1),
    },
    button: {
      mt: theme.spacing(1),
      width: "100%",
    },
    patientQueueNumber: {
      typography: theme.typography.header1_semibold,
      color: theme.palette.text.primary,
    },
    patientWaitingText: {
      typography: theme.typography.header4_regular,
      color: theme.palette.text.primary,
    },
    statusContainer: { float: "left" },
    statusTitle: {
      typography: theme.typography.body1_regular,
      color: theme.palette.grey[400],
    },
    statusInfo: {
      typography: theme.typography.body1_semibold,
      color: ` ${colorTone} `,
    },
    orderMedicationContainer: {
      float: "right",
    },
  } satisfies SxObjType;
};

export { sxStyles };
