import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { GET_TRIAGE_QUESTIONNAIRE_URL } from "../../resources/configs/URL";
import { formatGetTriageQuestionnaireErrorMessage } from "../../resources/messages/ErrorMessage";
import { GetTriageQuestionnairePayload } from "./getTriageQuestionnaire.fromApi.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";

/**
 * Retrieves the triage questionnaire displayed after selecting a particular
 * service when creating / re-scheduling an appointment.
 *
 * POST
 * Endpoint: /GetTriageQuestionnaire
 *
 * @param {string | null} institutionCode  Code of the institution / polyclinic of this service
 * @param {string | null} serviceType  Name of this service type
 * @param {string | null} targetSystem  Source system of this institution
 *
 * @returns {GetTriageQuestionnairePayload} Response payload from the remote API
 */
const getTriageQuestionnaire = async (
  questionnaireId: string | null,
): Promise<GetTriageQuestionnairePayload> => {
  try {
    const response = await axios.post<GetTriageQuestionnairePayload>(
      GET_TRIAGE_QUESTIONNAIRE_URL(),
      createRequestPayload(questionnaireId),
      getRequestOptions(),
    );
    return parseResponse(response, GET_TRIAGE_QUESTIONNAIRE_URL());
  } catch (error) {
    const msg = formatGetTriageQuestionnaireErrorMessage(
      GET_TRIAGE_QUESTIONNAIRE_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (questionnaireId: string | null) => {
  return {
    QuestionnaireId: questionnaireId,
  };
};

export { getTriageQuestionnaire };
