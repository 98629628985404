import { useCallback, useEffect, useState } from "react";
import { Box, Button, Card, Typography, useTheme } from "@mui/material";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import { sxStyles } from "./ContactDetails.styles";
import { ContactDetailsProps } from "./ContactDetails.types";
import { validatePhoneNumber } from "lib/util/ValidatorUtil/phoneNumberValidator/phoneNumberValidator";
import { validateEmail } from "lib/util/ValidatorUtil/emailValidator/emailValidator";
import IMAGES from "lib/assets/images";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import {
  selectEpicProfileState,
  selectPMGeneralErrors,
  selectPMSettingsValue,
} from "../PatientMaster/ducks/selectors";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import {
  setContactDetailsEmailError,
  setContactDetailsHomeNumberError,
  setContactDetailsMobileError,
  setContactDetailsSMSMobileError,
  setContactDetailsWorkNumberError,
  setEpicProfileTempState,
} from "../PatientMaster/ducks/pmSlice";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import MiniButton from "lib/components/buttons/MiniButton/MiniButton";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import IconTextButton from "lib/components/buttons/IconTextButton/IconTextButton";

const ContactDetails = ({
  contactDisclaimer = null,
  showContactRequiredText = true,
  contact,
  smsContact,
  showEmailRequiredText = false,
  email,
  homeNumber,
  workNumber,
  disabled = false,
  hasEpicProfile,
  handleContactChange,
  handleSMSContactChange,
  handleEmailChange,
  handleHomeNumberChange,
  handleWorkNumberChange,
  hasEditedPMA,
  PMAStates,
}: ContactDetailsProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const classes = sxStyles(theme);
  const navigate = useNavigate();

  // Redux states
  const {
    hasMobileErrored,
    hasSMSMobileErrored,
    hasEmailErrored,
    hasHomeNumberErrored,
    hasWorkNumberErrored,
  } = useAppSelector(selectPMGeneralErrors);
  const { disclaimers } = useAppSelector(selectPMSettingsValue);
  const epicProfileData = useAppSelector(selectEpicProfileState);

  // Local states
  const [isEmailQuestionClick, setIsEmailQuestionClick] = useState(false);
  const [showSMSReminder, setShowSMSReminder] = useState(!!smsContact);
  const [isSMSReminderModalOpen, setIsSMSReminderModalOpen] = useState(false);
  const [isPMAModalOpen, setIsPMAModalOpen] = useState(false);

  const isExpired =
    new Date().setHours(0, 0, 0, 0) >
    new Date(epicProfileData.MailingAddressExpiryDate as string).setHours(
      0,
      0,
      0,
      0,
    );
  const shouldShowPreferredMailingAdd = () => {
    if (hasEditedPMA && PMAStates) {
      if (
        PMAStates.PreferredPostalCode === "" ||
        !PMAStates.PreferredPostalCode
      ) {
        // When there is no postal code, it is assumed that there is no preferred address
        return false;
      } else {
        return true;
      }
    }
    // When there is no postal code, it is assumed that there is no preferred address
    if (!epicProfileData.PreferredPostalCode) {
      return false;
    }

    // When MailingAddressExpiryDate is null, it is never expired(Permanent address)
    if (!epicProfileData.MailingAddressExpiryDate) {
      return true;
    }

    if (isExpired) {
      return false;
    }

    return true;
  };

  const [showPreferredMailingAdd, setShowPreferredMailingAdd] = useState(
    shouldShowPreferredMailingAdd(),
  );

  const handleEmailDialogClick = () => {
    setIsEmailQuestionClick(!isEmailQuestionClick);
  };

  const toggleSMSReminderModalOpen = () => {
    setIsSMSReminderModalOpen(!isSMSReminderModalOpen);
  };

  const clearPMAData = () => {
    dispatch(
      setEpicProfileTempState({
        PreferredPostalCode: "",
        PreferredStreetName: "",
        PreferredBlockNumber: "",
        PreferredCombinedUnitNumber: "",
        PreferredUnitNumber: "",
        PreferredFloorNumber: "",
        MailingAddressExpiryDate: "",
      }),
    );
  };

  const togglePMAModalOpen = () => {
    setIsPMAModalOpen(!isPMAModalOpen);
  };

  const handleEditPMA = () => {
    navigate(PATHS.MY_PROFILE_PREFERRED_MAILING.path);
  };

  const validateMobileNumber = useCallback(() => {
    if (contact && contact !== "") {
      if (validatePhoneNumber(contact, true)) {
        dispatch(setContactDetailsMobileError(false));
      } else {
        dispatch(setContactDetailsMobileError(true));
      }
    } else {
      dispatch(setContactDetailsMobileError(true));
    }
  }, [contact, dispatch]);

  const validateSMSMobileNumber = useCallback(() => {
    if (smsContact === null || smsContact === "") {
      dispatch(setContactDetailsSMSMobileError(false));
    } else if (smsContact && smsContact !== "") {
      if (validatePhoneNumber(smsContact, true)) {
        dispatch(setContactDetailsSMSMobileError(false));
      } else {
        dispatch(setContactDetailsSMSMobileError(true));
      }
    } else {
      dispatch(setContactDetailsSMSMobileError(true));
    }
  }, [smsContact, dispatch]);

  const validateHomeNumber = useCallback(() => {
    if (homeNumber && homeNumber !== "") {
      if (validatePhoneNumber(homeNumber)) {
        dispatch(setContactDetailsHomeNumberError(false));
      } else {
        dispatch(setContactDetailsHomeNumberError(true));
      }
    } else {
      dispatch(setContactDetailsHomeNumberError(true));
    }
  }, [homeNumber, dispatch]);

  const validateWorkNumber = useCallback(() => {
    if (workNumber && workNumber !== "") {
      if (validatePhoneNumber(workNumber)) {
        dispatch(setContactDetailsWorkNumberError(false));
      } else {
        dispatch(setContactDetailsWorkNumberError(true));
      }
    } else {
      dispatch(setContactDetailsWorkNumberError(true));
    }
  }, [workNumber, dispatch]);

  // Mobile Number validation
  useEffect(() => {
    validateMobileNumber();
  }, [validateMobileNumber]);

  // SMS Mobile Number validation
  useEffect(() => {
    validateSMSMobileNumber();
  }, [validateSMSMobileNumber]);

  // Home Number validation
  useEffect(() => {
    validateHomeNumber();
  }, [validateHomeNumber]);

  // Home Number validation
  useEffect(() => {
    validateWorkNumber();
  }, [validateWorkNumber]);

  // Email validation
  useEffect(() => {
    if (email && email !== "") {
      if (validateEmail(email)) {
        dispatch(setContactDetailsEmailError(false));
      } else {
        dispatch(setContactDetailsEmailError(true));
      }
    } else {
      dispatch(setContactDetailsEmailError(false));
    }
  }, [dispatch, email]);

  // Home Number validation
  useEffect(() => {
    if (homeNumber && homeNumber !== "") {
      if (validatePhoneNumber(homeNumber)) {
        dispatch(setContactDetailsHomeNumberError(false));
      } else {
        dispatch(setContactDetailsHomeNumberError(true));
      }
    } else {
      dispatch(setContactDetailsHomeNumberError(false));
    }
  }, [dispatch, homeNumber]);

  // Work Number validation
  useEffect(() => {
    if (workNumber && workNumber !== "") {
      if (validatePhoneNumber(workNumber)) {
        dispatch(setContactDetailsWorkNumberError(false));
      } else {
        dispatch(setContactDetailsWorkNumberError(true));
      }
    } else {
      dispatch(setContactDetailsWorkNumberError(false));
    }
  }, [dispatch, workNumber]);

  return (
    <Box sx={classes.mainContainer}>
      <Box sx={{ ...classes.textFieldMainContainer, mt: 2 }}>
        <Box sx={classes.textField}>
          <SingleLineTextField
            name="Email Address"
            placeholder="Email Address (Recommended)"
            type="email"
            required={showEmailRequiredText}
            value={disabled ? email || "-" : email || ""}
            error={hasEmailErrored}
            errorText="Enter a valid email"
            handleChange={(event) => handleEmailChange(event.target.value)}
          />
        </Box>
        <Box sx={classes.iconContainer} onClick={handleEmailDialogClick}>
          <Box
            component="img"
            alt="email query"
            src={IMAGES.general.QuestionMark}
            sx={classes.queryIcon}
          />
        </Box>
      </Box>

      <Box sx={classes.textFieldMainContainer}>
        <Box sx={classes.textField}>
          <SingleLineTextField
            name="Mobile Number"
            placeholder="Mobile Number"
            type={disabled ? "text" : "number"}
            required={showContactRequiredText}
            maxCharLength={8}
            value={disabled ? contact || "-" : contact || ""}
            disabled={disabled}
            error={hasMobileErrored}
            errorText={
              contact === "" || !contact
                ? "*Required"
                : "Enter a valid mobile number"
            }
            isFromMyInfoAndProfile={true}
            handleChange={(event) => handleContactChange(event.target.value)}
          />
        </Box>
      </Box>

      {hasEpicProfile && (
        <>
          <Box sx={{ ...classes.textFieldMainContainer, mt: 2 }}>
            <Box sx={classes.textField}>
              <Typography sx={classes.textContent}>
                If you prefer to receive Appointment SMS reminders through a
                different number (optional):
              </Typography>
              {!showSMSReminder && (
                <Button
                  variant="text"
                  sx={classes.textButton}
                  onClick={() => {
                    setShowSMSReminder(true);
                    handleSMSContactChange("");
                  }}
                >
                  Add alternate mobile number
                </Button>
              )}
            </Box>
          </Box>
          {showSMSReminder && (
            <Box sx={{ ...classes.textFieldMainContainer, mt: 2 }}>
              <Box sx={classes.textField}>
                <SingleLineTextField
                  name="Mobile Number"
                  required={false}
                  placeholder="Alternate Mobile Number"
                  type={disabled ? "text" : "number"}
                  maxCharLength={8}
                  value={disabled ? smsContact || "-" : smsContact || ""}
                  disabled={disabled}
                  error={hasSMSMobileErrored}
                  errorText={
                    smsContact === "" || !smsContact
                      ? ""
                      : "Enter a valid mobile number"
                  }
                  isFromMyInfoAndProfile={true}
                  handleChange={(event) => {
                    handleSMSContactChange(event.target.value);
                  }}
                  // handleKeyDown={(event) => {
                  //   if (
                  //     event.key.toLowerCase() === "backspace" ||
                  //     event.key.toLowerCase() === "delete"
                  //   ) {
                  //     event.preventDefault();
                  //     handleSMSContactChange("");
                  //   }
                  // }}
                />
              </Box>
              <Box
                sx={classes.iconContainer}
                onClick={() => {
                  if (smsContact) {
                    toggleSMSReminderModalOpen();
                  }
                }}
              >
                <IconTextButton
                  icon={
                    smsContact
                      ? IMAGES.general.DeleteBlueIcon
                      : IMAGES.general.DeleteGreyIcon
                  }
                  label={["Delete"]}
                  ariaLabel={"delete alternate number"}
                  isDisabled={!smsContact || smsContact === ""}
                />
              </Box>
            </Box>
          )}{" "}
        </>
      )}

      <Box sx={classes.textFieldMainContainer}>
        <Box sx={classes.textField}>
          <SingleLineTextField
            name="Home Number"
            placeholder="Home Number"
            type={disabled ? "text" : "number"}
            required={false}
            maxCharLength={8}
            value={homeNumber}
            error={hasHomeNumberErrored}
            disabled={disabled}
            errorText="Enter a valid home number"
            handleChange={(event) => handleHomeNumberChange(event.target.value)}
          />
        </Box>
      </Box>

      <Box sx={classes.textFieldMainContainer}>
        <Box sx={classes.textField}>
          <SingleLineTextField
            name="Work Number"
            placeholder="Work Number"
            type={disabled ? "text" : "number"}
            required={false}
            maxCharLength={8}
            value={workNumber}
            error={hasWorkNumberErrored}
            errorText="Enter a valid work number"
            handleChange={(event) => handleWorkNumberChange(event.target.value)}
          />
        </Box>
      </Box>

      <Box sx={classes.textFieldMainContainer}>
        <Box sx={classes.textField}>
          <Typography sx={classes.preferredMailTitle}>
            Preferred Local Mailing Address
          </Typography>
          <Typography sx={classes.textContent}>
            If you prefer to receive mails from NUHS at a different address
            (optional):
          </Typography>

          {showPreferredMailingAdd === false ? (
            <Button
              variant="text"
              sx={classes.textButton}
              onClick={handleEditPMA}
            >
              Add preferred local mailing address
            </Button>
          ) : null}
        </Box>
      </Box>

      {showPreferredMailingAdd && (
        <Box sx={{ ...classes.textFieldMainContainer, mt: 1 }}>
          <Card sx={classes.preferredCard}>
            <Box sx={classes.preferredContentContainer}>
              <Box sx={classes.preferredMailBlock}>
                <Typography sx={classes.preferredMailLabel}>
                  Postal Code
                </Typography>
                <Typography sx={classes.preferredMailValue}>
                  {hasEditedPMA && PMAStates
                    ? PMAStates.PreferredPostalCode
                      ? PMAStates.PreferredPostalCode
                      : "-"
                    : epicProfileData.PreferredPostalCode
                      ? epicProfileData.PreferredPostalCode
                      : "-"}
                </Typography>
              </Box>

              <Box sx={classes.preferredMailBlock}>
                <Typography sx={classes.preferredMailLabel}>Street</Typography>
                <Typography sx={classes.preferredMailValue}>
                  {hasEditedPMA && PMAStates
                    ? PMAStates.PreferredStreetName
                      ? PMAStates.PreferredStreetName
                      : "-"
                    : epicProfileData.PreferredStreetName
                      ? epicProfileData.PreferredStreetName
                      : "-"}
                </Typography>
              </Box>

              <Box sx={classes.preferredMailBlock}>
                <Typography sx={classes.preferredMailLabel}>
                  No. / Blk
                </Typography>
                <Typography sx={classes.preferredMailValue}>
                  {hasEditedPMA && PMAStates
                    ? PMAStates.PreferredBlockNumber
                      ? PMAStates.PreferredBlockNumber
                      : "-"
                    : epicProfileData.PreferredBlockNumber
                      ? epicProfileData.PreferredBlockNumber
                      : "-"}
                </Typography>
              </Box>

              <Box sx={classes.preferredMailBlock}>
                <Typography sx={classes.preferredMailLabel}>Unit</Typography>
                <Typography sx={classes.preferredMailValue}>
                  {hasEditedPMA && PMAStates
                    ? PMAStates.PreferredCombinedUnitNumber
                      ? PMAStates.PreferredCombinedUnitNumber
                      : "-"
                    : epicProfileData.PreferredUnitNumber
                      ? epicProfileData.PreferredUnitNumber
                      : "-"}
                </Typography>
              </Box>

              <Box sx={classes.preferredMailBlock}>
                <Typography sx={classes.preferredMailLabel}>
                  Valid Till
                </Typography>
                <Typography sx={classes.preferredMailValue}>
                  {hasEditedPMA && PMAStates
                    ? PMAStates.MailingAddressExpiryDate
                      ? formatDate(
                          PMAStates.MailingAddressExpiryDate,
                          "d MMM yyyy",
                        )
                      : "-"
                    : epicProfileData.MailingAddressExpiryDate
                      ? formatDate(
                          epicProfileData.MailingAddressExpiryDate,
                          "d MMM yyyy",
                        )
                      : "-"}
                </Typography>
              </Box>
              <MiniButton
                onClick={handleEditPMA}
                icon={IMAGES.general.EditBlueIcon}
              >
                Edit
              </MiniButton>
            </Box>
          </Card>

          <Box sx={classes.iconContainer}>
            <IconTextButton
              onClick={togglePMAModalOpen}
              icon={IMAGES.general.DeleteBlueIcon}
              label={["Delete"]}
              ariaLabel={"delete preferred local mailing address"}
            />
          </Box>
        </Box>
      )}

      {/* "Why you should provide your email address" modal */}
      <ConfirmationModal
        title={disclaimers.emailAddress.title}
        body={MapRawStringToReactElement(
          disclaimers.emailAddress.description ?? "",
        )}
        open={isEmailQuestionClick}
        nextButtonText={"OK"}
        onClickNext={handleEmailDialogClick}
        hideCancelButton={true}
      />

      <ConfirmationModal
        title={"Delete Alternate Mobile Number?"}
        body={"Appointment SMS reminders will be sent to your Mobile Number."}
        nextButtonText={"Delete"}
        cancelButtonText={"Cancel"}
        open={isSMSReminderModalOpen}
        onClickNext={() => {
          setShowSMSReminder(false);
          handleSMSContactChange("");
          validateSMSMobileNumber();
          toggleSMSReminderModalOpen();
        }}
        onClickCancel={toggleSMSReminderModalOpen}
      />

      <ConfirmationModal
        title={"Delete Preferred Local Mailing Address?"}
        body={"Mails from NUHS will be sent to your registered address."}
        nextButtonText={"Delete"}
        cancelButtonText={"Cancel"}
        open={isPMAModalOpen}
        onClickNext={() => {
          togglePMAModalOpen();
          clearPMAData();
          setShowPreferredMailingAdd(false);
        }}
        onClickCancel={togglePMAModalOpen}
      />
    </Box>
  );
};

export default ContactDetails;
