import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = ({ categoryCode }: { categoryCode: string | null }) => {
  return {
    contentBox: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      m: 2,

      "& .programcontent": {
        color: theme.palette.grey[400],
        typography: theme.typography.body2_regular,
        "& p": {
          marginTop: theme.spacing(0),
        },

        "& h5": {
          color: theme.palette.grey[400],
          typography: theme.typography.body2_semibold,
          margin: theme.spacing(0),
        },
      },
    },
    linksBox: {
      display: "flex",
      flexDirection: "column",
      gap: 1.5,
    },
  } satisfies SxObjType;
};

export { sxStyles };
