import axios from "axios";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { formatGetAddressByPostalCodeErrorMessage } from "api/resources/messages/ErrorMessage";
import {
  GetAddressByPostalCodePayload,
  AddressResult,
} from "./getAddressByPostalCode.types";

/**
 * Fetches the Address according to postal code
 *
 * POST
 * Endpoint: /developers.onemap.sg/commonapi
 *
 * @param {string} postalCode
 */
const getAddressByPostalCode = async (
  postalCode: string | null,
): Promise<AddressResult> => {
  const GET_ADDRESS_BY_POSTAL_CODE_URL = `https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${postalCode}&returnGeom=Y&getAddrDetails=Y&pageNum=1`;
  try {
    const response = await axios.get<GetAddressByPostalCodePayload>(
      GET_ADDRESS_BY_POSTAL_CODE_URL,
    );
    const responsePayload = parseResponse(
      response,
      GET_ADDRESS_BY_POSTAL_CODE_URL,
    );
    if (responsePayload.found) {
      return {
        ...responsePayload.results[0],
        HAS_VALID_ADDRESS: true,
        HAS_ERRORED: false,
      };
    } else {
      return {
        BLK_NO: null,
        ROAD_NAME: null,
        HAS_VALID_ADDRESS: false,
        HAS_ERRORED: false,
      };
    }
  } catch (error) {
    const msg = formatGetAddressByPostalCodeErrorMessage(
      GET_ADDRESS_BY_POSTAL_CODE_URL,
    );
    console.error(msg);
    return {
      BLK_NO: null,
      ROAD_NAME: null,
      HAS_VALID_ADDRESS: false,
      HAS_ERRORED: true,
    };
  }
};

export { getAddressByPostalCode };
