import { connect } from "react-redux";
import { RootState } from "lib/redux/root/redux.types";
import AvailableSlotsList from "./AvailableSlotsList";

const mapStateToProps = (state: RootState) => {
  return {
    currentFlowAction: state.navigation.currentFlowAction,
  };
};

export default connect(mapStateToProps)(AvailableSlotsList);
