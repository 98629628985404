import { Box, Typography } from "@mui/material";
import IconLink from "lib/components/links/IconLink/IconLink";
import { autopayUrl } from "lib/configs/configs/config";
import { navigateToExternalInMobileBrowser } from "lib/routing/navigate/navigate";
import { sxStyles } from "./OtherEnquiriesPage.styles";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";

const AUTOPAY_TITLE = "Sign up for Auto-Pay";
const HOSPITAL_BILL_TITLE = "Send a hospital bill enquiry";
const POLYCLINIC_BILL_TITLE = "Send a polyclinic bill enquiry";
const HARD_COPY_TITLE = "Request for hard-copy bills";

const AUTOPAY_BODY =
  "Auto-Pay (formerly known as ZOOM) offers you the convenience of paying your bills without the hassle of waiting in queue after your visit. The charges are automatically credited to your pre-authorised credit card by the next working day. ";

const HOSPITAL_BILL_URL = "https://for.sg/asknuhs";
const HARD_COPY_URL = "https://for.sg/nuhsbill";

const OtherEnquiriesPage = () => {
  const classes = sxStyles();

  const handleAutoPayOnClick = () => {
    logEventToGoogleAnalytics(EVENTS.CLICK_SIGN_UP_AUTO_PAY);
    navigateToExternalInMobileBrowser(autopayUrl(), false);
  };

  const handleHospitalBillOnClick = () => {
    logEventToGoogleAnalytics(EVENTS.CLICK_SEND_HOSPITAL_BILL_ENQUIRY);
    navigateToExternalInMobileBrowser(HOSPITAL_BILL_URL);
  };

  const handleHardcopyOnClick = () => {
    logEventToGoogleAnalytics(EVENTS.CLICK_REQUEST_HARD_COPY_BILLS);
    navigateToExternalInMobileBrowser(HARD_COPY_URL);
  };

  const handlePolyclicnicBillOnClick = () => {
    logEventToGoogleAnalytics(EVENTS.CLICK_SEND_POLYCLINIC_BILL_ENQUIRY);
  };

  return (
    <Box sx={classes.mainContainer}>
      <Box>
        <IconLink title={AUTOPAY_TITLE} onClick={handleAutoPayOnClick} />
        <Typography sx={classes.linkBody}>{AUTOPAY_BODY}</Typography>
      </Box>

      <IconLink
        title={HOSPITAL_BILL_TITLE}
        onClick={handleHospitalBillOnClick}
      />

      <IconLink
        title={POLYCLINIC_BILL_TITLE}
        email={"finance_ar@nuhs.edu.sg"}
        onClick={handlePolyclicnicBillOnClick}
      />

      <IconLink title={HARD_COPY_TITLE} onClick={handleHardcopyOnClick} />
    </Box>
  );
};

export default OtherEnquiriesPage;
