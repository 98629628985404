import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";
import { RootState } from "lib/redux/root/redux.types";
import {
  updateCheckInSuccessState,
  updateQueueForActualiseAppointment,
} from "ui/appointment/ducks/appointmentsSlice";
import KioskQuestions from "./KioskQuestionnairePage";
import { handleErrorModal } from "lib/redux/notifications";

import { OwnProps } from "./KioskQuestionnairePage.types";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const apptId = state.appointments.appointmentMeta.appointmentId || "";

  const apptDetail = state.appointments.register[apptId];
  return {
    memberIdentifier: state.user.memberIdentifier,
    appointmentId: state.appointments.appointmentMeta.appointmentId,
    regInstitutionCode: state.appointments.appointmentMeta.regInstitutionCode,
    institutionCode: state.appointments.appointmentMeta.institutionCode,
    targetSystem: state.appointments.appointmentMeta.targetSystem,
    clinicCode: state.appointments.appointmentMeta.clinicCode,
    actualizeStatus: state.appointments.appointmentMeta.actualizeStatus,
    institutionName: state.appointments.appointmentMeta.institutionName,
    queueNumber: apptDetail?.queueNumber || null,
    consultType: state.appointments.appointmentMeta.consultType,
    visitType: state.appointments.appointmentMeta.visitType,
    regAppointmentId: state.appointments.appointmentMeta.regAppointmentId,
    isCheckIn: ownProps.isCheckIn,
    checkInClinicCode: apptDetail?.currentClinicCode || null,
    encounterId: apptDetail?.encounterId || null,
    currentAppointmentTime:
      state.appointments.appointmentMeta.datetimeString ||
      apptDetail?.appointmentTimeToArrive,
    vcSupportStatus: state.appointments.appointmentMeta.vcSupportStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onActualisedAppointment: (
      appointmentId: string,
      queueNumber: string,
      institutionName: string | null,
      consultType: string | null,
      encounterId: string | null,
      appointmentTime: string | null,
    ) => {
      dispatch(
        updateQueueForActualiseAppointment({
          appointmentId,
          queueNumber,
          institutionName,
          consultType,
          encounterId,
          appointmentTime,
        }),
      );
    },
    handleErrorModal: (open: boolean, message: string | null) => {
      dispatch(handleErrorModal({ open, message }));
    },
    handleSuccessModal: (
      checkInSuccessStatus: boolean,
      checkInSuccessTitleIcon: "checkIcon" | "errorIcon" | null,
      title: string | null,
      message: string | null,
    ) => {
      dispatch(
        updateCheckInSuccessState({
          checkInSuccessStatus,
          checkInSuccessTitleIcon,
          title,
          message,
        }),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KioskQuestions);
