import { color } from "lib/theme/color";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    journeyCardActiveBox: {
      // border: `1px solid ${theme.palette.primary.main}`,
    },
    journeyCardNonActiveBox: {
      // border: `1px solid ${theme.palette.grey[100]}`,
    },
    journeyCardBox: {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "0px 0px 4px " + color.RGBA_0,
      display: "flex",
      marginBottom: 1,
    },
    journeyActiveNumberBox: {
      backgroundColor: theme.palette.warning.main,
      display: "flex",
      justifyContent: "center",
      width: theme.spacing(5),
    },
    journeyNonActiveNumberBox: {
      backgroundColor: theme.palette.grey[100],
      display: "flex",
      justifyContent: "center",
      width: theme.spacing(5),
    },
    journeyNumber: {
      color: theme.palette.common.white,
      fontSize: 23,
      fontWeight: theme.typography.fontWeightMedium,
      marginTop: theme.spacing(1),
    },
    journeyTextBox: {
      margin: theme.spacing(1),
      width: "100%",
      padding: theme.spacing(0.5, 0, 1),
    },
    journeyCardActiveText: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
    },
    journeyCardActiveDateTime: {
      color: theme.palette.text.primary,
      typography: theme.typography.caption1_semibold,
      marginTop: theme.spacing(1),
      width: "90%",
      textAlign: "left",
    },
    journeyCardActiveStatus: {
      color: theme.palette.text.primary,
      typography: theme.typography.caption1_semibold,
      marginTop: theme.spacing(1),
      width: "40%",
      textAlign: "right",
    },
    contrastText: {
      color: theme.palette.error.main,
      typography: theme.typography.caption1_regular,
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      paddingTop: theme.spacing(1),
      margin: theme.spacing(2, 1, 0, 0),
    },
  } satisfies SxObjType;
};
export { sxStyles };
