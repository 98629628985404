import { PastReportCardProps } from "./PastReportCard.types";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { sxStyles } from "./PastReportCard.styles";
import { PATHS } from "lib/routing";
import { format } from "date-fns";

import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import IMAGES from "lib/assets/images";
import {
  formatDate,
  formatOptions,
} from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

const PastReportCard = ({
  pastReport,
  index,
  onClickPastReport,
}: PastReportCardProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();

  const ReportDateTimeFromString = getDateFromISOString(
    pastReport.ReportDateTime,
  );

  return (
    <Card
      key={pastReport.ReferenceNo}
      sx={classes.card}
      onClick={() => {
        // Update Redux state so that detail page can read all details and populate appropriately
        onClickPastReport(index);

        // Navigate to detail page
        navigate(PATHS.MRRP_PAST_REPORT_DETAIL.path);
      }}
    >
      <CardHeader
        title={
          ReportDateTimeFromString
            ? format(ReportDateTimeFromString, formatOptions.month)
            : null
        }
        subheader={
          ReportDateTimeFromString
            ? format(ReportDateTimeFromString, formatOptions.day)
            : null
        }
        // sx={{
        //   root: classes.cardDate,
        //   title: classes.cardMY,
        //   subheader: (classes.cardDay, classes.cardDayHighlight),
        // }}
        sx={classes.cardHeader}
        action={
          ReportDateTimeFromString
            ? formatDate(formatDateToISO(ReportDateTimeFromString), "yyyy")
            : null
        }
      />
      <CardContent sx={classes.cardDetailWrapper}>
        <Box sx={classes.cardDetails}>
          <Typography sx={classes.cardTitle}>
            {pastReport.ReportName}
          </Typography>

          <Typography sx={classes.cardContent}>
            {pastReport.Hospital}
          </Typography>

          <Typography sx={classes.cardLabel}>{pastReport.Status}</Typography>
        </Box>
      </CardContent>
      <CardActions disableSpacing>
        <Box component={"img"} src={IMAGES.general.ArrowForward} />
      </CardActions>
    </Card>
  );
};

export default PastReportCard;
