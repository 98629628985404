import { Box } from "@mui/material";
import { JourneyCardListProps } from "./JourneyCardList.types";
import { sxStyles } from "./JourneyCardList.styles";
import JourneyCard from "../JourneyCard/JourneyCard";

const JourneyCardList = ({ journeyList }: JourneyCardListProps) => {
  const classes = sxStyles();

  if (journeyList && journeyList.length >= 1) {
    return (
      <Box sx={classes.journeyDataRegionBox}>
        {journeyList.map((item, index) => {
          return (
            <Box sx={{ mb: 2 }} key={item.Station}>
              <JourneyCard
                isActive={item?.IsActive}
                status={item.StationStatus}
                journeyNumber={index + 1}
                locationTitle={item.Station}
                dateTime={item?.AppointmentTime}
                contrastText={item?.StationMsg}
              />
            </Box>
          );
        })}
      </Box>
    );
  } else {
    return null;
  }
};

export default JourneyCardList;
