import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    barRoot: {
      padding: "0 0.5rem",
    },

    contentMessage: {
      padding: "0.1rem 0",
      alignItems: "center",
    },
    icon: {
      fontSize: size.TOAST_ICON,
      fontWeight: theme.typography.fontWeightBold,
      marginRight: "1rem",
    },
  } satisfies SxObjType;
};

export { sxStyles };
