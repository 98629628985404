import { color } from "lib/theme/color";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    sectionRegion: {
      backgroundColor: theme.palette.common.white,
      boxShadow: "0px 0px 6px " + color.RGBA_52,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1.5),
      margin: theme.spacing(2, 0, 0),
      wordBreak: "break-word",
    },
    editIconButton: {
      padding: theme.spacing(1.5),
      "& svg": {
        fontSize: 25,
        color: theme.palette.primary.main,
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
