import { Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { sxStyles } from "./OtherProvidersSection.styles";
import toTitleCase from "lib/util/StringUtil/toTitleCase/toTitleCase";

const OTHER_PROVIDERS_TITLE = "From other providers";
/**
 * This component will not render anything if there are no children
 * @param children
 * @returns ReactNode or null
 */
const OtherProvidersSection = ({
  children,
}: {
  children?: ReactNode | null;
}) => {
  const classes = sxStyles();
  return children ? (
    <>
      <Typography sx={classes.otherProvidersTitle}>
        {toTitleCase(OTHER_PROVIDERS_TITLE)}
      </Typography>
      {children}
    </>
  ) : null;
};

export default OtherProvidersSection;
