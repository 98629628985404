import { FormControl, RadioGroup, Box, Typography } from "@mui/material";
import { MammogramQuestionnaireProps } from "./MammogramQuestionnaire.types";
import { sxStyles } from "./MammogramQuestionnaire.styles";
import RadioButton from "lib/components/formInputs/FullRadioButton/FullRadioButton";
import parser from "html-react-parser";

const MammogramQuestionnaire = ({
  questionId,
  questionText,
  questionAdditional,
  questionAns,
  handleChange,
}: MammogramQuestionnaireProps) => {
  const classes = sxStyles();
  let index = questionId - 1;

  return (
    <FormControl component="fieldset" fullWidth>
      <Box sx={classes.formSameLine}>
        <Typography sx={classes.questionNo}>{questionId + "."}</Typography>
        <Box sx={classes.fullWidth}>
          <Typography sx={classes.questionBody}>{questionText}</Typography>
        </Box>
      </Box>
      <Box pl={0}>
        <RadioGroup
          name={"q" + index}
          value={questionAns}
          onChange={handleChange}
        >
          <Box sx={classes.radioButtons}>
            <Box display="flex" justifyContent="space-around" margin="auto">
              {["Yes", "No"].map((choice) => (
                <RadioButton
                  key={choice}
                  checked={questionAns === choice}
                  label={choice}
                  value={choice}
                />
              ))}
            </Box>
          </Box>

          {questionAdditional && questionAns === "Yes" && (
            <Box sx={classes.infoBox}>
              <Typography sx={classes.infoText}>
                {parser(questionAdditional)}
              </Typography>
            </Box>
          )}
        </RadioGroup>
      </Box>
    </FormControl>
  );
};

export default MammogramQuestionnaire;
