import React from "react";
import { Box } from "@mui/material";
import QuantityContainer from "../../refill/quantity/Quantity/QuantityContainer";

const EditQuantityPage = () => {
  return (
    <Box mt={-9}>
      <QuantityContainer isEditablePage={true} />
    </Box>
  );
};

export default EditQuantityPage;
