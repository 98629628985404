import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    formTitle: {
      color: theme.palette.text.primary,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    formLabel: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      margin: theme.spacing(1, 0, 0, 1),
    },
    textBox: {
      margin: theme.spacing(2, 0, 2),
      "& .MuiInputBase-root": {
        borderRadius: theme.spacing(0.5),
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.grey[100]}`,
        },
      },
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
      "& .MuiInputLabel-animated": {
        fontSize: size.FONTSIZE_16,
        color: theme.palette.primary.main,
      },
    },
    singlePharmacyLabel: {
      display: "flex",
      width: "100%",
    },
    formContent: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      marginRight: theme.spacing(1),
    },
  } satisfies SxObjType;
};

export { sxStyles };
