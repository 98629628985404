import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { firebaseConfigValues } from "lib/configs/configs/config";

/**
 * This function configures Firebase Analytics and returns references to the initialized Firebase app
 * and analytics service.
 * @returns An object with two properties: `app` and `analytics`, which are references to the
 * initialized Firebase app and analytics service, respectively.
 */
export const configureFirebaseAnalytics = () => {
  const firebaseConfig = firebaseConfigValues();
  // Initialize Firebase and get a reference to the sdk
  const app = initializeApp(firebaseConfig);
  // Initialize Analytics and get a reference to the service
  const analytics = getAnalytics(app);

  return { app, analytics };
};
