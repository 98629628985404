import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests/RequestOptions";
import { formatGetAppointmentStatusErrorMessage } from "../../resources/messages/ErrorMessage";
import { GET_APPOINTMENT_STATUS_URL } from "../../resources/configs/URL";
import { GetAppointmentStatusPayload } from "./getAppointmentStatus.fromApi.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";
import { RelationType } from "lib/redux/user/user.types";

/**
 * Fetches the appointment status of a particular patient.
 *
 * POST
 * Endpoint: /appointment/GetAppointmentStatus
 */

export interface appointmentStatusReq {
  memberIdentifier: string | null;
  relationType: RelationType;
  institutionCode: string | null;
  targetSystem: string | null;
  enrolmentDate: string | null;
  isHpCompleted: boolean | null;
  subsequentStartDate: string | null;
  healthPlanCompletionDate: string | null;
}

const getAppointmentStatus = async ({
  memberIdentifier,
  relationType,
  institutionCode,
  targetSystem,
  enrolmentDate,
  isHpCompleted,
  subsequentStartDate,
  healthPlanCompletionDate,
}: appointmentStatusReq): Promise<GetAppointmentStatusPayload> => {
  try {
    const response = await axios.post<GetAppointmentStatusPayload>(
      GET_APPOINTMENT_STATUS_URL(),
      createRequestPayload({
        memberIdentifier,
        relationType,
        institutionCode,
        targetSystem,
        enrolmentDate,
        isHpCompleted,
        subsequentStartDate,
        healthPlanCompletionDate,
      }),
      getRequestOptions(),
    );
    return parseResponse(response, GET_APPOINTMENT_STATUS_URL());
  } catch (error) {
    const msg = formatGetAppointmentStatusErrorMessage(
      GET_APPOINTMENT_STATUS_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = ({
  memberIdentifier,
  relationType,
  institutionCode,
  targetSystem,
  enrolmentDate,
  isHpCompleted,
  subsequentStartDate,
  healthPlanCompletionDate,
}: appointmentStatusReq) => {
  return {
    MemberIdentifier: memberIdentifier,
    RelationType: relationType,
    TargetSystem: targetSystem,
    EnrolmentDate: enrolmentDate,
    InstitutionCode: institutionCode,
    IsHpCompleted: isHpCompleted,
    SubsequentStartDate: subsequentStartDate,
    HealthPlanCompletionDate: healthPlanCompletionDate,
  };
};

export { getAppointmentStatus };
