import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests/RequestOptions";
import { formatGetAppointmentListErrorMessage } from "../../resources/messages/ErrorMessage";
import { GET_APPOINTMENT_LIST_URL } from "../../resources/configs/URL";
import { GetAppointmentListPayload } from "./getAppointmentList.fromApi.types";
import { AppointmentsLookUpAndListing } from "./getAppointmentList.toUi.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";
import { mapApiDataToProps } from "./mappers/mapper";
import { RelationType } from "lib/redux/user/user.types";

/**
 * Retrieves the appointment list
 *
 * POST
 * Endpoint: /GetAppointmentList
 *
 * @param {string | null} memberIdentifier  Member Identifier of the person we are making this request for
 * @param {RelationType} relationType relationship between patient and logged in user
 *
 * @returns {boolean, string, AppointmentsLookUpAndListing}
 *   0 - True if API encounters error, false otherwise.
 *
 *   1 - Message returned from API
 *
 *   2 - Mapped response structure for response payload from the remote API
 */
const getAppointmentList = async (
  memberIdentifier: string | null,
  relationType: RelationType,
): Promise<{
  message: string | null;
  payload: AppointmentsLookUpAndListing;
  teleconsultMessage: string | null;
}> => {
  try {
    const response = await axios.post<GetAppointmentListPayload>(
      GET_APPOINTMENT_LIST_URL(),
      createRequestPayload(memberIdentifier, relationType),
      getRequestOptions(),
    );
    const parsedResponse = parseResponse(response, GET_APPOINTMENT_LIST_URL());

    return {
      //frontend needs to render the message when error occurs or souce system encounter issue to provide the completed list of appointments
      message: parsedResponse.Message,
      payload: mapApiDataToProps(parsedResponse),
      teleconsultMessage: parsedResponse.TeleconsultMessage || "",
    };
  } catch (error) {
    const msg = formatGetAppointmentListErrorMessage(
      GET_APPOINTMENT_LIST_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  memberIdentifier: string | null,
  relationType: RelationType,
) => {
  return {
    MemberIdentifier: memberIdentifier,
    RelationType: relationType,
  };
};

export { getAppointmentList };
