import { Box, CardHeader, Skeleton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectUser } from "lib/redux/user/selectors";
import { PATHS } from "lib/routing";
import { useNavigate } from "react-router-dom";
import { setIsCreateFromAppointmentLanding } from "ui/appointment/ducks";
import { sxStyles } from "./UserProfile.styles";
import { UserProfileProps } from "./UserProfile.types";
import IconLink from "lib/components/links/IconLink/IconLink";

const UserProfile = ({
  showMakeAppointmentButton = true,
}: UserProfileProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();
  const dispatch = useAppDispatch();

  // redux states
  const { patientName: name, isLoading } = useAppSelector(selectUser);

  return (
    <Box sx={classes.profileHeader}>
      <CardHeader
        avatar={<></>}
        title={
          isLoading ? (
            <Skeleton variant="text" animation="wave" width="60%" />
          ) : name ? (
            name + "'s Appointments"
          ) : null
        }
        sx={classes.root}
        titleTypographyProps={{ noWrap: false }}
      />

      {showMakeAppointmentButton && (
        <Box sx={classes.makeNewAppointment}>
          <IconLink
            title={"New"}
            iconPosition="start"
            icon="addCircleOutline"
            onClick={() => {
              dispatch(setIsCreateFromAppointmentLanding(true));
              navigate(PATHS.APPOINTMENT_CREATE_INSTITUTIONS.path);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default UserProfile;
