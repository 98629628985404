import React from "react";
import { AllBalanceForSelectedDurationProps } from "./AllBalanceForSelectedDuration.types";
import { Typography } from "@mui/material";
import { sxStyles } from "./AllBalanceForSelectedDuration.styles";
import NumberAndUomDurationFields from "../NumberAndUomDurationFields/NumberAndUomDurationFields";

const AllBalanceForSelectedDuration = ({
  number,
  durationId,
  handleNumberChange,
  handleDurationIdChange,
  durationChoice,
  numberValidationMsg,
}: AllBalanceForSelectedDurationProps) => {
  const classes = sxStyles();

  return (
    <>
      <Typography sx={classes.title}>
        All my balance for a selected duration
      </Typography>
      <NumberAndUomDurationFields
        numberPlaceholder="Number"
        number={number}
        uomDurationPlaceholder="Duration"
        uomDurationId={durationId}
        handleNumberChange={handleNumberChange}
        handleUomDurationIdChange={handleDurationIdChange}
        durationList={durationChoice}
        uomList={[]}
        numberValidationMsg={numberValidationMsg}
      ></NumberAndUomDurationFields>
    </>
  );
};

export default AllBalanceForSelectedDuration;
