import { RootState, RootDispatch } from "lib/redux/root/redux.types";
import { connect } from "react-redux";
import { OwnProps } from "./VirtualConsultEntryPointPage.types";
import {
  updateFacility,
  fetchSystemSettings,
  fetchMedications,
  fetchClinics,
} from "ui/medrefill/ducks";
import {
  fetchCollectionPaymentDetails,
  fetchDocumentByCluster,
} from "ui/medrefill/ducks/thunks";
import {
  clearState,
  updateRequestType,
  setSystemSettingsHasErrored,
  setMedicationsHasErrored,
  setCollectionPaymentDetailsHasErrored,
  setClinicsHasErrored,
  setDocumentByClusterHasErrored,
} from "ui/medrefill/ducks/medrefillSlice";
import { setUserProfileHasErrored, fetchUserConsent } from "lib/redux/user";
import VirtualConsultEntryPointPage from "./VirtualConsultEntryPointPage";
import { PRESCRIBED_MEDICATION_CONSENT_CODE } from "api/shared/ValidateConsent/validateConsent.code";

const mapStateToProps = (state: RootState) => {
  // const id = state.appointments.appointmentMeta.appointmentId || "";
  return {
    relationType: state.user.relationType, //returns "F' for family or friends, "C" for child, or null if no relationship

    institutionCode:
      state.appointments.appointmentMeta.institutionCode === "PIO" ||
      state.appointments.appointmentMeta.institutionCode === "BBK" ||
      state.appointments.appointmentMeta.institutionCode === "BPJ" ||
      state.appointments.appointmentMeta.institutionCode === "CCK" ||
      state.appointments.appointmentMeta.institutionCode === "CLM" ||
      state.appointments.appointmentMeta.institutionCode === "JUR" ||
      state.appointments.appointmentMeta.institutionCode === "QT"
        ? "NUP"
        : state.appointments.appointmentMeta.institutionCode === "ALEX"
          ? "AH"
          : state.appointments.appointmentMeta.institutionCode,

    institutionName: state.appointments.appointmentMeta.institutionName,

    isLoadingFormData:
      state.medRefill.systemSettings.isLoading ||
      state.medRefill.medications.isLoading ||
      state.medRefill.collectionAndPayments.isLoading ||
      state.medRefill.clinics.isLoading ||
      state.medRefill.documentByCluster.isLoading ||
      state.user.consent.isLoading,

    hasErroredSystemSettings: state.medRefill.systemSettings.hasErrored,
    errorMessageSystemSettings: state.medRefill.systemSettings.errorMessage,

    hasErroredMedications: state.medRefill.medications.hasErrored,
    errorMessageMedications: state.medRefill.medications.errorMessage,

    hasErroredCollectionPaymentDetails:
      state.medRefill.collectionAndPayments.hasErrored,
    errorMessageCollectionPaymentDetails:
      state.medRefill.collectionAndPayments.errorMessage,

    hasErroredClinics: state.medRefill.clinics.hasErrored,
    errorMessageClinics: state.medRefill.clinics.errorMessage,

    hasErroredDocByCluster: state.medRefill.documentByCluster.hasErrored,
    errorMessageDocByCluster: state.medRefill.documentByCluster.errorMessage,
  };
};

const mapDispatchToProps = (dispatch: RootDispatch, ownProps: OwnProps) => {
  return {
    onLoad: () => {
      dispatch(clearState());
      dispatch(setSystemSettingsHasErrored(null));
      dispatch(setMedicationsHasErrored(null));
      dispatch(setCollectionPaymentDetailsHasErrored(null));
      dispatch(setClinicsHasErrored(null));
      dispatch(setDocumentByClusterHasErrored(null));
      dispatch(setUserProfileHasErrored(null));
      dispatch(updateRequestType(ownProps.prescriptionType));
    },
    onFetchingPrescribedMedicationConsent: () => {
      dispatch(fetchUserConsent(PRESCRIBED_MEDICATION_CONSENT_CODE));
    },
    onSelect: (facilityId: number, facilityName: string, cluster: string) => {
      dispatch(updateFacility({ facilityId, facilityName, cluster }));
      dispatch(fetchSystemSettings(cluster));
      dispatch(fetchMedications(cluster));
      dispatch(fetchCollectionPaymentDetails(cluster, facilityId));
      dispatch(fetchClinics(facilityId));
      dispatch(fetchDocumentByCluster(cluster));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VirtualConsultEntryPointPage);
