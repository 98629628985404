import { color } from "lib/theme/color";
import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    card: {
      boxShadow: "0px 0px 6px " + color.RGBA_52,
      margin: theme.spacing(3, 1, 1),
    },
    cardHeader: {
      display: "flex",
      background: theme.palette.secondary.main,
      padding: theme.spacing(1.75, 2),
    },
    cardMainTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      width: "100%",
    },
    cardAmountValue: {
      color: theme.palette.warning.main,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      width: "100%",
      textAlign: "right",
    },
    cardDetailWrapper: {
      padding: 0,
      "&:last-child": {
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
        padding: theme.spacing(1, 0),
        marginBottom: theme.spacing(1),
      },
    },
    cardHeadDetails: {
      minHeight: "100%",
      wordBreak: "break-word",
      margin: theme.spacing(0, 2),
      display: "flex",
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
      paddingBottom: theme.spacing(1),
      alignItems: "center",
    },
    cardDetails: {
      minHeight: "100%",
      wordBreak: "break-word",
      padding: theme.spacing(0, 2),
      display: "flex",
      "& first-child": {
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
      },
      alignItems: "center",
    },

    cardLeft: {
      width: "40%",
    },
    cardRight: {
      textAlign: "right",
      color: theme.palette.common.black,
      typography: theme.typography.body2_semibold,
      width: "60%",
    },
    cardTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      padding: theme.spacing(0.75, 0, 0),
    },

    cardLabel: {
      color: theme.palette.grey[600],
      typography: theme.typography.caption1_semibold,
      padding: theme.spacing(0.75, 0, 0),
    },
    cardDate: {
      color: theme.palette.warning.main,
      typography: theme.typography.body2_semibold,
    },

    cardFooter: {
      minHeight: "100%",
      wordBreak: "break-word",
      margin: theme.spacing(1.5, 2),
      paddingTop: theme.spacing(1),
      display: "flex",
      borderTop: "1px solid " + theme.palette.grey[100],
      marginTop: theme.spacing(1),
      alignItems: "center",
    },

    cardButtons: {
      padding: theme.spacing(2.5, 1),
    },
    fullButton: {
      display: "block",
      width: "100%",
    },
    cardValueBox: {
      color: theme.palette.warning.main,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      display: "flex",
      alignItems: "center",
      width: "150px",
      marginTop: theme.spacing(1),
      float: "right",
      "& .MuiOutlinedInput-root": {
        borderRadius: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5),
      },
      "& .MuiOutlinedInput-input": {
        color: theme.palette.warning.main,
        fontSize: size.TITLE_FONTSIZE,
        fontWeight: theme.typography.fontWeightMedium,
        textAlign: "right",
      },
      "& div ": {
        "& div:nth-child(2)": {
          position: "absolute",
          right: theme.spacing(1),
        },
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
