import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = (shouldRenderStepHeader: boolean) => {
  return {
    formSection: () => ({
      marginTop: shouldRenderStepHeader ? theme.spacing(10.75) : 0,
    }),
  } satisfies SxObjType;
};

export { sxStyles };
