import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests";
import { GET_CLINICS_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { formatGetClinicsErrorMessage } from "api/resources/messages/ErrorMessage";
import { GetClinicsPayload } from "./getClinics.fromApi.types";

/**
 * Fetches the Clinics for a certain institution cluster
 *
 * POST
 * Endpoint: /GetClinics
 *
 * @param {number} facilityId a specific code to identify a group of Clinics
 */
const getClinics = async (
  facilityId: number,
  oldTokenizedId: string | null,
): Promise<GetClinicsPayload> => {
  try {
    const response = await axios.post<GetClinicsPayload>(
      GET_CLINICS_URL(),
      createRequestPayload(facilityId, oldTokenizedId),
      getRequestOptions(),
    );
    return parseResponse(response, GET_CLINICS_URL());
  } catch (error) {
    const msg = formatGetClinicsErrorMessage(GET_CLINICS_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  facilityId: number,
  oldTokenizedId: string | null,
) => {
  return {
    FacilityId: facilityId,
    OldTokenizedId: oldTokenizedId,
  };
};

export { getClinics };
