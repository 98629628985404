import { Box, CircularProgress } from "@mui/material";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import { ACTIONS, PATHS } from "lib/routing";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LinkedApptsEntryPointPageProps } from "./LinkedApptsEntryPointPage.types";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import {
  selectAppointmentMeta,
  selectAppointments,
} from "ui/appointment/ducks/selectors";

import {
  fetchAppointments,
  fetchSystemSettings,
} from "ui/appointment/ducks/thunks";
import { updateSelectedLinkedAppointment } from "ui/appointment/ducks";
import { setCustomisedBack } from "lib/redux/navigation";
import { selectNavigation } from "lib/redux/navigation/selectors";

const LinkedApptsEntryPointPage = ({
  fromMobile,
}: LinkedApptsEntryPointPageProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const appointmentId = useAppSelector(selectAppointmentMeta).appointmentId;
  const preventDirectBackNavigationState =
    useAppSelector(selectNavigation).customisedBack;
  const appointment =
    useAppSelector(selectAppointments).init.lookup[appointmentId || 0];

  const {
    isLoading: isLoadingAppointment,
    hasErrored: hasErroredAppointment,
    errorMessage: errorMessageAppointment,
  } = useAppSelector(selectAppointments).init;

  const {
    value: systemSettingsValue,
    isLoading: isLoadingSystemSetting,
    hasErrored: hasErroredSystemSetting,
    errorMessage: errorMessageSystemSetting,
  } = useAppSelector(selectAppointments).systemSettings;

  const { targetSystem: sourceSystem, institutionCode } = useAppSelector(
    selectAppointmentMeta,
  );

  const NUPInstitutionCode: string[] = [
    "CLM",
    "JUR",
    "PIO",
    "QTP",
    "BBK",
    "CCK",
    "BPJ",
  ];

  // Fetch appointments on loading to get the corresponding appointment details
  useEffect(() => {
    dispatch(fetchAppointments());
    dispatch(
      fetchSystemSettings("NGEMR", "Appointment", "SOCInstitutions", null),
    );
  }, [dispatch]);

  // Need to ensure that the appointment object is populated in redux before dispatching to rescheduleLinkedAppointment.selectedLinkedAppointment state
  useEffect(() => {
    if (appointment && appointment.appointmentId) {
      dispatch(updateSelectedLinkedAppointment({ appointment, index: 0 }));
    }
  }, [appointment, dispatch]);

  // Redirection
  // need to ensure the appointment object is populated in redux before redirection
  useEffect(() => {
    if (
      appointment &&
      appointment.appointmentId &&
      systemSettingsValue &&
      isLoadingSystemSetting === false &&
      hasErroredSystemSetting === false
    ) {
      if (
        appointment.isHsgAppt &&
        (appointment.linkedAppointments ?? []).length > 0
      ) {
        if (fromMobile) {
          navigate(
            PATHS.APPOINTMENT_RESCHEDULE_AVAILABLE_BUNDLED_SLOTS_MOBILE.path,
            { replace: true },
          );
        } else {
          navigate(PATHS.APPOINTMENT_RESCHEDULE_AVAILABLE_BUNDLED_SLOTS.path, {
            replace: true,
          });
        }
      }
      // else if (
      //   sourceSystem === "NGEMR" &&
      //   institutionCode &&
      //   systemSettingsValue?.includes(institutionCode)
      // ) {
      //   if (fromMobile) {
      //     navigate(
      //       PATHS.APPOINTMENT_RESCHEDULE_AVAILABLE_BUNDLED_SLOTS_MOBILE.path,
      //       { replace: true },
      //     );
      //   } else {
      //     navigate(PATHS.APPOINTMENT_RESCHEDULE_AVAILABLE_BUNDLED_SLOTS.path, {
      //       replace: true,
      //     });
      //   }
      // }
      else if (
        institutionCode &&
        !NUPInstitutionCode.includes(institutionCode)
      ) {
        if (fromMobile) {
          navigate(
            PATHS.APPOINTMENT_RESCHEDULE_AVAILABLE_BUNDLED_SLOTS_MOBILE.path,
            { replace: true },
          );
        } else {
          navigate(PATHS.APPOINTMENT_RESCHEDULE_AVAILABLE_BUNDLED_SLOTS.path, {
            replace: true,
          });
        }
      } else {
        //   Normal flow
        if (fromMobile) {
          navigate(PATHS.APPOINTMENT_RESCHEDULE_LINKED_CONFIRM_MOBILE.path, {
            replace: true,
          });
        } else {
          navigate(PATHS.APPOINTMENT_RESCHEDULE_LINKED_CONFIRM.path, {
            replace: true,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appointment,
    fromMobile,
    hasErroredSystemSetting,
    navigate,
    isLoadingSystemSetting,
    systemSettingsValue,
    sourceSystem,
    institutionCode,
  ]);

  //customized back navigation when back button is clicked
  useEffect(() => {
    if (preventDirectBackNavigationState) {
      // reset the back button flag setting
      dispatch(setCustomisedBack(false));
      fromMobile
        ? mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH)
        : navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
    }
    // we only want this hook to fire on back navigation
    // eslint-disable-next-line
  }, [preventDirectBackNavigationState]);

  return (
    <>
      {isLoadingSystemSetting === true || isLoadingAppointment === true ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : hasErroredSystemSetting === true ? (
        <ErrorDisplayContainer
          errorMessage={errorMessageSystemSetting}
          onTryAgain={() => {
            dispatch(fetchAppointments());
            dispatch(
              fetchSystemSettings(
                "NGEMR",
                "Appointment",
                "SOCInstitutions",
                null,
              ),
            );
          }}
        />
      ) : hasErroredAppointment === true ? (
        <ErrorDisplayContainer
          errorMessage={errorMessageAppointment}
          onTryAgain={() => {
            dispatch(fetchAppointments());
            dispatch(
              fetchSystemSettings(
                "NGEMR",
                "Appointment",
                "SOCInstitutions",
                null,
              ),
            );
          }}
        />
      ) : null}
    </>
  );
};

export default LinkedApptsEntryPointPage;
