import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { formatUpdateHandphoneNumberErrorMessage } from "api/resources/messages/ErrorMessage";
import { UpdateHandphoneNumberResponsePayload } from "./updateHandphoneNumber.fromApi.types";
import { UPDATE_HANDPHONE_NUMBER_URL } from "api/resources/configs/URL";

/**
 * Update Handphone Number
 *
 * POST
 * Endpoint: /appointment/UpdateHandphoneNumber
 *
 * @param {string | null} memberIdentifier  Member Identifier of the person we are making this request for
 * @param {string | null} handphoneNumber  handphone number to be submited for the registered appointment
 * @param {string | null} regInstitutionCode  Reg Institution code of this appointment (e.g. "NUP")
 * @param {string | null} targetSystem  target system code of this appointment
 *
 * @returns {UpdateHandphoneNumberResponsePayload} Response payload from the remote API
 */
const updateHandphoneNumber = async (
  memberIdentifier: string | null,
  handphoneNumber: string | null,
  regInstitutionCode: string | null,
  encounterId: string | null,
): Promise<UpdateHandphoneNumberResponsePayload> => {
  try {
    const response = await axios.post<UpdateHandphoneNumberResponsePayload>(
      UPDATE_HANDPHONE_NUMBER_URL(),
      createRequestPayload(
        memberIdentifier,
        handphoneNumber,
        regInstitutionCode,
        encounterId,
      ),
      getRequestOptions(),
    );
    return parseResponse(response, UPDATE_HANDPHONE_NUMBER_URL());
  } catch (error) {
    const msg = formatUpdateHandphoneNumberErrorMessage(
      UPDATE_HANDPHONE_NUMBER_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  memberIdentifier: string | null,
  handphoneNumber: string | null,
  regInstitutionCode: string | null,
  encounterId: string | null,
) => {
  return {
    MemberIdentifier: memberIdentifier,
    HandphoneNumber: handphoneNumber,
    InstitutionCode: regInstitutionCode,
    EncounterId: encounterId,
  };
};
export { updateHandphoneNumber };
