/**
 * format string template with arbitary number of args
 *
 * example of use
 * const myString: string = 'page={0}&id={1}';
 * const formattedWithFormat = stringFormat(myString, 123, 456);
 * consol.log(formattedWithFormat); // page=123&id=456
 *
 * @param template string template which contains section to be replaced with customized text
 * @param args customized text
 */
export const formatString = (template: string, ...args: any[]) => {
  return template.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
};

// export const formatFirstLetterCapital = (word: string | null) => {
//   if (word && word !== "") {
//     const newWord = word.charAt(0) + word.substring(1).toLowerCase();
//     return newWord;
//   }
//   return "";
// };
