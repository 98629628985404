import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    cardTag: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1, 0, 0.75, 0),
    },
    cardTagText: {
      color: theme.palette.grey[600],
      typography: theme.typography.caption1_semibold,
      padding: theme.spacing(0, 1, 0, 1),
    },
  } satisfies SxObjType;
};

export { sxStyles };
