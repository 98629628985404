import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { GET_QUEUE_STATUS_AND_JOURNEY_URL } from "../../resources/configs/URL";
import { formatGetQueueStatusAndJourneyErrorMessage } from "../../resources/messages/ErrorMessage";
import { GetQueueStatusAndJourneyPayload } from "./getQueueStatusAndJourney.fromApi.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";

interface GetQueueStatusAndJourneyRequestPayload {
  memberIdentifier: string | null;
  regAppointmentId: string | null;
  encounterId: string | null;
  appointmentTime: string | null;
  vcSupportStatus: string | null;
}

/**
 * Retrieves the queue status and journey information for a given appointment.
 *
 * @param {Object} params - The parameters for the request.
 * @param {string} params.memberIdentifier - The identifier for the member.
 * @param {string} params.regAppointmentId - The registration appointment ID.
 * @param {string} params.encounterId - The encounter ID.
 * @param {string} params.appointmentTime - The time of the appointment.
 * @param {string} params.vcSupportStatus - The VC support status.
 * @returns {Promise<GetQueueStatusAndJourneyPayload>} A promise that resolves to the queue status and journey payload.
 * @throws Will throw an error if the request fails.
 */
const getQueueStatusAndJourney = async ({
  memberIdentifier,
  regAppointmentId,
  encounterId,
  appointmentTime,
  vcSupportStatus,
}: GetQueueStatusAndJourneyRequestPayload): Promise<GetQueueStatusAndJourneyPayload> => {
  try {
    const response = await axios.post<GetQueueStatusAndJourneyPayload>(
      GET_QUEUE_STATUS_AND_JOURNEY_URL(),
      createRequestPayload({
        memberIdentifier,
        regAppointmentId,
        encounterId,
        appointmentTime,
        vcSupportStatus,
      }),
      getRequestOptions(),
    );
    return parseResponse(response, GET_QUEUE_STATUS_AND_JOURNEY_URL());
  } catch (error) {
    const msg = formatGetQueueStatusAndJourneyErrorMessage(
      GET_QUEUE_STATUS_AND_JOURNEY_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = ({
  memberIdentifier,
  regAppointmentId,
  encounterId,
  appointmentTime,
  vcSupportStatus,
}: GetQueueStatusAndJourneyRequestPayload) => ({
  MemberIdentifier: memberIdentifier,
  RegAppointmentId: regAppointmentId,
  EncounterId: encounterId,
  AppointmentTime: appointmentTime,
  VcSupportStatus: vcSupportStatus,
});

export { getQueueStatusAndJourney };
