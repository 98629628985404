import { PATHS } from "lib/routing/paths/paths";

/**
 * == PATHS TO HIJACK
 * Paths where back navigation should be hijacked.
 *
 * === Description:
 * This list of paths refer to pages that require custom handling for back navigation.
 *
 * If a page is listed here, it means that direct back navigation, e.g. history.goBack(), will not be fired.
 * [IMPORTANT] Each page will be responsible for handling navigation here.
 *
 * === Example / Explanation:
 * This is useful, for example, in the MedRefill module for the particulars page.
 *
 * Here, we need to show a Confirmation modal for the user on the 1st step of the form flow
 * when the user is going back, to warn them before proceeding.
 *
 * Hence you need "custom handling" on this interaction and need to include the path name of those affected pages here.
 * How you handle navigation from those pages is up to you and can be completely customizable.
 * Please see how those pages are handling navigation in more detail.
 */
const PATH_WITH_BACK_NAVIGATION_HIJACKED = [
  // =======================
  // Profile
  // =======================
  PATHS.MY_PROFILE_MOBILE.path,
  PATHS.MY_PROFILE_MOBILE_ERROR.path,
  PATHS.MY_PROFILE_PREFERRED_MAILING.path,
  PATHS.MY_INFOPROFILE.path,
  PATHS.MYINFO_AND_PROFILE_CONFIRM.path,
  PATHS.MYINFO_AND_PROFILE_COMPARE.path,

  // ====================
  // MedRefill
  // ===================
  PATHS.MED_REFILL_PARTICULARS.path,
  PATHS.MED_REFILL_QUANTITY.path,
  PATHS.MED_REFILL_COLLECTION.path,
  PATHS.MED_REFILL_PAYMENT.path,
  PATHS.MED_REFILL_ORDER_SUMMARY.path,

  PATHS.MED_VIRTUALCONSULT_ORDER_MEDICATION_PARTICULARS_MOBILE.path,
  PATHS.MED_VIRTUALCONSULT_ORDER_MEDICATION_COLLECTION.path,
  PATHS.MED_VIRTUALCONSULT_ORDER_MEDICATION_PAYMENT.path,
  PATHS.MED_VIRTUALCONSULT_ORDER_MEDICATION_ORDER_SUMMARY.path,

  PATHS.MED_TOPUP_PARTICULARS.path,
  PATHS.MED_TOPUP_PRESCRIPTION.path,
  PATHS.MED_TOPUP_COLLECTION.path,
  PATHS.MED_TOPUP_PAYMENT.path,
  PATHS.MED_TOPUP_ORDER_SUMMARY.path,

  // ========================
  // MRRP
  // ========================
  PATHS.MRRP_NEW_REQUEST_REPORT_DETAILS.path,
  PATHS.MRRP_NEW_REQUEST_COLLECTION.path,
  PATHS.MRRP_PAST_REPORT_DETAIL.path,

  // =======================
  // Appointments
  // ======================
  PATHS.APPOINTMENT_MAMMOGRAM_FORM.path,

  PATHS.APPOINTMENT_RESCHEDULE_LINKED_ENTRY.path,
  PATHS.APPOINTMENT_RESCHEDULE_LINKED_ENTRY_MOBILE.path,
  PATHS.APPOINTMENT_RESCHEDULE_LINKED_CONFIRM_MOBILE.path,
  PATHS.APPOINTMENT_RESCHEDULE_LINKED_CONFIRM.path,
  PATHS.APPOINTMENT_RESCHEDULE_AVAILABLE_BUNDLED_SLOTS_MOBILE.path,
  PATHS.APPOINTMENT_RESCHEDULE_AVAILABLE_BUNDLED_SLOTS.path,

  // =====================
  // MHM
  // =====================
  PATHS.MHM_ENROLLED_USER_QUESTIONNAIRE.path,

  // =====================
  // Payment
  // =====================
  PATHS.PAYMENT_PAYOR_INFO_TRY_AGAIN.path,
  PATHS.PAYMENT_RECEIPT.path,

  // =========================
  // Health Together
  // =========================
];

/**
 * Checks if the specified location subpath is contained in the list of hijackable paths.
 * This method can be used to check if the path the user is currently at needs custom handling.
 *
 * @param {string} locationPathName  Subpath in the URl containing the specific route to
 *   search for in the list of hijackable paths
 * @returns {boolean}  True if this path can be found, false otherwise
 */
const containsBackNavHijackedPath = (locationPathName: string) => {
  return PATH_WITH_BACK_NAVIGATION_HIJACKED.includes(locationPathName);
};

/**
 * == PATHS TO HIJACK
 * Paths where CLOSE button at top right (only appear for caregiver flow) should be hijacked.
 *
 * === Description:
 * This list of paths refer to pages that require custom handling for close button.
 *
 * If a page is listed here, it means that navigation to dashboard will not be fired.
 * [IMPORTANT] Each page will be responsible for handling the action after the CLOSE button is clicked.
 *
 * === Example / Explanation:
 * This is useful, for example, in the MedRefill module for the particulars page.
 *
 * Here, we need to show a Confirmation modal for the user in the form flow
 * when the user intends to exit, to warn them before proceeding.
 *
 * Hence you need "custom handling" on this interaction and need to include the path name of those affected pages here.
 * How you handle navigation from those pages is up to you and can be completely customizable.
 * Please see how those pages are handling navigation in more detail.
 */
const PATH_WITH_CLOSE_BUTTON_HIJACKED = [
  PATHS.MED_REFILL_PARTICULARS.path,
  PATHS.MED_REFILL_QUANTITY.path,
  PATHS.MED_REFILL_COLLECTION.path,
  PATHS.MED_REFILL_PAYMENT.path,
  PATHS.MED_REFILL_ORDER_SUMMARY.path,
  PATHS.MED_VIRTUALCONSULT_ORDER_MEDICATION_PARTICULARS_MOBILE.path,

  PATHS.MED_TOPUP_PARTICULARS.path,
  PATHS.MED_TOPUP_PRESCRIPTION.path,
  PATHS.MED_TOPUP_COLLECTION.path,
  PATHS.MED_TOPUP_PAYMENT.path,
  PATHS.MED_TOPUP_ORDER_SUMMARY.path,
];

/**
 * Checks if the specified location subpath is contained in the list of hijackable paths.
 * This method can be used to check if the path the user is currently at needs custom handling.
 *
 * @param {string} locationPathName  Subpath in the URl containing the specific route to
 *   search for in the list of hijackable paths
 * @returns {boolean}  True if this path can be found, false otherwise
 */
const containsCloseButtonHijackedPath = (locationPathName: string) => {
  return PATH_WITH_CLOSE_BUTTON_HIJACKED.includes(locationPathName);
};

export {
  PATH_WITH_BACK_NAVIGATION_HIJACKED,
  PATH_WITH_CLOSE_BUTTON_HIJACKED,
  containsBackNavHijackedPath,
  containsCloseButtonHijackedPath,
};
