/**
 * Strips the "data:image" part from a base64 string of any image type.
 * Splits the variable string at "base64," than take the second part.
 *
 * Encoding Scheme Prefix example:
 *   data:image/jpeg;base64,
 *
 * @param {string} str Input string to remove prefix for
 * @returns {string}  Result string with the Encoding Scheme Prefix removed.
 *   If the input was invalid, or if an exception is thrown, the input will be returned again.
 */
export const removeImageDataEncodingPrefix = (str: string) => {
  try {
    if (str) {
      const parts = str.split("base64,");

      if (parts.length >= 2) {
        // part with the data payload only, no encoding scheme
        return parts[1];
      } else {
        // no encoding scheme prefix found
        return str;
      }
    } else {
      return str;
    }
  } catch (error) {
    return str;
  }
};
