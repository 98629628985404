import axios from "axios";
import { GET_REPORT_HISTORY_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatGetReportHistoryErrorMessage } from "api/resources/messages/ErrorMessage";
import { GetReportHistoryResponsePayload } from "./getReportHistory.fromApi.types";

/**
 * Get report information from multiple source institutions
 *
 * POST
 * Endpoint: secure/mrrp/GetReportHistory
 *
 * @param {string} hospitalCode Hospital code to retrieve values for, retrieve for all when null, ADMC | CGH | IMH | KKH | KTPH | NUH | SGH | SKH | TTSH
 * @param {string} memberIdentifier Encrypted NRIC. e.g. "50484c3f98b15deff2f1e76e0d736abc" (this one is invalid)
 */
const getReportHistory = async (
  hospitalCode: string | null,
  memberIdentifier: string | null,
  oldMemberIdentifier: string | null,
  oldTokenizedId: string | null,
): Promise<GetReportHistoryResponsePayload> => {
  try {
    const response = await axios.post<GetReportHistoryResponsePayload>(
      GET_REPORT_HISTORY_URL(),
      createRequestPayload(
        hospitalCode,
        memberIdentifier,
        oldMemberIdentifier,
        oldTokenizedId,
      ),
      getRequestOptions(),
    );
    return parseResponse(response, GET_REPORT_HISTORY_URL());
  } catch (error) {
    const msg = formatGetReportHistoryErrorMessage(GET_REPORT_HISTORY_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  hospitalCode: string | null,
  memberIdentifier: string | null,
  oldMemberIdentifier: string | null,
  oldTokenizedId: string | null,
) => {
  return {
    HospitalCode: hospitalCode,
    MemberIdentifier: memberIdentifier,
    OldMemberIdentifier: oldMemberIdentifier,
    OldTokenizedId: oldTokenizedId,
  };
};

export { getReportHistory };
