import { PickersActionBarAction } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";

const ISO_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

/**
 * Returns the ISO format of a given date in Date format, guranteed to return a string
 * Different from getNextDate in AvailableSlotsSection
 *
 * @param {string} dateString  The date in any string format
 * @returns {string}  The date in ISO format
 */
export const getISOString = (dateString: string): string => {
  return formatDateToISO(new Date(dateString)) ?? "";
};

/**
 * Returns the ISO format of next date for a given date in string format
 * Different from getNextDate in AvailableSlotsSection
 *
 * @param {string} maxDateString  The date which needs to be get next date for
 * @returns {string}  The next date in ISO format, or the max date in javascript
 */
export const getNextDateISO = (maxDateString: string) => {
  try {
    var nextDate = new Date(maxDateString);
    nextDate.setDate(nextDate.getDate() + 1);
    return format(nextDate, ISO_DATE_FORMAT);
  } catch (error) {
    return format(new Date(8640000000000000), ISO_DATE_FORMAT); // max date in javascript
  }
};

/**
 * The function `getViews` takes a date format string as input and returns an array of views ("year",
 * "month", "day") based on the format.
 * @param {string} dateFormat - A string that represents the desired format for displaying dates. It
 * can include the following placeholders:
 * @returns The function `getViews` returns an array of strings, where each string represents a unit of
 * time ("year", "month", or "day") based on the provided `dateFormat` parameter.
 */
export const getViews = (dateFormat: string) => {
  let viewList: ("year" | "month" | "day")[] = [];
  if (dateFormat.includes("y")) {
    viewList.push("year");
  }
  if (dateFormat.includes("M")) {
    viewList.push("month");
  }
  if (dateFormat.includes("d")) {
    viewList.push("day");
  }
  return viewList;
};

// include *Required keyword or error text, both subject to presence, with proirity error text > "*Required"
/**
 * The function `getHelperText` returns a helper text based on the provided parameters.
 * @param {string} errorText - A string that represents an error message.
 * @param {boolean} required - A boolean value indicating whether the field is required or not.
 * @param {boolean} disabled - A boolean value indicating whether the input field is disabled or not.
 * @param {string | null | undefined} customHelperText - The `customHelperText` parameter is a string
 * that represents a custom helper text that you want to display. It can be `null`, `undefined`, or a
 * non-empty string.
 * @returns The function `getHelperText` returns one of the following:
 */
export const getHelperText = (
  errorText: string,
  required: boolean,
  disabled: boolean,
  customHelperText: string | null | undefined,
): any => {
  if (errorText) {
    return errorText;
  }

  if (required && !disabled) {
    return "*Required";
  }

  if (customHelperText) {
    return MapRawStringToReactElement(customHelperText);
  }
};

/**
 * The function `customActions` takes in optional boolean parameters and returns an array of
 * `PickersActionBarAction` based on the provided options.
 * @param  - - `showAccept` (optional): A boolean indicating whether to show the "accept" action.
 * @returns The function `customActions` returns an array of `PickersActionBarAction` values.
 */
export const customActions = ({
  showAccept = true,
  showCancel,
  showToday,
  showClear,
}: {
  showAccept?: boolean;
  showCancel?: boolean;
  showToday?: boolean;
  showClear?: boolean;
}): PickersActionBarAction[] => {
  const actions = [];
  if (showClear) {
    actions.push("clear");
  }
  if (showToday) {
    actions.push("today");
  }
  if (showCancel) {
    actions.push("cancel");
  }
  if (showAccept) {
    actions.push("accept");
  }

  return actions as PickersActionBarAction[];
};

/**
 * The function `getShouldShowToday` checks if the current date is within the specified range of
 * minimum and maximum dates.
 * @param  - The `minDate` parameter is an optional string or null value representing the minimum date
 * allowed. The `maxDate` parameter is also an optional string or null value representing the maximum
 * date allowed.
 * @returns The function `getShouldShowToday` returns a boolean value.
 */
export const getShouldShowToday = ({
  minDate,
  maxDate,
}: {
  minDate?: string | null;
  maxDate?: string | null;
}): boolean => {
  const today = new Date();
  const current = formatDateToISO(today);
  if (
    (minDate && current < getISOString(minDate)) ||
    (maxDate && current > getNextDateISO(maxDate))
  ) {
    return false;
  }
  return true;
};
