import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      height: "100%",
    },
    topContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      flex: 1,
    },
    errorImageContainer: {
      height: "57px",
      width: "57px",
      margin: "0 auto",
      marginBottom: theme.spacing(2),
    },
    errorImage: {
      objectFit: "contain",
      width: "100%",
    },
    titleText: {
      color: theme.palette.text.primary,
      textAlign: "center",
      marginBottom: theme.spacing(2),
    },
    bodyText: {
      color: theme.palette.grey[400],
      padding: `0 ${theme.spacing(4)}`,
      textAlign: "center",
      marginBottom: theme.spacing(7.5),
    },
    buttonsContainer: {
      padding: theme.spacing(2),
      textAlign: "center",
    },
  } satisfies SxObjType;
};

export { sxStyles };
