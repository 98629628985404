import { OidcClient } from "@axa-fr/react-oidc";
import { CONTENT_TYPE, API_KEY } from "../Config";

/**
 * Formats the Standardized API Request options used for all API calls.
 *
 * Pre-condition:
 *   The store must be updated with the relevant values below in order for them to be extracted
 *   correctly during API calls. This is important because API calls could theoretically occur
 *   before the store is instantiated and completely mapped with data values.
 *
 * @returns {Object} Axios compatible HTTP header object
 */
const getRequestOptions = () => {
  const oidc = OidcClient.get();
  return {
    headers: {
      "Content-Type": CONTENT_TYPE,
      "x-api-key": API_KEY,
      Authorization: `Bearer ${oidc.tokens?.accessToken}`,
      "Client-id": oidc.configuration.client_id,
    },
    timeout: 30000,
  };
};

export { getRequestOptions };
