import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MedUserPreferenceState } from "./medUserPreference.types";

// Initial state occurring when Medrefill user preference is not initialized yet
// All fields should be NULL when not logged in yet.
export const initialState: MedUserPreferenceState = {
  refill: {
    lastFiveInstitutionSearchedKeywords: [],
  },
  topup: {
    lastFiveInstitutionSearchedKeywords: [],
  },
};

/**
 * The slice   reducer for the part of state that represents the logged in user.
 * Contains the user preference information.
 */
const medUserPreferenceSlice = createSlice({
  name: "medUserPreference",
  initialState,
  reducers: {
    // === For the Refill Flow ===
    updateRefillInsUserPreference(state, action: PayloadAction<string>) {
      const index: number =
        state.refill.lastFiveInstitutionSearchedKeywords.indexOf(
          action.payload,
        );
      //if the list has contain the str, remove it before adding it to the end again
      if (index !== -1) {
        state.refill.lastFiveInstitutionSearchedKeywords.splice(index, 1);
      }
      if (state.refill.lastFiveInstitutionSearchedKeywords.length < 5) {
        state.refill.lastFiveInstitutionSearchedKeywords.push(action.payload);
      } else {
        state.refill.lastFiveInstitutionSearchedKeywords.push(action.payload);
        state.refill.lastFiveInstitutionSearchedKeywords.shift();
      }
    },

    // === For the Topup Flow ===
    updateTopupInsUserPreference(state, action: PayloadAction<string>) {
      const index: number =
        state.topup.lastFiveInstitutionSearchedKeywords.indexOf(action.payload);
      //if the list has contain the str, remove it before adding it to the end again
      if (index !== -1) {
        state.topup.lastFiveInstitutionSearchedKeywords.splice(index, 1);
      }
      if (state.topup.lastFiveInstitutionSearchedKeywords.length < 5) {
        state.topup.lastFiveInstitutionSearchedKeywords.push(action.payload);
      } else {
        state.topup.lastFiveInstitutionSearchedKeywords.push(action.payload);
        state.topup.lastFiveInstitutionSearchedKeywords.shift();
      }
    },
  },
});

export const { updateRefillInsUserPreference, updateTopupInsUserPreference } =
  medUserPreferenceSlice.actions;

export default medUserPreferenceSlice.reducer;
