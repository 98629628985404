import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";
import { parseISO } from "date-fns";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { RootState } from "lib/redux/root/redux.types";
import { rescheduleAppointment } from "api/appointment/RescheduleAppointment/rescheduleAppointment";
import ConfirmationPage from "./ConfirmationPage";
import { handleErrorModal } from "lib/redux/notifications";
import { createAppointment } from "api/appointment/CreateAppointment/createAppointment";
import { onBookReq } from "ui/appointment/components/createAppointment/pages/confirmation/ConfirmationPage/ConfirmationPage.types";
import { RescheduleReq } from "./ConfirmationPage.types";

// ==========================
// MAP STATE TO PROPS
// ==========================

const mapStateToProps = (state: RootState) => {
  const apptId = state.appointments.appointmentMeta.appointmentId;
  const appointment = state.appointments.init.lookup[apptId || 0];
  return {
    memberIdentifier: state.user.memberIdentifier,
    existingAppointmentId: state.appointments.appointmentMeta.appointmentId,
    institution: state.appointments.reschedule.selectedInstitution,
    location: state.appointments.appointmentMeta.location,
    service: state.appointments.reschedule.selectedService,
    slotDateTime: parseDateTime(state.appointments.reschedule.selectedDateTime),
    rescheduledSlotDateTime: parseDateTime(
      state.appointments.reschedule.selectedAvailableSlotDateTime,
    ),
    extensionData:
      state.appointments.reschedule.selectedAvailableSlotExtensionData,
    targetSystem: state.appointments.appointmentMeta.targetSystem,
    isHsgAppt: state.appointments.appointmentMeta.isHsgAppt || false,
    institutionCode: state.appointments.appointmentMeta.institutionCode,
    appointmentType: state.appointments.appointmentMeta.appointmentType,
    slotId: state.appointments.create?.selectedAvailableSlotId,
    rescheduleSlotId: state.appointments.reschedule.selectedAvailableSlotId,
    visitTypeId: state.appointments.appointmentMeta.visitTypeId,
    calendarTitle: state.appointments.appointmentMeta.calendarTitle,
    calendarLocation: state.appointments.appointmentMeta.calendarLocation,
    reminderMsg: appointment?.reminder ? appointment.reminder : "",
    departmentName: state.appointments.appointmentMeta.departmentName,
  };
};

const parseDateTime = (serializedDate: string | null) => {
  if (serializedDate) {
    // assume the date can be de-serialized
    return parseISO(serializedDate);
  } else {
    return null;
  }
};

// ==========================
// MAP DISPATCH TO PROPS
// ==========================

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onReschedule: async ({
      memberIdentifier,
      existingAppointmentId,
      rescheduledSlotDateTime,
      institutionCode,
      rescheduleSlotId,
      visitTypeId,
      isHsgAppt,
    }: RescheduleReq) => {
      return await rescheduleAppointment({
        memberIdentifier,
        existingAppointmentId,
        rescheduledSlotDateTime: rescheduledSlotDateTime,
        institutionCode,
        rescheduleSlotId,
        visitTypeId,
        isHsgAppt,
      });
    },

    onCreate: async ({
      visitTypeId,
      memberIdentifier,
      slotDateTime,
      slotId,
      institutionCode,
      isHsgAppt,
    }: onBookReq) => {
      return await createAppointment({
        visitTypeId,
        memberIdentifier,
        start: formatDateToISO(slotDateTime),
        slotId,
        institutionCode,
        isHsgAppt,
      });
    },

    handleErrorModal: (
      open: boolean,
      message: string | null,
      buttonText?: string | null,
    ) => {
      dispatch(handleErrorModal({ open, message, buttonText }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage);
