import { Box, Typography } from "@mui/material";
import { NoSlotsMessageProps } from "./NoSlotsMessage.types";
import { sxStyles } from "./NoSlotsMessage.styles";

const NoSlotsMessage = ({ children }: NoSlotsMessageProps) => {
  const classes = sxStyles();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
      }}
    >
      <Typography sx={classes.text} variant="body1_regular">
        {children}
      </Typography>
    </Box>
  );
};

export default NoSlotsMessage;
