import React from "react";
import { PATHS } from "lib/routing";
import QuantityContainer from "../../quantity/Quantity/QuantityContainer";

const RefillQuantityPage = () => {
  return (
    <QuantityContainer pathToNextStep={PATHS.MED_REFILL_COLLECTION.path} />
  );
};

export default RefillQuantityPage;
