import { useEffect, useState } from "react";
import { sxStyles } from "./PastRequest.styles";
import { Summary } from "api/medrefill/GetMedicationSummary/getMedicationSummary.types";
import { PastRequestProps } from "./PastRequest.types";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import { FormLabel, Box, Typography, CircularProgress } from "@mui/material";
import Page from "lib/components/layout/Page/Page";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import MedicationCardContainer from "../MedicationCard/MedicationCardContainer";
import { sameStringTest } from "lib/util/StringUtil/sameStringTest/sameStringTest";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";

import {
  updatePastRequests,
  fetchPastRequests,
  updatePastRequestRequestorId,
  setPastRequestsOffset,
  fetchClinics,
  fetchCollectionPaymentDetails,
  fetchDocumentByCluster,
  fetchMedicationImages,
  fetchMedications,
  fetchMedicationSummaryDetail,
  fetchSystemSettings,
  updateReorderFlag,
  updateState,
  setClinicsHasErrored,
  setCollectionPaymentDetailsHasErrored,
  setDocumentByClusterHasErrored,
  setMedicationsHasErrored,
  setPastRequestMedicationImages,
  setPastRequestMedicationImagesHasErrored,
  setSystemSettingsHasErrored,
  setViewPastDetail,
  setViewPastDetailsHasErrored,
} from "ui/medrefill/ducks";
import { mapPastRequestRefillDetailToMedrefillState } from "lib/util/MedrefillMappingUtil/mapPastRequestRefillDetailToMedrefillState";
import { mapPastRequestTopupDetailToMedrefillState } from "lib/util/MedrefillMappingUtil/mapPastRequestTopupDetailToMedrefillState";
import { MedRefillState } from "ui/medrefill/ducks/medrefill.types";
import { initialState as medRefillInitialState } from "ui/medrefill/ducks";
import { selectMedRefill } from "ui/medrefill/ducks/selectors";

const NUMBER_OF_REQUESTS = 30;

const PastRequest = ({ requestType }: PastRequestProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();
  const dispatch = useAppDispatch();

  const pastRequestDetail =
    useAppSelector(selectMedRefill).viewPastRequest.detail;
  const pastRequestMedicationImages =
    useAppSelector(selectMedRefill).pastRequestMedicationImages.images;
  const medrefillState =
    useAppSelector(selectMedRefill).viewPastRequest.detail?.PrescripionType ===
    "REFILL"
      ? mapPastRequestRefillDetailToMedrefillState(
          pastRequestDetail,
          pastRequestMedicationImages,
        )
      : mapPastRequestTopupDetailToMedrefillState(
          pastRequestDetail,
          pastRequestMedicationImages,
        );

  const {
    isLoading: isLoadingDetail,
    hasErrored: hasErroredDetail,
    errorMessage: errorMessageDetail,
  } = useAppSelector(selectMedRefill).viewPastRequest;
  const {
    isLoading: isLoadingImages,
    hasErrored: hasErroredImages,
    errorMessage: errorMessageImages,
  } = useAppSelector(selectMedRefill).pastRequestMedicationImages;
  const {
    isLoading,
    hasErrored,
    errorMessage,
    summaryList: pastRequestList,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasMoreRecords,
  } = useAppSelector(selectMedRefill).pastRequest;
  const {
    isLoading: isLoadingSystemSettings,
    hasErrored: hasErroredSystemSettings,
    errorMessage: errorMessageSystemSettings,
  } = useAppSelector(selectMedRefill).systemSettings;
  const {
    isLoading: isLoadingMedications,
    hasErrored: hasErroredMedications,
    errorMessage: errorMessageMedications,
  } = useAppSelector(selectMedRefill).medications;
  const {
    isLoading: isLoadingCollectionPaymentDetails,
    hasErrored: hasErroredCollectionPaymentDetails,
    errorMessage: errorMessageCollectionPaymentDetails,
  } = useAppSelector(selectMedRefill).collectionAndPayments;
  const {
    isLoading: isLoadingDocByCluster,
    hasErrored: hasErroredDocByCluster,
    errorMessage: errorMessageDocByCluster,
  } = useAppSelector(selectMedRefill).documentByCluster;
  const {
    isLoading: isLoadingDocByClinics,
    hasErrored: hasErroredClinics,
    errorMessage: errorMessageClinics,
  } = useAppSelector(selectMedRefill).clinics;

  // Local states
  // Stores the requestorId of selected medication request
  const [selectedRequestorId, setSelectedRequestorId] = useState<number>(0);

  const onFetchingLatestRequestDetail = (requesterId: number) => {
    dispatch(updatePastRequestRequestorId(requesterId));
    dispatch(fetchMedicationSummaryDetail());
  };
  const onFetchingLatestRequestImages = (requesterId: number) => {
    dispatch(updatePastRequestRequestorId(requesterId));
    dispatch(fetchMedicationImages());
  };

  const onReorder = (pastRequestState: MedRefillState) => {
    dispatch(
      updateState({
        ...pastRequestState,
        collection: {
          ...pastRequestState.collection,
          date: medRefillInitialState.collection.date,
          timeslot: medRefillInitialState.collection.timeslot,
        },
      }),
    );
    dispatch(updateReorderFlag(true));
    dispatch(fetchSystemSettings(pastRequestState.institution.cluster || ""));
    dispatch(fetchMedications(pastRequestState.institution.cluster || ""));
    dispatch(
      fetchCollectionPaymentDetails(
        pastRequestState.institution.cluster || "",
        pastRequestState.institution.facilityId || 0,
      ),
    );
    dispatch(
      fetchDocumentByCluster(pastRequestState.institution.cluster || ""),
    );
    dispatch(fetchClinics(pastRequestState.institution.facilityId || 0));
  };

  useEffect(() => {
    dispatch(setPastRequestsOffset(0));
    dispatch(updatePastRequests(null));
    dispatch(fetchPastRequests(NUMBER_OF_REQUESTS, requestType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // call APIs to fetch form data (systemSettings, payment mode, etc) after user chooses to reorder latest request and APIs fetching request detail have been finished successfully
  useEffect(() => {
    if (
      selectedRequestorId &&
      medrefillState.requestType &&
      hasErroredImages === false
    ) {
      dispatch(
        updateState({
          ...medrefillState,
          collection: {
            ...medrefillState.collection,
            date: medRefillInitialState.collection.date,
            timeslot: medRefillInitialState.collection.timeslot,
          },
        }),
      );
      dispatch(updateReorderFlag(true));
      dispatch(fetchSystemSettings(medrefillState.institution.cluster || ""));
      dispatch(fetchMedications(medrefillState.institution.cluster || ""));
      dispatch(
        fetchCollectionPaymentDetails(
          medrefillState.institution.cluster || "",
          medrefillState.institution.facilityId || 0,
        ),
      );
      dispatch(
        fetchDocumentByCluster(medrefillState.institution.cluster || ""),
      );
      dispatch(fetchClinics(medrefillState.institution.facilityId || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRequestorId,
    medrefillState.requestType,
    dispatch,
    hasErroredImages,
  ]);

  // do the page redirection to do reordering only after all form data API calls have been finished successfully
  // !exp Error checks are needed to ensure API and states are completed
  useEffect(() => {
    if (
      selectedRequestorId &&
      medrefillState.requestType &&
      hasErroredSystemSettings === false &&
      hasErroredMedications === false &&
      hasErroredCollectionPaymentDetails === false &&
      hasErroredDocByCluster === false &&
      hasErroredClinics === false
    ) {
      if (medrefillState.requestType === "REFILL") {
        if (
          medrefillState.particulars.isPatient === false &&
          medrefillState.particulars.patientNric &&
          medrefillState.particulars.requesterNric &&
          sameStringTest(
            medrefillState.particulars.patientNric,
            medrefillState.particulars.requesterNric,
          )
        ) {
          navigate(PATHS.MED_REFILL_SELECT_INSTITUTIONS.path);
        } else {
          navigate(PATHS.MED_REFILL_QUANTITY.path);
        }
      }
      if (medrefillState.requestType === "TOPUP") {
        navigate(PATHS.MED_TOPUP_PRESCRIPTION.path);
      }
    }
  }, [
    selectedRequestorId,
    medrefillState.requestType,
    hasErroredSystemSettings,
    hasErroredMedications,
    hasErroredCollectionPaymentDetails,
    hasErroredDocByCluster,
    hasErroredClinics,
    navigate,
    medrefillState.particulars.isPatient,
    medrefillState.particulars.patientNric,
    medrefillState.particulars.requesterNric,
  ]);

  const onReOrderClickHandler = (item: Summary) => {
    // !exp this clears the previous medrefillState
    dispatch(setViewPastDetail(null));
    dispatch(setPastRequestMedicationImages(null));

    // Clearing errors
    dispatch(setViewPastDetailsHasErrored(null));
    dispatch(setPastRequestMedicationImagesHasErrored(null));
    dispatch(setSystemSettingsHasErrored(null));
    dispatch(setMedicationsHasErrored(null));
    dispatch(setCollectionPaymentDetailsHasErrored(null));
    dispatch(setDocumentByClusterHasErrored(null));
    dispatch(setClinicsHasErrored(null));

    // !exp fetches new details and images based on current requesterId of clicked item
    onFetchingLatestRequestDetail(item.RequesterId);

    if (item.RequestType !== "VIRTUALCONSULT") {
      onFetchingLatestRequestImages(item.RequesterId);
    }

    // !exp sets local state to trigger useeffect history.push to redirect
    setSelectedRequestorId(item.RequesterId);
  };

  const onClickCardHandler = (item: Summary) => {
    // Navigate to appropriate page
    if (item.PrescriptionRequestType === "REFILL") {
      navigate(PATHS.MED_REFILL_VIEW_PAST_REQUEST_DETAIL.path);
    }
    if (item.PrescriptionRequestType === "TOPUP") {
      navigate(PATHS.MED_TOPUP_VIEW_PAST_REQUEST_DETAIL.path);
    }
    if (item.PrescriptionRequestType === "VIRTUALCONSULT") {
      navigate(
        PATHS.MED_VIRTUALCONSULT_ORDER_MEDICATION_VIEW_PAST_REQUEST_DETAIL.path,
      );
    }
  };

  return (
    <Page>
      {isLoadingDetail ||
      isLoadingImages ||
      isLoadingSystemSettings ||
      isLoadingCollectionPaymentDetails ||
      isLoadingDocByClinics ||
      isLoadingDocByCluster ||
      isLoadingMedications ||
      (isLoading && pastRequestList === null) ? (
        <Box display="flex" align-items="center" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : hasErrored ? (
        <ErrorDisplay
          errorMessage={errorMessage}
          onTryAgain={() =>
            dispatch(fetchPastRequests(NUMBER_OF_REQUESTS, requestType))
          }
        />
      ) : hasErroredDetail ? (
        <ErrorDisplay
          errorMessage={errorMessageDetail}
          onTryAgain={() => onFetchingLatestRequestDetail(selectedRequestorId)}
        />
      ) : hasErroredImages ? (
        <ErrorDisplay
          errorMessage={errorMessageImages}
          onTryAgain={() => onFetchingLatestRequestImages(selectedRequestorId)}
        />
      ) : hasErroredSystemSettings ? (
        <ErrorDisplay
          errorMessage={errorMessageSystemSettings}
          onTryAgain={() => onReorder(medrefillState)}
        />
      ) : hasErroredMedications ? (
        <ErrorDisplay
          errorMessage={errorMessageMedications}
          onTryAgain={() => onReorder(medrefillState)}
        />
      ) : hasErroredCollectionPaymentDetails ? (
        <ErrorDisplay
          errorMessage={errorMessageCollectionPaymentDetails}
          onTryAgain={() => onReorder(medrefillState)}
        />
      ) : hasErroredDocByCluster ? (
        <ErrorDisplay
          errorMessage={errorMessageDocByCluster}
          onTryAgain={() => onReorder(medrefillState)}
        />
      ) : hasErroredClinics ? (
        <ErrorDisplay
          errorMessage={errorMessageClinics}
          onTryAgain={() => onReorder(medrefillState)}
        />
      ) : pastRequestList ? (
        <Box p={2} mt={1}>
          <FormLabel sx={classes.formTitle}>Your Past Requests</FormLabel>

          {pastRequestList.map((item, index) => {
            return (
              <MedicationCardContainer
                key={item.RequestType + item.Facility + index}
                data={item}
                onReOrder={() => {
                  onReOrderClickHandler(item);
                }}
                onClickCard={() => onClickCardHandler(item)}
              />
            );
          })}

          {isLoading && (
            <Box
              display="flex"
              align-items="center"
              justifyContent="center"
              mt={4}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      ) : (
        <Typography sx={classes.noRecordBox}>No past request</Typography>
      )}
    </Page>
  );
};

export default PastRequest;
