import React from "react";
import TopupPrescriptionContainer from "../../topup/prescription/TopupPrescriptionContainer";
import { Box } from "@mui/material";

const EditTopupPrescriptionPage = () => {
  return (
    <Box mt={-9}>
      <TopupPrescriptionContainer isEditablePage={true} />
    </Box>
  );
};

export default EditTopupPrescriptionPage;
