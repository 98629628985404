/**
 * Time converter to change minutes to milliseconds
 * @param {number} minutes - time in minutes
 * @returns {number} time in milliseconds
 */
const getMsFromMinutes = (minutes: number) => {
  return minutes * 60 * 1000;
};

export default getMsFromMinutes;
