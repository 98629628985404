import React from "react";
import { Box, Typography } from "@mui/material";
import { SummaryCardProps } from "./SummaryCard.types";
import { sxStyles } from "./SummaryCard.styles";
import { formatPayment } from "lib/util/StringUtil/formatPayment/formatPayment";

const SummaryCard = ({
  billReferenceNumber,
  institutionName,
  paidAmount,
}: SummaryCardProps) => {
  const classes = sxStyles();

  return (
    <Box sx={classes.summarySection}>
      <>
        <Box sx={classes.summaryPanel}>
          <Typography sx={classes.content}>Bill Reference No</Typography>
          <Typography sx={classes.title}>{billReferenceNumber}</Typography>
        </Box>
        <Box sx={classes.summaryPanel}>
          <Typography sx={classes.content}>Institute Name</Typography>
          <Typography sx={classes.title}>{institutionName}</Typography>
        </Box>

        <Box sx={classes.summaryPanel}>
          <Typography sx={classes.content}>Paid Amount</Typography>
          <Typography sx={classes.payValue}>
            {formatPayment(paidAmount)}
          </Typography>
        </Box>
      </>
    </Box>
  );
};

export default SummaryCard;
