const ERROR_REQUIRED_LABEL = "[Field Validation (Required field)] ";
const ERROR_OPTIONAL_LABEL = "[Field Validation (Optional field)] ";

/**
 * Determines if a field is present in an object.
 *
 * @param {any} dataObj  The object to extract the specified field from
 * @param {string} fieldName  The name of the field to validate
 *
 * @returns {boolean} True if field is present, throws otherwise
 * @throws {Error} If this fieldName doesn't exist in the object
 */
const validateRequiredField = (dataObj: any, fieldName: string) => {
  if (dataObj) {
    if (!dataObj.hasOwnProperty(fieldName)) {
      const errorMessage = `${ERROR_REQUIRED_LABEL}The field named: '${fieldName}', does not exist in the provided dataObj '${JSON.stringify(
        dataObj,
      )}'.`;
      throw new Error(errorMessage);
    } else if (
      dataObj[fieldName] === null ||
      dataObj[fieldName] === undefined
    ) {
      const errorMessage = `${ERROR_REQUIRED_LABEL}The field named: '${fieldName}', was found to be NULL or UNDEFINED in the provided dataObj '${JSON.stringify(
        dataObj,
      )}'.`;
      throw new Error(errorMessage);
    } else {
      return true;
    }
  } else {
    const errorMessage = `${ERROR_REQUIRED_LABEL}The data object received was found to be NULL or UNDEFINED.`;
    throw new Error(errorMessage);
  }
};

/**
 * Determines if a field is present in an object.
 *
 * @param {any} dataObj  The object to extract the specified field from
 * @param {string} fieldName  The name of the field to validate
 * @param {boolean} shouldWarn  Turn on / off console warn when the field is not present
 *
 * @returns  True if field is present, false otherwise
 */
const validateOptionalField = (
  dataObj: any,
  fieldName: string,
  shouldWarn: boolean = false,
) => {
  if (dataObj) {
    if (!dataObj.hasOwnProperty(fieldName)) {
      if (shouldWarn) {
        console.warn(
          `${ERROR_OPTIONAL_LABEL}The field named: '${fieldName}', does not exist in the provided dataObj '${JSON.stringify(
            dataObj,
          )}'.`,
        );
      }
      return false;
    } else if (
      dataObj[fieldName] === null ||
      dataObj[fieldName] === undefined
    ) {
      if (shouldWarn) {
        console.warn(
          `${ERROR_OPTIONAL_LABEL}The field named: '${fieldName}', was found to be NULL or UNDEFINED in the provided dataObj '${JSON.stringify(
            dataObj,
          )}'.`,
        );
      }
      return false;
    } else {
      return true;
    }
  } else {
    if (shouldWarn) {
      console.warn(
        `${ERROR_OPTIONAL_LABEL}The data object received was found to be NULL or UNDEFINED.`,
      );
    }
    return false;
  }
};

export { validateRequiredField, validateOptionalField };
