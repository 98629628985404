import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_semibold,
      padding: theme.spacing(3, 0, 1.5, 0),
    },

    text: {
      color: theme.palette.grey[400],
      typography: theme.typography.body1_regular,
      //padding: theme.spacing(0, 2, 3, 2),
    },
    textBox: {
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
      "& .MuiInputLabel-animated": {
        fontSize: size.FONTSIZE_16,
        color: theme.palette.primary.main,
      },
      mb: 4,
    },
    disclaimer: {
      typography: theme.typography.caption1_regular,
      color: theme.palette.grey[400],
      my: 2.25,
    },
  } satisfies SxObjType;
};

export { sxStyles };
