import { Events } from "./logEvent.types";
import { utils, writeFile } from "xlsx";

export const getEventsListing = (eventObj: Events) => {
  const values: { Name: string; Action: string | null }[] = Object.values(
    eventObj,
  ).map((item) => {
    return { Name: item.eventName, Action: item.params.action };
  });

  // Generate Markdown
  const headers = Object.keys(values[0]);
  const markdownRows = values.map(
    (item) =>
      `| ${headers
        .map((header) => item[header as keyof typeof item])
        .join(" | ")} |`,
  );
  const markdown = `| ${headers.join(" | ")} |\n| ${headers
    .map(() => "---")
    .join(" | ")} |\n${markdownRows.join("\n")}`;

  // Generate blob for download
  const element = document.createElement("a");
  const file = new Blob([markdown], { type: "text/markdown" });
  element.href = URL.createObjectURL(file);
  element.download = "ReactEvents.md";
  document.body.appendChild(element);

  // Generate excel for download
  const ws = utils.json_to_sheet(values);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "EventsList");

  // Trigger download
  element.click();
  console.log("Markdown file created successfully");
  writeFile(wb, "ReactEvents.xlsx");
  console.log("Excel file created successfully");
};
