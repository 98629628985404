import { Box, Dialog, Skeleton } from "@mui/material";

import React from "react";

const CancelAppointmentPageSkeleton = ({
  isCancelling,
}: {
  isCancelling: boolean;
}) => {
  return (
    <Dialog open={isCancelling} fullWidth={true} maxWidth="lg">
      <Box p={2} aria-label="skeleton-container">
        <Skeleton
          variant="text"
          animation="wave"
          sx={{ width: 0.8, height: 30, mb: 2 }}
        />
        <Skeleton
          variant="text"
          animation="wave"
          sx={{ m: "0 auto", width: 0.75 }}
        />
        <Skeleton
          variant="text"
          animation="wave"
          sx={{ width: 0.75, m: "0 auto", mb: 1 }}
        />
      </Box>
    </Dialog>
  );
};

export default CancelAppointmentPageSkeleton;
