import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    formPage: {
      "& .MuiFormLabel-root.Mui-focused": {
        color: theme.palette.text.primary,
      },
    },
    formToggle: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1, 2),
      lineHeight: 2.5,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    formToggleTitle: {
      color: theme.palette.grey[400],
      typography: theme.typography.body1_semibold,
    },
    formTitle: {
      color: theme.palette.text.primary,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: "25px",
      marginBottom: theme.spacing(1),
    },
    textBox: {
      margin: theme.spacing(2.5, 0, 2.5),
    },
    infoBox: {
      border: `1px solid ${theme.palette.primary.main}`,
      background: theme.palette.secondary.main,
      padding: theme.spacing(0.5),
      display: "flex",
      lineHeight: theme.typography.body1.lineHeight,
      borderRadius: theme.spacing(0.5),
      margin: theme.spacing(2, 0),
    },
  } satisfies SxObjType;
};

export { sxStyles };
