import { Box, Button } from "@mui/material";
import { sxStyles } from "./ButtonsFooter.styles";
import { ButtonsFooterProps } from "./ButtonsFooter.types";

const ButtonsFooter = ({
  hideNextButton = false,
  nextButtonText,
  nextButtonLabel,
  hideCancelButton = false,
  cancelButtonText,
  cancelButtonLabel,
  isDisabled = false,
  onClickNext,
  onClickCancel,
}: ButtonsFooterProps) => {
  const classes = sxStyles();
  return (
    <Box display="flex" flexDirection="column">
      {/* NEXT */}
      {hideNextButton ? null : (
        <Box sx={classes.topButtonLayout}>
          <Button
            sx={{}}
            variant="contained"
            size="large"
            disabled={isDisabled}
            fullWidth
            onClick={isDisabled ? undefined : onClickNext}
            data-testid="nextButton"
          >
            {nextButtonText}
          </Button>
          {nextButtonLabel ? (
            <Box sx={classes.buttonLabel}>{nextButtonLabel}</Box>
          ) : null}
        </Box>
      )}

      {/* CANCEL */}
      {hideCancelButton ? null : (
        <Button
          sx={classes.topButtonLayout}
          variant="outlined"
          size="large"
          fullWidth={true}
          onClick={onClickCancel}
          data-testid="cancelButton"
        >
          {cancelButtonText}
        </Button>
      )}

      {/* OPTIONAL LABEL */}
      {cancelButtonLabel ? (
        <Box sx={classes.buttonLabel}>{cancelButtonLabel}</Box>
      ) : null}
    </Box>
  );
};

export default ButtonsFooter;
