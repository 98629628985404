import { Dispatch, AnyAction } from "@reduxjs/toolkit";
import { RootState } from "../root/redux.types";
import { getUserProfile } from "api/shared/GetUserProfile/getUserProfile";
import { getPatientProfile } from "api/shared/GetPatientProfile/getPatientProfile";
import {
  setUserProfile,
  setUserProfileIsLoading,
  setUserProfileHasErrored,
  clearUserProfile,
  setDependentProfileIsLoading,
  setDependentProfileHasErrored,
  setDependentProfileErrorMessage,
  setDependentProfileSources,
  setConsentIsLoading,
  setConsentHasErrored,
  setConsentErrorMessage,
  setConsentFlag,
  setDependentProfilesAreLoading,
  setUserProfileErrorMessage,
  setDependentProfiles,
  setDependentProfilesHaveErrored,
  setDependentProfilesErrorMessage,
} from ".";
import { getDependentProfiles } from "api/shared/GetDependentProfiles/getDependentProfiles";
import { validateConsent } from "api/shared/ValidateConsent/validateConsent";
import { AxiosError } from "axios";
import { PM_ERROR_422 } from "api/patientMaster/GetPatientMasterProfile/getPatientMasterProfile";

/**
 *
 * @param mapAddress whether to map registered address or preferred address according to usePreferredAddress field
 * if mapAddress=true, address fields are mapped with preferred address fields or registered address fields based on usePreferredAddress value
 * if mapAddress=false, address fields are purely preferred address fields
 * @param isPatient optional flag default to true, true if the current patient is login user, false if the patient is a dependant
 *
 */
export const fetchUserParticulars =
  ({
    mapAddress,
    isPatient = true,
    retrievePhoto = false,
  }: {
    mapAddress: boolean;
    isPatient?: boolean;
    retrievePhoto?: boolean;
  }) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setUserProfileIsLoading(true));
      const userParticularsPayload = await getUserProfile({ retrievePhoto });

      dispatch(
        setUserProfile({
          ...userParticularsPayload.UserProfile,
          HasConsentedToTermsAndConditions:
            userParticularsPayload.HasConsentedToTermsAndConditions,
          mapAddress: mapAddress,
          isPatient: isPatient,
          isMismatched: !!userParticularsPayload.UserProfile.MismatchStatus,
        }),
      );
      dispatch(setUserProfileHasErrored(false));
      dispatch(setUserProfileErrorMessage(null));
    } catch (error) {
      dispatch(clearUserProfile());
      dispatch(setUserProfileHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setUserProfileErrorMessage(error.response?.data.Message));
      }
    } finally {
      dispatch(setUserProfileIsLoading(false));
    }
  };

export const fetchDependentProfiles =
  () => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setDependentProfilesAreLoading(true));
      const dependentProfilesPayload = await getDependentProfiles();

      dispatch(
        setDependentProfiles({
          ChildrenProfiles: dependentProfilesPayload.ChildrenProfiles,
          CareRecipientProfiles: dependentProfilesPayload.CareRecipientProfiles,
        }),
      );
      dispatch(setDependentProfilesHaveErrored(false));
      dispatch(setDependentProfilesErrorMessage(null));
    } catch (error) {
      dispatch(
        setDependentProfiles({
          ChildrenProfiles: [],
          CareRecipientProfiles: [],
        }),
      );
      dispatch(setDependentProfilesHaveErrored(true));
      if (error instanceof AxiosError) {
        dispatch(
          setDependentProfilesErrorMessage(error.response?.data.Message),
        );
      } else {
        dispatch(setDependentProfilesErrorMessage(null));
      }
    } finally {
      dispatch(setDependentProfilesAreLoading(false));
    }
  };

/**
 * Executes the API to fetch a dependent's profile. Required as a workaround because the backend
 * is not able to provide the "Sources" attribute returned from /GetUserProfile's dependents list.
 * Hence this API is used to set the "Source" attribute for this dependent.
 */

export const fetchDependentParticulars =
  () => async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(setDependentProfileIsLoading(true));
      const payload = await getPatientProfile(getState().user.memberIdentifier);
      if (payload.Patient.Sources) {
        dispatch(setDependentProfileSources(payload.Patient.Sources));
        dispatch(setDependentProfileHasErrored(false));
        dispatch(setDependentProfileErrorMessage(null));
      } else if (payload.Code === PM_ERROR_422) {
        // Error 422 block patient
        dispatch(setDependentProfileSources([]));
        dispatch(setDependentProfileHasErrored(true));
        dispatch(setDependentProfileErrorMessage(payload.Message));
      } else if (payload.Code === "PAT0002") {
        // Error 404 to allow patient offline form flow
        dispatch(setDependentProfileSources([]));
        dispatch(setDependentProfileHasErrored(false));
        dispatch(setDependentProfileErrorMessage(null));
      } else {
        dispatch(setDependentProfileSources([]));
        dispatch(setDependentProfileHasErrored(true));
        dispatch(setDependentProfileErrorMessage(payload.Message));
      }
    } catch (error) {
      dispatch(setDependentProfileSources([]));
      dispatch(setDependentProfileHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setDependentProfileErrorMessage(error.response?.data.Message));
      } else {
        dispatch(setDependentProfileErrorMessage(null));
      }
    } finally {
      dispatch(setDependentProfileIsLoading(false));
    }
  };

/**
 * Executes the API to fetch user's consent against a particular module.
 * @param consentCode unique code of the particular module
 */
export const fetchUserConsent =
  (consentCode: string) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(setConsentIsLoading(true));
      const payload = await validateConsent(
        getState().user.memberIdentifier,
        consentCode,
      );

      dispatch(setConsentFlag(payload.IsConsentGiven));
      dispatch(setConsentHasErrored(false));
      dispatch(setConsentErrorMessage(null));
    } catch (error) {
      dispatch(setConsentFlag(false));
      dispatch(setConsentHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setConsentErrorMessage(error.response?.data.Message));
      } else {
        dispatch(setConsentErrorMessage(null));
      }
    } finally {
      dispatch(setConsentIsLoading(false));
    }
  };
