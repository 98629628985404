import UserProfileContainer from "./UserProfile/UserProfileContainer";
import PastReportCardContainer from "./PastReportCard/PastReportCardContainer";
import { Box, Button, CircularProgress } from "@mui/material";
import IMAGES from "lib/assets/images";
import { sxStyles } from "./MrrpPage.styles";
import { MrrpPageProps } from "./MrrpPage.types";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import Page from "lib/components/layout/Page/Page";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectMrrp, setMRRPAppRating } from "ui/mrrp/ducks/mrrpSlice";
import { useEffect } from "react";
import { MessageActions } from "lib/routing/messageChannel/messageActions";
import { setMessageToSend } from "lib/redux/navigation/navigationSlice";

const DEFAULT_NUMBER_OF_REPORT_SUMMARIES_TO_SHOW = 4;

const MrrpPage = ({
  isLoadingReportHistory,
  hasErroredReportHistory,
  errorMessageReportHistory,
  allPastReports,
  expandedPastReportListFlag,
  setExpandedPastReportListFlag,
  clearPastReports,
  callGetReportHistory,
}: MrrpPageProps) => {
  const dispatch = useAppDispatch();
  const classes = sxStyles();

  // Redux states
  const appRating = useAppSelector(selectMrrp).appRating;

  const errorMessageReportHistoryReactElement = errorMessageReportHistory
    ? MapRawStringToReactElement(errorMessageReportHistory)
    : null;

  const numberToShow = !expandedPastReportListFlag
    ? DEFAULT_NUMBER_OF_REPORT_SUMMARIES_TO_SHOW
    : allPastReports.length;

  useEffect(() => {
    // prevent reloading when back from report details page
    // report details page is only available when api returns non empty list
    if (allPastReports.length === 0) callGetReportHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetReportHistory]);

  useEffect(() => {
    if (appRating) {
      dispatch(setMRRPAppRating(false));
      dispatch(setMessageToSend(MessageActions.rating()));
    }
  }, [appRating, dispatch]);

  return (
    <Page>
      <UserProfileContainer
        onResetPastReportListFlag={() => {
          clearPastReports();
          setExpandedPastReportListFlag(false);
        }}
      />
      {isLoadingReportHistory ? (
        <Box display="flex" align-items="center" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : hasErroredReportHistory ? (
        <ErrorDisplayContainer
          errorMessage={errorMessageReportHistory}
          onTryAgain={() => {
            clearPastReports();
            callGetReportHistory();
          }}
        />
      ) : (
        <>
          {allPastReports.length > 0 ? (
            // show report history list page
            <Box p={1}>
              {allPastReports.slice(0, numberToShow).map((r, index) => {
                return (
                  <PastReportCardContainer
                    key={index}
                    pastReport={r}
                    index={index}
                  />
                );
              })}
            </Box>
          ) : (
            <Box
              src={IMAGES.mrrp.NoRequestedReport}
              alt="No Requested Report"
              sx={classes.noReportImage}
              component="img"
            />
          )}

          {!expandedPastReportListFlag &&
            allPastReports.length >
              DEFAULT_NUMBER_OF_REPORT_SUMMARIES_TO_SHOW && (
              <Box display="flex" justifyContent="center" p={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setExpandedPastReportListFlag(true);
                  }}
                  sx={classes.loadMoreButton}
                >
                  Load more
                </Button>
              </Box>
            )}

          {errorMessageReportHistoryReactElement ? (
            // display notice text when api returning W status code
            <Box mx={2} mt={3}>
              <SharpNoticePanel bgColor="extremeWarn">
                {errorMessageReportHistoryReactElement}
              </SharpNoticePanel>
            </Box>
          ) : null}
        </>
      )}
    </Page>
  );
};

export default MrrpPage;
