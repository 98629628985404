import { size } from "./size";

// ======================
// Font Weights
// ======================
export enum fontWeights {
  fontWeightLight = 300, //w2_light
  fontWeightRegular = 400, //w3_normal
  fontWeightMedium = 600, //w4_semiBold
  fontWeightBold = 700, //w5_bold
}

// Use this object as main reference for typography
// This can be used in the event that a component doesn't have a typography sx prop
export const typography = {
  title_bold: {
    fontSize: size.FONTSIZE_30,
    fontWeight: fontWeights.fontWeightBold,
  },
  title_semibold: {
    fontSize: size.FONTSIZE_30,
    fontWeight: fontWeights.fontWeightMedium,
  },
  header1_bold: {
    fontSize: size.FONTSIZE_24,
    fontWeight: fontWeights.fontWeightBold,
  },
  header1_semibold: {
    fontSize: size.FONTSIZE_24,
    fontWeight: fontWeights.fontWeightMedium,
  },
  header1_regular: {
    fontSize: size.FONTSIZE_24,
    fontWeight: fontWeights.fontWeightRegular,
  },
  header2_bold: {
    fontSize: size.FONTSIZE_22,
    fontWeight: fontWeights.fontWeightBold,
  },
  header2_semibold: {
    fontSize: size.FONTSIZE_22,
    fontWeight: fontWeights.fontWeightMedium,
  },
  header2_regular: {
    fontSize: size.FONTSIZE_22,
    fontWeight: fontWeights.fontWeightRegular,
  },
  header3_bold: {
    fontSize: size.FONTSIZE_20,
    fontWeight: fontWeights.fontWeightBold,
  },
  header3_semibold: {
    fontSize: size.FONTSIZE_20,
    fontWeight: fontWeights.fontWeightMedium,
  },
  header3_regular: {
    fontSize: size.FONTSIZE_20,
    fontWeight: fontWeights.fontWeightRegular,
  },
  header4_bold: {
    fontSize: size.FONTSIZE_18,
    fontWeight: fontWeights.fontWeightBold,
  },
  header4_semibold: {
    fontSize: size.FONTSIZE_18,
    fontWeight: fontWeights.fontWeightMedium,
  },
  header4_regular: {
    fontSize: size.FONTSIZE_18,
    fontWeight: fontWeights.fontWeightRegular,
  },
  body1_bold: {
    fontSize: size.FONTSIZE_16,
    fontWeight: fontWeights.fontWeightBold,
  },
  body1_semibold: {
    fontSize: size.FONTSIZE_16,
    fontWeight: fontWeights.fontWeightMedium,
  },
  body1_regular: {
    fontSize: size.FONTSIZE_16,
    fontWeight: fontWeights.fontWeightRegular,
  },
  body2_bold: {
    fontSize: size.FONTSIZE_14,
    fontWeight: fontWeights.fontWeightBold,
  },
  body2_semibold: {
    fontSize: size.FONTSIZE_14,
    fontWeight: fontWeights.fontWeightMedium,
  },
  body2_regular: {
    fontSize: size.FONTSIZE_14,
    fontWeight: fontWeights.fontWeightRegular,
  },
  caption1_bold: {
    fontSize: size.FONTSIZE_12,
    fontWeight: fontWeights.fontWeightBold,
  },
  caption1_semibold: {
    fontSize: size.FONTSIZE_12,
    fontWeight: fontWeights.fontWeightMedium,
  },
  caption1_regular: {
    fontSize: size.FONTSIZE_12,
    fontWeight: fontWeights.fontWeightRegular,
  },
  caption2_bold: {
    fontSize: size.FONTSIZE_10,
    fontWeight: fontWeights.fontWeightBold,
  },
  caption2_semibold: {
    fontSize: size.FONTSIZE_10,
    fontWeight: fontWeights.fontWeightMedium,
  },
  caption2_regular: {
    fontSize: size.FONTSIZE_10,
    fontWeight: fontWeights.fontWeightRegular,
  },
  label1_semibold: {
    fontSize: size.FONTSIZE_9,
    fontWeight: fontWeights.fontWeightMedium,
  },
};
