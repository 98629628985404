import { MedRefillState, MedRefillSystemSetting } from "./medrefill.types";

// Initial state representing the time when appointments are not initialized yet
export const initialState: MedRefillState = {
  requestType: null,
  reorder: false,
  showMyChartMedication: false,

  // =====================
  // PAGE-SPECIFIC
  // =====================

  institution: {
    facilityName: null,
    facilityId: null,
    cluster: null,
    clinicId: 0,
  },

  // Particulars
  particulars: {
    isPatient: true,
    requesterId: null,
    requesterNric: null,
    requesterName: null,
    patientName: null,
    patientNric: null,
    contactNumber: null,
    email: null,
    postalCode: null,
    unitNo: null,
    blockNo: null,
    address: null,
  },

  // Prescription
  prescription: {
    //pass in some mock data temporarily for order summary
    //need to remove after prescription section logic can save the input
    refillOriginalPrescriptionDate: null,
    files: [],
    topupReason: null,
    // topupReasonText: "Insufficient medications until next appointment",
    topupReasonText: null,
    topupOtherReason: null,
  },

  // Quantity
  quantity: {
    typeId: null,
    details: [],

    temp: {
      medicationName: null,
      number: null,
      UOMId: null,
      UOM: null,
    },
  },

  // Collection
  collection: {
    typeId: null,
    type: null,
    label: null,
    locationId: null,
    locationName: null,
    timeslot: null,
    date: null,
    postalCode: null,
    unitNo: null,
    blockNo: null,
    address: null,
    specialRequest: null,
    errorPostalCode: false,
  },

  // Payment
  payment: {
    subsidy: null,
    subsidyDescription: null,
    paymentInstructions: null,
  },

  // =====================
  // GENERAL
  // Parts / Slices of state that are used throughout the MedRefill flow, and are usually
  // hydrated by an API call that fetches some metadata about the user's input fields.
  // =====================

  systemSettings: {
    lookup: new Map<string, MedRefillSystemSetting[]>(),
    isLoading: false,
    hasErrored: null,
    errorMessage: null,
  },
  documentByCluster: {
    documentByClusterList: [],
    isLoading: false,
    hasErrored: null,
    errorMessage: null,
  },

  medications: {
    medicationList: [],
    isLoading: false,
    hasErrored: null,
    errorMessage: null,
  },

  clinics: {
    clinicList: [],
    isLoading: false,
    hasErrored: null,
    errorMessage: null,
  },

  collectionAndPayments: {
    isLoading: false,
    hasErrored: null,
    errorMessage: null,
    isPayInstructionEnabled: false,
    isMultiplePayment: false,
    collectionOptionList: [],
    paymentOptionList: [],
  },

  pastRequest: {
    offset: 0,
    summaryList: null,
    hasMoreRecords: false,
    isLoading: false,
    hasErrored: false,
    errorMessage: null,
    selectedRequesterId: null,
  },

  viewPastRequest: {
    isLoading: false,
    hasErrored: null,
    errorMessage: null,
    detail: null,
  },
  pastRequestMedicationImages: {
    images: [],
    isLoading: false,
    hasErrored: null,
    errorMessage: null,
  },
  fileUpload: {
    fileUploadPrescriptionErrorArray: [[false, false]],
    fileUploadDetails: [
      [
        {
          hasUploadedSuccessfully: false,
          isLoading: false,
          fileName: null,
          fileData: null,
          errorMessage: null,
        },
        {
          hasUploadedSuccessfully: false,
          isLoading: false,
          fileName: null,
          fileData: null,
          errorMessage: null,
        },
      ],
    ],
  },
};
