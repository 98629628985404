import { color } from "lib/theme/color";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    card: {
      boxShadow: "0px 0px 6px 0px " + color.RGBA_52,
      borderRadius: theme.spacing(1),
      paddingTop: 0,
      paddingBottom: 0,
    },
    divider: {
      marginLeft: theme.spacing(1.75),
      marginRight: theme.spacing(1.75),
    },
    cardItem: {
      padding: theme.spacing(1.75),
      justifyContent: "space-between",
      my: 0,
    },

    icon: {
      width: 24,
      height: 24,
    },
    title: {
      lineHeight: 1,
      color: theme.palette.grey[400],
      typography: theme.typography.body2_semibold,
    },
  } satisfies SxObjType;
};

export { sxStyles };
