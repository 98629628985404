import { RootStore } from "lib/redux/root/redux.types";
import { MobileIntegrationAppointmentState } from "../appointments.types";
import { initializeAppointmentsFromMobileIntegration } from "../";
import { validateOptionalField } from "lib/util/ValidatorUtil/fieldValidator/validateField";

const dispatchToReducer = (
  store: RootStore,
  apptObj: MobileIntegrationAppointmentState,
) => {
  validateOptionalField(apptObj, "id", true);

  store.dispatch(
    initializeAppointmentsFromMobileIntegration({
      appointmentId: apptObj.appointmentId,
      appointmentStartDateTime: apptObj.appointmentStartDateTime,
      institutionName: apptObj.institutionName,
      location: apptObj.location,
      institutionCode: apptObj.institutionCode,
      clinicCode: apptObj.clinicCode,
      departmentCode: apptObj.departmentCode,
      departmentName: apptObj.departmentName,
      targetSystem: apptObj.targetSystem,
      visitTypeId: apptObj.visitTypeId,
      serviceDisplayName: apptObj.serviceDisplayName,
      slotSearchDateRangeFrom: apptObj.slotSearchDateRangeFrom,
      slotSearchDateRangeTo: apptObj.slotSearchDateRangeTo,
      mobileRegQNo: apptObj.mobileRegQNo,
      actualizeStatus: apptObj.actualizeStatus,
      calendarTitle: apptObj.calendarTitle,
      calendarLocation: apptObj.calendarLocation,
      regInstitutionCode: apptObj.regInstitutionCode,
      consultType: apptObj.consultType,
      expectedOrderDate: apptObj.expectedOrderDate,
      linkedAppointments: apptObj.linkedAppointments,
      currentClinicCode: apptObj.currentClinicCode,
      hasPromptedRegTutorial: apptObj.hasPromptedRegTutorial,
      appointmentTimeToArrive: apptObj.appointmentTimeToArrive,
      regAppointmentId: apptObj.regAppointmentId,
      vcSupportStatus: apptObj.vcSupportStatus,
      centerContactNo: apptObj.centerContactNo,
      exceedRescheduleLimit: apptObj.exceedRescheduleLimit,
      encounterId: apptObj.encounterId,
      isHsgAppt: apptObj.isHsgAppt,
      slotId: apptObj.slotId,
      rescheduleToDays: apptObj.rescheduleToDays,
    }),
  );
};

export { dispatchToReducer };
