import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const iconSize = size.FONTSIZE_24;
const sxStyles = () => {
  return {
    radioButton: {
      alignItems: "flex-start",

      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: "30px",

      minWidth: "78px",
      "& .MuiFormControlLabel-label": {
        mt: `calc((${iconSize}px + ${theme.spacing(2)} - (1.5 * 1em)) / 2)`, // this is to offset first line height when flex-start
        mb: 1,
        mr: 2,
        color: theme.palette.grey[600],
        typography: theme.typography.body2_regular,
      },
      margin: "0px",
      mr: "1px", //this accounts for border 1px

      //* Apply checked styles to the parent
      "&:has(.Mui-checked)": {
        borderColor: theme.palette.primary.main,

        "& .MuiFormControlLabel-label": {
          color: theme.palette.primary.main,
        },
      },
    },
    radioIcon: {
      width: iconSize,
    },
    radio: {
      p: 1,
      ml: 0.125,
    },
  } satisfies SxObjType;
};

export { sxStyles };
