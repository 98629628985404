import React, { useState } from "react";
import { Typography, Button, Box } from "@mui/material";
import { PatientOrContactDetailSummarySectionProps } from "./PatientOrContactDetailSummarySection.types";
import { sxStyles } from "./PatientOrContactDetailSummarySection.styles";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import { validatePhoneNumber } from "lib/util/ValidatorUtil/phoneNumberValidator/phoneNumberValidator";
import { validateEmail } from "lib/util/ValidatorUtil/emailValidator/emailValidator";

const PatientOrContactDetailSummarySection = ({
  title,
  upperLabel,
  upperDetail,
  lowerLabel,
  lowerDetail,
  editable,
  showLowerLabel = true,
  validateAsPhoneAndEmail = false,
  setEditable,
  handleChanges,
}: PatientOrContactDetailSummarySectionProps) => {
  const classes = sxStyles();

  const [localUpperDetail, setLocalUpperDetail] = useState<string | null>(
    upperDetail,
  );
  const [localLowerDetail, setLocalLowerDetail] = useState<string | null>(
    lowerDetail,
  );
  const shouldValidate = editable && validateAsPhoneAndEmail;

  return (
    <>
      <Typography sx={classes.title}>{title}</Typography>

      {editable && handleChanges ? (
        <Box mb={2}>
          <SingleLineTextField
            placeholder={upperLabel}
            type={shouldValidate ? "number" : undefined}
            maxCharLength={shouldValidate ? 8 : undefined}
            value={localUpperDetail}
            handleChange={(event) => setLocalUpperDetail(event.target.value)}
            error={
              shouldValidate ? !validatePhoneNumber(localUpperDetail) : false
            }
            errorText="Enter a valid phone number"
          ></SingleLineTextField>
        </Box>
      ) : (
        <>
          <Typography sx={classes.label}>{upperLabel}</Typography>
          <Typography sx={classes.upperDetail}>{upperDetail}</Typography>
        </>
      )}

      {showLowerLabel && editable && handleChanges ? (
        <Box mb={2}>
          <SingleLineTextField
            placeholder={lowerLabel}
            value={localLowerDetail}
            handleChange={(event) => setLocalLowerDetail(event.target.value)}
            error={shouldValidate ? !validateEmail(localLowerDetail) : false}
            errorText="Enter a valid email"
          ></SingleLineTextField>
        </Box>
      ) : showLowerLabel ? (
        <>
          <Typography sx={classes.label}>{lowerLabel}</Typography>
          <Typography sx={classes.lowerDetail}>{lowerDetail}</Typography>
        </>
      ) : null}

      {editable && handleChanges && setEditable && (
        <Box pt={1.5} display="flex" justifyContent="flex-end">
          <Button
            sx={classes.button}
            variant="outlined"
            color="primary"
            onClick={() => {
              setEditable(false);
              setLocalUpperDetail(upperDetail);
              setLocalLowerDetail(lowerDetail);
            }}
          >
            Cancel
          </Button>
          <Button
            sx={classes.button}
            variant="contained"
            color="primary"
            size="large"
            disabled={
              !validatePhoneNumber(localUpperDetail) ||
              !validateEmail(localLowerDetail)
            }
            onClick={() =>
              handleChanges(localUpperDetail || "", localLowerDetail || "")
            }
          >
            Save
          </Button>
        </Box>
      )}
    </>
  );
};

export default PatientOrContactDetailSummarySection;
