import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests";
import { GET_MEDICATIONS_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { formatGetMedicationsErrorMessage } from "api/resources/messages/ErrorMessage";
import { GetMedicationsPayload } from "./getMedications.fromApi.types";

/**
 * Fetches the Medications for a certain institution cluster
 *
 * POST
 * Endpoint: /GetMedications
 *
 * @param {string} cluster a specific code to identify a group of hospitals or polyclinics
 */
const getMedications = async (
  cluster: string,
  oldTokenizedId: string | null,
): Promise<GetMedicationsPayload> => {
  try {
    const response = await axios.post<GetMedicationsPayload>(
      GET_MEDICATIONS_URL(),
      createRequestPayload(cluster, oldTokenizedId),
      getRequestOptions(),
    );
    return parseResponse(response, GET_MEDICATIONS_URL());
  } catch (error) {
    const msg = formatGetMedicationsErrorMessage(GET_MEDICATIONS_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  cluster: string,
  oldTokenizedId: string | null,
) => {
  return {
    Cluster: cluster,
    OldTokenizedId: oldTokenizedId,
  };
};

export { getMedications };
