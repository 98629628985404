import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { GET_INSTITUTIONS_BY_CLUSTERS_AND_GROUPS_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { GetInstitutionsByClustersAndGroupsPayload } from "./getInstitutionsByClustersAndGroups.fromApi.types";
import { formatGetInstitutionsByClustersAndGroupsErrorMessage } from "api/resources/messages/ErrorMessage";

/**
 * Fetches the list of institutions meant for appointment bookings.
 *
 * POST
 * Endpoint: /GetInstitutionsByClustersAndGroups
 */
const getInstitutionsByClustersAndGroups =
  async (): Promise<GetInstitutionsByClustersAndGroupsPayload> => {
    try {
      const response =
        await axios.post<GetInstitutionsByClustersAndGroupsPayload>(
          GET_INSTITUTIONS_BY_CLUSTERS_AND_GROUPS_URL(),
          createRequestPayload(),
          getRequestOptions(),
        );
      return parseResponse(
        response,
        GET_INSTITUTIONS_BY_CLUSTERS_AND_GROUPS_URL(),
      );
    } catch (error) {
      const msg = formatGetInstitutionsByClustersAndGroupsErrorMessage(
        GET_INSTITUTIONS_BY_CLUSTERS_AND_GROUPS_URL(),
      );
      console.error(msg);
      throw error;
    }
  };

const createRequestPayload = () => {
  return {};
};

export { getInstitutionsByClustersAndGroups };
