import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    topTextLayout: {
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(1, 2),
      color: theme.palette.text.secondary,
      display: "flex",
    },

    layout: {
      display: "flex",
      padding: theme.spacing(0, 1),
    },
    suggestion: {
      marginBottom: theme.spacing(2.5),
      padding: theme.spacing(2, 2, 0),
    },
    titleLayout: {
      padding: theme.spacing(0, 2, 0),
    },
    cardSectionLayout: {
      justifyContent: "space-between",
    },
    cardLayout: {
      width: "100%",
    },
    card: {
      margin: theme.spacing(1.5, 0),
      boxShadow: "none",
    },
    cardLeft: {
      backgroundColor: theme.palette.secondary.main,
      width: "100%",
      display: "flex",
      "& .MuiCard-root": {
        boxShadow: "none",
        "& .MuiCardHeader-root": {
          padding: theme.spacing(1, 2),
        },
        "& .MuiCardContent-root": {
          "& div.MuiBox-root": {
            display: "flex",
            alignItems: "center",
            paddingRight: 0,
            "& p": {
              paddingRight: 0,
            },
          },
        },
      },
    },

    titleLeftText: {
      color: theme.palette.text.primary,
      textAlign: "left",
      width: "50%",
    },
    titleRightText: {
      color: theme.palette.text.primary,
      textAlign: "left",
      width: "50%",
      marginLeft: theme.spacing(8),
    },

    arrow: {
      margin: "auto",
    },

    label: {
      // need a width here so that all text coming after this label is aligned
      // even with any additional lines
      width: theme.spacing(12),
    },
    labelText: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    text: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      lineHeight: 1.5,
    },
    textArea: {
      // need a width here so that long text will not push into the space of
      // the label on the left
      width: "75%",
    },
  } satisfies SxObjType;
};

export { sxStyles };
