import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageLayout from "lib/components/layout/Page/Page";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { RescheLinkedApptAvailableSlotsPageProps } from "./RescheLinkedApptAvailableSlotsPage.types";
import AvailableSlotsSectionContainer from "ui/appointment/components/common/availableSlots/AvailableSlotsSection/AvailableSlotsSectionContainer";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { isSameDayReschedule } from "api/appointment/GetAppointmentList/mappers/isSameDayBooking";
import RescheduleNotice from "../../pages/availableSlots/RescheduleNotice/RescheduleNotice";
import AvailableSlotsHeader from "ui/appointment/components/common/availableSlots/AvailableSlotsHeader/AvailableSlotsHeader";
import { BaseAppointment } from "api/appointment/GetAppointmentList/getAppointmentList.toUi.types";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

const RescheLinkedApptAvailableSlotsPage = ({
  memberIdentifier,
  appointment,
  onLoad,
  onSelect,
  updateVisitedSlotsPage,
}: RescheLinkedApptAvailableSlotsPageProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    onLoad();
    updateVisitedSlotsPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resolvedSearchDateFrom = getDateFromISOString(
    appointment?.slotSearchDateRangeFrom ?? "",
  );

  const resolvedSearchDateTo = getDateFromISOString(
    appointment?.slotSearchDateRangeTo ?? "",
  );

  const isSameDay = isSameDayReschedule(
    appointment?.departmentCode || null,
    appointment?.logoCode || null,
  );

  useEffect(() => {
    //log event to google analytics
    if (isSameDay) {
      logEventToGoogleAnalytics(EVENTS.VIEW_RESCHEDULE_SAMEDAY);
    } else {
      logEventToGoogleAnalytics(EVENTS.VIEW_RESCHEDULE_ADVANCE);
    }
  }, [isSameDay]);

  return (
    <PageLayout>
      <>
        {/* appointment.clinician */}
        {appointment && (
          <AvailableSlotsHeader
            appointmentType={castAppointmentType(appointment)}
            location={appointment.institutionName}
            service={appointment.serviceDisplayName}
            currentDateTime={formatDate(formatDateToISO(appointment.datetime))}
            inAdvanceNoticeComponent={<RescheduleNotice />}
          />
        )}

        {/* // Wait until we've checked if there are any values for the min & max
          slot search dates // before rendering the datepicker, so that the min
          & max dates are set properly */}
        <AvailableSlotsSectionContainer
          centerContactNo={null}
          isLinkedAppointment={true}
          isNewAppointment={false}
          isSameDayBooking={isSameDay}
          isUrti={false}
          minStartDate={resolvedSearchDateFrom}
          maxEndDate={resolvedSearchDateTo}
          shouldGetAvailableSlots={resolvedSearchDateTo !== null}
          onSelect={(slotId, slotDateTime) => {
            onSelect(slotDateTime, slotId);
            logEventToGoogleAnalytics(EVENTS.VIEW_RESCHEDULE_DETAILS);
            navigate(-1);
          }}
          onCancel={() => {
            navigate(-1);
          }}
        />
      </>
    </PageLayout>
  );
};

/**
 * Formats the date to be displayed.
 *
 * @param {BaseAppointment} appointment  an appointment in BaseAppointment type
 *
 * @returns {string} appointment type for AvailableSlotsHeader.
 *   Returns the type of the appointment casted to "upcoming" | "missed" | "open" | null,
 *   additonally, null if the appointment object is falsy.
 */
const castAppointmentType = (appointment: BaseAppointment | null) => {
  if (appointment) {
    return appointment.type === "actualised" ? null : appointment.type;
  } else {
    return null;
  }
};

export default RescheLinkedApptAvailableSlotsPage;
