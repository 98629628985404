import React from "react";
import { PATHS } from "lib/routing";
import ParticularsContainer from "../../../common/particulars/ParticularsContainer";

const VirtualConsultParticularsPage = () => {
  return (
    <ParticularsContainer
      pathToNextStep={PATHS.MED_VIRTUALCONSULT_ORDER_MEDICATION_COLLECTION.path}
    />
  );
};

export default VirtualConsultParticularsPage;
