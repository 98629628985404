import { connect } from "react-redux";
import { RootDispatch, RootState } from "lib/redux/root/redux.types";
import { PaymentMode } from "ui/mrrp/ducks/mrrp.types";
import SelectHospital from "./SelectHospital";
import {
  setSelectedHospital,
  setPaymentMode,
  clearState,
  resetGetHospitalApiStatus,
  resetGetReportOptionsApiStatus,
  resetFormLableApiStatus,
  setPatient,
  fetchFormLabels,
  fetchHospital,
  fetchReportOptions,
} from "ui/mrrp/ducks";
import { fetchUserParticulars } from "lib/redux/user";

const mapStateToProps = (state: RootState) => {
  return {
    userState: state.user,
    isLoadingHospital: state.mrrp.hospital.isLoading,
    hasErroredHospital: state.mrrp.hospital.hasErrored,
    errorMessageHospital: state.mrrp.hospital.errorMessage,
    allHospitals: state.mrrp.hospital.allHospitals,
    selectedHospitalCode: state.mrrp.hospital.selectedHospitalCode,
    isLoadingReportOptions: state.mrrp.reportOptions.isLoading,
    hasErroredReportOptions: state.mrrp.reportOptions.hasErrored,
    errorMessageReportOptions: state.mrrp.reportOptions.errorMessage,
    formLabelAPIStatus: state.mrrp.formLabel.apiStatus,
  };
};

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    onLoad: () => {
      dispatch(clearState());
    },
    onRedirect: () => {
      dispatch(resetGetHospitalApiStatus());
      dispatch(resetGetReportOptionsApiStatus());
      dispatch(resetFormLableApiStatus());
    },
    onGetUserParticulars: (isPatient: boolean) => {
      dispatch(fetchUserParticulars({ mapAddress: true, isPatient }));
    },
    onSetPatient: (name: string | null, nric: string | null) => {
      dispatch(setPatient({ name, nric }));
    },
    onGetHospital: () => {
      dispatch(fetchHospital(null));
    },
    onSaveHospital: (
      hospitalCode: string | null,
      hospitalName: string | null,
      paymentMode: PaymentMode,
    ) => {
      dispatch(setSelectedHospital({ hospitalCode, hospitalName }));
      dispatch(setPaymentMode(paymentMode));
    },
    onGetReportOptions: (hospitalCode: string | null) => {
      dispatch(fetchReportOptions(hospitalCode || "", ""));
    },
    onGetFormLabels: () => {
      dispatch(fetchFormLabels());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectHospital);
