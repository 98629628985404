import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SummarySectionFrame from "lib/components/summary/SummarySectionFrame/SummarySectionFrame";
import PatientOrContactDetailSummarySection from "lib/components/summary/PatientOrContactDetailSummarySection/PatientOrContactDetailSummarySection";
import { sxStyles } from "./TopupViewPastRequestDetailPage.styles";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import { PATHS } from "lib/routing";
import { TopupViewPastRequestDetailProps } from "./TopupViewPastRequestDetailPage.types";
import MedicationTopupDetailSummarySection from "ui/medrefill/components/common/orderSummary/MedicationTopupDetailSummarySection/MedicationTopupDetailSummarySection";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import { maskNric } from "ui/appointment/components/common/user/UserProfile/maskNric";
import DeliveryDetailSummarySection from "lib/components/summary/DeliveryDetailSummarySection/DeliveryDetailSummarySection";
import PharmacyDetailSummarySection from "ui/medrefill/components/common/orderSummary/PharmacyDetailSummarySection/PharmacyDetailSummarySection";
// import PaymentSummarySection from "ui/medrefill/components/common/orderSummary/OrderSummary/PaymentSummarySection/PaymentSummarySection";
// import PharmacyDetailSummarySection from "ui/medrefill/components/common/orderSummary/PharmacyDetailSummarySection/PharmacyDetailSummarySection";import {
//   selectViewPastRequestDetailPaymentInstructions,
//   selectViewPastRequestDetailSubsidy,
// } from "ui/medrefill/ducks/selectors";
// import { useAppSelector } from "lib/redux/hooks";

const TopupViewPastRequestDetailPage = ({
  isLoadingPastDetail,
  hasErroredViewPastRequest,
  errorMessageViewPastRequest,
  isLoadingImages,
  hasErroredImages,
  errorMessageImages,
  pastRequstHistory,
  isLoadingFormData,
  hasErroredSystemSettings,
  errorMessageSystemSettings,
  hasErroredMedications,
  errorMessageMedications,
  hasErroredCollectionPaymentDetails,
  errorMessageCollectionPaymentDetails,
  hasErroredDocByCluster,
  errorMessageDocByCluster,
  hasErroredClinics,
  errorMessageClinics,
  onLoad,
  pushPastRequestState,
  onReorder,
  canReOrderMedication = false,
}: TopupViewPastRequestDetailProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();

  const [stateCleared, setStateCleared] = useState(false);

  React.useEffect(() => {
    // Load data every time this component mounts
    onLoad();
    setStateCleared(true);
    logEventToGoogleAnalytics(EVENTS.VIEW_TOPUP_PAST_REQUEST_DETAIL);
  }, [onLoad]);

  // do the page redirection to do reordering only after all form data API calls have been finished successfully
  useEffect(() => {
    if (
      !isLoadingFormData &&
      hasErroredSystemSettings === false &&
      hasErroredMedications === false &&
      hasErroredCollectionPaymentDetails === false &&
      hasErroredDocByCluster === false &&
      hasErroredClinics === false &&
      stateCleared === true
    ) {
      setStateCleared(false);
      navigate(PATHS.MED_TOPUP_PRESCRIPTION.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingFormData,
    hasErroredSystemSettings,
    hasErroredMedications,
    hasErroredCollectionPaymentDetails,
    hasErroredDocByCluster,
    hasErroredClinics,
    navigate,
  ]);

  return (
    <Box mx={2}>
      {isLoadingPastDetail || isLoadingFormData ? (
        <Box display="flex" align-items="center" justifyContent="center" pt={3}>
          <CircularProgress />
        </Box>
      ) : hasErroredViewPastRequest ? (
        <ErrorDisplay
          errorMessage={errorMessageViewPastRequest}
          onTryAgain={() => onLoad()}
        />
      ) : hasErroredImages ? (
        <ErrorDisplay
          errorMessage={errorMessageImages}
          onTryAgain={() => onLoad()}
        />
      ) : hasErroredSystemSettings ? (
        <ErrorDisplay
          errorMessage={errorMessageSystemSettings}
          onTryAgain={() =>
            onReorder(
              pastRequstHistory.institution.facilityId || 0,
              pastRequstHistory.institution.cluster || "",
            )
          }
        />
      ) : hasErroredMedications ? (
        <ErrorDisplay
          errorMessage={errorMessageMedications}
          onTryAgain={() =>
            onReorder(
              pastRequstHistory.institution.facilityId || 0,
              pastRequstHistory.institution.cluster || "",
            )
          }
        />
      ) : hasErroredCollectionPaymentDetails ? (
        <ErrorDisplay
          errorMessage={errorMessageCollectionPaymentDetails}
          onTryAgain={() =>
            onReorder(
              pastRequstHistory.institution.facilityId || 0,
              pastRequstHistory.institution.cluster || "",
            )
          }
        />
      ) : hasErroredDocByCluster ? (
        <ErrorDisplay
          errorMessage={errorMessageDocByCluster}
          onTryAgain={() =>
            onReorder(
              pastRequstHistory.institution.facilityId || 0,
              pastRequstHistory.institution.cluster || "",
            )
          }
        />
      ) : hasErroredClinics ? (
        <ErrorDisplay
          errorMessage={errorMessageClinics}
          onTryAgain={() =>
            onReorder(
              pastRequstHistory.institution.facilityId || 0,
              pastRequstHistory.institution.cluster || "",
            )
          }
        />
      ) : (
        <>
          <Typography sx={classes.title}>Order Summary</Typography>
          <SummarySectionFrame>
            <Typography sx={classes.facilityTitle}>
              Prescribing Institution
            </Typography>
            <Typography sx={classes.facilityDetail}>
              {pastRequstHistory.institution.facilityName}
            </Typography>
          </SummarySectionFrame>
          <SummarySectionFrame>
            <PatientOrContactDetailSummarySection
              title="Patient's Detail"
              upperLabel="Name"
              upperDetail={pastRequstHistory.particulars.patientName}
              lowerLabel="NRIC / Birth Certificate / FIN"
              lowerDetail={maskNric(
                pastRequstHistory.particulars.patientNric || "",
              )}
            ></PatientOrContactDetailSummarySection>
          </SummarySectionFrame>
          <SummarySectionFrame>
            <PatientOrContactDetailSummarySection
              title="Contact Details"
              upperLabel="Contact Number"
              upperDetail={pastRequstHistory.particulars.contactNumber}
              lowerLabel="Email"
              lowerDetail={pastRequstHistory.particulars.email}
            ></PatientOrContactDetailSummarySection>
          </SummarySectionFrame>
          <SummarySectionFrame>
            <MedicationTopupDetailSummarySection
              topupReasonText={
                pastRequstHistory.prescription.topupReasonText
                  ? pastRequstHistory.prescription.topupReasonText
                  : pastRequstHistory.prescription.topupOtherReason
              }
              files={pastRequstHistory.prescription.files}
            ></MedicationTopupDetailSummarySection>
            {isLoadingImages && (
              <Box sx={classes.overlayCircularProgress}>
                <CircularProgress sx={classes.loadingIcon} />
              </Box>
            )}
          </SummarySectionFrame>
          <SummarySectionFrame>
            {pastRequstHistory.collection.typeId === 3 ? (
              <DeliveryDetailSummarySection
                postalCode={pastRequstHistory.collection.postalCode}
                blockNo={pastRequstHistory.collection.blockNo}
                unitNo={pastRequstHistory.collection.unitNo}
                address={pastRequstHistory.collection.address}
                showSpecialRequest={true}
                specialRequest={pastRequstHistory.collection.specialRequest}
              ></DeliveryDetailSummarySection>
            ) : (
              <PharmacyDetailSummarySection
                facilityName={pastRequstHistory.institution.facilityName}
                locationName={pastRequstHistory.collection.locationName}
                specialRequest={pastRequstHistory.collection.specialRequest}
                date={pastRequstHistory.collection.date}
                timeslot={pastRequstHistory.collection.timeslot}
              ></PharmacyDetailSummarySection>
            )}
          </SummarySectionFrame>
          {/* {subsidy && (
            <SummarySectionFrame>
              <PaymentSummarySection
                subsidyDescription={
                  subsidy?.split("|").filter((a) => a !== "") ?? []
                }
                paymentInstructions={paymentInstructions ?? ""}
              />
            </SummarySectionFrame>
          )} */}
          <Box pb={2} pt={3}>
            <ButtonsFooter
              nextButtonText="Re-order"
              cancelButtonText="Back"
              isDisabled={!canReOrderMedication}
              onClickNext={() => {
                pushPastRequestState(pastRequstHistory);
                onReorder(
                  pastRequstHistory.institution.facilityId || 0,
                  pastRequstHistory.institution.cluster || "",
                );
                logEventToGoogleAnalytics(EVENTS.REORDER_TOPUP_PAST_REQUEST);
              }}
              onClickCancel={() => navigate(-1)}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default TopupViewPastRequestDetailPage;
