import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = ({ isTodayEnabled = false }: { isTodayEnabled: boolean }) => {
  return {
    listContainer: {
      display: "flex",
      justifyContent: isTodayEnabled ? "space-between" : "flex-end",
    },
    list: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      mr: 2,
    },
    listItem: {
      width: "unset",
    },
    listItemButton: {
      flex: 0,
    },
    listItemText: {
      "& .MuiListItemText-primary": {
        typography: theme.typography.body2_semibold,
        color: theme.palette.primary.main,
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
