import { useState, useEffect } from "react";
import { Summary } from "api/medrefill/GetMedicationSummary/getMedicationSummary.types";
import { getMostRecentMedicationSummary } from "api/medrefill/GetMostRecentMedicationSummary/getMostRecentMedicationSummary";
import { AxiosError } from "axios";

const useGetMostRecentMedicationSummary = (
  pageFlag: boolean,
  memberIdentifier: string | null,
  oldMemberIdentifier: string | null,
  oldTokenizedId: string | null,
): [
  Summary | null, //data
  boolean, //isLoading
  boolean | null, //hasErrored
  string | null, //errorMessage
  string | null,
  string | null,
] => {
  const [data, setData] = useState<Summary | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [oldMemberIdentifierFromRes, setOldMemberIdentifierFromRes] = useState<
    string | null
  >(oldMemberIdentifier);
  const [oldTokenizedIdFromRes, setOldTokenizedIdFromRes] = useState<
    string | null
  >(oldTokenizedId);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const responseData = await getMostRecentMedicationSummary(
          memberIdentifier,
          oldMemberIdentifier,
          oldTokenizedId,
        );

        setData(responseData.Data);
        setHasErrored(false);
        setErrorMessage(null);
        setOldMemberIdentifierFromRes(responseData.OldMemberIdentifier);
        setOldTokenizedIdFromRes(responseData.OldTokenizedId);
      } catch (error) {
        setData(null);
        setHasErrored(true);
        if (error instanceof AxiosError) {
          setErrorMessage(error.response?.data.Message);
        }
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageFlag, memberIdentifier]);

  return [
    data,
    isLoading,
    hasErrored,
    errorMessage,
    oldMemberIdentifierFromRes,
    oldTokenizedIdFromRes,
  ];
};

export { useGetMostRecentMedicationSummary };
