import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests";
import { GetFacilitiesPayload } from "./getFacilities.fromApi.types";
import { GET_FACILITIES_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { formatGetFacilitiesErrorMessage } from "api/resources/messages/ErrorMessage";
import { MedRefillRequestType } from "ui/medrefill/ducks/medrefill.types";

/**
 * Fetches the facilites for a certain institution cluster based on prescription type
 *
 * POST
 * Endpoint: /GetFacilities
 *
 * @param {MedRefillRequestType} prescriptionType type of medication services, either "REFILL" or "TOPUP"
 *
 * @returns {GetFacilitiesPayload} Response payload from the remote API
 */
const getFacilities = async (
  prescriptionType: MedRefillRequestType,
  oldTokenizedId: string | null,
): Promise<GetFacilitiesPayload> => {
  try {
    const response = await axios.post<GetFacilitiesPayload>(
      GET_FACILITIES_URL(),
      createRequestPayload(prescriptionType, oldTokenizedId),
      getRequestOptions(),
    );
    return parseResponse(response, GET_FACILITIES_URL());
  } catch (error) {
    const msg = formatGetFacilitiesErrorMessage(GET_FACILITIES_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  prescriptionType: MedRefillRequestType,
  oldTokenizedId: string | null,
) => {
  return {
    PrescriptionType: prescriptionType,
    OldTokenizedId: oldTokenizedId,
  };
};

export { getFacilities };
