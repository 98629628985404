import { RootState } from "lib/redux/root/redux.types";
import { connect } from "react-redux";
import CreateApptForm from "./CreateApptForm";

const mapStateToProps = (state: RootState) => {
  const institutionName: string = state.appointments.create.selectedInstitution
    ? state.appointments.create.selectedInstitution
    : "";
  const institutionCode: string = state.appointments.create
    .selectedInstitutionCode
    ? state.appointments.create.selectedInstitutionCode
    : "";
  const specialtyValue: string | null =
    state.appointments.systemSettings.values["ServiceList"]?.Value ?? null;
  // if Institution Code has own specialty list, use it. Removed populating "Others" as HH does not render it. To keep code clean, only list institutes with specialty. Default will be no specialty.
  const specialtyForInstitution: string | null = specialtyValue
    ? JSON.parse(specialtyValue)[institutionCode] ?? null
    : null;
  const specialtyList: string[] = specialtyForInstitution
    ? specialtyForInstitution.split("|").sort()
    : [];

  return {
    institutionName: institutionName,
    specialtyList: specialtyList.map((specialty) => {
      return {
        id: specialty,
        text: specialty,
      };
    }),
  };
};

export default connect(mapStateToProps)(CreateApptForm);
