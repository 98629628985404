import { useCallback, useMemo } from "react";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { ErrorModalProps } from "./ErrorModal.types";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectCurrentFlowAction } from "lib/redux/navigation/selectors";
import {
  concealCancelButton,
  liveAgentCreateCancelRescheduleHelper,
  pathCreateCancelRescheduleHelper,
  renderCancelButtonText,
  renderNextButtonText,
  renderTitle,
  renderAdditionalButtonText,
  shouldNotDisplayAdditionalButton,
  shouldDisplayMakeOfflineRequestTextHelper,
  toHandleCancelButtonClick,
  toHandleModalClose,
  toHandleNextButtonClick,
  toHandleAdditionalButtonClick,
  toMapToPath,
  toShowBody,
  pathMyInfoHelper,
} from "./HelperFunctions";
import { selectUser } from "lib/redux/user/selectors";
import { PATHS } from "lib/routing";

export const INCOMPLETE_PROFILE_ERROR_BUTTON_TEXT = "Complete my profile";
// export const CONNECT_TO_LIVE_AGENT_BUTTON_TEXT = "CONNECT TO LIVE AGENT";
export const CONNECT_TO_LIVE_AGENT_BUTTON_TEXT = "Chat with us";

const ErrorModal = ({
  open,
  path,
  customizedButtonText,
  errorMessage,
  closeErrorModal,
}: ErrorModalProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userProfile = useAppSelector(selectUser);

  const currentFlowAction = useAppSelector(selectCurrentFlowAction);

  const pathCreateCancelReschedule = useMemo(
    () => pathCreateCancelRescheduleHelper(path),
    [path],
  );

  const pathMyInfo = useMemo(() => pathMyInfoHelper(path), [path]);

  const pathIHA = useMemo(
    () => path === PATHS.APPOINTMENT_QUEUECHIT_JOURNEY,
    [path],
  );

  const shouldDisplayMakeOfflineRequestText = useMemo(
    () =>
      shouldDisplayMakeOfflineRequestTextHelper(
        errorMessage,
        pathCreateCancelReschedule,
      ),
    [errorMessage, pathCreateCancelReschedule],
  );

  const liveAgentCreateCancelReschedule = useMemo(
    () =>
      liveAgentCreateCancelRescheduleHelper(
        pathCreateCancelReschedule,
        customizedButtonText,
      ),
    [pathCreateCancelReschedule, customizedButtonText],
  );

  const title = useMemo(
    () => renderTitle(path, customizedButtonText),
    [customizedButtonText, path],
  );

  const isCancelButtonHidden = useMemo(
    () =>
      concealCancelButton(
        pathCreateCancelReschedule,
        customizedButtonText || null,
      ),
    [customizedButtonText, pathCreateCancelReschedule],
  );

  const cancelButtonText = useMemo(
    () =>
      renderCancelButtonText(
        liveAgentCreateCancelReschedule,
        shouldDisplayMakeOfflineRequestText,
      ),
    [liveAgentCreateCancelReschedule, shouldDisplayMakeOfflineRequestText],
  );

  const concealAdditionalButton = useMemo(
    () =>
      shouldNotDisplayAdditionalButton(
        liveAgentCreateCancelReschedule,
        shouldDisplayMakeOfflineRequestText,
      ),
    [liveAgentCreateCancelReschedule, shouldDisplayMakeOfflineRequestText],
  );

  const additionalButtonText = useMemo(() => renderAdditionalButtonText(), []);

  const showBody = useCallback(
    () =>
      toShowBody(
        errorMessage,
        pathCreateCancelReschedule,
        pathMyInfo,
        pathIHA,
        shouldDisplayMakeOfflineRequestText,
        customizedButtonText ?? null,
      ),
    [
      errorMessage,
      pathCreateCancelReschedule,
      pathMyInfo,
      pathIHA,
      shouldDisplayMakeOfflineRequestText,
      customizedButtonText,
    ],
  );

  const mapToPath = useCallback(
    () => toMapToPath(currentFlowAction, navigate),
    [currentFlowAction, navigate],
  );

  const nextButtonText = useMemo(
    () =>
      renderNextButtonText(
        customizedButtonText ?? null,
        pathCreateCancelReschedule,
        pathMyInfo,
        shouldDisplayMakeOfflineRequestText,
      ),
    [
      customizedButtonText,
      pathCreateCancelReschedule,
      pathMyInfo,
      shouldDisplayMakeOfflineRequestText,
    ],
  );

  const handleNextButtonClick = useCallback(
    () =>
      toHandleNextButtonClick(
        closeErrorModal,
        customizedButtonText ?? null,
        navigate,
        mapToPath,
        path,
        pathCreateCancelReschedule,
        liveAgentCreateCancelReschedule,
        shouldDisplayMakeOfflineRequestText,
        dispatch,
        userProfile,
      ),
    [
      closeErrorModal,
      customizedButtonText,
      navigate,
      mapToPath,
      path,
      pathCreateCancelReschedule,
      liveAgentCreateCancelReschedule,
      shouldDisplayMakeOfflineRequestText,
      dispatch,
      userProfile,
    ],
  );

  const handleCancelButtonClick = useCallback(
    () =>
      toHandleCancelButtonClick(
        path,
        customizedButtonText ?? null,
        closeErrorModal,
        pathCreateCancelReschedule,
        navigate,
      ),
    [
      path,
      customizedButtonText,
      closeErrorModal,
      pathCreateCancelReschedule,
      navigate,
    ],
  );

  const handleModalClose = useCallback(
    () => toHandleModalClose(closeErrorModal, path, customizedButtonText),
    [closeErrorModal, path, customizedButtonText],
  );

  const handleAdditionalButtonClick = useCallback(
    () => toHandleAdditionalButtonClick(closeErrorModal),
    [closeErrorModal],
  );

  return (
    <ConfirmationModal
      title={title || "Service Error"}
      open={open}
      body={showBody()}
      onClose={() => handleModalClose()}
      nextButtonText={nextButtonText}
      onClickNext={() => handleNextButtonClick()}
      hideCancelButton={isCancelButtonHidden}
      cancelButtonText={cancelButtonText}
      onClickCancel={() => handleCancelButtonClick()}
      additionalButtonText={additionalButtonText}
      hideAdditionalButton={concealAdditionalButton}
      onClickAdditional={() => handleAdditionalButtonClick()}
    />
  );
};

export default ErrorModal;
