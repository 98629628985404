import {
  GetAppointmentListPayload,
  AppointmentDetailSchema,
} from "api/appointment/GetAppointmentList/getAppointmentList.fromApi.types";
import { parseISO } from "date-fns";
import {
  AppointmentsLookUpAndListing,
  AppointmentsLookup,
  Appointment,
  AppointmentType,
} from "../getAppointmentList.toUi.types";

/**
 * Maps fetched GetAppointmentList API payload to a schema that the
 * UI components can consume.
 *
 * @param {GetAppointmentListPayload | null} payload
 *   The payload which will be transformed
 * @returns {AppointmentsLookUpAndListing}
 *   Appointments data in a schema the UI can support
 */
const mapApiDataToProps = (
  payload: GetAppointmentListPayload | null,
): AppointmentsLookUpAndListing => {
  if (!payload) {
    return {
      lookup: {},
      listing: {
        upcoming: [],
        missed: [],
        open: [],
        actualised: [],
      },
    };
  } else {
    const {
      UpcomingAppointments,
      MissedAppointments,
      OpenAppointments,
      ActualisedAppointments,
    } = payload;

    // lookups (flatten individual lists of appointments)
    const upcomingLookup = generateLookup(UpcomingAppointments, "upcoming");
    const missedLookup = generateLookup(MissedAppointments, "missed");
    const openLookup = generateLookup(OpenAppointments, "open");

    // listings (categorized)
    const upcomingList = generateListing(UpcomingAppointments, "upcoming");
    const missedList = generateListing(MissedAppointments, "missed");
    const openList = generateListing(OpenAppointments, "open");
    const actualisedList = generateListing(
      ActualisedAppointments,
      "actualised",
    );

    return {
      lookup: {
        ...upcomingLookup,
        ...missedLookup,
        ...openLookup,
      },
      listing: {
        upcoming: upcomingList,
        missed: missedList,
        open: openList,
        actualised: actualisedList,
      },
    };
  }
};

// ===========================
// MAP LOOKUPS & LISTINGS
// ===========================

/**
 * Generate a lookup of appointments data
 *
 * @param {AppointmentDetailSchema[]} appointmentLists  Lists of backend appointment detail objects
 * @param {AppointmentType} apptType  Appointment type
 *
 * @returns {AppointmentsLookup} Appointments Lookup object
 */
const generateLookup = (
  appointmentLists: AppointmentDetailSchema[],
  apptType: AppointmentType,
): AppointmentsLookup => {
  const result: AppointmentsLookup = {};

  appointmentLists?.forEach((appt) => {
    // every appointment has a unique ID
    result[appt.AppointmentId] = mapAppointmentFromApi(appt, apptType);
  });

  return result;
};

/**
 * Generate a list of appointments
 *
 * @param {AppointmentDetailSchema[]} appointmentLists  Lists of backend appointment detail objects
 * @param {AppointmentType} apptType  Appointment type
 *
 * @returns {Appointment[]}  List of appointments of a particular type. An empty list is returned
 *   if the appointment list provided is falsy.
 */
const generateListing = (
  apptList: AppointmentDetailSchema[],
  apptType: AppointmentType,
): Appointment[] => {
  if (apptList) {
    return apptList.map((appt) => {
      const appointment = mapAppointmentFromApi(appt, apptType);

      return {
        ...appointment,
        linkedAppointments: mapLinkedAppointmentsFromApi(
          appt.LinkedAppointments,
          apptType,
        ),
      };
    });
  } else {
    return [];
  }
};

/**
 * Maps linked appointments (a child of a main appointment object)
 */
const mapLinkedAppointmentsFromApi = (
  linkedAppts: AppointmentDetailSchema[] | null,
  apptType: AppointmentType,
): Appointment[] | null => {
  if (linkedAppts) {
    return linkedAppts.map((l) => mapAppointmentFromApi(l, apptType));
  } else {
    return null;
  }
};

// ===========================
// MAP A SINGLE APPOINTMENT
// ===========================

const mapAppointmentFromApi = (
  appt: AppointmentDetailSchema,
  apptType: AppointmentType,
): Appointment => {
  const mappedAppointment: Appointment = {
    type: apptType,

    regAppointmentId: appt.RegAppointmentId ?? "",
    title: appt.Title ?? "",
    appointmentId: appt.AppointmentId ?? "",
    datetime: parseISO(appt.AppointmentStartDateTime ?? ""),
    appointmentStartDateTime: appt.AppointmentStartDateTime ?? "",
    appointmentEndDateTime: appt.AppointmentEndDateTime ?? "",
    location: appt.Location ?? "",
    specialty: appt.Specialty ?? "",
    clinician: appt.Clinician ?? "",
    clinicianCode: appt.ClinicianCode,
    clinicCode: appt.ClinicCode,
    institutionCode: appt.InstitutionCode ?? "",
    logoCode: appt.LogoCode,
    institutionName: appt.InstitutionName ?? "",
    caseNumber: appt.CaseNumber,
    treatmentRoom: appt.TreatmentRoom,
    caseCheckDigit: appt.CaseCheckDigit,
    resource: appt.Resource || "",
    departmentCode: appt.DepartmentCode,
    canMake: appt.CanMake,
    canReschedule: appt.CanReschedule,
    canCancel: appt.CanCancel,
    rescheduleToDays: appt.RescheduleToDays,
    // CCDP Behaviour:
    // "Y" if this appointment can be registered (i.e. can display the "REGISTER" button)
    // "N" or NULL if this appointment can't be registered yet
    // "E" if this appointment can't be registered due to an error
    isMobileRegAvailable: appt.IsMobileRegAvailable === "Y" ? true : false,
    encounterId: appt.EncounterId,
    actionMessage: appt.ActionMessage,
    slotHelpInfo: appt.SlotHelpInfo,
    visitType: appt.VisitType,
    visitTypeDescription: appt.VisitTypeDescription,
    visitTypeId: appt.VisitTypeId,
    status: appt.Status,
    vcSupportStatus: appt.VcSupportStatus,
    mobileRegQNo: appt.MobileRegQNo,
    reminder: appt.Reminder,
    linkId: appt.LinkId,
    consultType: appt.ConsultType,
    linkedAppointments: mapLinkedAppointmentsFromApi(
      appt.LinkedAppointments,
      apptType,
    ),
    sourceSystem: appt.SourceSystem,
    isQuestionnaireAvailable: appt.IsQuestionnaireAvailable,
    slotId: appt.SlotId,
    screeningStatus: appt.ScreeningStatus,
    mpayIndicator: appt.MPayIndicator,
    departmentName: appt.DepartmentName,
    zoneInfo: appt.ZoneInfo,
    isHsgAppt: appt.IsHsgAppt,
    calendarTitle: appt.CalendarTitle,
    calendarLocation: appt.CalendarLocation,
    slotSearchDateRangeFrom: appt.SlotSearchDateRangeFrom,
    slotSearchDateRangeTo: appt.SlotSearchDateRangeTo,
    appointmentTimeToArrive: appt.AppointmentTimeToArrive || "",
    duration: appt.Duration,
    expectedOrderDate: appt.ExpectedOrderDate,
    callSlotSearchDateRangeSeparately: appt.CallSlotSearchDateRangeSeparately,
    serviceDisplayName: appt.ServiceDisplayName || "",
    regInstitutionCode: appt.RegInstitutionCode,
    regErrorMessage:
      appt.IsMobileRegAvailable === "E" ? appt.RegErrorMessage : null,
    checkInIndicator: appt.CheckInIndicator,
    formFlowDisclaimer: appt.FormFlowDisclaimer,
    actualizeStatus: appt.ActualizeStatus,
    exceedRescheduleLimit: appt.ExceedRescheduleLimit,
    visitTypeName: appt.VisitTypeName,
    additionInformation: appt.AdditionInformation,
    supportingInfo: appt.SupportingInfo,
    participantURL: appt.ParticipantURL,
    encounterStatusCode: appt.EncounterStatusCode,
    encounterStatusDisplay: appt.EncounterStatusDisplay,
    encounterModel: appt.EncounterModel,
    encounterActions: appt.EncounterActions ?? [],
    isLoggingProceedToVideoConsultRequired:
      appt.IsLoggingProceedToVideoConsultRequired ?? false,
    zoomMeetingId: appt.ZoomMeetingId,
    zoomMeetingPassword: appt.ZoomMeetingPassword,
    formattedZoomMeetingId: appt.FormattedZoomMeetingId,
    hasTeleconsultmessage: appt.HasTeleconsultmessage || false,
    isCrossInstitution: appt.IsCrossInstitution,
    isVirtualConsultAppointment: appt.VcSupportStatus === "supported",
    hintMsg: appt.HintMsg ?? null,
    showShareVCLinks: appt.ShowShareVCLinks ?? false,
    zoomLinkStatus: appt.ZoomLinkStatus,
    zoomLinkErrorMessage: appt.ZoomLinkErrorMessage,
    centerContactNo: appt.CenterContactNo,
    appointmentIdToRegister: appt.AppointmentIdToRegister,
  };

  return mappedAppointment;
};

export { mapApiDataToProps, mapAppointmentFromApi };
