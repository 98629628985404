import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import Datepicker from "lib/components/formInputs/Datepicker/Datepicker";
import { PreferredDatepickerProps } from "./PreferredDatepicker.types";
import {
  computeDisabledDates,
  getMinDate,
  getMaxDate,
} from "./computeDisabledDates";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";

const PreferredDatepicker = ({
  label,
  date,
  location,
  onSelectDate,
}: PreferredDatepickerProps) => {
  const TODAY = new Date();
  return (
    <Datepicker
      label={label}
      value={getDateFromISOString(date)}
      format="d MMM yyyy"
      onSelect={onSelectDate}
      minDate={formatDateToISO(getMinDate(location, TODAY))}
      maxDate={formatDateToISO(getMaxDate(location, TODAY))}
      shouldDisableDates={computeDisabledDates(location)}
    />
  );
};

export default PreferredDatepicker;
