import { connect } from "react-redux";
import { RootState } from "lib/redux/root/redux.types";
import AvailableBundledSlotsList from "./AvailableBundledSlotsList";

const mapStateToProps = (state: RootState) => {
  const apptId = state.appointments.appointmentMeta.appointmentId;

  const appointment = state.appointments.init.lookup[apptId || 0];

  return {
    appointment,
    currentFlowAction: state.navigation.currentFlowAction,
  };
};

export default connect(mapStateToProps)(AvailableBundledSlotsList);
