import { color } from "lib/theme/color";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    headerRegion: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(1),
    },
    reportName: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_semibold,
      margin: theme.spacing(1.5, 0.5),
      width: "100%",
    },
    downloadImage: {
      margin: theme.spacing(0, 0.5),
    },
    reportRegion: {
      backgroundColor: theme.palette.common.white,
      boxShadow: "0px 0px 6px " + color.RGBA_52,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1.5),
      margin: theme.spacing(1.5, 1.5, 1),
      wordBreak: "break-word",
    },
    detailLabel: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    detailValue: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_bold,
      marginBottom: theme.spacing(2),
    },
  } satisfies SxObjType;
};

export { sxStyles };
