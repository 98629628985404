import { Box, Skeleton } from "@mui/material";
import { sxStyles } from "./QueueCardSkeleton.styles";

const QueueCardSkeleton = () => {
  const classes = sxStyles();
  return (
    <Box sx={classes.mainContainer}>
      <Box sx={classes.card}>
        <Skeleton animation="wave" sx={{ width: 100, margin: "0 auto" }} />
      </Box>
      <Box sx={classes.headerContainer}>
        <Skeleton
          animation="wave"
          sx={{ width: 200, height: 50, margin: "0 auto" }}
        />
      </Box>
      <Box sx={classes.bodyContainer}>
        <Skeleton animation="wave" sx={{ width: 200 }} />
        <Skeleton animation="wave" sx={{ width: 200 }} />
        <Skeleton width={"70%"} animation="wave" sx={{ margin: "0 auto" }} />
      </Box>
    </Box>
  );
};

export default QueueCardSkeleton;
