import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { PreCancelRescheduleModalProps } from "./PreCancelRescheduleModal.types";

const PreCancelRescheduleModal = ({
  isOpen,
  type,
  onClickFirstButton,
  onClickSecondButton,
}: PreCancelRescheduleModalProps) => {
  return (
    <ConfirmationModal
      title={"Your health matters"}
      body={
        type === "cancel"
          ? "Cancelling may disrupt your treatment plan. Please do not cancel unless necessary."
          : "Rescheduling may disrupt your treatment plan. Please do not reschedule unless necessary."
      }
      open={isOpen}
      nextButtonText="Back"
      cancelButtonText="Next"
      onClickNext={onClickFirstButton}
      onClickCancel={onClickSecondButton}
    />
  );
};

export default PreCancelRescheduleModal;
