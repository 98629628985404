import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { GetMedicationImagesPayload } from "./getMedicationImages.fromApi.types";
import { GET_MEDICATION_IMAGES_URL } from "api/resources/configs/URL";
import { formatGetMedicationImagesErrorMessage } from "api/resources/messages/ErrorMessage";

const getMedicationImages = async (
  requestorId: number,
  memberIdentifier: string | null,
  oldMemberIdentifier: string | null,
  oldTokenizedId: string | null,
): Promise<GetMedicationImagesPayload> => {
  try {
    const response = await axios.post<GetMedicationImagesPayload>(
      GET_MEDICATION_IMAGES_URL(),
      createRequestPayload(
        requestorId,
        memberIdentifier,
        oldMemberIdentifier,
        oldTokenizedId,
      ),
      getRequestOptions(),
    );
    return parseResponse(response, GET_MEDICATION_IMAGES_URL());
  } catch (error) {
    const msg = formatGetMedicationImagesErrorMessage(
      GET_MEDICATION_IMAGES_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  requestorId: number,
  memberIdentifier: string | null,
  oldMemberIdentifier: string | null,
  oldTokenizedId: string | null,
) => {
  return {
    RequesterId: requestorId,
    MemberIdentifier: memberIdentifier,
    OldMemberIdentifier: oldMemberIdentifier,
    OldTokenizedId: oldTokenizedId,
  };
};

export { getMedicationImages };
