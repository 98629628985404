import { Box, Typography } from "@mui/material";
import { sxStyles } from "./Tags.styles";
import { TagsProps, StyleProps } from "./Tags.types";

const Tags = ({ label, colorChoice = "lightOrange", labelSize }: TagsProps) => {
  const styleProps: StyleProps = { colorChoice, labelSize };
  const classes = sxStyles(styleProps);

  return (
    <Box sx={classes.container}>
      <Typography sx={classes.label}>{label}</Typography>
    </Box>
  );
};

export default Tags;
