/**
 * Strips a leading "/" from the beginning of a string if any exists.
 *
 * @param {string} str Input string to strip
 */
export const stripLeadingSlash = (str: string) => {
  try {
    if (str && str.startsWith("/")) {
      return str.slice(1);
    }
  } catch (error) {
    // return the input itself since this might not have been compatible
    // with this function, e.g. input is a number or object
    return str;
  }
  return str;
};
