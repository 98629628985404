import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = (isVcp: boolean | null) => {
  return {
    cardHeaderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    cardDate: isVcp
      ? {
          backgroundColor: theme.palette.secondary.light,
          borderRadius: theme.spacing(1),
          border: "1px solid " + theme.palette.grey[100],
          width: 48,
          margin: 1,
          marginTop: 2,
          padding: 0,
          textAlign: "center",
          alignItems: "start",
          display: "block",

          "&::before": {
            content: "' '",
            display: "block",
            height: "3px",
            width: theme.spacing(2.5),
            mt: 0.5,
            mb: 1,
            mx: "auto",
            borderRadius: 2,
            backgroundColor: theme.palette.common.white,
          },
          "&::after": {
            content: "' '",
            display: "block",
            height: "8px",
            width: "8px",
            mb: 0.5,
            mt: 1,
            mx: "auto",
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.common.white,
          },
          "& .MuiCardHeader-title": {
            color: theme.palette.grey[400],
            typography: theme.typography.body2_regular,
            textTransform: "uppercase",
            margin: theme.spacing(0.5, "auto"),
            width: 34,
            lineHeight: "19.07px",
          },
          "& .MuiCardHeader-subheader": {
            color: theme.palette.warning.main,
            typography: theme.typography.title_bold,
            lineHeight: "40.85px",
            textTransform: "uppercase",
            margin: theme.spacing(0),
          },
          "& .MuiCardHeader-action": {
            color: theme.palette.grey[400],
            typography: theme.typography.body2_regular,
            margin: theme.spacing(0.5, "auto"),
            alignItems: "start",
            width: "100%",
            lineHeight: "19.07px",
          },
        }
      : {
          margin: theme.spacing(1.25),
          marginTop: 2,
          padding: "0px 0px 10px 0px!important",
          width: 44,
          textAlign: "center",
          alignItems: "start",
          display: "block",
          "& .MuiCardHeader-title": {
            color: theme.palette.grey[400],
            typography: theme.typography.body2_regular,
            textTransform: "uppercase",
            lineHeight: "19.07px",
          },
          "& .MuiCardHeader-subheader": {
            color: theme.palette.warning.main,
            typography: theme.typography.title_bold,
            lineHeight: "40.85px",
            textTransform: "uppercase",
            margin: theme.spacing(0),
          },
          "& .MuiCardHeader-action": {
            color: theme.palette.grey[400],
            typography: theme.typography.body2_regular,
            margin: theme.spacing(0, "auto"),
            alignItems: "start",
            width: "100%",
            lineHeight: "19.07px",
          },
        },
  } satisfies SxObjType;
};
export { sxStyles };
