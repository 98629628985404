import { color } from "lib/theme/color";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = (isLinkedAppointmentStyled: boolean) => {
  return {
    card: isLinkedAppointmentStyled
      ? {
          boxShadow: "0px 0px 6px " + color.RGBA_52,
          clipPath: "inset(0px -10px -10px -10px)",
          backgroundColor: theme.palette.secondary.main,
          "& div.MuiBox-root": {
            "& div.MuiCardContent-root": {
              "& div": {
                borderLeft: `2px solid ${theme.palette.error.main}`,
              },
            },
          },
          borderRadius: theme.spacing(1),
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }
      : {
          boxShadow: "0px 0px 6px " + color.RGBA_52,
          backgroundColor: theme.palette.secondary.main,
          borderRadius: theme.spacing(1),
        },
  } satisfies SxObjType;
};

export { sxStyles };
