import React from "react";
import { Box, Typography } from "@mui/material";
import { ReceiptCardProps } from "./ReceiptCard.types";
import { sxStyles } from "./ReceiptCard.styles";

const ReceiptCard = ({
  payorName,
  receiptID,
  receiptDate,
  hasPaymentCardNo,
  paymentCardNo,
  payAmount,
}: ReceiptCardProps) => {
  const classes = sxStyles();

  return (
    <Box sx={classes.receiptSection}>
      <>
        <Box sx={classes.receiptPanel}>
          <Box sx={classes.leftBox}>
            <Typography sx={classes.content}>Payor Name</Typography>
            <Typography sx={classes.title}>{payorName}</Typography>
          </Box>
          <Box sx={classes.rightBox}>
            <Typography sx={classes.content}>Receipt Date</Typography>
            <Typography sx={classes.title}>{receiptDate}</Typography>
          </Box>
        </Box>
        <Box sx={classes.receiptPanel}>
          <Box sx={classes.leftBox}>
            <Typography sx={classes.content}>Receipt ID</Typography>
            <Typography sx={classes.title}>{receiptID}</Typography>
          </Box>
          {hasPaymentCardNo && (
            <Box sx={classes.rightBox}>
              <Typography sx={classes.content}>Payment Card No</Typography>
              <Typography sx={classes.title}>{paymentCardNo}</Typography>
            </Box>
          )}
        </Box>
        <Box sx={classes.payPanel}>
          <Typography sx={classes.title}>Total Amount Paid:</Typography>
          <Typography sx={classes.payValue}>{payAmount}</Typography>
        </Box>
      </>
    </Box>
  );
};

export default ReceiptCard;
