import { ShowMoreLessPanelProps } from "./ShowMoreLessPanel.types";
import ShowMoreText from "react-show-more-text";
import cx from "classnames";
import mapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import "./ShowMoreLessPanel.css";

/**
 *Component to truncate message and place into a Show More/Less panel
 *
 * @param {string} message String to be displayed. Required
 * @param {boolean} displayShowMoreInNewLine Flag to determine if More/Less will be on a new line. Default: false
 * @param {number}  linesToDisplay Number of lines string will be truncated to when collapsed. Default: 3
 * @param {boolean} showRaw Flag to determine if string is to be passed without going through html parser. Default: false
 * @returns JSX
 */

// Since the component is a react component, css is used for styling
const ShowMoreLessPanel = ({
  message,
  displayShowMoreInNewLine = false,
  linesToDisplay = 3,
  showRaw = false,
  children,
}: ShowMoreLessPanelProps) => {
  return (
    <ShowMoreText
      lines={linesToDisplay}
      more={"More"}
      less={"Less"}
      className={"reminderDisclaimerText"}
      anchorClass={
        displayShowMoreInNewLine
          ? cx("anchorText", "anchorTextInNewLine")
          : "anchorText"
      }
      truncatedEndingComponent={"... "}
    >
      {showRaw
        ? message
          ? message
          : children
        : typeof message === "string"
          ? mapRawStringToReactElement(message)
          : typeof children === "string"
            ? mapRawStringToReactElement(children)
            : null}
    </ShowMoreText>
  );
};

export default ShowMoreLessPanel;
