import axios from "axios";
import { SUBMIT_PAYMENT_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatSubmitPaymentErrorMessage } from "api/resources/messages/ErrorMessage";
import {
  SubmitPaymentRequestPayload,
  SubmitPaymentResponsePayload,
} from "./submitPayment.fromApi.types";

/**
 * Submit payment request for a new medical report request
 *
 * POST
 * Endpoint: secure/mrrp/SubmitPayment
 *
 * @param {SubmitPaymentRequestPayload} request // request payload
 *
 */
const submitPayment = async (
  request: SubmitPaymentRequestPayload,
): Promise<SubmitPaymentResponsePayload> => {
  try {
    const response = await axios.post<SubmitPaymentResponsePayload>(
      SUBMIT_PAYMENT_URL(),
      request,
      getRequestOptions(),
    );
    return parseResponse(response, SUBMIT_PAYMENT_URL());
  } catch (error) {
    const msg = formatSubmitPaymentErrorMessage(SUBMIT_PAYMENT_URL());
    console.error(msg);
    throw error;
  }
};

export { submitPayment };
