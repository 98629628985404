import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import MammogramQuestionnaire from "../MammogramQuestionnaire/MammogramQuestionnaire";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import { MammogramFormQuestionnairePageProps } from "./MammogramRequestFormQuestionnairePage.types";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { sxStyles } from "./MammogramRequestFormQuestionnairePage.styles";
import Datepicker from "lib/components/formInputs/Datepicker/Datepicker";
import SimpleCheckbox from "lib/components/formInputs/Checkbox/SimpleCheckbox";
import Dropdown from "lib/components/formInputs/Dropdown/Dropdown";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import {
  CHECKBOX_QUESTION_INDEX,
  DATEPICKER_QUESTION_INDEX,
  DROPDOWN_QUESTION_INDEX,
} from "api/appointment/GetMammogramQuestionnaire/getMammogramQuestionnaire";
import IMAGES from "lib/assets/images";
import {
  formatDate,
  formatOptions,
} from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

const PREFERRED_DATE_FORMAT = "d MMM yyyy";

const MammogramRequestFormQuestionnairePage = ({
  currentPage,
  ansList,
  handleQuestionnaireChange,
  disableNext,
  handlePageChange,
  openNotRecommended,
  setOpenNotRecommended,
  questions,
  titleText,
}: MammogramFormQuestionnairePageProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();

  const checkBoxAnswers =
    ansList.length > CHECKBOX_QUESTION_INDEX
      ? ansList[CHECKBOX_QUESTION_INDEX].split(",")
      : [];

  const handleCheckBoxAns = (checkBoxIndex: number, checked: boolean) => {
    let updated = [...checkBoxAnswers];
    updated[checkBoxIndex] = checked ? "1" : "0";

    if (checked) {
      //if user ticks first checkbox choice (no preference), all other choices need to be unchecked automatically
      if (checkBoxIndex === 0) {
        updated = updated.map((val, i) => (i === 0 ? val : "0"));
      }
      // if user ticks any other checkbox, first checkbox needs to be unchecked automatically
      else {
        updated[0] = "0";
      }
    }

    const answers = updated.join(",");
    const event = {
      target: { name: `q${CHECKBOX_QUESTION_INDEX}`, value: answers },
    };
    handleQuestionnaireChange(event);
  };

  const handleDatepickerAns = (date: Date) => {
    const dateString = formatDate(
      formatDateToISO(date),
      formatOptions.dayMonthYear,
    );
    const event = {
      target: { name: `q${DATEPICKER_QUESTION_INDEX}`, value: dateString },
    };
    handleQuestionnaireChange(event);
  };

  return (
    <Box sx={{ mx: 2 }}>
      <>
        {currentPage === "questionnaire" && (
          <Box pt={2} pb={2}>
            <Typography variant="body2" sx={classes.title} paragraph>
              Mammogram Request Form
            </Typography>
            {titleText && (
              <Box sx={classes.questionText}>
                {MapRawStringToReactElement(titleText)}
              </Box>
            )}
            <Box sx={{ mt: 3 }}>
              <Box sx={classes.requireBox}>
                <Box
                  component={"img"}
                  src={IMAGES.general.InformationErrorIcon}
                />
                <Typography component={"span"} sx={classes.requireText}>
                  All fields required
                </Typography>
              </Box>
            </Box>

            {questions.slice(0, 5)?.map((question, index) => {
              return (
                <Box sx={{ mt: 0.5 }} key={question.QuestionId}>
                  <MammogramQuestionnaire
                    questionId={question.QuestionId || 1}
                    questionText={question.QuestionText || ""}
                    questionAdditional={question.QuestionAddtional || ""}
                    questionAns={ansList[index]}
                    handleChange={handleQuestionnaireChange}
                  ></MammogramQuestionnaire>
                </Box>
              );
            })}

            {questions.length > DATEPICKER_QUESTION_INDEX && (
              <Box sx={{ pt: 2 }}>
                <Box sx={classes.formSameLine}>
                  <Typography sx={classes.questionNo}>{6 + "."}</Typography>
                  <Box width="100%">
                    <Datepicker
                      required={false}
                      disablePast
                      format={PREFERRED_DATE_FORMAT}
                      label={questions[DATEPICKER_QUESTION_INDEX].QuestionText}
                      value={ansList[DATEPICKER_QUESTION_INDEX] || null}
                      onSelect={handleDatepickerAns}
                    />
                    <Typography sx={classes.datePickerDisclaimerText}>
                      {questions[DATEPICKER_QUESTION_INDEX].QuestionAddtional}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}

            {questions.length > CHECKBOX_QUESTION_INDEX && (
              <Box sx={{ pb: 1 }}>
                <Box sx={classes.formSameLine}>
                  <Typography sx={classes.questionNo}>{7 + "."}</Typography>
                  <Box width="100%">
                    <Typography sx={classes.questionText}>
                      {questions[CHECKBOX_QUESTION_INDEX].QuestionText}
                    </Typography>
                    {questions[CHECKBOX_QUESTION_INDEX].QuestionDetails.map(
                      (choice, i) => {
                        return (
                          <SimpleCheckbox
                            checked={checkBoxAnswers[i] === "1" ? true : false}
                            value={choice.Id.toString()}
                            label={
                              <Box sx={{ mt: -3.5 }}>
                                <Typography sx={classes.questionText}>
                                  {choice.Text}
                                </Typography>
                              </Box>
                            }
                            handleChange={(event, checked) =>
                              handleCheckBoxAns(i, checked)
                            }
                          ></SimpleCheckbox>
                        );
                      },
                    )}
                    <Typography sx={classes.checkboxDisclaimerText}>
                      {questions[CHECKBOX_QUESTION_INDEX].QuestionAddtional}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}

            {questions.length > DROPDOWN_QUESTION_INDEX && (
              <Box sx={{ py: 2 }}>
                <Box sx={classes.formSameLine}>
                  <Typography sx={classes.questionNo}>{8 + "."}</Typography>
                  <Box sx={classes.menuItemContainer}>
                    <Dropdown
                      required={false}
                      label={questions[DROPDOWN_QUESTION_INDEX].QuestionText}
                      value={ansList[DROPDOWN_QUESTION_INDEX]}
                      items={questions[
                        DROPDOWN_QUESTION_INDEX
                      ].QuestionDetails.map((d) => {
                        return { id: d.Text || "", text: d.Text || "" };
                      })}
                      name={`q${DROPDOWN_QUESTION_INDEX}`}
                      handleChange={(event) =>
                        handleQuestionnaireChange({
                          ...event,
                          target: {
                            ...event.target,
                            name:
                              event.target.name ||
                              `q${DROPDOWN_QUESTION_INDEX}`,
                          },
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            )}

            <Box sx={{ mt: 2.5 }}>
              <ButtonsFooter
                nextButtonText="Next"
                cancelButtonText="Back"
                isDisabled={disableNext}
                onClickNext={() => {
                  handlePageChange();
                }}
                onClickCancel={() => {
                  navigate(-1);
                }}
              />
            </Box>
            <ConfirmationModal
              title="Mammogram Not Recommended"
              titleBold={false}
              body="A screening mammogram is not recommended if you have answered 'Yes' to any of the 5 questions."
              nextButtonText="Close"
              hideCancelButton={false}
              cancelButtonText="Go to Appointment"
              onClickNext={() => setOpenNotRecommended(false)}
              onClickCancel={() => {
                navigate(PATHS.APPOINTMENT_MOBILE.path);
              }}
              open={openNotRecommended}
              onClose={() => {}}
            />
          </Box>
        )}
      </>
    </Box>
  );
};

export default MammogramRequestFormQuestionnairePage;
