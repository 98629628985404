import { Theme } from "@mui/material";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = (theme: Theme) => {
  return {
    accordion: {
      boxShadow: "none",
      borderBottom: `1px solid ${theme.palette.divider}`,
      "&::before": {
        height: 0,
      },
      "&.Mui-expanded": { margin: "unset" },
    },
    accordionSummary: {
      "&.Mui-expanded": { minHeight: "unset" },
      "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: "12px 0",
      },
      "& .MuiAccordionSummary-expandIconWrapper": {
        alignSelf: "flex-start",
        mt: 1.5,
      },
    },
    accordionDetails: {
      p: 0,
    },
  } satisfies SxObjType;
};

export { sxStyles };
