import { Box, CardHeader } from "@mui/material";
import { AppointmentCardHeaderProps } from "./AppointmentCardHeader.types";
import { sxStyles } from "./AppointmentCardHeader.styles";
import {
  formatDate,
  formatOptions,
} from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

const AppointmentCardHeader = ({
  datetime,
  isHighlighted = false,
  isVcp = false,
}: AppointmentCardHeaderProps) => {
  const classes = sxStyles(isVcp);

  return (
    <Box sx={classes.cardHeaderContainer} aria-label="cardHeaderContainer">
      <CardHeader
        avatar={null}
        title={
          datetime
            ? formatDate(formatDateToISO(datetime), formatOptions.monthYear)
            : null
        }
        subheader={
          datetime
            ? formatDate(formatDateToISO(datetime), formatOptions.day)
            : null
        }
        sx={classes.cardDate}
        action={
          datetime
            ? formatDate(
                formatDateToISO(datetime),
                formatOptions.dayOfWeek,
              ).toUpperCase()
            : null
        }
      />
    </Box>
  );
};

export default AppointmentCardHeader;
