import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    buttonBox: {
      display: "grid",
      justifyContent: "flex-end",
    },

    formContent: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    formTitle: {
      color: theme.palette.text.primary,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: "19px",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },

    textBox: {
      margin: theme.spacing(1.5, 0, 2.5),
      "& .MuiInputBase-root": {
        borderRadius: theme.spacing(0.5),
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.grey[100]}`,
        },
      },
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
      "& .MuiInputLabel-animated": {
        fontSize: size.FONTSIZE_16,
        color: theme.palette.primary.main,
      },
    },

    infoBox: {
      border: `1px solid ${theme.palette.primary.main}`,
      background: theme.palette.secondary.main,
      padding: theme.spacing(0.5),
      display: "flex",
      lineHeight: theme.typography.body1.lineHeight,
      borderRadius: theme.spacing(0.5),
      margin: theme.spacing(2, 0),
    },
  } satisfies SxObjType;
};

export { sxStyles };
