import { Box, Typography, Button, useTheme } from "@mui/material";
import { AppointmentCardProps } from "../types/AppointmentCard.types";
import CardWrapper from "../../../common/cards/CardWrapper/CardWrapper";
import AppointmentCardHeader from "../../../common/cards/AppointmentCardHeader/AppointmentCardHeader";
import AppointmentCardBody from "../../../common/cards/AppointmentCardBody/AppointmentCardBody";
import AppointmentCardFooterBtnsContainer from "ui/appointment/components/appointmentList/cardButtons/AppointmentCardFooterBtns/AppointmentCardFooterBtnsContainer";
import LinkedAppointmentSection from "./LinkedAppointmentSection/LinkedAppointmentSection";
import { formatAddToCalendarURI } from "lib/mobileIntegration/addToCalendar/formatUrl";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { sxStyles } from "./UpcomingAppointmentCard.styles";
import ShowMoreLessPanel from "lib/components/notice/ShowMoreLessPanel/ShowMoreLessPanel";
import mapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import VcpCardContainer from "../VcpCard/VcpCardContainer";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import IMAGES from "lib/assets/images";
import IconTextButton from "lib/components/buttons/IconTextButton/IconTextButton";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectAppointments } from "ui/appointment/ducks/selectors";
import { updateDetailsForActualiseAppointment } from "ui/appointment/ducks";
import { updateAppointmentMetaAppointmentType } from "ui/appointment/ducks/appointmentsSlice";
import LinkedAppointmentHeader from "./LinkedAppointmentSection/LinkedAppointmentHeader";

const UpcomingAppointmentCard = ({ appointment }: AppointmentCardProps) => {
  const theme = useTheme();
  const classes = sxStyles(theme);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    appointmentId,
    datetime,
    institutionName,
    location,
    serviceDisplayName,
    clinician,
    departmentName,
    visitType,
    zoneInfo,
    calendarTitle,
    calendarLocation,
    linkedAppointments,
    reminder,
    formFlowDisclaimer,
    regErrorMessage,
    isVirtualConsultAppointment,
    actionMessage,
    canCancel,
    canReschedule,
    institutionCode,
    hasTeleconsultmessage,
    appointmentIdToRegister,
    isCrossInstitution,
    regAppointmentId,
    regInstitutionCode,
    sourceSystem,
    clinicCode,
    actualizeStatus,
    mobileRegQNo,
    consultType,
    encounterId,
    appointmentStartDateTime,
    vcSupportStatus,
  } = appointment;

  const teleconsultMessage =
    useAppSelector(selectAppointments).teleconsultMessage;

  const isJCH = institutionCode === "JCH";

  const isExceedRescheduleLimit = appointment.exceedRescheduleLimit;

  const isLinkedAppt = !!(
    appointment.linkedAppointments && appointment.linkedAppointments?.length > 0
  );

  const onSelectRegister = () => {
    dispatch(
      updateDetailsForActualiseAppointment({
        appointmentId,
        regAppointmentId,
        regInstitutionCode: regInstitutionCode || "",
        institutionCode: institutionCode || "",
        sourceSystem,
        clinicCode,
        actualizeStatus,
        queueNumber: mobileRegQNo || "",
        institutionName,
        consultType,
        encounterId: encounterId || "",
        appointmentTimeToArrive: appointmentStartDateTime || "",
        vcSupportStatus: vcSupportStatus || "",
      }),
    );
    dispatch(updateAppointmentMetaAppointmentType("upcoming"));
    navigate(PATHS.APPOINTMENT_REGISTER_KIOSK.path);
  };

  return (
    <Box>
      {isLinkedAppt && <LinkedAppointmentHeader />}

      <CardWrapper isLinkedAppointmentStyled={isLinkedAppt}>
        <Box sx={classes.cardTopContainer}>
          <AppointmentCardHeader
            datetime={datetime}
            isHighlighted={true}
            isVcp={isVirtualConsultAppointment === true ? true : false}
          />

          <AppointmentCardBody
            institution={institutionName}
            datetime={datetime}
            departmentName={departmentName}
            clinician={clinician}
            location={location}
            zoneInfo={zoneInfo}
            serviceDisplayName={serviceDisplayName}
            visitType={visitType}
            isVcp={isVirtualConsultAppointment === true ? true : false}
          />
          <Box sx={classes.addToCalendarContainer}>
            <IconTextButton
              icon={IMAGES.appointment.AddToCalendarIcon}
              label={["Add to", "calendar"]}
              onClick={() =>
                mobileNavigate(
                  formatAddToCalendarURI(
                    appointmentId,
                    datetime,
                    calendarLocation,
                    institutionName,
                    calendarTitle,
                  ),
                )
              }
              ariaLabel={"Add to calendar"}
            />
          </Box>
        </Box>

        {isVirtualConsultAppointment && (
          <VcpCardContainer appointment={appointment} />
        )}

        {hasTeleconsultmessage && (
          <Box m={1}>
            <ShowMoreLessPanel message={teleconsultMessage} />
          </Box>
        )}

        {reminder && (
          <Box sx={classes.reminderText}>
            <ShowMoreLessPanel message={reminder} showRaw={true} />
          </Box>
        )}

        {isLinkedAppt && appointmentIdToRegister === appointmentId && (
          <Box sx={{ mx: 1, my: 2 }}>
            <Button
              variant="contained"
              sx={{ width: "100%" }}
              onClick={onSelectRegister}
            >
              Register
            </Button>
          </Box>
        )}

        <Box>
          <LinkedAppointmentSection
            linkedAppointments={linkedAppointments}
            onSelectRegister={onSelectRegister}
          />
        </Box>

        {isCrossInstitution
          ? actionMessage && (
              // !exp margin added to compensate for hiding of buttons
              <Typography sx={{ ...classes.actionMsg, m: 1 }}>
                {mapRawStringToReactElement(actionMessage)}
              </Typography>
            )
          : isExceedRescheduleLimit
            ? isJCH
              ? actionMessage && (
                  <Typography sx={classes.actionMsg}>
                    {mapRawStringToReactElement(actionMessage + `.`)}
                  </Typography>
                )
              : actionMessage && (
                  <Typography sx={classes.actionMsg}>
                    {mapRawStringToReactElement(
                      actionMessage +
                        ` or submit your request using below link${
                          !canCancel && !canReschedule ? "s" : ""
                        }.`,
                    )}
                  </Typography>
                )
            : // !exp formFlowDisclaimer value is created from lambda
              formFlowDisclaimer && (
                <Typography sx={classes.shouldSubmitRequestDisclaimerText}>
                  {mapRawStringToReactElement(formFlowDisclaimer)}
                </Typography>
              )}

        <AppointmentCardFooterBtnsContainer
          appointment={appointment}
          onCancelSystemFlow={() => navigate(PATHS.APPOINTMENT_CANCEL.path)}
          isCrossInstitution={isCrossInstitution}
          onSelectRegister={onSelectRegister}
        />

        {regErrorMessage && (
          <Box p={1} sx={classes.reminderText}>
            <ShowMoreLessPanel message={regErrorMessage} />
          </Box>
        )}
      </CardWrapper>
    </Box>
  );
};

export default UpcomingAppointmentCard;
