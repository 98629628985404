import { SxObjType } from "lib/theme/types/SxObjType.type";
import { Theme } from "@mui/material";

const sxStyles = (theme: Theme) => {
  return {
    uneditableFieldsContainer: { mx: 2 },
    subtitle: {
      color: theme.palette.text.primary,
      typography: "header4_semibold",
    },
    introText: {
      color: theme.palette.grey[400],
      typography: "body2_regular",
      marginBottom: 2,
      lineHeight: "19px",
    },
    myInfoButton: {
      margin: theme.spacing(2, 0),
      "& button": {
        padding: 0,
      },
    },
    fieldsContainer: {
      px: 1.25,
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
      mb: 2,
    },
    formContainer: {
      mb: 2.5,
      mt: 1,
      "&:last-of-type": {
        mb: 2,
      },
    },
    formLabel: {
      color: theme.palette.grey[400],
      typography: "body2_regular",
    },
    formValue: {
      color: theme.palette.grey[400],
      typography: "body1_semibold",
    },
    link: {
      color: theme.palette.primary.main,
      typography: "caption1_semibold",
    },

    nricErrorMessage: {
      color: theme.palette.error.main,
      typography: "caption1_regular",
    },
    nricLink: {
      color: theme.palette.primary.main,
      typography: "caption1_semibold",
      marginBottom: theme.spacing(2.5),
    },
    caption: {
      color: theme.palette.grey[600],
      typography: "caption1_regular",
    },
    subsubtitle: {
      color: theme.palette.grey[400],
      typography: "body1_semibold",
    },
    myInfoLastSync: {
      color: theme.palette.grey[600],
      typography: "caption2_regular",
      marginTop: 0.5,
    },
  } satisfies SxObjType;
};
export { sxStyles };
