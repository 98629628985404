import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    card: {
      marginBottom: theme.spacing(2),
      borderRadius: theme.spacing(1),
    },
    mediaContent: {
      display: "flex",
      padding: theme.spacing(1),
      "& div:last-child": {
        paddingBottom: theme.spacing(0.75),
      },
    },
    cardHeader: {
      padding: theme.spacing(1),
      "& .MuiCardHeader-subheader": {
        color: theme.palette.grey[400],
        typography: theme.typography.body2_semibold,
        "& .MuiTypography-colorTextSecondary": {
          color: theme.palette.grey[400],
        },
      },
      "& .MuiCardHeader-action": {
        marginTop: theme.spacing(-1),
        marginRight: theme.spacing(-1),
      },
    },
    media: {
      backgroundColor: "theme.palette.common.white,",
      borderRadius: theme.spacing(1),
      paddingTop: theme.spacing(0),
      width: "48%",
      margin: "0 1%",
      color: theme.palette.grey[100],
      textAlign: "center",
    },
    mediaTitle: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_semibold,
      "& .MuiTypography-colorTextSecondary": {
        color: theme.palette.grey[400],
      },
    },
    deleteIcon: {
      color: theme.palette.error.main,
      fontWeight: theme.typography.fontWeightMedium,
      "& .MuiSvgIcon-root": {
        fontSize: size.FONTSIZE_24,
      },
      padding: theme.spacing(0.3, 0.5),
    },
  } satisfies SxObjType;
};

export { sxStyles };
