import { SimpleAutocompleteProps } from "./SimpleAutoComplete.types";
import { sxStyles } from "./SimpleAutoComplete.styles";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  TextField,
} from "@mui/material";

const SimpleAutocomplete = ({
  required = true,
  maxCharLength,
  label,
  placeholder,
  value,
  noOptionsText,
  items,
  onSelect,
  inputValue,
  onInputChange,
  error = false,
  helperText,
  disabled = false,
}: SimpleAutocompleteProps) => {
  const classes = sxStyles();

  return (
    <>
      <Autocomplete
        freeSolo
        fullWidth
        popupIcon={null}
        options={items}
        value={value}
        disabled={disabled}
        onChange={(event, value) => {
          onSelect(value);
        }}
        ListboxProps={{ sx: classes.autocompleteInput }}
        inputValue={inputValue || ""}
        onInputChange={(event, value) => {
          onInputChange(value.slice(0, maxCharLength));
        }}
        noOptionsText={noOptionsText}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            variant="outlined"
            error={error}
            sx={!error ? classes.textFieldRoot : undefined}
            InputProps={{
              // preserves InputProps
              ...params.InputProps,

              endAdornment: null,
            }}
            InputLabelProps={{
              sx: classes.textFieldLabel,
            }}
          ></TextField>
        )}
      />

      {required ? (
        error && helperText ? (
          <Box component="div" sx={classes.errText}>
            *Required. {helperText}.
          </Box>
        ) : (
          <Box sx={classes.errText}>*Required</Box>
        )
      ) : (
        error &&
        helperText && (
          <Box component="div" sx={classes.errText}>
            {helperText}.
          </Box>
        )
      )}
    </>
  );
};

export default SimpleAutocomplete;
