import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { CREATE_LINKED_APPOINTMENT_URL } from "../../resources/configs/URL";
import { formatCreateLinkedAppointmentErrorMessage } from "../../resources/messages/ErrorMessage";
import { CreateLinkedAppointmentPayload } from "./createLinkedAppointment.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";

/**
 * Creates a linked appointment
 *
 * POST
 * Endpoint: /CreateLinkedAppointment
 *
 * @param {string | null} memberIdentifier  Member Identifier of the person we are making this request for
 * @param {string | null} targetSystem  Source system from which these slots are from
 * @param {string | null} institutionCode  Code of the institution of the appointment to reschedule
 * @param {AppointmentDataObjectRequest[]} appointmentDataObjectRequests  List of appointment data objects that represent
 * @returns {CreateLinkedAppointmentPayload} Response payload from the remote API
 */
const createLinkedAppointment = async (
  memberIdentifier: string | null,
  institutionCode: string | null,
  slotIdList: (string | null)[],
): Promise<CreateLinkedAppointmentPayload> => {
  try {
    const response = await axios.post<CreateLinkedAppointmentPayload>(
      CREATE_LINKED_APPOINTMENT_URL(),
      createRequestPayload(memberIdentifier, institutionCode, slotIdList),
      getRequestOptions(),
    );
    return parseResponse(response, CREATE_LINKED_APPOINTMENT_URL());
  } catch (error) {
    const msg = formatCreateLinkedAppointmentErrorMessage(
      CREATE_LINKED_APPOINTMENT_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  memberIdentifier: string | null,
  institutionCode: string | null,
  slotIdList: (string | null)[],
) => {
  return {
    MemberIdentifier: memberIdentifier,
    InstitutionCode: institutionCode,
    SlotIdList: slotIdList,
  };
};

export { createLinkedAppointment };
