import SvgIcon from "@mui/material/SvgIcon";

function CustomSvgIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="14.142"
          height="2.8284"
          rx="1.4142"
          transform="matrix(0.707113 -0.707101 0.707113 0.7071 10 16)"
          fill="#0077C8"
        />
        <rect
          width="14.142"
          height="2.8284"
          rx="1.4142"
          transform="matrix(-0.707113 -0.7071 0.707113 -0.707101 12 18)"
          fill="#0077C8"
        />
      </svg>
    </SvgIcon>
  );
}

export default CustomSvgIcon;
