import {
  GetServiceListPayload,
  ServicePayload,
  DepartmentNamePayload,
} from "../getServiceList.fromApi.types";
import { Service } from "../getServiceList.toUi.types";

/**
 * Maps fetched GetServiceList API payload to a schema that the
 * UI components can consume.
 *
 * @param {GetServiceListPayload} payload  The payload which will be transformed
 * @returns {Service[]} List of renderable services
 */
const mapApiDataToProps = (payload: GetServiceListPayload): Service[] => {
  let serviceList: Service[] = [];
  if (payload.InstitutionList) {
    payload.InstitutionList.forEach((clinic) => {
      clinic.Departments.forEach((department) => {
        department.Services.forEach((service) => {
          serviceList.push(mapService(service, department.DepartmentName));
        });
      });
    });
  }

  return serviceList;
};

const mapApiDataToInstitutionId = (payload: GetServiceListPayload): string => {
  let clinicID = "";
  if (payload.InstitutionList) {
    payload.InstitutionList.forEach((clinic) => {
      if (clinic.InstitutionId) {
        clinicID = clinic.InstitutionId;
      }
    });
  }
  return clinicID;
};

// ===========================
// MAP INDIVIDUAL OBJECTS
// ===========================

const mapService = (
  service: ServicePayload,
  category: DepartmentNamePayload,
): Service => {
  return {
    displayName: service.DisplayName,
    category: category,
    departmentCode: service.DepartmentCode,
    visitTypeId: service.VisitTypeId,
    afterCareMessage: service.AfterCareMessage,
    beforeCareMessage: service.BeforeCareMessage,
    questionnaireId: service.QuestionnaireId?.split("/")[1],
    hasTriageQuestions: service.HasTriageQuestions,
    isHsgAppt: service.IsHsgAppt,
    alternateServiceType: service.AlternateServiceType,
    linkedServiceTypeName: service.LinkedServiceTypeName,
  };
};

export { mapApiDataToProps, mapApiDataToInstitutionId };
