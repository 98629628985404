import { RootStore } from "lib/redux/root/redux.types";
import { MobileIntegrationParsed } from "lib/redux/system/system.types";
import {
  validateRequiredField,
  validateOptionalField,
} from "lib/util/ValidatorUtil/fieldValidator/validateField";
import { dispatchToReducer as dispatchToSystemReducer } from "lib/redux/system/mobileIntegration/dispatcher";
import { dispatchToReducer as dispatchToUserReducer } from "lib/redux/user/mobileIntegration/dispatcher";
import { dispatchToReducer as dispatchToNavigationReducer } from "lib/redux/navigation/mobileIntegration/dispatcher";
import { dispatchToReducer as dispatchToAppointmentsReducer } from "ui/appointment/ducks/mobileIntegration/dispatcher";

const FIELD_NAME_SYSTEM = "system";
const FIELD_NAME_USER = "user";
const FIELD_NAME_NAVIGATION = "navigation";
const FIELD_NAME_APPOINTMENTS = "appointment";

/**
 * Validates all required fields and calls the appropriate dispatchers so that the state of each slice is updated.
 *
 * To add support for another module's reducer, please see implementation for Appointment / MedRefill module below.
 *
 * @param {RootStore} store  Redux store
 * @param {MobileIntegrationParsed} parsedPayload  Payload parsed from the input mobile integration state
 */
const validateAndDispatchToAllReducers = (
  store: RootStore,
  parsedPayload: MobileIntegrationParsed,
) => {
  // --- Common
  validateRequiredField(parsedPayload, FIELD_NAME_SYSTEM);
  dispatchToSystemReducer(store, parsedPayload[FIELD_NAME_SYSTEM]);

  validateRequiredField(parsedPayload, FIELD_NAME_USER);
  dispatchToUserReducer(store, parsedPayload[FIELD_NAME_USER]);

  validateOptionalField(parsedPayload, FIELD_NAME_NAVIGATION);
  dispatchToNavigationReducer(store, parsedPayload[FIELD_NAME_NAVIGATION]);

  // --- Module-specific
  if (validateOptionalField(parsedPayload, FIELD_NAME_APPOINTMENTS)) {
    dispatchToAppointmentsReducer(
      store,
      parsedPayload[FIELD_NAME_APPOINTMENTS],
    );
  }
};

export { validateAndDispatchToAllReducers };
