import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    dropdownRegion: {
      backgroundColor: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[2],
      borderRadius: theme.spacing(1),
      position: "absolute",
      width: "92%",
    },
    textFieldLabel: {
      color: theme.palette.primary.main,
      typography: theme.typography.body1_regular,
    },
    dropdownText: {
      color: theme.palette.grey[400],
      fontSize: size.FONTSIZE_14,

      "& .MuiListItemText-root": {
        color: theme.palette.grey[400],
        fontSize: size.FONTSIZE_14,
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
