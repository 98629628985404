import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_semibold,
      paddingBottom: theme.spacing(2),
    },

    photoPairTitle: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    photoCaption: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
      textAlign: "center",
      marginTop: theme.spacing(1),
      textTransform: "none",
      zIndex: 10,
    },
    prescriptionPhotoPairBox: {
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2, 0, 0),
    },
    prescriptionPhotoFrame: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: theme.spacing(0.5),
      width: "50%",
    },
    photo: {
      width: theme.spacing(18),
      height: theme.spacing(18),
      borderRadius: theme.spacing(1),
      objectFit: "contain",
      border: `1px solid ${theme.palette.grey[100]}`,
    },
  } satisfies SxObjType;
};

export { sxStyles };
