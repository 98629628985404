import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    formTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_semibold,
      lineHeight: 1,
    },

    cardDetails: {
      borderLeft: `2px solid ${theme.palette.error.main}`,
      padding: theme.spacing(0, 0, 0, 1),
      minHeight: "100%",
      wordBreak: "break-word",
    },

    noRecordBox: {
      textAlign: "center",
      margin: theme.spacing(5, 0, 12),
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
  } satisfies SxObjType;
};

export { sxStyles };
