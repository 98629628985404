import { connect } from "react-redux";
import { RootDispatch, RootState } from "lib/redux/root/redux.types";
import Payment from "./Payment";
import { getSubmitPayment } from "ui/mrrp/ducks/thunks";
import { ApiEnum, MrrpPersist } from "ui/mrrp/ducks/mrrp.types";
import { resetApiStatus, setMrrpPerist } from "ui/mrrp/ducks";

const mapStateToProps = (state: RootState) => {
  const selectedHospital = state.mrrp.hospital.selectedHospitalCode ?? "";
  const formLabelSelected = state.mrrp.formLabel.labels.find((label) => {
    return label.institutions.includes(selectedHospital);
  });
  const formLabelDefault = state.mrrp.formLabel.labels.find((label) => {
    return label.institutions.includes("Default");
  });
  const formLabel = formLabelSelected ?? formLabelDefault;
  return {
    submitPaymentApiStatus: state.mrrp.apiStatus[ApiEnum.SubmitPayment],
    memberIdentifier: state.user.memberIdentifier,
    selectedInstitutionCode: state.mrrp.hospital.selectedHospitalCode,
    availablePaymentMode: state.mrrp.paymentMode,
    paymentAmount: state.mrrp.reportDetail.reportFees,
    reportNo: state.mrrp.payment.createMedicalReport.mnReportNo,
    reportTypeLabel: formLabel?.reportType ?? "Report Type",
    reportType: state.mrrp.reportDetail.reportType,
    paymentToken: state.mrrp.payment.submitPayment.PaymentToken,
    redirectionUrl: state.mrrp.payment.submitPayment.RedirectionUrl,
    emailAddress: state.mrrp.particulars.email,
  };
};

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    onResetPaymentApiStatus: () => {
      dispatch(resetApiStatus());
    },
    onSetMrrpPersist: (mrrpPersist: MrrpPersist) => {
      dispatch(setMrrpPerist(mrrpPersist));
    },
    onSubmitPayment: (
      institutionCode: string | null,
      paymentMethod: string | null,
      totalAmount: number | null,
      reportNo: string | null,
    ) => {
      dispatch(
        getSubmitPayment(institutionCode, paymentMethod, totalAmount, reportNo),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
