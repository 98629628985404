import axios from "axios";
import { GET_AVAILABLE_PAYMENT_MODES_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatGetAvailablePaymentModesErrorMessage } from "api/resources/messages/ErrorMessage";
import { GetAvailablePaymentModesResponsePayload } from "./getAvailablePaymentModes.fromApi.types";

/**
 * Fetches the available payments modes for Submit Payment
 *
 * POST
 * Endpoint: secure/data/GetAvailablePaymentModes
 *
 */
const getAvailablePaymentModes =
  async (): Promise<GetAvailablePaymentModesResponsePayload> => {
    try {
      const response =
        await axios.post<GetAvailablePaymentModesResponsePayload>(
          GET_AVAILABLE_PAYMENT_MODES_URL(),
          createRequestPayload(),
          getRequestOptions(),
        );
      return parseResponse(response, GET_AVAILABLE_PAYMENT_MODES_URL());
    } catch (error) {
      const msg = formatGetAvailablePaymentModesErrorMessage(
        GET_AVAILABLE_PAYMENT_MODES_URL(),
      );
      console.error(msg);
      throw error;
    }
  };

const createRequestPayload = () => {
  return {};
};

export { getAvailablePaymentModes };
