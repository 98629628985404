import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { sxStyles } from "./BillSummary.styles";
import IconButton from "@mui/material/IconButton";
import IMAGES from "lib/assets/images";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import { formatPayment } from "lib/util/StringUtil/formatPayment/formatPayment";
import BillCard from "./BillCard/BillCard";
import { ViewOutstandingBills } from "api/payment/GetOutstandingBills/getOutstandingBills.fromApi.types";
import { ViewManualBill } from "api/payment/AddManualBill/addManualBill.fromApi.types";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { PaymentBillsToPayState } from "ui/payment/ducks/payment.types";
import StepPageLayout from "../common/StepPageLayout/StepPageLayout";
import SimpleCheckbox from "lib/components/formInputs/Checkbox/SimpleCheckbox";
import AddOtherBillDialog from "../common/AddOtherBillDialog/AddOtherBillDialog";
import {
  MAX_AMOUNT_PAYABLE_PER_TRANSACTION,
  TRANSACTION_LIMIT_ERROR,
} from "../Constants";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import toTitleCase from "lib/util/StringUtil/toTitleCase/toTitleCase";
import IconLink from "lib/components/links/IconLink/IconLink";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import {
  selectPaymentsAllAddedBills,
  selectPaymentsAllOutstandingBills,
  selectPaymentsBillsToPay,
} from "ui/payment/ducks/selectors";
import { selectMemberIdentifier } from "lib/redux/user/selectors";
import {
  deleteAddedBillByInvNo,
  fetchOutstandingBills,
  setBillsToPay,
} from "ui/payment/ducks";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import { fetchDocumentForAllTypes } from "ui/appointment/ducks/thunks";

const OUTSTANDING_BILL_TITLE = "All Outstanding Bills";
const OTHER_ADDED_BILL_TITLE = "Added Bills";
const AMOUNT_TO_PAY_TITLE = "Amount To Pay";
const BUTTON_TITLE_PAY = "Pay";
const ICON_LINK_TITLE = "Add other bill";

const BillSummary = () => {
  const navigate = useNavigate();
  const classes = sxStyles();
  const dispatch = useAppDispatch();

  // Redux States
  const {
    allOutstandingBills: allSystemBills,
    isLoading,
    hasErrored,
    errorMessage,
  } = useAppSelector(selectPaymentsAllOutstandingBills);
  const allAddedBills = useAppSelector(selectPaymentsAllAddedBills);
  const billsToPay = useAppSelector(selectPaymentsBillsToPay);
  const addedBillsState = useMemo(
    () => allAddedBills.map(mapAddedBillToStateVar),
    [allAddedBills],
  ); //memoized objects/arrays for use within useEffect
  const systemBillsState = useMemo(
    () => allSystemBills.map(mapOutstandingBillToStateVar),
    [allSystemBills],
  );
  const memberIdentifier = useAppSelector(selectMemberIdentifier);

  const SYSTEM_BILL_COUNT = allSystemBills.length;

  // Local States
  const [billRefNo, setBillRefNo] = useState<string[]>([]);
  const [openAddBillDialog, setOpenAddBillDialog] = useState(false);
  const [billsState, setBillsState] = useState(
    billsToPay.length === 0
      ? systemBillsState.concat(addedBillsState)
      : billsToPay,
  );
  const billsStateMemo = useMemo(() => billsState, [billsState]); //memoized objects/arrays for use within useEffect
  // Delete selected added bill(s) Warning Dialog open & close state and handlers
  const [open, setOpen] = useState(false);
  // Controls the logic to select all added bills
  const [selectAllAdded, setSelectAllAdded] = useState(true);

  // Helper functions to update billsState, passed into child components
  const updateSelectedBillByIndex = (index: number) => {
    const bill = billsState[index];
    let newBillState = billsState
      .slice(0, index)
      .concat({ ...bill, Selected: !bill.Selected })
      .concat(billsState.slice(index + 1));
    setBillsState(newBillState);
  };

  const updateAmountToPayByIndex = (index: number, amountToPay: string) => {
    const bill = billsState[index];
    let newBillState = billsState
      .slice(0, index)
      .concat({ ...bill, AmountToPay: amountToPay })
      .concat(billsState.slice(index + 1));
    setBillsState(newBillState);
  };

  const disablePayment = () => {
    const amountToPay = getTotalAmount(billsState);
    if (amountToPay <= MAX_AMOUNT_PAYABLE_PER_TRANSACTION && amountToPay > 0) {
      return false;
    } else {
      return true;
    }
  };

  const updateSelectAllAddedBill = (selected: boolean) => {
    const newBillState = billsState.map((bill, index) =>
      index >= SYSTEM_BILL_COUNT ? { ...bill, Selected: selected } : bill,
    );
    setBillsState(newBillState);
  };

  // change "select all" check box status when all added bills are selected / unselected
  const selectedAddedBillCountReducer = useCallback(
    (
      accumulator: number,
      currentBill: PaymentBillsToPayState,
      index: number,
    ): number => {
      const currentCount =
        index >= SYSTEM_BILL_COUNT && currentBill.Selected ? 1 : 0;
      return accumulator + currentCount;
    },
    [SYSTEM_BILL_COUNT],
  );

  // Deleted selected added bill(s), leverage filter to call each reducer
  // also update billsState together
  const deleteSelectedAddedBill = () => {
    const newBillsState = billsState.filter((bill, index) => {
      if (index >= SYSTEM_BILL_COUNT && bill.Selected) {
        dispatch(deleteAddedBillByInvNo(bill.InvNo));

        return false;
      } else {
        return true;
      }
    });
    setBillsState(newBillsState);
  };

  const setSelectedAddedbill = () => {
    const out: string[] = [];
    // eslint-disable-next-line array-callback-return
    billsState.filter((bill, index) => {
      if (index >= SYSTEM_BILL_COUNT && bill.Selected) {
        out.push(bill.InvNo);
      }
      setBillRefNo(out);
    });
  };

  // log page to GA on mount
  useEffect(() => {
    logEventToGoogleAnalytics(EVENTS.VIEW_PAYMENT_BILL_SUMMARY);
  }, []);

  useEffect(() => {
    if (billsStateMemo.length === 0) {
      dispatch(fetchDocumentForAllTypes());
      dispatch(fetchOutstandingBills());
      setBillsState(systemBillsState.concat(addedBillsState));
    }
  }, [addedBillsState, billsStateMemo.length, dispatch, systemBillsState]);

  // Helper function to accommodate adding a new bill (can only be manual bill and will come in last)
  useEffect(() => {
    if (
      systemBillsState.length + addedBillsState.length >
      billsStateMemo.length
    ) {
      setBillsState(billsStateMemo.concat(addedBillsState.slice(-1)));
    }
  }, [addedBillsState, billsStateMemo, systemBillsState.length]);

  useEffect(() => {
    // total number of added bill being selected
    const totalNumAddedBillSelected = billsStateMemo.reduce(
      selectedAddedBillCountReducer,
      0,
    );
    if (
      totalNumAddedBillSelected < billsStateMemo.length - SYSTEM_BILL_COUNT &&
      selectAllAdded === true
    ) {
      // when at least one of the selected bill is unselected
      setSelectAllAdded(false);
    } else if (
      totalNumAddedBillSelected !== 0 &&
      totalNumAddedBillSelected === billsStateMemo.length - SYSTEM_BILL_COUNT &&
      selectAllAdded === false
    ) {
      // when the last one unselected bill is selected
      setSelectAllAdded(true);
    }
  }, [
    SYSTEM_BILL_COUNT,
    billsStateMemo,
    selectAllAdded,
    selectedAddedBillCountReducer,
  ]);

  return (
    <>
      <StepPageLayout currentStepIndex={0}>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <CircularProgress />
          </Box>
        ) : hasErrored ? (
          <ErrorDisplay errorMessage={errorMessage} />
        ) : (
          <>
            <Box sx={classes.payBoxFrame}>
              <Box sx={classes.payBox}>
                <Box sx={classes.payBoxLeft}>
                  <Typography sx={classes.title}>
                    {toTitleCase(AMOUNT_TO_PAY_TITLE)}
                  </Typography>
                  <Typography sx={classes.payValue}>
                    {formatPayment(getTotalAmount(billsState))}
                  </Typography>
                </Box>
                <Box sx={classes.payBoxRight}>
                  <Box sx={classes.cardButtons}>
                    <Button
                      sx={classes.fullButton}
                      variant="contained"
                      color="primary"
                      disabled={disablePayment()}
                      onClick={() => {
                        dispatch(setBillsToPay(billsState));

                        logEventToGoogleAnalytics(
                          EVENTS.SELECT_PAY_BILL_SUMMARY,
                        );
                        navigate(PATHS.PAYMENT_PAYOR_INFO.path);
                      }}
                    >
                      {BUTTON_TITLE_PAY}
                    </Button>
                  </Box>
                </Box>
              </Box>
              {getTotalAmount(billsState) >
                MAX_AMOUNT_PAYABLE_PER_TRANSACTION && (
                <Box sx={classes.errText}>{TRANSACTION_LIMIT_ERROR}</Box>
              )}
            </Box>
            <Box mt={13.5}>
              {allSystemBills.length > 0 ? (
                <Box sx={classes.card}>
                  <Box sx={classes.cardHeader}>
                    <Typography sx={classes.cardTitle}>
                      {OUTSTANDING_BILL_TITLE}
                    </Typography>
                  </Box>
                  <Box sx={classes.cardDetailWrapper}>
                    {allSystemBills.map((bill, i) => {
                      return (
                        <BillCard
                          key={bill.InvoiceNumber}
                          bill={bill}
                          checked={getSelectedByInvNo(
                            bill.InvoiceNumber,
                            billsState,
                          )}
                          disabled={bill.HasPendingArPosting}
                          amountToPayForThisBill={getAmountByInvNo(
                            bill.InvoiceNumber,
                            billsState,
                          )}
                          onCheckBill={() => updateSelectedBillByIndex(i)}
                          onChangeAmount={(amount) =>
                            updateAmountToPayByIndex(i, amount)
                          }
                          memberIdentifier={memberIdentifier}
                          showDownloadBillIcon={true}
                        />
                      );
                    })}
                  </Box>
                </Box>
              ) : null}
              {allAddedBills && allAddedBills.length > 0 ? (
                <Box sx={classes.card}>
                  <Box sx={classes.cardHeader}>
                    <Box sx={classes.cardTopPanel}>
                      <Box sx={classes.cardLeftPanel}>
                        <Box sx={classes.checkboxField}>
                          <SimpleCheckbox
                            checked={selectAllAdded}
                            value=""
                            label=""
                            handleChange={() => {
                              setSelectAllAdded(!selectAllAdded);
                              updateSelectAllAddedBill(!selectAllAdded);
                            }}
                            data-testid="simple-checkbox"
                            ariaLabel="all added bills"
                          />
                        </Box>
                      </Box>
                      <Box sx={classes.cardRightPanel}>
                        <Box sx={classes.cardDetails}>
                          <Box sx={classes.cardLeft}>
                            <Typography sx={classes.cardTitle}>
                              {toTitleCase(OTHER_ADDED_BILL_TITLE)}
                            </Typography>
                          </Box>
                          <Box sx={classes.cardRight}>
                            <Typography sx={classes.cardValue}>
                              <IconButton
                                aria-label="delete"
                                sx={classes.deleteIcon}
                                disabled={
                                  billsState.reduce(
                                    selectedAddedBillCountReducer,
                                    0,
                                  ) === 0
                                }
                                onClick={() => {
                                  setSelectedAddedbill();
                                  setOpen(true);
                                }}
                              >
                                <img
                                  src={IMAGES.general.DeleteIcon}
                                  alt="Delete"
                                />
                              </IconButton>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={classes.cardDetailWrapper}>
                    {allAddedBills.map((bill, i) => {
                      return (
                        <BillCard
                          key={bill.InvoiceNumber}
                          bill={bill}
                          checked={getSelectedByInvNo(
                            bill.InvoiceNumber,
                            billsState,
                          )}
                          amountToPayForThisBill={getAmountByInvNo(
                            bill.InvoiceNumber,
                            billsState,
                          )}
                          onCheckBill={() =>
                            updateSelectedBillByIndex(allSystemBills.length + i)
                          }
                          onChangeAmount={(amount) =>
                            updateAmountToPayByIndex(
                              allSystemBills.length + i,
                              amount,
                            )
                          }
                          memberIdentifier={memberIdentifier}
                          showDownloadBillIcon={false}
                        />
                      );
                    })}
                    <ConfirmationModal
                      open={open}
                      title="Are you sure?"
                      body={
                        <>
                          Bill <b>Reference No. {billRefNo.toString()}</b> will
                          be deleted.
                        </>
                      }
                      nextButtonText="Delete"
                      cancelButtonText="Cancel"
                      onClose={() => setOpen(false)}
                      onClickNext={() => {
                        deleteSelectedAddedBill();
                        setOpen(false);
                      }}
                      onClickCancel={() => setOpen(false)}
                    />
                  </Box>
                </Box>
              ) : null}
              <Box sx={classes.addOtherBillButton}>
                <IconLink
                  title={ICON_LINK_TITLE}
                  icon="addCircleOutline"
                  iconPosition="start"
                  onClick={() => {
                    logEventToGoogleAnalytics(
                      EVENTS.SELECT_ADD_OTHER_BILL_BILL_SUMMARY,
                    );
                    setOpenAddBillDialog(true);
                  }}
                />
              </Box>
              <AddOtherBillDialog
                open={openAddBillDialog}
                hostedOnBillSummaryPage={true}
                handleClose={() => {
                  setOpenAddBillDialog(!openAddBillDialog);
                }}
              />
            </Box>{" "}
          </>
        )}
      </StepPageLayout>
    </>
  );
};

// ================
// Helper functions
// ================
// Use a list of BillStateVar as a state vaiable billsState,
// to track the selected and amount status of each bill on this page
// Also referred by payorInfo page
const mapOutstandingBillToStateVar = (
  bill: ViewOutstandingBills,
): PaymentBillsToPayState => {
  return {
    InvNo: bill.InvoiceNumber ?? "",
    Selected: !bill.HasPendingArPosting,
    AmountToPay: bill.AmountDueFromPatient.toFixed(2),
  };
};
const mapAddedBillToStateVar = (
  bill: ViewManualBill,
): PaymentBillsToPayState => {
  return {
    InvNo: bill.InvoiceNumber ?? "",
    Selected: true,
    AmountToPay:
      (!bill.AmountDueFromPatient
        ? bill.PaymentAmount
        : bill.AmountDueFromPatient?.toFixed(2)) ?? "0",
  };
};

// Function to calculate total amount to pay on the go
const totalAmountToPayReducer = (
  accumulator: number,
  currentBill: PaymentBillsToPayState,
): number => {
  const currentValue = currentBill.Selected ? currentBill.AmountToPay : 0;
  return accumulator + Number(currentValue);
};

const getTotalAmount = (billsState: PaymentBillsToPayState[]): number => {
  const rawTotal = billsState.reduce(totalAmountToPayReducer, 0);
  return Number(rawTotal.toFixed(2));
};

// Function to get selected / checked state for each bill, passed into child components
const getSelectedByInvNo = (
  invNo: string | null,
  billsState: PaymentBillsToPayState[],
): boolean => {
  const filtered = billsState.filter((bill) => bill.InvNo === invNo);
  return filtered.length > 0 ? filtered[0].Selected : false;
};
// Function to get amount to pay for each bill, passed into child components
const getAmountByInvNo = (
  invNo: string | null,
  billsState: PaymentBillsToPayState[],
): string => {
  const filtered = billsState.filter((bill) => bill.InvNo === invNo);
  return filtered.length > 0 ? filtered[0].AmountToPay : "0";
};

export const exportForTesting = { mapAddedBillToStateVar };

export default BillSummary;
