import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { sxStyles } from "./ActionBar.styles";
import { PickersActionBarProps } from "@mui/x-date-pickers";

const ActionBar = ({
  onAccept,
  onClear,
  onCancel,
  onSetToday,
  actions,
}: PickersActionBarProps) => {
  const isTodayEnabled = actions?.find((el) => el === "today") ? true : false;

  const classes = sxStyles({
    isTodayEnabled,
  });

  const customActions = actions?.map((actionType) => {
    switch (actionType) {
      case "clear":
        return { text: "Clear", method: onClear };
      case "cancel":
        return { text: "Cancel", method: onCancel };
      case "accept":
        return { text: "OK", method: onAccept };
      default:
        return null;
    }
  });

  return (
    <List sx={classes.listContainer}>
      {isTodayEnabled && (
        <ListItem key={"Today"} disablePadding sx={classes.listItem}>
          <ListItemButton onClick={onSetToday} sx={classes.listItemButton}>
            <ListItemText sx={classes.listItemText} primary={"Today"} />
          </ListItemButton>
        </ListItem>
      )}

      <Box sx={classes.list}>
        {customActions && customActions.length !== 0
          ? customActions.map((item) => {
              return item ? (
                <ListItem key={item.text} disablePadding sx={classes.listItem}>
                  <ListItemButton
                    onClick={item.method}
                    sx={classes.listItemButton}
                  >
                    <ListItemText
                      sx={classes.listItemText}
                      primary={item.text}
                    />
                  </ListItemButton>
                </ListItem>
              ) : null;
            })
          : null}
      </Box>
    </List>
  );
};

export default ActionBar;
