import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    receiptLayout: {
      padding: theme.spacing(1),
    },
    checkStatusBox: {
      marginTop: "35%",
    },

    title: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      padding: theme.spacing(0, 1),
    },
    summaryCard: {
      borderBottom: "1px solid " + theme.palette.secondary.light,
      padding: theme.spacing(1, 0),
    },
    cardButtons: {
      margin: theme.spacing(2.5, 1),
    },
    cardRight: {
      textAlign: "right",

      minWidth: 100,
    },
  } satisfies SxObjType;
};

export { sxStyles };
