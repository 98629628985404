import { CREATE_PATIENT_MASTER_URL } from "api/resources/configs/URL";
import { CreatePatientMasterProfileResponsePayload } from "./createPatientMasterProfile.fromApi.types";
import { formatCreatePatientMasterProfileErrorMessage } from "api/resources/messages/ErrorMessage";
import { PatientMasterCreateUpdateProfile } from "../patientMasterProfile.types";
import axios from "axios";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "../../resources/configs/requests";
// import { MOCK_CREATE_PATIENT_MASTER_PROFILE_RESPONSE } from "./mock/createPatientMasterProfile.mock";

export const createPatientMasterProfile = async (
  createPatientMasterProfile: PatientMasterCreateUpdateProfile,
): Promise<CreatePatientMasterProfileResponsePayload> => {
  try {
    const response =
      await axios.post<CreatePatientMasterProfileResponsePayload>(
        CREATE_PATIENT_MASTER_URL(),
        createRequestPayload(createPatientMasterProfile),
        getRequestOptions(),
      );
    return parseResponse(response, CREATE_PATIENT_MASTER_URL(), true);
  } catch (error) {
    const msg = formatCreatePatientMasterProfileErrorMessage(
      CREATE_PATIENT_MASTER_URL(),
    );
    console.error(msg);
    throw error;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createRequestPayload = (
  createPatientMasterProfile: PatientMasterCreateUpdateProfile,
) => {
  return {
    ...createPatientMasterProfile,
  };
};
