import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, CircularProgress } from "@mui/material";
import { PATHS } from "lib/routing";
import Page from "lib/components/layout/Page/Page";
import { useAutocomplete } from "@mui/base";
import SimpleAutocomplete from "lib/components/formInputs/SimpleAutocomplete/SimpleAutocomplete";
import SelectionInstructions from "ui/appointment/components/common/selection-listing/SelectionInstructions/SelectionInstructions";
import SelectionList from "ui/appointment/components/common/selection-listing/SelectionList/SelectionList";
import { InstitutionsPageProps } from "./InstitutionsPage.types";
import { sxStyles } from "./InstitutionsPage.styles";
import { useGetFacilities } from "ui/medrefill/hooks/useGetFacilities";
import { DisplayableItem } from "lib/data/data.types";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import { Facility } from "api/medrefill/GetFacilities/getFacilities.fromApi.types";
import { MedInstitution } from "ui/medrefill/ducks/medrefill.types";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectUser } from "lib/redux/user/selectors";
import { setOldTokenizedId } from "lib/redux/user/userSlice";

const InstitutionsPage = ({
  prescriptionType,
  lastFiveSearchedKeywords,
  isLoadingFormData,
  hasErroredSystemSettings,
  errorMessageSystemSettings,
  hasErroredMedications,
  errorMessageMedications,
  hasErroredCollectionPaymentDetails,
  errorMessageCollectionPaymentDetails,
  hasErroredClinics,
  errorMessageClinics,
  hasErroredDocByCluster,
  errorMessageDocByCluster,
  onLoad,
  onSelect,
  onSaveInstitutionKeyword,
}: InstitutionsPageProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();
  const dispatch = useAppDispatch();

  // Redux States
  const { oldTokenizedId } = useAppSelector(selectUser);

  // Local States
  const [pageFlag, setPageFlag] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState<{
    facilityId: number;
    facilityName: string;
    cluster: string;
  } | null>(null);

  const selectFacility = (facility: Facility) => {
    // set the state to be able to trigger API call again with the state values whenever error occurs
    setSelectedFacility({
      facilityId: facility.FacilityId,
      facilityName: facility.FacilityName,
      cluster: facility.Cluster,
    });
    // call API to fetch form data (systemSettings, medications, payment mode, etc)
    onSelect(facility.FacilityId, facility.FacilityName, facility.Cluster);
  };

  useEffect(() => {
    // Load data and initialize API call error status every time this component mounts
    onLoad();
    if (prescriptionType === "REFILL") {
      logEventToGoogleAnalytics(EVENTS.VIEW_NEW_REFILL_INSTITUTIONS);
    } else {
      logEventToGoogleAnalytics(EVENTS.VIEW_NEW_TOPUP_INSTITUTIONS);
    }
  }, [onLoad, prescriptionType]);

  const [
    payload,
    mappedPayload,
    isLoading,
    hasErrored,
    errorMessage,
    oldTokenizedIdFromRes,
  ] = useGetFacilities(pageFlag, prescriptionType, oldTokenizedId);

  useEffect(() => {
    if (oldTokenizedIdFromRes) {
      dispatch(setOldTokenizedId(oldTokenizedIdFromRes));
    }
  }, [dispatch, oldTokenizedIdFromRes]);

  const { getInputProps, groupedOptions, inputValue, focused } =
    useAutocomplete({
      options: mappedPayload ? mappedPayload : [],
      getOptionLabel: (option: DisplayableItem) => option.displayName,
    });

  // do the page redirection only after all form data API calls have been finished successfully
  useEffect(() => {
    if (
      selectedFacility &&
      !isLoadingFormData &&
      hasErroredSystemSettings === false &&
      hasErroredMedications === false &&
      hasErroredCollectionPaymentDetails === false &&
      hasErroredClinics === false &&
      hasErroredDocByCluster === false
    ) {
      if (prescriptionType === "REFILL") {
        navigate(PATHS.MED_REFILL_PARTICULARS.path);
      } else {
        navigate(PATHS.MED_TOPUP_PARTICULARS.path);
      }
    }
  }, [
    selectedFacility,
    isLoadingFormData,
    hasErroredSystemSettings,
    hasErroredMedications,
    hasErroredCollectionPaymentDetails,
    hasErroredClinics,
    hasErroredDocByCluster,
    prescriptionType,
    navigate,
  ]);

  return (
    <Page>
      <SelectionInstructions text="Select the Prescribing Institution"></SelectionInstructions>
      {isLoading || isLoadingFormData ? (
        <Box display="flex" align-items="center" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : hasErrored ? (
        <ErrorDisplay
          errorMessage={errorMessage}
          onTryAgain={() => setPageFlag(!pageFlag)}
        />
      ) : hasErroredSystemSettings && selectedFacility ? (
        <ErrorDisplay
          errorMessage={errorMessageSystemSettings}
          onTryAgain={() =>
            onSelect(
              selectedFacility.facilityId,
              selectedFacility.facilityName,
              selectedFacility.cluster,
            )
          }
        />
      ) : hasErroredMedications && selectedFacility ? (
        <ErrorDisplay
          errorMessage={errorMessageMedications}
          onTryAgain={() =>
            onSelect(
              selectedFacility.facilityId,
              selectedFacility.facilityName,
              selectedFacility.cluster,
            )
          }
        />
      ) : hasErroredCollectionPaymentDetails && selectedFacility ? (
        <ErrorDisplay
          errorMessage={errorMessageCollectionPaymentDetails}
          onTryAgain={() =>
            onSelect(
              selectedFacility.facilityId,
              selectedFacility.facilityName,
              selectedFacility.cluster,
            )
          }
        />
      ) : hasErroredClinics && selectedFacility ? (
        <ErrorDisplay
          errorMessage={errorMessageClinics}
          onTryAgain={() =>
            onSelect(
              selectedFacility.facilityId,
              selectedFacility.facilityName,
              selectedFacility.cluster,
            )
          }
        />
      ) : hasErroredDocByCluster && selectedFacility ? (
        <ErrorDisplay
          errorMessage={errorMessageDocByCluster}
          onTryAgain={() =>
            onSelect(
              selectedFacility.facilityId,
              selectedFacility.facilityName,
              selectedFacility.cluster,
            )
          }
        />
      ) : payload ? (
        <>
          <Box mt={2} mb={1} px={2} style={{ zIndex: 100 }}>
            <SimpleAutocomplete
              placeholder="Search Institutions"
              showHistoryKeyword={focused && inputValue.length === 0}
              items={lastFiveSearchedKeywords.filter((keyword) =>
                mappedPayload?.map((fac) => fac.displayName).includes(keyword),
              )}
              inputProps={getInputProps}
              onSelect={(selectedValue) => {
                let index = payload
                  ?.map((fac) => fac.FacilityName)
                  .indexOf(selectedValue);
                selectFacility(payload[index]);
                onSaveInstitutionKeyword(selectedValue);
              }}
            />
          </Box>
          {inputValue.length !== 0 && groupedOptions.length === 0 ? (
            <Box sx={classes.notFoundTextBox}>
              <Typography sx={classes.notFoundText}>
                The institution you are looking for is not in this list.
              </Typography>
            </Box>
          ) : (
            <Box sx={classes.selectionListBox}>
              <SelectionList
                items={
                  groupedOptions.length === 0
                    ? mappedPayload || []
                    : (groupedOptions as MedInstitution[])
                }
                onSelect={(selectedValue) => {
                  let index = payload
                    ?.map((fac) => fac.FacilityName)
                    .indexOf(selectedValue);
                  selectFacility(payload[index]);
                  onSaveInstitutionKeyword(selectedValue);
                }}
              />
            </Box>
          )}
        </>
      ) : null}
    </Page>
  );
};

export default InstitutionsPage;
