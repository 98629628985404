import { useState } from "react";
import { StepPageButtonsFooterProps } from "./StepPageButtonsFooter.types";
import { useNavigate } from "react-router-dom";
import { sxStyles } from "./StepPageButtonsFooter.styles";
import { Box } from "@mui/material";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { PATHS } from "lib/routing";

const StepPageButtonsFooter = ({
  nextButtonText = "Next",
  isNextButtonDisabled,
  pathToNextStep,
  pathToCancelRequest = PATHS.MRRP_MOBILE.path,
  onNextStep,
}: StepPageButtonsFooterProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();

  // Cancel Request Warning Dialog open & close state and handlers
  const [open, setOpen] = useState(false);

  const handleOpenWarningModal = () => {
    setOpen(true);
  };

  const handleCloseWarningModal = () => setOpen(false);

  const handleClickConfirm = () => {
    navigate(pathToCancelRequest, { replace: true });
  };

  return (
    <Box sx={{ mt: 1, mb: 3 }}>
      <Box sx={classes.buttonsFooter}>
        <ButtonsFooter
          isDisabled={isNextButtonDisabled}
          nextButtonText={nextButtonText}
          cancelButtonText="Cancel request"
          onClickNext={async () => {
            onNextStep();
            if (pathToNextStep) navigate(pathToNextStep);
          }}
          onClickCancel={handleOpenWarningModal}
        />
      </Box>
      <ConfirmationModal
        open={open}
        title="Cancel Request?"
        body={
          <p>
            If you cancel now, you will need to fill in your report details
            again.
          </p>
        }
        nextButtonText="No, continue request"
        cancelButtonText="Yes, cancel request"
        showCloseButton={true}
        onClose={handleCloseWarningModal}
        onClickNext={handleCloseWarningModal}
        onClickCancel={handleClickConfirm}
      />
    </Box>
  );
};

export default StepPageButtonsFooter;
