import { color } from "lib/theme/color";
import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    payBoxFrame: {
      width: "100%",
      borderBottom: "1px solid " + theme.palette.grey[100],
      backgroundColor: theme.palette.common.white,
      position: "fixed",
      zIndex: 10,
    },
    payBox: {
      display: "flex",
      alignItems: "center",
    },
    payBoxLeft: {
      width: "100%",
      marginLeft: theme.spacing(2),
    },
    payBoxRight: {
      textAlign: "right",
      color: theme.palette.common.black,
      typography: theme.typography.header1_semibold,
      minWidth: 120,
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
    },
    payValue: {
      color: theme.palette.warning.main,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      width: "100%",
      textAlign: "left",
    },
    cardButtons: {
      margin: theme.spacing(2.5, 2),
      "& div": {
        marginBottom: 0,
      },
    },
    fullButton: {
      display: "block",
      width: "100%",
    },
    errText: {
      textAlign: "right",
      color: theme.palette.error.main,
      typography: theme.typography.caption1_regular,
      margin: theme.spacing(-1.5, 1.5, 0.5),
    },
    addOtherBillButton: {
      display: "grid",
      justifyContent: "flex-end",
      margin: theme.spacing(2.5, 2),
    },
    card: {
      boxShadow: "0px 0px 6px " + color.RGBA_52,
      margin: theme.spacing(3, 1, 1),
    },
    cardHeader: {
      display: "flex",
      background: theme.palette.secondary.main,
      padding: theme.spacing(1.5, 1),
    },
    cardTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      width: "100%",
    },
    cardDetailWrapper: {
      margin: theme.spacing(0),
    },
    cardTopPanel: {
      display: "flex",
      border: 0,
      width: "100%",
    },
    cardLeftPanel: {
      margin: theme.spacing(0, 1, 0, 0),
      "& .MuiFormControlLabel-root": {
        marginRight: 0,
      },
    },
    cardRightPanel: {
      width: "100%",
    },
    checkboxField: {
      margin: theme.spacing(0),
      "& .MuiCheckbox-root": {
        padding: theme.spacing(-1.125, 1.125),
        paddingBottom: 0,
        paddingTop: 0,
      },
      "& .MuiFormControlLabel-root": {
        marginRight: 0,
      },
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: theme.palette.primary.main,
      },
    },
    cardDetails: {
      wordBreak: "break-word",
      padding: theme.spacing(0),
      display: "flex",
      alignItems: "center",
      "&:last-child": {
        borderBottom: `0px solid ${theme.palette.secondary.light}`,
      },
    },
    cardLeft: {
      width: "100%",
    },
    cardRight: {
      textAlign: "right",
      color: theme.palette.common.black,
      typography: theme.typography.body2_semibold,
      minWidth: 110,
    },

    cardValue: {
      color: theme.palette.common.black,
      typography: theme.typography.body2_semibold,
    },
    deleteIcon: {
      padding: 0,
      fontWeight: theme.typography.fontWeightMedium,
    },
  } satisfies SxObjType;
};

export { sxStyles };
