import { Box, Skeleton } from "@mui/material";

const InstitutionsPageSkeleton = () => {
  return (
    <>
      <Box sx={{ mt: 1, mb: 1, px: 2 }}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={40}
          sx={{ mt: "15px", height: 40 }}
        />
        <Skeleton
          animation="wave"
          variant="text"
          height={50}
          width={140}
          sx={{ mt: "25px", height: 50, width: 140 }}
        />
        <Skeleton
          animation="wave"
          variant="text"
          height={40}
          width={250}
          sx={{ mt: "2px", height: 40, width: 250 }}
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ mt: "2px", height: 40, width: 250 }}
        />
      </Box>
    </>
  );
};

export default InstitutionsPageSkeleton;
