import axios from "axios";
import { GET_MY_INFO_AUTH_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatGetMyInfoAuthUrlErrorMessage } from "api/resources/messages/ErrorMessage";
import { GetMyInfoAuthUrlResponsePayload } from "./getMyInfoAuthUrl.fromApi.types";

/**
 * Get redirection url to MyInfo site, and store user identifer for matching later
 *
 * POST
 * Endpoint: secure/myinfo/GetMyInfoAuthUrl
 *
 * @param {string | null} purpose specify the purpose for retrieving MyInfo data at MyInfo consent page, default purpose will be generatedfrom backend if not provided
 * @param {string | null} memberIdentifier  callback URL to caller module, will be enchoed back in the RetrieveMyInfoProfile and SaveMyInfoProfile response
 */
const getMyInfoAuthUrl = async (
  purpose: string | null,
  moduleCallBackUrl: string | null,
): Promise<GetMyInfoAuthUrlResponsePayload> => {
  try {
    const response = await axios.post<GetMyInfoAuthUrlResponsePayload>(
      GET_MY_INFO_AUTH_URL(),
      createRequestPayload(purpose, moduleCallBackUrl),
      getRequestOptions(),
    );
    return parseResponse(response, GET_MY_INFO_AUTH_URL());
  } catch (error) {
    const msg = formatGetMyInfoAuthUrlErrorMessage(GET_MY_INFO_AUTH_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  purpose: string | null,
  callBackUrl: string | null,
) => {
  return {
    Purpose: purpose,
    ModuleCallBackUrl: callBackUrl,
  };
};

export { getMyInfoAuthUrl };
