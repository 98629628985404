import React from "react";
import { NumberAndUomDurationFieldsProps } from "./NumberAndUomDurationFields.types";
import { Box } from "@mui/material";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import { sxStyles } from "./NumberAndUomDurationFields.styles";
import GroupedDropdown from "lib/components/formInputs/GroupedDropdown/GroupedDropdown";
import Dropdown from "lib/components/formInputs/Dropdown/Dropdown";

const NumberAndUomDurationFields = ({
  numberPlaceholder,
  number,
  uomDurationPlaceholder, //label for dropdown on right
  uomDurationId,
  handleNumberChange,
  handleUomDurationIdChange,
  durationList,
  uomList,
  numberValidationMsg,
}: NumberAndUomDurationFieldsProps) => {
  const classes = sxStyles();

  return (
    <>
      <Box mt={1} sx={classes.sameLine}>
        <Box
          sx={classes.numberBox}
          width={numberPlaceholder === "Number" ? "30%" : "40%"}
          mt={2}
        >
          <SingleLineTextField
            placeholder={numberPlaceholder || ""}
            type="number"
            value={number?.toString() || ""}
            handleChange={(event) => {
              if (event.target.value === "") {
                handleNumberChange(null);
              } else {
                handleNumberChange(parseInt(event.target.value));
              }
            }}
            maxCharLength={4}
            error={numberValidationMsg !== null}
            errorText={numberValidationMsg || ""}
          ></SingleLineTextField>
        </Box>
        <Box
          sx={classes.durationBox}
          width={numberPlaceholder === "Number" ? "40%" : "45%"}
          mt={2}
        >
          {uomList.length !== 0 ? (
            <GroupedDropdown
              label="Unit of Measure"
              listOfItems={[durationList, uomList]}
              categories={["Duration", "Unit of Order"]}
              value={uomDurationId}
              handleChange={(event) =>
                handleUomDurationIdChange(event.target.value)
              }
            ></GroupedDropdown>
          ) : (
            <Dropdown
              label="Duration"
              items={durationList}
              value={uomDurationId}
              handleChange={(event) =>
                handleUomDurationIdChange(event.target.value)
              }
            ></Dropdown>
          )}
        </Box>
      </Box>
    </>
  );
};

export default NumberAndUomDurationFields;
