import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    text: {
      color: theme.palette.text.secondary,
      lineHeight: 1.5,
    },
  } satisfies SxObjType;
};

export { sxStyles };
