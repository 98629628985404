import React from "react";
import PastRequest from "ui/medrefill/components/common/pastRequest/PastRequest";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";

const PastRequestPage = () => {
  logEventToGoogleAnalytics(EVENTS.VIEW_PAST_REQUEST);

  return <PastRequest requestType={null} />;
};

export default PastRequestPage;
