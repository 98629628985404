import { Box, Typography } from "@mui/material";
import ServiceCardProps from "./CategoryCard.types";
import { sxStyles } from "../Dashboard.styles";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import * as Constants from "../Constants";
import VerticalSpreadLayout from "lib/components/layout/VerticalSpreadLayout/VerticalSpreadLayout";
import IMAGES from "lib/assets/images";
import { setNavBarDisplayDisplayName } from "lib/redux/navigation";
import { useAppDispatch } from "lib/redux/hooks";
import { setSelectedCategory } from "ui/mhm/ducks";

const CategoryCard = ({ name }: ServiceCardProps) => {
  const classes = sxStyles();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const categoryImage =
    Constants.CATEGORY_IMAGE_MAP[name.code || ""] ||
    IMAGES.healthTogetherMHM.CategoryDefaultImage;

  return (
    <>
      <Box sx={classes.semiCard}>
        <VerticalSpreadLayout>
          <Box
            onClick={() => {
              dispatch(setNavBarDisplayDisplayName(name.display));
              dispatch(setSelectedCategory(name.code));
              navigate(PATHS.MHM_ENROLLED_USER_HMT.path);
            }}
          >
            <Box
              component="img"
              src={categoryImage}
              alt={name.display || ""}
              sx={classes.cardIcon}
            />
            <Typography sx={classes.subtitle}>{name.display}</Typography>
          </Box>
        </VerticalSpreadLayout>
      </Box>
    </>
  );
};

export default CategoryCard;
