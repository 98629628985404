import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { CREATE_APPOINTMENT_URL } from "../../resources/configs/URL";
import { formatCreateAppointmentErrorMessage } from "../../resources/messages/ErrorMessage";
import { CreateAppointmentPayload } from "./createAppointment.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";

/**
 * Creates an appointment
 *
 * POST
 * Endpoint: /CreateAppointment
 *
 * @param {string | null} memberIdentifier  Member Identifier of the person we are making this request for
 * @param {RelationType} relationType patient relationship to logged in user
 * @param {string} slotDate  ISO formatted DateTime (e.g. "2020-06-20T11:16:56") of the available appointment slot
 * @param {string | null} targetSystem  Source system from which these slots are from
 * @param {string | null} institutionCode  Code of the institution to create an appointment for
 *
 * @returns {CreateAppointmentPayload} Response payload from the remote API
 */

interface createAppointmentReq {
  visitTypeId: string | null;
  memberIdentifier: string | null;
  start: string | null;
  slotId: string;
  institutionCode: string | null;
  isHsgAppt: boolean | null;
  end?: string | null;
}

const createAppointment = async ({
  visitTypeId,
  memberIdentifier,
  start,
  slotId,
  end = "",
  isHsgAppt,
  institutionCode,
}: createAppointmentReq): Promise<CreateAppointmentPayload> => {
  try {
    const response = await axios.post<CreateAppointmentPayload>(
      CREATE_APPOINTMENT_URL(),
      createRequestPayload({
        visitTypeId,
        memberIdentifier,
        start,
        end,
        isHsgAppt,
        slotId,
        institutionCode,
      }),
      getRequestOptions(),
    );
    return parseResponse(response, CREATE_APPOINTMENT_URL());
  } catch (error) {
    const msg = formatCreateAppointmentErrorMessage(CREATE_APPOINTMENT_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = ({
  visitTypeId,
  memberIdentifier,
  start,
  slotId,
  end,
  isHsgAppt,
  institutionCode,
}: createAppointmentReq) => {
  return {
    VisitTypeId: visitTypeId,
    MemberIdentifier: memberIdentifier,
    Start: start,
    End: end,
    InstitutionCode: institutionCode,
    SlotId: slotId,
    IsHSG: isHsgAppt,
  };
};

export { createAppointment };
