import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { setCurrentFlowAction } from "lib/redux/navigation";
import { RootState } from "lib/redux/root/redux.types";
import RescheLinkedApptAvailableSlotsPage from "./RescheLinkedApptAvailableSlotsPage";
import {
  setSlotDetailsForRescheduleLinkedAppointment,
  updateRescheduleLinkedAppointmentVisitedSlotsPage,
} from "ui/appointment/ducks";

// ==========================
// MAP STATE TO PROPS
// ==========================

const mapStateToProps = (state: RootState) => {
  return {
    memberIdentifier: state.user.memberIdentifier,
    appointment:
      state.appointments.rescheduleLinkedAppointment.selectedLinkedAppointment,
  };
};

// ==========================
// MAP DISPATCH TO PROPS
// ==========================

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onLoad: () => {
      dispatch(setCurrentFlowAction("APPOINTMENT_RESCHEDULE"));
    },
    onSelect: (slotDateTime: string | null, slotId: string) => {
      dispatch(
        setSlotDetailsForRescheduleLinkedAppointment({
          datetime: slotDateTime,
          slotId: slotId,
        }),
      );
    },
    updateVisitedSlotsPage: () => {
      dispatch(updateRescheduleLinkedAppointmentVisitedSlotsPage(true));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RescheLinkedApptAvailableSlotsPage);
