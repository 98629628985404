import { Box, Button, IconButton, Typography } from "@mui/material";
import IMAGES from "lib/assets/images";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { removeImageDataEncodingPrefix } from "lib/util/ReactComponentUtil/removeImageDataEncodingPrefix/removeImageDataEncodingPrefix";
import { useRef } from "react";
import {
  setFileUploadDetails,
  setFileUploadPrescriptionError,
} from "ui/medrefill/ducks/medrefillSlice";
import { selectFileUpload } from "ui/medrefill/ducks/selectors";
import { sxStyles } from "./ImageUploader.styles";
import {
  ImageUploaderProps,
  OverlayColorType,
  UploadState,
} from "./ImageUploader.types";
import * as ProcessUploadFileModule from "./processUploadedFile";
import { getInitialUploadState } from "./processUploadedFile.states";

// Inline image encoding scheme prefix (required by <img src="..." /> components)
const INLINE_IMAGE_ENCODING_SCHEME_PREFIX = "data:image/jpeg;base64,";

const ImageUploader = ({
  isAlternateColor = false,
  overlayText,
  defaultFileData,
  onFileUploadSuccess,
  onClearFileData,
  onImageProcessing,
  disabled = false,
  index,
  nestedIndex,
}: ImageUploaderProps) => {
  const dispatch = useAppDispatch();
  const { fileUploadPrescriptionErrorArray, fileUploadDetails } =
    useAppSelector(selectFileUpload);
  const inputFieldRef = useRef<HTMLInputElement>(null);
  // const [fileUploadState, setFileUploadState] = useState<UploadState>({
  //   ...getInitialUploadState(),
  // });

  const classes = sxStyles(getColorType(isAlternateColor, defaultFileData));

  return (
    <Box position="relative">
      {/* Clear Image Button */}
      {defaultFileData &&
      fileUploadDetails[index][nestedIndex].errorMessage === null ? (
        <IconButton
          sx={classes.clearButton}
          onClick={() => {
            onClearFileData();
            var innerArray: UploadState[] = [];
            var outerArray: UploadState[][] = [];
            for (var i = 0; i < fileUploadDetails.length; i++) {
              innerArray = [...fileUploadDetails[i]];
              outerArray = [...outerArray, [...innerArray]];
            }
            //"index" and "nestedIndex" may encounter 0
            if (
              typeof index !== "undefined" &&
              index !== -1 &&
              typeof nestedIndex !== "undefined" &&
              nestedIndex !== -1
            ) {
              outerArray[index][nestedIndex] = { ...getInitialUploadState() };
              dispatch(setFileUploadDetails(outerArray));
            }
          }}
          disabled={disabled}
        >
          <Box component={"img"} src={IMAGES.general.CancelIcon} />
        </IconButton>
      ) : null}

      {/* Upload Image Button */}
      <Button
        sx={classes.uploadButton}
        onClick={() => {
          // Simulates a file upload. Invokes the appropriate file input field's onChange
          // handler, as that is where the data for the uploaded file will be passed to
          inputFieldRef?.current?.click();
        }}
        disabled={disabled}
      >
        {/* Loading, Error and Overlay, based on states */}
        {fileUploadDetails[index][nestedIndex].isLoading ? (
          <Box
            component={"img"}
            src={IMAGES.general.DottedLoading}
            alt="Uploading"
            sx={classes.loadingIcon}
          />
        ) : validateFileUpload(
            index,
            nestedIndex,
            fileUploadPrescriptionErrorArray,
          ) ? (
          // Error UI
          <>
            <Box
              component={"img"}
              src={IMAGES.general.Camera}
              alt="Camera"
              sx={
                fileUploadDetails[index][nestedIndex].errorMessage
                  ? classes.overlayErrorCameraIcon
                  : classes.overlayCameraIcon
              }
            />

            <Typography sx={classes.errorText}>
              {fileUploadDetails[index][nestedIndex].errorMessage}
            </Typography>
          </>
        ) : defaultFileData ? (
          // Uploaded Image
          <Box
            component={"img"}
            alt="uploaded"
            sx={classes.uploadedImage}
            src={
              INLINE_IMAGE_ENCODING_SCHEME_PREFIX + defaultFileData || undefined
            }
          />
        ) : (
          // Default camera icon
          <Box
            component={"img"}
            src={IMAGES.general.Camera}
            alt="Camera"
            sx={
              fileUploadDetails[index][nestedIndex].errorMessage
                ? classes.overlayErrorCameraIcon
                : classes.overlayCameraIcon
            }
          />
        )}

        {/* File upload input field, receives the file data (not for display) */}
        <input
          title="File Upload"
          style={{ display: "none" }} // Hide this field because its onChange handler is directly controlled
          // by the wrapping button's onClick invocation. Hence, we do not need
          // the default style of the vanilla HTML input field, and can hide it.
          ref={inputFieldRef}
          type="file"
          accept="image/*"
          onChange={(event) => {
            if (event?.target?.files) {
              // Set Image Loading State to true here to control 'next' button behaviour
              if (onImageProcessing) {
                onImageProcessing(true);
              }

              const file = event.target.files[0]; // file object from user input

              if (file) {
                ProcessUploadFileModule.processUploadedFile(
                  file,
                  (uploadState) => {
                    // All file processing states are updated here
                    var innerArray: UploadState[] = [];
                    var outerArray: UploadState[][] = [];
                    for (var i = 0; i < fileUploadDetails.length; i++) {
                      innerArray = [...fileUploadDetails[i]];
                      outerArray = [...outerArray, [...innerArray]];
                    }
                    //"index" and "nestedIndex" may encounter 0
                    if (
                      typeof index !== "undefined" &&
                      index !== -1 &&
                      typeof nestedIndex !== "undefined" &&
                      nestedIndex !== -1
                    ) {
                      outerArray[index][nestedIndex] = { ...uploadState };
                      dispatch(setFileUploadDetails(outerArray));
                    }

                    // File processed successfully, so we can pass this value to the calling function
                    if (
                      uploadState.hasUploadedSuccessfully &&
                      uploadState.fileName &&
                      uploadState.fileData
                    ) {
                      // Set Image Loading State to false here to control 'next' button behaviour
                      if (onImageProcessing) {
                        onImageProcessing(false);
                      }

                      onFileUploadSuccess(
                        uploadState.fileName,
                        removeImageDataEncodingPrefix(uploadState.fileData),
                      );
                    } else {
                      // Set Image Loading State to false here to control 'next' button behaviour
                      if (onImageProcessing) {
                        onImageProcessing(false);
                      }
                    }
                  },
                  onClearFileData,
                  (hasErrored) => {
                    var innerArray: boolean[] = [];
                    var outerArray: boolean[][] = [];
                    for (
                      var i = 0;
                      i < fileUploadPrescriptionErrorArray.length;
                      i++
                    ) {
                      innerArray = [...fileUploadPrescriptionErrorArray[i]];
                      outerArray = [...outerArray, [...innerArray]];
                    }
                    //"index" and "nestedIndex" may encounter 0
                    if (
                      typeof index !== "undefined" &&
                      index !== -1 &&
                      typeof nestedIndex !== "undefined" &&
                      nestedIndex !== -1
                    ) {
                      outerArray[index][nestedIndex] = hasErrored;
                      dispatch(setFileUploadPrescriptionError(outerArray));
                    }
                  },
                );
              } else {
                // Set Image Loading State to false here to control 'next' button behaviour
                if (onImageProcessing) {
                  onImageProcessing(false);
                }
              }
            }

            // !exp setting target value to empty resets the input to prepare to receive next image or repeated image
            event.target.value = "";
          }}
        />
      </Button>
      <Typography sx={classes.overlayText}>{overlayText}</Typography>
    </Box>
  );
};

const getColorType = (
  isAlternateColor: boolean,
  defaultFileData: string | null,
): OverlayColorType => {
  if (defaultFileData) {
    return "SUCCESS";
  } else if (isAlternateColor) {
    return "ALTERNATE";
  } else {
    return "DEFAULT";
  }
};

const validateFileUpload = (
  index: number | undefined,
  nestedIndex: number | undefined,
  fileUploadPrescriptionErrorArray: boolean[][],
) => {
  if (
    typeof index === "undefined" ||
    typeof nestedIndex === "undefined" ||
    index === null ||
    nestedIndex === null
  ) {
    return true;
  } else {
    return fileUploadPrescriptionErrorArray[index][nestedIndex];
  }
};

export default ImageUploader;
