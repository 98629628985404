import { Box, Button, Typography, useTheme } from "@mui/material";
import { getAddressByPostalCode } from "api/medrefill/GetAdressByPostalCode/getAddressByPostalCode";
import { AddressResult } from "api/medrefill/GetAdressByPostalCode/getAddressByPostalCode.types";
import Datepicker from "lib/components/formInputs/Datepicker/Datepicker";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { useEffect, useMemo, useState } from "react";
import {
  setBypassEpicProfile,
  setEpicProfileTempState,
  setPreferredAddPostalCodeError,
  setPreferredBlockError,
  setPreferredDateError,
  setPreferredStreetError,
} from "../PatientMaster/ducks/pmSlice";
import {
  selectEpicProfileState,
  selectEpicProfileTempState,
  selectPMGeneralErrors,
  selectPMSettingsValue,
} from "../PatientMaster/ducks/selectors";
import { sxStyles } from "./PreferredMailingAddPage.styles";
import { PreferredMailingAddPageProps } from "./PreferredMailingAddPage.types";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import { useNavigate } from "react-router-dom";
import { setCustomisedBack } from "lib/redux/navigation";
import { selectNavigation } from "lib/redux/navigation/selectors";
import { useGetMessageAction } from "lib/routing/messageChannel/hooks/useGetMessageAction";

const PreferredMailingAddPage = ({
  myInfoProfilePage,
}: PreferredMailingAddPageProps) => {
  const theme = useTheme();
  const classes = sxStyles(theme);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { messageActionObj, consumeMessageActionObj } = useGetMessageAction();

  // Redux States
  const preventDirectBackNavigationState =
    useAppSelector(selectNavigation).customisedBack;
  const { hasPostalCodeErrored, hasBlockErrored, hasStreetErrored } =
    useAppSelector(selectPMGeneralErrors);

  const { disclaimers } = useAppSelector(selectPMSettingsValue);

  const {
    PreferredPostalCode: pmPostalCode,
    PreferredStreetName: pmStreet,
    PreferredBlockNumber: pmBlock,
    PreferredUnitNumber: pmUnit,
    MailingAddressExpiryDate: pmDate,
  } = useAppSelector(selectEpicProfileState);
  const PMAStates = useAppSelector(selectEpicProfileTempState);

  const hasEditedPMA = useMemo(() => {
    if (PMAStates) {
      if (
        PMAStates.PreferredPostalCode !== pmPostalCode ||
        PMAStates.PreferredStreetName !== pmStreet ||
        PMAStates.PreferredBlockNumber !== pmBlock ||
        PMAStates.PreferredCombinedUnitNumber !== pmUnit ||
        PMAStates.MailingAddressExpiryDate !== pmDate
      ) {
        return true; //only if PMAState is not undefined and the values are different, return true
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [PMAStates, pmBlock, pmDate, pmPostalCode, pmStreet, pmUnit]);

  const [validAddress, setValidAddress] = useState<AddressResult>({
    BLK_NO: null,
    ROAD_NAME: null,
    HAS_VALID_ADDRESS: false,
    HAS_ERRORED: false,
  });
  const [postalCode, setPostalCode] = useState<string>(
    hasEditedPMA && PMAStates
      ? PMAStates.PreferredPostalCode ?? ""
      : pmPostalCode ?? "",
  );
  const [street, setStreet] = useState<string>(
    hasEditedPMA && PMAStates
      ? PMAStates.PreferredStreetName ?? ""
      : pmStreet ?? "",
  );
  const [block, setBlock] = useState<string>(
    hasEditedPMA && PMAStates
      ? PMAStates.PreferredBlockNumber ?? ""
      : pmBlock ?? "",
  );
  const [unit, setUnit] = useState<string>(
    hasEditedPMA && PMAStates
      ? PMAStates.PreferredCombinedUnitNumber?.replace("#", "") ?? ""
      : pmUnit?.replace("#", "") ?? "",
  );
  const [date, setDate] = useState<string | null>(
    hasEditedPMA && PMAStates
      ? PMAStates.MailingAddressExpiryDate ?? ""
      : pmDate ?? "",
  );

  const [blockErrorText, setBlockErrorText] = useState("");
  const [streetErrorText, setStreetErrorText] = useState("");
  const checkErrors = useAppSelector(selectPMGeneralErrors);

  const handleGetAddressClick = () => {
    if (myInfoProfilePage) {
      logEventToGoogleAnalytics(
        EVENTS.SELECT_PM_VERIFY_PARTICULARS_GET_ADDRESS,
      );
    }

    if (postalCode !== "" && postalCode !== null) {
      setStreet(validAddress.ROAD_NAME ?? "");
      setBlock(validAddress.BLK_NO ?? "");
    } else {
    }
  };

  const handleUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    const regex = /^[a-zA-Z0-9-]*$/;
    if (!regex.test(value)) {
      return;
    } else {
      setUnit(value);
    }
  };

  const isEmptyPostalCode = () => {
    return hasPostalCodeErrored || !postalCode;
  };

  const disableNext = useMemo(() => {
    if (
      checkErrors.hasPostalCodeErrored ||
      checkErrors.hasBlockErrored ||
      checkErrors.hasStreetErrored ||
      checkErrors.hasDateErrored
    )
      return true;
    return false;
  }, [checkErrors]);

  const onClickSave = () => {
    dispatch(
      setEpicProfileTempState({
        PreferredPostalCode: postalCode,
        PreferredStreetName: street,
        PreferredBlockNumber: block,
        PreferredCombinedUnitNumber: unit ? "#" + unit.trim() : "",
        PreferredFloorNumber: unit.split("-")[0].trim().replaceAll("#", ""),
        PreferredUnitNumber: unit.split("-")[1]?.trim() ?? "",
        MailingAddressExpiryDate: date ?? null,
      }),
    );
    dispatch(setBypassEpicProfile(true));
    navigate(-1);
  };

  const onClickCancel = () => {
    dispatch(setBypassEpicProfile(true));
    navigate(-1);
  };

  useEffect(() => {
    if (
      messageActionObj?.action === "backTriggered" ||
      preventDirectBackNavigationState
    ) {
      //this is for the reset of back button
      dispatch(setCustomisedBack(false));
      consumeMessageActionObj();
      dispatch(setBypassEpicProfile(true));
      navigate(-1);
    }
  }, [
    dispatch,
    navigate,
    preventDirectBackNavigationState,
    consumeMessageActionObj,
    messageActionObj?.action,
  ]);

  // Postal Code validation
  useEffect(() => {
    const validatePostalCode = async () => {
      if (postalCode !== "" && postalCode) {
        if (postalCode.length === 6) {
          const addrPayload = await getAddressByPostalCode(postalCode);
          if (addrPayload.HAS_VALID_ADDRESS && !addrPayload.HAS_ERRORED) {
            dispatch(setPreferredAddPostalCodeError(false));
            setValidAddress(addrPayload);
          } else {
            setStreet("");
            setBlock("");
            setUnit("");
            setDate("");
            dispatch(setPreferredAddPostalCodeError(true));
          }
        } else {
          setStreet("");
          setBlock("");
          setUnit("");
          setDate("");
          dispatch(setPreferredAddPostalCodeError(true));
        }
      } else {
        setStreet("");
        setBlock("");
        setUnit("");
        setDate("");
        dispatch(setPreferredAddPostalCodeError(false));
      }
    };

    validatePostalCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalCode]);

  //Block validation
  useEffect(() => {
    if (postalCode && postalCode !== "" && !hasPostalCodeErrored) {
      //there is postal code and it is not errored
      if (block === null || block === "") {
        //if block has nothing
        dispatch(setPreferredBlockError(true));
        setBlockErrorText("*Required");
      } else {
        //if block has something
        dispatch(setPreferredBlockError(false));
      }
    } else {
      //if postal code has errored
      dispatch(setPreferredBlockError(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPostalCodeErrored, block, postalCode]);

  //Street validation
  useEffect(() => {
    if (postalCode && postalCode !== "" && !hasPostalCodeErrored) {
      //there is postal code and it is not errored
      if (street === null || street === "") {
        //if street has nothing
        dispatch(setPreferredStreetError(true));
        setStreetErrorText("*Required");
      } else {
        //if street has something
        dispatch(setPreferredStreetError(false));
      }
    } else {
      //if postal code has errored
      dispatch(setPreferredStreetError(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPostalCodeErrored, street, postalCode]);

  // From date validation: should not allow after 31/12/2100
  const prefMailingAddrExpDate = formatDateToISO(new Date("2100-12-31"));

  return (
    <Box sx={classes.mainContainer}>
      <Box sx={{ flex: 1 }}>
        <Box sx={classes.postalCodeContainer}>
          <Box sx={classes.postalCodeInputContainer}>
            <SingleLineTextField
              placeholder="Postal Code"
              required={false}
              maxCharLength={6}
              type="number"
              value={postalCode ?? ""}
              error={hasPostalCodeErrored}
              errorText={
                postalCode === "" || postalCode === null
                  ? "*Required"
                  : "Invalid postal code. Please enter a valid postal code."
              }
              isFromMyInfoAndProfile={true}
              handleChange={(event) => {
                setPostalCode(event.target.value);
              }}
            />
          </Box>
          <Box sx={classes.getAddressButton}>
            <Button
              disabled={isEmptyPostalCode()}
              variant="outlined"
              color="primary"
              fullWidth={true}
              onClick={handleGetAddressClick}
            >
              Get address
            </Button>
          </Box>
        </Box>
        <Box sx={classes.streetContainer}>
          <SingleLineTextField
            type="text"
            value={street ?? ""}
            disabled
            required={false}
            error={hasStreetErrored}
            errorText={streetErrorText}
            placeholder="Street"
            maxCharLength={50}
            isFromMyInfoAndProfile={true}
            handleChange={(event) => setStreet(event.target.value)}
          />
        </Box>
        <Box sx={classes.blockUnitMainContainer}>
          <Box sx={classes.blockUnitContainer}>
            <SingleLineTextField
              type="text"
              value={block ?? ""}
              disabled
              required={false}
              error={hasBlockErrored}
              errorText={blockErrorText}
              placeholder="No. / Blk"
              maxCharLength={10}
              isFromMyInfoAndProfile={true}
              handleChange={(event) => setBlock(event.target.value)}
            />
          </Box>
          <Box sx={classes.blockUnitContainer}>
            <SingleLineTextField
              type="text"
              value={unit ?? ""}
              disabled={
                !postalCode || postalCode.length !== 6 || hasPostalCodeErrored
              }
              required={false}
              placeholder="Unit"
              maxCharLength={7}
              handleChange={(event) =>
                handleUnitChange(event as React.ChangeEvent<HTMLInputElement>)
              }
            />
          </Box>
        </Box>

        <Box sx={classes.datePickerBox}>
          <Box sx={classes.datepickerContainer}>
            <Typography sx={classes.dateDisclaimer}>
              {disclaimers.preferredDateDisclaimer.description}
            </Typography>
            <Datepicker
              key={date ?? ""}
              isClearEnabled={date ? true : false}
              showClearButton={true}
              isCheckingInitialValue={true}
              label={"Valid Till"}
              disablePast={true}
              value={
                !postalCode ||
                postalCode.length !== 6 ||
                hasPostalCodeErrored ||
                !date
                  ? null
                  : getDateFromISOString(date)
              }
              onSelect={(date) => {
                setDate(date ? formatDateToISO(date) : null);
              }}
              disabled={
                !postalCode || postalCode.length !== 6 || hasPostalCodeErrored
              }
              isKeyboardDatePicker={false}
              required={false}
              format={"d MMM yyyy"}
              placeholder={"D/M/YYYY"}
              onInputError={() => dispatch(setPreferredDateError(true))}
              maxDate={prefMailingAddrExpDate}
              isChangingInitialValue={true}
              onClearDate={() => setDate(null)}
              shouldRespectLeadingZeros={true}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={classes.saveButton}>
        <ButtonsFooter
          nextButtonText={"Confirm"}
          cancelButtonText={"Cancel"}
          isDisabled={disableNext}
          onClickNext={onClickSave}
          onClickCancel={onClickCancel}
        />
      </Box>
    </Box>
  );
};

export default PreferredMailingAddPage;
