import React from "react";
import ConfirmationModal from "../ConfirmationModal";
import { UserConsentModalProps } from "./UserConsentModal.types";

const UserConsentModal = ({ open, onClickNext }: UserConsentModalProps) => {
  return (
    <ConfirmationModal
      title="Caregiver Access"
      body="Your care giver did not grant you consent to view this health record type"
      nextButtonText="OK"
      hideCancelButton={true}
      open={open}
      onClickNext={onClickNext}
      onClose={onClickNext}
    />
  );
};

export default UserConsentModal;
