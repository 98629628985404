import React, { useState } from "react";
import { sxStyles } from "./FilterTransaction.styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { FilterTransactionProps } from "./FilterTransaction.types";
import { Box, Button, Typography } from "@mui/material";
import Datepicker from "lib/components/formInputs/Datepicker/Datepicker";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import CommonRadioButton from "lib/components/formInputs/CommonRadioButton/CommonRadioButton";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import IMAGES from "lib/assets/images";

const monthOptions = [
  {
    id: 3,
    text: "Last 3 months",
  },

  {
    id: 6,
    text: "Last 6 months",
  },
  {
    id: 12,
    text: "Last 12 months",
  },
];

const FilterTransaction = ({
  open,
  startDate,
  endDate,
  selectedMonthOption,
  onClose,
  onClickCancel,
  onSelectApplyFilter,
  onChangeStartDate,
  onChangeEndDate,
  onChangeMonthOption,
}: FilterTransactionProps) => {
  const classes = sxStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const disableFilter = () => {
    if (
      localSelectedMonthOption ||
      (localDatePickerStartDate && localDatePickerEndDate)
    ) {
      return false;
    } else {
      return true;
    }
  };

  // From date validation: should not allow more than 1 years back
  const now = new Date();
  const twoYearBeforeDay = formatDateToISO(
    new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()),
  );

  // log page to GA on mount
  React.useEffect(() => {
    logEventToGoogleAnalytics(EVENTS.VIEW_PAYMENT_TRANSACTION_FILTER);
  }, []);

  const [localSelectedMonthOption, setLocalSelectedMonthOption] = useState<
    number | null
  >(selectedMonthOption);
  const [localMonthOptionStartDate, setLocalMonthOptionStartDate] = useState<
    string | null
  >(startDate);
  const [localMonthOptionEndDate, setLocalMonthOptionEndDate] = useState<
    string | null
  >(endDate);

  const [localDatePickerStartDate, setLocalDatePickerStartDate] = useState<
    string | null
  >(startDate);
  const [localDatePickerEndDate, setLocalDatePickerEndDate] = useState<
    string | null
  >(endDate);

  // clear all local changes
  const onClear = () => {
    setLocalSelectedMonthOption(null);
    setLocalMonthOptionStartDate(null);
    setLocalMonthOptionEndDate(null);
    setLocalDatePickerStartDate(null);
    setLocalDatePickerEndDate(null);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => {
          onClear();
          onClose();
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <>
          <IconButton
            aria-label="close"
            sx={classes.closeButton}
            onClick={() => {
              onClear();
              onClose();
            }}
          >
            <Box component={"img"} src={IMAGES.general.GeneralCloseIcon} />
          </IconButton>
          <DialogTitle id="responsive-dialog-title" sx={classes.title}>
            {"Filter Transactions"}
          </DialogTitle>
          <DialogContent>
            <Box py={2}>
              <Typography sx={classes.title}>Select Period From</Typography>
              <CommonRadioButton
                radioButtonSelectionList={monthOptions}
                selectedRadioButton={String(localSelectedMonthOption)}
                handleRadioButtonChange={(id) => {
                  let startingDate: string | null = null;
                  let endingDate: string | null = null;
                  var d = new Date();

                  d.setMonth(d.getMonth() - Number(id));
                  startingDate = d.toISOString();

                  let today = new Date();
                  endingDate = today.toISOString();

                  setLocalSelectedMonthOption(Number(id));
                  setLocalMonthOptionStartDate(startingDate);
                  setLocalMonthOptionEndDate(endingDate);
                  setLocalDatePickerStartDate(null);
                  setLocalDatePickerEndDate(null);
                }}
              ></CommonRadioButton>
            </Box>
            <DialogContentText sx={classes.cardContent}>Or</DialogContentText>
            <Box py={2}>
              <Typography sx={classes.title}>
                Select a Date Range From
              </Typography>
              <Box mt={3} mb={2} sx={classes.textBox}>
                <Datepicker
                  label="From Date"
                  value={getDateFromISOString(localDatePickerStartDate)}
                  format="d MMM yyyy"
                  onSelect={(startDate) => {
                    setLocalDatePickerStartDate(formatDateToISO(startDate));
                    setLocalSelectedMonthOption(null);
                  }}
                  disableFuture
                  required={false}
                  minDate={twoYearBeforeDay}
                  maxDate={localDatePickerEndDate}
                ></Datepicker>
              </Box>
              <Box mt={3} mb={2} sx={classes.textBox}>
                <Datepicker
                  label="To Date"
                  value={getDateFromISOString(localDatePickerEndDate)}
                  format="d MMM yyyy"
                  onSelect={(endDate) => {
                    setLocalDatePickerEndDate(formatDateToISO(endDate));
                    setLocalSelectedMonthOption(null);
                  }}
                  disableFuture
                  required={false}
                  minDate={localDatePickerStartDate}
                ></Datepicker>
              </Box>
            </Box>
            <DialogActions sx={classes.buttonWrapper}>
              <Box sx={classes.cardButtons}>
                <Button
                  sx={classes.fullButton}
                  variant="contained"
                  color="primary"
                  disabled={disableFilter()}
                  onClick={() => {
                    onChangeMonthOption(localSelectedMonthOption);
                    if (localSelectedMonthOption) {
                      onChangeStartDate(localMonthOptionStartDate);
                      onChangeEndDate(localMonthOptionEndDate);
                    } else {
                      onChangeStartDate(localDatePickerStartDate);
                      onChangeEndDate(localDatePickerEndDate);
                    }
                    onSelectApplyFilter();
                    onClear();
                  }}
                >
                  Apply filter
                </Button>
              </Box>
              <Box sx={classes.cancelButtons}>
                <Button
                  sx={classes.cancelButton}
                  variant="outlined"
                  color="primary"
                  fullWidth={true}
                  onClick={() => {
                    logEventToGoogleAnalytics(
                      EVENTS.CANCEL_FILTER_TRANSACTION_HISTORY,
                    );
                    if (onClickCancel) {
                      onClear();
                      onClickCancel();
                    }
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </DialogActions>
          </DialogContent>
        </>
      </Dialog>
    </>
  );
};

export default FilterTransaction;
