import React from "react";
import { Box, Typography } from "@mui/material";
import { sxStyles } from "./PendingDisclaimer.styles";
import Tags from "lib/components/tags/Tags";

export const PENDING_DISCLAIMER_TEXT = ""; // requirement as of 13th Aug: Only show the pending tag, no other text

const PendingDisclaimer = () => {
  const classes = sxStyles();

  return (
    <Box sx={classes.cardTag} title="pending-disclaimer">
      <Tags label={"pending"} colorChoice={"lightOrange"} labelSize={9}></Tags>
      <Typography sx={classes.cardTagText}>
        {PENDING_DISCLAIMER_TEXT}
      </Typography>
    </Box>
  );
};

export default PendingDisclaimer;
