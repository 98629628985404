import React from "react";
import { Box } from "@mui/material";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { DisclaimerTextProps } from "./DisclaimerText.types";
import { sxStyles } from "./DisclaimerText.styles";

const DisclaimerText = ({ htmlText }: DisclaimerTextProps) => {
  const classes = sxStyles();

  if (htmlText) {
    return (
      <Box sx={classes.infoText}>{MapRawStringToReactElement(htmlText)}</Box>
    );
  } else {
    return <></>;
  }
};

export default DisclaimerText;
