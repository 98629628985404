import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { RESCHEDULE_APPOINTMENT_URL } from "../../resources/configs/URL";
import { formatRescheduleAppointmentErrorMessage } from "../../resources/messages/ErrorMessage";
import { RescheduleAppointmentPayload } from "./rescheduleAppointment.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";
import { RescheduleReq } from "ui/appointment/components/rescheduleAppointment/pages/confirmation/ConfirmationPage/ConfirmationPage.types";

/**
 * Reschedules an appointment
 *
 * POST
 * Endpoint: /RescheduleAppointment
 *
 * @param {string | null} memberIdentifier  Member Identifier of the person we are making this request for
 * @param {string | null} existingAppointmentId  ID of the appointment to re-schedule
 * @param {string | null} slotDate  ISO formatted DateTime (e.g. "2020-06-20T11:16:56") of the available appointment slot
 * @param {string | null} extensionData  Extension Data, that serves as metadata required to be passed this endpoint
 * @param {string | null} targetSystem  Source system from which these slots are from
 * @param {string | null} institutionCode  Code of the institution of the appointment to reschedule
 * @param {string | null} apptSessionId  NGEMR Appointment Session ID
 * @param {string | null} slotClinicianCode  NGEMR chosen available slot's clinician code
 * @param {string | null} departmentName Department Name of the appointment
 *
 * @returns {RescheduleAppointmentPayload} Response payload from the remote API
 */
const rescheduleAppointment = async ({
  memberIdentifier,
  existingAppointmentId,
  rescheduledSlotDateTime,
  institutionCode,
  rescheduleSlotId,
  visitTypeId,
  isHsgAppt
}: RescheduleReq): Promise<RescheduleAppointmentPayload> => {
  try {
    const response = await axios.post<RescheduleAppointmentPayload>(
      RESCHEDULE_APPOINTMENT_URL(),
      createRequestPayload({
        memberIdentifier,
        existingAppointmentId,
        rescheduledSlotDateTime,
        institutionCode,
        rescheduleSlotId,
        visitTypeId,
        isHsgAppt
      }),
      getRequestOptions(),
    );
    return parseResponse(response, RESCHEDULE_APPOINTMENT_URL());
  } catch (error) {
    const msg = formatRescheduleAppointmentErrorMessage(
      RESCHEDULE_APPOINTMENT_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = ({
  memberIdentifier,
  existingAppointmentId,
  rescheduledSlotDateTime,
  institutionCode,
  rescheduleSlotId,
  visitTypeId,
  isHsgAppt,
}: RescheduleReq) => {
  return {
    MemberIdentifier: memberIdentifier,
    ExistingAppointmentId: existingAppointmentId,
    SlotDate: rescheduledSlotDateTime,
    InstitutionCode: institutionCode,
    SlotId: rescheduleSlotId,
    IsHSG: isHsgAppt,
    VisitTypeId: visitTypeId,
  };
};

export { rescheduleAppointment };
