import { useCallback, useEffect } from "react";
import Page from "lib/components/layout/Page/Page";
import { Box, Typography, Button } from "@mui/material";
import { sxStyles } from "./Dashboard.styles";
import PaymentFooterLinksContainer from "../common/PaymentFooterLinks/PaymentFooterLinksContainer";
import ProfileHeaderContainer from "../common/ProfileHeader/ProfileHeaderContainer";
import IMAGES from "lib/assets/images";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import { DashboardProps } from "./Dashboard.types";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import PullToRefreshWrapper from "lib/components/pullToRefreshWrapper/PullToRefreshWrapper";
import AnnouncementsPanelSkeleton from "lib/components/notice/AnnouncementPanel/AnnouncementPanelSkeleton";
import BillCardSkeleton from "./BillCard/BillCardSkeleton/BillCardSkeleton";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import toTitleCase from "lib/util/StringUtil/toTitleCase/toTitleCase";
import { useAppDispatch } from "lib/redux/hooks";
import BillCard from "./BillCard/BillCard";
import {
  setSelectedAddedBillsIndex,
  setShouldUpdate,
  setSelectedOutstandingBillsIndex,
} from "ui/payment/ducks";
import { useGetMessageAction } from "lib/routing/messageChannel/hooks/useGetMessageAction";

const DEFAULT_NUMBER_OF_REPORT_SUMMARIES_TO_SHOW = 2;
const OUTSTANDING_BILL_TITLE = "Latest Outstanding Bills";
const OTHER_ADDED_BILL_TITLE = "Added Bills";

const Dashboard = ({
  shouldUpdateOutstandingBills,
  isLoadingOutstandingBills,
  hasErroredOutstandingBills,
  errorMessageOutstandingBills,
  allOutstandingBills,
  expandedOutstandingBillsListFlag,
  otherAddedBillList,
  isLoadingDisclaimer,
  disclaimer,
  onLoad,
  setExpandedOutstandingBillsListFlag,
  callGetDocumentByType,
  callGetOutstandingBills,
  onResetShouldUpdate,
}: DashboardProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();
  const dispatch = useAppDispatch();

  const numberToShow = expandedOutstandingBillsListFlag
    ? allOutstandingBills.length
    : DEFAULT_NUMBER_OF_REPORT_SUMMARIES_TO_SHOW;

  const { messageActionObj, consumeMessageActionObj } = useGetMessageAction();

  const refreshData = useCallback(() => {
    onLoad();
    logEventToGoogleAnalytics(EVENTS.VIEW_PAYMENT_LANDING);
    // prevent reloading when back from bill details page
    if (shouldUpdateOutstandingBills) {
      callGetDocumentByType();
      callGetOutstandingBills();
    }
  }, [
    callGetDocumentByType,
    callGetOutstandingBills,
    onLoad,
    shouldUpdateOutstandingBills,
  ]);

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetOutstandingBills]);

  // Mobile will send a message to force refresh when user switches modules from bottom tab
  useEffect(() => {
    if (messageActionObj?.action === "refreshLanding") {
      refreshData();
      consumeMessageActionObj();
    }
  }, [consumeMessageActionObj, messageActionObj?.action, refreshData]);

  return (
    <Page>
      <PullToRefreshWrapper
        refreshData={() => {
          onResetShouldUpdate();
          refreshData();
        }}
      >
        <ProfileHeaderContainer title="'s Bills" />

        {isLoadingDisclaimer ? (
          <AnnouncementsPanelSkeleton />
        ) : disclaimer ? (
          <Box m={2}>
            <SharpNoticePanel children={disclaimer} />
          </Box>
        ) : null}

        {isLoadingOutstandingBills ? (
          <BillCardSkeleton />
        ) : hasErroredOutstandingBills ? (
          <ErrorDisplayContainer
            errorMessage={errorMessageOutstandingBills}
            onTryAgain={() => {
              callGetOutstandingBills();
            }}
          />
        ) : (
          <>
            <Box m={2}>
              {allOutstandingBills && allOutstandingBills.length > 0 ? (
                <>
                  <Typography sx={classes.title}>
                    {toTitleCase(OUTSTANDING_BILL_TITLE)}
                  </Typography>

                  {allOutstandingBills
                    .slice(0, numberToShow)
                    .map((bill, index) => {
                      return (
                        <BillCard
                          key={bill.InvoiceNumber}
                          addedBill={null}
                          outstandingBill={bill}
                          onClickBill={function (
                            selectedBillReferenceNumber: string | null,
                          ): void {
                            dispatch(setShouldUpdate(false));
                            dispatch(
                              setSelectedOutstandingBillsIndex(
                                selectedBillReferenceNumber,
                              ),
                            );
                          }}
                        />
                      );
                    })}
                </>
              ) : (
                allOutstandingBills.length === 0 &&
                otherAddedBillList.length === 0 && (
                  <Box align-items="center" justifyContent="center" mt={0}>
                    <Box sx={classes.noRecordBox}>
                      <Box sx={classes.noRecordImage}>
                        <img
                          src={IMAGES.payment.NoOutstandingBills}
                          alt="No Outstanding Bills"
                        />
                      </Box>
                      <Box sx={classes.noRecordTitle}>
                        You have no outstanding bills.
                      </Box>
                    </Box>
                  </Box>
                )
              )}

              {!expandedOutstandingBillsListFlag &&
                allOutstandingBills.length >
                  DEFAULT_NUMBER_OF_REPORT_SUMMARIES_TO_SHOW && (
                  <Box sx={classes.viewPastRequestTextBox}>
                    <Box
                      sx={classes.viewAllOutStandingContainer}
                      onClick={() => {
                        setExpandedOutstandingBillsListFlag(true);
                      }}
                    >
                      <Typography sx={classes.viewOutStandingText}>
                        View all outstanding bills
                      </Typography>

                      <Box
                        sx={classes.arrowRightIcon}
                        src={IMAGES.general.ArrowRightIcon}
                        alt="View all outstanding bills"
                        component="img"
                      />
                    </Box>
                  </Box>
                )}
            </Box>

            {otherAddedBillList && otherAddedBillList.length > 0 ? (
              <Box m={2}>
                <Typography sx={classes.title}>
                  {OTHER_ADDED_BILL_TITLE}
                </Typography>

                {otherAddedBillList.map((bill, index) => {
                  return (
                    <BillCard
                      key={bill.InvoiceNumber}
                      addedBill={bill}
                      outstandingBill={null}
                      onClickBill={function (
                        selectedBillReferenceNumber: string | null,
                      ): void {
                        dispatch(setShouldUpdate(false));
                        dispatch(
                          setSelectedAddedBillsIndex(bill.InvoiceNumber),
                        );
                      }}
                    />
                  );
                })}
              </Box>
            ) : null}
            {(allOutstandingBills.length !== 0 ||
              otherAddedBillList.length !== 0) && (
              <Box sx={classes.cardButtons}>
                <Button
                  variant="contained"
                  sx={classes.fullButton}
                  onClick={() => {
                    logEventToGoogleAnalytics(
                      EVENTS.SELECT_MAKE_PAYMENT_LANDING,
                    );
                    navigate(PATHS.PAYMENT_BILL_SUMMARY.path);
                  }}
                  title="button"
                >
                  Make payment
                </Button>
              </Box>
            )}
          </>
        )}

        <PaymentFooterLinksContainer />
      </PullToRefreshWrapper>
    </Page>
  );
};

export default Dashboard;
