import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    checkboxContainer: {
      marginBottom: theme.spacing(2),
    },
    checkBoxLabel: {
      fontWeight: theme.typography.fontWeightBold,
    },
  } satisfies SxObjType;
};
export { sxStyles };
