import { Box, Card, CardHeader, CardContent, IconButton } from "@mui/material";
import { MedicationCardProps } from "./MedicationCard.types";
import { sxStyles } from "./MedicationCard.styles";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import ImageUploader from "lib/components/upload/ImageUploader/ImageUploader";
import SimpleAutocomplete from "ui/medrefill/components/common/SimpleAutoComplete/SimpleAutoComplete";
import Dropdown from "lib/components/formInputs/Dropdown/Dropdown";
import GroupedDropdown from "lib/components/formInputs/GroupedDropdown/GroupedDropdown";
import IMAGES from "lib/assets/images";

const MedicationCard = ({
  index,
  title,
  medicationCardData,
  medicationName,
  hideDeleteIcon = false,
  durationList,
  uomList,
  medicationList,
  setMedicationCardData,
  setMedicationName,
  onClickDeleteMedicationCard,
  onImageProcessing,
  disabled = false,
}: MedicationCardProps) => {
  const classes = sxStyles();

  return (
    <Card sx={classes.card}>
      <CardHeader
        p={0}
        subheader={title}
        action={
          hideDeleteIcon ? null : (
            <IconButton
              aria-label="delete"
              sx={classes.deleteIcon}
              disabled={disabled}
              onClick={() => onClickDeleteMedicationCard(index)}
            >
              <img src={IMAGES.general.DeleteIcon} alt="Delete" />
            </IconButton>
          )
        }
        sx={classes.cardHeader}
      />
      <CardContent sx={classes.mediaContent}>
        <Box sx={classes.media}>
          <ImageUploader
            defaultFileData={medicationCardData.fileData}
            overlayText="Medication Image"
            onImageProcessing={(loading) => {
              onImageProcessing(loading);
            }}
            onFileUploadSuccess={(fileName, fileData) => {
              setMedicationCardData({
                ...medicationCardData,
                fileName,
                fileData,
              });
            }}
            onClearFileData={() => {
              setMedicationCardData({
                ...medicationCardData,
                fileName: null,
                fileData: null,
              });
            }}
            disabled={disabled}
            index={0}
            nestedIndex={0}
          />
        </Box>
        <Box sx={classes.textBox}>
          <SimpleAutocomplete
            label="Medication Name"
            maxCharLength={60}
            items={medicationList}
            value={medicationCardData.medicationName}
            inputValue={medicationName}
            onSelect={(medicationName) => {
              setMedicationCardData(
                {
                  ...medicationCardData,
                  medicationName,
                },
                true,
              );
            }}
            onInputChange={(medicationName) => {
              setMedicationName(medicationName);
            }}
            disabled={disabled}
            required={false}
          />
        </Box>
        <Box sx={classes.textBoxes}>
          <Box sx={classes.qtyBox}>
            <SingleLineTextField
              type="number"
              value={
                medicationCardData.quantity === null ||
                medicationCardData.quantity === undefined
                  ? null
                  : medicationCardData.quantity.toString()
              }
              placeholder="Quantity"
              handleChange={(event) => {
                setMedicationCardData({
                  ...medicationCardData,
                  quantity: parseInt(event.target.value) || null,
                });
              }}
              error={medicationCardData.quantityValidationMsg !== null}
              errorText={medicationCardData.quantityValidationMsg || ""}
              disabled={disabled}
              required={false}
              maxCharLength={4}
            ></SingleLineTextField>
          </Box>
          <Box sx={classes.selectBox}>
            {uomList.length !== 0 ? (
              <GroupedDropdown
                label="Duration/Unit of Measure"
                listOfItems={[durationList, uomList]}
                categories={["Duration", "Unit of Order"]}
                value={medicationCardData.UOMId}
                handleChange={(event) => {
                  setMedicationCardData({
                    ...medicationCardData,
                    UOMId: event.target.value,
                  });
                }}
                disabled={disabled}
                required={false}
              />
            ) : (
              <Dropdown
                label="Duration"
                value={medicationCardData.UOMId}
                items={durationList}
                handleChange={(event) => {
                  setMedicationCardData({
                    ...medicationCardData,
                    UOMId: event.target.value,
                  });
                }}
                disabled={disabled}
                required={false}
              ></Dropdown>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MedicationCard;
