import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { GET_BARCODE_IMAGE_URL } from "../../resources/configs/URL";
import { formatGetBarCodeImageErrorMessage } from "../../resources/messages/ErrorMessage";
import { GetBarCodeImagePayload } from "./getBarCodeImage.fromApi.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";

/**
 * Retrieves the user NRIC's corresponding barcode image (in base64 byte data that can
 * be rendered on the UI).
 *
 * POST
 * Endpoint: /data/GetBarCodeImage
 *
 * @param {string | null} memberIdentifier  Member Identifier of the person we are making this request for
 *
 * @returns {GetBarCodeImagePayload} Response payload from the remote API
 */
const getBarCodeImage = async (
  memberIdentifier: string | null,
): Promise<GetBarCodeImagePayload> => {
  try {
    const response = await axios.post<GetBarCodeImagePayload>(
      GET_BARCODE_IMAGE_URL(),
      createRequestPayload(memberIdentifier),
      getRequestOptions(),
    );
    return parseResponse(response, GET_BARCODE_IMAGE_URL());
  } catch (error) {
    const msg = formatGetBarCodeImageErrorMessage(GET_BARCODE_IMAGE_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (memberIdentifier: string | null) => {
  return {
    MemberIdentifier: memberIdentifier,
  };
};

export { getBarCodeImage };
