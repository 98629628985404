import { Box, Typography, useTheme } from "@mui/material";
import IMAGES from "lib/assets/images";
import { useState } from "react";
import { sxStyles } from "./LinkedAppointmentHeader.styles";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";

const LinkedAppointmentHeader = () => {
  const theme = useTheme();
  const classes = sxStyles(theme);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={classes.linkedHeader}>
      <Typography sx={classes.linkedTitle}>Linked Appointments</Typography>
      <Box
        component={"img"}
        aria-label="help"
        src={IMAGES.general.HelpOutline}
        sx={classes.helpIcon}
        onClick={handleClickOpen}
      />
      <ConfirmationModal
        title={"What are Linked Appointments?"}
        body={
          "These are related appointments which your care provider has planned for your next visit."
        }
        open={open}
        hideNextButton={true}
        hideCancelButton={false}
        onClickCancel={handleClose}
        cancelButtonText={"Close"}
      />
    </Box>
  );
};

export default LinkedAppointmentHeader;
