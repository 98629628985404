import React from "react";
import { Box, Snackbar, Typography, Slide, SxProps } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { ToastSnackbarProps, Icon } from "./ToastSnackbar.types";
import { sxStyles } from "./ToastSnackbar.styles";
import { TransitionProps } from "@mui/material/transitions";
import IMAGES from "lib/assets/images";

const HIDE_DURATION = 3000; // No. of (ms) to wait before onClose is called
const TRANSITION_DURATION = 250; // No. of (ms) a transition lasts, e.g. entering / exiting

const ToastSnackbar = ({
  open,
  icon,
  message,
  onClose,
}: ToastSnackbarProps) => {
  const classes = sxStyles();

  return (
    <Snackbar
      data-testid="snackbar"
      sx={classes.barRoot}
      open={open}
      onClose={onClose}
      autoHideDuration={HIDE_DURATION}
      transitionDuration={TRANSITION_DURATION}
      TransitionComponent={Transition}
      message={
        <Box sx={classes.contentMessage} display="flex">
          {getIcon(icon, classes.icon)}
          <Typography variant="h5" sx={{ fontSize: "1.5rem" }}>
            {message}
          </Typography>
        </Box>
      }
    />
  );
};

const getIcon = (icon: Icon | null, iconClassName: SxProps) => {
  if (icon === "calendar") {
    return (
      <CalendarTodayIcon sx={iconClassName} data-testid="calendar-today-icon" />
    );
  } else if (icon === "success") {
    return (
      <Box
        data-testid="check-circle-icon"
        component={"img"}
        src={IMAGES.general.CheckIcon}
        sx={iconClassName}
      />
    );
  } else {
    return null;
  }
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ToastSnackbar;
