import { mask } from "lib/util/StringUtil/mask/mask";

/**
 * Masks the NRIC.
 *
 * @param {string} nric  NRIC string value
 * @returns {string | null}  Masked NRIC string value
 */
export const maskNric = (nric: string | null) => {
  if (nric) {
    return mask(nric, "X", 1, 5);
  } else {
    return null;
  }
};
