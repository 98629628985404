import { useEffect } from "react";
import { Box } from "@mui/material";
import { sxStyles } from "./HMT.styles";
import mapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { useAppSelector } from "lib/redux/hooks";
import { CHAS_URL, NUHS_POSTALCODE } from "../../Dashboard/Constants";
import IconLink from "lib/components/links/IconLink/IconLink";
import { ACTIONS } from "lib/routing";
import {
  mobileNavigate,
  navigateToExternalInMobileBrowser,
  navigateToExternalInMobileBrowserForStandardPage,
} from "lib/routing/navigate/navigate";
import { selectMhm, selectMhmSystemSettings } from "ui/mhm/ducks/selectors";
import { selectNavigation } from "lib/redux/navigation/selectors";
import {
  screenForLifeBackUrl,
  screenForLifeUrl,
} from "lib/configs/configs/config";

const HMT = () => {
  const categoryCode = useAppSelector(selectMhm).selectedCategory.code;
  const categoryIntro = useAppSelector(selectMhmSystemSettings)
    .systemSettingMap[`CP${categoryCode}INTRO`].DisplayValue;
  const programName = useAppSelector(selectNavigation).navBarDisplayName;
  const classes = sxStyles({ categoryCode });

  useEffect(
    () =>
      logEventToGoogleAnalytics(
        EVENTS.VIEW_MHM_PROGRAM_TEMPLATE,
        programName?.toLocaleLowerCase().replace(" ", "_"),
        programName || "",
      ),
    [programName],
  );

  return (
    <Box sx={classes.contentBox}>
      <Box data-testid="cat-intro">
        {mapRawStringToReactElement(categoryIntro ?? "")}
      </Box>
      <Box sx={classes.linksBox}>
        {categoryCode === "PR_CAN" ? (
          <>
            <IconLink
              title={
                "Colorectal Screening - Find the nearest place to get your FIT kit."
              }
              onClick={() => {
                mobileNavigate(
                  ACTIONS.DOS_CLINICS_ACTION(NUHS_POSTALCODE, "ADD_COCS"),
                );
              }}
            />
            <IconLink
              title={
                "(For females) Breast Screening - Find the nearest place to get your mammogram."
              }
              onClick={() => {
                mobileNavigate(
                  ACTIONS.DOS_CLINICS_ACTION(NUHS_POSTALCODE, "ADD_BCS"),
                );
              }}
            />
            <IconLink
              title={
                "(For females) Cervical screening - Find the nearest clinic."
              }
              onClick={() => {
                mobileNavigate(
                  ACTIONS.DOS_CLINICS_ACTION(NUHS_POSTALCODE, "ADD_CCS"),
                );
              }}
            />
          </>
        ) : categoryCode === "PR_CDM" ? (
          <>
            <IconLink
              title={"Apply for CHAS subsidy."}
              onClick={() => {
                navigateToExternalInMobileBrowser(CHAS_URL);
              }}
            />
            <IconLink
              title={"Find a screening clinic near you."}
              onClick={() => {
                mobileNavigate(
                  ACTIONS.DOS_CLINICS_ACTION(NUHS_POSTALCODE, "PR_CDM"),
                );
              }}
            />
          </>
        ) : categoryCode === "PR_CHS" ? (
          <>
            <IconLink
              title={
                "Find out about Screen For Life, and check if you are eligible."
              }
              onClick={() => {
                navigateToExternalInMobileBrowserForStandardPage(
                  screenForLifeUrl(),
                  "hh",
                  screenForLifeBackUrl(),
                );
              }}
            />
            <IconLink
              title={"Find a screening clinic near you."}
              onClick={() => {
                // PR_CHS does not have a DOS weblink, it defaults ot PR_CDM
                mobileNavigate(
                  ACTIONS.DOS_CLINICS_ACTION(NUHS_POSTALCODE, "PR_CDM"),
                );
              }}
            />
          </>
        ) : categoryCode === "PR_VAX" ? (
          <>
            <IconLink
              title={"Vaccination - Find the nearest clinic."}
              onClick={() => {
                mobileNavigate(
                  ACTIONS.DOS_CLINICS_ACTION(NUHS_POSTALCODE, "PR_VAX"),
                );
              }}
            />
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default HMT;
