import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.text.primary,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
    },
    
  } satisfies SxObjType;
};

export { sxStyles };
