import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { GET_SERVICE_LIST_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { GetServiceListPayload } from "./getServiceList.fromApi.types";
import { formatGetServiceListErrorMessage } from "api/resources/messages/ErrorMessage";

/**
 * Fetches the services given by the specified clinic.
 *
 * POST
 * Endpoint: /appointment/GetServiceList
 *
 * @param {string} clinicCode Category of SystemSettings it belongs to
 * @param {string} targetSystem SubCategory of SystemSettings it belongs to
 */
const getServiceList = async (
  institutionCode: string | null,
): Promise<GetServiceListPayload> => {
  try {
    const response = await axios.post<GetServiceListPayload>(
      GET_SERVICE_LIST_URL(),
      createRequestPayload(institutionCode),
      getRequestOptions(),
    );
    return parseResponse(response, GET_SERVICE_LIST_URL());
  } catch (error) {
    const msg = formatGetServiceListErrorMessage(GET_SERVICE_LIST_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (institutionCode: string | null) => {
  return {
    InstitutionCode: institutionCode,
  };
};

export { getServiceList };
