import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.text.primary,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
    },
    
    formTitle: {
      color: theme.palette.text.primary,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.5,
      marginBottom: theme.spacing(1),
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.grey[100]}`,
      },
      "& .MuiCheckbox-colorPrimary": {
        padding: 0,
        color: theme.palette.primary.main,
        marginRight: "8px",
      },
      "& .MuiSvgIcon-root": {
        fontSize: size.FONTSIZE_24,
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
