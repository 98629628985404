import { useEffect, useState } from "react";
import { QuantityProps } from "./Quantity.types";
import StepPageLayoutContainer from "ui/medrefill/components/common/StepPageLayout/StepPageLayoutContainer";
import StepPageButtonsFooter from "ui/medrefill/components/common/StepPageButtonsFooter/StepPageButtonsFooter";
import QuantityBody from "./QuantityBody";
import { useNavigate } from "react-router-dom";
import { MedRefillQuantityDetail } from "ui/medrefill/ducks/medrefill.types";

const MedicationCardLimitNumber = 20;

const Quantity = ({
  isEditablePage = false,
  pathToNextStep,
  typeId,
  details,
  option3Temp,
  onSelectQuantity,
  durationList,
  uomList,
  medicationList,
  preventDirectBackNavigationState,
  resetPreventDirectBackNavigationState,
}: QuantityProps) => {
  const navigate = useNavigate();

  //QuantiyTypeId 1: All my balance
  //QuantiyTypeId 2: An amount for a selected duration
  //QuantiyTypeId 3: Selected medication only
  const [selectedQuantiyTypeId, setSelectedQuantiyTypeId] = useState<
    number | null
  >(typeId);

  //populate quantityDetails from redux only for 'Selected medication only' option
  const [quantityDetails, setQuantityDetails] = useState<
    MedRefillQuantityDetail[]
  >(selectedQuantiyTypeId === 3 ? details : []);

  //populate number from redux details for 'An amount for a selected duration' option
  //                from redux temp for 'Selected medication only' option
  const [number, setNumber] = useState<number | null>(
    selectedQuantiyTypeId === 2 && details.length !== 0
      ? details[0].number
      : selectedQuantiyTypeId === 3
      ? option3Temp.number
      : null,
  );

  //populate UOMDurationId from redux only for 'An amount for a selected duration' option
  // from redux temp for 'Selected medication only' option
  const [UOMDurationId, setUOMDurationId] = useState<number | null>(
    selectedQuantiyTypeId === 2 && details[0]
      ? details[0].UOMDurationId
      : selectedQuantiyTypeId === 3
      ? option3Temp.UOMId
      : null,
  );

  //populate UOMDuration from redux only for 'An amount for a selected duration' option
  //from redux temp for 'Selected medication only' option
  const [UOMDuration, setUOMDuration] = useState<string | null>(
    selectedQuantiyTypeId === 2 && details[0]
      ? details[0].UOMDuration
      : selectedQuantiyTypeId === 3
      ? option3Temp.UOM
      : null,
  );

  //for AutoComplete dropdown, the value selected by user
  const [prescriptionItemNo, setPrescriptionItemNo] = useState<string | null>(
    selectedQuantiyTypeId === 3 ? option3Temp.medicationName : null,
  );
  //for AutoComplete textfield, the value typed by user
  const [prescriptionItemNoFreeSolo, setPrescriptionItemNoFreesolo] = useState<
    string | null
  >(selectedQuantiyTypeId === 3 ? option3Temp.medicationName : null);

  const [invalidPrescriptionItemNoMsg, setInvalidPrescriptionItemNoMsg] =
    useState<string | null>(null);

  const [invalidNumberMsg, setInvalidNumberMsg] = useState<string | null>(
    selectedQuantiyTypeId === 2 && details[0]
      ? validateNumberAccordingToDuration(number, UOMDuration)
      : selectedQuantiyTypeId === 3
      ? validateNumberAccordingToDuration(number, UOMDuration)
      : null,
  );

  const [medicationCardNumberLimitMsg, setMedicationCardNumberLimitMsg] =
    useState<string | null>(null);

  const handleQuantityTypeIdChange = (selectedQuantiyTypeId: number | null) => {
    setQuantityDetails([]);
    setPrescriptionItemNo(null);
    setPrescriptionItemNoFreesolo(null);
    setNumber(null);
    setUOMDurationId(null);
    setUOMDuration(null);
    setInvalidPrescriptionItemNoMsg(null);
    setInvalidNumberMsg(null);
    setMedicationCardNumberLimitMsg(null);
    setSelectedQuantiyTypeId(selectedQuantiyTypeId);
  };

  //when a prescriptionItemNo is selected from AutoComplete list,
  //look up the corresponding UOM for the selected prescriptionItemNo and autoSelect the UOM in dropdown if exist
  const handlePrescriptionItemNoChange = (
    prescriptionItemNo: string | null,
  ) => {
    setPrescriptionItemNo(prescriptionItemNo);

    let index = medicationList
      .map((med) => med.MedicationName)
      .indexOf(prescriptionItemNo || "");
    if (index >= 0) {
      let selectedUomId = medicationList[index].UomId;

      const UOMDuration = durationList
        .concat(uomList)
        .find((x) => x.id === selectedUomId)?.text;

      if (UOMDuration) {
        setUOMDurationId(selectedUomId);
        setUOMDuration(UOMDuration);
      }
    }
  };

  const handlePrescriptionItemNoFreeSoloChange = (
    prescriptionItemNo: string | null,
  ) => {
    setPrescriptionItemNoFreesolo(prescriptionItemNo);
    if (
      quantityDetails.find(
        (detail) => detail.prescriptionItemNo === prescriptionItemNo,
      )
    ) {
      setInvalidPrescriptionItemNoMsg("Medication name is already in the list");
    } else {
      setInvalidPrescriptionItemNoMsg(null);
    }
  };

  const handleNumberChange = (number: number | null) => {
    setNumber(number);
    let UOMDuration =
      durationList.concat(uomList).find((x) => x.id === UOMDurationId)?.text ||
      null;
    setInvalidNumberMsg(validateNumberAccordingToDuration(number, UOMDuration));
  };

  const handleUOMDurationIdChange = (UOMDurationId: number | null) => {
    setUOMDurationId(UOMDurationId);
    let UOMDuration =
      durationList.concat(uomList).find((x) => x.id === UOMDurationId)?.text ||
      null;
    setUOMDuration(UOMDuration);
    setInvalidNumberMsg(validateNumberAccordingToDuration(number, UOMDuration));
  };

  //for 'An amount for a selected duration' option, add Quantity detail only when next button is clicked
  //for 'Selected medication only' option, add Quantity detail once 'Add medication' button is clicked
  const handleAddQuantityDetail = () => {
    setQuantityDetails(addQuantityDetail());
    setPrescriptionItemNo(null);
    setPrescriptionItemNoFreesolo(null);
    setNumber(null);
    setUOMDurationId(null);
    setUOMDuration(null);
  };

  const disableNext = () => {
    let disableNext = true;
    if (selectedQuantiyTypeId) {
      if (selectedQuantiyTypeId === 1) {
        disableNext = false;
      } else if (
        selectedQuantiyTypeId === 2 &&
        number &&
        UOMDurationId &&
        !invalidNumberMsg
      ) {
        disableNext = false;
      } else {
        if (selectedQuantiyTypeId === 3 && quantityDetails.length !== 0) {
          disableNext = false;
        }
      }
    }
    return disableNext;
  };

  const saveFormState = () => {
    if (selectedQuantiyTypeId === 2) {
      onSelectQuantity(selectedQuantiyTypeId, addQuantityDetail(), {
        medicationName: null,
        number: null,
        UOMId: null,
        UOM: null,
      });
    } else {
      onSelectQuantity(selectedQuantiyTypeId, quantityDetails, {
        medicationName: prescriptionItemNoFreeSolo ?? prescriptionItemNo,
        number: number,
        UOMId: UOMDurationId,
        UOM: UOMDuration,
      });
    }
  };
  // !exp Checks on load if current selected medication is already in the list
  // !exp this is needed when user returns from back and state is repopulated but onChange of Textfield is not triggered
  useEffect(() => {
    handlePrescriptionItemNoFreeSoloChange(prescriptionItemNo);

    // eslint-disable-next-line
  }, [prescriptionItemNo]);

  //save form state when back navigation
  useEffect(() => {
    if (preventDirectBackNavigationState) {
      saveFormState();
      resetPreventDirectBackNavigationState();
      navigate(-1);
    }
    // we only want this hook to fire on back navigation
    // eslint-disable-next-line
  }, [preventDirectBackNavigationState]);

  const addQuantityDetail = () => {
    let updated = [...quantityDetails];
    if (quantityDetails.length < MedicationCardLimitNumber) {
      updated.push({
        //consider user chooses an option and modify the option text, the modified text is chosen
        prescriptionItemNo: prescriptionItemNoFreeSolo ?? prescriptionItemNo,
        number,
        UOMDuration,
        UOMDurationId,
      });
      setPrescriptionItemNo(null);
      setInvalidPrescriptionItemNoMsg(null);
    } else {
      setMedicationCardNumberLimitMsg(
        "You have exceeded the limit of " +
          MedicationCardLimitNumber +
          " items",
      );
    }
    return updated;
  };

  const removeQuantityDetail = (
    index: number,
    medicationName: string | null,
  ) => {
    if (medicationName === prescriptionItemNoFreeSolo) {
      setInvalidPrescriptionItemNoMsg(null);
    }
    let updated = [...quantityDetails];
    updated.splice(index, 1);
    setQuantityDetails(updated);
    setMedicationCardNumberLimitMsg(null);
  };

  return (
    <StepPageLayoutContainer
      editableFlag={isEditablePage}
      isNextButtonDisabled={false}
      currentStepIndex={1}
      shouldShowButtonsFooter={false}
      pathToNextStep={pathToNextStep ?? ""}
      onNextStep={() => {}}
    >
      <QuantityBody
        durationChoice={durationList}
        uomChoice={uomList}
        medicationNameList={
          medicationList !== undefined
            ? medicationList.map((med) => med.MedicationName)
            : []
        }
        selectedQuantiyTypeId={selectedQuantiyTypeId}
        setSelectedQuantiyTypeId={handleQuantityTypeIdChange}
        number={number}
        setNumber={handleNumberChange}
        UOMDurationId={UOMDurationId}
        setUOMDurationId={handleUOMDurationIdChange}
        prescriptionItemNo={prescriptionItemNo}
        prescriptionItemNoFreeSolo={prescriptionItemNoFreeSolo}
        setPrescriptionItemNo={handlePrescriptionItemNoChange}
        setPrescriptionItemNoFreeSolo={handlePrescriptionItemNoFreeSoloChange}
        quantityDetails={quantityDetails}
        addQuantityDetail={handleAddQuantityDetail}
        removeQuantityDetail={removeQuantityDetail}
        prescriptionItemNoValidationMsg={invalidPrescriptionItemNoMsg}
        numberValidationMsg={invalidNumberMsg}
        medicationCardNumberLimitMsg={medicationCardNumberLimitMsg}
      />

      {/* NEXT PAGE CONTROLS */}
      <StepPageButtonsFooter
        type={isEditablePage ? "EDIT" : "NEXT_STEP"}
        isNextButtonDisabled={disableNext()}
        pathToNextStep={pathToNextStep}
        onNextStep={() => {
          saveFormState();
        }}
      />
    </StepPageLayoutContainer>
  );
};

export default Quantity;

const validateNumberAccordingToDuration = (
  number: number | null,
  UOMDuration: string | null,
) => {
  if (number === null) {
    return null;
  } else if (UOMDuration === "Day(s)" && number > 365) {
    return "Day(s) cannot be more than 365";
  } else if (UOMDuration === "Week(s)" && number > 52) {
    return "Week(s) cannot be more than 52";
  } else if (UOMDuration === "Month(s)" && number > 12) {
    return "Month(s) cannot be more than 12";
  } else if (number <= 0) {
    return "Invalid input";
  } else return null;
};
