const MAX_AMOUNT_PAYABLE_PER_TRANSACTION = 99999.99;
const MAX_DIGIT_OF_WHOLE_NUMBER_PART = 8;
const MAX_DIGIT_OF_FRACTIONAL_PART = 2;
const BILL_LIMIT_ERROR = "Amount cannot exceed the maximum amount";
const TRANSACTION_LIMIT_ERROR = "Payment limit per transaction is $ 99,999.99";

export {
  MAX_AMOUNT_PAYABLE_PER_TRANSACTION,
  MAX_DIGIT_OF_WHOLE_NUMBER_PART,
  MAX_DIGIT_OF_FRACTIONAL_PART,
  BILL_LIMIT_ERROR,
  TRANSACTION_LIMIT_ERROR,
};
