import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests/RequestOptions";
import { GET_PATIENTPROFILE_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { GetPatientProfilePayload } from "./getPatientProfile.fromApi.types";
import { formatGetPatientProfileErrorMessage } from "api/resources/messages/ErrorMessage";

/**
 * Fetches the User Profile for a user. This API is usually only used for the dependent flow.
 *
 * POST
 * Endpoint: /profile/GetPatientProfile
 *
 * @param {string | null} memberIdentifier  Member Identifier of the person we are making this request for
 *
 * @returns {GetPatientProfilePayload} Response payload from the remote API
 */
const getPatientProfile = async (
  memberIdentifier: string | null,
): Promise<GetPatientProfilePayload> => {
  try {
    const response = await axios.post<GetPatientProfilePayload>(
      GET_PATIENTPROFILE_URL(),
      createRequestPayload(memberIdentifier),
      getRequestOptions(),
    );
    return parseResponse(response, GET_PATIENTPROFILE_URL());
  } catch (error) {
    const msg = formatGetPatientProfileErrorMessage(GET_PATIENTPROFILE_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (memberIdentifier: string | null) => {
  return {
    MemberIdentifier: memberIdentifier,
  };
};

export { getPatientProfile };
