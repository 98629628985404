import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { getClinics } from "api/medrefill/GetClinics/getClinics";
import { getCollectionPaymentDetails } from "api/medrefill/GetCollectionPaymentDetails/getCollectionPaymentDetails";
import {
  Collection,
  Payment,
} from "api/medrefill/GetCollectionPaymentDetails/getCollectionPaymentDetails.fromApi.types";
import { getDocumentByCluster } from "api/medrefill/GetDocumentByCluster/getDocumentByCluster";
import { getMedicationImages } from "api/medrefill/GetMedicationImages/getMedicationImages";
import { getMedicationSummary } from "api/medrefill/GetMedicationSummary/getMedicationSummary";
import { PrescriptionRequestType } from "api/medrefill/GetMedicationSummary/getMedicationSummary.types";
import { getMedicationSummaryDetail } from "api/medrefill/GetMedicationSummaryDetail/getMedicationSummaryDetail";
import { getMedications } from "api/medrefill/GetMedications/getMedications";
import { getSystemSettings } from "api/shared/GetSystemSettings/getSystemSettings";
import { RootState } from "lib/redux/root/redux.types";
import { parseJson } from "lib/util/StringUtil/jsonParser/parseJson";
import { setShowPreventiveCare } from "ui/mhm/ducks/mhmSlice";
import {
  setClinicList,
  setClinicsErrorMessage,
  setClinicsHasErrored,
  setClinicsIsLoading,
  setCollectionList,
  setCollectionPaymentDetailsErrorMessage,
  setCollectionPaymentDetailsHasErrored,
  setCollectionPaymentDetailsIsLoading,
  setDocumentByClusterErrorMessage,
  setDocumentByClusterHasErrored,
  setDocumentByClusterIsLoading,
  setDocumentByClusterList,
  setIsMultiplePayment,
  setIsPayInstructionEnabled,
  setMedicationList,
  setMedicationsErrorMessage,
  setMedicationsHasErrored,
  setMedicationsIsLoading,
  setPastRequestMedicationImages,
  setPastRequestMedicationImagesErrorMessage,
  setPastRequestMedicationImagesHasErrored,
  setPastRequestMedicationImagesIsLoading,
  setPastRequestsErrorMessge,
  setPastRequestsHasErrored,
  setPastRequestsHasMoreRecords,
  setPastRequestsIsLoading,
  setPastRequestsOffset,
  setPaymentList,
  setSystemSettingsErrorMessage,
  setSystemSettingsHasErrored,
  setSystemSettingsIsLoading,
  setSystemSettingsLookup,
  setViewPastDetail,
  setViewPastDetailErrorMessage,
  setViewPastDetailsHasErrored,
  setViewPastDetailsIsLoading,
  updatePastRequests,
} from ".";
import { setShowMyChartMedication } from "./medrefillSlice";
import { AxiosError } from "axios";
import renderErrorMsg from "lib/util/ConsoleUtil/renderErrorMsg";
import {
  setOldMemberIdentifier,
  setOldTokenizedId,
} from "lib/redux/user/userSlice";

const fetchSystemSettings =
  (cluster: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setSystemSettingsIsLoading(true));
      const systemSettingList = await getSystemSettings({
        category: "Medication",
        subcategory: null,
        codeName: cluster,
        institutionCode: null,
      });
      dispatch(setSystemSettingsLookup(systemSettingList.Settings));
      dispatch(setSystemSettingsHasErrored(false));
      dispatch(setSystemSettingsErrorMessage(null));
    } catch (error) {
      console.error(renderErrorMsg(error));
      dispatch(setSystemSettingsLookup([]));
      dispatch(setSystemSettingsHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setSystemSettingsErrorMessage(error.response?.data.Message));
      }
    } finally {
      dispatch(setSystemSettingsIsLoading(false));
    }
  };

const fetchMyChartSystemSettings =
  () => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setSystemSettingsIsLoading(true));
      const systemSettingList = await getSystemSettings({
        category: "OneNUHs",
        subcategory: "AppServicesAndUsefulLinks",
        codeName: "FeatureToggle",
        institutionCode: null,
      });

      const rawValue = systemSettingList.Settings[0].Value;

      const parsedValueArray: { name: string; toggle: boolean }[] =
        parseJson(rawValue);

      const MedicationRecordToggle = parsedValueArray.find(
        (obj) => obj.name === "View Medication Records",
      );

      const PreventiveCareToggle = parsedValueArray.find(
        (obj) => obj.name === "Preventive Care",
      );

      dispatch(
        setShowMyChartMedication(
          MedicationRecordToggle ? MedicationRecordToggle.toggle : false,
        ),
      );

      dispatch(
        setShowPreventiveCare(
          PreventiveCareToggle ? PreventiveCareToggle.toggle : false,
        ),
      );
      dispatch(setSystemSettingsHasErrored(false));
      dispatch(setSystemSettingsErrorMessage(null));
    } catch (error) {
      dispatch(setShowMyChartMedication(false));
      dispatch(setSystemSettingsHasErrored(true));

      if (error instanceof AxiosError) {
        dispatch(setSystemSettingsErrorMessage(error.response?.data.Message));
      }
    } finally {
      dispatch(setSystemSettingsIsLoading(false));
    }
  };

const fetchMedications =
  (cluster: string) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    const oldTokenizedId = getState().user.oldTokenizedId;
    try {
      dispatch(setMedicationsIsLoading(true));
      const medicationResponseData = await getMedications(
        cluster,
        oldTokenizedId,
      );

      dispatch(setMedicationList(medicationResponseData.Medications));
      dispatch(setMedicationsHasErrored(false));
      dispatch(setMedicationsErrorMessage(null));
      dispatch(setOldTokenizedId(medicationResponseData.OldTokenizedId));
    } catch (error) {
      dispatch(setMedicationList([]));
      dispatch(setMedicationsHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setMedicationsErrorMessage(error.response?.data.Message));
      }
    } finally {
      dispatch(setMedicationsIsLoading(false));
    }
  };

const fetchDocumentByCluster =
  (cluster: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setDocumentByClusterIsLoading(true));
      const documentByClusterResponseData = await getDocumentByCluster(
        cluster,
        "MedicationRefill",
      );

      dispatch(
        setDocumentByClusterList(documentByClusterResponseData.Document),
      );
      dispatch(setDocumentByClusterHasErrored(false));
      dispatch(setDocumentByClusterErrorMessage(null));
    } catch (error) {
      dispatch(setDocumentByClusterList([]));
      dispatch(setDocumentByClusterHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(
          setDocumentByClusterErrorMessage(error.response?.data.Message),
        );
      }
    } finally {
      dispatch(setDocumentByClusterIsLoading(false));
    }
  };

const fetchClinics =
  (facilityId: number) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    const oldTokenizedId = getState().user.oldTokenizedId;
    try {
      dispatch(setClinicsIsLoading(true));
      const clinicResponseData = await getClinics(facilityId, oldTokenizedId);

      dispatch(setClinicList(clinicResponseData.Clinics));
      dispatch(setClinicsHasErrored(false));
      dispatch(setClinicsErrorMessage(null));
      dispatch(setOldTokenizedId(clinicResponseData.OldTokenizedId));
    } catch (error) {
      dispatch(setClinicList([]));
      dispatch(setClinicsHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setClinicsErrorMessage(error.response?.data.Message));
      }
    } finally {
      dispatch(setClinicsIsLoading(false));
    }
  };

const fetchCollectionPaymentDetails =
  (cluster: string | null, facilityId: number | null) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    const oldTokenizedId = getState().user.oldTokenizedId;
    try {
      dispatch(setCollectionPaymentDetailsIsLoading(true));
      const collectionAndPaymentDetailsResponseData =
        await getCollectionPaymentDetails(cluster, facilityId, oldTokenizedId);

      // Please note that refillType means 'prescription with me' (true) / 'prescription with pharmacy' (false)
      // For TOPUP flow, it is always assumed 'prescription with pharmacy'
      // For REFILL flow, OneNUHS assumes 'prescription with pharmacy' too
      const refillType = false;

      const collectionPaymentDetails =
        collectionAndPaymentDetailsResponseData.CollectionPayments.filter(
          (x) => x.RefillType === refillType,
        );

      // --- Collection
      let collectionList = collectionPaymentDetails.map(
        (detail): Collection => ({
          CollectionCode: detail.CollectionCode,
          CollectionValue: detail.CollectionValue,
          CollectionType: detail.CollectionType,
          OrderByCollectionMode: detail.OrderByCollectionMode,
        }),
      );
      // de-duplicate list of options, as API returns non-unique options
      collectionList = collectionList.filter(
        (payment, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.CollectionCode === payment.CollectionCode &&
              t.CollectionValue === payment.CollectionValue,
          ),
      );
      dispatch(
        setOldTokenizedId(
          collectionAndPaymentDetailsResponseData.OldTokenizedId,
        ),
      );
      dispatch(setCollectionList(collectionList));

      // --- Payment
      let paymentList = collectionPaymentDetails.map(
        (detail): Payment => ({
          PaymentCode: detail.PaymentCode,
          PaymentValue: detail.PaymentValue,
          PaymentDisclaimer: detail.PaymentDisclaimer,
          IsMultiplePayment: detail.IsMultiplePayment,
          IsPayInstructionEnabled: detail.IsPayInstructionEnabled,
        }),
      );
      // de-duplicate list of options, as API returns non-unique options
      paymentList = paymentList.filter(
        (payment, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.PaymentCode === payment.PaymentCode &&
              t.PaymentValue === payment.PaymentValue &&
              t.PaymentDisclaimer === payment.PaymentDisclaimer,
          ),
      );
      dispatch(setPaymentList(paymentList));

      dispatch(setIsMultiplePayment(paymentList[0].IsMultiplePayment));
      dispatch(
        setIsPayInstructionEnabled(paymentList[0].IsPayInstructionEnabled),
      );

      dispatch(setCollectionPaymentDetailsHasErrored(false));
      dispatch(setCollectionPaymentDetailsErrorMessage(null));
    } catch (error) {
      dispatch(setCollectionList([]));
      dispatch(setPaymentList([]));
      dispatch(setIsMultiplePayment(false));
      dispatch(setIsPayInstructionEnabled(false));
      dispatch(setCollectionPaymentDetailsHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(
          setCollectionPaymentDetailsErrorMessage(error.response?.data.Message),
        );
      }
    } finally {
      dispatch(setCollectionPaymentDetailsIsLoading(false));
    }
  };

const fetchPastRequests =
  (numberOfItems: number, prescriptionRequestType: PrescriptionRequestType) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    const { memberIdentifier, oldMemberIdentifier, oldTokenizedId } =
      getState().user;
    try {
      dispatch(setPastRequestsIsLoading(true));

      const pastRequestsPayload = await getMedicationSummary(
        memberIdentifier,
        getState().medRefill.pastRequest.offset * numberOfItems,
        numberOfItems,
        prescriptionRequestType,
        oldMemberIdentifier,
        oldTokenizedId,
      );

      const currentSummaryList =
        getState().medRefill.pastRequest.summaryList ?? [];

      dispatch(
        updatePastRequests(
          currentSummaryList.concat(pastRequestsPayload.Summary),
        ),
      );
      dispatch(
        setPastRequestsHasMoreRecords(pastRequestsPayload.HasMoreRecords),
      );
      dispatch(
        setPastRequestsOffset(getState().medRefill.pastRequest.offset + 1),
      );
      dispatch(setPastRequestsHasErrored(false));
      dispatch(setPastRequestsErrorMessge(null));
    } catch (error) {
      dispatch(updatePastRequests(null));
      dispatch(setPastRequestsHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setPastRequestsErrorMessge(error.response?.data.Message));
      }
    } finally {
      dispatch(setPastRequestsIsLoading(false));
    }
  };

const fetchMedicationSummaryDetail =
  () => async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      //retrieve requestorId from redux
      const requestorId = getState().medRefill.pastRequest.selectedRequesterId;
      const { memberIdentifier, oldMemberIdentifier, oldTokenizedId } =
        getState().user;
      // if requestorId has been stored properly
      if (requestorId) {
        dispatch(setViewPastDetailsIsLoading(true));
        const medicationSummaryDetailPayload = await getMedicationSummaryDetail(
          requestorId,
          memberIdentifier,
          oldMemberIdentifier,
          oldTokenizedId,
        );

        dispatch(
          setViewPastDetail(medicationSummaryDetailPayload.SummaryDetail),
        );
        dispatch(setViewPastDetailsHasErrored(false));
        dispatch(setViewPastDetailErrorMessage(null));
        dispatch(
          setOldMemberIdentifier(
            medicationSummaryDetailPayload.OldMemberIdentifier,
          ),
        );
        dispatch(
          setOldTokenizedId(medicationSummaryDetailPayload.OldTokenizedId),
        );
      } else {
        dispatch(setViewPastDetail(null));
        dispatch(setViewPastDetailsHasErrored(true));
        dispatch(setViewPastDetailErrorMessage(null));
      }
    } catch (error) {
      dispatch(setViewPastDetail(null));
      dispatch(setViewPastDetailsHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setViewPastDetailErrorMessage(error.response?.data.Message));
      }
    } finally {
      dispatch(setViewPastDetailsIsLoading(false));
    }
  };

const fetchMedicationImages =
  () => async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      const requestorId = getState().medRefill.pastRequest.selectedRequesterId;
      const memberIdentifier = getState().user.memberIdentifier;
      const { oldMemberIdentifier, oldTokenizedId } = getState().user;

      if (requestorId) {
        dispatch(setPastRequestMedicationImagesIsLoading(true));
        const medicationImagesPayload = await getMedicationImages(
          requestorId,
          memberIdentifier,
          oldMemberIdentifier,
          oldTokenizedId,
        );

        dispatch(
          setPastRequestMedicationImages(medicationImagesPayload.Images),
        );
        dispatch(setPastRequestMedicationImagesHasErrored(false));
        dispatch(setPastRequestMedicationImagesErrorMessage(null));
        dispatch(
          setOldMemberIdentifier(medicationImagesPayload.OldMemberIdentifier),
        );
        dispatch(setOldTokenizedId(medicationImagesPayload.OldTokenizedId));
      } else {
        dispatch(setPastRequestMedicationImages(null));
        dispatch(setPastRequestMedicationImagesHasErrored(true));
        dispatch(setPastRequestMedicationImagesErrorMessage(null));
      }
    } catch (error) {
      dispatch(setPastRequestMedicationImages(null));
      dispatch(setPastRequestMedicationImagesHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(
          setPastRequestMedicationImagesErrorMessage(
            error.response?.data.Message,
          ),
        );
      }
    } finally {
      dispatch(setPastRequestMedicationImagesIsLoading(false));
    }
  };

export {
  fetchClinics,
  fetchCollectionPaymentDetails,
  fetchDocumentByCluster,
  fetchMedicationImages,
  fetchMedicationSummaryDetail,
  fetchMedications,
  fetchMyChartSystemSettings,
  fetchPastRequests,
  fetchSystemSettings,
};
