import { ACTIONS } from "lib/routing";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { clearAllUserSession } from "lib/util/storage/clearAllUserSession";
import { useEffect } from "react";

const SessionLost = () => {
  useEffect(() => {
    clearAllUserSession();
    mobileNavigate(ACTIONS.MOBILE_UNAUTHORISED_PATH);
  }, []);

  return null;
};

export default SessionLost;
