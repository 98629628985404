import React, { useState } from "react";
import { sxStyles } from "./MedicationTopupDetailSummarySection.styles";
import { MedicationTopupDetailSummarySectionProps } from "./MedicationTopupDetailSummarySection.types";
import { Typography, Box } from "@mui/material";
import ImageModal from "lib/components/modals/ImageModal/ImageModal";

// Inline image encoding scheme prefix (required by <img src="..." /> components)
const INLINE_IMAGE_ENCODING_SCHEME_PREFIX = "data:image/jpeg;base64,";

const MedicationTopupDetailSummarySection = ({
  topupReasonText,
  files,
}: MedicationTopupDetailSummarySectionProps) => {
  const classes = sxStyles();

  const [open, setOpen] = useState(false);
  const [startAt, setStartAt] = useState(0);

  return (
    <>
      <Typography sx={classes.title}>Repeat Prescription For</Typography>
      <Typography sx={classes.topupReason}>{topupReasonText}</Typography>

      {files.length > 0 && (
        <Box display="flex" flexDirection="column">
          <Typography sx={classes.subtitle}>Details of Prescription</Typography>
          {files.map((file, index) => (
            <Box key={index} sx={classes.contentBox}>
              {file.front.fileData && (
                <Box
                  sx={classes.prescriptionPhotoFrame}
                  onClick={() => {
                    setStartAt(index);
                    setOpen(true);
                  }}
                >
                  <Box
                    component="img"
                    src={
                      INLINE_IMAGE_ENCODING_SCHEME_PREFIX + file.front.fileData
                    }
                    alt="front"
                    sx={classes.photo}
                  />
                  <Typography sx={classes.photoCaption}>
                    {"Medication Image " + (index + 1)}
                  </Typography>
                </Box>
              )}

              <Box
                display="flex"
                mb={1.5}
                key={
                  (file.topUp.medicationName || "") +
                  (file.topUp.quantity || "") +
                  (file.topUp.UOM || "")
                }
              >
                <Box width="80%">
                  <Typography sx={classes.medicationDetail}>
                    {file.topUp.medicationName}
                  </Typography>
                </Box>
                <Typography sx={classes.medicationDetailValue}>
                  {file.topUp.quantity + " " + file.topUp.UOM}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}

      <ImageModal
        fileDataList={files.map((file) => file.front.fileData || "")}
        startAt={startAt}
        footerMessage={files.map(
          (file, index) => "Medication Image " + (index + 1),
        )}
        footerDescription={files.map(
          (file) =>
            file.topUp.medicationName +
            ": " +
            file.topUp.quantity +
            file.topUp.UOM,
        )}
        open={open}
        onClose={() => {
          setStartAt(0);
          setOpen(false);
        }}
      ></ImageModal>
    </>
  );
};

export default MedicationTopupDetailSummarySection;
