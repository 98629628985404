/**
 * Formats the url to access 'Add to Calendar' function on mobile
 *
 * @param {number} appointmentId Id of appointment
 * @param {Date} dataTime start time of appointment
 * @param {string} location appointment location
 * @param {string} institution appointment institution
 */
export const formatAddToCalendarURI = (
  appointmentId: string,
  dataTime: Date,
  location: string | null,
  institution: string,
  defaultTitle: string | null,
) => {
  const id = encodeURIComponent(appointmentId);
  const title = defaultTitle
    ? defaultTitle
    : encodeURIComponent("Appointment at " + institution);
  const startTime = Number(dataTime);
  const endTime = startTime + 30 * 60 * 1000; //set 30 mins by default
  const loc = location ? encodeURIComponent(location) : ""; //to avoid showing text null
  return `appointments?action=calendar&appointmentId=${id}&title=${title}&description=&startTime=${startTime}&endTime=${endTime}&location=${loc}`;
};
