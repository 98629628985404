import { ACTIONS } from "lib/routing";

/**Formats the url to access "Share" function on mobile
 * @param {string} url link to share
 * @returns encoded url prefixed with share action
 */
export const formatSharingUrl = (url: string): string => {
  const encodedUrl = encodeURIComponent(url);
  return `${ACTIONS.SHARE_ACTION}&url=${encodedUrl}`;
};
