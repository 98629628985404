/**
 * If the error is an instance of Error, return the error message, otherwise return the error as a
 * string.
 * @param {unknown} error - unknown - The error that was thrown.
 * @returns A function that takes an error and returns a string.
 */
const renderErrorMsg = (error: unknown) => {
  if (error instanceof Error) return error.message;
  return String(error);
};
export default renderErrorMsg;
