/**
 * convert a Uint8Array buffer to a base64 string
 * @param {Uint8Array} buffer data in arrayBuffer format
 * @returns {string} The converted base64 string
 */
const arrayBufferToBase64 = (buffer: Uint8Array) => {
  var binaryString = "";
  const len = buffer.byteLength;
  for (var i = 0; i < len; i++) {
    binaryString += String.fromCharCode(buffer[i]);
  }
  return btoa(binaryString);
};

export { arrayBufferToBase64 };
