import { RootState } from "lib/redux/root/redux.types";
import { connect } from "react-redux";
import CancelApptFormField from "./CancelApptFormField";
import { CANCELLATION_REASON_LIST_KEY } from "api/shared/GetDocumentByType/getDocumentByType.mapper";

const mapStateToProps = (state: RootState) => {
  let cancellationReasonListValue =
    state.appointments.documentByType.documentByTypeMap[
      CANCELLATION_REASON_LIST_KEY
    ];
  const cancellationReasonList = cancellationReasonListValue
    ? cancellationReasonListValue.split("|")
    : [];
  return {
    cancellationReasonList: cancellationReasonList.map((reason) => {
      return {
        id: reason,
        text: reason,
      };
    }),
  };
};

export default connect(mapStateToProps)(CancelApptFormField);
