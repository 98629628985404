import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { REQUEUE_FOR_CONSULTATION_URL } from "../../resources/configs/URL";
import { formatRequeueForConsultationErrorMessage } from "../../resources/messages/ErrorMessage";
import { RequeueForConsultationPayload } from "./requeueForConsultation.fromApi.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";

/**
 * Requeue an appointment
 *
 * POST
 * Endpoint: /encounter/RequeueForConsultation
 *
 * @param {string | null} memberIdentifier  Member Identifier of the person we are making this request for
 * @param {string} regAppointmentId  Reg ID of the appointment
 * @param {string | null} regInstitutionCode  Reg Institution code of this appointment (e.g. "NUP")
 * @param {string | null} institutionCode  Institution code of the appointment
 * @param {string | null} clinicCode  Clinic code of the appointment
 *
 * @returns {RequeueForConsultationPayload} Response payload from the remote API
 */
const requeueForConsultation = async (
  memberIdentifier: string | null,
  regAppointmentId: string | null,
  regInstitutionCode: string | null,
  institutionCode: string | null,
  clinicCode: string | null,
): Promise<RequeueForConsultationPayload> => {
  try {
    const response = await axios.post<RequeueForConsultationPayload>(
      REQUEUE_FOR_CONSULTATION_URL(),
      createRequestPayload(
        memberIdentifier,
        regAppointmentId,
        regInstitutionCode,
        institutionCode,
        clinicCode,
      ),
      getRequestOptions(),
    );
    return parseResponse(response, REQUEUE_FOR_CONSULTATION_URL());
  } catch (error) {
    const msg = formatRequeueForConsultationErrorMessage(
      REQUEUE_FOR_CONSULTATION_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  memberIdentifier: string | null,
  regAppointmentId: string | null,
  regInstitutionCode: string | null,
  institutionCode: string | null,
  clinicCode: string | null,
) => {
  return {
    MemberIdentifier: memberIdentifier,
    RegAppointmentId: regAppointmentId,
    RegInstitutionCode: regInstitutionCode,
    InstitutionCode: institutionCode,
    ClinicCode: clinicCode,
  };
};

export { requeueForConsultation };
