import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { ImageModalProps } from "./ImageModal.types";
import { sxStyles } from "./ImageModal.styles";
import Carousel from "react-material-ui-carousel";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import IMAGES from "lib/assets/images";

// Inline image encoding scheme prefix (required by <img src="..." /> components)
const INLINE_IMAGE_ENCODING_SCHEME_PREFIX = "data:image/jpeg;base64,";

const ImageModal = ({
  title,
  footerMessage,
  footerDescription,
  fileDataList,
  startAt = 0,
  open,
  disableBackdropClick = false,
  onClose,
}: ImageModalProps) => {
  const classes = sxStyles();

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={(event, reason) => {
        if (disableBackdropClick) {
          if (reason === "backdropClick") {
          } else {
            if (onClose) onClose();
          }
        } else {
          if (onClose) onClose();
        }
      }}
      sx={classes.dialog}
    >
      <IconButton
        data-testid="close"
        aria-label="close"
        sx={classes.closeButton}
        onClick={onClose}
      >
        <Box component={"img"} src={IMAGES.general.GeneralCloseIcon} />
      </IconButton>
      <DialogContent>
        <Carousel
          autoPlay={false}
          // startAt={startAt}
          navButtonsAlwaysInvisible={fileDataList.length === 1 ? true : false}
        >
          {fileDataList.map((fileData, index) => {
            return (
              <Box key={index}>
                {title && <Typography sx={classes.title}>{title}</Typography>}
                <Box sx={classes.photoWrapper}>
                  <TransformWrapper
                    initialScale={1}
                    initialPositionX={200}
                    initialPositionY={100}
                  >
                    <TransformComponent>
                      <Box
                        component="img"
                        src={INLINE_IMAGE_ENCODING_SCHEME_PREFIX + fileData}
                        alt="prescription"
                        sx={classes.photo}
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </Box>
                {footerMessage && footerMessage[index] && (
                  <Typography sx={classes.photoPairTitle}>
                    {footerMessage[index]}
                  </Typography>
                )}

                {footerDescription && footerDescription[index] && (
                  <p>{footerDescription[index]}</p>
                )}
              </Box>
            );
          })}
        </Carousel>
      </DialogContent>
    </Dialog>
  );
};

export default ImageModal;
