import { connect } from "react-redux";
import { RootState } from "lib/redux/root/redux.types";
import RequestSummary from "./RequestSummary";
import { createMedicalReport } from "api/mrrp/CreateMedicalReport/createMedicalReport";
import { Dispatch } from "react";
import { AnyAction } from "redux";
import { handleErrorModal } from "lib/redux/notifications";
import { ViewMN } from "api/mrrp/CreateMedicalReport/createMedicalReport.fromApi.types";
import { setCreateMedicalReportResult } from "ui/mrrp/ducks";

const mapStateToProps = (state: RootState) => {
  return {
    summary: state.mrrp,
    memberIdentifier: state.user.memberIdentifier,
    isSelf: state.user.isPatient,
    oldMemberIdentifier: state.user.oldMemberIdentifier,
    oldTokenizedId: state.user.oldTokenizedId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onBook: async (
      hospitalCode: string | null,
      memberIdentifier: string | null,
      isSelf: boolean | null,
      requesterContactPerson: string | null,
      requesterContactPhoneNo: string | null,
      requesterAlternatePhoneNo: string | null,
      block: string | null,
      floorAndUnit: string | null,
      street: string | null,
      postalCode: string | null,
      requesterEmail: string | null,
      reportTypeCode: string | null,
      reportTypeName: string | null,
      reportFee: number | null,
      deptCode: string | null,
      reportFileByte: Uint8Array | null,
      reportFileName: string | null,
      relationshipWithPatient: string | null,
      modeOfDelivery: string | null,
      purposeState: string | null,
      visitDate: string | null,
      oldMemberIdentifier: string | null,
      oldTokenizedId: string | null,
    ) => {
      return await createMedicalReport(
        hospitalCode ?? "",
        memberIdentifier ?? "",
        isSelf ?? true,
        requesterContactPerson ?? "",
        requesterContactPhoneNo ?? "",
        requesterAlternatePhoneNo ?? "",
        block + ", " + floorAndUnit,
        street,
        null,
        postalCode,
        requesterEmail ?? "",
        reportTypeCode ?? "",
        reportTypeName ?? "",
        reportFee ?? 0,
        deptCode ?? "",
        reportFileByte,
        reportFileName,
        relationshipWithPatient,
        modeOfDelivery ?? "",
        purposeState ?? "",
        null,
        visitDate ?? "",
        "N/A",
        oldMemberIdentifier,
        oldTokenizedId,
      );
    },
    onSetApiResult: (data: ViewMN) => {
      dispatch(setCreateMedicalReportResult(data));
    },
    handleErrorModal: (open: boolean, message: string | null) => {
      dispatch(handleErrorModal({ open, message }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestSummary);
