import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    cardContainer: {
      display: "flex",
      position: "relative",
    },
    addToCalendar: {
      position: "absolute",
      top: 0,
      right: 0,
      padding: theme.spacing(2, 1, 0.5),
    },
    teleconsultMessage: {
      padding: 1,
    },
    reminderText: {
      padding: 1,
      background: theme.palette.info.light,
    },
  } satisfies SxObjType;
};

export { sxStyles };
