import { Box } from "@mui/material";
import { ConfirmationCardProps } from "./ConfirmationCard.types";
import CardWrapper from "../../../../common/cards/CardWrapper/CardWrapper";
import AppointmentCardHeader from "ui/appointment/components/common/cards/AppointmentCardHeader/AppointmentCardHeader";
import AppointmentCardBody from "ui/appointment/components/common/cards/AppointmentCardBody/AppointmentCardBody";

const ConfirmationCard = ({
  slotDateTime,
  institution,
  service,
  location,
  zoneInfo,
}: ConfirmationCardProps) => {
  return (
    <CardWrapper isLinkedAppointmentStyled={false}>
      <Box sx={{ display: "flex" }}>
        <AppointmentCardHeader datetime={slotDateTime} isHighlighted={true} />
        <AppointmentCardBody
          institution={institution || ""}
          datetime={slotDateTime}
          departmentName={null}
          clinician={null}
          location={location}
          zoneInfo={zoneInfo}
          serviceDisplayName={service}
          visitType={null}
          isVcp={false}
        />
      </Box>
    </CardWrapper>
  );
};

export default ConfirmationCard;
