import { ViewOutstandingBills } from "api/payment/GetOutstandingBills/getOutstandingBills.fromApi.types";
import { ViewPaidBillsHistory } from "api/payment/GetPaidBillsHistory/getPaidBillsHistory.fromApi.types";
import { ViewPaymentHistory } from "api/payment/GetTransactionHistory/getTransactionHistory.fromApi.types";
import { ViewBillSummaryDetails } from "api/payment/GetBillSummaryDetails/getBillSummaryDetails.fromApi.types";
import { ViewSubmitPaymentRequest } from "api/payment/SubmitPaymentRequest/submitPaymentRequest.fromApi.types";
import { ViewPaymentInfo } from "api/payment/GetPaymentInfo/getPaymentInfo.fromApi.types";
import { ViewManualBill } from "api/payment/AddManualBill/addManualBill.fromApi.types";
import { ViewArInstitutions } from "api/payment/GetArInstitutions/getArInstitutions.fromApi.types";
import { documentByType } from "ui/appointment/ducks/appointments.types";

export interface PaymentState {
  // Configurable disclaimer from backend
  documentByType: documentByType;
  // All outstanding bills, include index of selected outstanding bills for viewing (if available)
  outstandingBills: PaymentOutstandingBillsState;

  addedBills: PaymentAddedBillsState;

  // Selected Bill details for viewing (if available)
  billDetails: PaymentBillDetailsState;

  // All paid bills, include index of selected paid bills for viewing (if available)
  paidBills: PaymentPaidBillsState;

  // All transaction History, include index of selected transaction history for viewing (if available)
  transactionHistory: PaymentTransactionHistoryState;

  billsToPay: PaymentBillsToPayState[];

  payment: Payment;

  // true if receipt page should show as "check status" button, false is should show receipt content
  receiptPageLanding: boolean;

  paymentPersist: PaymentPersist;

  // TODO: optimize to use store all API status
  apiStatus: PaymentApiStatus;

  arInstitutions: {
    arInstitutionsList: ViewArInstitutions[];
    isLoading: boolean;
    hasErrored: boolean | null;
    errorMessage: string | null;
  };

  availablePaymentModes: {
    IsCcAvailable: boolean;
    IsDdAvailable: boolean;
    IsAmexAvailable: boolean;
    CcDisclaimer: string | null;
    DdDisclaimer: string | null;
    AmexDisclaimer: string | null;
    isLoading: boolean;
    hasErrored: boolean | null;
    errorMessage: string | null;
  };
}

export enum ApiEnum {
  SubmitPaymentRequest,
  GetPaymentInfo,
}

export interface PaymentApiStatus {
  [ApiEnum.SubmitPaymentRequest]: SingleApiStatus;
  [ApiEnum.GetPaymentInfo]: SingleApiStatus;
}

// TODO: This interface can be applied to outstanding bills, paid bills for optimization
export interface SingleApiStatus {
  isLoading: boolean | null; // true if API call is on going
  hasErrored: boolean | null; // False if the API call is sucessful, true otherwise
  errorMessage: string | null;
}

// === GetOutstandingBills
export interface PaymentOutstandingBillsState {
  shouldUpdate: boolean; // boolean state to control update of the payment list, false if returned from bill details screen, true otherwise
  isLoading: boolean; // true if API call is on going
  hasErrored: boolean | null; // False if the API call is sucessful, true otherwise
  errorMessage: string | null;
  selectedOutstandingBillsIndex: string | null; // index of selected outstanding bills for viewing
  allOutstandingBills: ViewOutstandingBills[];
  expandedOutstandingBillsListFlag: boolean;
}

// === GetBillSummaryDetails
export interface PaymentBillDetailsState {
  isLoading: boolean; // true if API call is on going
  hasErrored: boolean | null; // False if the API call is sucessful, true otherwise
  errorMessage: string | null;
  billDetails: ViewBillSummaryDetails | null;
}

// === GetPaidBillsHistory
export interface PaymentPaidBillsState {
  isLoading: boolean; // true if API call is on going
  hasErrored: boolean | null; // False if the API call is sucessful, true otherwise
  errorMessage: string | null;
  selectedPaidBillsIndex: number | null; // index of selected paid bills for viewing
  allPaidBills: ViewPaidBillsHistory[];
  expandedPaidBillsListFlag: boolean;
}

// === GetTransactionHistory
export interface PaymentTransactionHistoryState {
  isLoading: boolean; // true if API call is on going
  hasErrored: boolean | null; // False if the API call is sucessful, true otherwise
  errorMessage: string | null;
  selectedTransactionHistoryIndex: number | null; // index of selected paid bills for viewing
  allTransactionHistory: ViewPaymentHistory[];
  expandedTransactionHistoryListFlag: boolean;
}

export interface PaymentBillsToPayState {
  InvNo: string; // Unique Invoice Number for each bill
  Selected: boolean; // if the bill is selected to proceed to next page
  AmountToPay: string; // The amount user want to pay for each  bill in string format
}

export interface PaymentAddedBillsState {
  selectedAddedBillsIndex: string | null; // index of selected added bills for viewing
  allAddedBills: ViewManualBill[];
}

// === SubmitPaymentRequest, GetPaymentInfo
export interface Payment {
  submitPaymentRequest: ViewSubmitPaymentRequest;
  paymentInfo: ViewPaymentInfo;
}

// ==== persist for GetPaymentInfo
export interface PaymentPersist {
  payorName: string | null;
  email: string | null;
  paymentToken: string | null;
  merchantRefNumber: string | null;
  requestorMemberIdentifier: string | null;
  selectedPaymentMode: string | null;
}
