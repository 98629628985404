import { Box, Dialog, DialogContent, Button } from "@mui/material";
import { TriageQuestionPromptModalProps } from "./TriageQuestionPromptModal.types";
import { sxStyles } from "./TriageQuestionPromptModal.styles";

const TriageQuestionPromptModal = ({
  body,
  nextButtonText,
  cancelButtonText,
  cancelButtonLabel,
  open,
  disableBackdropClick = false,
  onClose,
  onClickNext,
  onClickCancel,
}: TriageQuestionPromptModalProps) => {
  const classes = sxStyles();
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={(event, reason) => {
        const isBackdropClick = reason === "backdropClick";
        if (disableBackdropClick && isBackdropClick) {
        } else {
          onClose();
        }
      }}
    >
      <Box sx={classes.dialog}>
        <DialogContent sx={classes.dialogContent}>{body}</DialogContent>
        <Box sx={{ m: 2 }}>
          <Box display="flex" flexDirection="column">
            <Box sx={classes.topButtonLayout}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={onClickNext}
              >
                {nextButtonText}
              </Button>
            </Box>

            <Button
              variant="outlined"
              color="primary"
              fullWidth={true}
              onClick={onClickCancel}
            >
              {cancelButtonText}
            </Button>

            {/* OPTIONAL LABEL */}
            {cancelButtonLabel ? (
              <Box sx={classes.buttonLabel}>{cancelButtonLabel}</Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default TriageQuestionPromptModal;
