import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { GetCollectionPaymentsPayload } from "./getCollectionPaymentDetails.fromApi.types";
import { GET_COLLECTION_PAYMENT_DETAILS_URL } from "api/resources/configs/URL";
import { formatGetCollectionPaymentsErrorMessage } from "api/resources/messages/ErrorMessage";

/**
 * Fetches the Collection and Payment details based on cluster and facility
 *
 * POST
 * Endpoint: /GetCollectionPaymentDetails
 *
 * @param {string} cluster a specific code to identify a group of hospitals or polyclinics
 * @param {string} facilityId Identifier for mapping facility in database
 */
const getCollectionPaymentDetails = async (
  cluster: string | null,
  facilityId: number | null,
  oldTokenizedId: string | null,
): Promise<GetCollectionPaymentsPayload> => {
  try {
    const response = await axios.post<GetCollectionPaymentsPayload>(
      GET_COLLECTION_PAYMENT_DETAILS_URL(),
      createRequestPayload(cluster, facilityId, oldTokenizedId),
      getRequestOptions(),
    );
    return parseResponse(response, GET_COLLECTION_PAYMENT_DETAILS_URL());
  } catch (error) {
    const msg = formatGetCollectionPaymentsErrorMessage(
      GET_COLLECTION_PAYMENT_DETAILS_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  cluster: string | null,
  facilityId: number | null,
  oldTokenizedId: string | null,
) => {
  return {
    Cluster: cluster,
    FacilityId: facilityId,
    OldTokenizedId: oldTokenizedId,
  };
};

export { getCollectionPaymentDetails };
