import { sxStyles } from "./Dropdown.styles";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { DropdownProps } from "./Dropdown.types";
import { color } from "lib/theme/color";
import CustomSvgIcon from "./DropdownCustomSvg";

const Dropdown = ({
  required = true,
  name,
  value,
  disabled = false,
  items,
  label,
  handleChange,
}: DropdownProps) => {
  const classes = sxStyles();

  return (
    <>
      <FormControl variant="outlined" fullWidth sx={classes.menulist}>
        <InputLabel disabled={disabled} id="demo-simple-select-outlined-label">
          {label}
        </InputLabel>
        <Select
          label={label} //this not showing up
          fullWidth
          variant="outlined"
          notched={false}
          data-testid="demo-simple-select-outlined"
          value={value || ""}
          disabled={disabled}
          onChange={handleChange as any}
          name={name}
          sx={classes.menuItemContainer}
          IconComponent={CustomSvgIcon}
        >
          {items.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              data-testid="here"
              sx={classes.menuIem}
              style={{
                whiteSpace: "normal",
                color: color.GREY400,
                justifyContent: "flex-start",
                paddingLeft: "16px",
              }}
            >
              {item.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {required && (
        <Box m={0} sx={classes.errText}>
          *Required
        </Box>
      )}
    </>
  );
};

export default Dropdown;
