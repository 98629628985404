import axios from "axios";
import { GET_OUTSTANDING_BILLS_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatGetOutstandingBillsErrorMessage } from "api/resources/messages/ErrorMessage";
import { GetOutstandingBillsResponsePayload } from "./getOutstandingBills.fromApi.types";

/**
 * Get report Outstanding Bills from multiple source institutions
 *
 * POST
 * Endpoint: secure/payment/GetOutstandingBills
 *
 * @param {string} InstitutionCode Hospital code to retrieve values for, retrieve for all when null, ADMC | CGH | IMH | KKH | KTPH | NUH | SGH | SKH | TTSH
 * @param {string} memberIdentifier Encrypted NRIC. e.g. "d030644b48cdbf04f4f87e6dfd02939f" (this one is invalid)
 */
const getOutstandingBills = async (
  InstitutionCode: string | null,
  memberIdentifier: string | null,
): Promise<GetOutstandingBillsResponsePayload> => {
  try {
    const response = await axios.post<GetOutstandingBillsResponsePayload>(
      GET_OUTSTANDING_BILLS_URL(),
      createRequestPayload(InstitutionCode, memberIdentifier),
      getRequestOptions(),
    );
    return parseResponse(response, GET_OUTSTANDING_BILLS_URL());
  } catch (error) {
    const msg = formatGetOutstandingBillsErrorMessage(
      GET_OUTSTANDING_BILLS_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  InstitutionCode: string | null,
  memberIdentifier: string | null,
) => {
  return {
    InstitutionCode: InstitutionCode,
    MemberIdentifier: memberIdentifier,
  };
};

export { getOutstandingBills };
