import { color } from "lib/theme/color";
import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = ({
  colorType,
  isIHADisabled,
}: {
  colorType: string;
  isIHADisabled: boolean;
}) => {
  let colorTone: string | null = null;
  if (colorType === "completed") {
    colorTone = theme.palette.success.main;
  } else if (colorType === "missed") {
    colorTone = theme.palette.warning.main;
  } else {
    colorTone = theme.palette.text.primary;
  }

  return {
    root: {
      padding: theme.spacing(2, 1),
    },
    institutionNameBox: {
      textAlign: "center",
      borderBottom: "2px solid " + color.RGBA_204,
      paddingBottom: theme.spacing(0.5),
    },
    institutionNameBoxText: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_bold,
    },
    queueNumberBox: {
      borderBottom: "2px solid " + color.RGBA_204,
      py: 1,

      alignItems: "center",
    },
    detailLabel: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    queueNumber: {
      color: theme.palette.text.primary,
      typography: theme.typography.title_bold,
      textAlign: "center",
      marginTop: theme.spacing(-4.25),
      height: 45,
      letterSpacing: "0.1em",
    },
    currentStationTextBox: {
      padding: theme.spacing(1, 0),
    },
    currentStationText: {
      color: theme.palette.text.primary,
      typography: theme.typography.body1_semibold,
    },
    queueFooterBox: {
      display: "inline-block",
      width: "100%",
    },
    queueFooterBoxSpace: {
      width: "50%",
      float: "left",
      margin: theme.spacing(1, 0),
      minHeight: theme.spacing(6),
    },
    queueStatusBox: {
      width: "100%",
      margin: theme.spacing(1, 0, 2),
    },
    contentTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.body1_semibold,
    },
    contentText: {
      color: theme.palette.text.primary,
      typography: theme.typography.body1_semibold,
    },
    queueStatusText: {
      color: colorTone,
      typography: theme.typography.body1_semibold,
    },
    messageText: {
      color: theme.palette.error.main,
      typography: theme.typography.caption1_regular,
      paddingBottom: theme.spacing(2),
    },
    queueDetailArrow: {
      display: "flex",
      alignItems: "center",
    },

    vcpInstitutionNameBox: {
      textAlign: "left",
    },
    cardDivider: {
      borderTop: `1px solid ${theme.palette.grey[100]}`,
    },
    cardLink: {
      color: theme.palette.primary.main,
      typography: theme.typography.body2_semibold,
      margin: theme.spacing(2, 0),
      display: "flex",
      alignItems: "center",
    },
    cardLinkIcon: {
      paddingRight: theme.spacing(1),
    },
    topButtonLayout: {
      marginBottom: theme.spacing(2),
    },
    button: {
      width: "100%",
      ...(isIHADisabled && theme.IHADisabled?.disabled),
    },
    patientQueueNumber: {
      typography: theme.typography.header3_semibold,
    },
    statusContainer: { float: "left" },

    orderMedicationContainer: {
      float: "right",
    },
    infoBox: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: size.FONTSIZE_14,
    },
  } satisfies SxObjType;
};

export { sxStyles };
