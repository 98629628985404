import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    formSameLine: {
      color: theme.palette.grey[400],
      display: "flex",
      flexDirection: "row",
    },
    questionNo: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    questionBody: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      marginBottom: theme.spacing(1.5),
    },
    fullWidth: {
      width: "100%",
    },

    infoBox: {
      border: `1px solid ${theme.palette.primary.main}`,
      background: theme.palette.secondary.main,
      padding: theme.spacing(0.5),
      display: "flex",
      lineHeight: theme.typography.body1.lineHeight,
      borderRadius: theme.spacing(0.5),
      margin: theme.spacing(2, 0),
      "& :nth-child(3)": {
        clear: "both",
      },
    },
    infoText: {
      color: theme.palette.grey[400],
      fontSize: size.FONTSIZE_12,
      paddingLeft: theme.spacing(1),
      width: "100%",
    },

    radioButtons: {
      maxWidth: theme.spacing(34),
      marginLeft: theme.spacing(1),
    },
  } satisfies SxObjType;
};

export { sxStyles };
