import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = (disabled: boolean) => {
  return {
    button: {
      typography: theme.typography.body2_semibold,
      padding: 0,
      height: "auto",
      textAlign: "start",
      alignItems: "start",
      justifyContent: "start",
      // lineHeight: "normal",
      minWidth: "unset",
      "& .MuiButton-startIcon": {
        color: !disabled ? theme.palette.primary.main : theme.palette.grey[100],
      },
      "& .MuiButton-endIcon": {
        color: !disabled ? theme.palette.primary.main : theme.palette.grey[100],
      },
    },
    requiredText: {
      marginTop: theme.spacing(0.5),
      color: theme.palette.error.main,
      typography: theme.typography.caption1_regular,
    },
  } satisfies SxObjType;
};

export { sxStyles };
