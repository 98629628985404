import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import { MedicationRefillPrescriptionSummaryDetailProps } from "./MedicationRefillPrescriptionSummaryDetail.types";
import { sxStyles } from "./MedicationRefillPrescriptionSummaryDetail.styles";
import ImageModal from "lib/components/modals/ImageModal/ImageModal";

// Inline image encoding scheme prefix (required by <img src="..." /> components)
const INLINE_IMAGE_ENCODING_SCHEME_PREFIX = "data:image/jpeg;base64,";

const MedicationRefillPrescriptionSummaryDetail = ({
  files,
}: MedicationRefillPrescriptionSummaryDetailProps) => {
  const classes = sxStyles();

  const [open, setOpen] = useState(false);
  const [fileDataList, setFileDataList] = useState<string[]>([]);
  const [startAt, setStartAt] = useState(0);
  const [title, setTitle] = useState<string | null>(null);
  const [footerMessage, setFooterMessage] = useState<string[]>([]);

  return (
    <>
      <Typography sx={classes.title}>Medication Prescription</Typography>
      {files.length !== 0 && (
        <Box display="flex" flexDirection="column">
          {files.map((file, index) => (
            <Box key={index} sx={classes.prescriptionPhotoPairBox}>
              <Typography sx={classes.photoPairTitle}>
                Prescription Sheet {index + 1}
              </Typography>
              <Box display="flex">
                {file.front.fileData && (
                  <Box
                    sx={classes.prescriptionPhotoFrame}
                    onClick={() => {
                      if (file.front.fileData && file.back.fileData) {
                        setFileDataList([
                          file.front.fileData,
                          file.back.fileData,
                        ]);
                        setFooterMessage(["Front Page", "Back Page"]);
                      } else {
                        setFileDataList([file.front.fileData || ""]);
                        setFooterMessage(["Front Page"]);
                      }
                      setTitle("Prescription Sheet " + (index + 1));
                      setStartAt(0);
                      setOpen(true);
                    }}
                  >
                    <Box
                      component="img"
                      src={
                        INLINE_IMAGE_ENCODING_SCHEME_PREFIX +
                        file.front.fileData
                      }
                      alt="front page"
                      sx={classes.photo}
                    />
                    <Typography sx={classes.photoCaption}>
                      Front Page
                    </Typography>
                  </Box>
                )}
                {file.back.fileData && (
                  <Box
                    sx={classes.prescriptionPhotoFrame}
                    onClick={() => {
                      if (file.front.fileData && file.back.fileData) {
                        setFileDataList([
                          file.front.fileData,
                          file.back.fileData,
                        ]);
                        setFooterMessage(["Front Page", "Back Page"]);
                        setStartAt(1);
                      } else {
                        setFileDataList([file.back.fileData || ""]);
                        setFooterMessage(["Back Page"]);
                        setStartAt(0);
                      }
                      setTitle("Prescription Sheet " + (index + 1));
                      setOpen(true);
                    }}
                  >
                    <Box
                      component="img"
                      sx={classes.photo}
                      src={
                        INLINE_IMAGE_ENCODING_SCHEME_PREFIX + file.back.fileData
                      }
                      alt="back page"
                    />
                    <Typography sx={classes.photoCaption}>Back Page</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      )}
      <ImageModal
        fileDataList={fileDataList}
        startAt={startAt}
        title={title}
        footerMessage={footerMessage}
        open={open}
        onClose={() => {
          setFileDataList([]);
          setTitle(null);
          setFooterMessage([]);
          setStartAt(0);
          setOpen(false);
        }}
      ></ImageModal>
    </>
  );
};

export default MedicationRefillPrescriptionSummaryDetail;
