import { Theme } from "@mui/material";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = (theme: Theme) => {
  return {
    loadingContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      mt: 4,
    },
    noticeContainer: {
      pt: 1,
      pl: 2,
      pr: 2,
    },
  } satisfies SxObjType;
};
export { sxStyles };
