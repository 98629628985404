import { ViewMN } from "api/mrrp/CreateMedicalReport/createMedicalReport.fromApi.types";
import { ViewFormLabel } from "api/mrrp/GetAllByPlatform/getAllByPlatform.fromApi.types";
import { ViewHospital } from "api/mrrp/GetHospital/getHospital.fromApi.types";
import { ViewPaymentStatusInfo } from "api/mrrp/GetPaymentStatusInfo/getPaymentStatusInfo.fromApi.types";
import { ViewReportHistory } from "api/mrrp/GetReportHistory/getReportHistory.fromApi.types";
import {
  ViewDepartment,
  ViewMOD,
  ViewPatientRelationship,
  ViewPurpose,
} from "api/mrrp/GetReportOptions/getReportOptions.fromApi.types";
import { ViewSubmitPayment } from "api/mrrp/SubmitPayment/submitPayment.fromApi.types";

export interface MrrpState {
  landing: MrrpLandingState;

  hospital: MrrpHospitalState;

  paymentMode: PaymentMode;

  particulars: MrrpParticularsState;

  reportDetail: MrrpReportDetailState;

  collection: MrrpCollectionState;

  formLabel: MrrpFormLabelState;

  // All past reports, include index of selected past report history for viewing (if available)
  reportHistory: MrrpReportHistoryState;

  // Some options / dropdowns used during making new request flow
  reportOptions: MrrpReportOptions;

  payment: MrrpPayment;

  // true if receipt page should show as "check status" button, false is should show receipt content
  receiptPageLanding: boolean;

  mrrpPersist: MrrpPersist;

  // TODO: optimize to use store all API status
  apiStatus: MrrpApiStatus;

  appRating: boolean;
}

// Payment mode available to the user, based on selected hospital
// C for Credit, D for Debit, B for both
// since this value is from api and will return to api, won't need force type check to it
export type PaymentMode = string | null;

export enum ApiEnum {
  SubmitPayment,
  GetPaymentStatusInfo,
}

export interface MrrpApiStatus {
  [ApiEnum.SubmitPayment]: singleApiStatus;
  [ApiEnum.GetPaymentStatusInfo]: singleApiStatus;
}

// TODO: This interface can be applied to hospital, reportHistory, reportOptions for optimization
export interface singleApiStatus {
  isLoading: boolean | null; // true if API call is on going
  hasErrored: boolean | null; // False if the API call is sucessful, true otherwise
  errorMessage: string | null;
}

export interface MrrpLandingState {
  expandedPastReportListFlag: boolean;
}

export interface MrrpFormLabelState {
  apiStatus: singleApiStatus;
  labels: ViewFormLabel[];
}

// === GetHospital
export interface MrrpHospitalState {
  isLoading: boolean; // true if API call is on going
  hasErrored: boolean | null; // False if the API call is sucessful, true otherwise
  errorMessage: string | null;
  selectedHospitalCode: string | null; // Code for the selected Hospital for making new request
  selectedHospitalName: string | null; // Name for the selected Hospital for making new request
  allHospitals: ViewHospital[];
}

export interface MrrpParticularsState {
  patientName: string | null;
  patientNric: string | null;
  requestorName: string | null;
  relationshipCodeWithPatient: string | null;
  relationshipWithPatient: string | null;
  contactNumber: string | null;
  alternativeNumber: string | null;
  email: string | null;
}

export interface MrrpReportDetailState {
  visitDate: string | null;
  clinicalDepartmentCode: string | null;
  clinicalDepartment: string | null;
  purposeCodeOfApplication: string | null;
  purposeOfApplication: string | null;
  reportTypeCode: string | null;
  reportType: string | null;
  reportDescription: string | null;
  reportFees: number | null;
  reportFileName: string | null;
  reportFileByte: Uint8Array | null;
  // A string, containing the media type(MIME) indicating the type of the file, for example "application/pdf" for PDF
  reportFileMIMEType: string | null;
}

export interface MrrpCollectionState {
  deliveryModeCode: string | null;
  deliveryMode: string | null;
  postalCode: string | null;
  floorAndUnit: string | null;
  blockNo: string | null;
  address: string | null;
  errorPostalCode: boolean;
}

// === GetReportHistory
export interface MrrpReportHistoryState {
  isLoading: boolean; // true if API call is on going
  hasErrored: boolean | null; // False if the API call is sucessful, true otherwise
  errorMessage: string | null;
  selectedPastReportIndex: number | null; // index of selected past report for viewing
  allPastReports: ViewReportHistory[];
}

// === GetReportOptions
export interface MrrpReportOptions {
  isLoading: boolean; // true if API call is on going
  hasErrored: boolean | null; // False if the API call is sucessful, true otherwise
  errorMessage: string | null;
  patientRelationships: ViewPatientRelationship[];
  departments: ViewDepartment[];
  purposes: ViewPurpose[];
  deliveryModes: ViewMOD[];
}

// === SubmitPayment, GetPaymentStatusInfo
export interface MrrpPayment {
  createMedicalReport: ViewMN;
  submitPayment: ViewSubmitPayment;
  paymentStatusInfo: ViewPaymentStatusInfo;
}

// ==== persist for GetPaymentStatusInfo
export interface MrrpPersist {
  paymentToken: string | null;
  reportNo: string | null;
  reportTypeLabel: string | null;
  reportType: string | null;
  memberIdentifier: string | null;
  emailAddress: string | null;
  selectedPaymentMode: string | null;
}
