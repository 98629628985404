import { useState, useEffect } from "react";
import {
  getAvailableSlots,
  getAvailableSlotsRequest,
} from "api/appointment/GetAvailableSlots/getAvailableSlots";
import {
  AvailableBundledSlot,
  AvailableSlot,
} from "api/appointment/GetAvailableSlots/getAvailableSlots.toUi.types";
import {
  mapApiBundledLinkedAppointmentsDataToProps,
  mapApiDataToProps,
} from "../../../api/appointment/GetAvailableSlots/mappers/mapper";
import { AxiosError } from "axios";

/**
 * A custom hook that fetches patient's Available Slots data from
 * the remote API and returns the appropriate data payload & loading
 * state of that operation.
 *
 * @param {boolean} pageFlag helper flag to trigger the hook
 * @param {boolean} shouldGetAvailableSlots flag to determine if to call API getAvailableSlots
 * @param {number} paginationIndex
 *   Index of the page of available slots to query, from the remote API.
 *   While this index is not used in the actual API call, it's index represents the no. of times so far
 *   the user has requested for a new page of data.
 * @param {Date | null} date  The date to query available slots for, from the remote API.
 * @param {string | null} appointmentId  ID of an existing  appointment, if any.
 * @param {number | null} serviceTypeId  This service's Type ID
 * @param {string | nul} targetSystem  Source system from which these slots are from
 * @param {string | nul} departmentCode  Department code of this service or this appointment to reschedule for
 * @param {string | null} endDate Maximum date which the user can choose for this appointment
 * @param {boolean} isDateChanged Indicate if user choose Date from Datepicker. True if user selects Date from Datepicker, false if users clicks at 'Show more timeslots'
 *
 * @returns {[AvailableSlot[], boolean, boolean } null]}
 *   0 - Data mapped into AvailableSlot[]
 *
 *   1 - True if the data fetch is complete, false otherwise.
 *
 *   2 - True if the data fetch completed with errors, false otherwise.
 *
 *   3 - True if the data fetch completed with errors, false otherwise.
 *
 *   4 - True if there are still more available slots on the remote server to fetch, false otherwise.
 *       Null is returned when the API call has not been executed yet.
 */

interface useGetAvailableSlotsRequest extends getAvailableSlotsRequest {
  shouldGetAvailableSlots: boolean;
  isDateChanged: boolean;
  pageFlag: boolean;
  paginationIndex: number;
  isBundledLinkedAppointments?: boolean;
  answerToUrtiTriage?: boolean;
  isHsgSubsequent?: boolean | null;
}

interface useGetAvailableSlotsResponse {
  availableSlots: AvailableSlot[];
  availableBundledSlots: AvailableBundledSlot[][];
  isLoading: boolean;
  hasErrored: boolean | null;
  errorMessage: string | null;
  isLiveChatEnabled: boolean;
  hasMoreSlots: boolean | null;
  noSlotMessage: string | null;
}

const useGetAvailableSlots = ({
  pageFlag,
  isNewAppointment,
  shouldGetAvailableSlots,
  memberIdentifier,
  institutionCode,
  slotIdList,
  startDate,
  endDate,
  visitTypeId,
  isDateChanged,
  paginationIndex,
  isBundledLinkedAppointments,
  answerToUrtiTriage,
  isHsgSubsequent,
}: useGetAvailableSlotsRequest): useGetAvailableSlotsResponse => {
  const [nextPageRequestData, setNextPageRequestData] = useState<string | null>(
    null,
  );
  const [availableSlots, setAvailableSlot] = useState<AvailableSlot[]>([]);
  const [availableBundledSlots, setAvailableBundledSlot] = useState<
    AvailableBundledSlot[][]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLiveChatEnabled, setIsLiveChatEnabled] = useState<boolean>(false);
  const [hasMoreSlots, setHasMoreSlots] = useState<boolean | null>(null);
  const [noSlotMessage, setNoSlotMessage] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        if (shouldGetAvailableSlots) {
          // set the time to 00:00:00.000 of the current timezone so that all available time slots on the selected day are returned
          // this is to avoid timeslots only after the current time are returned
          const dateParam = startDate || new Date();
          dateParam.setHours(0, 0, 0, 0);

          const responseData = await getAvailableSlots({
            memberIdentifier,
            isNewAppointment,
            institutionCode,
            nextPageRequestData: isDateChanged ? null : nextPageRequestData,
            slotIdList,
            startDate,
            endDate,
            visitTypeId,
            answerToUrtiTriage,
            isHsgSubsequent,
          });

          // --- Pagination control
          // keep track of the metadata to fetch the next page of records
          const nextPageRequestDataFromApi = responseData.NextPageRequestData;

          // keep track of whether there are more pages from API, as we need this in the next API call
          // (if user chooses to see more slots). Eventually, this value gets set to NULL when no more
          // calls are to be made.
          setNextPageRequestData(nextPageRequestDataFromApi);

          // indicates whether the API allows for more slots to be retrieved
          setHasMoreSlots(apiHasMoreSlots(nextPageRequestDataFromApi));
          setIsLiveChatEnabled(responseData.IsLiveChatEnabled);

          if (!isBundledLinkedAppointments) {
            // --- Retrieve data from API & Process
            // every API invocation returns just the additional slots
            const availableSlotList = mapApiDataToProps(responseData);
            setHasErrored(false);

            if (!isDateChanged) {
              // same date's worth of slots which we can extend the list with
              setAvailableSlot(availableSlots.concat(availableSlotList));
            } else {
              // note: this branch is currently not coverable by unit test
              // completely replace the slots if a new date was chosen
              setAvailableSlot(availableSlotList);
            }
          } else {
            // Maps for Bundled Linked Appointments
            // --- Retrieve data from API & Process
            // every API invocation returns just the additional slots
            const availableBundledSlotList =
              mapApiBundledLinkedAppointmentsDataToProps(responseData);
            setHasErrored(false);

            if (!isDateChanged) {
              // same date's worth of slots which we can extend the list with
              setAvailableBundledSlot(
                availableBundledSlots.concat(availableBundledSlotList),
              );
            } else {
              // note: this branch is currently not coverable by unit test
              // completely replace the slots if a new date was chosen
              setAvailableBundledSlot(availableBundledSlotList);
            }
          }

          // Set display message from API if no slots are available
          setNoSlotMessage(responseData.DisplayMessage);
        }
      } catch (error) {
        setAvailableSlot([]);
        setAvailableBundledSlot([]);
        setHasErrored(true);
        if (error instanceof AxiosError) {
          setErrorMessage(error.response?.data.Message);
          setIsLiveChatEnabled(error.response?.data.IsLiveChatEnabled);
        }
      } finally {
        setIsLoading(false);
      }
    })();
    // disable eslint rule to listen to other dependencies, as we are sure that the
    // only few dependencies we need to listen to for changes are these.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationIndex, startDate, pageFlag]);

  return {
    availableSlots,
    availableBundledSlots,
    isLoading,
    hasErrored,
    errorMessage,
    isLiveChatEnabled,
    hasMoreSlots,
    noSlotMessage,
  };
};

/**
 * Determines if the API has more slots available to be fetched.
 *
 * @param {string | null} nextPageRequestDataFromApi  A metadata payload, understood by the API,
 *   where if set to NULL means that no more additional pages can be requested (end of pagination).
 */
const apiHasMoreSlots = (nextPageRequestDataFromApi: string | null) => {
  return (
    nextPageRequestDataFromApi !== "" && nextPageRequestDataFromApi !== null
  );
};

export { useGetAvailableSlots };
