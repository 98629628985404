import React from "react";
import CollectionContainer from "../../common/collection/Collection/CollectionContainer";
import { Box } from "@mui/material";

//may need to add access for requestType, passing as props or getting from redux
//so that this shared page can load the required data according to requestType
const EditCollectionPage = () => {
  return (
    <Box mt={-9}>
      <CollectionContainer isEditablePage={true} />
    </Box>
  );
};

export default EditCollectionPage;
