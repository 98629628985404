import { PMState } from "./pm.types";

export const initialState: PMState = {
  systemSettings: {
    isLoading: false, // True if the fetch is still ongoing, false if it has stopped
    hasErrored: false, // True if the fetch threw an error, false otherwise
    errorMessage: null,
    value: {
      disclaimers: {
        comparePage: {
          title: null,
          description: null,
        },
        personalDetails: {
          title: null,
          description: null,
        },
        imptToNote: {
          title: null,
          description: null,
        },
        verifyYourParticulars: {
          title: null,
          description: null,
        },
        upToDate: {
          title: null,
          description: null,
        },
        unsyncParticulars: {
          title: null,
          description: null,
        },
        meansTesting: {
          title: null,
          description: null,
        },
        emailAddress: {
          title: null,
          description: null,
        },
        unsavedChanges: {
          title: null,
          description: null,
        },
        spotAnyIssues: {
          title: null,
          description: null,
        },
        syncYourParticulars: {
          title: null,
          description: null,
        },
        preferredDateDisclaimer: {
          title: null,
          description: null,
        },
      },
      errorMessages: {
        syncParticularsFailed: {
          title: null,
          description: null,
        },
        noRecordsFound: {
          title: null,
          description: null,
        },
      },
      successMessages: {
        syncParticularsSuccess: {
          title: null,
          description: null,
        },
      },
    },
  },

  epicProfile: {
    isLoading: false, // True if the fetch is still ongoing, false if it has stopped
    hasErrored: false, // True if the fetch threw an error, false otherwise
    errorMessage: null,
    hasFetchedEpicProfile: false,
    hasEpicProfile: false,
    value: {
      Name: null,
      Nric: null,
      Title: null,
      Sex: null,
      DateOfBirth: null,
      Nationality: null,
      NationalityCode: null,
      Race: null,
      RaceCode: null,
      DocumentTypeCode: null,
      DocumentTypeDisplay: null,
      DocumentTypeExpiry: null,
      RegisteredPostalCode: null,
      RegisteredStreetName: null,
      RegisteredBlockNumber: null,
      RegisteredUnitNumber: null,
      RegisteredFloorNumber: null,
      PreferredPostalCode: null,
      PreferredStreetName: null,
      PreferredBlockNumber: null,
      PreferredUnitNumber: null,
      PreferredFloorNumber: null,
      MailingAddressExpiryDate: null,
      MailingAddressStartDate: null,
      ContactNumber: null,
      SMSContactNumber: null,
      Email: null,
      HomeNumber: null,
      WorkNumber: null,
      MismatchStatus: null,
      PwrFlag: null,
      UserId: null,
    },
    oldValue: {
      Name: null,
      Nric: null,
      Title: null,
      Sex: null,
      DateOfBirth: null,
      Nationality: null,
      NationalityCode: null,
      Race: null,
      RaceCode: null,
      DocumentTypeCode: null,
      DocumentTypeDisplay: null,
      DocumentTypeExpiry: null,
      RegisteredPostalCode: null,
      RegisteredStreetName: null,
      RegisteredBlockNumber: null,
      RegisteredUnitNumber: null,
      RegisteredFloorNumber: null,
      PreferredPostalCode: null,
      PreferredStreetName: null,
      PreferredBlockNumber: null,
      PreferredUnitNumber: null,
      PreferredFloorNumber: null,
      MailingAddressExpiryDate: null,
      MailingAddressStartDate: null,
      ContactNumber: null,
      SMSContactNumber: null,
      Email: null,
      HomeNumber: null,
      WorkNumber: null,
      MismatchStatus: null,
      PwrFlag: null,
      UserId: null,
    },
  },

  myInfoProfile: {
    isLoading: false, // True if the fetch is still ongoing, false if it has stopped
    hasErrored: false, // True if the fetch threw an error, false otherwise
    errorMessage: null,
    value: {
      Nric: null,
      Name: null,
      Sex: null,
      DateOfBirth: null,
      Nationality: null,
      NationalityCode: null,
      Race: null,
      RaceCode: null,
      ResidentialStatus: null,
      ResidentialStatusCode: null,
      PassType: null,
      PassTypeCode: null,
      PassExpiryDate: null,
      RegisteredPostalCode: null,
      RegisteredStreetName: null,
      RegisteredBlockNumber: null,
      RegisteredUnitNumber: null,
      RegisteredFloorNumber: null,
    },
    codeVerifier: null,
  },
  general: {
    errors: {
      hasPostalCodeErrored: false,
      hasStreetErrored: false,
      hasBlockErrored: false,
      hasDateErrored: false,
      hasMobileErrored: false,
      hasSMSMobileErrored: false,
      hasEmailErrored: false,
      hasHomeNumberErrored: false,
      hasWorkNumberErrored: false,
      hasNRICErrored: false,
    },
    bypassEpicProfile: false,
  },
};
