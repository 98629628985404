import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  const DEFAULT_MATERIAL_UI_APPBAR_HEIGHT = "56px";
  const IOS_UI_APPBAR_HEIGHT = "44px";
  return {
    defaultBody: {
      height: `calc(100vh - ${DEFAULT_MATERIAL_UI_APPBAR_HEIGHT})`,
      marginTop: DEFAULT_MATERIAL_UI_APPBAR_HEIGHT,
    },
    iOSBody: {
      height: `calc(100vh - ${IOS_UI_APPBAR_HEIGHT})`,
      marginTop: IOS_UI_APPBAR_HEIGHT,
    },
    noNav: {
      height: "100vh",
    },
  } satisfies SxObjType;
};

export { sxStyles };
