import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";

import { RootState } from "lib/redux/root/redux.types";
import { closeBottomNotification } from "lib/redux/notifications";
import ToastSnackbar from "./ToastSnackbar";

/**
 * App-wide Toast Snackbar Container that can be mounted anywhere in the application.
 *
 * Invoke Redux dispatch actions to emit this toast and close this toast.
 *
 * You can emit / close this toast anywhere within the application, as long as the
 * appropriate Redux actions are used.
 */

const mapStateToProps = (state: RootState) => {
  return {
    open: state.notifications.bottom.open,
    message: state.notifications.bottom.message,
    icon: state.notifications.bottom.icon,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onClose: () => {
      dispatch(closeBottomNotification());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastSnackbar);
