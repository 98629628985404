/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactElement, ReactNode } from "react";
import { FlowAction } from "lib/redux/navigation/navigation.types";
import { PATHS } from "lib/routing/paths/paths";
import { replaceStringWithReactLinkElement } from "lib/util/ReactComponentUtil/replaceStringWithReactElement/ReplaceStringWithReactLinkElement";
import RedirectLinkContainer from "lib/components/error/ErrorDisplay/RedirectLink/RedirectLinkContainer";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import {
  mobileNavigate,
  navigateToExternalInMobileBrowser,
} from "lib/routing/navigate/navigate";
import { Link, Typography } from "@mui/material";
import { ACTIONS } from "lib/routing";

/**
 * Constants
 */
const UI_LINK_PLACEHOLDER = "[REQUESTFORMLINK]";

/**
 * From the current action of the user, this function resolves it to the
 * path to navigate to if a UI_LINK_PLACEHOLDER is found in the error message.
 *
 * For example, in reschedule appointment flow, user is automatically redirected to the reschedule appointment form,
 * as it's the most relevant form to redirect them to.
 *
 * @param {FlowAction | null} currentFlowAction  Current action the user is taking
 *
 * @returns {string}  Path to navigate to, default being the Appointments Path
 */
const getPathToNavigateTo = (currentFlowAction: FlowAction | null) => {
  switch (currentFlowAction) {
    case "APPOINTMENT_CREATE":
      return PATHS.APPOINTMENT_CREATE_FORM.path;
    case "APPOINTMENT_RESCHEDULE":
      return PATHS.APPOINTMENT_RESCHEDULE_FORM.path;
    case "APPOINTMENT_CANCEL":
      return PATHS.APPOINTMENT_CANCEL_FORM.path;
    default:
      // default in case a UI REQUESTFORMLINK placeholder is found in the error message
      // safely re-direct the user to the start of the flow
      return PATHS.APPOINTMENT_MOBILE.path;
  }
};

/**
 * Renders the error message as a React component with any occurrences of UI_LINK_PLACEHOLDER
 * replaced with a valid React Route Link component, so that link navigations work.
 *
 * @param {string} errorMessage  Error message to be checked
 * @param {FlowAction} currentFlowAction  Current action the user is taking that allows us to redirect them to the relevant places
 * @param {boolean} isLiveChatEnabled
 *
 * @returns Mountable error message components list with UI_LINK_PLACEHOLDER replaced with React Router links
 */
const renderErrorMessage = (
  errorMessage: string,
  currentFlowAction: FlowAction | null,
  isLiveChatEnabled?: boolean,
): ReactElement[] => {
  let returnComponent: ReactNode[] = [];
  let message = errorMessage;
  let requestFormLinkText = null;
  let externalLinkSection = "";
  let externalLinkAddr = "";
  let externalLinkText = null;

  // first case: errorMessage uses UI_LINK_PLACEHOLDER to represent the whole anchor tag. Refer to replaceStringWithReactLinkElement for message example
  // second case: errorMessage uses UI_LINK_PLACEHOLDER to represent only the url inside anchor tag e.g. Please <a href=\'[REQUESTFORMLINK]'\ class='link'>submit a request online</a>.
  // for second case, we use regex to get the text inside the anchor tag e.g. submit a request online
  const requestFormLinkReg =
    /<a href\s*=\s*\\*['"]\[REQUESTFORMLINK\]\\*['"][^>]*>([^<]+)<\/a>/g;

  // requestFormLinkRegArr has the matched text as the first item, and then one item for each parenthetical capture group of the matched text.
  var requestFormLinkRegArr = requestFormLinkReg.exec(message);
  //check if it is second case
  if (requestFormLinkRegArr) {
    requestFormLinkText = requestFormLinkRegArr[1]; //get the text inside anchor tag with capture group
    message = message.replace(requestFormLinkReg, UI_LINK_PLACEHOLDER); // convert second case to first case
  }

  const onClickLiveChatButton = () => {
    mobileNavigate(
      ACTIONS.LIVECHAT_PAGE_INTERCEPTION(PATHS.APPOINTMENT_MOBILE.path),
    );
  };

  const navigateTo = getPathToNavigateTo(currentFlowAction);
  returnComponent = !isLiveChatEnabled
    ? replaceStringWithReactLinkElement(
        message,
        UI_LINK_PLACEHOLDER,
        <RedirectLinkContainer
          linkTo={navigateTo}
          linkText={requestFormLinkText}
        />,
      )
    : replaceStringWithReactLinkElement(
        message,
        UI_LINK_PLACEHOLDER,
        <Typography
          component={"span"}
          onClick={() => {
            onClickLiveChatButton();
          }}
          sx={{
            fontWeight: "inherit",
            fontSize: "inherit",
            color: "primary.main",
          }}
        >
          {requestFormLinkText}
        </Typography>,
      );

  // TODO: below implementation can only replace first occurrence of external link. Plan to make it able to replace all occurrences.
  // The method mentioned in following link appears to has bug, so can't properly replace multiple links as it promised:
  // https://www.npmjs.com/package/react-string-replace#multiple-replacements-on-a-single-string

  // Further replace external link with React element that can open the link in mobile browser
  // e.g. Handle Consent form is available for download at <a href = 'https://www.nup.com.sg' class='link' target='_blank'>https://www.nup.com.sg</a> <br />
  var externalLinkReg = /<a href\s*=\s*['"]([^'"]*)['"][^>]*>([^<]+)<\/a>/g;
  var externalLinkRegArr = externalLinkReg.exec(message);
  if (externalLinkRegArr && externalLinkRegArr.length > 2) {
    externalLinkSection = externalLinkRegArr[0]; // get the matched external link
    externalLinkAddr = externalLinkRegArr[1]; //get the link inside anchor tag
    externalLinkText = externalLinkRegArr[2]; //get the text inside anchor tag with capture group

    // should navigate to external link in mobile browser, but keep the page unchanged (with placeholder href, which returns to the top of the page)
    returnComponent = replaceStringWithReactLinkElement(
      returnComponent,
      externalLinkSection,
      <>
        {/* special handling for telephone href */}
        {externalLinkAddr.slice(0, 3) === "tel" ? (
          <Link href={externalLinkAddr}>{externalLinkText}</Link>
        ) : (
          <Typography
            component={"span"}
            sx={{
              fontWeight: "inherit",
              fontSize: "inherit",
            }}
            onClick={() => navigateToExternalInMobileBrowser(externalLinkAddr)}
          >
            <Link href="#">{externalLinkText}</Link>
          </Typography>
        )}
      </>,
    );
  }

  return returnComponent.map((element, index): ReactElement => {
    // in case the element contains raw HTML nodes that need to be rendered
    return typeof element === "string"
      ? MapRawStringToReactElement(element)
      : (element as ReactElement);
  });
};

export { renderErrorMessage, getPathToNavigateTo };
