import { Box, IconButton } from "@mui/material";
import { sxStyles } from "./SummarySectionFrame.styles";
import { SummarySectionFrameProps } from "./SummarySectionFrame.types";
import IMAGES from "lib/assets/images";

const SummarySectionFrame = ({
  editable = false,
  children,
  onClickEdit,
  editing,
}: SummarySectionFrameProps) => {
  const classes = sxStyles();
  return (
    <Box
      display="flex"
      position="relative"
      flexDirection="column"
      sx={classes.sectionRegion}
    >
      {editable && !editing && (
        <Box position="absolute" top={0} right={0}>
          <IconButton sx={classes.editIconButton} onClick={onClickEdit}>
            <Box component={"img"} src={IMAGES.general.EditIcon} alt="Edit" />
          </IconButton>
        </Box>
      )}

      <Box>{children}</Box>
    </Box>
  );
};

export default SummarySectionFrame;
