import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  NavigationState,
  FlowAction,
  MobileIntegrationNavigationState,
} from "./navigation.types";
import { MessageActionType } from "lib/routing/messageChannel/messageActions.types";

// Initial state occurring when page without customised back navigation is landing
export const initialState: NavigationState = {
  customisedBack: false,
  customisedClose: false,
  shouldShowBackOnEntry: false,
  shouldShowBackOnEntryHijack: null,
  currentFlowAction: null,
  navBarDisplayName: null,
  fromInitialProfilePath: null,
  mobileMessage: null,
  isAcknowledged: false,
  messageToSend: "",
};

/**
 * The slice   reducer for the part of state that represents the navigation information
 */
const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    initializeNavigationStateFromMobileIntegration(
      state,
      action: PayloadAction<MobileIntegrationNavigationState>,
    ) {
      state.shouldShowBackOnEntry = action.payload.shouldShowBackOnEntry;
    },

    setShouldShowBackOnEntry(state, action: PayloadAction<boolean>) {
      state.shouldShowBackOnEntry = action.payload;
    },

    setshouldShowBackOnEntryHijack(
      state,
      action: PayloadAction<boolean | null>,
    ) {
      state.shouldShowBackOnEntryHijack = action.payload;
    },
    //set if the page has customised back navigation
    setCustomisedBack(state, action: PayloadAction<boolean>) {
      state.customisedBack = action.payload;
    },
    //set if the page has customised back navigation
    setCustomisedClose(state, action: PayloadAction<boolean>) {
      state.customisedClose = action.payload;
    },
    //set the current user action flow
    setCurrentFlowAction(state, action: PayloadAction<FlowAction>) {
      state.currentFlowAction = action.payload;
    },
    // set helper flag for profile page navigation
    setFromInitialProfile(state, action: PayloadAction<string | null>) {
      state.fromInitialProfilePath = action.payload;
    },
    // set customized navigation bar title
    setNavBarDisplayDisplayName(state, action: PayloadAction<string | null>) {
      state.navBarDisplayName = action.payload;
    },
    setMobileMessage(state, action: PayloadAction<string>) {
      state.mobileMessage = action.payload;
    },
    setIsAcknowledged(state, action: PayloadAction<boolean>) {
      state.isAcknowledged = action.payload;
    },
    setMessageToSend(state, action: PayloadAction<MessageActionType | string>) {
      state.messageToSend = action.payload;
    },
  },
});

export const {
  setCustomisedBack,
  setCustomisedClose,
  initializeNavigationStateFromMobileIntegration,
  setCurrentFlowAction,
  setFromInitialProfile,
  setNavBarDisplayDisplayName,
  setShouldShowBackOnEntry,
  setshouldShowBackOnEntryHijack,
  setMobileMessage,
  setIsAcknowledged,
  setMessageToSend,
} = navigationSlice.actions;

export default navigationSlice.reducer;
