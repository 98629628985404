import { size } from "lib/theme/size";
import { color } from "lib/theme/color";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    menulist: {
      width: "100%",

      "& .MuiOutlinedInput-root": {
        "&.Mui-disabled": {
          backgroundColor: theme.palette.secondary.light,
        },
        "& .MuiSelect-select": {
          marginRight: theme.spacing(1.5),
        },
      },
      "& .MuiFormLabel-root": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        paddingLeft: "2px",
        paddingRight: "2px",
        marginTop: "-4px",
        "&.Mui-disabled": {
          color: theme.palette.grey[600],
        },
      },
    },
    menuIem: {
      color: theme.palette.grey[400],
      fontSize: size.FONTSIZE_16,
      whiteSpace: "normal",
      paddingLeft: theme.spacing(3),
    },
    menuItemContainer: {
      color: theme.palette.grey[400],
      borderRadius: theme.spacing(0.5),

      "& .MuiMenu-list .MuiListSubheader-root": {
        color: theme.palette.primary.main,
        fontSize: size.FONTSIZE_14,
      },
      "& .MuiSelect-icon": {
        right: "0px",
        fontSize: "24px",
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
      },
    },
    subItem: {
      color: theme.palette.grey[400],
      backgroundColor: color.RGBA_246,
      typography: theme.typography.body1_semibold,
      lineHeight: "1.25",
      padding: theme.spacing(1),
      opacity: "0.9",
      position: "static",
      width: "100%",
    },
    errText: {
      color: theme.palette.error.main,
      typography: theme.typography.caption1_regular,
      padding: theme.spacing(0.5, 1.5),
    },
  } satisfies SxObjType;
};

export { sxStyles };
