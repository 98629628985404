/**
 * Obtains the specified key's value from environment variables
 *
 * @param {string} key  Environment variable's key to retrieve the values from
 *
 * @returns {string}  React Web Application's hosted URL
 * @throws {Error}  Throws an error if the environment variable's
 *   value at the specified key does not exist
 */
const getEnvValue = (key: string): string => {
  const result = process.env[key];
  if (result) {
    return result;
  } else {
    throw new Error(
      `Unable to obtain value from process.env['${key}'] from the environment variables.`,
    );
  }
};

/**
 * Checks if a particular process.env variable is true or not.
 *
 * @param key  Key of the string holding this process.env variable
 */
const isTrue = (key: string) => {
  return process.env[key] === "true";
};

export { getEnvValue, isTrue };
