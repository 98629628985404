import { Box, Typography } from "@mui/material";
import { PaidBillCardProps } from "./PaidBillCard.types";
import { sxStyles } from "./PaidBillCard.styles";
import IMAGES from "lib/assets/images";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { ACTIONS } from "lib/routing";
import IconTextButton from "lib/components/buttons/IconTextButton/IconTextButton";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

const PaidBillCard = ({ paidBill, memberIdentifier }: PaidBillCardProps) => {
  const classes = sxStyles();

  const institution = paidBill.InstitutionName;
  const billReferenceNumber = paidBill.InvoiceNumber;

  const downloadPdfForpaidBill = () => {
    mobileNavigate(
      `${ACTIONS.GET_PDF_BILL_ACTION}&BillDate=${paidBill.DischargeDate}&BillingSystem=${paidBill.BillingSystem}&InstitutionCode=${paidBill.InstitutionCode}&DownloadId=${paidBill.DownloadId}&MemberIdentifier=${memberIdentifier}&BillCategory=FullyPaid`,
    );
  };

  let dateTimeObj: Date | null = null;
  let dateTime: string | null;
  if (paidBill.VisitType === "I") {
    dateTime = paidBill.DischargeDate;
    dateTimeObj = getDateFromISOString(dateTime);
  } else if (paidBill.VisitType === "O") {
    dateTime = paidBill.VisitDate;
    dateTimeObj = getDateFromISOString(dateTime);
  } else {
    if (paidBill.DischargeDate) {
      dateTime = paidBill.DischargeDate;
      dateTimeObj = getDateFromISOString(dateTime);
    } else if (paidBill.VisitDate !== null) {
      dateTime = paidBill.VisitDate;
      dateTimeObj = getDateFromISOString(dateTime);
    }
  }

  return (
    <>
      <Box sx={classes.cardDetailWrapper}>
        <Box sx={classes.cardDetails}>
          <Box sx={classes.cardTopLeft}>
            <Typography sx={classes.cardTitle}>{institution}</Typography>
          </Box>
          <Box sx={classes.cardTopRight}>
            <IconTextButton
              icon={IMAGES.general.DownloadIcon}
              label={["Download", "bill"]}
              onClick={async () => {
                logEventToGoogleAnalytics(
                  EVENTS.SELECT_DOWNLOAD_BILL_PAID_BILLS,
                );
                downloadPdfForpaidBill();
              }}
              ariaLabel={"Download bill"}
            />
          </Box>
        </Box>
        <Box sx={classes.cardDetails}>
          <Box sx={classes.cardLeft}>
            <Typography sx={classes.cardLabel}>
              {paidBill.VisitType === "I" ? (
                <span>Discharged on </span>
              ) : paidBill.VisitType === "O" ? (
                <span>Visited on </span>
              ) : paidBill.VisitType !== "I" && paidBill.VisitType !== "O" ? (
                <span>Bill on </span>
              ) : null}

              {dateTimeObj
                ? formatDate(
                    formatDateToISO(dateTimeObj),
                    "dd MMM yyyy",
                  ).toUpperCase()
                : null}
            </Typography>
          </Box>
        </Box>
        <Box sx={classes.cardDetails}>
          <Box sx={classes.cardLeft}>
            <Typography sx={classes.cardLabel}>Date:</Typography>
          </Box>
          <Box sx={classes.cardRight}>
            <Typography sx={classes.cardValue}>
              {dateTimeObj
                ? formatDate(
                    formatDateToISO(dateTimeObj),
                    "dd MMM yyyy",
                  ).toUpperCase()
                : null}
            </Typography>
          </Box>
        </Box>
        <Box sx={classes.cardDetails}>
          <Box sx={classes.cardLeft}>
            <Typography sx={classes.cardLabel}>Bill Reference No:</Typography>
          </Box>
          <Box sx={classes.cardRight}>
            <Typography sx={classes.cardValue}>
              {billReferenceNumber}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PaidBillCard;
