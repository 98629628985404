import { OperatingHr } from "api/medrefill/GetLocations/getLocations.types";

/**
 * Computes pharmacy operating hours and displays the corresponding labels
 * on a best-effort basis.
 */

const CLOSED_LABEL = "Closed";

// currently assumes Monday to Friday have same operating hours
const computeMonToFriLabel = (weekOperatingHours: OperatingHr[] | null) => {
  //get Monday's operating timeslots
  return computeOperatingHrLabel(weekOperatingHours, 1);
};

const computeSatLabel = (weekOperatingHours: OperatingHr[] | null) => {
  //get Saturday's operating timeslots
  return computeOperatingHrLabel(weekOperatingHours, 6);
};

const computeSunPublicHolidayLabel = (
  weekOperatingHours: OperatingHr[] | null,
) => {
  //get Sunday's operating timeslots
  return computeOperatingHrLabel(weekOperatingHours, 0);
};

const computeOperatingHrLabel = (
  weekOperatingHours: OperatingHr[] | null,
  day: number,
) => {
  if (weekOperatingHours) {
    const slots = weekOperatingHours?.find((hrs) => hrs.Day === day)?.Slots;
    if (slots && slots.length > 0) {
      return slots?.join(", ");
    } else {
      return CLOSED_LABEL;
    }
  } else {
    return CLOSED_LABEL;
  }
};

export {
  CLOSED_LABEL,
  computeMonToFriLabel,
  computeSatLabel,
  computeSunPublicHolidayLabel,
};
