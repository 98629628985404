import { Box, Typography, Card } from "@mui/material";
import { sxStyles } from "./ConfirmationDetails.styles";
import { ConfirmationDetailsProps } from "./ConfirmationDetails.types";
import ConfirmationAppointmentCard from "../ConfirmationCard/ConfirmationCard";

const ConfirmationDetails = ({
  slotDateTime,
  institution,
  service,
  location,
  zoneInfo,
  appointmentType,
}: ConfirmationDetailsProps) => {
  const classes = sxStyles();
  return (
    <Box sx={classes.detailsLayout}>
      <Typography sx={classes.titleText}>Appointment Details</Typography>
      <Card sx={classes.root}>
        <ConfirmationAppointmentCard
          slotDateTime={slotDateTime}
          institution={institution}
          service={service}
          location={location}
          zoneInfo={zoneInfo}
        />
      </Card>
    </Box>
  );
};

export default ConfirmationDetails;
