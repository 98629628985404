import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_semibold,
      margin: theme.spacing(1.5, 0.5, 1),
    },
    noRecordBox: {
      textAlign: "center",
      margin: theme.spacing(2, 1),
    },
    noRecordImage: {
      margin: theme.spacing(3, 0),
      "& img": {
        maxWidth: 200,
      },
    },
    noRecordTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_regular,
      margin: theme.spacing(2, 0),
    },

    cardButtons: {
      margin: theme.spacing(2.5, 2),
    },
    fullButton: {
      display: "block",
      width: "100%",
    },

    viewPastRequestTextBox: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(1.5),
    },
    viewAllOutStandingContainer: {
      display: "flex",
      alignItems: "center",
    },
    viewOutStandingText: {
      color: theme.palette.primary.main,
      typography: theme.typography.body2_semibold,
      marginRight: theme.spacing(1),
    },
    arrowRightIcon: {
      width: "22px",
    },
  } satisfies SxObjType;
};

export { sxStyles };
