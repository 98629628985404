import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    formContentVal: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      width: "100%",
    },
  } satisfies SxObjType;
};

export { sxStyles };
