import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";
import { RootState } from "lib/redux/root/redux.types";
import { MedRefillParticularsState } from "ui/medrefill/ducks/medrefill.types";
import { updateParticulars } from "ui/medrefill/ducks";
import Particulars from "./Particulars";

const mapStateToProps = (state: RootState) => {
  const isNormalFlow = state.user.isPatient;
  return {
    //flag to determine if it is normal logged in user flow or caregiver flow
    //if it is false, it means care giver flow in which requestor is different from patient
    //for normal flow, checkbox should be enabled and set as true by default,
    //    checking checkbox makes logged in user profile filled into patient name and nric,
    //    unchecking checkbox makes patient name and nric blank
    //for caregiver flow, checkbox is disabled, patient name and nric are prepopulated and DISABLED
    isNormalFlow: isNormalFlow,

    defaultPatientName: isNormalFlow
      ? state.medRefill.particulars.patientName
      : state.user.patientName,
    defaultPatientNric: isNormalFlow
      ? state.medRefill.particulars.patientNric
      : state.user.patientNric,
    defaultRequesterName: state.user.requestorName,
    defaultRequesterNric: state.user.requestorNric,
    defaultContactNumber: state.medRefill.particulars.contactNumber,
    defaultEmail: state.medRefill.particulars.email,

    //medrefill internal status
    // for normal flow, it can be true or false, for caregiver flow, it is fixed as false
    defaultIsPatient: isNormalFlow
      ? state.medRefill.particulars.isPatient
      : false,

    profileContactNumber: state.user.contact,
    profileEmail: state.user.email,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onSelectParticulars: (particularsState: MedRefillParticularsState) => {
      dispatch(updateParticulars(particularsState));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Particulars);
