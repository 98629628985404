import React from "react";
import { Box, Typography } from "@mui/material";
import { SummaryCardProps } from "./SummaryCard.types";
import { sxStyles } from "./SummaryCard.styles";

const SummaryCard = ({
  patientName,
  patientNRIC,
  institutionName,
  billReferenceNumber,
  amount,
}: SummaryCardProps) => {
  const classes = sxStyles();

  return (
    <Box sx={classes.summarySection}>
      <>
        <Box sx={classes.summaryPanel}>
          <Typography sx={classes.content}>Patient Name</Typography>
          <Typography sx={classes.title}>{patientName || "-"}</Typography>
        </Box>
        <Box sx={classes.summaryPanel}>
          <Typography sx={classes.content}>NRIC</Typography>
          <Typography sx={classes.title}>{patientNRIC || "-"}</Typography>
        </Box>

        <Box sx={classes.summaryPanel}>
          <Typography sx={classes.content}>Bill for</Typography>
          <Typography sx={classes.title}>{institutionName || "-"}</Typography>
        </Box>
        <Box sx={classes.summaryPanel}>
          <Typography sx={classes.content}>Bill Reference No</Typography>
          <Typography sx={classes.title}>
            {billReferenceNumber || "-"}
          </Typography>
        </Box>

        <Box sx={classes.summaryPanel}>
          <Typography sx={classes.content}>Amount</Typography>
          <Typography sx={classes.payValue}>{amount || "-"}</Typography>
        </Box>
      </>
    </Box>
  );
};

export default SummaryCard;
