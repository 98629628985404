import { Box, CardHeader, Skeleton } from "@mui/material";
import IMAGES from "lib/assets/images";
import { PATHS } from "lib/routing";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { useNavigate } from "react-router-dom";
import { sxStyles } from "./UserProfile.styles";
import { UserProfileProps } from "./UserProfile.types";
import IconTextButton from "lib/components/buttons/IconTextButton/IconTextButton";

const UserProfile = ({
  name,
  isLoading,
  onResetPastReportListFlag,
}: UserProfileProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();
  return (
    <Box sx={classes.profileHeader}>
      <CardHeader
        title={
          isLoading ? (
            <Skeleton variant="text" animation="wave" width="60%" />
          ) : name ? (
            name + "'s requested reports"
          ) : null
        }
        sx={classes.cardHeader}
      />

      <Box sx={classes.iconButton}>
        <IconTextButton
          icon={IMAGES.mrrp.NewRequestIcon}
          label={["Make new", "request"]}
          onClick={() => {
            logEventToGoogleAnalytics(EVENTS.SELECT_NEW_MEDICAL_REPORT_REQUEST);
            onResetPastReportListFlag();
            navigate(PATHS.MRRP_NEW_REQUEST_SELECT_HOSPITAL.path);
          }}
          ariaLabel={"Make new request"}
        />
      </Box>
    </Box>
  );
};

export default UserProfile;
