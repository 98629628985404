import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    card: {
      margin: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    noRecordBox: {
      textAlign: "center",
      margin: theme.spacing(2, 1),
    },
    noRecordImage: {
      margin: theme.spacing(3, 0),
      "& img": {
        maxWidth: 200,
      },
    },
    noRecordTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_regular,
      margin: theme.spacing(2, 0),
    },

    cardDetails: {
      minHeight: "100%",
      wordBreak: "break-word",
      display: "flex",
      margin: theme.spacing(1.5, 0, 2),
      alignItems: "center",
    },

    cardLeft: {
      width: "60%",
      marginRight: "2%",
    },
    cardRight: {
      textAlign: "left",
      color: theme.palette.common.black,
      typography: theme.typography.body2_semibold,
      width: "36%",
      marginLeft: "2%",
    },
    cardLink: {
      color: theme.palette.primary.main,
      typography: theme.typography.body2_semibold,
      // paddingBottom: theme.spacing(0.75),
      textAlign: "right",
    },
    cardLabel: {
      color: theme.palette.common.black,
      fontSize: size.FONTSIZE_14,
    },
    loadMoreButton: {
      display: "inline-block",
    },
  } satisfies SxObjType;
};

export { sxStyles };
