import React from "react";
import { Typography } from "@mui/material";
import { PharmacyLabelProps } from "./PharmacyLabel.types";
import { sxStyles } from "./PharmacyLabel.styles";

const PharmacyLabel = ({ children }: PharmacyLabelProps) => {
  const classes = sxStyles();

  return <Typography sx={classes.formContentVal}>{children}</Typography>;
};

export default PharmacyLabel;
