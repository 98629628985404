import { Button, Typography, Box, Link } from "@mui/material";
import { sxStyles } from "./AppointmentCardFooterBtns.styles";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { PATHS } from "lib/routing/paths/paths";
import { AppointmentCardFooterBtnProps } from "./AppointmentCardFooterBtns.types";

const AppointmentCardFooterBtns = ({
  appointment,
  onSelectRescheduleOrCancel,
  onCancel,
  onReschedule,
  onSelectRegister,
  onCancelSystemFlow,
  isCrossInstitution = false,
}: AppointmentCardFooterBtnProps) => {
  const classes = sxStyles();
  const navigate = useNavigate();

  const {
    canReschedule,
    canCancel,
    isMobileRegAvailable,
    actualizeStatus,
    linkedAppointments,
  } = appointment;

  // Helper function to decide if the form link should be displayed
  // As of Aug 2021, JCH is the only institution known that don't have online form flow.
  const shouldDisplayFooter = () => {
    return appointment.institutionCode !== "JCH";
  };

  return (
    <>
      {shouldDisplayFooter() &&
        (!canCancel || !canReschedule) &&
        !isCrossInstitution && (
          <Box sx={classes.footerContainer}>
            {!canCancel && (
              <Link
                component={RouterLink}
                to={PATHS.APPOINTMENT_CANCEL_FORM.path}
                onClick={() => {
                  onCancel();
                  onSelectRescheduleOrCancel(appointment);
                }}
                sx={classes.btnLink}
              >
                {"Cancel"}
              </Link>
            )}

            {!canCancel && !canReschedule && (
              <Typography sx={classes.text}>or</Typography>
            )}

            {!canReschedule && (
              <Link
                component={RouterLink}
                to={PATHS.APPOINTMENT_RESCHEDULE_FORM.path}
                onClick={() => {
                  onReschedule();
                  onSelectRescheduleOrCancel(appointment);
                }}
                sx={classes.btnLink}
              >
                {"Reschedule"}
              </Link>
            )}
          </Box>
        )}

      {(canCancel || canReschedule || isMobileRegAvailable) && (
        <Box sx={classes.cardButtons}>
          {canCancel && !isCrossInstitution && (
            <Button
              variant="outlined"
              sx={classes.button}
              onClick={() => {
                onCancel();
                onSelectRescheduleOrCancel(appointment);
                onCancelSystemFlow();
              }}
            >
              Cancel
            </Button>
          )}

          {canReschedule && !isCrossInstitution && (
            <Button
              variant="outlined"
              sx={classes.button}
              onClick={() => {
                onReschedule();
                onSelectRescheduleOrCancel(appointment);
                if (
                  appointment.linkedAppointments &&
                  appointment.linkedAppointments.length >= 1
                ) {
                  navigate(PATHS.APPOINTMENT_RESCHEDULE_LINKED_ENTRY.path);
                } else {
                  navigate(PATHS.APPOINTMENT_RESCHEDULE_AVAILABLE_SLOTS.path);
                }
              }}
            >
              Reschedule
            </Button>
          )}

          {linkedAppointments && linkedAppointments.length === 0
            ? isMobileRegAvailable &&
              !(
                actualizeStatus === "S" ||
                actualizeStatus === "W" ||
                actualizeStatus === "E"
              ) && (
                <Button
                  variant="contained"
                  sx={classes.button}
                  onClick={onSelectRegister}
                >
                  Register
                </Button>
              )
            : linkedAppointments &&
              linkedAppointments[linkedAppointments.length - 1]
                .appointmentId ===
                linkedAppointments[linkedAppointments.length - 1]
                  .appointmentIdToRegister &&
              linkedAppointments[linkedAppointments.length - 1]
                .isMobileRegAvailable && (
                <Button
                  variant="contained"
                  sx={classes.button}
                  onClick={onSelectRegister}
                >
                  Register
                </Button>
              )}
        </Box>
      )}
    </>
  );
};

export default AppointmentCardFooterBtns;
