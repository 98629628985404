import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { getKioskQuestionnaire } from "api/appointment/GetKioskQuestionnaire/getKioskQuestionnaire";
import { KioskQuestionAndAnswer } from "api/appointment/GetKioskQuestionnaire/getKioskQuestionnaire.toUi";
import { AxiosError } from "axios";

/**
 * A custom hook that fetches list of questions before registering an appointment from
 * the remote API and returns the appropriate data payload & loading state of that operation.
 * 
 * @param {boolean} pageFlag helper flag to trigger the hook
 * @param {boolean} shouldGetKioskQuestionnaire Whether or not to trigger the API call to retrieve kiosk questionnaire
 * @param {string | null} memberIdentifier  Member Identifier of the person we are making this request for
 * @param {string | null} regInstitutionCode  Reg Institution code of the selected appointment
 * @param {string | null} targetSystem  Source system of the selected appointment
 * @param {string | null} visitType Visit Type of the selected appointment

 * @returns {[KioskQuestionAndAnswer[], boolean, boolean | null, string | null]}
 *   0 - Data mapped into DisplayQuestion[]
 *   1 - Hook to set mapped into DisplayQuestion[]
 *   2 - True if the data fetch is complete, false otherwise.
 *   3 - True if the data fetch completed with errors, false otherwise.
 *   4 - Error message returned from API call
 *   5 - Queue Number returned from API call
 */
const useGetKioskQuestionnaire = (
  pageFlag: boolean,
  shouldGetKioskQuestionnaire: boolean,
  memberIdentifier: string | null,
  regInstitutionCode: string | null,
  institutionCode: string | null,
  targetSystem: string | null,
  clinicCode: string | null,
  visitType: string | null,
): [
  KioskQuestionAndAnswer[],
  Dispatch<SetStateAction<KioskQuestionAndAnswer[]>>,
  boolean,
  boolean | null,
  string | null,
  string | null,
] => {
  const [kioskQuestionAnswer, setKioskQuestionAnswer] = useState<
    KioskQuestionAndAnswer[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [queueNumber, setQueueNumber] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        if (shouldGetKioskQuestionnaire) {
          const responseData = await getKioskQuestionnaire(
            memberIdentifier,
            regInstitutionCode,
            institutionCode,
            targetSystem,
            clinicCode,
            visitType,
          );

          const questionList = responseData.Questions.map((q) => {
            return {
              QuestionId: q.QuestionId,
              QuestionText: q.QuestionEnglish,
              QuestionAnswer: null,
            };
          });

          // set list of questions once API call that retrieves the list of kiosk questions has completed
          setKioskQuestionAnswer(questionList);
          setQueueNumber(responseData.QueueNumber);
          setHasErrored(false);
          setErrorMessage(null);
        }
      } catch (error) {
        setKioskQuestionAnswer([]);
        setHasErrored(true);
        if (error instanceof AxiosError) {
          setErrorMessage(error.response?.data.Message);
        } else {
          setErrorMessage(null);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    memberIdentifier,
    regInstitutionCode,
    institutionCode,
    targetSystem,
    clinicCode,
    visitType,
    pageFlag,
    shouldGetKioskQuestionnaire,
  ]);

  return [
    kioskQuestionAnswer,
    setKioskQuestionAnswer,
    isLoading,
    hasErrored,
    errorMessage,
    queueNumber,
  ];
};

export { useGetKioskQuestionnaire };
