import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    title: {
      fontSize: size.FONTSIZE_18,
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(2.5),
    },
    suggestion: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2.5),
    },
  } satisfies SxObjType;
};

export { sxStyles };
