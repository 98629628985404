import { getRequestOptions } from "api/resources/configs/requests";
import { GET_LIVE_CHAT_FLAG_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { formatGetLiveChatFlagErrorMessage } from "api/resources/messages/ErrorMessage";
import axios from "axios";
import { GetLiveChatFlagResponsePayload } from "./getLiveChatFlag.types";

export const getLiveChatFlag = async (
  errorCode: string = "",
): Promise<GetLiveChatFlagResponsePayload> => {
  try {
    const response = await axios.post<GetLiveChatFlagResponsePayload>(
      GET_LIVE_CHAT_FLAG_URL(),
      createRequestPayload(errorCode),
      getRequestOptions(),
    );
    return parseResponse(response, GET_LIVE_CHAT_FLAG_URL());
  } catch (error) {
    const msg = formatGetLiveChatFlagErrorMessage(GET_LIVE_CHAT_FLAG_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (errorCode?: string) => {
  return {
    ErrorCode: errorCode ? errorCode : "",
  };
};
