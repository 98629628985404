import { Box, Typography } from "@mui/material";
import IMAGES from "lib/assets/images";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { setCustomisedBack } from "lib/redux/navigation";
import { selectNavigation } from "lib/redux/navigation/selectors";
import { ACTIONS, PATHS } from "lib/routing";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import {
  formatDate,
  formatOptions,
} from "lib/util/DateTimeUtil/formatDate/formatDate";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setEpicProfileState } from "../../ducks";
import {
  selectEpicProfileState,
  selectMyInfoProfileState,
  selectPMSettingsValue,
} from "../../ducks/selectors";
import { fetchSystemSettings } from "../../ducks/thunks";
import { sxStyles } from "./PMComparePage.styles";

import { selectUser } from "lib/redux/user/selectors";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import {
  setHasFetchedEpicProfile,
  setPreferredDateError,
} from "../../ducks/pmSlice";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { useGetMessageAction } from "lib/routing/messageChannel/hooks/useGetMessageAction";

const nextButtonText = "Proceed to update";
const cancelButtonText = "Cancel";

const PMComparePage = () => {
  const classes = sxStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const epicProfileData = useAppSelector(selectEpicProfileState);
  const myInfoData = useAppSelector(selectMyInfoProfileState);
  const userProfile = useAppSelector(selectUser);

  const { disclaimers } = useAppSelector(selectPMSettingsValue);
  const preventDirectBackNavigationState =
    useAppSelector(selectNavigation).customisedBack;

  const [isSpotAnyIssuesLinkClicked, setIsSpotAnyIssuesLinkClicked] =
    useState(false);

  useEffect(() => {
    dispatch(fetchSystemSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { messageActionObj, consumeMessageActionObj } = useGetMessageAction();

  const resetPreventDirectBackNavigationState = () => {
    dispatch(setCustomisedBack(false));
    consumeMessageActionObj();
  };

  useEffect(() => {
    if (
      preventDirectBackNavigationState ||
      messageActionObj?.action === "backTriggered"
    ) {
      dispatch(setHasFetchedEpicProfile(false));
      resetPreventDirectBackNavigationState();
      if (
        userProfile.cancellationPath === PATHS.PM_ENTRY.path ||
        userProfile.cancellationPath === PATHS.PM_ENTRY_MOBILE.path
      ) {
        mobileNavigate(ACTIONS.PM_SYNC_DO_IT_LATER);
      } else if (userProfile.profileAccessEntryPoint === "MY HEALTH MAP") {
        mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH);
      } else {
        navigate(PATHS.MY_PROFILE_MOBILE.path); //check the diff between 'mobile' and no 'mobile'
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preventDirectBackNavigationState, messageActionObj?.action]);

  const hasDifference = (
    myInfoDataField: string | null,
    epicProfileDataField: string | null,
  ) => {
    if (
      (myInfoDataField == null || myInfoDataField === "") &&
      (epicProfileDataField == null || epicProfileDataField === "")
      //if both fields are empty or null
    ) {
      return false;
    } else if (
      myInfoDataField == null ||
      myInfoDataField === "" ||
      epicProfileDataField == null ||
      epicProfileDataField === ""
    ) {
      //if only one of the fields is empty or null, the other is not
      return true;
    } else if (myInfoDataField !== epicProfileDataField) {
      //if both fields are not empty, thus need to check the values
      return true;
    }
    return false;
  };

  const handleSpotAnyIssuesClick = () => {
    setIsSpotAnyIssuesLinkClicked(!isSpotAnyIssuesLinkClicked);
  };

  const toHandleNextButtonClick = () => {
    logEventToGoogleAnalytics(
      EVENTS.SELECT_PM_VERIFY_PARTICULARS_PROCEED_UPDATE,
    );

    if (
      epicProfileData.MailingAddressExpiryDate &&
      formatDate(formatDateToISO(new Date()), "yyyy-MM-dd") >
        formatDate(
          formatDateToISO(new Date(epicProfileData.MailingAddressExpiryDate)),
          "yyyy-MM-dd",
        )
    ) {
      dispatch(
        setEpicProfileState({
          ...epicProfileData,
          Nric: myInfoData.Nric,
          Nationality: myInfoData.Nationality,
          NationalityCode: myInfoData.NationalityCode,
          Race: myInfoData.Race,
          RaceCode: myInfoData.RaceCode,
          ResidentialStatus: myInfoData.ResidentialStatus,
          ResidentialStatusCode: myInfoData.ResidentialStatusCode,
          PassType: myInfoData.PassType,
          PassTypeCode: myInfoData.PassTypeCode,
          PassExpiryDate: myInfoData.PassExpiryDate,
          RegisteredPostalCode: myInfoData.RegisteredPostalCode,
          RegisteredStreetName: myInfoData.RegisteredStreetName,
          RegisteredBlockNumber: myInfoData.RegisteredBlockNumber,
          RegisteredUnitNumber: myInfoData.RegisteredUnitNumber,
          PreferredPostalCode: "",
          PreferredStreetName: "",
          PreferredBlockNumber: "",
          PreferredUnitNumber: "",
          MailingAddressExpiryDate: "",
        }),
      );
      dispatch(setPreferredDateError(false));
    } else {
      dispatch(
        setEpicProfileState({
          ...epicProfileData,
          Nric: myInfoData.Nric,
          Nationality: myInfoData.Nationality,
          NationalityCode: myInfoData.NationalityCode,
          Race: myInfoData.Race,
          RaceCode: myInfoData.RaceCode,
          ResidentialStatus: myInfoData.ResidentialStatus,
          ResidentialStatusCode: myInfoData.ResidentialStatusCode,
          PassType: myInfoData.PassType,
          PassTypeCode: myInfoData.PassTypeCode,
          PassExpiryDate: myInfoData.PassExpiryDate,
          RegisteredPostalCode: myInfoData.RegisteredPostalCode,
          RegisteredStreetName: myInfoData.RegisteredStreetName,
          RegisteredBlockNumber: myInfoData.RegisteredBlockNumber,
          RegisteredUnitNumber: myInfoData.RegisteredUnitNumber,
        }),
      );
    }
    navigate(PATHS.MY_INFOPROFILE.path);
  };

  const toDisplay = (
    //myInfoDataFieldForComparison may be different for comparison, like for example, display nationality and but compare pass type code
    field: string,
    myInfoDataFieldForComparison: string | null,
    epicProfileDataFieldForComparison: string | null,
    myInfoDataFieldForDisplay?: string | null,
    epicProfileDataFieldForDisplay?: string | null,
  ) => {
    if (
      //if either myInfo or epicProfile not null, then display (eg: passType)
      myInfoDataFieldForComparison !== null ||
      myInfoDataFieldForComparison !== "" ||
      epicProfileDataFieldForComparison !== null ||
      epicProfileDataFieldForComparison !== ""
    ) {
      const epicFieldDisplayed =
        epicProfileDataFieldForComparison == null ||
        epicProfileDataFieldForComparison === ""
          ? "-"
          : epicProfileDataFieldForDisplay !== undefined &&
              epicProfileDataFieldForDisplay !== null
            ? epicProfileDataFieldForDisplay
            : epicProfileDataFieldForComparison;
      const myInfoDataFieldDisplayed =
        myInfoDataFieldForComparison == null ||
        myInfoDataFieldForComparison === ""
          ? "-"
          : myInfoDataFieldForDisplay !== undefined &&
              myInfoDataFieldForDisplay !== null
            ? myInfoDataFieldForDisplay
            : myInfoDataFieldForComparison;
      if (
        //has a difference
        hasDifference(
          myInfoDataFieldForComparison,
          epicProfileDataFieldForComparison,
        )
      ) {
        return (
          <Box>
            <Box sx={classes.cardHeader}>
              <Box sx={classes.cardHeaderLeft}>
                <Box
                  component={"img"}
                  src={IMAGES.general.WarningIcon}
                  sx={classes.infoIcon}
                />
                <Typography sx={classes.cardHeaderLeft}>{field}</Typography>
              </Box>
            </Box>
            <Box sx={classes.cardDetails}>
              <Box sx={classes.cardLeft}>
                <Typography sx={classes.cardLabel}>
                  {field === "Pass Expiry Date"
                    ? epicFieldDisplayed === "-"
                      ? "-"
                      : formatDate(
                          epicFieldDisplayed,
                          formatOptions.dayMonthYear,
                        )
                    : epicFieldDisplayed}
                </Typography>
              </Box>

              <Box sx={classes.cardRight}>
                <Box sx={classes.imageContainer}>
                  <Box
                    component={"img"}
                    src={IMAGES.pm.RightArrow}
                    alt="Right Arrow"
                    sx={classes.image}
                  />
                </Box>
                <Typography sx={classes.cardValue}>
                  {field === "Pass Expiry Date"
                    ? myInfoDataFieldDisplayed === "-"
                      ? "-"
                      : formatDate(
                          myInfoDataFieldDisplayed,
                          formatOptions.dayMonthYear,
                        )
                    : myInfoDataFieldDisplayed}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      } else {
        //has no difference
        return (
          <Box>
            <Box sx={classes.cardDetails}>
              <Box sx={classes.cardLeft}>
                <Typography sx={classes.cardDetailLabel}>{field}</Typography>
                <Typography sx={classes.cardDetailValue}>
                  {field === "Pass Expiry Date"
                    ? epicFieldDisplayed === "-"
                      ? "-"
                      : formatDate(
                          epicFieldDisplayed,
                          formatOptions.dayMonthYear,
                        )
                    : epicFieldDisplayed}
                </Typography>
              </Box>
              <Box sx={classes.cardRight}>
                <Box sx={classes.cardRightNoDifferenceContainter}>
                  <Typography sx={classes.cardDetailLabel}>{field}</Typography>
                  <Typography sx={classes.cardDetailValue}>
                    {field === "Pass Expiry Date"
                      ? myInfoDataFieldDisplayed === "-"
                        ? "-"
                        : formatDate(
                            myInfoDataFieldDisplayed,
                            formatOptions.dayMonthYear,
                          )
                      : myInfoDataFieldDisplayed}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      }
    }
  };

  return (
    <Box sx={classes.mainContainer}>
      <Typography sx={classes.content}>
        {MapRawStringToReactElement(disclaimers.comparePage.description ?? "")}
      </Typography>
      <Box my={2} px={1}>
        <Box>
          <Box sx={classes.cardDetails}>
            <Box sx={classes.cardLeft}>
              <Typography sx={classes.cardTitle}>
                Existing NUHS Record
              </Typography>
            </Box>
            <Box sx={classes.cardRight}>
              <Box sx={classes.imageContainer}>
                <Box
                  component={"img"}
                  src={IMAGES.pm.RightArrow}
                  alt="Right Arrow"
                  sx={classes.image}
                />
              </Box>
              <Box>
                <Typography sx={classes.cardTitle}>New NUHS Record</Typography>
                <Typography
                  sx={classes.spotAnyIssuesLink}
                  onClick={handleSpotAnyIssuesClick}
                >
                  Spot any issues?
                </Typography>
              </Box>
            </Box>
          </Box>
          {toDisplay(
            "Nationality",
            myInfoData.NationalityCode,
            epicProfileData.NationalityCode,
            myInfoData.Nationality,
            epicProfileData.Nationality,
          )}
        </Box>
        <Box>
          <Box sx={classes.cardHeader}>
            <Typography sx={classes.cardSubHeader}>
              Residential Address
            </Typography>
          </Box>
        </Box>
        {toDisplay(
          "Postal Code",
          myInfoData.RegisteredPostalCode,
          epicProfileData.RegisteredPostalCode,
        )}
        {toDisplay(
          "Street",
          myInfoData.RegisteredStreetName,
          epicProfileData.RegisteredStreetName,
        )}
        {toDisplay(
          "No. / Blk",
          myInfoData.RegisteredBlockNumber,
          epicProfileData.RegisteredBlockNumber,
        )}
        {toDisplay(
          "Unit",
          myInfoData.RegisteredUnitNumber,
          epicProfileData.RegisteredUnitNumber,
        )}
        <Box px={2}>
          <SharpNoticePanel bgColor="warn">
            Tap the button below to update your particulars using Myinfo.
          </SharpNoticePanel>
        </Box>
        <Box pt={1} px={2} pb={2}>
          <ButtonsFooter
            nextButtonText={nextButtonText}
            onClickNext={toHandleNextButtonClick}
            cancelButtonText={cancelButtonText}
            onClickCancel={() => {
              dispatch(setHasFetchedEpicProfile(false));
              if (
                userProfile.cancellationPath === PATHS.PM_ENTRY.path ||
                userProfile.cancellationPath === PATHS.PM_ENTRY_MOBILE.path
              ) {
                mobileNavigate(ACTIONS.PM_SYNC_DO_IT_LATER);
              } else if (
                userProfile.profileAccessEntryPoint === "MY HEALTH MAP"
              ) {
                mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH);
              } else {
                navigate(PATHS.MY_PROFILE_MOBILE.path); //check the diff between 'mobile' and no 'mobile'
              }
            }}
          />
        </Box>
        <ConfirmationModal
          title={disclaimers.spotAnyIssues.title}
          open={isSpotAnyIssuesLinkClicked}
          onClose={() => {}}
          body={MapRawStringToReactElement(
            disclaimers.spotAnyIssues.description ?? "",
          )}
          hideCancelButton={true}
          nextButtonText="OK"
          onClickNext={handleSpotAnyIssuesClick}
        />
      </Box>
    </Box>
  );
};

export default PMComparePage;
