import Page from "lib/components/layout/Page/Page";
import BannerSection from "./BannerSection/BannerSection";
import ExploreHealthTogetherSection from "./ExploreHealthTogetherSection/ExploreHealthTogetherSection";

const HTLandingPage = () => {
  return (
    <Page>
      <BannerSection />
      <ExploreHealthTogetherSection />
    </Page>
  );
};

export default HTLandingPage;
