import axios from "axios";
import { GET_PAID_BILLS_HISTORY_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatGetPaidBillsHistoryErrorMessage } from "api/resources/messages/ErrorMessage";
import { GetPaidBillsHistoryResponsePayload } from "./getPaidBillsHistory.fromApi.types";

/**
 * Get paid bills history
 *
 * POST
 * Endpoint: secure/payment/GetPaidBillsHistory
 *
 * @param {string} clusterCode cluster code to retrieve values for, for our app is "NUHS".
 * @param {string} memberIdentifier Encrypted NRIC. e.g. "d030644b48cdbf04f4f87e6dfd02939f" (this one is invalid)
 */
const getPaidBillsHistory = async (
  clusterCode: string | null,
  memberIdentifier: string | null,
): Promise<GetPaidBillsHistoryResponsePayload> => {
  try {
    const response = await axios.post<GetPaidBillsHistoryResponsePayload>(
      GET_PAID_BILLS_HISTORY_URL(),
      createRequestPayload(clusterCode, memberIdentifier),
      getRequestOptions(),
    );
    return parseResponse(response, GET_PAID_BILLS_HISTORY_URL());
  } catch (error) {
    const msg = formatGetPaidBillsHistoryErrorMessage(
      GET_PAID_BILLS_HISTORY_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  clusterCode: string | null,
  memberIdentifier: string | null,
) => {
  return {
    ClusterCode: clusterCode,
    MemberIdentifier: memberIdentifier,
  };
};

export { getPaidBillsHistory };
