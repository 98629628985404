import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    topButtonLayout: {
      marginTop: theme.spacing(2),
    },
    buttonLabel: {
      padding: "0.5rem 0.5rem 0 0.5rem",
    },
  } satisfies SxObjType;
};

export { sxStyles };
