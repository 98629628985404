import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DependentProfile } from "api/shared/GetDependentProfiles/getDependentProfiles.fromApi.types";
import { UserProfile } from "api/shared/GetUserProfile/getUserProfile.fromApi.types";
import {
  MobileIntegrationUserState,
  ProfileAccessEntryPoint,
  UserState,
} from "./user.types";

// Initial state occurring when user is not initialized yet
// All fields should be NULL when not logged in yet.
export const initialState: UserState = {
  memberIdentifier: null,
  patientName: null,
  patientNric: null,
  requestorName: null,
  requestorNric: null,
  contact: null,
  dateOfBirth: null,
  email: null,
  usePreferredAddress: false,
  postalCode: null,
  block: null,
  streetAddress: null,
  unitNo: null,
  registeredPostalCode: null,
  registeredBlock: null,
  registeredStreetAddress: null,
  registeredUnitNo: null,
  preferredPostalCode: null,
  preferredBlock: null,
  preferredStreetAddress: null,
  preferredUnitNo: null,
  nationality: null,
  gender: null,
  photo: null,
  sources: null,
  buildingName: null,
  hasConsentedToTermsAndConditions: false,
  isPatient: true,
  relationType: null,
  childrenProfiles: [],
  careRecipientProfiles: [],
  isLoading: false,
  hasErrored: null,
  errorMessage: null,
  dependentProfilesAreLoading: false,
  dependentProfilesHaveErrored: null,
  dependentProfilesErrorMessage: null,
  hasMyInfoProfile: false,
  lastRetrievedMyInfoProfileOn: null,
  shouldDirectToMyInfo: false,
  cancellationPath: null,
  profileAccessEntryPoint: null,

  // Dependent
  dependentIsLoading: false,
  dependentHasErrored: false,
  dependentErrorMessage: null,

  // user consent info for accessing particular module
  consent: {
    isLoading: false,
    hasErrored: null,
    errorMessage: null,
    consentFlag: false,
  },

  // for programs enrolled Introduction
  healthProfile: null,

  // patient master
  hasSyncedParticulars: false,
  hasSubscribed: false,
  passType: null,
  passTypeCode: null,
  passExpiryDate: null,
  codeVerifier: null,
  myChartUuid: null,

  isMismatched: false,
  oldMemberIdentifier: null, //TODO: remove after MRRP/MedRefill decom
  oldTokenizedId: null, //TODO: remove after MRRP/MedRefill decom
};

/**
 * The slice   reducer for the part of state that represents the logged in user.
 * Contains the management of user information.
 */
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    initializeUserProfileFromMobileIntegration(
      state,
      action: PayloadAction<MobileIntegrationUserState>,
    ) {
      state.memberIdentifier = action.payload.patientMemberIdentifier;
      state.patientName = action.payload.patientName;
      state.patientNric = action.payload.patientNric;
      state.sources = action.payload.patientSources;
      if (action.payload.usePreferredAddress) {
        state.postalCode = action.payload.preferredPostalCode;
        state.block = action.payload.preferredBlock;
        state.unitNo = action.payload.preferredUnitNo;
        state.streetAddress = action.payload.preferredStreetAddress;
      } else {
        state.postalCode = action.payload.registeredPostalCode;
        state.block = action.payload.registeredBlock;
        state.unitNo = action.payload.registeredUnitNo;
        state.streetAddress = action.payload.registeredStreetAddress;
      }

      // still store the preferred address for display despite usePreferredAddress value
      state.preferredPostalCode = action.payload.preferredPostalCode;
      state.preferredBlock = action.payload.preferredBlock;
      state.preferredUnitNo = action.payload.preferredUnitNo;
      state.preferredStreetAddress = action.payload.preferredStreetAddress;

      state.gender = action.payload.gender;
      state.nationality = action.payload.nationality;
      state.dateOfBirth = action.payload.patientDateOfBirth;
      state.email = action.payload.email;
      state.contact = action.payload.contact;
      state.requestorName = action.payload.requestorName;
      state.requestorNric = action.payload.requestorNric;
      state.isPatient = action.payload.isPatient;
      state.relationType = action.payload.relationType;
      state.hasConsentedToTermsAndConditions =
        action.payload.hasConsentedToTermsAndConditions;
      state.shouldDirectToMyInfo = action.payload.shouldDirectToMyInfo;
      state.hasMyInfoProfile = action.payload.hasMyInfoProfile;
      state.myChartUuid = action.payload.myChartUuid;
    },
    setUserProfile(
      state,
      action: PayloadAction<
        UserProfile & {
          HasConsentedToTermsAndConditions: boolean;
          mapAddress: boolean;
          isPatient?: boolean;
          isMismatched: boolean;
        }
      >,
    ) {
      state.requestorName = action.payload.Name;
      state.requestorNric = action.payload.Nric;
      // only populate patient name, NRIC, and memberIdentifier as login user when login user is patient
      if (action.payload.isPatient === false) {
        state.isPatient = false;
      } else {
        state.isPatient = true;
        state.patientName = action.payload.Name;
        state.patientNric = action.payload.Nric;
        state.memberIdentifier = action.payload.MemberIdentifier;
      }

      state.contact = action.payload.ContactNumber;
      state.dateOfBirth = action.payload.DateOfBirth;
      state.email = action.payload.Email;
      state.photo = action.payload.Photo;
      // map address fields according to UsePreferredAddress only when mapAddress=true
      if (!action.payload.mapAddress || action.payload.UsePreferredAddress) {
        state.postalCode = action.payload.PreferredAddress?.PostalCode || null;
        state.streetAddress = action.payload.PreferredAddress?.Street || null;
        state.block = action.payload.PreferredAddress?.Block || null;
        state.unitNo = action.payload.PreferredAddress?.Unit || null;
      } else {
        state.postalCode = action.payload.RegisteredAddress?.PostalCode || null;
        state.streetAddress = action.payload.RegisteredAddress?.Street || null;
        state.block = action.payload.RegisteredAddress?.Block || null;
        state.unitNo = action.payload.RegisteredAddress?.Unit || null;
      }

      state.registeredPostalCode =
        action.payload.RegisteredAddress?.PostalCode || null;
      state.registeredStreetAddress =
        action.payload.RegisteredAddress?.Street || null;
      state.registeredBlock = action.payload.RegisteredAddress?.Block || null;
      state.registeredUnitNo = action.payload.RegisteredAddress?.Unit || null;

      state.usePreferredAddress = action.payload.UsePreferredAddress;
      state.nationality = action.payload.Nationality;
      state.gender = action.payload.Sex;
      state.sources = action.payload.Sources;
      state.hasConsentedToTermsAndConditions =
        action.payload.HasConsentedToTermsAndConditions;
      state.buildingName = action.payload.BuildingName;

      state.hasMyInfoProfile = action.payload.HasMyInfoProfile;
      state.lastRetrievedMyInfoProfileOn =
        action.payload.LastRetrievedMyInfoProfileOn;
      state.hasSyncedParticulars = action.payload.HasSyncedParticulars;
      state.hasSubscribed = action.payload.HasSubscribed;
      state.isMismatched = action.payload.isMismatched;
    },
    clearUserProfile(state) {
      Object.assign(state, initialState);
    },
    setProfileAccessEntryPoint(
      state,
      action: PayloadAction<ProfileAccessEntryPoint>,
    ) {
      state.profileAccessEntryPoint = action.payload;
    },
    setShouldDirectToMyInfo(
      state,
      action: PayloadAction<{
        shouldDirectToMyInfo: boolean;
        cancellationPath?: string;
      }>,
    ) {
      state.shouldDirectToMyInfo = action.payload.shouldDirectToMyInfo;
      if (action.payload.cancellationPath !== undefined) {
        state.cancellationPath = action.payload.cancellationPath;
      }
    },
    setUserProfileIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setUserProfileHasErrored(state, action: PayloadAction<boolean | null>) {
      state.hasErrored = action.payload;
    },
    setUserProfileErrorMessage(state, action: PayloadAction<string | null>) {
      state.errorMessage = action.payload;
    },
    setDependentProfileIsLoading(state, action: PayloadAction<boolean>) {
      state.dependentIsLoading = action.payload;
    },
    setDependentProfileHasErrored(state, action: PayloadAction<boolean>) {
      state.dependentHasErrored = action.payload;
    },
    setDependentProfileErrorMessage(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.dependentErrorMessage = action.payload;
    },
    setDependentProfileSources(state, action: PayloadAction<string[]>) {
      state.sources = action.payload;
    },
    setDependentProfiles(
      state,
      action: PayloadAction<{
        ChildrenProfiles: DependentProfile[];
        CareRecipientProfiles: DependentProfile[];
      }>,
    ) {
      state.childrenProfiles = action.payload.ChildrenProfiles;
      state.careRecipientProfiles = action.payload.CareRecipientProfiles;
    },
    setDependentProfilesAreLoading(state, action: PayloadAction<boolean>) {
      state.dependentProfilesAreLoading = action.payload;
    },
    setDependentProfilesHaveErrored(
      state,
      action: PayloadAction<boolean | null>,
    ) {
      state.dependentProfilesHaveErrored = action.payload;
    },
    setDependentProfilesErrorMessage(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.dependentProfilesErrorMessage = action.payload;
    },
    setConsentIsLoading(state, action: PayloadAction<boolean>) {
      state.consent.isLoading = action.payload;
    },
    setConsentHasErrored(state, action: PayloadAction<boolean | null>) {
      state.consent.hasErrored = action.payload;
    },
    setConsentErrorMessage(state, action: PayloadAction<string | null>) {
      state.consent.errorMessage = action.payload;
    },
    setConsentFlag(state, action: PayloadAction<boolean>) {
      state.consent.consentFlag = action.payload;
    },
    setRequestorName(state, action: PayloadAction<string | null>) {
      state.requestorName = action.payload;
    },

    setMyInfoCodeVerifier(state, action: PayloadAction<string | null>) {
      state.codeVerifier = action.payload;
    },
    setMyChartUserId(state, action: PayloadAction<string | null>) {
      state.myChartUuid = action.payload;
    },

    //TODO: remove after MRRP/MedRefill decom
    setOldMemberIdentifier(state, action: PayloadAction<string | null>) {
      state.oldMemberIdentifier = action.payload;
    },
    //TODO: remove after MRRP/MedRefill decom
    setOldTokenizedId(state, action: PayloadAction<string | null>) {
      state.oldTokenizedId = action.payload;
    },
  },
});

export const {
  initializeUserProfileFromMobileIntegration,
  setUserProfile,
  clearUserProfile,
  setShouldDirectToMyInfo,
  setProfileAccessEntryPoint,
  setUserProfileIsLoading,
  setUserProfileHasErrored,
  setUserProfileErrorMessage,
  setDependentProfileIsLoading,
  setDependentProfileHasErrored,
  setDependentProfileErrorMessage,
  setDependentProfileSources,
  setDependentProfiles,
  setDependentProfilesAreLoading,
  setDependentProfilesHaveErrored,
  setDependentProfilesErrorMessage,
  setConsentIsLoading,
  setConsentHasErrored,
  setConsentErrorMessage,
  setConsentFlag,
  setRequestorName,
  setMyInfoCodeVerifier,
  setMyChartUserId,
  setOldMemberIdentifier, //TODO: remove after MRRP/MedRefill decom
  setOldTokenizedId, //TODO: remove after MRRP/MedRefill decom
} = userSlice.actions;

export default userSlice.reducer;
