import React from "react";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { RefillOrTopupSubmitRequestConfirmationModalProps } from "./RefillOrTopupSubmitRequestConfirmationModal.types";
import { Typography } from "@mui/material";
import { sxStyles } from "./RefillOrTopupSubmitRequestConfirmationModal.styles";

const RefillOrTopupSubmitRequestConfirmationModal = ({
  open,
  requesterId,
  isPatient,
  onClickConfirm,
  onClickCancel,
}: RefillOrTopupSubmitRequestConfirmationModalProps) => {
  const classes = sxStyles();

  return (
    <ConfirmationModal
      open={open}
      title="Request Submitted Successfully!"
      titleBold={false}
      hideNextButton={!isPatient}
      body={
        <Typography sx={classes.confirmationText}>
          Reference ID: {requesterId} <br /> Your request will be processed
          accordingly. <br />
          <br />
          Our staff will contact you if your request cannot be processed or if
          clarification is required.
        </Typography>
      }
      nextButtonText="Submit another request"
      cancelButtonText="Back to Home"
      onClose={() => {}}
      onClickNext={onClickConfirm}
      onClickCancel={onClickCancel}
    ></ConfirmationModal>
  );
};

export default RefillOrTopupSubmitRequestConfirmationModal;
