import { Box, CardHeader, Skeleton } from "@mui/material";
import { sxStyles } from "./TransactionHistoryProfileHeader.styles";
import { TransactionHistoryProfileHeaderProps } from "./TransactionHistoryProfileHeader.types";
import IMAGES from "lib/assets/images";
import IconTextButton from "lib/components/buttons/IconTextButton/IconTextButton";

const TransactionHistoryProfileHeader = ({
  name,
  isLoading,
  title,
  showMFilterTransactionsButton = true,
  openFilterDialog,
}: TransactionHistoryProfileHeaderProps) => {
  const classes = sxStyles();

  return (
    <Box sx={classes.paymentPage}>
      <Box sx={classes.profileHeader}>
        <CardHeader
          title={
            isLoading ? (
              <Skeleton variant="text" animation="wave" width="60%" />
            ) : name ? (
              name + title
            ) : null
          }
          sx={classes.cardHeader}
        />

        {showMFilterTransactionsButton && (
          <Box sx={classes.filterTransactionsBox}>
            <IconTextButton
              icon={IMAGES.general.FilterIcon}
              label={["Filter", "transactions"]}
              onClick={openFilterDialog}
              ariaLabel={"Filter transactions"}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TransactionHistoryProfileHeader;
