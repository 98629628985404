import { CircularProgress } from "@mui/material";

const LoadingPage = () => {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "40%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default LoadingPage;
