import { connect } from "react-redux";
import { RootState } from "lib/redux/root/redux.types";
import VerifyParticulars from "./VerifyParticulars";
import { Dispatch, AnyAction } from "redux";
import { MrrpParticularsState } from "ui/mrrp/ducks/mrrp.types";
import { setParticulars } from "ui/mrrp/ducks";

const mapStateToProps = (state: RootState) => {
  const selectedHospital = state.mrrp.hospital.selectedHospitalCode ?? "";
  const formLabelSelected = state.mrrp.formLabel.labels.find((label) => {
    return label.institutions.includes(selectedHospital);
  });
  const formLabelDefault = state.mrrp.formLabel.labels.find((label) => {
    return label.institutions.includes("Default");
  });
  const formLabel = formLabelSelected ?? formLabelDefault;
  return {
    requestorNameFormLabel: formLabel?.requestorName ?? null,
    relationshipFormLabel: formLabel?.relationship ?? null,
    contactNoFormLabel: formLabel?.contactNo ?? null,

    userState: state.user,
    patientRelationships: state.mrrp.reportOptions.patientRelationships,
    particulars: state.mrrp.particulars,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onSetParticulars: (particularsState: MrrpParticularsState) => {
      dispatch(setParticulars(particularsState));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyParticulars);
