import { parseISO, format } from "date-fns";

export enum formatOptions {
  default = "E, dd MMMM yyyy, h:mm aaa", // Date format constructed from the rules of the date formatting library. e.g. Sat, 09 Apr 2020, 8:45 am
  timeWithPeriod = "h:mm aaa", // e.g. 8:45 am
  time = "h:mm", //e.g. 8:45
  dayOfWeek = "E", // e.g. Sat
  day = "dd", // e.g. 09
  month = "MMM", // e.g. Apr
  year = "yyyy", // e.g. 2020
  monthYear = "MMM yyyy",
  dayMonthYear = "dd MMM yyyy", // e.g. 09 Apr 2020
  dayMonthYearTime = "dd MMM yyyy, h:mm aaa", // e.g. "01 Jul 2020, 1:30 am"
  myInfoRetrieved = "d MMM yyyy, h:mm aaa", // e.g. "1 Jul 2020, 1:30 am"
  datePickerDefault = "d MMM yyyy",
  datePickerPlaceholder = "D/MM/YYYY",
}

/**
 * Formats an ISO date to a displayble format on the UI
 *
 * @param dateString  ISO DateTime format, e.g. "2020-04-09T08:45:00"
 * @param dateFormat  Optional Date format string. Please use formatOptions object for consistency
 *
 * @returns Formatted date string, ready to be displayed on UI, e.g. "Sat, 09 Apr 2020, 8:45 am"
 * @throws  Error when provided input is not in ISO DateTime format and can't be parsed
 */
export const formatDate = (
  dateString: string | null,
  dateFormat: string = formatOptions.default,
) => {
  if (!dateString) {
    return "";
  }

  const dateObj = parseISO(dateString);

  return format(dateObj, dateFormat);
};
