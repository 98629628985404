import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    mainContainer: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      gap: 1.5,
    },

    linkBody: {
      typography: theme.typography.caption1_regular,
      color: theme.palette.grey[400],
      marginLeft: theme.spacing(3),
    },
  } satisfies SxObjType;
};

export { sxStyles };
