import { Box, Card, CardHeader, CardContent, IconButton } from "@mui/material";
import { MedicationCardProps } from "./MedicationCard.types";
import { sxStyles } from "./MedicationCard.styles";
import ImageUploader from "lib/components/upload/ImageUploader/ImageUploader";
import IMAGES from "lib/assets/images";

const MedicationCard = ({
  index,
  title,
  medicationCardData,
  hideDeleteIcon = false,
  setMedicationCardData,
  onClickDeleteMedicationCard,
  onImageProcessing,
  disabled = false,
}: MedicationCardProps) => {
  const classes = sxStyles();

  return (
    <Card sx={classes.card}>
      <CardHeader
        p={0}
        subheader={title}
        action={
          hideDeleteIcon ? null : (
            <IconButton
              aria-label="delete"
              sx={classes.deleteIcon}
              disabled={disabled}
              onClick={() => onClickDeleteMedicationCard(index)}
            >
              <img src={IMAGES.general.DeleteIcon} alt="Delete" />
            </IconButton>
          )
        }
        sx={classes.cardHeader}
      />
      <CardContent sx={classes.mediaContent}>
        <Box sx={classes.media}>
          <ImageUploader
            onImageProcessing={(loading) => {
              onImageProcessing(loading, 0);
            }}
            defaultFileData={medicationCardData.frontFileData}
            overlayText="Front page"
            onFileUploadSuccess={(frontFileName, frontFileData) => {
              setMedicationCardData({
                ...medicationCardData,
                frontFileName,
                frontFileData,
              });
            }}
            onClearFileData={() => {
              setMedicationCardData({
                ...medicationCardData,
                frontFileName: null,
                frontFileData: null,
                backFileName: null,
                backFileData: null,
              });
            }}
            disabled={disabled}
            index={index}
            nestedIndex={0}
          />
        </Box>
        <Box sx={classes.media}>
          <ImageUploader
            onImageProcessing={(loading) => {
              onImageProcessing(loading, 1);
            }}
            defaultFileData={medicationCardData.backFileData}
            overlayText="Back page"
            onFileUploadSuccess={(backFileName, backFileData) => {
              setMedicationCardData({
                ...medicationCardData,
                backFileName,
                backFileData,
              });
            }}
            onClearFileData={() => {
              setMedicationCardData({
                ...medicationCardData,
                backFileName: null,
                backFileData: null,
              });
            }}
            disabled={disabled || !medicationCardData.frontFileData}
            index={index}
            nestedIndex={1}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default MedicationCard;
