import { mobileNavigate } from "lib/routing/navigate/navigate";
import { Redirectable } from "./routesToRedirect.types";
import { setRedirectToExternalFlag } from "lib/routing/externalRedirection/externalRedirection";
import { ACTIONS, PATHS } from "lib/routing";

/**
 * == PATHS TO REDIRECT (on "<" back button press)
 * Paths where navigation should be redirected to a specified route by the "<" back button.
 *
 * === Description:
 * This list of paths refer to pages that require custom redirection to a specific page not
 * found in the react-route history stack.
 *
 * If a page is listed here, it means that direct back navigation, e.g. history.goBack(), will not be fired.
 * [IMPORTANT] Each page listed will be redirected to the routes indicated below instead.
 *
 * === Example / Explanation:
 * This is useful, for example, in the Appointment module for the QueueChitAndJourneyPage / KioskQuestionnaire page.
 *
 * Here, we need to redirect the user back to the mobile dashboard (not even a React page!), which requires that you
 * redirect to a specific route. Furthermore, the way navigation works in this case is that you're not even supposed
 * to use history.pushState({ ... }), but a window.location.assign(...) kind of navigation.
 */

const PATH_WITH_BACK_NAVIGATION_REDIRECTED: Redirectable = {
  // ======================
  // PROFILE MODULE
  // ======================
  [PATHS.MY_PROFILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  // ======================
  // APPOINTMENT MODULE
  // ======================

  // ------------------------
  // Appointment (Create, Reschedule, Cancel)
  // ------------------------

  [PATHS.APPOINTMENT_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  [PATHS.APPOINTMENT_CREATE_INSTITUTIONS_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  [PATHS.APPOINTMENT_RESCHEDULE_AVAILABLE_SLOTS_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  [PATHS.APPOINTMENT_RESCHEDULE_LINKED_CONFIRM_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  [PATHS.APPOINTMENT_RESCHEDULE_FORM_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  [PATHS.APPOINTMENT_CANCEL_FORM_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  // ------------------------
  // Register an Appointment
  // ------------------------

  [PATHS.APPOINTMENT_QUEUECHIT_JOURNEY_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },
  [PATHS.APPOINTMENT_REGISTER_KIOSK_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },
  [PATHS.APPOINTMENT_CHECKIN_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  // =========================
  // Cancel an Appointment
  // =========================
  [PATHS.APPOINTMENT_CANCEL_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  // ======================
  // MEDREFILL MODULE
  // ======================
  [PATHS.MEDICATIONS_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  // ------------------------
  // Refill
  // ------------------------

  [PATHS.MED_REFILL_SELECT_INSTITUTIONS_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  [PATHS.MED_REFILL_PAST_REQUEST_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  // ------------------------
  // Topup
  // ------------------------

  [PATHS.MED_TOPUP_SELECT_INSTITUTIONS_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  [PATHS.MED_TOPUP_PAST_REQUEST_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  // ------------------------
  // Virtual Consult
  // ------------------------
  [PATHS.MED_VIRTUALCONSULT_ORDER_MEDICATION_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  // ------------------------
  // My Health Map
  // ------------------------
  [PATHS.MHM_FIRST_TIME_USER_INTRODUCTION_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },
  [PATHS.MHM_DASHBOARD_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  // ======================
  // MRRP MODULE
  // ======================
  [PATHS.MRRP_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },
  [PATHS.MRRP_NEW_REQUEST_RECEIPT.path]: {
    redirect: () => {
      // setting flag since navigation is using windows.location.assign which will reload the page
      setRedirectToExternalFlag();
      mobileNavigate(PATHS.MRRP_MOBILE.path);
    },
  },

  // ======================
  // PAYMENT MODULE
  // ======================
  [PATHS.PAYMENT_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  [PATHS.PAYMENT_RECEIPT.path]: {
    redirect: () => {
      // setting flag since navigation is using windows.location.assign which will reload the page
      setRedirectToExternalFlag();
      mobileNavigate(PATHS.PAYMENT_MOBILE.path);
    },
  },

  [PATHS.PAYMENT_BILL_SUMMARY_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },

  // =========================
  // HEALTH TOGETHER MODULE
  // =========================
  [PATHS.HT_MOBILE.path]: {
    redirect: () => {
      mobileNavigate(ACTIONS.BACK_ACTION);
    },
  },
};

/**
 * Checks if the specified location subpath is contained in the dictionary of redirectable paths.
 * This method can be used to check if the path the user is currently at needs custom
 * redirection as specified in the dictionary of redirectable paths.
 *
 * @param {string} locationPathName  Subpath in the URl containing the specific route to
 *   search for in the dictionary of redirectable paths
 * @returns {boolean}  True if this path can be found, false otherwise
 */
const containsPath = (locationPathName: string) => {
  return Object.keys(PATH_WITH_BACK_NAVIGATION_REDIRECTED).includes(
    locationPathName,
  );
};

/**
 * Performs custom redirect, for this path.
 *
 * @param {string} locationPathName  Subpath in the URL (assumed to be present in
 *   dictionary of redirectable paths)
 */
const executeRedirect = (locationPathName: string | null) => {
  if (
    locationPathName &&
    PATH_WITH_BACK_NAVIGATION_REDIRECTED[locationPathName]
  ) {
    PATH_WITH_BACK_NAVIGATION_REDIRECTED[locationPathName].redirect();
  }
};

export { PATH_WITH_BACK_NAVIGATION_REDIRECTED, containsPath, executeRedirect };
