import axios from "axios";
import { CREATE_MAMMOGRAM_APPOINTMENT_REQUEST_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "../../resources/configs/requests";
import {
  CreateMammogramAppointmentRequestPayload,
  CreateMammogramAppointmentResponsePayload,
} from "./createMammogramAppointment.types";
import { formatCreateMammogramAppointmentRequestErrorMessage } from "api/resources/messages/ErrorMessage";

/**
 *
 * Submits a request to creates a mammogram appointment
 *
 * POST
 * Endpoint: /appointment/CreateMammogramAppointment
 *
 * @param {CreateMammogramAppointmentRequestPayload} request  The request payload to be sent to the API endpoint
 */
const createMammogramAppointment = async (
  request: CreateMammogramAppointmentRequestPayload,
): Promise<CreateMammogramAppointmentResponsePayload> => {
  try {
    const response =
      await axios.post<CreateMammogramAppointmentResponsePayload>(
        CREATE_MAMMOGRAM_APPOINTMENT_REQUEST_URL(),
        request,
        getRequestOptions(),
      );
    return parseResponse(response, CREATE_MAMMOGRAM_APPOINTMENT_REQUEST_URL());
  } catch (error) {
    const msg = formatCreateMammogramAppointmentRequestErrorMessage(
      CREATE_MAMMOGRAM_APPOINTMENT_REQUEST_URL(),
    );
    console.error(msg);
    throw error;
  }
};

export { createMammogramAppointment };
