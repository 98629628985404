import React, { useRef, useState } from "react";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import IMAGES from "lib/assets/images";
import { sxStyles } from "./FileUploader.styles";
import { FileUploaderProps } from "./FileUploader.types";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import ImageModal from "lib/components/modals/ImageModal/ImageModal";
import { arrayBufferToBase64 } from "lib/util/StringUtil/base64/arrayBufferToBase64";
import SummarySectionFrame from "lib/components/summary/SummarySectionFrame/SummarySectionFrame";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { ACTIONS } from "lib/routing";
import { useAppSelector } from "lib/redux/hooks";
import { selectSystem } from "lib/redux/system/selectors";
import { SYSTEM_DEVICE_PLATFORM_ANDROID } from "lib/mobileIntegration/constants";
import { convertByteArrayToPdfAndOpenInMobile } from "lib/util/DownloadUtil/openPdfInMobile";
import IconLink from "lib/components/links/IconLink/IconLink";

// Inline image encoding scheme prefix (required by <img src="..." /> components)
const INLINE_IMAGE_ENCODING_SCHEME_PREFIX = "data:image/jpeg;base64,";

const FileUploader = ({
  disabled = false,
  required,
  maxFileSize,
  fileName,
  fileByte,
  fileMIMEType,
  onClickUpload,
  onClickDelete,
}: FileUploaderProps) => {
  const inputFieldRef = useRef<HTMLInputElement>(null);
  const classes = sxStyles();

  // Redux states
  const { devicePlatform } = useAppSelector(selectSystem);

  const formatBytes = (bytes: number) => {
    var totalSizeMB = bytes / Math.pow(1024, 2);
    return totalSizeMB;
  };

  // to enable event fired upon selecting same file
  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = "";
  };

  const [exceedSizeLimit, setExceedSizeLimit] = useState(false);
  const [fileFormatNotAccepted, setFileFormatNotAccepted] = useState(false);
  const [erroredFileFormat, setErroredFileFormat] = useState("");
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box sx={classes.buttonBox}>
        <IconLink
          title="Add attachment"
          icon="addCircleOutline"
          iconPosition="start"
          disabled={disabled}
          required={required}
          onClick={() => {
            inputFieldRef?.current?.click();
          }}
        />
      </Box>
      <Box mt={2}>
        <SharpNoticePanel bgColor="warn">
          {`File format: jpg, jpeg, pdf. Max file size: ${maxFileSize}MB`}
        </SharpNoticePanel>
      </Box>

      {/* File upload input field, receives the file data (not for display) */}
      <input
        style={{ display: "none" }} // Hide this field because its onChange handler is directly controlled
        // by the wrapping button's onClick invocation. Hence, we do not need
        // the default style of the vanilla HTML input field, and can hide it.
        ref={inputFieldRef}
        type="file"
        accept="application/pdf, image/jpeg" // by right, current is supporting pdf, jpeg, jpg, jfif, pjpeg, pjp
        onClick={onInputClick}
        onChange={(event) => {
          if (event?.target?.files) {
            const file = event.target.files[0]; // file object from user input
            if (file && file.name !== fileName) {
              if (
                file.type !== "application/pdf" &&
                file.type !== "image/jpeg"
              ) {
                setFileFormatNotAccepted(true);
                setErroredFileFormat(file.type.split("/")[1]);
                return;
              } else {
                setFileFormatNotAccepted(false);
                setErroredFileFormat("");
              }

              if (maxFileSize && formatBytes(file.size) > maxFileSize) {
                setExceedSizeLimit(true);
                return;
              } else {
                setExceedSizeLimit(false);
              }

              file
                .arrayBuffer()
                .then((data) => onClickUpload(file.name, data, file.type));
            }
          }
        }}
      />

      {fileName && (
        <SummarySectionFrame>
          <Box sx={classes.boxRow}>
            <Box sx={classes.boxLeft}>
              <Typography sx={classes.fileNameLabel}>Filename</Typography>
              <Typography
                sx={classes.fileName}
                onClick={() => {
                  // !exp Due to Checkmarx issue for android receiving blob, there needs a special handling for android only
                  if (fileByte && fileMIMEType === "application/pdf") {
                    if (devicePlatform === SYSTEM_DEVICE_PLATFORM_ANDROID) {
                      mobileNavigate(ACTIONS.ANDROID_UPLOAD_PREVIEW(fileName));
                    } else {
                      convertByteArrayToPdfAndOpenInMobile(fileName, fileByte);
                    }
                  }
                }}
              >
                {fileName}
              </Typography>
            </Box>

            <Box sx={classes.boxRight}>
              <IconButton
                aria-label="delete"
                sx={classes.deleteIcon}
                onClick={() => onClickDelete()}
              >
                <img src={IMAGES.general.DeleteIcon} alt="Delete" />
              </IconButton>
            </Box>
          </Box>
          {fileByte && fileMIMEType === "image/jpeg" && (
            <Box
              sx={classes.attachmentJpegFrame}
              onClick={() => {
                setOpen(true);
              }}
            >
              <Box
                src={
                  INLINE_IMAGE_ENCODING_SCHEME_PREFIX +
                  arrayBufferToBase64(fileByte)
                }
                alt="attachment"
                sx={classes.jpeg}
                component="img"
              />
            </Box>
          )}
        </SummarySectionFrame>
      )}

      <ImageModal
        title={"Attachment"}
        fileDataList={fileByte ? [arrayBufferToBase64(fileByte)] : []}
        startAt={0}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      ></ImageModal>

      {exceedSizeLimit && (
        <Typography sx={classes.errText}>
          The file exceeds the size limit.
        </Typography>
      )}
      {fileFormatNotAccepted && (
        <Typography sx={classes.errText}>
          The file format {erroredFileFormat} is not supported.
        </Typography>
      )}
    </>
  );
};

export default FileUploader;
