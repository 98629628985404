import React, { Fragment } from "react";
import {
  Box,
  CardActionArea,
  Divider,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { sxStyles } from "./ExternalLinkCard.styles";
import { ExternalLinkCardProps } from "./ExternalLinkCard.types";
import IMAGES from "lib/assets/images";

const ExternalLinkCard = (externalLinkObject: ExternalLinkCardProps) => {
  const classes = sxStyles();

  return (
    <List sx={classes.card}>
      {externalLinkObject.externalLinkObject &&
        externalLinkObject.externalLinkObject.map((listitem, index) => (
          <Fragment key={index}>
            <CardActionArea>
              <ListItem
                key={listitem.title}
                aria-label={listitem.title || ""}
                sx={classes.cardItem}
                onClick={(event) => {
                  event.stopPropagation();
                  listitem.onClickCard && listitem.onClickCard();
                }}
              >
                <Typography sx={classes.title}> {listitem.title} </Typography>
                {listitem.endIcon === "externalLink" && (
                  <Box component="img" src={IMAGES.general.ExternalLinkIcon} />
                )}
              </ListItem>
            </CardActionArea>
            {index + 1 !== externalLinkObject.externalLinkObject.length && (
              <Divider sx={classes.divider} />
            )}
          </Fragment>
        ))}
    </List>
  );
};

export default ExternalLinkCard;
