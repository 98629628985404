import React, { useState } from "react";
import { AttachmentSummarySectionProps } from "./AttachmentSummarySection.types";
import { Box, Typography } from "@mui/material";
import { sxStyles } from "./AttachmentSummarySection.styles";
import ImageModal from "lib/components/modals/ImageModal/ImageModal";
import { arrayBufferToBase64 } from "lib/util/StringUtil/base64/arrayBufferToBase64";
import { ACTIONS } from "lib/routing";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { SYSTEM_DEVICE_PLATFORM_ANDROID } from "lib/mobileIntegration/constants";
import { useAppSelector } from "lib/redux/hooks";
import { selectSystem } from "lib/redux/system/selectors";
import { convertByteArrayToPdfAndOpenInMobile } from "lib/util/DownloadUtil/openPdfInMobile";

// Inline image encoding scheme prefix (required by <img src="..." /> components)
const INLINE_IMAGE_ENCODING_SCHEME_PREFIX = "data:image/jpeg;base64,";

const AttachmentSummarySection = ({
  fileName,
  fileByte,
  fileMIMEType,
}: AttachmentSummarySectionProps) => {
  const classes = sxStyles();
  const [open, setOpen] = useState(false);

  // Redux states
  const { devicePlatform } = useAppSelector(selectSystem);

  return (
    <>
      <Typography sx={classes.title}>Attachment</Typography>
      <Typography sx={classes.fileNameLabel}>Filename</Typography>
      <Typography
        sx={classes.fileName}
        onClick={() => {
          // !exp Due to Checkmarx issue for android receiving blob, there needs a special handling for android only
          if (fileMIMEType === "application/pdf") {
            if (devicePlatform === SYSTEM_DEVICE_PLATFORM_ANDROID) {
              mobileNavigate(ACTIONS.ANDROID_UPLOAD_PREVIEW(fileName));
            } else {
              convertByteArrayToPdfAndOpenInMobile(fileName, fileByte);
            }
          }
        }}
      >
        {fileName}
      </Typography>
      {fileMIMEType === "image/jpeg" && fileByte && (
        // fileMIMEType === "image/jpeg"
        <Box
          sx={classes.attachmentJpegFrame}
          onClick={() => {
            setOpen(true);
          }}
        >
          <Box
            src={
              INLINE_IMAGE_ENCODING_SCHEME_PREFIX +
              arrayBufferToBase64(fileByte)
            }
            alt="attachment"
            sx={classes.jpeg}
            component={"img"}
          />
        </Box>
      )}
      <ImageModal
        title={"Attachment"}
        fileDataList={fileByte ? [arrayBufferToBase64(fileByte)] : []}
        startAt={0}
        footerMessage={["Attachment"]}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      ></ImageModal>
    </>
  );
};

export default AttachmentSummarySection;
