import { RootStore } from "lib/redux/root/redux.types";
import { validateOptionalField } from "lib/util/ValidatorUtil/fieldValidator/validateField";
import { MobileIntegrationNavigationState } from "../navigation.types";
import { initializeNavigationStateFromMobileIntegration } from "..";

const dispatchToReducer = async (
  store: RootStore,
  navObj: MobileIntegrationNavigationState,
) => {
  validateOptionalField(navObj, "shouldShowBackOnEntry", true);

  await store.dispatch(initializeNavigationStateFromMobileIntegration(navObj));
};

export { dispatchToReducer };
