import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { GET_MAMMOGRAM_QUESTIONNAIRE_URL } from "../../resources/configs/URL";
import { formatGetMammogramQuestionnaireErrorMessage } from "../../resources/messages/ErrorMessage";
import { GetMammogramQuestionnairePayload } from "./getMammogramQuestionnaire.fromApi.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";

export const DATEPICKER_QUESTION_INDEX = 5;
export const CHECKBOX_QUESTION_INDEX = 6;
export const DROPDOWN_QUESTION_INDEX = 7;

/**
 *
 * Retrieves the mammogram questionnaire, for mammogram appointments.
 *
 * POST
 * Endpoint: /appointment/GetMammogramQuestionnaire
 *
 *
 * @returns {GetMammogramQuestionnairePayload} Response payload from the remote API
 */
const getMammogramQuestionnaire =
  async (): Promise<GetMammogramQuestionnairePayload> => {
    try {
      const response = await axios.post<GetMammogramQuestionnairePayload>(
        GET_MAMMOGRAM_QUESTIONNAIRE_URL(),
        {},
        getRequestOptions(),
      );
      return parseResponse(response, GET_MAMMOGRAM_QUESTIONNAIRE_URL());
    } catch (error) {
      const msg = formatGetMammogramQuestionnaireErrorMessage(
        GET_MAMMOGRAM_QUESTIONNAIRE_URL(),
      );
      console.error(msg);
      throw error;
    }
  };

export { getMammogramQuestionnaire };
