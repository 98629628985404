import { CardContent, Typography, Box } from "@mui/material";
import { AppointmentCardBodyProps } from "./AppointmentCardBody.types";
import { sxStyles } from "./AppointmentCardBody.styles";
import {
  formatDate,
  formatOptions,
} from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

const AppointmentCardBody = ({
  institution,
  datetime,
  isDateTimeHighlighted = true,
  departmentName,
  clinician,
  serviceDisplayName,
  location,
  zoneInfo,
  visitType,
  isVcp,
}: AppointmentCardBodyProps) => {
  const classes = sxStyles();
  /* Test build */
  return (
    <CardContent sx={classes.cardDetailWrapper} aria-label="cardDetailWrapper">
      <Box sx={classes.cardDetails}>
        {institution && (
          <Typography sx={classes.cardTitle}>{institution}</Typography>
        )}
        <Typography sx={classes.cardTime}>
          {datetime
            ? formatDate(
                formatDateToISO(datetime),
                formatOptions.timeWithPeriod,
              )
            : null}
        </Typography>
        {serviceDisplayName && (
          <Typography sx={classes.cardSubtitle} component="h5">
            {serviceDisplayName}
          </Typography>
        )}

        {!isVcp && (
          <>
            {departmentName && (
              <Typography sx={classes.cardContent}>{departmentName}</Typography>
            )}
            {clinician && (
              <Typography sx={classes.cardContent}>{clinician}</Typography>
            )}
            {location && (
              <Typography sx={classes.cardContent}>{location}</Typography>
            )}
            {zoneInfo && (
              <Typography sx={classes.cardContent}>{zoneInfo}</Typography>
            )}
            {visitType && (
              <Typography sx={classes.cardContent}>{visitType}</Typography>
            )}
          </>
        )}
      </Box>
    </CardContent>
  );
};

export default AppointmentCardBody;
