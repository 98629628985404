import React, { useEffect, useState } from "react";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { CollectionProps, CollectionState } from "./Collection.types";
import {
  Box,
  FormControl,
  RadioGroup,
  CircularProgress,
  Typography,
} from "@mui/material";
import { sxStyles } from "./Collection.styles";
import { COLLECTION_TYPES, REQUEST_TYPES } from "./CollectionCodes";
import { useGetLocations } from "ui/medrefill/hooks/useGetLocations";
import StepPageLayoutContainer from "../../../common/StepPageLayout/StepPageLayoutContainer";
import StepPageButtonsFooter from "../../../common/StepPageButtonsFooter/StepPageButtonsFooter";
import CollectionRadio from "../CollectionRadio/CollectionRadio";
import MultiLineTextField from "lib/components/formInputs/MultiLineTextField/MultiLineTextField";
import PickUpAtPharmacy from "../PickUpAtPharmacy/PickUpAtPharmacy";
import Delivery from "../Delivery/Delivery";
import { useNavigate } from "react-router-dom";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import { Location } from "api/medrefill/GetLocations/getLocations.types";
import { findLocationByLocationId } from "../PickUpAtPharmacy/mapper";
import { getMinDate } from "../PreferredDatepicker/computeDisabledDates";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import {
  shouldDisplayPrescribedMedication,
  prescribedMedicationConsent,
} from "lib/util/StandardPageUtil/prescribedMedication";
import { IterableElement } from "lib/data/data.types";

import { MedicationCardData } from "../prescription/RefillPrescription.types";

// Custom components
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import SimpleCheckbox from "lib/components/formInputs/Checkbox/SimpleCheckbox";
import RefillPrescription from "../prescription/RefillPrescription";
import PrescriptionRecordsSection from "ui/medrefill/components/virtualconsult/Collection/PrescriptionRecordsSection/PrescriptionRecordsSection";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";

import { setFileUploadPrescriptionError } from "ui/medrefill/ducks/medrefillSlice";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectFileUpload } from "ui/medrefill/ducks/selectors";
import { selectUser } from "lib/redux/user/selectors";
import { setOldTokenizedId } from "lib/redux/user/userSlice";

const Collection = ({
  isEditablePage = false,
  requestType,
  pathToNextStep,
  collectionChoices,
  collectionState,
  userState,
  facilityId,
  prescriptionType,
  prescribedMedicationConsentFlag,
  preventDirectBackNavigationState,
  prescriptionState,
  institutionState,
  defaultMedicationCardDataList,
  documentList,
  onSelectCollection,
  onSelectPrescription,
  resetPreventDirectBackNavigationState,
}: CollectionProps) => {
  const classes = sxStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Redux States
  const { fileUploadPrescriptionErrorArray } = useAppSelector(selectFileUpload);
  const { oldTokenizedId } = useAppSelector(selectUser);

  // --- Set up Initial State
  const [collection, setCollection] = useState<CollectionState>({
    typeId: collectionState.typeId,
    type: collectionState.type,
    label: collectionState.label,
    locationId: collectionState.locationId,
    locationName: collectionState.locationName,
    timeslot: collectionState.timeslot,
    date: collectionState.date,
    specialRequest: collectionState.specialRequest,

    // My Registered Address details
    postalCode: collectionState.postalCode,
    blockNo: collectionState.blockNo,
    unitNo: collectionState.unitNo,
    address: collectionState.address,
    errorPostalCode: collectionState.errorPostalCode,
  });

  // --- STATES FOR MEDICATIONS
  // Represents the initial, default state of the medication cards
  let initialMedicationCardDataListState: MedicationCardData[] =
    defaultMedicationCardDataList;
  if (
    !defaultMedicationCardDataList ||
    defaultMedicationCardDataList.length === 0
  ) {
    // if is some null, undefined or empty list value, then it means that the user hasn't set any prescription sheet yet

    initialMedicationCardDataListState = [
      {
        frontFileName: null,
        frontFileData: null,
        backFileName: null,
        backFileData: null,
      },
    ];
  }

  // Sort collection choices based on order returned by API
  const collectionChoicesArray = [...collectionChoices];

  const defaultChoices = collectionChoicesArray.sort(
    (a, b) => a.OrderByCollectionMode - b.OrderByCollectionMode,
  );

  const homeDeliveryChoice = collectionChoices.find(
    (element) =>
      element.CollectionType.toString().toLowerCase() ===
      COLLECTION_TYPES.HOME_DELIVERY.TYPE,
  );
  const filteredArray = defaultChoices.filter(
    (element) =>
      element.CollectionType.toString().toLowerCase() !==
      COLLECTION_TYPES.HOME_DELIVERY.TYPE,
  );
  // Sorting of collection choices specific to VCP
  const vcpCollectionChoices = homeDeliveryChoice
    ? [homeDeliveryChoice, ...filteredArray]
    : [];

  const [pageFlag, setPageFlag] = useState(false);

  // --- API Call based on location type (radio button choice selected by the user)
  const [
    locations,
    isLoading,
    hasErrored,
    errorMessage,
    oldTokenizedIdFromRes,
  ] = useGetLocations(
    pageFlag,
    facilityId,
    collection.type,
    requestType === REQUEST_TYPES.VCP ? "refill" : prescriptionType,
    oldTokenizedId,
  );

  useEffect(() => {
    if (oldTokenizedIdFromRes) {
      dispatch(setOldTokenizedId(oldTokenizedIdFromRes));
    }
  }, [dispatch, oldTokenizedIdFromRes]);

  //save form state when back navigation
  React.useEffect(() => {
    if (!isEditablePage && preventDirectBackNavigationState) {
      if (requestType === REQUEST_TYPES.REFILL) {
        saveFormState();
      }
      onSelectCollection(collection);
      resetPreventDirectBackNavigationState();
      navigate(-1);
    }
    // we only want this hook to fire on back navigation
    // eslint-disable-next-line
  }, [preventDirectBackNavigationState]);

  const TODAY = new Date();

  //Auto select location if there is only single location
  React.useEffect(() => {
    if (locations && locations.length === 1) {
      const minDate = formatDateToISO(getMinDate(locations[0], TODAY));
      setCollection({
        ...collection,
        locationId: locations[0].LocationId,
        locationName: locations[0].LocationName,
        date: collection.date ? collection.date : minDate,
        timeslot: collection.date ? collection.timeslot : null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  const location = findLocationByLocationId(
    locations ?? [],
    collection.locationId,
  );

  const timeslotOptions = location?.Timeslots?.find(
    (t) => t.Day === getDateFromISOString(collection.date)?.getDay(),
  )?.Slots?.map((timeslot) => {
    const element: IterableElement = {
      id: timeslot,
      text: timeslot,
    };
    return element;
  });

  // Prescription Option Checkbox - initial state set to true if previously file was uploaded
  const [isPrescriptionChecked, setIsPrescriptionChecked] = useState(
    initialMedicationCardDataListState[0].frontFileData === null ? false : true,
  );

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const [date, setDate] = useState<string | null>(
    prescriptionState.refillOriginalPrescriptionDate,
  );

  const [medicationCardDataList, setMedicationCardDataList] = useState<
    MedicationCardData[]
  >(initialMedicationCardDataListState);

  const medicationCardDataListWithFilter = medicationCardDataList.filter(
    (file) => file.frontFileName !== null || file.backFileName !== null,
  );

  const saveFormState = () => {
    // save all without doing filter if it is back navigation, else save the filtered list
    const cardDataList = preventDirectBackNavigationState
      ? medicationCardDataList
      : medicationCardDataListWithFilter;

    onSelectPrescription({
      refillOriginalPrescriptionDate: date,
      files: cardDataList.map((med, index) => {
        return {
          front: {
            fileName: med.frontFileName,
            fileData: med.frontFileData,
          },
          back: {
            fileName: med.backFileName,
            fileData: med.backFileData,
          },
          topUp: {
            quantity: null,
            UOMId: null,
            UOM: null,
            medicationName: null,
          },
          prescription: "Medication " + (index + 1).toString(),
        };
      }),
      topupReason: null,
      topupReasonText: null,
      topupOtherReason: null,
      clinicId: 0,
    });
  };

  return (
    <StepPageLayoutContainer
      editableFlag={isEditablePage}
      isNextButtonDisabled={false}
      currentStepIndex={
        requestType === REQUEST_TYPES.REFILL ||
        requestType === REQUEST_TYPES.TOPUP
          ? 2
          : 1
      }
      shouldShowButtonsFooter={false}
      pathToNextStep={pathToNextStep ?? ""}
      onNextStep={() => {}}
    >
      <Box mt={3} ml={2} mr={2}>
        {prescriptionType === "virtualconsult" && (
          <SharpNoticePanel bgColor="extremeWarn">
            If your doctor indicates this is an urgent order, it will be
            processed in 1 or 2 days
          </SharpNoticePanel>
        )}

        <FormControl component="fieldset" fullWidth sx={classes.formpage}>
          <Typography sx={classes.formTitle}>
            {prescriptionType === "virtualconsult"
              ? "How would you like to receive your prescribed medication?"
              : "How would you like to receive your order?"}
          </Typography>

          {prescriptionType === "virtualconsult" &&
            shouldDisplayPrescribedMedication(userState.relationType) && (
              <PrescriptionRecordsSection
                prescribedMedicationConsentFlag={prescribedMedicationConsent(
                  userState.relationType,
                  prescribedMedicationConsentFlag,
                )}
              />
            )}

          {/* COLLECTION TYPE SELECTION */}
          <RadioGroup
            value={collection.type}
            onChange={(event) => {
              const selectedObj = defaultChoices.find(
                (ele) =>
                  event.target.value.toString().toLowerCase() ===
                  ele.CollectionType.toString().toLowerCase(),
              );

              const typeOfSelected =
                selectedObj?.CollectionType.toString().toLowerCase();
              const indexOfSelected = parseInt(
                selectedObj?.CollectionCode ?? "",
              );
              //reload user profile address fields if home delivery option is chosen, otherwise clear the address related input fields

              const typeSelected = event.target.value;
              const addrField =
                typeOfSelected === COLLECTION_TYPES.HOME_DELIVERY.TYPE
                  ? {
                      postalCode: userState.postalCode,
                      blockNo: userState.block,
                      unitNo: userState.unitNo,
                      address: userState.streetAddress,
                    }
                  : {
                      postalCode: null,
                      blockNo: null,
                      unitNo: null,
                      address: null,
                    };
              setCollection({
                ...collection,

                // Set to appropriate collection type id
                typeId: indexOfSelected,

                type: typeSelected,

                label: selectedObj?.CollectionValue ?? "",

                // Reset the rest of the fields too, because each choice will provide
                // different conditions that the user must fill up again
                locationId: null,
                locationName: null,
                date: null,
                timeslot: null,
                specialRequest: null,
                ...addrField,
              });
            }}
          >
            {prescriptionType !== "virtualconsult"
              ? defaultChoices.map((choice) => (
                  <CollectionRadio
                    key={choice.CollectionType}
                    value={choice.CollectionType}
                    label={choice.CollectionValue}
                  />
                ))
              : vcpCollectionChoices.map((choice) => (
                  <CollectionRadio
                    key={choice.CollectionType}
                    value={choice.CollectionType}
                    label={choice.CollectionValue}
                  />
                ))}
          </RadioGroup>

          {collection.type === null ? null : isLoading ? (
            <Box
              display="flex"
              align-items="center"
              justifyContent="center"
              mt={4}
            >
              <CircularProgress />
            </Box>
          ) : hasErrored ? (
            <ErrorDisplay
              errorMessage={errorMessage}
              onTryAgain={() => setPageFlag(!pageFlag)}
            />
          ) : (
            <>
              {/* Special Request for virtual consult */}
              {prescriptionType === "virtualconsult" && (
                <Box pt={2} sx={classes.formTitle}>
                  <MultiLineTextField
                    value={collection.specialRequest || ""}
                    placeholder="Special instructions (e.g. I only need 2 week’s worth of medication)"
                    rowNumber={3}
                    maxNumber={5}
                    maxCharLength={200}
                    handleChange={(event) => {
                      setCollection({
                        ...collection,
                        specialRequest: event.target.value,
                      });
                    }}
                  />
                </Box>
              )}

              {locations &&
              (collection.type.toString().toLowerCase() ===
                COLLECTION_TYPES.PHARMACY.TYPE ||
                collection.type.toString().toLowerCase() ===
                  COLLECTION_TYPES.MEDLOCKER.TYPE) ? (
                // COLLECTION FORM (Pick Up At Pharmacy)
                <PickUpAtPharmacy
                  title={collection.label}
                  type={collection.type}
                  chosenLocation={location}
                  locations={locations}
                  timeslotOptions={timeslotOptions}
                  preferredCollectionDate={collection.date}
                  preferredCollectionTimeslot={collection.timeslot}
                  onSelectPreferredCollectionDate={(date) => {
                    setCollection({
                      ...collection,
                      date: formatDateToISO(date),
                      timeslot: null,
                    });
                  }}
                  onSelectLocation={(locationId) => {
                    const location = findLocationByLocationId(
                      locations,
                      locationId,
                    );
                    let minDate: string | null = null;
                    if (location) {
                      minDate = formatDateToISO(getMinDate(location, TODAY));
                    }
                    setCollection({
                      ...collection,
                      locationId: locationId,
                      locationName: location?.LocationName || "",
                      date: minDate,
                      timeslot: null,
                    });
                  }}
                  onSelectPreferredCollectionTimeslot={(timeslot) => {
                    setCollection({
                      ...collection,
                      timeslot: timeslot,
                    });
                  }}
                />
              ) : null}

              {locations &&
              collection.type.toString().toLowerCase() ===
                COLLECTION_TYPES.HOME_DELIVERY.TYPE ? (
                // COLLECTION FORM (Delivery)
                <Delivery
                  requestType={requestType}
                  chosenLocation={location}
                  collection={collection}
                  locations={locations}
                  facilityId={facilityId}
                  location={locations[0]}
                  deliveryTimeSlotId={collection.timeslot}
                  formLabelText={
                    prescriptionType === "virtualconsult"
                      ? "Home delivery address"
                      : "Delivery to address"
                  }
                  datepickerLabelText={
                    prescriptionType === "virtualconsult"
                      ? "Preferred Collection Date"
                      : "Preferred Delivery Date"
                  }
                  setCollection={setCollection}
                  onSelectLocation={(locationId) => {
                    const location = findLocationByLocationId(
                      locations,
                      locationId,
                    );

                    setCollection({
                      ...collection,
                      locationId: locationId,
                      locationName: location?.LocationName || "",
                    });
                  }}
                />
              ) : null}

              {/* Special Request for refill or topup */}
              {prescriptionType !== "virtualconsult" && (
                <Box pt={1} pb={2} sx={classes.formTitle}>
                  <MultiLineTextField
                    value={collection.specialRequest || ""}
                    placeholder="Special request (if any)"
                    rowNumber={3}
                    maxNumber={5}
                    maxCharLength={200}
                    handleChange={(event) => {
                      setCollection({
                        ...collection,
                        specialRequest: event.target.value,
                      });
                    }}
                  />
                </Box>
              )}

              {/* Prescription Option Checkbox - controlled */}
              {requestType === REQUEST_TYPES.REFILL ? (
                <>
                  <SimpleCheckbox
                    checked={isPrescriptionChecked}
                    label={MapRawStringToReactElement(
                      "My prescription is not an electronic prescription. Or I would like to specify which prescription to use",
                    )}
                    value={"prescriptionOptionCheck"}
                    handleChange={() => {
                      if (isPrescriptionChecked) {
                        setIsConfirmOpen(true);
                      } else {
                        setIsPrescriptionChecked(!isPrescriptionChecked);
                      }
                    }}
                  />

                  {isPrescriptionChecked ? (
                    <RefillPrescription
                      prescriptionState={prescriptionState}
                      institutionState={institutionState}
                      documentList={documentList}
                      onSelectPrescription={onSelectPrescription}
                      preventDirectBackNavigationState={
                        preventDirectBackNavigationState
                      }
                      resetPreventDirectBackNavigationState={
                        resetPreventDirectBackNavigationState
                      }
                      medicationCardDataList={medicationCardDataList}
                      setMedicationCardDataList={setMedicationCardDataList}
                      date={date}
                      setDate={setDate}
                    />
                  ) : null}

                  <ConfirmationModal
                    title="Changing Prescription Format"
                    open={isConfirmOpen}
                    nextButtonText={"Confirm change"}
                    cancelButtonText={"Cancel"}
                    showCloseButton={true}
                    body={MapRawStringToReactElement(
                      "Changing to an electronic prescription format will result in the loss of manually entered prescription details.",
                    )}
                    onClose={() => {
                      setIsConfirmOpen(false);
                    }}
                    onClickNext={() => {
                      setIsConfirmOpen(false);
                      setIsPrescriptionChecked(false);
                      setMedicationCardDataList([
                        {
                          frontFileName: null,
                          frontFileData: null,
                          backFileName: null,
                          backFileData: null,
                        },
                      ]);
                      dispatch(
                        setFileUploadPrescriptionError([[false, false]]),
                      );
                    }}
                    onClickCancel={() => {
                      setIsConfirmOpen(false);
                    }}
                  />
                </>
              ) : null}
            </>
          )}
        </FormControl>
      </Box>

      {/* NEXT PAGE CONTROLS */}
      <StepPageButtonsFooter
        type={isEditablePage ? "EDIT" : "NEXT_STEP"}
        isNextButtonDisabled={shouldDisableNext(
          collection,
          locations,
          timeslotOptions,
          isPrescriptionChecked,
          medicationCardDataList,
          facilityId,
          requestType,
          fileUploadPrescriptionErrorArray,
        )}
        pathToNextStep={pathToNextStep}
        onNextStep={() => {
          onSelectCollection(collection);
          if (requestType === REQUEST_TYPES.REFILL) {
            saveFormState();
          }
        }}
      />
    </StepPageLayoutContainer>
  );
};

export default Collection;

// =====================
// HELPERS
// =====================

/**
 * Determines if should allow user to proceed, by enabling / disabling
 * the NEXT STEP button.
 *
 * @param {CollectionState} collection  Collection form fields' state object
 * @returns {boolean}  TRUE if the NEXT STEP button should be disabled, FALSE otherwise
 */
const shouldDisableNext = (
  collection: CollectionState,
  locations: Location[] | null,
  timeslotsOption: IterableElement[] | undefined,
  isPrescriptionChecked: boolean,
  medicationCardDataList: MedicationCardData[],
  facilityId: number | null,
  requestType: string | null,
  fileUploadPrescriptionErrorArray: boolean[][],
): boolean => {
  // Conditional check for Pharmacy Collection
  if (
    (collection.type?.toString().toLowerCase() ===
      COLLECTION_TYPES.PHARMACY.TYPE ||
      collection.type?.toString().toLowerCase() ===
        COLLECTION_TYPES.MEDLOCKER.TYPE) &&
    locations
  ) {
    if (
      collection.date &&
      (locations.length === 1 ||
        locations.find((loc) => loc.LocationId === collection.locationId))
    ) {
      // user has selected a date for pick up and also the pickup location, which is currently available
      if (timeslotsOption) {
        if (collection.timeslot) {
          if (isPrescriptionChecked) {
            if (
              !JSON.stringify(fileUploadPrescriptionErrorArray).includes(
                "true",
              ) &&
              medicationCardDataList[0].frontFileData !== null
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        if (isPrescriptionChecked) {
          if (
            !JSON.stringify(fileUploadPrescriptionErrorArray).includes(
              "true",
            ) &&
            medicationCardDataList[0].frontFileData !== null
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }
    }
  }

  // Conditional checks for Home Delivery
  if (
    collection.type?.toString().toLowerCase() ===
    COLLECTION_TYPES.HOME_DELIVERY.TYPE
  ) {
    // Disables NEXT if date and timeslot are not selected
    if (!(collection.date && collection.timeslot)) {
      return true;
    }

    // Disables NEXT if postalCode, blockNo, address are not filled and and there is no error in the PostalCode
    if (
      !(
        collection.postalCode &&
        collection.blockNo &&
        collection.address &&
        !collection.errorPostalCode
      )
    ) {
      return true;
    }

    // Disables NEXT if the Prescription Checkbox is checked and (no medication image is uploaded or uploading of file has errored)
    if (
      isPrescriptionChecked &&
      (JSON.stringify(fileUploadPrescriptionErrorArray).includes("true") ||
        medicationCardDataList[0].frontFileData === null)
    ) {
      return true;
    }

    // Disables NEXT if Prescribing Clinic is not selected only for NUHS
    if (
      facilityId === 41 &&
      requestType === REQUEST_TYPES.REFILL &&
      (collection.locationName === null ||
        collection.locationName === "Home Delivery")
    ) {
      return true;
    }

    // Enables NEXT
    return false;
  }

  // Disables NEXT if user hasn't selected a radio button yet
  return true;
};
