import { Detail } from "api/medrefill/GetMedicationSummaryDetail/getMedicationSummaryDetail.fromApi.types";
import { MedImage } from "api/medrefill/GetMedicationImages/getMedicationImages.fromApi.types";
import {
  MedRefillState,
  MedRefillPrescriptionFile,
} from "ui/medrefill/ducks/medrefill.types";
import { initialState } from "ui/medrefill/ducks";
import { UploadState } from "lib/components/upload/ImageUploader/ImageUploader.types";

export const mapPastRequestRefillDetailToMedrefillState = (
  summaryDetail: Detail | null,
  images: MedImage[] | null,
) => {
  let medrefillState: MedRefillState = { ...initialState };
  medrefillState.institution = { ...initialState.institution };
  medrefillState.particulars = { ...initialState.particulars };
  medrefillState.prescription = { ...initialState.prescription };
  medrefillState.quantity = { ...initialState.quantity };
  medrefillState.collection = { ...initialState.collection };
  medrefillState.payment = { ...initialState.payment };
  medrefillState.fileUpload = { ...initialState.fileUpload };
  if (summaryDetail) {
    medrefillState.requestType = summaryDetail.PrescripionType;

    medrefillState.institution.facilityId = summaryDetail.FacilityId;
    medrefillState.institution.facilityName = summaryDetail.Facility;
    medrefillState.institution.cluster = summaryDetail.Cluster;
    medrefillState.institution.clinicId = summaryDetail.ClinicId;

    medrefillState.particulars.requesterId = summaryDetail.RequesterId;
    medrefillState.particulars.requesterNric = summaryDetail.RequesterNric;
    medrefillState.particulars.requesterName = summaryDetail.RequesterName;
    medrefillState.particulars.isPatient = summaryDetail.IsPatient;
    medrefillState.particulars.patientName = summaryDetail.PatientName;
    medrefillState.particulars.patientNric = summaryDetail.PatientNric;
    medrefillState.particulars.contactNumber = summaryDetail.Contact;
    medrefillState.particulars.email = summaryDetail.Email;

    medrefillState.prescription.refillOriginalPrescriptionDate =
      summaryDetail.PrescriptionDate;

    let tmpFiles: MedRefillPrescriptionFile[] = [];
    if (images) {
      let runningSeq = 0;
      let nextRunningSeq = 0;
      let loopCounter = 0;
      while (loopCounter !== images.length) {
        let tmpImage: MedRefillPrescriptionFile = {
          front: {
            fileName: null,
            fileData: null,
          },
          back: {
            fileName: null,
            fileData: null,
          },
          topUp: {
            quantity: null,
            UOMId: null,
            UOM: null,
            medicationName: null,
          },
          prescription: null,
        };
        runningSeq = Number(
          images[loopCounter].Prescription.slice(
            images[loopCounter].Prescription.length - 1,
          ),
        );
        tmpImage.front.fileName = images[loopCounter].FileName;
        tmpImage.front.fileData = images[loopCounter].FileData;
        if (loopCounter + 1 !== images.length) {
          nextRunningSeq = Number(
            images[loopCounter + 1].Prescription.slice(
              images[loopCounter + 1].Prescription.length - 1,
            ),
          );
          if (runningSeq === nextRunningSeq) {
            tmpImage.back.fileName = images[loopCounter + 1].FileName;
            tmpImage.back.fileData = images[loopCounter + 1].FileData;
            loopCounter++;
          }
        }
        tmpFiles.push(tmpImage);
        loopCounter++;
      }
    }
    medrefillState.prescription.files = tmpFiles;

    if (tmpFiles.length > 0) {
      var errorArray: boolean[][] = [];
      var detailsArray: UploadState[][] = [];
      tmpFiles?.forEach((tmpFile) => {
        errorArray.push([false, false]);
        detailsArray.push([
          {
            hasUploadedSuccessfully: true,
            isLoading: false,
            fileName: tmpFile.front.fileName,
            fileData: tmpFile.front.fileData,
            errorMessage: null,
          },
          {
            hasUploadedSuccessfully: tmpFile.back.fileName ? true : false,
            isLoading: false,
            fileName: tmpFile.back.fileName,
            fileData: tmpFile.back.fileData,
            errorMessage: null,
          },
        ]);
      });
      medrefillState.fileUpload.fileUploadPrescriptionErrorArray = errorArray;
      medrefillState.fileUpload.fileUploadDetails = detailsArray;
    }

    medrefillState.quantity.typeId = summaryDetail.QuantityTypeId;
    if (summaryDetail.QuantityDetails) {
      medrefillState.quantity.details = summaryDetail.QuantityDetails.map(
        (detail) => ({
          prescriptionItemNo: detail.PrecriptionItemNo,
          number: detail.Number,
          UOMDurationId: detail.UOMDurationId,
          UOMDuration: detail.UOMDuration,
        }),
      );
    }

    medrefillState.collection.typeId = summaryDetail.CollectionTypeId;
    medrefillState.collection.locationId = summaryDetail.LocationId;
    medrefillState.collection.locationName = summaryDetail.Location;
    medrefillState.collection.date = summaryDetail.CollectionDate;
    medrefillState.collection.timeslot = summaryDetail.TimeSlot;
    medrefillState.collection.blockNo = summaryDetail.BlockNo;
    medrefillState.collection.unitNo = summaryDetail.UnitNo;
    medrefillState.collection.address = summaryDetail.Address;
    medrefillState.collection.postalCode = summaryDetail.PostalCode;
    medrefillState.collection.specialRequest = summaryDetail.SpecialRequest;

    medrefillState.payment.subsidy = summaryDetail.SubsidyId;
    medrefillState.payment.paymentInstructions =
      summaryDetail.PaymentInstructions;
    medrefillState.payment.subsidyDescription =
      summaryDetail.Subsidy?.split("|");
  }
  return medrefillState;
};
