import axios from "axios";
import { GET_REPORT_OPTIONS_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatGetReportOptionsErrorMessage } from "api/resources/messages/ErrorMessage";
import { GetReportOptionsResponsePayload } from "./getReportOptions.fromApi.types";

/**
 * Get:
 * 1. Patient relationship, returns error response if hospitalCode not provided,
 * 2. Department code, retrieve for all hospital when hospitalCode is null,
 * 3. Purpose code and description mappings along with report types, returns error response if hospitalCode not provided,
 * 4. Mode of deliveries, retrieve for all hospital when hospitalCode is null.
 * by hospital code
 *
 * POST
 * Endpoint: secure/MRRP/Data/GetReportOptions
 *
 * @param {string} hospitalCode Hospital code to retrieve values for, ADMC | CGH | IMH | KKH | KTPH | NUH | SGH | SKH | TTSH
 * @param {string} reportTypeCode Hospital code to retrieve values for, ADMC | CGH | IMH | KKH | KTPH | NUH | SGH | SKH | TTSH
 *
 */
const getReportOptions = async (
  hospitalCode: string,
  reportTypeCode: string,
  oldTokenizedId: string | null,
): Promise<GetReportOptionsResponsePayload> => {
  try {
    const response = await axios.post<GetReportOptionsResponsePayload>(
      GET_REPORT_OPTIONS_URL(),
      createRequestPayload(hospitalCode, reportTypeCode, oldTokenizedId),
      getRequestOptions(),
    );
    return parseResponse(response, GET_REPORT_OPTIONS_URL());
  } catch (error) {
    const msg = formatGetReportOptionsErrorMessage(GET_REPORT_OPTIONS_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  hospitalCode: string,
  reportTypeCode: string,
  oldTokenizedId: string | null,
) => ({
  HospitalCode: hospitalCode,
  ReportTypeCode: reportTypeCode,
  OldTokenizedId: oldTokenizedId,
});

export { getReportOptions };
