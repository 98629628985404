import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests/RequestOptions";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { GetMedicationSummaryDetailPayload } from "./getMedicationSummaryDetail.fromApi.types";
import { GET_MEDICATION_SUMMARY_DETAIL_URL } from "api/resources/configs/URL";
import { formatGetMedicationSummaryDetailErrorMessage } from "api/resources/messages/ErrorMessage";

/**
 * Fetches the Medication Summary Detail based on a specific requestorId
 *
 * POST
 * Endpoint: /GetMedicationSummaryDetail
 *
 * @param {number} requestorId Id of requestor
 * @param {string | null} memberIdentifer memberIdentifer of the patient
 */
const getMedicationSummaryDetail = async (
  requestorId: number,
  memberIdentifer: string | null,
  oldMemberIdentifier: string | null,
  oldTokenizedId: string | null,
): Promise<GetMedicationSummaryDetailPayload> => {
  try {
    const response = await axios.post<GetMedicationSummaryDetailPayload>(
      GET_MEDICATION_SUMMARY_DETAIL_URL(),
      createRequestPayload(
        requestorId,
        memberIdentifer,
        oldMemberIdentifier,
        oldTokenizedId,
      ),
      getRequestOptions(),
    );
    return parseResponse(response, GET_MEDICATION_SUMMARY_DETAIL_URL());
  } catch (error) {
    const msg = formatGetMedicationSummaryDetailErrorMessage(
      GET_MEDICATION_SUMMARY_DETAIL_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  requestorId: number,
  memberIdentifer: string | null,
  oldMemberIdentifier: string | null,
  oldTokenizedId: string | null,
) => {
  return {
    RequesterId: requestorId,
    MemberIdentifier: memberIdentifer,
    OldMemberIdentifier: oldMemberIdentifier,
    OldTokenizedId: oldTokenizedId,
  };
};

export { getMedicationSummaryDetail };
