const getBTInstitutionNameRegex = (institutionCode: string) => {
  switch (institutionCode) {
    case "NUH":
      return {
        Name: "National University Hospital",
        RegexValidation: "^EN[A-Z]{1}\\d{2}\\d{8}[A-Z]$",
      };
    case "NTFGH":
      return {
        Name: "NTFGH & JCH",
        RegexValidation: "^(EC|EM|ET|EX)[A-Z]{1}\\d{2}\\d{8}[A-Z]$",
      };
    case "ALEX":
      return {
        Name: "Alexandra Hospital",
        RegexValidation: "^EA[A-Z]{1}\\d{2}\\d{8}[A-Z]$",
      };
    // case "NUP":
    //   return {
    //     Name: "National University Polyclinics",
    //     RegexValidation:
    //       "^(FB|FC|FJ|FK|FM|FP|FQ|FT|FY)[A-Z]{1}\\d{2}\\d{8}[A-Z]$",
    //   };
    default:
      return {
        Name: null,
        RegexValidation: null,
      };
  }
};

const convertNumeric = (letter: string) => {
  letter = letter.toUpperCase();

  return letter.charCodeAt(0) - 64; // Get the ASCII value of the letter and subtract 64 to get the corresponding alphabetical position
};

const getBTInvoiceCheckDigitIndexForBfe = (invoiceNumber: string): boolean => {
  const invoiceNoFactors = [0, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0, 9, 8];
  const invoiceCheckDigitForBT = "ABCDEFGHKZJ";

  let sum = 0;

  for (let i = 0; i < invoiceNumber.length - 1; i++) {
    const x = invoiceNumber[i];
    if (isNaN(+x)) {
      sum += convertNumeric(x);
    } else {
      sum += parseInt(x) * invoiceNoFactors[i];
    }
  }

  const remainder = sum % 11;
  const subtractionResult = remainder - 11;
  const absoluteValue = Math.abs(subtractionResult);
  const lastChar = invoiceNumber[invoiceNumber.length - 1];
  return invoiceCheckDigitForBT[absoluteValue - 1] === lastChar;
};

const getNNInstitutionNameRegex = (institutionCode: string) => {
  switch (institutionCode) {
    case "NUH":
      return {
        Name: "National University Hospital",
        RegexValidation: "(^15\\d{8}[A-Z]-\\d{5}$)|(^15\\d{8}[A-Z]$)",
      };
    case "NTFGH":
      return {
        Name: "NTFGH & JCH",
        RegexValidation: "^\\d{8}[A-Z]$",
      };
    case "ALEX":
      return {
        Name: "Alexandra Hospital",
        RegexValidation: "(^28\\d{8}[A-Z]-\\d{5}$)|(^28\\d{8}[A-Z]$)",
      };
    default:
      return {
        Name: null,
        RegexValidation: null,
      };
  }
};

const getNNInvoiceCheckDigitIndexForBfe = (invNo: string): number => {
  const invoiceNoFactorsForBfe = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  let index = -1;
  let sum = 0;

  // Remove last character on string
  const lastChar = invNo.slice(-1);
  const isLetter = invNo !== "" && /[a-zA-Z]/.test(lastChar);

  if (isLetter) {
    // Exclude the last character if letter
    invNo = invNo.slice(0, -1);
  }

  // Move bill number(only numbers) into a 10 numeric number format, excluding the visit number -VVVVV
  // if length < 10, prefix 0
  if (invNo.length === 9) {
    invNo = "0" + invNo;
  }
  if (invNo.length === 8) {
    invNo = "00" + invNo;
  }

  // A. Multiply each of the 10 numeric digits based on weightage table below and sums up the total
  for (let i = 0; i < invNo.length; i++) {
    const x = invNo[i];
    if (/[a-zA-Z]/.test(x)) {
      sum += 0;
    } else {
      sum += parseInt(x, 10) * invoiceNoFactorsForBfe[i];
    }
  }

  // B. Divide total number by 11 to determine the remainder e.g<Total> mod 11
  index = sum % 11;

  // C. Subtract remainder (B) from 11
  index = 11 - index;

  // D. Based on result C), look up the Check Digit

  return index;
};

const CheckBillFormat = (
  btregex: string,
  nnregex: string,
  invoiceNumber: string,
  institutionCode: string,
): boolean => {
  let result = false;

  // Step 1: Check the old regex format for NUHS institutions
  result = CheckNNBillFormat(nnregex, invoiceNumber, institutionCode);
  // Step 2: if old regex fails, check for new format
  if (result) {
    return result;
  } else {
    return CheckBTBillFormat(btregex, invoiceNumber, institutionCode);
  }
};

const CheckBTBillFormat = (
  btregex: string,
  invoiceNumber: string,
  institutionCode: string,
): boolean => {
  let result = false;

  if (
    btregex === "" ||
    invoiceNumber === "" ||
    institutionCode === "" ||
    invoiceNumber.length !== 14
  ) {
    return result;
  }

  // Step 1: Check the regex format for NUHS institutions
  try {
    result = new RegExp(btregex).test(invoiceNumber);
  } catch (e) {
    return false;
  }

  // Step 2: Perform Check digit validation
  if (result) {
    // Validation if check digit character matched with the index list
    result = getBTInvoiceCheckDigitIndexForBfe(invoiceNumber);
  }

  return result;
};

const CheckNNBillFormat = (
  nnregex: string,
  invoiceNumber: string,
  institutionCode: string,
): boolean => {
  let result = false;
  const invoiceCheckDigitForBfe = "ABCDEFGHIZJ";

  if (institutionCode === "NUP") {
    return true;
  } else if (nnregex === "" || invoiceNumber === "" || institutionCode === "") {
    return result;
  }

  // Step 1: Check the old regex format for NUHS institutions
  try {
    result = new RegExp(nnregex).test(invoiceNumber);
  } catch (e) {
    return false;
  }

  // Step 2: Perform Check digit validation
  if (result && institutionCode.toUpperCase() !== "NUP") {
    // Get only string before visit number if any
    if (invoiceNumber.includes("-")) {
      invoiceNumber = invoiceNumber.substring(0, invoiceNumber.indexOf("-"));
    }

    // Validation if check digit character matched with the index list
    const idx = getNNInvoiceCheckDigitIndexForBfe(invoiceNumber);
    const checkDigit = invoiceCheckDigitForBfe[idx - 1];

    result = invoiceNumber.endsWith(checkDigit);
  }

  return result;
};

export const billRefValidator = (
  institutionCode: string,
  billReferenceNo: string,
) => {
  const NNRegexValidation =
    getNNInstitutionNameRegex(institutionCode).RegexValidation;
  const BTRegexValidation =
    getBTInstitutionNameRegex(institutionCode).RegexValidation;
  const isValidFormat = CheckBillFormat(
    BTRegexValidation ?? "",
    NNRegexValidation ?? "",
    billReferenceNo.toLocaleUpperCase(),
    institutionCode,
  );
  return isValidFormat;
};
