import { connect } from "react-redux";
import { RootState } from "lib/redux/root/redux.types";
import PastReportDetailPage from "./PastReportDetailPage";

const mapStateToProps = (state: RootState) => {
  const allPastReports = state.mrrp.reportHistory.allPastReports;
  const selectedIndex = state.mrrp.reportHistory.selectedPastReportIndex;

  return {
    pastReport: selectedIndex !== null ? allPastReports[selectedIndex] : null,
    memberIdentifier: state.user.memberIdentifier,
  };
};

export default connect(mapStateToProps)(PastReportDetailPage);
