import {
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  useTheme,
} from "@mui/material";
import { sxStyles } from "./SimpleCheckbox.styles";
import { SimpleCheckboxProps } from "./SimpleCheckbox.types";
import IMAGES from "lib/assets/images";

const SimpleCheckbox = ({
  checked = false,
  value,
  label,
  preventLabelClick = false,
  disabled = false,
  disclaimerText,
  required = false,
  ariaLabel,
  handleChange,
}: SimpleCheckboxProps) => {
  const theme = useTheme();
  const classes = sxStyles(theme);

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            sx={classes.checkbox}
            icon={
              disabled ? (
                <Box
                  sx={classes.checkIcon}
                  aria-label="disabled unchecked"
                  component={"img"}
                  src={IMAGES.general.CheckBoxOutlineBlankIconDisabled}
                />
              ) : (
                <Box
                  sx={classes.checkIcon}
                  aria-label="enabled unchecked"
                  component={"img"}
                  src={IMAGES.general.CheckBoxOutlineBlankIcon}
                />
              )
            }
            checkedIcon={
              disabled ? (
                <Box
                  sx={classes.checkIcon}
                  aria-label="disabled checked"
                  component={"img"}
                  src={IMAGES.general.CheckBoxIconDisabled}
                />
              ) : (
                <Box
                  sx={classes.checkIcon}
                  aria-label="enabled checked"
                  component={"img"}
                  src={IMAGES.general.CheckBoxIcon}
                />
              )
            }
            checked={checked}
            disabled={disabled}
            onChange={handleChange}
            color="primary"
            inputProps={{ "aria-label": ariaLabel }}
          />
        }
        disabled={disabled}
        label={preventLabelClick ? null : label}
        value={value}
        sx={classes.checkboxText}
      />

      {preventLabelClick && (
        <Box ml={4} mt={-4}>
          {label}
        </Box>
      )}

      {disclaimerText && (
        <Typography sx={classes.disclaimerText}>{disclaimerText}</Typography>
      )}

      {required && <Typography sx={classes.requiredText}>*Required</Typography>}
    </Box>
  );
};

export default SimpleCheckbox;
