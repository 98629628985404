import React from "react";
import { Box } from "@mui/material";
import Page from "lib/components/layout/Page/Page";
import VerticalSpreadLayout from "lib/components/layout/VerticalSpreadLayout/VerticalSpreadLayout";
import { PAYMENT_STEP_LABELS } from "./Steps";
import StepIndicator from "lib/components/wizard/StepIndicator/StepIndicator";
import { StepPageLayoutProps } from "./StepPageLayout.types";
import { sxStyles } from "./StepPageLayout.styles";

// ============================
// Step Indicator
// ============================

const renderStepIndicator = (currentStepIndex: number) => {
  return (
    <StepIndicator
      steps={PAYMENT_STEP_LABELS}
      currentStepIndex={currentStepIndex}
    />
  );
};

// ============================
// COMPONENT
// ============================

const StepPageLayout = ({
  shouldRenderStepHeader = true,
  children,
  currentStepIndex,
}: StepPageLayoutProps) => {
  const classes = sxStyles(shouldRenderStepHeader);

  return (
    <Page>
      {shouldRenderStepHeader && renderStepIndicator(currentStepIndex)}
      <VerticalSpreadLayout>
        <Box sx={classes.formSection}>{children}</Box>
      </VerticalSpreadLayout>
    </Page>
  );
};

export default StepPageLayout;
