import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PatientMasterProfile,
  PreferredMailingAddPage,
} from "api/patientMaster/patientMasterProfile.types";
import { initialState } from "./initialState";
import { MyInfoProfile } from "./pm.types";
const pmSlice = createSlice({
  name: "patientMaster ",
  initialState,
  reducers: {
    //HHPS System Settings Reducers
    setSystemSettingsIsLoading(state, action) {
      state.systemSettings.isLoading = action.payload;
    },
    setSystemSettingsHasErrored(state, action) {
      state.systemSettings.hasErrored = action.payload;
    },
    setSystemSettingsErrorMessage(state, action) {
      state.systemSettings.errorMessage = action.payload;
    },
    setSystemSettingsValue(state, action) {
      state.systemSettings.value = action.payload;
    },

    //Epic Profile Reducers
    setEpicProfileIsLoading(state, action: PayloadAction<boolean>) {
      state.epicProfile.isLoading = action.payload;
    },
    setEpicProfileHasErrored(state, action: PayloadAction<boolean | null>) {
      state.epicProfile.hasErrored = action.payload;
    },
    setEpicProfileErrorMessage(state, action: PayloadAction<string | null>) {
      state.epicProfile.errorMessage = action.payload;
    },
    setEpicProfileState(state, action: PayloadAction<PatientMasterProfile>) {
      state.epicProfile.value = action.payload;
    },
    setOldEpicProfileState(state, action: PayloadAction<PatientMasterProfile>) {
      state.epicProfile.oldValue = action.payload;
    },
    setEpicProfileStateDates(
      state,
      action: PayloadAction<{
        DoB: string | null;
        PED: string | null;
        MEED: string | null;
        DED: string | null;
        MESD: string | null;
      }>,
    ) {
      state.epicProfile.value.DateOfBirth = action.payload.DoB;
      state.epicProfile.value.PassExpiryDate = action.payload.PED;
      state.epicProfile.value.MailingAddressExpiryDate = action.payload.MEED;
      state.epicProfile.value.DocumentTypeExpiry = action.payload.DED;
      state.epicProfile.value.MailingAddressStartDate = action.payload.MESD;
    },

    setEpicProfileTempState(
      state,
      action: PayloadAction<PreferredMailingAddPage | undefined>,
    ) {
      state.epicProfile.tempValue = action.payload;
    },

    setEpicProfileMailingAddressExpiryDate(
      state,
      action: PayloadAction<string>,
    ) {
      state.epicProfile.value.MailingAddressExpiryDate = action.payload;
    },
    setEpicProfilePreferredPostalCode(state, action: PayloadAction<string>) {
      state.epicProfile.value.PreferredPostalCode = action.payload;
    },
    setEpicProfilePreferredStreet(state, action: PayloadAction<string>) {
      state.epicProfile.value.PreferredStreetName = action.payload;
    },
    setEpicProfilePreferredBlock(state, action: PayloadAction<string>) {
      state.epicProfile.value.PreferredBlockNumber = action.payload;
    },
    setEpicProfilePreferredUnit(state, action: PayloadAction<string>) {
      state.epicProfile.value.PreferredUnitNumber = action.payload;
    },
    setHasFetchedEpicProfile(state, action: PayloadAction<boolean>) {
      state.epicProfile.hasFetchedEpicProfile = action.payload;
    },
    setEpicProfileHasEpicProfile(state, action: PayloadAction<boolean>) {
      state.epicProfile.hasEpicProfile = action.payload;
    },

    //MyInfo Profile Reducers
    setMyInfoProfileIsLoading(state, action: PayloadAction<boolean>) {
      state.myInfoProfile.isLoading = action.payload;
    },
    setMyInfoProfileHasErrored(state, action: PayloadAction<boolean | null>) {
      state.myInfoProfile.hasErrored = action.payload;
    },
    setMyInfoProfileErrorMessage(state, action: PayloadAction<string | null>) {
      state.myInfoProfile.errorMessage = action.payload;
    },
    setMyInfoProfileState(state, action: PayloadAction<MyInfoProfile>) {
      state.myInfoProfile.value = action.payload;
    },

    // General Reducers
    setPreferredAddPostalCodeError(state, action: PayloadAction<boolean>) {
      state.general.errors.hasPostalCodeErrored = action.payload;
    },
    setPreferredStreetError(state, action: PayloadAction<boolean>) {
      state.general.errors.hasStreetErrored = action.payload;
    },
    setPreferredBlockError(state, action: PayloadAction<boolean>) {
      state.general.errors.hasBlockErrored = action.payload;
    },
    setPreferredDateError(state, action: PayloadAction<boolean>) {
      state.general.errors.hasDateErrored = action.payload;
    },

    setContactDetailsMobileError(state, action: PayloadAction<boolean>) {
      state.general.errors.hasMobileErrored = action.payload;
    },
    setContactDetailsSMSMobileError(state, action: PayloadAction<boolean>) {
      state.general.errors.hasSMSMobileErrored = action.payload;
    },
    setContactDetailsEmailError(state, action: PayloadAction<boolean>) {
      state.general.errors.hasEmailErrored = action.payload;
    },
    setContactDetailsWorkNumberError(state, action: PayloadAction<boolean>) {
      state.general.errors.hasWorkNumberErrored = action.payload;
    },
    setContactDetailsHomeNumberError(state, action: PayloadAction<boolean>) {
      state.general.errors.hasHomeNumberErrored = action.payload;
    },
    setNRICError(state, action: PayloadAction<boolean>) {
      state.general.errors.hasNRICErrored = action.payload;
    },
    setBypassEpicProfile(state, action: PayloadAction<boolean>) {
      state.general.bypassEpicProfile = action.payload;
    },
  },
});

export const {
  setSystemSettingsIsLoading,
  setSystemSettingsHasErrored,
  setSystemSettingsErrorMessage,
  setSystemSettingsValue,
  setEpicProfileIsLoading,
  setEpicProfileHasErrored,
  setEpicProfileErrorMessage,
  setEpicProfileState,
  setOldEpicProfileState,
  setEpicProfileTempState,
  setEpicProfileStateDates,
  setEpicProfilePreferredPostalCode,
  setEpicProfilePreferredStreet,
  setEpicProfilePreferredBlock,
  setEpicProfilePreferredUnit,
  setEpicProfileHasEpicProfile,
  setHasFetchedEpicProfile,
  setMyInfoProfileIsLoading,
  setMyInfoProfileHasErrored,
  setMyInfoProfileErrorMessage,
  setMyInfoProfileState,
  setPreferredAddPostalCodeError,
  setPreferredStreetError,
  setPreferredBlockError,
  setPreferredDateError,
  setContactDetailsMobileError,
  setContactDetailsSMSMobileError,
  setContactDetailsEmailError,
  setContactDetailsWorkNumberError,
  setContactDetailsHomeNumberError,
  setNRICError,
  setEpicProfileMailingAddressExpiryDate,
  setBypassEpicProfile,
} = pmSlice.actions;

export default pmSlice.reducer;
