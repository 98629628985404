import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    mainContainer: {
      display: "inline-flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 0,
    },
    iconContainer: {
      width: "24px",
      height: "24px",
      marginBottom: "2px",
    },
    text: {
      typography: "caption2_semibold",
      color: "primary.main",
      lineHeight: "10px",
      textAlign: "center",
      overflow: "visible",
    },
  } satisfies SxObjType;
};

export { sxStyles };
