import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = (error: boolean | undefined) => {
  return {
    region: {
      pt: 1,
    },
    textFieldLabel: {
      color: theme.palette.primary.main,
      typography: theme.typography.body1_regular,
      "&.Mui-disabled": {
        color: theme.palette.grey[600],
      },
    },

    textfieldError: {
      "& .MuiInputBase-root": {
        "&.Mui-error ": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px",
            borderColor: theme.palette.error.main,
          },
          color: theme.palette.error.main,
        },
        "& .MuiFormLabel-root": {
          top: "-4px",
          color: theme.palette.error.main,
        },
      },
    },

    textfieldDisabled: {
      "& .MuiInputBase-root": {
        "&.Mui-disabled": {
          backgroundColor: theme.palette.secondary.light,
          borderWidth: "1px",
          borderColor: theme.palette.grey[100],
        },
        color: theme.palette.grey[600],
        "& .MuiInputBase-input.Mui-disabled": {
          "-webkit-text-fill-color": "initial",
          color: theme.palette.grey[400],
        },
      },
      "& .MuiFormLabel-root": {
        top: "-4px",
        color: theme.palette.grey[600],
      },
    },

    errText: {
      color: theme.palette.error.main,
      typography: theme.typography.caption1_regular,
      p: theme.spacing(0.5, 1.5),
    },
  } satisfies SxObjType;
};

export { sxStyles };
