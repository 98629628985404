import { PATHS } from "lib/routing/paths/paths";

/**
 * == PATHS THAT LISTEN TO shouldShowBackButtonOnEntry
 * Paths where back button display is determined according to the flag shouldShowBackButtonOnEntry.
 *
 * === Description:
 * This list of paths refer to pages that require custom display of back button.
 *
 * If a page is listed here, it means that the flag shouldShowBackButtonOnEntry is used to decide if back button is to be displayed,
 * instead of back button display configuration in PATHS file
 *
 * === Example / Explanation:
 * This is useful, for example, in the MedRefill landing page
 *
 * Here, we need to always show back button on landing page for the user if he accesses Medrefill from service listing,
 * but not when he accesses Medrefill from Medrefill tab
 *
 */
const PATH_WITH_DYNAMIC_BACK_BUTTON_DISPLAY_CONFIG = [
  PATHS.APPOINTMENT_MOBILE.path,
  PATHS.MEDICATIONS_MOBILE.path,
  PATHS.PAYMENT_MOBILE.path,
  PATHS.PAYMENT_PAYOR_INFO.path,
];

/**
 * Checks if the specified location subpath is contained in the list of dynamic back button config paths.
 * This method can be used to check if the path the user is currently at needs custom back button configuration.
 *
 * @param {string} locationPathName  Subpath in the URl containing the specific route to
 *   listen to external flag to decide if back button is to be displayed
 * @returns {boolean}  True if this path can be found, false otherwise
 */
const containsDynamicBackButtonConfigPath = (locationPathName: string) => {
  return PATH_WITH_DYNAMIC_BACK_BUTTON_DISPLAY_CONFIG.includes(
    locationPathName,
  );
};

export {
  PATH_WITH_DYNAMIC_BACK_BUTTON_DISPLAY_CONFIG,
  containsDynamicBackButtonConfigPath,
};
