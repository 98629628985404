import { Box, Button } from "@mui/material";
import { LinkedAppoinmentSectionProps } from "./LinkedAppointmentSection.types";
import AppointmentCardHeader from "ui/appointment/components/common/cards/AppointmentCardHeader/AppointmentCardHeader";
import AppointmentCardBody from "ui/appointment/components/common/cards/AppointmentCardBody/AppointmentCardBody";
import LinkedAppointmentCardSeparator from "../../LinkedAppointmentCardSeparator/LinkedAppointmentCardSeparator";
import { formatAddToCalendarURI } from "lib/mobileIntegration/addToCalendar/formatUrl";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import ShowMoreLessPanel from "lib/components/notice/ShowMoreLessPanel/ShowMoreLessPanel";
import IconTextButton from "lib/components/buttons/IconTextButton/IconTextButton";
import IMAGES from "lib/assets/images";
import { selectAppointments } from "ui/appointment/ducks/selectors";
import { useAppSelector } from "lib/redux/hooks";
import { sxStyles } from "./LinkedAppointmentSection.styles";

const LinkedAppointmentSection = ({
  isHighlighted = true,
  linkedAppointments,
  onSelectRegister,
}: LinkedAppoinmentSectionProps) => {
  const classes = sxStyles();
  const teleconsultMessage =
    useAppSelector(selectAppointments).teleconsultMessage;

  if (linkedAppointments && linkedAppointments.length !== 0) {
    return (
      <>
        <LinkedAppointmentCardSeparator />

        {/* Continue chain of linked appointments */}
        {linkedAppointments.map((appointment, index) => {
          const {
            appointmentId,
            datetime,
            institutionName,
            departmentName,
            clinician,
            location,
            zoneInfo,
            calendarTitle,
            calendarLocation,
            serviceDisplayName,
            visitType,
            isMobileRegAvailable,
            reminder,
            hasTeleconsultmessage,
            isVirtualConsultAppointment,
            appointmentIdToRegister,
          } = appointment;

          return (
            <Box key={appointmentId}>
              <Box sx={classes.cardContainer}>
                <AppointmentCardHeader
                  datetime={datetime}
                  isHighlighted={isHighlighted}
                />
                <AppointmentCardBody
                  institution={institutionName}
                  datetime={datetime}
                  departmentName={departmentName}
                  clinician={clinician}
                  location={location}
                  zoneInfo={zoneInfo}
                  serviceDisplayName={serviceDisplayName}
                  visitType={visitType}
                  isVcp={isVirtualConsultAppointment === true ? true : false}
                />
                {!isMobileRegAvailable && (
                  <Box sx={classes.addToCalendar}>
                    <IconTextButton
                      icon={IMAGES.appointment.AddToCalendarIcon}
                      label={["Add to", "calendar"]}
                      ariaLabel={"Add to calendar"}
                      onClick={() =>
                        mobileNavigate(
                          formatAddToCalendarURI(
                            appointmentId,
                            datetime,
                            calendarLocation,
                            institutionName,
                            calendarTitle,
                          ),
                          true,
                        )
                      }
                    />
                  </Box>
                )}
              </Box>
              <Box>
                {hasTeleconsultmessage && (
                  <Box
                    sx={classes.teleconsultMessage}
                    data-testid="teleconsultMessage"
                  >
                    <ShowMoreLessPanel message={teleconsultMessage} />
                  </Box>
                )}
                {reminder && (
                  <Box sx={classes.reminderText}>
                    <ShowMoreLessPanel message={reminder} />
                  </Box>
                )}
              </Box>

              {/* render Register button if linked appointment isn't the last because the last linked appointment will render register button on parent level */}
              {index + 1 !== linkedAppointments.length &&
                appointmentIdToRegister === appointmentId &&
                isMobileRegAvailable && (
                  <Box sx={{ mx: 1, my: 2 }}>
                    <Button
                      variant="contained"
                      sx={{ width: "100%" }}
                      onClick={onSelectRegister}
                    >
                      Register
                    </Button>
                  </Box>
                )}

              {index + 1 !== linkedAppointments.length ? (
                // not the last card in the linked appointments chain
                <LinkedAppointmentCardSeparator />
              ) : null}
            </Box>
          );
        })}
      </>
    );
  } else {
    return null;
  }
};

export default LinkedAppointmentSection;
