import { color } from "lib/theme/color";
import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    stepperRoot: {
      padding: theme.spacing(2, 0, 2, 0),
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
      left: "auto",
      right: "0",
      position: "fixed",
      width: "100%",
      zIndex: 100,
      marginTop: 0,
      backgroundColor: theme.palette.common.white,
    },

    stepConnectorAlternativeLabel: {
      "&.MuiStepConnector-alternativeLabel": {
        left: "calc(-50% + 8px)",
        right: "calc(50% + 8px)",
        marginTop: theme.spacing(0.5),
        zIndex: -1,
      },

      "& .MuiStepConnector-line": {
        borderColor: theme.palette.grey[100],
        borderRadius: 1,
      },

      "&.Mui-active .MuiStepConnector-line": {
        borderColor: theme.palette.warning.main,
      },

      "&.Mui-completed .MuiStepConnector-line": {
        borderColor: theme.palette.warning.main,
      },
    },

    stepLabelContainer: {
      "& .MuiStepLabel-alternativeLabel.MuiStepLabel-label": {
        color: color.RGBA_102,
        zIndex: 1,
        fontSize: size.FONTSIZE_10,
        marginTop: theme.spacing(0.5),
      },

      "& .MuiStepLabel-alternativeLabel.MuiStepLabel-label.Mui-active": {
        color: theme.palette.text.primary,
        typography: theme.typography.caption2_semibold,
      },
      "& .MuiStepLabel-alternativeLabel.MuiStepLabel-label.Mui-completed": {
        color: theme.palette.text.primary,
        typography: theme.typography.caption2_semibold,
      },
    },

    stepIconRoot: {
      width: theme.spacing(2),
      color: theme.palette.grey[100],

      "&.Mui-active": {
        color: theme.palette.warning.main,
        fontWeight: theme.typography.fontWeightMedium,
        width: theme.spacing(3),
      },
      "&.Mui-completed": {
        color: theme.palette.warning.main,
        fontWeight: theme.typography.fontWeightMedium,
        width: theme.spacing(3),
      },
      "& .MuiStepIcon-text": {
        fontSize: size.FONTSIZE_13,
        fill: "transparent",
      },
      "&.Mui-active .MuiStepIcon-text": {
        fill: theme.palette.common.white,
      },
      "&.Mui-completed .MuiStepIcon-text": {
        fill: theme.palette.common.white,
      },
    },
  } satisfies SxObjType;
};
export { sxStyles };
