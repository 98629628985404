import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import PageLayout from "lib/components/layout/Page/Page";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import AvailableSlotsHeader from "ui/appointment/components/common/availableSlots/AvailableSlotsHeader/AvailableSlotsHeader";
import AvailableBundledSlotsSectionContainer from "ui/appointment/components/common/availableSlots/AvailableBundledSlotsSection/AvailableBundledSlotsSectionContainer";
import { PATHS } from "lib/routing";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import {
  selectAppointmentDocByType,
  selectAppointmentMeta,
  selectAppointments,
} from "ui/appointment/ducks/selectors";
import RescheduleNotice from "ui/appointment/components/rescheduleAppointment/pages/availableSlots/RescheduleNotice/RescheduleNotice";
import { sxStyles } from "./CreateBundledLinkedApptAvailableSlotsPage.styles";
import { CREATE_APPOINTMENT_SLOT_MSG_KEY } from "api/shared/GetDocumentByType/getDocumentByType.mapper";
import { setCurrentFlowAction } from "lib/redux/navigation";
import { fetchDocumentForAllTypes } from "ui/appointment/ducks/thunks";
import { setSlotDetailsForCreateBundledLinkedAppointment } from "ui/appointment/ducks/appointmentsSlice";
// import { selectUser } from "lib/redux/user/selectors";

const CreateBundledLinkedApptAvailableSlotsPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const classes = sxStyles(theme);
  const navigate = useNavigate();

  // Redux State
  // const memberIdentifier = useAppSelector(selectUser).memberIdentifier;
  const maxSlot = useAppSelector(selectAppointmentMeta).maxSlot || 0;
  const {
    isSameDayBooking,
    appointmentType,
    // appointmentId,
    // institutionCode,
    // targetSystem,
    HSG,
  } = useAppSelector(selectAppointmentMeta);
  const {
    selectedInstitution: location,
    selectedService: service,
    lastVisitDateTime,
    isUrti,
  } = useAppSelector(selectAppointments).create;
  const isLoadingSlotAndPreConsultMsg = useAppSelector(
    selectAppointmentDocByType,
  ).isLoading;
  const slotMsg = useAppSelector(selectAppointmentDocByType).documentByTypeMap[
    CREATE_APPOINTMENT_SLOT_MSG_KEY
  ];
  const { centerContactNo } = useAppSelector(selectAppointmentMeta);

  useEffect(() => {
    dispatch(setCurrentFlowAction("APPOINTMENT_CREATE"));
    if (isLoadingSlotAndPreConsultMsg === null) {
      dispatch(fetchDocumentForAllTypes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //log event to google analytics
    if (isSameDayBooking) {
      logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENT_SAMEDAY);
    } else {
      logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENT_ADVANCE);
    }
  }, [isSameDayBooking]);

  // Dates to display in the notice
  const startDate = HSG.isHpCompleted
    ? HSG.subsequentStartDate
      ? new Date(HSG.subsequentStartDate)
      : new Date()
    : new Date();

  let endDate = new Date();

  endDate = HSG.isHpCompleted
    ? HSG.subsequentEndDate
      ? new Date(HSG.subsequentEndDate)
      : new Date()
    : new Date(endDate.setDate(endDate.getDate() + maxSlot));
  return (
    <PageLayout>
      <>
        <AvailableSlotsHeader
          location={location ?? ""}
          service={service}
          lastVisitDatetime={lastVisitDateTime}
          appointmentType={appointmentType}
          inAdvanceNoticeComponent={
            isSameDayBooking ? (
              <Box sx={classes.noticeContainer}>
                <SharpNoticePanel bgColor="warn">{slotMsg}</SharpNoticePanel>
              </Box>
            ) : (
              <RescheduleNotice />
            )
          }
        />

        <AvailableBundledSlotsSectionContainer
          isLinkedAppointment={true}
          isNewAppointment={true}
          isSameDayBooking={isSameDayBooking}
          minStartDate={startDate}
          maxEndDate={endDate}
          isUrti={isUrti}
          shouldGetAvailableSlots={
            appointmentType === "open" ? endDate !== null : true
          }
          onSelect={(selectedBundledSlots) => {
            const selectedBundledSlotsData = selectedBundledSlots.map(
              (slot) => {
                return {
                  datetime: slot.date,
                  slotId: slot.id,
                  resource: slot.resource,
                };
              },
            );

            dispatch(
              setSlotDetailsForCreateBundledLinkedAppointment([
                ...selectedBundledSlotsData,
              ]),
            );
            logEventToGoogleAnalytics(EVENTS.VIEW_RESCHEDULE_DETAILS);
            navigate(PATHS.APPOINTMENT_CREATE_BUNDLED_LINKED_CONFIRM.path);
          }}
          onCancel={() => {
            navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
          }}
          centerContactNo={centerContactNo}
        />
      </>
    </PageLayout>
  );
};

export default CreateBundledLinkedApptAvailableSlotsPage;
