import { connect } from "react-redux";
import Dashboard from "./Dashboard";
import { RootDispatch, RootState } from "lib/redux/root/redux.types";
import {
  setBillsToPay,
  setExpandedOutstandingBillsListFlag,
  setExpandedPaidBillsListFlag,
  setPaidBills,
  setShouldUpdate,
  setTransactionHistory,
} from "ui/payment/ducks";
import { fetchOutstandingBills } from "ui/payment/ducks";
import { PAYMENT_BILL_DASHBOARD_TEXT_KEY } from "api/shared/GetDocumentByType/getDocumentByType.mapper";
import { fetchDocumentForAllTypes } from "ui/payment/ducks/thunks";

const mapStateToProps = (state: RootState) => {
  return {
    shouldUpdateOutstandingBills: state.payments.outstandingBills.shouldUpdate,
    isLoadingOutstandingBills: state.payments.outstandingBills.isLoading,
    hasErroredOutstandingBills: state.payments.outstandingBills.hasErrored,
    errorMessageOutstandingBills: state.payments.outstandingBills.errorMessage,
    allOutstandingBills: state.payments.outstandingBills.allOutstandingBills,
    expandedOutstandingBillsListFlag:
      state.payments.outstandingBills.expandedOutstandingBillsListFlag,
    otherAddedBillList: state.payments.addedBills.allAddedBills,
    isLoadingDisclaimer: state.payments.documentByType.isLoading,
    disclaimer:
      state.payments.documentByType.documentByTypeMap[
        PAYMENT_BILL_DASHBOARD_TEXT_KEY
      ],
  };
};

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    onLoad: () => {
      // clearing existing data when back from transaction history page
      dispatch(setTransactionHistory([]));
      // clearing existing data when back from paid bills page
      dispatch(setPaidBills([]));
      // clearing amount to pay when back from details page
      dispatch(setBillsToPay([]));
      // changing paid bills flag as false when back from paid bills page
      setExpandedPaidBillsListFlag(false);
    },
    setExpandedOutstandingBillsListFlag: (flag: boolean) => {
      dispatch(setExpandedOutstandingBillsListFlag(flag));
    },
    callGetDocumentByType: () => {
      dispatch(fetchDocumentForAllTypes());
    },
    callGetOutstandingBills: () => {
      dispatch(fetchOutstandingBills());
    },
    onResetShouldUpdate: () => {
      dispatch(setShouldUpdate(true));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
