import { FormControl, Typography, FormLabel, Box } from "@mui/material";
import { sxStyles } from "../../AppointmentForm.styles";
import { WebApptFormFieldProps } from "./WebApptFormField.types";
import Datepicker from "lib/components/formInputs/Datepicker/Datepicker";
import nameof from "ts-nameof.macro";
import MultiLineTextField from "lib/components/formInputs/MultiLineTextField/MultiLineTextField";

const WebApptFormField = ({
  apptFormType,
  name,
  currentApptDateTime,
  serviceDisplayName,
  rescheduleApptFormField,
  handleChange,
}: WebApptFormFieldProps) => {
  const classes = sxStyles();

  const handleDatePickerDataChange = (id: string) => (date: Date) => {
    var event = { target: { name: id, value: date } };
    handleChange(event);
  };

  return (
    <>
      <Box sx={classes.layout}>
        <Typography variant="subtitle1" sx={classes.intensionText}>
          I want to{" "}
          <strong>
            {apptFormType === "reschedule" ? "reschedule" : "book"}
          </strong>{" "}
          an appointment at:
        </Typography>
        <Typography variant="subtitle1" sx={classes.intensionLocationText}>
          {name}
        </Typography>
      </Box>
      <FormControl component="fieldset" fullWidth>
        <FormLabel sx={classes.currentDateTimeformLabel}>
          {apptFormType === "open"
            ? "Open"
            : apptFormType === "missed"
              ? "Missed"
              : "Current"}{" "}
          Appointment
        </FormLabel>
        <Box sx={classes.apptDateTimeField}>
          <Box>
            <Typography variant="subtitle1" sx={classes.intensionText}>
              Date:
            </Typography>
            <Typography variant="subtitle1" sx={classes.intensionLocationText}>
              {currentApptDateTime}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" sx={classes.intensionText}>
              Service:
            </Typography>
            <Typography variant="subtitle1" sx={classes.intensionLocationText}>
              {serviceDisplayName}
            </Typography>
          </Box>
        </Box>
      </FormControl>
      <FormControl component="fieldset" fullWidth>
        <Box mt={3} mb={2}>
          <FormLabel sx={classes.formLabel}>Preferred Date(s)</FormLabel>
          <Box sx={classes.formSameLine}>
            <Box sx={classes.leftDatepickerContainer}>
              <Datepicker
                label="Start Date"
                value={rescheduleApptFormField.preferredStartDate}
                onSelect={handleDatePickerDataChange(
                  nameof(rescheduleApptFormField.preferredStartDate),
                )}
                disablePast={true}
                maxDate={rescheduleApptFormField.preferredEndDate}
              ></Datepicker>
            </Box>
            <Box sx={classes.rightDatepickerContainer}>
              <Datepicker
                label="End Date"
                value={rescheduleApptFormField.preferredEndDate}
                onSelect={handleDatePickerDataChange(
                  nameof(rescheduleApptFormField.preferredEndDate),
                )}
                disablePast={true}
                minDate={rescheduleApptFormField.preferredStartDate}
              ></Datepicker>
            </Box>
          </Box>
        </Box>
      </FormControl>

      <FormControl component="fieldset" fullWidth>
        <MultiLineTextField
          name={nameof(rescheduleApptFormField.remarks)}
          placeholder="Remarks"
          value={rescheduleApptFormField.remarks}
          rowNumber={3}
          maxNumber={5}
          maxCharLength={200}
          handleChange={handleChange}
        ></MultiLineTextField>
      </FormControl>
    </>
  );
};

export default WebApptFormField;
