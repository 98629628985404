import { useEffect, useState } from "react";
import { PaymentProps } from "./Payment.types";
import { Typography, Box, CircularProgress } from "@mui/material";
import { sxStyles } from "./Payment.styles";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import VerticalSpreadLayout from "lib/components/layout/VerticalSpreadLayout/VerticalSpreadLayout";
import { useNavigate } from "react-router-dom";
import { formatPayment } from "lib/util/StringUtil/formatPayment/formatPayment";
import Page from "lib/components/layout/Page/Page";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import { initialState } from "ui/mrrp/ducks";
import {
  navigateToExternalInMobileBrowser,
  navigateToExternalInAppWebView,
} from "lib/routing/navigate/navigate";
import IMAGES from "lib/assets/images";
import { PATHS } from "lib/routing";
import { setMessageToSend } from "lib/redux/navigation/navigationSlice";
import { MessageActions } from "lib/routing/messageChannel/messageActions";
import { useAppDispatch } from "lib/redux/hooks";

const Payment = ({
  submitPaymentApiStatus,
  memberIdentifier,
  selectedInstitutionCode,
  availablePaymentMode,
  paymentAmount,
  reportNo,
  reportTypeLabel,
  reportType,
  paymentToken,
  redirectionUrl,
  emailAddress,
  onResetPaymentApiStatus,
  onSetMrrpPersist,
  onSubmitPayment,
}: PaymentProps) => {
  const classes = sxStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const CREDIT = "CC";
  const DEBIT = "DD";
  const creditCard = (
    <img
      key="cc"
      src={IMAGES.payment.VisaMasterEnabled}
      alt="Visa / Master"
      onClick={() => setSelectedPaymentMode(CREDIT)}
    />
  );
  const directDebit = (
    <Box
      component={"img"}
      key="dd"
      src={IMAGES.payment.eNetsEnabled}
      alt="eNets"
      onClick={() => setSelectedPaymentMode(DEBIT)}
    />
  );
  const getPaymentCards = () => {
    if (availablePaymentMode === "C") {
      return creditCard;
    } else if (availablePaymentMode === "D") {
      return directDebit;
    } else {
      // availablePaymentMode is "B" or others
      // HealthHub is letting both options available for deault availablePaymentMode string
      return [creditCard, directDebit];
    }
  };
  const [selectedPaymentMode, setSelectedPaymentMode] = useState<string | null>(
    null,
  );

  useEffect(() => {
    // on load of the payment page

    // reseting api flags and session storage persist
    onResetPaymentApiStatus();
    onSetMrrpPersist(initialState.mrrpPersist);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // on select of payment mode, submit payment request
    if (selectedPaymentMode === CREDIT || selectedPaymentMode === DEBIT) {
      onSubmitPayment(
        selectedInstitutionCode,
        selectedPaymentMode,
        paymentAmount,
        reportNo,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMode]);

  // redirect when SubmitPayment API success
  useEffect(() => {
    if (
      !submitPaymentApiStatus.isLoading &&
      submitPaymentApiStatus.hasErrored === false &&
      redirectionUrl
    ) {
      // Persit the needed props for Receipt page API call: getPaymentStatusInfo
      onSetMrrpPersist({
        paymentToken,
        reportNo,
        reportTypeLabel,
        reportType,
        memberIdentifier,
        emailAddress,
        selectedPaymentMode,
      });
      if (selectedPaymentMode === CREDIT) {
        // send message to mobile to freeze c1 header before proceeding to payment gateway
        dispatch(
          setMessageToSend(
            MessageActions.staticTitle({ title: "Payment", isEnabled: true }),
          ),
        );
        // navigation to common payment gateway (external link)
        navigateToExternalInAppWebView(redirectionUrl);
      } else {
        // selectedPaymentMode === DEBIT
        // navigate to MRR receipt page directly
        navigateToExternalInMobileBrowser(redirectionUrl);
        navigate(PATHS.MRRP_NEW_REQUEST_RECEIPT.path);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitPaymentApiStatus]);

  return (
    <Page>
      {submitPaymentApiStatus.isLoading ? (
        <Box display="flex" align-items="center" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : submitPaymentApiStatus.hasErrored ? (
        <ErrorDisplayContainer
          errorMessage={submitPaymentApiStatus.errorMessage}
          // No onTryAgain since user probably want to try another payment method at fail
        />
      ) : (
        <VerticalSpreadLayout>
          <Box mt={3}>
            <Box sx={classes.amountBox}>
              <Typography sx={classes.grandTotalLabel}>Grand Total</Typography>
              <Typography sx={classes.grandTotalValue}>
                {formatPayment(paymentAmount)}
              </Typography>
            </Box>

            <Box mx={2}>
              <Typography sx={classes.paymentModeLabel}>
                Select Payment Mode
              </Typography>
              <Box sx={classes.payMethods}>{getPaymentCards()}</Box>
            </Box>
          </Box>

          <Box mb={3} mx={2}>
            <ButtonsFooter
              hideNextButton={true}
              nextButtonText=""
              cancelButtonText="Cancel"
              onClickCancel={() => navigate(-1)}
            ></ButtonsFooter>
          </Box>
        </VerticalSpreadLayout>
      )}
    </Page>
  );
};

export default Payment;
