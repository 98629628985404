import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_semibold,
      margin: theme.spacing(2, 0, 1.5, 0),
    },
    formLabel: {
      color: theme.palette.primary.main,
      typography: theme.typography.caption1_regular,
      margin: theme.spacing(0, 2),
    },
    text: {
      color: theme.palette.grey[400],
      typography: theme.typography.body1_regular,
      margin: theme.spacing(0, 2, 3, 2),
    },
    textBox: {},
    singleLineTextBox: {},
  } satisfies SxObjType;
};

export { sxStyles };
