import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { setCurrentFlowAction, setCustomisedBack } from "lib/redux/navigation";
import { RootState } from "lib/redux/root/redux.types";
import RescheBundledLinkedApptAvailableSlotsPage from "./RescheBundledLinkedApptAvailableSlotsPage";
import { updateRescheduleLinkedAppointmentVisitedSlotsPage } from "ui/appointment/ducks";
import { setSlotDetailsForRescheduleBundledLinkedAppointment } from "ui/appointment/ducks/appointmentsSlice";
import { AvailableBundledSlot } from "api/appointment/GetAvailableSlots/getAvailableSlots.toUi.types";

// ==========================
// MAP STATE TO PROPS
// ==========================

const mapStateToProps = (state: RootState) => {
  return {
    memberIdentifier: state.user.memberIdentifier,
    appointment:
      state.appointments.rescheduleLinkedAppointment.selectedLinkedAppointment,
    preventDirectBackNavigationState: state.navigation.customisedBack,
  };
};

// ==========================
// MAP DISPATCH TO PROPS
// ==========================

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onLoad: () => {
      dispatch(setCurrentFlowAction("APPOINTMENT_RESCHEDULE"));
    },
    onSelect: (selectedBundledSlots: AvailableBundledSlot[]) => {
      const selectedBundledSlotsData = selectedBundledSlots.map((slot) => {
        return {
          datetime: slot.date,
          slotId: slot.id,
          resource: slot.resource,
        };
      });

      dispatch(
        setSlotDetailsForRescheduleBundledLinkedAppointment([
          ...selectedBundledSlotsData,
        ]),
      );
    },
    updateVisitedSlotsPage: () => {
      dispatch(updateRescheduleLinkedAppointmentVisitedSlotsPage(true));
    },
    resetPreventDirectBackNavigationState: () => {
      dispatch(setCustomisedBack(false));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RescheBundledLinkedApptAvailableSlotsPage);
