import { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import { sxStyles } from "./Collection.styles";
import { CollectionProps } from "./Collection.types";
import CommonRadioButton from "lib/components/formInputs/CommonRadioButton/CommonRadioButton";
import AddressFields from "lib/components/formFields/AddressFields/AddressFields";
import { getAddressByPostalCode } from "api/medrefill/GetAdressByPostalCode/getAddressByPostalCode";
import { useNavigate } from "react-router-dom";
import StepPageButtonsFooter from "../../common/StepPageButtonsFooter/StepPageButtonsFooter";
import { PATHS } from "lib/routing";
import VerticalSpreadLayout from "lib/components/layout/VerticalSpreadLayout/VerticalSpreadLayout";
import { ViewMOD } from "api/mrrp/GetReportOptions/getReportOptions.fromApi.types";

const deliveryModeMapper = (deliveryModes: ViewMOD[]) => {
  return deliveryModes.map((deliveryMode) => ({
    id: deliveryMode.Code || "",
    text: deliveryMode.Description || "",
    ...(deliveryMode.Code === "DA"
      ? { disclaimerText: downloadOnAppText }
      : {}),
  }));
};

const downloadOnAppText =
  "Your report will be available for downloading in the app. Please check back later.";

const Collection = ({
  deliveryModes,
  profilePostalCode,
  profileBlock,
  profileUnitNo,
  profileStreetAddress,
  collection,
  preventDirectBackNavigationState,
  onSelectCollection,
  resetPreventDirectBackNavigationState,
}: CollectionProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();

  const defaultMode = deliveryModes.find((m) => m.Code === "DA");
  const [collectionState, setCollectionState] = useState({
    ...collection,
    postalCode: collection.postalCode ?? profilePostalCode,
    blockNo: collection.blockNo ?? profileBlock,
    floorAndUnit: collection.floorAndUnit ?? profileUnitNo,
    address: collection.address ?? profileStreetAddress,
    ...(!collection.deliveryMode && defaultMode
      ? {
          deliveryModeCode: defaultMode?.Code || null,
          deliveryMode: defaultMode?.Description || null,
        }
      : {}),
  });

  //save form state when back navigation
  useEffect(() => {
    if (preventDirectBackNavigationState) {
      onSelectCollection(collectionState);
      resetPreventDirectBackNavigationState();
      navigate(-1);
    }
    // we only want this hook to fire on back navigation
    // eslint-disable-next-line
  }, [preventDirectBackNavigationState]);

  const disableNext = () => {
    if (collectionState.deliveryModeCode === "DA") {
      return false;
    }

    if (
      collectionState.deliveryModeCode &&
      collectionState.postalCode &&
      !collectionState.errorPostalCode &&
      collectionState.blockNo &&
      collectionState.address
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <VerticalSpreadLayout>
      <Box sx={{ mx: 2 }}>
        <Typography sx={classes.title}>Mode of Delivery</Typography>
        <CommonRadioButton
          radioButtonSelectionList={deliveryModeMapper(deliveryModes)}
          selectedRadioButton={collectionState.deliveryModeCode}
          handleRadioButtonChange={(selected) => {
            const mode = deliveryModes.find((m) => m.Code === selected);
            setCollectionState({
              ...collectionState,
              deliveryModeCode: selected,
              deliveryMode: mode?.Description || null,
            });
          }}
        />

        {collectionState.deliveryModeCode !== "DA" && (
          <>
            <Typography sx={classes.title}>Delivery Address</Typography>
            <AddressFields
              postalCode={collectionState.postalCode}
              block={collectionState.blockNo}
              unitNo={collectionState.floorAndUnit}
              street={collectionState.address}
              showRequiredText={false}
              errorPostalCode={collectionState.errorPostalCode}
              onChangePostalCode={async (value) => {
                const errorPostalCode = value.length === 0 ? false : true;
                setCollectionState({
                  ...collectionState,
                  postalCode: value,
                  errorPostalCode,
                });
                if (value.length === 6) {
                  const addrPayload = await getAddressByPostalCode(value);
                  if (
                    addrPayload.HAS_VALID_ADDRESS &&
                    !addrPayload.HAS_ERRORED
                  ) {
                    setCollectionState({
                      ...collectionState,
                      postalCode: value,
                      blockNo: addrPayload.BLK_NO,
                      address: addrPayload.ROAD_NAME,
                      errorPostalCode: false, //hide error text 'Invalid postal code' when postal code is valid
                    });
                  }
                }
              }}
              onChangeBlock={(value) => {
                setCollectionState({
                  ...collectionState,
                  blockNo: value,
                });
              }}
              onChangeUnitNo={(value) => {
                setCollectionState({
                  ...collectionState,
                  floorAndUnit: value,
                });
              }}
              onChangeStreet={(value) => {
                setCollectionState({
                  ...collectionState,
                  address: value,
                });
              }}
            />
          </>
        )}
      </Box>

      <StepPageButtonsFooter
        isNextButtonDisabled={disableNext()}
        pathToNextStep={PATHS.MRRP_NEW_REQUEST_SUMMARY.path}
        onNextStep={() => onSelectCollection(collectionState)}
      />
    </VerticalSpreadLayout>
  );
};

export default Collection;
