import { connect } from "react-redux";
import { setCurrentFlowAction } from "lib/redux/navigation";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { RootState, RootDispatch } from "lib/redux/root/redux.types";
import { updateSlotDetailsForReschedule } from "ui/appointment/ducks";
import { isSameDayReschedule } from "api/appointment/GetAppointmentList/mappers/isSameDayBooking";
import RescheApptAvailableSlotsPage from "./RescheApptAvailableSlotsPage";
import { fetchDocumentForAllTypes } from "ui/appointment/ducks/thunks";

// ==========================
// MAP STATE TO PROPS
// ==========================

const mapStateToProps = (state: RootState) => {
  const resolvedSlotSearchDateRangeFrom = resolveSlotSearchDateTime(
    state.appointments.appointmentMeta.slotSearchDateRangeFrom,
  );
  const resolvedSlotSearchDateRangeTo = resolveSlotSearchDateTime(
    state.appointments.appointmentMeta.slotSearchDateRangeTo,
  );

  return {
    memberIdentifier: state.user.memberIdentifier,
    appointmentId: state.appointments.appointmentMeta.appointmentId,
    isSameDayBooking: resolveIsSameDayBooking(state),
    location: state.appointments.reschedule.selectedInstitution || "",
    service: state.appointments.reschedule.selectedService || "",
    currentDateTime: formatDate(state.appointments.reschedule.selectedDateTime),
    institutionCode: state.appointments.appointmentMeta.institutionCode,
    targetSystem: state.appointments.appointmentMeta.targetSystem,
    appointmentType: state.appointments.appointmentMeta.appointmentType,
    hasDefinedSlotSearchDatesFromAppointment: hasDefinedSlotSearchDates(
      resolvedSlotSearchDateRangeFrom,
      resolvedSlotSearchDateRangeTo,
    ),
    slotSearchDateRangeFrom: resolvedSlotSearchDateRangeFrom,
    slotSearchDateRangeTo: resolvedSlotSearchDateRangeTo,
    isLoadingSlotAndPreConsultMsg: state.appointments.documentByType.isLoading,
  };
};

const resolveIsSameDayBooking = (state: RootState) => {
  const deptCode = state.appointments.appointmentMeta.departmentCode;
  const logoCode = state.appointments.appointmentMeta.logoCode;
  const sameDayBookingFlag =
    state.appointments.appointmentMeta.isSameDayBooking;

  if (deptCode) {
    return isSameDayReschedule(deptCode, logoCode);
  } else {
    return sameDayBookingFlag;
  }
};

const resolveSlotSearchDateTime = (slotDateString: string | null) => {
  return getDateFromISOString(slotDateString);
};

const hasDefinedSlotSearchDates = (
  slotSearchDateRangeFrom: Date | null,
  slotSearchDateRangeTo: Date | null,
) => {
  if (slotSearchDateRangeFrom !== null && slotSearchDateRangeTo !== null) {
    return true;
  } else {
    return false;
  }
};

// ==========================
// MAP DISPATCH TO PROPS
// ==========================

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    onLoad: () => {
      dispatch(setCurrentFlowAction("APPOINTMENT_RESCHEDULE"));
    },
    onSelect: (slotId: string, slotDateTime: string | null) => {
      dispatch(
        updateSlotDetailsForReschedule({
          slotId,
          slotDateTime,
        }),
      );
    },
    fetchSlotAndPreConsultMsg: () => {
      dispatch(fetchDocumentForAllTypes());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RescheApptAvailableSlotsPage);
