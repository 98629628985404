import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { parseISO } from "date-fns";
import { Box, CircularProgress, Typography } from "@mui/material";
import AppointmentFormHeader from "../../AppointmentFormHeader/AppointmentFormHeader";
import CancelApptFormFieldContainer from "../CancelApptFormField/CancelApptFormFieldContainer";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import { CancelApptFormFieldStruct } from "../CancelApptFormField/CancelApptFormField.types";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { PATHS, ACTIONS } from "lib/routing";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { CancelApptFormProps } from "./CancelApptForm.types";
import { PersonalParticularsFormStruct } from "../../Form/Form.types";
import { validatePhoneNumber } from "lib/util/ValidatorUtil/phoneNumberValidator/phoneNumberValidator";
import { validateEmail } from "lib/util/ValidatorUtil/emailValidator/emailValidator";
import { useSelector } from "react-redux";
import { RootState } from "lib/redux/root/redux.types";
import SimpleCheckbox from "lib/components/formInputs/Checkbox/SimpleCheckbox";
import { sxStyles } from "./CancelApptForm.styles";
import { useAppSelector } from "lib/redux/hooks";
import { selectAppointments } from "ui/appointment/ducks/selectors";
import PersonalParticularsForm from "../../PersonalParticularsForm/PersonalParticularsForm";
import {
  formatDate,
  formatOptions,
} from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

// ==========================
// COMPONENT
// ==========================

export const CancelApptForm = ({
  personalParticularsFormField,
  handlePersonalParticularsFormFieldChange,
  submitIsLoading,
  openConfirmationModal,
  onSubmitAppointmentRequest,
}: CancelApptFormProps) => {
  const classes = sxStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    selectedDateTime: currentApptdateTime,
    selectedDepartmentName: departmentName,
  } = useAppSelector(selectAppointments).reschedule;
  const institutionName =
    useAppSelector(selectAppointments).reschedule.selectedInstitution ?? "";

  const [cancelApptFormField, setCancelApptFormField] =
    useState<CancelApptFormFieldStruct>({
      reasonForCancel: "",
      remarks: "",
    });
  const [errorDOB, setErrorDOB] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const isSubmitDisabled = useMemo(
    () =>
      disableSubmit(
        submitIsLoading,
        cancelApptFormField,
        personalParticularsFormField,
        errorDOB,
        isChecked,
      ),
    [
      cancelApptFormField,
      errorDOB,
      isChecked,
      personalParticularsFormField,
      submitIsLoading,
    ],
  );

  // Event fired whenever a field on this reschedule form has been updated
  // name: name attribute of input component
  const handleChange = (event: { target: { name: string; value: string } }) => {
    const { name, value } = event.target;
    const updatedCancelApptFormField = {
      ...cancelApptFormField,
      [name]: value,
    };
    setCancelApptFormField(updatedCancelApptFormField);
  };

  const handleSubmit = () => {
    if (currentApptdateTime && cancelApptFormField.reasonForCancel) {
      onSubmitAppointmentRequest({
        IsMakeApptAction: false,
        IsRescheduleApptAction: false,
        IsCancelApptAction: true,
        CancelAppointmentOriginalDate: currentApptdateTime,
        CancelAppointmentReason: cancelApptFormField.reasonForCancel,
        CancelAppointmentRemarks: cancelApptFormField.remarks,
        CancelAppointmentInstitution: institutionName,
        DepartmentName: departmentName,
      });
    }
  };

  const formValues = useSelector(
    (state: RootState) =>
      state.appointments.systemSettings.values["NUPApptOfflineFormText"]?.Value,
  );

  // !exp Remap object to keep consistent with camelcase convention
  const {
    Alert: alert,
    MainInfo: mainInfo,
    Notes: notes,
  } = formValues
    ? JSON.parse(formValues)
    : {
        Alert: "",
        MainInfo: "",
        Notes: "",
      };

  return (
    <Box sx={{ p: 2 }}>
      {submitIsLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <AppointmentFormHeader alert={alert} mainInfo={mainInfo} />
          <CancelApptFormFieldContainer
            name={institutionName}
            currentApptDateTime={
              currentApptdateTime
                ? formatDate(
                    formatDateToISO(parseISO(currentApptdateTime)),
                    formatOptions.dayMonthYearTime,
                  )
                : ""
            }
            cancelApptFormField={cancelApptFormField}
            handleChange={handleChange}
          />
          <PersonalParticularsForm
            personalParticularsFormField={personalParticularsFormField}
            handlePersonalParticularsFormFieldChange={(event: {
              target: { name: string; value: string };
            }) => {
              handlePersonalParticularsFormFieldChange(event);
              setErrorDOB(false);
            }}
            showRequiredForEmail={true}
            onInputError={() => setErrorDOB(true)}
          />
          <Box sx={classes.checkboxContainer}>
            <SimpleCheckbox
              checked={isChecked}
              label={
                <Typography component={"span"} sx={classes.checkBoxLabel}>
                  {notes}
                </Typography>
              }
              value="agreementCheck"
              handleChange={() => setIsChecked(!isChecked)}
              required={true}
            />
          </Box>
          <ButtonsFooter
            isDisabled={isSubmitDisabled}
            nextButtonText="Send request"
            cancelButtonText="Cancel"
            onClickNext={() => handleSubmit()}
            onClickCancel={() => {
              if (
                location.pathname === PATHS.APPOINTMENT_CANCEL_FORM_MOBILE.path
              ) {
                mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH);
              } else {
                navigate(-1);
              }
            }}
          />
          <ConfirmationModal
            title="Request Submitted"
            body="We will get back to you with a confirmation for this request shortly."
            hideNextButton={true}
            hideCancelButton={false}
            cancelButtonText="Go to Appointment"
            onClickCancel={() => {
              navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
            }}
            open={openConfirmationModal}
            onClose={() => {}}
          />
        </>
      )}
    </Box>
  );
};

// ==========================
// HELPER FUNCTIONS
// ==========================
/**
 * Disable submit button depending on whether required fields are present and of the required format.
 *
 * @param submitIsLoading: whether the submit API call is still loading
 * @param cancelApptFormField: input fields on the form, only cancel reason is mandatory
 * @param personalParticularsFormField : mandatory input fields on the form
 * @param errorDOB: boolean when DOB has error
 * @param isChecked: boolean for agreement checkbox
 * @returns False if all mandatory input fields have been filled in, true otherwise.
 */
const disableSubmit = (
  submitIsLoading: boolean,
  cancelApptFormField: CancelApptFormFieldStruct,
  personalParticularsFormField: PersonalParticularsFormStruct,
  errorDOB: boolean,
  isChecked: boolean,
) => {
  if (
    submitIsLoading ||
    !cancelApptFormField.reasonForCancel ||
    cancelApptFormField.reasonForCancel.length === 0 ||
    !personalParticularsFormField.name ||
    !personalParticularsFormField.dateOfBirth ||
    !validatePhoneNumber(personalParticularsFormField.contact) ||
    !validateEmail(personalParticularsFormField.email) ||
    errorDOB ||
    !isChecked
  ) {
    return true;
  }
  return false;
};

export default CancelApptForm;
