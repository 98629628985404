import { RootState } from "lib/redux/root/redux.types";
import { connect } from "react-redux";
import { MAMMOGRAM_QUESTION_TITLE_TEXT_KEY } from "api/shared/GetDocumentByType/getDocumentByType.mapper";
import MammogramRequestFormQuestionnairePage from "./MammogramRequestFormQuestionnairePage";

const mapStateToProps = (state: RootState) => {
  return {
    titleText:
      state.appointments.documentByType.documentByTypeMap[
        MAMMOGRAM_QUESTION_TITLE_TEXT_KEY
      ],
  };
};

export default connect(mapStateToProps)(MammogramRequestFormQuestionnairePage);
