import { connect } from "react-redux";
import { RootState } from "lib/redux/root/redux.types";
import VcpCard from "./VcpCard";

const mapStateToProps = (state: RootState) => {
  return {
    disclaimerList: state.appointments.systemSettings.values,
  };
};

export default connect(mapStateToProps)(VcpCard);
