import { PastReportDetailPageProps } from "./PastReportDetailPage.types";
import { Typography, Box } from "@mui/material";
import Page from "lib/components/layout/Page/Page";
import { sxStyles } from "./PastReportDetailPage.styles";
import IMAGES from "lib/assets/images";
import { ViewReportHistory } from "api/mrrp/GetReportHistory/getReportHistory.fromApi.types";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { ACTIONS } from "lib/routing";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import IconTextButton from "lib/components/buttons/IconTextButton/IconTextButton";
import { useAppDispatch } from "lib/redux/hooks";
import { useEffect } from "react";
import { setMRRPAppRating } from "ui/mrrp/ducks/mrrpSlice";

const PastReportDetailPage = ({
  pastReport,
  memberIdentifier,
}: PastReportDetailPageProps) => {
  const classes = sxStyles();
  const dispatch = useAppDispatch();

  const enableDownload = (pastReport: ViewReportHistory | null) => {
    if (pastReport?.Download === "1") {
      return true;
    } else {
      return false;
    }
  };

  const downloadReport = async () => {
    if (pastReport) {
      logEventToGoogleAnalytics(EVENTS.SELECT_MEDICAL_REPORT_DOWNLOAD_REPORT);
      mobileNavigate(
        `${ACTIONS.DOWNLOAD_MEDICAL_REPORT_ACTION}&MemberIdentifier=${memberIdentifier}&HospitalCode=${pastReport.HospitalCode}&DownloadId=${pastReport.DownloadId}`,
      );
    }
  };

  useEffect(() => {
    if (pastReport?.Status === "COMPLETED") {
      dispatch(setMRRPAppRating(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <>
        <Box sx={classes.headerRegion}>
          <Typography sx={classes.reportName}>
            {pastReport?.ReportName}
          </Typography>

          {enableDownload(pastReport) && (
            <Box sx={classes.downloadImage}>
              <IconTextButton
                icon={IMAGES.general.DownloadIcon}
                label={["Download", "report"]}
                onClick={async () => downloadReport()}
                ariaLabel={"Download report"}
              />
            </Box>
          )}
        </Box>
        <Box sx={classes.reportRegion}>
          <Typography sx={classes.detailLabel}>Report No</Typography>
          <Typography sx={classes.detailValue}>
            {pastReport?.ReportNo}
          </Typography>

          <Typography sx={classes.detailLabel}>Status</Typography>
          <Typography sx={classes.detailValue}>{pastReport?.Status}</Typography>

          <Typography sx={classes.detailLabel}>eNETS Ref No:</Typography>
          <Typography sx={classes.detailLabel}>
            {pastReport?.ReferenceNo || "-"}
          </Typography>
        </Box>
        <Box sx={classes.reportRegion}>
          <Typography sx={classes.detailLabel}>Hospital</Typography>
          <Typography sx={classes.detailValue}>
            {pastReport?.Hospital}
          </Typography>

          <Typography sx={classes.detailLabel}>Clinical Department</Typography>
          <Typography sx={classes.detailValue}>
            {pastReport?.ClinicalDepartment}
          </Typography>

          <Typography sx={classes.detailLabel}>Requested by</Typography>
          <Typography sx={classes.detailValue}>
            {pastReport?.RequestedBy}
          </Typography>
        </Box>
      </>
    </Page>
  );
};

export default PastReportDetailPage;
