import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests/RequestOptions";
import { formatGetHealthPlanCompletionDateErrorMessage } from "../../resources/messages/ErrorMessage";
import { GET_HEALTH_PLAN_COMPLETION_DATE_URL } from "../../resources/configs/URL";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";
import { GetHealthPlanCompletionDate } from "./getHealthPlanCompletionDate.fromApi.types";

const getHealthPlanCompletionDate = async (
  memberIdentifier: string | null,
  rescheduleToDays?: number | null,
  appointmentStartDateTime?: string | null,
): Promise<GetHealthPlanCompletionDate> => {
  try {
    const response = await axios.post<GetHealthPlanCompletionDate>(
      GET_HEALTH_PLAN_COMPLETION_DATE_URL(),
      createRequestPayload(
        memberIdentifier,
        rescheduleToDays,
        appointmentStartDateTime,
      ),
      getRequestOptions(),
    );
    return parseResponse(response, GET_HEALTH_PLAN_COMPLETION_DATE_URL());
  } catch (error) {
    const msg = formatGetHealthPlanCompletionDateErrorMessage(
      GET_HEALTH_PLAN_COMPLETION_DATE_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  memberIdentifier: string | null,
  rescheduleToDays: number | null = null,
  appointmentStartDateTime: string | null = "",
) => {
  return {
    MemberIdentifier: memberIdentifier,
    RescheduleToDays: rescheduleToDays,
    AppointmentStartDateTime: appointmentStartDateTime,
  };
};

export { getHealthPlanCompletionDate };
