import { useState } from "react";
import IMAGES from "lib/assets/images";
import { PullToRefresh } from "react-js-pull-to-refresh";
import { sxStyles } from "./PullToRefreshWrapper.styles";
import { Box, Typography } from "@mui/material";
import PullToRefreshProps from "./PullToRefreshWrapper.types";

/**
 * This wrapper component will simulate native pull to refresh data for any child components in it
 * @param [function] refreshData will be called to refresh data when pulled to refresh is triggered
 * @returns jsx refreshed component
 */
const PullToRefreshComp = ({ children, refreshData }: PullToRefreshProps) => {
  const classes = sxStyles();

  const onRefresh = () => {
    return new Promise((resolve) => {
      refreshData();
      handleTick();
      setTimeout(resolve, 0);
    });
  };

  const [displayTick, setDisplayTick] = useState(false);

  const handleTick = () => {
    setDisplayTick(true);
    setTimeout(function () {
      setDisplayTick(false);
    }, 3000); // wait 3 seconds, then reset to false
  };

  return (
    <>
      <Box sx={classes.page} data-testid="pull-to-refresh">
        <Box sx={classes.pullToRefresh}>
          <Box sx={classes.line}></Box>
          <Box></Box>
          <Box sx={classes.line}></Box>
        </Box>
        <PullToRefresh
          data-testid="PullToRefreshFn"
          pullDownContent={
            !displayTick ? (
              <Box sx={classes.initialText}>Pull to refresh</Box>
            ) : (
              <Typography sx={classes.tickImage}>
                <Box
                  component="img"
                  src={IMAGES.general.TickAnimation}
                  alt="TickAnimation"
                  sx={classes.tickIcon}
                />
              </Typography>
            )
          }
          releaseContent={<Box sx={classes.releaseBox}>Release to refresh</Box>}
          refreshContent={
            <Typography sx={classes.tickImage}>
              <Box
                component="img"
                src={IMAGES.general.PullToRefreshBounching}
                alt="PullToRefreshBounching"
                sx={classes.tickIcon}
              />
            </Typography>
          }
          pullDownThreshold={0}
          onRefresh={onRefresh}
          triggerHeight={250}
        >
          <Box mt={5.5} />
          {children}
        </PullToRefresh>
      </Box>
    </>
  );
};

export default PullToRefreshComp;
