import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ViewOutstandingBills } from "api/payment/GetOutstandingBills/getOutstandingBills.fromApi.types";
import { ViewPaidBillsHistory } from "api/payment/GetPaidBillsHistory/getPaidBillsHistory.fromApi.types";
import { ViewPaymentHistory } from "api/payment/GetTransactionHistory/getTransactionHistory.fromApi.types";
import { ViewBillSummaryDetails } from "api/payment/GetBillSummaryDetails/getBillSummaryDetails.fromApi.types";
import { initialState } from "./initialState";
import {
  PaymentBillsToPayState,
  PaymentPersist,
  ApiEnum,
} from "./payment.types";
import {
  SubmitPaymentRequestResponsePayload,
  ViewSubmitPaymentRequest,
} from "api/payment/SubmitPaymentRequest/submitPaymentRequest.fromApi.types";
import {
  GetPaymentInfoResponsePayload,
  ViewPaymentInfo,
} from "api/payment/GetPaymentInfo/getPaymentInfo.fromApi.types";
import { ViewManualBill } from "api/payment/AddManualBill/addManualBill.fromApi.types";
import { ViewArInstitutions } from "api/payment/GetArInstitutions/getArInstitutions.fromApi.types";
import { DocumentByTypeMap } from "ui/appointment/ducks/appointments.types";
/**
 * The slice reducer for the part of state that represents the user's payment information.
 */
const paymentSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    clearState(state) {
      Object.assign(state, initialState);
    },

    setExpandedOutstandingBillsListFlag(state, action: PayloadAction<boolean>) {
      state.outstandingBills.expandedOutstandingBillsListFlag = action.payload;
    },
    // getReportHistory and select one report to view detail
    setShouldUpdate(state, action: PayloadAction<boolean>) {
      state.outstandingBills.shouldUpdate = action.payload;
    },
    setOutstandingBillsIsLoading(state, action: PayloadAction<boolean>) {
      state.outstandingBills.isLoading = action.payload;
    },
    setOutstandingBillsHasErrored(
      state,
      action: PayloadAction<boolean | null>,
    ) {
      state.outstandingBills.hasErrored = action.payload;
    },
    setOutstandingBillsErrorMessage(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.outstandingBills.errorMessage = action.payload;
    },
    setSelectedOutstandingBillsIndex(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.outstandingBills.selectedOutstandingBillsIndex = action.payload;
    },
    setOutstandingBills(state, action: PayloadAction<ViewOutstandingBills[]>) {
      state.outstandingBills.allOutstandingBills = action.payload;
    },

    setSelectedAddedBillsIndex(state, action: PayloadAction<string | null>) {
      state.addedBills.selectedAddedBillsIndex = action.payload;
    },
    addAddedBill(
      state,
      action: PayloadAction<{
        manualBill: ViewManualBill;
        institutionName: string | null;
        patientFullname: string | null;
        amountToPay: string | null;
      }>,
    ) {
      const amount = Math.abs(Number(action.payload.amountToPay)); // null will become 0
      const formattedAmount = (Math.round(amount * 100) / 100).toFixed(2);
      state.addedBills.allAddedBills.push({
        ...action.payload.manualBill,
        InstitutionName: action.payload.institutionName,
        PatientName: action.payload.manualBill.PatientName
          ? action.payload.manualBill.PatientName
          : action.payload.patientFullname,
        PaymentAmount: formattedAmount,
      });
    },
    deleteAddedBillByInvNo(state, action: PayloadAction<string | null>) {
      state.addedBills.allAddedBills = state.addedBills.allAddedBills.filter(
        (bill) => bill.InvoiceNumber !== action.payload,
      );
    },

    setExpandedPaidBillsListFlag(state, action: PayloadAction<boolean>) {
      state.paidBills.expandedPaidBillsListFlag = action.payload;
    },
    // getReportHistory and select one report to view detail
    setPaidBillsIsLoading(state, action: PayloadAction<boolean>) {
      state.paidBills.isLoading = action.payload;
    },
    setPaidBillsHasErrored(state, action: PayloadAction<boolean | null>) {
      state.paidBills.hasErrored = action.payload;
    },
    setPaidBillsErrorMessage(state, action: PayloadAction<string | null>) {
      state.paidBills.errorMessage = action.payload;
    },
    setSelectedPaidBillsIndex(state, action: PayloadAction<number | null>) {
      state.paidBills.selectedPaidBillsIndex = action.payload;
    },
    setPaidBills(state, action: PayloadAction<ViewPaidBillsHistory[]>) {
      state.paidBills.allPaidBills = action.payload;
    },

    setExpandedTransactionHistoryListFlag(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.transactionHistory.expandedTransactionHistoryListFlag =
        action.payload;
    },

    // getTransactionHistory and select one transaction to view detail
    setTransactionHistoryIsLoading(state, action: PayloadAction<boolean>) {
      state.transactionHistory.isLoading = action.payload;
    },
    setTransactionHistoryHasErrored(
      state,
      action: PayloadAction<boolean | null>,
    ) {
      state.transactionHistory.hasErrored = action.payload;
    },
    setTransactionHistoryErrorMessage(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.transactionHistory.errorMessage = action.payload;
    },
    setSelectedTransactionHistoryIndex(
      state,
      action: PayloadAction<number | null>,
    ) {
      state.transactionHistory.selectedTransactionHistoryIndex = action.payload;
    },
    setTransactionHistory(state, action: PayloadAction<ViewPaymentHistory[]>) {
      state.transactionHistory.allTransactionHistory = action.payload;
    },

    // getBillSummaryDetails
    setBillDetailsIsLoading(state, action: PayloadAction<boolean>) {
      state.billDetails.isLoading = action.payload;
    },
    setBillDetailsHasErrored(state, action: PayloadAction<boolean | null>) {
      state.billDetails.hasErrored = action.payload;
    },
    setBillDetailsErrorMessage(state, action: PayloadAction<string | null>) {
      state.billDetails.errorMessage = action.payload;
    },
    setBillDetails(
      state,
      action: PayloadAction<ViewBillSummaryDetails | null>,
    ) {
      state.billDetails.billDetails = action.payload;
    },
    setBillsToPay(state, action: PayloadAction<PaymentBillsToPayState[]>) {
      state.billsToPay = action.payload;
    },

    setIsLoadingByApi(
      state,
      action: PayloadAction<{ api: ApiEnum; isLoading: boolean }>,
    ) {
      const apiName = action.payload.api;
      state.apiStatus[apiName].isLoading = action.payload.isLoading;
    },
    setErrorStatusByApi(
      state,
      action: PayloadAction<{
        api: ApiEnum;
        hasErrored: boolean | null;
        errorMessage: string | null;
      }>,
    ) {
      const apiName = action.payload.api;
      state.apiStatus[apiName].hasErrored = action.payload.hasErrored;
      state.apiStatus[apiName].errorMessage = action.payload.errorMessage;
    },
    resetApiStatus(state) {
      state.apiStatus = initialState.apiStatus;
    },
    setPaymentPerist(state, action: PayloadAction<PaymentPersist>) {
      state.paymentPersist = action.payload;
    },
    setSubmitPaymentRequestResult(
      state,
      action: PayloadAction<SubmitPaymentRequestResponsePayload>,
    ) {
      // Dynamic assign will leave CommonResponseInterface props in payloadData,
      // but casting makes them not accessable from payloadData.Status etc
      const payloadData: ViewSubmitPaymentRequest = { ...action.payload };
      state.payment.submitPaymentRequest = payloadData;
    },
    setGetPaymentInfoResult(
      state,
      action: PayloadAction<GetPaymentInfoResponsePayload>,
    ) {
      // Dynamic assign will leave CommonResponseInterface props in payloadData,
      // but casting makes them not accessable from payloadData.Status etc
      const payloadData: ViewPaymentInfo = { ...action.payload };
      state.payment.paymentInfo = payloadData;
    },
    setReceiptPageLanding(state, action: PayloadAction<boolean>) {
      state.receiptPageLanding = action.payload;
    },

    // getArInstitutions
    setArInstitutionsIsLoading(state, action: PayloadAction<boolean>) {
      state.arInstitutions.isLoading = action.payload;
    },
    setArInstitutionsHasErrored(state, action: PayloadAction<boolean | null>) {
      state.arInstitutions.hasErrored = action.payload;
    },
    setArInstitutionsErrorMessage(state, action: PayloadAction<string | null>) {
      state.arInstitutions.errorMessage = action.payload;
    },
    setArInstitutionsList(state, action: PayloadAction<ViewArInstitutions[]>) {
      state.arInstitutions.arInstitutionsList = action.payload;
    },

    // GetAvailablePaymentModes
    setAvailablePaymentModesIsLoading(state, action: PayloadAction<boolean>) {
      state.availablePaymentModes.isLoading = action.payload;
    },
    setAvailablePaymentModesHasErrored(
      state,
      action: PayloadAction<boolean | null>,
    ) {
      state.availablePaymentModes.hasErrored = action.payload;
    },
    setAvailablePaymentModesErrorMessage(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.availablePaymentModes.errorMessage = action.payload;
    },
    setAvailablePaymentModes(
      state,
      action: PayloadAction<{
        IsCcAvailable: boolean;
        IsDdAvailable: boolean;
        IsAmexAvailable: boolean;
        CcDisclaimer: string | null;
        DdDisclaimer: string | null;
        AmexDisclaimer: string | null;
      }>,
    ) {
      state.availablePaymentModes.IsCcAvailable = action.payload.IsCcAvailable;
      state.availablePaymentModes.IsDdAvailable = action.payload.IsDdAvailable;
      state.availablePaymentModes.IsAmexAvailable =
        action.payload.IsAmexAvailable;
      state.availablePaymentModes.CcDisclaimer = action.payload.CcDisclaimer;
      state.availablePaymentModes.DdDisclaimer = action.payload.DdDisclaimer;
      state.availablePaymentModes.AmexDisclaimer =
        action.payload.AmexDisclaimer;
    },

    // For getDocumentByType API call
    setDocumentByTypeInitializeIsLoading(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.documentByType.isLoading = action.payload;
    },
    setDocumentByTypeInitializeHasErrored(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.documentByType.hasErrored = action.payload;
    },
    setDocumentByTypeInitializeErrorMessage(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.documentByType.errorMessage = action.payload;
    },
    setDocumentByTypeInitializeValue(
      state,
      action: PayloadAction<DocumentByTypeMap>,
    ) {
      state.documentByType.documentByTypeMap = action.payload;
    },
    clearDocumentByTypeState(state) {
      state.documentByType = initialState.documentByType;
    },
  },
});

export const {
  clearState,
  setExpandedOutstandingBillsListFlag,
  setSelectedOutstandingBillsIndex,

  setShouldUpdate,
  setOutstandingBills,
  setOutstandingBillsErrorMessage,
  setOutstandingBillsHasErrored,
  setOutstandingBillsIsLoading,

  setSelectedAddedBillsIndex,
  addAddedBill,
  deleteAddedBillByInvNo,

  setExpandedPaidBillsListFlag,
  setSelectedPaidBillsIndex,
  setPaidBills,
  setPaidBillsErrorMessage,
  setPaidBillsHasErrored,
  setPaidBillsIsLoading,

  setExpandedTransactionHistoryListFlag,
  setSelectedTransactionHistoryIndex,
  setTransactionHistory,
  setTransactionHistoryErrorMessage,
  setTransactionHistoryHasErrored,
  setTransactionHistoryIsLoading,

  setBillDetailsIsLoading,
  setBillDetailsHasErrored,
  setBillDetailsErrorMessage,
  setBillDetails,

  setBillsToPay,

  setIsLoadingByApi,
  setErrorStatusByApi,
  resetApiStatus,
  setPaymentPerist,
  setSubmitPaymentRequestResult,
  setGetPaymentInfoResult,
  setReceiptPageLanding,

  setArInstitutionsList,
  setArInstitutionsHasErrored,
  setArInstitutionsErrorMessage,
  setArInstitutionsIsLoading,

  setAvailablePaymentModes,
  setAvailablePaymentModesHasErrored,
  setAvailablePaymentModesErrorMessage,
  setAvailablePaymentModesIsLoading,
  setDocumentByTypeInitializeIsLoading,
  setDocumentByTypeInitializeHasErrored,
  setDocumentByTypeInitializeErrorMessage,
  setDocumentByTypeInitializeValue,
  clearDocumentByTypeState,
} = paymentSlice.actions;

export default paymentSlice.reducer;
