// Firebase App (the core Firebase SDK) is always required and must be listed first
import { format } from "date-fns";
import { getAnalytics, logEvent } from "firebase/analytics";
import { formatString } from "../StringUtil/formatString/formatString";
import { LoggingEvent } from "./logEvent.types";

// log event to google analytics
export const logEventToGoogleAnalytics = (
  event: LoggingEvent,
  eventNameArg?: string,
  eventTitleArg?: string,
) => {
  const analytics = getAnalytics();
  try {
    let eventName: string;
    let screenTitle: string | null;
    if (eventNameArg) {
      eventName = formatString(event.eventName, eventNameArg);
    } else {
      eventName = event.eventName;
    }
    if (eventTitleArg && event.params.screenTitle) {
      screenTitle = formatString(event.params.screenTitle, eventTitleArg);
    } else {
      screenTitle = event.params.screenTitle;
    }

    const eventParams = {
      ...event.params,
      screenTitle,
      eventTime: format(new Date(), "do MMMM yyyy, hh:mm:ss aaa"),
      platForm: "Web",
    };
    logEvent(analytics, eventName, eventParams);
  } catch (error) {
    console.error(
      `error occurred when logging event ${event} to google analytics:  ${error}`,
    );
  }
};
