import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { ACTUALISE_APPOINTMENT_URL } from "../../resources/configs/URL";
import { formatActualiseAppointmentErrorMessage } from "../../resources/messages/ErrorMessage";
import { ActualiseAppointmentPayload } from "./actualiseAppontment.fromApi.types";
import { KioskQuestionnaireResponse } from "./actualiseAppontment.fromUi.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";

/**
 * Actualises an appointment
 *
 * POST
 * Endpoint: /ActualiseAppointment
 *
 * @param {string | null} memberIdentifier  Member Identifier of the person we are making this request for
 * @param {string} appointmentId  ID of the appointment
 * @param {string | null} regInstitutionCode  Reg Institution code of this appointment (e.g. "NUP")
 *
 * @returns {ActualiseAppointmentPayload} Response payload from the remote API
 */
const actualiseAppointment = async (
  memberIdentifier: string | null,
  regAppointmentId: string,
  regInstitutionCode: string | null,
  institutionCode: string | null,
  clinicCode: string | null,
  kioskQuestionnaireResponse: KioskQuestionnaireResponse,
  vcSupportStatus: string | null,
): Promise<ActualiseAppointmentPayload> => {
  try {
    const response = await axios.post<ActualiseAppointmentPayload>(
      ACTUALISE_APPOINTMENT_URL(),
      createRequestPayload(
        memberIdentifier,
        regAppointmentId,
        regInstitutionCode,
        institutionCode,
        clinicCode,
        kioskQuestionnaireResponse,
        vcSupportStatus,
      ),
      getRequestOptions(),
    );
    return parseResponse(response, ACTUALISE_APPOINTMENT_URL());
  } catch (error) {
    const msg = formatActualiseAppointmentErrorMessage(
      ACTUALISE_APPOINTMENT_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  memberIdentifier: string | null,
  regAppointmentId: string,
  regInstitutionCode: string | null,
  institutionCode: string | null,
  clinicCode: string | null,
  kioskQuestionnaireResponse: KioskQuestionnaireResponse,
  vcSupportStatus: string | null,
) => {
  return {
    MemberIdentifier: memberIdentifier,
    RegAppointmentId: regAppointmentId,
    RegInstitutionCode: regInstitutionCode,
    InstitutionCode: institutionCode,
    ClinicCode: clinicCode,
    QuestionnaireResponse: kioskQuestionnaireResponse,
    VcSupportStatus: vcSupportStatus,
  };
};

export { actualiseAppointment };
