import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";
import { RootState } from "lib/redux/root/redux.types";
import { updateQuantity } from "ui/medrefill/ducks/medrefillSlice";
import {
  MedRefillQuantityDetail,
  MedRefillSystemSetting,
  MedRefillQuantityDetailOption3Temp,
} from "ui/medrefill/ducks/medrefill.types";
import { IterableElement } from "lib/data/data.types";
import { setCustomisedBack } from "lib/redux/navigation";
import Quantity from "./Quantity";

const mapper = (
  settings: MedRefillSystemSetting[] | undefined,
  sort: boolean,
): IterableElement[] => {
  if (settings === undefined) {
    return [];
  } else {
    let mapped = settings.map(
      (setting): IterableElement => ({
        id: setting.value || 0,
        text: setting.displayValue || "",
      }),
    );
    if (sort) {
      mapped = mapped.sort((x, y) => (x.text < y.text ? -1 : 1));
    }
    return mapped;
  }
};

const mapStateToProps = (state: RootState) => {
  return {
    typeId: state.medRefill.quantity.typeId,
    details: state.medRefill.quantity.details,
    option3Temp: state.medRefill.quantity.temp,
    durationList: mapper(
      state.medRefill.systemSettings.lookup.get("DURATION"),
      false,
    ),
    uomList: mapper(state.medRefill.systemSettings.lookup.get("UOM"), true),
    medicationList: state.medRefill.medications.medicationList,

    preventDirectBackNavigationState: state.navigation.customisedBack,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onSelectQuantity: (
      typeId: number | null,
      details: MedRefillQuantityDetail[],
      temp: MedRefillQuantityDetailOption3Temp,
    ) => {
      dispatch(
        updateQuantity({
          typeId,
          details,
          temp,
        }),
      );
    },
    resetPreventDirectBackNavigationState: () => {
      dispatch(setCustomisedBack(false));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Quantity);
