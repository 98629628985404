import { SxObjType } from "lib/theme/types/SxObjType.type";
import { StyleProps } from "./Tags.types";
import theme from "lib/theme/theme";

const sxStyles = (props: StyleProps) => {
  return {
    container: {
      display: "inline-block",
      width: "fit-content",
      borderRadius: 9.5,
      height: props.labelSize
        ? Number(props.labelSize) + 3
        : theme.spacing(1.5),
      backgroundColor:
        props.colorChoice === "orange"
          ? theme.palette.warning.main
          : props.colorChoice === "lightOrange"
            ? theme.palette.warning.light
            : props.colorChoice === "green"
              ? theme.palette.success.main
              : props.colorChoice === "lightBlue"
                ? theme.palette.primary.main
                : props.colorChoice === "darkBlue"
                  ? theme.palette.text.primary
                  : props.colorChoice === "red"
                    ? theme.palette.error.main
                    : props.colorChoice === "grey"
                      ? theme.palette.grey[100]
                      : "transparent",
      padding: theme.spacing(0.1, 1),
    },
    label: {
      color:
        props.colorChoice === "grey"
          ? theme.palette.grey[600]
          : theme.palette.common.white,
      fontSize: props.labelSize ? props.labelSize : 9,
      textAlign: "center",
      textTransform: "uppercase",
      fontWeight: theme.typography.fontWeightBold,
    },
  } satisfies SxObjType;
};

export { sxStyles };
