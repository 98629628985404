import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    datepicker: {
      padding: theme.spacing(1, 2),
      "& .MuiInputBase-root": {
        borderRadius: theme.spacing(0.5),
        height: theme.spacing(6),
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.grey[100]}`,
        },
      },
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
      "& .MuiInputLabel-animated": {
        fontSize: size.FONTSIZE_16,
        color: theme.palette.primary.main,
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
