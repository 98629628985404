import { color } from "lib/theme/color";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    mainContainer: {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "0px 0px 4px " + color.RGBA_0,
      borderRadius: theme.spacing(0.5),
      position: "relative",
      margin: theme.spacing(1, 2),
      padding: 1,
      height: "200px",
    },
    card: {
      backgroundColor: theme.palette.secondary.main,
      borderBottom: "2px solid " + color.RGBA_204,
      margin: theme.spacing(0, 1),
    },
    headerContainer: {
      backgroundColor: theme.palette.secondary.main,
      borderBottom: "2px solid " + color.RGBA_204,
      margin: theme.spacing(0, 1),
    },
    bodyContainer: {
      backgroundColor: theme.palette.secondary.main,
      margin: theme.spacing(1, 1),
    },
  } satisfies SxObjType;
};

export { sxStyles };
