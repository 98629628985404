import { Dispatch, AnyAction } from "@reduxjs/toolkit";
import {
  setSystemSettingsIsLoading,
  setSystemSettingsValue,
  setSystemSettingsHasErrored,
  setSystemSettingsErrorMessage,
} from "./";
import { getSystemSettings } from "api/shared/GetSystemSettings/getSystemSettings";
import { SystemSettings } from "./mhm.types";
import {
  setCategoryListErrorMessage,
  setCategoryListHasErrored,
  setCategoryListIsLoading,
  setCategoryListValue,
} from "./mhmSlice";
import { AxiosError } from "axios";

/***
 * Fetches system settings from the remote API and initializes data
 * Loading / error states are also handled here.
 */
export const fetchSystemSettings =
  () => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setSystemSettingsIsLoading(true));
      const response = await getSystemSettings({
        category: "CAREPLAN",
        subcategory: "MHM_STATICPGCT",
        codeName: null,
        institutionCode: null,
      });

      let map: SystemSettings = {};
      //store all system settings as key and value pair to make the lookup easier, and avoid multiples call of API
      for (var setting of response.Settings) {
        map[setting.CodeName] = {
          DisplayValue: setting.DisplayValue,
          Value: setting.Value,
        };
      }
      dispatch(setSystemSettingsValue(map));
      dispatch(setSystemSettingsHasErrored(false));
      dispatch(setSystemSettingsErrorMessage(null));
    } catch (error) {
      dispatch(setSystemSettingsValue({}));
      dispatch(setSystemSettingsHasErrored(true));

      if (error instanceof AxiosError) {
        dispatch(setSystemSettingsErrorMessage(error.response?.data.Message));
      }
    } finally {
      dispatch(setSystemSettingsIsLoading(false));
    }
  };

// Fetches MHM categories from systemSettings
export const fetchCategoryList =
  () => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setCategoryListIsLoading(true));
      const response = await getSystemSettings({
        category: "OneNUHS",
        subcategory: "MHM",
        codeName: "CategoryList",
        institutionCode: null,
      });

      const data = response.Settings[0].Value;
      const parsedData = JSON.parse(data ?? "");

      dispatch(setCategoryListValue(parsedData));

      dispatch(setCategoryListHasErrored(false));
      dispatch(setCategoryListErrorMessage(null));
    } catch (error) {
      dispatch(setCategoryListHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setCategoryListErrorMessage(error.response?.data.Message));
      }
    } finally {
      dispatch(setCategoryListIsLoading(false));
    }
  };
