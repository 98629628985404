import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";
import { RootState } from "lib/redux/root/redux.types";
import {
  MedRefillRequestType,
  MedRefillPrescriptionState,
} from "ui/medrefill/ducks/medrefill.types";
import { CollectionState, PrescriptionType } from "./Collection.types";
import {
  updateCollection,
  updatePrescription,
} from "ui/medrefill/ducks/medrefillSlice";
import Collection from "./Collection";
import { setCustomisedBack } from "lib/redux/navigation";

// =======================
// MAP STATE TO PROPS
// =======================

const mapStateToProps = (state: RootState) => {
  return {
    requestType: state.medRefill.requestType,
    facilityId: state.medRefill.institution.facilityId,
    prescriptionType: mapRequestType(state.medRefill.requestType),
    collectionChoices:
      state.medRefill.collectionAndPayments.collectionOptionList,
    collectionState: state.medRefill.collection,
    userState: state.user,
    prescribedMedicationConsentFlag: state.user.consent.consentFlag,

    // REFILL PRESCRIPTION STATES
    prescriptionState: state.medRefill.prescription,
    institutionState: state.medRefill.institution,
    defaultMedicationCardDataList: state.medRefill.prescription.files.map(
      (file) => {
        return {
          frontFileName: file.front.fileName,
          frontFileData: file.front.fileData,
          backFileName: file.back.fileName,
          backFileData: file.back.fileData,
          medicationName: file.topUp.medicationName,
          quantity: file.topUp.quantity,
          UOMId: file.topUp.UOMId,
          UOM: file.topUp.UOM,
        };
      },
    ),

    documentList: state.medRefill.documentByCluster.documentByClusterList.map(
      (documentObject) => {
        return {
          type: documentObject.Type,
          content: documentObject.Contents,
        };
      },
    ),

    preventDirectBackNavigationState: state.navigation.customisedBack,
  };
};

/**
 * Maps the type of medrefill request to the collection prescription type.
 * This is used in the Collection component for API calls & serves as metadata.
 *
 * @param {MedRefillRequestType} reduxRequestType  Type of MedRefill request
 * @returns {PrescriptionType}  Collection prescription type
 */
const mapRequestType = (
  reduxRequestType: MedRefillRequestType,
): PrescriptionType => {
  if (reduxRequestType === "REFILL") {
    return "refill";
  } else if (reduxRequestType === "TOPUP") {
    return "topup";
  } else return "virtualconsult";
};

/**
 * Maps the relevant parts of the Redux state to the collection component
 *
 * @param {MedRefillCollectionState} collectionState  Redux state of medrefill collection
 *
 * @returns {CollectionState}  Initial state of the collection component
 */
// const mapCollectionReduxStateToPropsState = (
//   collectionState: MedRefillCollectionState
// ): CollectionState => {
//   return {
//     typeId: collectionState.typeId,
//     type: collectionState.type,
//     label: collectionState.label,
//     locationId: collectionState.locationId,
//     locationName: collectionState.locationName,
//     timeslot: collectionState.timeslot,
//     date: collectionState.date,
//     specialRequest: collectionState.specialRequest,
//     postalCode: collectionState.postalCode,
//     blockNo: collectionState.blockNo,
//     unitNo: collectionState.unitNo,
//     address: collectionState.address,
//     errorPostalCode: collectionState.errorPostalCode,
//   };
// };

// =======================
// MAP DISPATCH TO PROPS
// =======================

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onSelectCollection: (collectionState: CollectionState) => {
      dispatch(
        updateCollection({
          typeId: collectionState.typeId,
          type: collectionState.type,
          label: collectionState.label,
          locationId: collectionState.locationId,
          locationName: collectionState.locationName,
          timeslot: collectionState.timeslot,
          date: collectionState.date,
          postalCode: collectionState.postalCode,
          blockNo: collectionState.blockNo,
          unitNo: collectionState.unitNo,
          address: collectionState.address,
          specialRequest: collectionState.specialRequest,
          errorPostalCode: collectionState.errorPostalCode,
        }),
      );
    },

    onSelectPrescription: (
      prescriptionState: MedRefillPrescriptionState & {
        clinicId: number;
      },
    ) => {
      dispatch(updatePrescription(prescriptionState));
    },
    resetPreventDirectBackNavigationState: () => {
      dispatch(setCustomisedBack(false));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
