import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import { Theme } from "@mui/material";

const sxStyles = ({
  theme,
  errorText,
  isDisabled,
  isNoActions,
}: {
  theme: Theme;
  errorText: string;
  isNoActions: boolean;
  isDisabled: boolean;
}) => {
  return {
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    upperLabel: {
      fontSize: size.FONTSIZE_14,
    },
    root: {
      width: "100%",
      padding: 0,
      "&.MuiTextField-root .MuiFormLabel-root": {
        color: !errorText
          ? isDisabled
            ? theme.palette.text.secondary
            : theme.palette.primary.main
          : theme.palette.error.main,
      },
    },
    icon: {
      paddingRight: theme.spacing(1.5),
    },
    textFieldLabel: {
      color: !errorText ? theme.palette.primary.main : theme.palette.error.main,
      fontSize: size.FONTSIZE_16,
      paddingRight: "0px",
    },
    errText: {
      color: theme.palette.error.main,
      typography: theme.typography.caption1_regular,
      padding: theme.spacing(0.5, 1.5),
    },
    helperText: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
      padding: theme.spacing(0.5, 1.5),
    },

    datepickerContainer: {
      display: "flex",
    },
    layout: {
      "& .MuiList-root": {
        gridColumn: 2,
        gridRow: 3,
      },
      "& .MuiPickersLayout-toolbar .MuiPickersToolbar-content .MuiTypography-root":
        {
          typography: theme.typography.header1_semibold,
          color: theme.palette.text.primary,
        },
      "& .MuiPickersLayout-toolbar .MuiTypography-overline": {
        typography: theme.typography.caption1_regular,
        color: theme.palette.grey[400],
        textTransform: "capitalize",
      },

      "& .MuiPickersLayout-contentWrapper .MuiDateCalendar-root": {
        ...(!isNoActions && {
          borderBottom: `1px solid ${theme.palette.secondary.light}`,
        }),
      },
      "& .MuiDateCalendar-viewTransitionContainer .MuiDayCalendar-weekDayLabel":
        {
          typography: theme.typography.caption1_bold,
          color: theme.palette.grey[400],
        },
      "& .MuiPickersSlideTransition-root": {
        minHeight: "210px",
      },
    },
    calendarHeader: {
      "& .MuiPickersCalendarHeader-label": {
        typography: theme.typography.body1_semibold,
        color: theme.palette.text.primary,
      },
    },
    day: {
      "&.MuiPickersDay-root": {
        typography: theme.typography.body1_regular,
        color: theme.palette.primary.main,
        "&.Mui-selected": {
          color: theme.palette.common.white,
          typography: theme.typography.body1_semibold,
          backgroundColor: theme.palette.primary.main,

          "&:hover": {
            backgroundColor: theme.palette.primary.main,
          },
        },
        "&.MuiPickersDay-today": {
          border: `1px solid ${theme.palette.grey[100]}`,
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
    mobilePaper: { borderRadius: theme.spacing(1) },
  } satisfies SxObjType;
};
export { sxStyles };
