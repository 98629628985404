import axios from "axios";
import { GET_PAYMENT_STATUS_INFO_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatGetPaymentStatusInfoErrorMessage } from "api/resources/messages/ErrorMessage";
import {
  GetPaymentStatusInfoRequestPayload,
  GetPaymentStatusInfoResponsePayload,
} from "./getPaymentStatusInfo.fromApi.types";

/**
 * Try adding a manual bill and get its information
 *
 * POST
 * Endpoint: secure/payment/GetPaymentInfo
 *
 * @param {GetPaymentStatusInfoRequestPayload} request
 */
const getPaymentStatusInfo = async (
  request: GetPaymentStatusInfoRequestPayload,
): Promise<GetPaymentStatusInfoResponsePayload> => {
  try {
    const response = await axios.post<GetPaymentStatusInfoResponsePayload>(
      GET_PAYMENT_STATUS_INFO_URL(),
      request,
      getRequestOptions(),
    );
    return parseResponse(response, GET_PAYMENT_STATUS_INFO_URL());
  } catch (error) {
    const msg = formatGetPaymentStatusInfoErrorMessage(
      GET_PAYMENT_STATUS_INFO_URL(),
    );
    console.error(msg);
    throw error;
  }
};

export { getPaymentStatusInfo };
