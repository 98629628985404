import { Box } from "@mui/material";
import { ConfirmationCardProps } from "./ConfirmationCard.types";
import CardWrapper from "../../../../common/cards/CardWrapper/CardWrapper";
import AppointmentCardHeader from "ui/appointment/components/common/cards/AppointmentCardHeader/AppointmentCardHeader";
import AppointmentCardBody from "ui/appointment/components/common/cards/AppointmentCardBody/AppointmentCardBody";

const ConfirmationCard = ({ slotDateTime }: ConfirmationCardProps) => {
  return (
    <CardWrapper>
      <Box sx={{ display: "flex" }}>
        <AppointmentCardHeader datetime={slotDateTime} />
        <AppointmentCardBody
          institution={null}
          datetime={slotDateTime}
          departmentName={null}
          clinician={null}
          location={null}
          zoneInfo={null}
          serviceDisplayName={null}
          visitType={null}
          isVcp={false}
        />
      </Box>
    </CardWrapper>
  );
};

export default ConfirmationCard;
