import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { CancelAppointmentConfirmedModalProps } from "./CancelAppointmentModal.types";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";

const title = "Appointment cancelled";
const cancelButtonText = "Go to Appointment";

const CancelAppointmentConfirmedModal = ({
  open,
  onCancel,
}: CancelAppointmentConfirmedModalProps) => {
  const handleCancel = () => {
    logEventToGoogleAnalytics(EVENTS.VIEW_CANCEL_CONFIRMATION);
    onCancel();
  };

  return (
    <ConfirmationModal
      open={open}
      hideNextButton={true}
      title={title}
      cancelButtonText={cancelButtonText}
      onClickCancel={handleCancel}
      onClose={handleCancel}
    />
  );
};

export default CancelAppointmentConfirmedModal;
