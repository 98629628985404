import { Location } from "api/medrefill/GetLocations/getLocations.types";
import { IterableElement } from "lib/data/data.types";

/**
 * This file contains methods for mapping data from the API or otherwise
 * to object schemas / interfaces supported by this component.
 */

/**
 * Maps a list of locations returned from the remote API to a list of
 * elements compatible with the Pharmacy selection dropdown.
 *
 * @param {Location[]} locations  List of locations from the API
 *
 * @returns {IterableElement[]}  List of elements in the dropdown selection.
 */
const mapLocationsListFromApiToOptions = (
  locations: Location[],
): IterableElement[] => {
  if (locations) {
    return (
      locations
        // don't render options with no name (as that data must be corrupted),
        // even though the ID of the location is defined. (user requests an
        // order to a place that they don't know where to pick up from!)
        .filter((location) => location.LocationName)
        .map((location) => {
          return {
            // used by /SubmitMedication to indicate ID of location
            id: location.LocationId,

            // used by dropdown to display name of location
            text: location.LocationName as string, // string will already be guaranteed by filter above
          };
        })
    );
  } else {
    return [];
  }
};

/**
 * Obtains the location tied to this ID.
 *
 * Some facilities (institutions) only have 1 location for pick up. So in these cases, we can simply
 * pick the 1st item of the list as the chosen option, since no other options can be selected by the user.
 *
 * @param {Location[]} locations  List of locations from the API
 * @param {number | null} locationIdToFind  ID of the location to find from the list
 *
 * @returns {Location}  Found location object
 */
const findLocationByLocationId = (
  locations: Location[],
  locationIdToFind: number | null,
): Location | null => {
  let defaultLocation = null;
  if (locations.length === 1) {
    defaultLocation = locations[0];
  }

  if (locationIdToFind) {
    // when location ID is already set or pre-defined, so we can immediately return the location object
    const foundLocation = locations.find(
      (location) => location.LocationId === locationIdToFind,
    );

    if (foundLocation) {
      return foundLocation;
    } else {
      return defaultLocation;
    }
  } else {
    return defaultLocation;
  }
};

export { mapLocationsListFromApiToOptions, findLocationByLocationId };
