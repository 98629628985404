/**
 *
 * validate if the input nric is a valid Singapore NRIC
 * return true if the input nric is valid, false otherwise
 *
 * @param {string} nric  input string to be validated
 */
export const validateNric = (
  nric: string | null,
  validateRX?: boolean,
  institutionCode?: string | null,
) => {
  try {
    if (nric) {
      if (nric.length !== 9) {
        return false;
      }
      nric = nric.toUpperCase();
      let icArray: number[] = [];

      for (let i = 1; i < 8; i++) {
        icArray[i] = Number(nric.charAt(i));
      }

      icArray[1] = parseInt(nric.charAt(1), 10) * 2;

      icArray[2] = parseInt(nric.charAt(2), 10) * 7;

      icArray[3] = parseInt(nric.charAt(3), 10) * 6;

      icArray[4] = parseInt(nric.charAt(4), 10) * 5;

      icArray[5] = parseInt(nric.charAt(5), 10) * 4;

      icArray[6] = parseInt(nric.charAt(6), 10) * 3;

      icArray[7] = parseInt(nric.charAt(7), 10) * 2;

      let weight = 0;

      for (let i = 1; i < 8; i++) {
        weight += icArray[i];
      }

      let offset =
        nric.charAt(0) === "T" || nric.charAt(0) === "G"
          ? 4
          : nric.charAt(0) === "M"
          ? 3
          : 0;

      let temp = (offset + weight) % 11;
      //S & T Series
      let st = ["J", "Z", "I", "H", "G", "F", "E", "D", "C", "B", "A"];
      //F & G Series
      let fg = ["X", "W", "U", "T", "R", "Q", "P", "N", "M", "L", "K"];
      //M Series
      let m = ["X", "W", "U", "T", "R", "Q", "P", "N", "J", "L", "K"];

      let theAlpha;

      if (validateRX) {
        if (nric.charAt(0) === "R" || nric.charAt(0) === "X") {
          theAlpha = st[temp];
        }
      }

      if (nric.charAt(0) === "S" || nric.charAt(0) === "T") {
        theAlpha = st[temp];
      } else if (nric.charAt(0) === "F" || nric.charAt(0) === "G") {
        theAlpha = fg[temp];
      } else if (nric.charAt(0) === "M") {
        theAlpha = m[temp];
      }

      return nric.charAt(8) === theAlpha;
    }

    return false;
  } catch (error) {
    return false;
  }
};
