import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    sameLine: {
      display: "flex",
    },

    numberBox: {
      margin: theme.spacing(1, 0.5, 1, 0),
      "& .MuiInputBase-root": {
        lineHeight: "initial",
        borderRadius: theme.spacing(0.5),
      },
      width: "49%",
    },
    durationBox: {
      margin: theme.spacing(1, 0, 1, 0.5),
      width: "49%",
      "& .MuiFormControl-root": {
        margin: theme.spacing(0, 1, 0, 0),
      },
      "& .MuiInputBase-root": {
        borderRadius: theme.spacing(0.5),
        lineHeight: "initial",
        height: "48px",
      },
      "& .MuiInputLabel-outlined": {
        color: theme.palette.primary.main,
        fontSize: size.FONTSIZE_16,
        marginTop: "-4px",
        whiteSpace: "nowrap",
        width: "70%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "calc(100% - 60px)",
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
