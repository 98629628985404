import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    footerContainer: {
      display: "flex",
      ml: 1,
      mr: 1,
      mb: 2,
      alignItems: "center",
    },
    cardButtons: {
      columnGap: "8px",
      justifyContent: "center",
      display: "flex",
      mx: 1,
      my: 2,
    },
    btnLink: {
      color: theme.palette.primary.main,
      fontSize: size.FONTSIZE_12,
    },
    button: {
      width: "100%",
    },
    text: {
      color: theme.palette.grey[400],
      margin: theme.spacing(0, 1),
      typography: theme.typography.caption1_regular,
    },
  } satisfies SxObjType;
};

export { sxStyles };
