import { useState, useEffect } from "react";
import { getBarCodeImage } from "api/appointment/GetBarCodeImage/getBarCodeImage";
import { AxiosError } from "axios";

/**
 * A custom hook that fetches bar code image from
 * the remote API and returns the appropriate data payload & loading
 * state of that operation.
 *
 * @param {string | null} memberIdentifier  Member Identifier of the person we are making this request for
 * @param {boolean} pageFlag helper flag to trigger the hook
 *
 * @returns {[string | null, boolean, boolean } null]}
 *   0 - Base 64 encoded bar code image
 *   1 - True if the data fetch is complete, false otherwise.
 *   2 - True if the data fetch completed with errors, false otherwise.
 */
const useGetBarCodeImage = (
  memberIdentifier: string | null,
  pageFlag: boolean,
): [string | null, boolean, boolean, string | null] => {
  const [data, setData] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const imageBarCodeResponseData =
          await getBarCodeImage(memberIdentifier);

        setData(imageBarCodeResponseData.ImageData);
        setHasErrored(false);
        setErrorMessage(null);
      } catch (error) {
        setData(null);
        setHasErrored(true);
        if (error instanceof AxiosError) {
          setErrorMessage(error.response?.data.Message);
        } else {
          setErrorMessage(null);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [memberIdentifier, pageFlag]);

  return [data, isLoading, hasErrored, errorMessage];
};

export { useGetBarCodeImage };
