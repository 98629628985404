import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import { Theme } from "@mui/material";

const iconSize = size.FONTSIZE_24;
const sxStyles = (theme: Theme) => {
  return {
    checkbox: { p: 1 },
    checkboxText: {
      alignItems: "flex-start",
      "& .MuiTypography-root": {
        mt: `calc((${iconSize}px + ${theme.spacing(2)} - (1.5 * 1em)) / 2)`, // this is to offset first line height when flex-start.
        color: theme.palette.grey[400],
        typography: theme.typography.body2_regular,
      },
    },
    checkIcon: {
      width: iconSize,
    },
    disclaimerText: {
      fontSize: size.FONTSIZE_12,
      color: theme.palette.grey[600],
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(-1),
    },
    requiredText: {
      fontSize: size.FONTSIZE_12,
      color: theme.palette.error.main,
      marginLeft: theme.spacing(5.5),
      marginTop: theme.spacing(0.5),
    },
  } satisfies SxObjType;
};
export { sxStyles };
