import { GetAvailableSlotsPayload } from "api/appointment/GetAvailableSlots/getAvailableSlots.fromApi.types";
import {
  AvailableBundledSlot,
  AvailableSlot,
} from "../getAvailableSlots.toUi.types";

/**
 * Maps fetched GetAvailableSlots API payload to props that the AvailableSlotList
 * React component can consume.
 *
 * @param {GetAvailableSlotsPayload | null} payload
 *   The payload which will be transformed to AvailableSlotListProps
 * @returns {AvailableSlot[]}
 *   List of appointment cards (with IDs) retrieved from the input payload
 */
const mapApiDataToProps = (
  payload: GetAvailableSlotsPayload | null,
): AvailableSlot[] => {
  if (!payload || !payload.Slots) {
    return [];
  } else {
    return payload.Slots.map((slot) => {
      return {
        id: slot.SlotId,
        date: slot.Start,
      };
    });
  }
};

const mapApiBundledLinkedAppointmentsDataToProps = (
  payload: GetAvailableSlotsPayload | null,
): AvailableBundledSlot[][] => {
  if (!payload || !payload.LinkedSlots) {
    return [];
  } else {
    return payload.LinkedSlots.map((slots) => {
      return slots.map((slot) => {
        return {
          id: slot.SlotId,
          date: slot.Start,
          institutionCode: slot.ClinicCode,
          resource: slot.ResourceId,
        };
      });
    });
  }
};

export { mapApiDataToProps, mapApiBundledLinkedAppointmentsDataToProps };
