import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    list: {
      paddingTop: 0,
    },
  } satisfies SxObjType;
};
export { sxStyles };
