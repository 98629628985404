import { Box, Skeleton } from "@mui/material";
import { sxStyles } from "./AnnouncementPanelSkeleton.styles";

const AnnouncementsPanelSkeleton = () => {
  const classes = sxStyles();
  return (
    <Box
      role="progressbar"
      m={1}
      p={1}
      sx={{
        borderRadius: 0.5,
        border: `1px solid lightgrey`,
        display: "flex",
        flexDirection: "column",
      }}
      data-testid="announcement-skeleton"
    >
      <Skeleton animation="wave" width="93%" sx={classes.skeletons} />
      <Skeleton animation="wave" width="90%" sx={classes.skeletons} />
      <Skeleton animation="wave" width="70%" sx={classes.skeletons} />
    </Box>
  );
};

export default AnnouncementsPanelSkeleton;
