import { connect } from "react-redux";
import { RootState } from "lib/redux/root/redux.types";
import StepPageLayout from "./StepPageLayout";

const mapStateToProps = (state: RootState) => {
  return {
    requestType: state.medRefill.requestType,
    reorderFlag: state.medRefill.reorder,
  };
};

export default connect(mapStateToProps)(StepPageLayout);
