import React from "react";
import { PATHS } from "lib/routing";
import CollectionContainer from "../../../common/collection/Collection/CollectionContainer";

const VirtualConsultCollectionPage = () => {
  return (
    <CollectionContainer
      pathToNextStep={PATHS.MED_VIRTUALCONSULT_ORDER_MEDICATION_PAYMENT.path}
    />
  );
};

export default VirtualConsultCollectionPage;
