import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";
import { RootState } from "lib/redux/root/redux.types";
import RefillPaymentPage from "./RefillPaymentPage";
import { updatePayment } from "ui/medrefill/ducks/medrefillSlice";
import { setCustomisedBack } from "lib/redux/navigation";
import { OwnProps } from "./RefillPaymentPage.types";

const mapStateToProps = (state: RootState, { isEditablePage }: OwnProps) => {
  return {
    isEditablePage,
    subsidy: state.medRefill.payment.subsidy,
    paymentList: state.medRefill.collectionAndPayments.paymentOptionList,
    paymentInfoText:
      state.medRefill.documentByCluster.documentByClusterList.find(
        (doc) => doc.Type === "PaymentDisclaimer",
      )?.Contents,
    preventDirectBackNavigationState: state.navigation.customisedBack,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onSelectSubsidy: (
      subsidy: string | null,
      subsidyDescription: string[] | null,
      paymentInstructions: string | null,
    ) => {
      dispatch(
        updatePayment({
          subsidy,
          subsidyDescription,
          paymentInstructions,
        }),
      );
    },
    resetPreventDirectBackNavigationState: () => {
      dispatch(setCustomisedBack(false));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RefillPaymentPage);
