import { connect } from "react-redux";
import { RootState } from "lib/redux/root/redux.types";
import { Dispatch, AnyAction } from "redux";
import { MrrpCollectionState } from "ui/mrrp/ducks/mrrp.types";
import { setCollection } from "ui/mrrp/ducks";
import { setCustomisedBack } from "lib/redux/navigation";
import Collection from "./Collection";

const mapStateToProps = (state: RootState) => {
  return {
    deliveryModes: state.mrrp.reportOptions.deliveryModes,
    profilePostalCode: state.user.postalCode,
    profileBlock: state.user.block,
    profileUnitNo: state.user.unitNo,
    profileStreetAddress: state.user.streetAddress,

    collection: state.mrrp.collection,

    preventDirectBackNavigationState: state.navigation.customisedBack,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onSelectCollection: (collectionState: MrrpCollectionState) => {
      dispatch(setCollection(collectionState));
    },
    resetPreventDirectBackNavigationState: () => {
      dispatch(setCustomisedBack(false));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
