import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { sxStyles } from "./TransactionHistory.styles";
import TransactionHistoryCard from "./TransactionHistoryCard/TransactionHistoryCard";
import { CircularProgress } from "@mui/material";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import FilterTransaction from "./FilterTransaction/FilterTransaction";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import TransactionHistoryProfileHeaderContainer from "../common/TransactionHistoryProfileHeader/TransactionHistoryProfileHeaderContainer";
import { Button } from "@mui/material";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import IMAGES from "lib/assets/images";

// Hooks
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import {
  fetchTransactionHistory,
  setTransactionHistory,
  setTransactionHistoryIsLoading,
  setTransactionHistoryHasErrored,
  setTransactionHistoryErrorMessage,
} from "ui/payment/ducks";
import { selectPaymentsTransactionHistory } from "ui/payment/ducks/selectors";
import React from "react";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

const LIMIT_PER_PAGE = 10;

const TransactionHistory = () => {
  const classes = sxStyles();
  const dispatch = useAppDispatch();
  const isLoadingTransactionHistory = useAppSelector(
    selectPaymentsTransactionHistory,
  ).isLoading;
  const hasErroredTransactionHistory = useAppSelector(
    selectPaymentsTransactionHistory,
  ).hasErrored;
  const errorMessageTransactionHistory = useAppSelector(
    selectPaymentsTransactionHistory,
  ).errorMessage;
  const allTransactionHistory = useAppSelector(
    selectPaymentsTransactionHistory,
  ).allTransactionHistory;

  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [selectedMonthOption, setSelectedMonthOption] = useState<number | null>(
    null,
  );
  const [showMore, setShowMore] = useState(true);

  const [triggerFlag, setTriggerFlag] = useState(false);

  // trigger whenever the page is loaded
  React.useEffect(() => {
    dispatch(setTransactionHistoryIsLoading(false));
    dispatch(setTransactionHistoryHasErrored(false));
    dispatch(setTransactionHistoryErrorMessage(null));
    dispatch(setTransactionHistory([]));
    // log page to GA on mount
    logEventToGoogleAnalytics(EVENTS.VIEW_PAYMENT_TRANSACTION_HISTORY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      fetchTransactionHistory(
        startDate,
        endDate,
        pageNumber.toString() || null,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, triggerFlag]);

  const [open, setOpen] = useState(false);
  const openFilterDialog = () => {
    logEventToGoogleAnalytics(EVENTS.SELECT_FILTER_TRANSACTION_HISTORY);
    setOpen(true);
  };
  const closeFilterDialog = () => {
    setOpen(false);
  };

  let startDateTimeObj: Date | null = null;
  startDateTimeObj = getDateFromISOString(startDate);
  let endDateTimeObj: Date | null = null;
  endDateTimeObj = getDateFromISOString(endDate);

  const [list, setList] = useState(allTransactionHistory);

  useEffect(() => {
    const newList = list.concat(allTransactionHistory);
    setList(newList);

    if (allTransactionHistory.length < LIMIT_PER_PAGE) {
      setShowMore(false);
    } else {
      setShowMore(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTransactionHistory]);

  const loadMore = () => {
    logEventToGoogleAnalytics(EVENTS.SELECT_LOAD_MORE_TRANSACTION_HISTORY);
    setClickedLoadMore(true);
    setPageNumber(pageNumber + 1);
  };

  const [clickedLoadMore, setClickedLoadMore] = useState(false);

  return (
    <>
      <TransactionHistoryProfileHeaderContainer
        title="'s Bills"
        showMFilterTransactionsButton={
          allTransactionHistory.length !== 0 ||
          allTransactionHistory.length !== null
        }
        openFilterDialog={openFilterDialog}
      />

      <Box sx={classes.card}>
        {isLoadingTransactionHistory && !clickedLoadMore ? (
          <Box
            display="flex"
            align-items="center"
            justifyContent="center"
            mt={4}
            p={5}
          >
            <CircularProgress />
          </Box>
        ) : hasErroredTransactionHistory ? (
          <ErrorDisplayContainer
            errorMessage={errorMessageTransactionHistory}
            onTryAgain={() => {
              setList([]);
              dispatch(setTransactionHistory([]));
              dispatch(
                fetchTransactionHistory(
                  startDate,
                  endDate,
                  pageNumber.toString(),
                ),
              );
            }}
          />
        ) : list.length === 0 ? (
          <Box align-items="center" justifyContent="center" mt={0}>
            <Box sx={classes.noRecordBox}>
              <Box sx={classes.noRecordImage}>
                <img
                  src={IMAGES.payment.NoTransactionHistory}
                  alt="No Paid Bills"
                />
              </Box>
              <Box sx={classes.noRecordTitle}>
                You have no transaction history.
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            {selectedMonthOption !== null ||
            startDate !== null ||
            endDate !== null ? (
              <Box sx={classes.cardDetails}>
                <Box sx={classes.cardLeft}>
                  {selectedMonthOption !== null ? (
                    <Typography sx={classes.cardLabel}>
                      Showing last {selectedMonthOption} months
                    </Typography>
                  ) : startDate !== null || endDate !== null ? (
                    <Typography sx={classes.cardLabel}>
                      <span>
                        {startDateTimeObj
                          ? formatDate(
                              formatDateToISO(startDateTimeObj),
                              "dd MMM yyyy",
                            )
                          : null}
                      </span>{" "}
                      to{" "}
                      <span>
                        {endDateTimeObj
                          ? formatDate(
                              formatDateToISO(endDateTimeObj),
                              "dd MMM yyyy",
                            )
                          : null}
                      </span>
                    </Typography>
                  ) : null}
                </Box>
                <Box
                  sx={classes.cardRight}
                  onClick={() => {
                    setList([]);
                    dispatch(setTransactionHistory([]));
                    setSelectedMonthOption(null);
                    setStartDate(null);
                    setEndDate(null);
                    setPageNumber(1);
                    setClickedLoadMore(false);
                    setTriggerFlag(!triggerFlag);
                  }}
                >
                  <Typography sx={classes.cardLink}>
                    Clear All Filters
                  </Typography>
                </Box>
              </Box>
            ) : null}
            {list.map((item, index) => {
              return (
                <Box>
                  <TransactionHistoryCard
                    paymentMethod={item.PaymentMethod}
                    paymentDetails={item.PaymentDetails}
                    merchantRefNum={item.MerchantRefNum}
                    totalAmountPaid={item.TotalAmountPaid}
                    paymentDate={item.PaymentDate}
                    paymentStatus={item.Status}
                  />
                </Box>
              );
            })}

            {!isLoadingTransactionHistory && showMore === true && (
              <Box display="flex" justifyContent="center" pt={2} pb={4}>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={classes.loadMoreButton}
                  onClick={loadMore}
                >
                  Load more
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>

      <Box>
        <FilterTransaction
          open={open}
          onClose={closeFilterDialog}
          onClickCancel={closeFilterDialog}
          onSelectApplyFilter={() => {
            logEventToGoogleAnalytics(
              EVENTS.FILTER_TRANSACTION_HISTORY_CUSTOM_RANGE,
            );
            setPageNumber(1);
            setList([]);
            dispatch(setTransactionHistory([]));
            setClickedLoadMore(false);
            setTriggerFlag(!triggerFlag);
            closeFilterDialog();
          }}
          startDate={startDate}
          endDate={endDate}
          onChangeStartDate={setStartDate}
          onChangeEndDate={setEndDate}
          selectedMonthOption={selectedMonthOption}
          onChangeMonthOption={setSelectedMonthOption}
        />
      </Box>
    </>
  );
};

export default TransactionHistory;
