import { Box, Typography } from "@mui/material";
import { SelectionListingHeaderTextProps } from "./SelectionListingHeaderText.types";
import { sxStyles } from "./SelectionListingHeaderText.styles";

const SelectionListingHeaderText = ({
  text,
  label,
  sameLine = true,
}: SelectionListingHeaderTextProps) => {
  const classes = sxStyles();
  return (
    <Box p={1} sx={{ ...classes.layout, ...(sameLine ? classes.flex : {}) }}>
      <Box sx={sameLine ? classes.label : {}}>
        <Typography sx={classes.labelText}>{label}:</Typography>
      </Box>
      <Box sx={classes.textArea}>
        <Typography sx={classes.text} variant="subtitle1">
          <b>{text}</b>
        </Typography>
      </Box>
    </Box>
  );
};

export default SelectionListingHeaderText;
