import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    noReportImage: {
      display: "block",
      margin: "auto",
      marginTop: theme.spacing(10),
    },
    loadMoreButton: {
      display: "inline-block",
    },
  } satisfies SxObjType;
};

export { sxStyles };
