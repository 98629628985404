import axios from "axios";
import { SUBMIT_PAYMENT_REQUEST_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatSubmitPaymentRequestErrorMessage } from "api/resources/messages/ErrorMessage";
import {
  SubmitPaymentRequestRequestPayload,
  SubmitPaymentRequestResponsePayload,
} from "./submitPaymentRequest.fromApi.types";

/**
 * Submit payment request for a new medical report request
 *
 * POST
 * Endpoint: secure/payment/SubmitPaymentRequest
 *
 * @param {SubmitPaymentRequestRequestPayload | null} request
 */
const submitPaymentRequest = async (
  request: SubmitPaymentRequestRequestPayload,
): Promise<SubmitPaymentRequestResponsePayload> => {
  try {
    const response = await axios.post<SubmitPaymentRequestResponsePayload>(
      SUBMIT_PAYMENT_REQUEST_URL(),
      request,
      getRequestOptions(),
    );
    return parseResponse(response, SUBMIT_PAYMENT_REQUEST_URL());
  } catch (error) {
    const msg = formatSubmitPaymentRequestErrorMessage(
      SUBMIT_PAYMENT_REQUEST_URL(),
    );
    console.error(msg);
    throw error;
  }
};

export { submitPaymentRequest };
