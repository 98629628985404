import { connect } from "react-redux";
import SelectRequest from "./SelectRequest";
import { RootState, RootDispatch } from "lib/redux/root/redux.types";
import { MedRefillState } from "ui/medrefill/ducks/medrefill.types";
import {
  fetchMedicationSummaryDetail,
  fetchMedicationImages,
  fetchSystemSettings,
  fetchMedications,
  fetchCollectionPaymentDetails,
  updatePastRequestRequestorId,
  updateState,
  updateReorderFlag,
  setViewPastDetail,
  setPastRequestMedicationImages,
  setSystemSettingsHasErrored,
  setMedicationsHasErrored,
  setCollectionPaymentDetailsHasErrored,
  setDocumentByClusterHasErrored,
  fetchDocumentByCluster,
  setClinicsHasErrored,
  fetchClinics,
  setViewPastDetailsHasErrored,
  setPastRequestMedicationImagesHasErrored,
} from "ui/medrefill/ducks";
import { mapPastRequestRefillDetailToMedrefillState } from "lib/util/MedrefillMappingUtil/mapPastRequestRefillDetailToMedrefillState";
import { mapPastRequestTopupDetailToMedrefillState } from "lib/util/MedrefillMappingUtil/mapPastRequestTopupDetailToMedrefillState";
import { initialState as medRefillInitialState } from "ui/medrefill/ducks";
import { fetchUserConsent } from "lib/redux/user/thunks";
import { PRESCRIBED_MEDICATION_CONSENT_CODE } from "api/shared/ValidateConsent/validateConsent.code";
import { fetchMyChartSystemSettings } from "ui/medrefill/ducks/thunks";
import { setFileUploadPrescriptionError } from "ui/medrefill/ducks/medrefillSlice";

const mapStateToProps = (state: RootState) => {
  const medrefillState =
    state.medRefill.viewPastRequest.detail?.PrescripionType === "REFILL"
      ? mapPastRequestRefillDetailToMedrefillState(
          state.medRefill.viewPastRequest.detail,
          state.medRefill.pastRequestMedicationImages.images,
        )
      : mapPastRequestTopupDetailToMedrefillState(
          state.medRefill.viewPastRequest.detail,
          state.medRefill.pastRequestMedicationImages.images,
        );

  return {
    username: state.user.patientName,
    isLoadingUsername: state.user.isLoading,
    memberIdentifier: state.user.memberIdentifier,
    relationType: state.user.relationType, //returns "F' for family or friends, "C" for child, or null if no relationship

    // Returned from fetchMedicationSummaryDetail thunk
    isLoadingDetail: state.medRefill.viewPastRequest.isLoading,
    hasErroredDetail: state.medRefill.viewPastRequest.hasErrored,
    errorMessageDetail: state.medRefill.viewPastRequest.errorMessage,

    // Returned from onFetchingLatestRequestImages thunk
    isLoadingImages: state.medRefill.pastRequestMedicationImages.isLoading,
    hasErroredImages: state.medRefill.pastRequestMedicationImages.hasErrored,
    errorMessageImages:
      state.medRefill.pastRequestMedicationImages.errorMessage,

    medrefillState: medrefillState,

    isLoadingFormData:
      state.medRefill.systemSettings.isLoading ||
      state.medRefill.medications.isLoading ||
      state.medRefill.collectionAndPayments.isLoading ||
      state.medRefill.clinics.isLoading,

    hasErroredSystemSettings: state.medRefill.systemSettings.hasErrored,
    errorMessageSystemSettings: state.medRefill.systemSettings.errorMessage,

    hasErroredMedications: state.medRefill.medications.hasErrored,
    errorMessageMedications: state.medRefill.medications.errorMessage,

    hasErroredCollectionPaymentDetails:
      state.medRefill.collectionAndPayments.hasErrored,
    errorMessageCollectionPaymentDetails:
      state.medRefill.collectionAndPayments.errorMessage,

    hasErroredDocByCluster: state.medRefill.documentByCluster.hasErrored,
    errorMessageDocByCluster: state.medRefill.documentByCluster.errorMessage,

    hasErroredClinics: state.medRefill.clinics.hasErrored,
    errorMessageClinics: state.medRefill.clinics.errorMessage,

    isLoadingPrescribedMedicationConsent: state.user.consent.isLoading,
    prescribedMedicationConsentFlag: state.user.consent.consentFlag,

    showMyChartMedication: state.medRefill.showMyChartMedication,
  };
};

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    clearPastData: () => {
      dispatch(setViewPastDetail(null));
      dispatch(setPastRequestMedicationImages(null));

      // Clear the thunk boolean errorState flags
      dispatch(setViewPastDetailsHasErrored(null));
      dispatch(setPastRequestMedicationImagesHasErrored(null));
      dispatch(setSystemSettingsHasErrored(null));
      dispatch(setMedicationsHasErrored(null));
      dispatch(setCollectionPaymentDetailsHasErrored(null));
      dispatch(setDocumentByClusterHasErrored(null));
      dispatch(setClinicsHasErrored(null));
      dispatch(setFileUploadPrescriptionError([[false, false]]));
    },
    onFetchingMyChartSystemSettings: () => {
      dispatch(fetchMyChartSystemSettings());
    },
    onFetchingLatestRequestDetail: (requesterId: number) => {
      dispatch(updatePastRequestRequestorId(requesterId));
      dispatch(fetchMedicationSummaryDetail());
    },
    onFetchingLatestRequestImages: (requesterId: number) => {
      dispatch(updatePastRequestRequestorId(requesterId));
      dispatch(fetchMedicationImages());
    },
    onFetchingPrescribedMedicationConsent: () => {
      dispatch(fetchUserConsent(PRESCRIBED_MEDICATION_CONSENT_CODE));
    },
    onReorder: (pastRequestState: MedRefillState) => {
      dispatch(
        updateState({
          ...pastRequestState,
          collection: {
            ...pastRequestState.collection,
            date: medRefillInitialState.collection.date,
            timeslot: medRefillInitialState.collection.timeslot,
          },
        }),
      );
      dispatch(updateReorderFlag(true));
      dispatch(fetchSystemSettings(pastRequestState.institution.cluster || ""));
      dispatch(fetchMedications(pastRequestState.institution.cluster || ""));
      dispatch(
        fetchCollectionPaymentDetails(
          pastRequestState.institution.cluster || "",
          pastRequestState.institution.facilityId || 0,
        ),
      );
      dispatch(
        fetchDocumentByCluster(pastRequestState.institution.cluster || ""),
      );
      dispatch(fetchClinics(pastRequestState.institution.facilityId || 0));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectRequest);
