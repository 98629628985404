import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.text.primary,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightBold,
    },
    subtitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.caption1_bold,
    },
    facilityTitle: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    facilityDetail: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
    },
    infoBox: {
      border: `1px solid ${theme.palette.primary.main}`,
      background: theme.palette.secondary.main,
      padding: theme.spacing(0.5),
      display: "flex",
      lineHeight: theme.typography.body1.lineHeight,
      borderRadius: theme.spacing(0.5),
      margin: theme.spacing(2, 0),
    },
  } satisfies SxObjType;
};

export { sxStyles };
