/* eslint-disable react/jsx-no-useless-fragment */
import { OidcClient, StringMap } from "@axa-fr/react-oidc";
import { PropsWithChildren, memo, useEffect } from "react";
import LoadingPage from "../LoadingPage/LoadingPage";

interface CustomOidcSecureProps {
  callbackPath?: string;
  extras?: StringMap;
  configurationName?: string;
  isDisabled?: boolean;
}

const CustomOidcSecure = ({
  callbackPath,
  children,
  configurationName,
  extras,
  isDisabled = false,
}: PropsWithChildren<CustomOidcSecureProps>) => {
  const oidc = OidcClient.get(configurationName);

  useEffect(() => {
    if (!oidc.tokens && !isDisabled) {
      oidc.loginAsync(callbackPath, extras);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationName, callbackPath, extras, oidc, isDisabled]);

  if (isDisabled) {
    return <>{children}</>;
  }

  if (!oidc.tokens) {
    return <LoadingPage />;
  }
  return <>{children}</>;
};

export default memo(CustomOidcSecure);
