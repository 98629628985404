import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { GET_ANNOUNCEMENTS_URL } from "./../../resources/configs/URL";
import { moduleType } from "./getAnnouncements.types";
import axios from "axios";
import { GetAnnouncementsResponsePayload } from "./getAnnouncements.types";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatGetAnnouncementsErrorMessage } from "api/resources/messages/ErrorMessage";

/**
 * Fetches announcements for specific Modules
 *
 * POST
 * Endpoint: data/GetAnnouncements
 * @param {string} module Module that we are getting announcements for
 * @returns {GetAnnouncementsResponsePayload} Response payload from remote API
 */
const getAnnouncements = async (
  module: moduleType,
): Promise<GetAnnouncementsResponsePayload> => {
  try {
    const response = await axios.post<GetAnnouncementsResponsePayload>(
      GET_ANNOUNCEMENTS_URL(),
      createRequestPayload(module),
      getRequestOptions(),
    );
    return parseResponse(response, GET_ANNOUNCEMENTS_URL());
  } catch (error) {
    const msg = formatGetAnnouncementsErrorMessage(GET_ANNOUNCEMENTS_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (module: moduleType) => {
  return {
    Module: module,
  };
};

export { getAnnouncements };
