import { color } from "lib/theme/color";
import packageJson from "../../../package.json";

const { version } = packageJson;
/**
 * The `consoleSplash` function logs a stylized ASCII art and a tip to the console.
 */
const consoleSplash = () => {
  // Using "o8" ascii
  console.log(
    `
%coooo   oooo ooooo  oooo ooooo ooooo  oooooooo8            %co                            
%c 8888o  88   888    88   888   888  888                  %c888    ooooooooo  ooooooooo   
%c 88 888o88   888    88   888ooo888   888oooooo          %c8  88    888    888 888    888 
%c 88   8888   888    88   888   888          888        %c8oooo88   888    888 888    888 
%co88o    88    888oo88   o888o o888o o88oooo888       %co88o  o888o 888ooo88   888ooo88   
                                                                %co888       o888
Version: ${version}
%cTip: type '%ctheme%c' to display theme object directly in console`,
    `font-family:monospace;color:${color.PRIMARY_BRAND}`,
    `font-family:monospace;color:${color.WARNING_MAIN}`,
    `font-family:monospace;color:${color.PRIMARY_BRAND}`,
    `font-family:monospace;color:${color.WARNING_MAIN}`,
    `font-family:monospace;color:${color.PRIMARY_BRAND}`,
    `font-family:monospace;color:${color.WARNING_MAIN}`,
    `font-family:monospace;color:${color.PRIMARY_BRAND}`,
    `font-family:monospace;color:${color.WARNING_MAIN}`,
    `font-family:monospace;color:${color.PRIMARY_BRAND}`,
    `font-family:monospace;color:${color.WARNING_MAIN}`,
    `font-family:monospace;color:${color.WARNING_MAIN}`,
    `font-family:monospace;color: unset`,
    `font-family:monospace;color:${color.SUCCESS_MAIN}; font-style: italic;`,
    `font-family:monospace;color: unset`,
  );
};

export default consoleSplash;
