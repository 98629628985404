import {
  FormControl,
  Box,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import { validateNric } from "lib/util/ValidatorUtil/nricValidator/nricValidator";
import { validateName } from "lib/util/ValidatorUtil/nameValidator/nameValidator";
import { sxStyles } from "./ParticularsBody.styles";
import { ParticularsBodyProps } from "./ParticularsBody.types";
import { sameStringTest } from "lib/util/StringUtil/sameStringTest/sameStringTest";
import { maskNric } from "ui/appointment/components/common/user/UserProfile/maskNric";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";

const ParticularsBody = ({
  disableToggleAndPatientFields,
  patientName,
  patientNric,
  requesterName,
  requesterNric,
  contactNumber,
  email,
  userIsPatient,
  isPatient,
  validation,
  onChangePatientName,
  onChangePatientNric,
  onChangeContactNumber,
  onChangeEmail,
  onChangeIsPatient,
}: ParticularsBodyProps) => {
  const classes = sxStyles();

  const isNricSame = () => {
    return (
      patientNric &&
      requesterNric &&
      sameStringTest(maskNric(patientNric), maskNric(requesterNric))
    );
  };

  return (
    <Box m={0}>
      <FormControl component="fieldset" fullWidth>
        {/* Renders Toggle switch only when user is signed in with NRIC, where mobile init isPatient === true */}
        {userIsPatient && (
          <Box pl={2} pr={2} sx={classes.formToggle}>
            <Typography sx={classes.formToggleTitle}>
              I am the patient
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={isPatient}
                  onChange={onChangeIsPatient}
                  disabled={disableToggleAndPatientFields}
                />
              }
              label=""
            />
          </Box>
        )}

        <Box mt={3} ml={2} mr={2}>
          <Box>
            <Typography sx={classes.formTitle}>
              Patient’s Particulars
            </Typography>
            {isPatient ? (
              <>
                <Box sx={classes.textBox}>
                  <SingleLineTextField
                    type="text"
                    value={requesterName}
                    placeholder="Name"
                    disabled={true}
                    required={false}
                  />
                </Box>
                <Box sx={classes.textBox}>
                  <SingleLineTextField
                    type="text"
                    value={requesterNric}
                    placeholder="NRIC / Birth Certificate / FIN"
                    disabled={true}
                    required={false}
                  />
                </Box>
              </>
            ) : (
              <>
                <Box sx={classes.textBox}>
                  <SingleLineTextField
                    type="text"
                    value={patientName}
                    error={!validateName(patientName) && patientName !== ""}
                    placeholder="Name"
                    errorText="Enter a valid name"
                    disabled={disableToggleAndPatientFields}
                    handleChange={(event) =>
                      onChangePatientName(event.target.value)
                    }
                  />
                </Box>
                <Box sx={classes.textBox}>
                  <SingleLineTextField
                    type="text"
                    value={patientNric}
                    error={
                      disableToggleAndPatientFields
                        ? false
                        : isNricSame()
                          ? true
                          : !validateNric(patientNric) && patientNric !== ""
                    }
                    placeholder="NRIC / Birth Certificate / FIN"
                    errorText={
                      isNricSame()
                        ? "Please ensure you have entered the correct patient’s NRIC"
                        : "Enter a valid NRIC / Birth Certificate / FIN"
                    }
                    disabled={disableToggleAndPatientFields}
                    handleChange={(event) =>
                      onChangePatientNric(event.target.value)
                    }
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>

        <Box mt={2} ml={2} mr={2}>
          <Box>
            <Typography sx={classes.formTitle}>Contact Details</Typography>
            <Box sx={{ my: 2 }}>
              <SharpNoticePanel bgColor="warn" showIcon>
                We may contact you to verify your request. Please ensure the
                number provided is correct.
              </SharpNoticePanel>
            </Box>
            <Box sx={classes.textBox}>
              <SingleLineTextField
                type="number"
                maxCharLength={8}
                value={contactNumber}
                error={!validation.contact && contactNumber !== ""}
                placeholder="Contact Number"
                errorText="Enter a valid phone number"
                handleChange={(event) =>
                  onChangeContactNumber(event.target.value)
                }
              />
            </Box>
            <Box sx={classes.textBox}>
              <SingleLineTextField
                type="text"
                value={email}
                error={!validation.email && email !== ""}
                placeholder="Email"
                errorText="Enter a valid email"
                handleChange={(event) => onChangeEmail(event.target.value)}
              />
            </Box>
          </Box>
        </Box>
      </FormControl>
    </Box>
  );
};

export default ParticularsBody;
