import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import React, { useState } from "react";
import {
  setFileUploadDetails,
  setFileUploadPrescriptionError,
} from "ui/medrefill/ducks/medrefillSlice";
import { RefillPrescriptionProps } from "./RefillPrescription.types";
import RefillPrescriptionBody from "./RefillPrescriptionBody";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectFileUpload } from "ui/medrefill/ducks/selectors";
import { getInitialUploadState } from "lib/components/upload/ImageUploader/processUploadedFile.states";

const RefillPrescription = ({
  isEditablePage = false,
  prescriptionState,
  institutionState,
  onSelectPrescription,
  documentList,
  preventDirectBackNavigationState,
  resetPreventDirectBackNavigationState,
  medicationCardDataList,
  setMedicationCardDataList,
  date,
  setDate,
}: RefillPrescriptionProps) => {
  const dispatch = useAppDispatch();
  const { fileUploadPrescriptionErrorArray, fileUploadDetails } =
    useAppSelector(selectFileUpload);

  // DISCLAIMER TEXT
  const PRES_DISC_TYPE = "RefillPrescriptionDetailDisclaimerText";
  const PRES_DISC_CONTENT = documentList.filter(
    (doc) => doc.type === PRES_DISC_TYPE,
  );

  const [isLoading, setIsLoading] = useState<boolean[][]>(
    new Array(medicationCardDataList.length).fill([false, false]),
  );

  // Date of Original Prescription date validation: should not allow more than 1 year back
  const now = new Date();
  const oneYearBeforeDay = formatDateToISO(
    new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()),
  );

  return (
    <>
      <RefillPrescriptionBody
        onImageProcessing={(loading, currentIndex, currentImagePairIndex) => {
          const prevList = [...isLoading];
          prevList[currentIndex][currentImagePairIndex] = loading;
          setIsLoading(prevList);
        }}
        date={date}
        setDate={setDate}
        minDate={oneYearBeforeDay}
        facilityName={institutionState.facilityName}
        prescriptionDisclaimerText={PRES_DISC_CONTENT}
        medicationCardDataList={medicationCardDataList}
        setMedicationCardData={(newMedicationCardData, currentIndex) => {
          // Clone the state of the current list of medication data
          const prevListOfMedicationCardData = [...medicationCardDataList];

          // Set the object with the new user input value
          prevListOfMedicationCardData[currentIndex] = newMedicationCardData;

          // Finally, set the current state to the new list
          setMedicationCardDataList(prevListOfMedicationCardData);
        }}
        onClickAddMedicationCard={() => {
          // Clone the state of the current list of medication data
          const prevListOfMedicationCardData = [...medicationCardDataList];

          // Set the object with an empty medication card
          prevListOfMedicationCardData.push({
            frontFileName: null,
            frontFileData: null,
            backFileName: null,
            backFileData: null,
          });

          // Finally, set the current state to the new list
          setMedicationCardDataList(prevListOfMedicationCardData);

          const prevLoading = [...isLoading];
          prevLoading.push([false, false]);
          setIsLoading(prevLoading);

          //file upload error state
          dispatch(
            setFileUploadPrescriptionError([
              ...fileUploadPrescriptionErrorArray,
              [false, false],
            ]),
          );

          //file upload details state
          dispatch(
            setFileUploadDetails([
              ...fileUploadDetails,
              [{ ...getInitialUploadState() }, { ...getInitialUploadState() }],
            ]),
          );
        }}
        onClickDeleteMedicationCard={(index: number) => {
          // Clone the state of the current list of medication data
          const prevListOfMedicationCardData = [...medicationCardDataList];

          // Set the object with an empty medication card
          if ([...medicationCardDataList].length !== 1) {
            prevListOfMedicationCardData.splice(index, 1);
          }

          // Finally, set the current state to the new list
          setMedicationCardDataList(prevListOfMedicationCardData);

          const prevLoading = [...isLoading];
          if ([...isLoading].length !== 1) {
            prevLoading.splice(index, 1);
          }
          setIsLoading(prevLoading);

          //file upload error state
          const newFileUploadPrescriptionError = [
            ...fileUploadPrescriptionErrorArray,
          ];
          newFileUploadPrescriptionError.splice(index, 1);
          dispatch(
            setFileUploadPrescriptionError([...newFileUploadPrescriptionError]),
          );

          //file upload details state
          const newFileUploadDetails = [...fileUploadDetails];
          newFileUploadDetails.splice(index, 1);
          dispatch(setFileUploadDetails([...newFileUploadDetails]));
        }}
        disableMedicationCardRegion={
          isLoading.find((pair) => pair.find((loading) => loading === true)) !==
          undefined
        }
      />
    </>
  );
};

export default RefillPrescription;
