import axios from "axios";
import { GET_TRANSACTION_HISTORY_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatGetTransactionHistoryErrorMessage } from "api/resources/messages/ErrorMessage";
import {
  GetTransactionHistoryRequestPayload,
  GetTransactionHistoryResponsePayload,
} from "./getTransactionHistory.fromApi.types";

/**
 * Try adding a manual bill and get its information
 *
 * POST
 * Endpoint: secure/payment/GetTransactionHistory
 *
 * @param {GetPaymentInfoRequestPayload} request
 */
const getTransactionHistory = async (
  request: GetTransactionHistoryRequestPayload,
): Promise<GetTransactionHistoryResponsePayload> => {
  try {
    const response = await axios.post<GetTransactionHistoryResponsePayload>(
      GET_TRANSACTION_HISTORY_URL(),
      request,
      getRequestOptions(),
    );
    return parseResponse(response, GET_TRANSACTION_HISTORY_URL());
  } catch (error) {
    const msg = formatGetTransactionHistoryErrorMessage(
      GET_TRANSACTION_HISTORY_URL(),
    );
    console.error(msg);
    throw error;
  }
};

export { getTransactionHistory };
