import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { PATHS } from "lib/routing";
import Page from "lib/components/layout/Page/Page";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import VerticalSpreadLayout from "lib/components/layout/VerticalSpreadLayout/VerticalSpreadLayout";
import {
  REFILL_STEP_LABELS,
  TOPUP_STEP_LABELS,
  REFILL_REORDER_STEP_LABELS,
  TOPUP_REORDER_STEP_LABELS,
  VIRTUAL_CONSULT_LABELS,
} from "./Steps";
import StepIndicator from "lib/components/wizard/StepIndicator/StepIndicator";
import {
  StepPageLayoutProps,
  MedRefillRequestType,
} from "./StepPageLayout.types";
import { sxStyles } from "./StepPageLayout.styles";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import CancelRequestConfirmationModal from "../confirmationModals/CancelRequestConfirmationModal/CancelRequestConfirmationModal";

// ============================
// Step Indicator
// ============================

const renderStepIndicator = (
  requestType: MedRefillRequestType,
  reorderFlag: boolean,
  editableFlag: boolean,
  currentStepIndex: number,
) => {
  if (editableFlag) {
    // If it is an editable page, we should not render the step indicator because
    // it's not in the normal REFILL or TOPUP flow anymore. It's a standalone step.
    return null;
  }

  switch (requestType) {
    case "REFILL":
      return (
        <StepIndicator
          steps={!reorderFlag ? REFILL_STEP_LABELS : REFILL_REORDER_STEP_LABELS}
          currentStepIndex={
            !reorderFlag ? currentStepIndex : currentStepIndex - 1
          }
        />
      );
    case "TOPUP":
      return (
        <StepIndicator
          steps={!reorderFlag ? TOPUP_STEP_LABELS : TOPUP_REORDER_STEP_LABELS}
          currentStepIndex={
            !reorderFlag ? currentStepIndex : currentStepIndex - 1
          }
        />
      );
    case "VIRTUALCONSULT":
      return (
        <StepIndicator
          steps={VIRTUAL_CONSULT_LABELS}
          currentStepIndex={currentStepIndex}
        />
      );
    default:
      return null;
  }
};

// ============================
// Google Analytics Logging Event
// ============================

const logViewEventToGoogleAnalytics = (
  requestType: MedRefillRequestType,
  reorderFlag: boolean,
  currentStepIndex: number,
) => {
  if (requestType === "REFILL") {
    if (reorderFlag === false) {
      switch (currentStepIndex) {
        case 0:
          logEventToGoogleAnalytics(EVENTS.VIEW_NEW_REFILL_PARTICULARS);
          break;
        case 1:
          logEventToGoogleAnalytics(EVENTS.VIEW_NEW_REFILL_QUANTITY);
          break;
        case 2:
          logEventToGoogleAnalytics(EVENTS.VIEW_NEW_REFILL_COLLECTION);
          break;
        case 3:
          logEventToGoogleAnalytics(EVENTS.VIEW_NEW_REFILL_PAYMENT);
          break;
        default:
          break;
      }
    } else {
      switch (currentStepIndex) {
        case 1:
          logEventToGoogleAnalytics(EVENTS.VIEW_REORDER_REFILL_QUANTITY);
          break;
        case 2:
          logEventToGoogleAnalytics(EVENTS.VIEW_REORDER_TOPUP_COLLECTION);
          break;
        case 3:
          logEventToGoogleAnalytics(EVENTS.VIEW_REORDER_TOPUP_PAYMENT);
          break;
        default:
          break;
      }
    }
  } else if (requestType === "TOPUP") {
    if (reorderFlag === false) {
      switch (currentStepIndex) {
        case 0:
          logEventToGoogleAnalytics(EVENTS.VIEW_NEW_TOPUP_PARTICULARS);
          break;
        case 1:
          logEventToGoogleAnalytics(EVENTS.VIEW_NEW_TOPUP_PRESCRIPTION);
          break;
        case 2:
          logEventToGoogleAnalytics(EVENTS.VIEW_NEW_TOPUP_COLLECTION);
          break;
        case 3:
          logEventToGoogleAnalytics(EVENTS.VIEW_NEW_TOPUP_PAYMENT);
          break;
        default:
          break;
      }
    } else {
      switch (currentStepIndex) {
        case 1:
          logEventToGoogleAnalytics(EVENTS.VIEW_REORDER_TOPUP_PRESCRIPTION);
          break;
        case 2:
          logEventToGoogleAnalytics(EVENTS.VIEW_REORDER_TOPUP_COLLECTION);
          break;
        case 3:
          logEventToGoogleAnalytics(EVENTS.VIEW_REORDER_TOPUP_PAYMENT);
          break;
        default:
          break;
      }
    }
  } else if (requestType === "VIRTUALCONSULT") {
    switch (currentStepIndex) {
      case 1:
        logEventToGoogleAnalytics(EVENTS.VIEW_NEW_VIRTUAL_CONSULT_PARTICULARS);
        break;
      case 2:
        logEventToGoogleAnalytics(EVENTS.VIEW_NEW_VIRTUAL_CONSULT_COLLECTION);
        break;
      case 3:
        logEventToGoogleAnalytics(EVENTS.VIEW_NEW_VIRTUAL_CONSULT_PAYMENT);
        break;
      default:
        break;
    }
  }
};

// ============================
// COMPONENT
// ============================

const StepPageLayout = ({
  children,
  requestType,
  reorderFlag,
  editableFlag = false,
  currentStepIndex,
  isNextButtonDisabled = false, // TODO: Remove default once refactoring is done
  shouldShowButtonsFooter = true, // TODO: Remove default once refactoring is done
  pathToNextStep,
  pathToCancelRequest = PATHS.MEDICATIONS_MOBILE.path,
  onNextStep,
}: StepPageLayoutProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();

  logViewEventToGoogleAnalytics(requestType, reorderFlag, currentStepIndex);

  // Cancel Request Warning Dialog open & close state and handlers
  const [open, setOpen] = useState(false);
  const handleOpenWarningModal = () => {
    if (requestType === "REFILL" && currentStepIndex === 4) {
      logEventToGoogleAnalytics(EVENTS.CANCEL_REQUEST_AT_REFILL_PAYMENT);
    } else if (requestType === "TOPUP" && currentStepIndex === 4) {
      logEventToGoogleAnalytics(EVENTS.CANCEL_REQUEST_AT_TOPUP_PAYMENT);
    } else if (requestType === "VIRTUALCONSULT" && currentStepIndex === 4) {
      logEventToGoogleAnalytics(
        EVENTS.CANCEL_REQUEST_AT_VIRTUAL_CONSULT_PAYMENT,
      );
    }
    setOpen(true);
  };
  const handleCloseWarningModal = () => setOpen(false);

  return (
    <Page>
      {renderStepIndicator(
        requestType,
        reorderFlag,
        editableFlag,
        currentStepIndex,
      )}
      <VerticalSpreadLayout>
        <Box sx={classes.formSection}>{children}</Box>
        {shouldShowButtonsFooter ? (
          // TODO: For temporary use as the refactor is ongoing
          // Can be removed once all pages follow the same layout
          <>
            <Box mt={2} mb={3} sx={classes.buttonsFooter}>
              <ButtonsFooter
                isDisabled={isNextButtonDisabled}
                nextButtonText="Next step"
                cancelButtonText="Cancel request"
                onClickNext={() => {
                  onNextStep();
                  navigate(pathToNextStep);
                }}
                onClickCancel={handleOpenWarningModal}
              />
            </Box>
            <CancelRequestConfirmationModal
              preventDirectCancel={open}
              onClickCancel={handleCloseWarningModal}
            />
          </>
        ) : null}
      </VerticalSpreadLayout>
    </Page>
  );
};

export default StepPageLayout;
