import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Scrolls to the top of the browser web view, when a route changes.
 * (recommended approach by the react-router documentation)
 *
 * === Note to developers:
 * Scrolls to the top of the BROWSER WEB VIEW'S global window, however, if a child component
 * wraps the page and has a style that forces the scroll to be preserved, this method will
 * not work and scrolling must be handled within that child component, additionally.
 */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <></>;
};

export default ScrollToTop;
