import { connect } from "react-redux";
// import { RootState } from "lib/redux/root/redux.types";
import { RootDispatch, RootState } from "lib/redux/root/redux.types";
// import { Dispatch, AnyAction } from "redux";
import ReportDetail from "./ReportDetail";
import { MrrpReportDetailState } from "ui/mrrp/ducks/mrrp.types";
import { setReportDetails, fetchReportOptions } from "ui/mrrp/ducks";
import { setCustomisedBack } from "lib/redux/navigation";

const mapStateToProps = (state: RootState) => {
  const selectedHospital = state.mrrp.hospital.selectedHospitalCode ?? "";
  const formLabelSelected = state.mrrp.formLabel.labels.find((label) => {
    return label.institutions.includes(selectedHospital);
  });
  const formLabelDefault = state.mrrp.formLabel.labels.find((label) => {
    return label.institutions.includes("Default");
  });
  const formLabel = formLabelSelected ?? formLabelDefault;
  return {
    visitDateFormLabel: formLabel?.visitDate ?? null,
    reportTypeFormLabel: formLabel?.reportType ?? null,
    reportDescriptionFormLabel: formLabel?.reportDescription ?? null,

    reportDetail: state.mrrp.reportDetail,

    preventDirectBackNavigationState: state.navigation.customisedBack,

    departments: state.mrrp.reportOptions.departments,
    purposes: state.mrrp.reportOptions.purposes,

    devicePlatform: state.system.devicePlatform,
    selectedHospitalCode: state.mrrp.hospital.selectedHospitalCode,
  };
};

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    onSelectReportDetail: (reportDetailState: MrrpReportDetailState) => {
      dispatch(setReportDetails(reportDetailState));
    },
    resetPreventDirectBackNavigationState: () => {
      dispatch(setCustomisedBack(false));
    },
    onGetReportOptions: (
      hospitalCode: string | null,
      reportTypeCode: string | null,
    ) => {
      dispatch(fetchReportOptions(hospitalCode || "", reportTypeCode || ""));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail);
