import React, { useState } from "react";
import { VerifyParticularsProps } from "./VerifyParticulars.types";
import { Typography, Box } from "@mui/material";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import { MrrpParticularsState } from "ui/mrrp/ducks/mrrp.types";
import Dropdown from "lib/components/formInputs/Dropdown/Dropdown";
import { sxStyles } from "./VerifyParticulars.styles";
import VerticalSpreadLayout from "lib/components/layout/VerticalSpreadLayout/VerticalSpreadLayout";
import { validatePhoneNumber } from "lib/util/ValidatorUtil/phoneNumberValidator/phoneNumberValidator";
import { validateEmail } from "lib/util/ValidatorUtil/emailValidator/emailValidator";
import StepPageButtonsFooter from "../../common/StepPageButtonsFooter/StepPageButtonsFooter";
import { PATHS } from "lib/routing";
import { ViewPatientRelationship } from "api/mrrp/GetReportOptions/getReportOptions.fromApi.types";
import { maskNric } from "ui/appointment/components/common/user/UserProfile/maskNric";

const patientRelationshipMapper = (
  patientRelationships: ViewPatientRelationship[],
) => {
  return patientRelationships.map((relationship) => ({
    id: relationship.Code,
    text: relationship.Description,
  }));
};

const VerifyParticulars = ({
  requestorNameFormLabel,
  relationshipFormLabel,
  contactNoFormLabel,
  userState,
  patientRelationships,
  particulars,
  onSetParticulars,
}: VerifyParticularsProps) => {
  const classes = sxStyles();

  const [particularsState, setParticularsState] =
    useState<MrrpParticularsState>({
      ...particulars,
      patientName: particulars.patientName ?? userState.patientName, //prepopulate patientName only if it is empty
      patientNric: particulars.patientNric ?? userState.patientNric, //prepopulate patientNric only if it is empty
      requestorName: particulars.requestorName ?? userState.requestorName, //prepopulate requestorName only if it is empty
      contactNumber: particulars.contactNumber ?? userState.contact,
      email: particulars.email ?? userState.email,
    });

  const disableNext = () => {
    const isSelfOrRelationshipSelected =
      userState.isPatient || particularsState.relationshipCodeWithPatient;
    if (
      particularsState.patientName &&
      particularsState.requestorName &&
      isSelfOrRelationshipSelected &&
      validatePhoneNumber(particularsState.contactNumber) &&
      (!particularsState.alternativeNumber ||
        validatePhoneNumber(particularsState.alternativeNumber)) &&
      validateEmail(particularsState.email)
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <VerticalSpreadLayout>
      <Box mx={2}>
        <Typography sx={classes.title}>Particulars</Typography>
        <Typography sx={classes.formLabel}>NRIC/FIN</Typography>
        <Typography sx={classes.text}>
          {maskNric(particularsState.patientNric)}
        </Typography>
        <Box pb={4} sx={classes.singleLineTextBox}>
          <SingleLineTextField
            value={particularsState.patientName}
            placeholder={"Patient's Name"}
            handleChange={(event) =>
              setParticularsState({
                ...particularsState,
                patientName: event.target.value,
              })
            }
            required={false}
          ></SingleLineTextField>
        </Box>
        <Box pb={4} sx={classes.singleLineTextBox}>
          <SingleLineTextField
            value={particularsState.requestorName}
            placeholder={
              requestorNameFormLabel
                ? requestorNameFormLabel
                : "Requestor's Name"
            }
            handleChange={(event) =>
              setParticularsState({
                ...particularsState,
                requestorName: event.target.value,
              })
            }
            required={false}
          ></SingleLineTextField>
        </Box>

        {/* show dropdown only when not requesting for self */}
        {!userState.isPatient ? (
          <Box sx={classes.textBox}>
            <Dropdown
              value={particularsState.relationshipCodeWithPatient}
              items={patientRelationshipMapper(patientRelationships)}
              label={
                relationshipFormLabel
                  ? relationshipFormLabel
                  : "Relationship with Patient"
              }
              handleChange={(event) => {
                const relationship = patientRelationships.find(
                  (x) => x.Code === event.target.value,
                );
                setParticularsState({
                  ...particularsState,
                  relationshipCodeWithPatient: relationship?.Code || null,
                  relationshipWithPatient: relationship?.Description || null,
                });
              }}
              required={false}
            ></Dropdown>
          </Box>
        ) : null}
        <Typography sx={classes.title}>Contact Details</Typography>
        <Box pb={4} sx={classes.singleLineTextBox}>
          <SingleLineTextField
            value={particularsState.contactNumber}
            type="number"
            placeholder={
              contactNoFormLabel ? contactNoFormLabel : "Contact Number"
            }
            maxCharLength={8}
            handleChange={(event) =>
              setParticularsState({
                ...particularsState,
                contactNumber: event.target.value,
              })
            }
            error={
              particularsState.contactNumber
                ? !validatePhoneNumber(particularsState.contactNumber)
                : false
            }
            errorText="Enter a valid phone number"
            required={false}
          ></SingleLineTextField>
        </Box>
        <Box pb={4} sx={classes.singleLineTextBox}>
          <SingleLineTextField
            value={particularsState.alternativeNumber}
            type="number"
            placeholder="Alternative Number"
            maxCharLength={8}
            handleChange={(event) =>
              setParticularsState({
                ...particularsState,
                alternativeNumber: event.target.value,
              })
            }
            error={
              particularsState.alternativeNumber
                ? !validatePhoneNumber(particularsState.alternativeNumber)
                : false
            }
            errorText="Enter a valid phone number"
            required={false}
          ></SingleLineTextField>
        </Box>
        <Box pb={4} sx={classes.singleLineTextBox}>
          <SingleLineTextField
            value={particularsState.email}
            placeholder={"Email"}
            handleChange={(event) =>
              setParticularsState({
                ...particularsState,
                email: event.target.value,
              })
            }
            error={
              particularsState.email
                ? !validateEmail(particularsState.email)
                : false
            }
            errorText="Enter a valid email"
            required={false}
          ></SingleLineTextField>
        </Box>
      </Box>

      <StepPageButtonsFooter
        isNextButtonDisabled={disableNext()}
        pathToNextStep={PATHS.MRRP_NEW_REQUEST_REPORT_DETAILS.path}
        onNextStep={() => onSetParticulars(particularsState)}
      ></StepPageButtonsFooter>
    </VerticalSpreadLayout>
  );
};

export default VerifyParticulars;
