import { Box, Typography } from "@mui/material";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { DeliveryProps } from "./Delivery.types";
import { sxStyles } from "./Delivery.styles";
import { mapApiLocationsToPreferredDeliveryTimeSlots } from "./mapper";
import Dropdown from "lib/components/formInputs/Dropdown/Dropdown";
import PreferredDatepicker from "../PreferredDatepicker/PreferredDatepicker";
import AddressFields from "lib/components/formFields/AddressFields/AddressFields";
import DisclaimerText from "./DisclaimerText/DisclaimerText";
import { getAddressByPostalCode } from "api/medrefill/GetAdressByPostalCode/getAddressByPostalCode";
import { mapLocationsListFromApiToOptions } from "../PickUpAtPharmacy/mapper";
import { REQUEST_TYPES } from "../Collection/CollectionCodes";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";

const Delivery = ({
  chosenLocation,
  collection,
  locations,
  facilityId,
  location,
  deliveryTimeSlotId,
  formLabelText,
  datepickerLabelText,
  requestType,
  onSelectLocation,
  setCollection,
}: DeliveryProps) => {
  const classes = sxStyles();
  const preferredDeliveryTimeOptions =
    mapApiLocationsToPreferredDeliveryTimeSlots(location, collection.date);

  const locationDropdownOptions = mapLocationsListFromApiToOptions(locations);

  return (
    <Box>
      <Box mt={2} pb={2}>
        <Typography sx={classes.formTitle}>{formLabelText}</Typography>
        {
          // Disclaimer Texts
          // Only render InfoPanel if all at least some disclaimer needs to be displayed
          location.DisclaimerText || location.DeliveryCharges ? (
            // <Box sx={{ my: 2 }}>
            <SharpNoticePanel bgColor="warn" showIcon>
              <>
                {location.DeliveryCharges ? (
                  <DisclaimerText htmlText={location.DeliveryCharges} />
                ) : null}
                {location.DisclaimerText ? (
                  <>
                    {location.DeliveryCharges && <br />}
                    <DisclaimerText htmlText={location.DisclaimerText} />
                  </>
                ) : null}
              </>
            </SharpNoticePanel>
          ) : // </Box>
          null
        }
      </Box>

      {/* Prescribing Clinic Dropdown */}
      {/* Dropdown will only be rendered for NUHS(FacilityId===41) */}
      {facilityId === 41 &&
        (requestType === REQUEST_TYPES.REFILL ||
          requestType === REQUEST_TYPES.VCP) && (
          <Box mt={2} mb={2}>
            <Dropdown
              label="Prescribing Clinic"
              value={chosenLocation?.LocationId}
              items={locationDropdownOptions}
              handleChange={(event) => onSelectLocation(event.target.value)}
            />
          </Box>
        )}

      {/* Delivery Dates & Timeslots */}
      <Box mt={2} mb={2}>
        <PreferredDatepicker
          label={datepickerLabelText}
          date={collection.date}
          location={location}
          onSelectDate={(dateValue) => {
            setCollection({
              ...collection,
              date: formatDateToISO(dateValue),
              timeslot: null,
            });
          }}
        />
      </Box>

      <Box mt={3} mb={2}>
        {preferredDeliveryTimeOptions.length > 0 ? (
          // Only show dropdown when there are options (after a day has been chosen)
          <>
            <Dropdown
              label="Preferred Delivery Time"
              value={deliveryTimeSlotId}
              items={preferredDeliveryTimeOptions}
              handleChange={(event) => {
                setCollection({
                  ...collection,
                  timeslot: event.target.value,
                });
              }}
            />
          </>
        ) : null}
      </Box>

      {/* My Registered Address */}
      <Box mt={2} mb={2}>
        <Typography sx={classes.formTitle}>Address</Typography>
        <AddressFields
          postalCode={collection.postalCode}
          block={collection.blockNo}
          unitNo={collection.unitNo}
          street={collection.address}
          errorPostalCode={collection.errorPostalCode}
          onChangePostalCode={async (value) => {
            const errorPostalCode = value.length === 0 ? false : true;
            setCollection({
              ...collection,
              postalCode: value,
              errorPostalCode,
            });
            if (value.length === 6) {
              const addrPayload = await getAddressByPostalCode(value);
              if (addrPayload.HAS_VALID_ADDRESS && !addrPayload.HAS_ERRORED) {
                setCollection({
                  ...collection,
                  postalCode: value,
                  blockNo: addrPayload.BLK_NO,
                  address: addrPayload.ROAD_NAME,
                  errorPostalCode: false,
                });
              }
            }
          }}
          onChangeBlock={(value) => {
            setCollection({
              ...collection,
              blockNo: value,
            });
          }}
          onChangeUnitNo={(value) => {
            setCollection({
              ...collection,
              unitNo: value,
            });
          }}
          onChangeStreet={(value) => {
            setCollection({
              ...collection,
              address: value,
            });
          }}
        />
      </Box>
    </Box>
  );
};

export default Delivery;
