import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.text.primary,
      fontSize: size.LIST_TITLEFONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      margin: theme.spacing(2, 0),
    },
    apptInfo: {
      backgroundColor: theme.palette.secondary.main,
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(1, 2),
    },
    apptInfoTextLabel: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      width: "28%",
    },
    apptInfoText: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
    },

    disclaimerInfo: {
      border: `1px solid ${theme.palette.primary.main}`,
      background: theme.palette.secondary.main,
      "& .MuiList-root": {
        paddingTop: 0,
      },
      "& .MuiListItemIcon-root": {
        color: theme.palette.secondary.main,
      },
      "& .MuiListItem-root": {
        marginTop: theme.spacing(1),
        "& :nth-child(1)": {
          marginTop: 0,
        },
      },
      padding: theme.spacing(1),
      color: theme.palette.grey[400],
      fontSize: size.FONTSIZE_12,
    },
    checkboxField: {
      margin: theme.spacing(2, 0, 0, 1),
    },

    infoBox: {
      border: `1px solid ${theme.palette.primary.main}`,
      background: theme.palette.secondary.main,
      padding: theme.spacing(0.5),
      display: "flex",
      lineHeight: theme.typography.body1.lineHeight,
      borderRadius: theme.spacing(0.5),
      margin: theme.spacing(2, 0),
      "& :nth-child(3)": {
        clear: "both",
      },
    },
    infoText: {
      color: theme.palette.grey[400],
      fontSize: size.FONTSIZE_12,
      width: "100%",
    },

    requireBox: {
      border: `1px solid ${theme.palette.grey[100]}`,
      background: theme.palette.common.white,
      padding: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      borderRadius: theme.spacing(0.5),
    },
  } satisfies SxObjType;
};
export { sxStyles };
