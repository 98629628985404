import { RootDispatch } from "lib/redux/root/redux.types";
import { connect } from "react-redux";

import { updatePastRequestRequestorId } from "ui/medrefill/ducks";
import MedicationCard from "./MedicationCard";

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    updateRequesterId: (requesterId: number) => {
      dispatch(updatePastRequestRequestorId(requesterId));
    },
  };
};

export default connect(null, mapDispatchToProps)(MedicationCard);
