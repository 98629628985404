import {
  authAuthority,
  authClientId,
  authClientSecret,
  authRedirectURI,
  authScope,
} from "../configs/config";
import CallbackSuccess from "lib/components/auth/CallbackSuccess/CallbackSuccess";
import SessionLost from "lib/components/auth/SessionLost/SessionLost";
import LoadingPage from "lib/components/auth/LoadingPage/LoadingPage";
import { OidcLocation } from "./OidcLocation";
import { TokenAutomaticRenewMode } from "@axa-fr/react-oidc";

export const AUTH_CONFIG = {
  sessionLostComponent: SessionLost,
  loadingComponent: LoadingPage,
  authenticatingComponent: LoadingPage,
  callbackSuccessComponent: CallbackSuccess,
  configuration: {
    client_id: authClientId(),
    redirect_uri: authRedirectURI(),
    scope: authScope(),
    authority: authAuthority(),
    refresh_time_before_tokens_expiration_in_second: 0,
    service_worker_relative_url: "/OidcServiceWorker.js",
    service_worker_only: false,
    token_request_extras: {
      client_secret: authClientSecret(),
    },
    token_renew_mode: "id_token_invalid",
    token_automatic_renew_mode:
      TokenAutomaticRenewMode.AutomaticOnlyWhenFetchExecuted,
  },
  location: new OidcLocation(),
};
