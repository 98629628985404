import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    receiptSection: { padding: theme.spacing(1, 0) },
    receiptPanel: {
      display: "flex",
      padding: theme.spacing(0, 1),
      alignItems: "center",
    },
    payPanel: {
      display: "flex",
      alignItems: "center",
      borderTop: "1px solid " + theme.palette.secondary.light,
      borderBottom: "1px solid " + theme.palette.secondary.light,
      padding: theme.spacing(1.5, 1),
    },
    leftBox: {
      width: "100%",
      padding: theme.spacing(0.75, 0),
    },
    rightBox: {
      width: "100%",
      padding: theme.spacing(0.75, 0),
    },
    title: {
      color: theme.palette.text.primary,
      typography: theme.typography.caption1_semibold,
      width: "100%",
    },
    content: {
      color: theme.palette.grey[600],
      typography: theme.typography.caption1_semibold,
      width: "100%",
    },
    payValue: {
      color: theme.palette.warning.main,
      typography: theme.typography.body2_semibold,
      width: "100%",
    },
  } satisfies SxObjType;
};

export { sxStyles };
