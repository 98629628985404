import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppointmentFormHeader from "../../AppointmentFormHeader/AppointmentFormHeader";
import WebApptFormField from "../WebApptFormField/WebApptFormField";
import { Box, CircularProgress, Typography } from "@mui/material";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { PATHS, ACTIONS } from "lib/routing";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { WebApptFormProps } from "./WebApptForm.types";
import { WebApptFormFieldStruct } from "../WebApptFormField/WebApptFormField.types";
import { PersonalParticularsFormStruct } from "../../Form/Form.types";
import { validatePhoneNumber } from "lib/util/ValidatorUtil/phoneNumberValidator/phoneNumberValidator";
import { validateEmail } from "lib/util/ValidatorUtil/emailValidator/emailValidator";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { useSelector } from "react-redux";
import { sxStyles } from "./WebApptForm.styles";
import SimpleCheckbox from "lib/components/formInputs/Checkbox/SimpleCheckbox";
import { useAppSelector } from "lib/redux/hooks";
import {
  selectAppointments,
  selectAppointmentsSystemSettings,
} from "ui/appointment/ducks/selectors";
import { parseJson } from "lib/util/StringUtil/jsonParser/parseJson";
import PersonalParticularsForm from "../../PersonalParticularsForm/PersonalParticularsForm";

// ==========================
// COMPONENT
// ==========================

export const WebApptForm = ({
  apptFormType,
  personalParticularsFormField,

  submitIsLoading,
  openConfirmationModal,
  onSubmitAppointmentRequest,
  handlePersonalParticularsFormFieldChange,
}: WebApptFormProps) => {
  const classes = sxStyles();
  const navigate = useNavigate();
  const location = useLocation();

  // Redux states
  const institutionName =
    useAppSelector(selectAppointments).reschedule.selectedInstitution ?? "";
  const currentApptdateTime =
    useAppSelector(selectAppointments).reschedule.selectedDateTime;
  const serviceDisplayName =
    useAppSelector(selectAppointments).reschedule.selectedService;
  const departmentName =
    useAppSelector(selectAppointments).reschedule.selectedDepartmentName ?? "";
  const formValues = useSelector(selectAppointmentsSystemSettings).values[
    "NUPApptOfflineFormText"
  ]?.Value;

  const {
    Alert: alert,
    MainInfo: mainInfo,
    Notes: notes,
  }: {
    Alert: string;
    MainInfo: string;
    Notes: string;
  } = formValues
    ? parseJson(formValues)
    : {
        Alert: "",
        MainInfo: "",
        Notes: "",
      };

  // Local states
  const [errorDOB, setErrorDOB] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [webApptFormField, setWebApptFormField] =
    useState<WebApptFormFieldStruct>({
      preferredStartDate: null,
      preferredEndDate: null,
      remarks: "",
    });

  const isSubmitDisabled = useMemo(
    () =>
      disableSubmit(
        submitIsLoading,
        webApptFormField,
        personalParticularsFormField,
        errorDOB,
        isChecked,
      ),
    [
      errorDOB,
      isChecked,
      personalParticularsFormField,
      submitIsLoading,
      webApptFormField,
    ],
  );

  // Event fired whenever a field on this reschedule form has been updated
  // name: name attribute of input component
  const handleChange = (event: {
    target: {
      name: string;
      value: string;
    };
  }) => {
    const { name, value } = event.target;
    const updatedWebApptFormField = {
      ...webApptFormField,
      [name]: value,
    };
    setWebApptFormField(updatedWebApptFormField);
  };

  const handleSubmit = () => {
    if (
      currentApptdateTime &&
      webApptFormField.preferredStartDate &&
      webApptFormField.preferredEndDate
    ) {
      // !exp Open/Missed appointment flow will use the flow of change appointment so that backend can use correctly map change appointment fields to email template
      onSubmitAppointmentRequest({
        IsMakeApptAction: false,
        IsRescheduleApptAction: true,
        IsCancelApptAction: false,

        ChangeAppointmentOriginalDate: currentApptdateTime,
        ChangeAppointmentPreferredStartDate:
          webApptFormField.preferredStartDate,
        ChangeAppointmentPreferredEndDate: webApptFormField.preferredEndDate,
        ChangeAppointmentRemarks: webApptFormField.remarks,
        NewAppointmentSpecialty: serviceDisplayName, // !exp this maps to backend Service type. backend does not have any other field to receive service type update so it is used even in the reschedule flow
        DepartmentName: departmentName,
      });
    }
  };

  return (
    <Box p={2}>
      {submitIsLoading ? (
        <Box display="flex" align-items="center" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <AppointmentFormHeader alert={alert} mainInfo={mainInfo} />
          <WebApptFormField
            apptFormType={apptFormType}
            name={institutionName}
            currentApptDateTime={
              currentApptdateTime ? formatDate(currentApptdateTime) : ""
            }
            serviceDisplayName={serviceDisplayName}
            rescheduleApptFormField={webApptFormField}
            handleChange={handleChange}
          />
          <PersonalParticularsForm
            personalParticularsFormField={personalParticularsFormField}
            handlePersonalParticularsFormFieldChange={(event: {
              target: { name: string; value: string };
            }) => {
              handlePersonalParticularsFormFieldChange(event);
              setErrorDOB(false);
            }}
            showRequiredForEmail={true}
            onInputError={() => setErrorDOB(true)}
          />

          <Box sx={classes.checkboxContainer}>
            <SimpleCheckbox
              checked={isChecked}
              label={
                <Typography component="span" sx={classes.checkBoxLabel}>
                  {notes}
                </Typography>
              }
              value="agreementCheck"
              handleChange={() => setIsChecked((current) => !current)}
              required={true}
            />
          </Box>
          <ButtonsFooter
            nextButtonText="Send request"
            cancelButtonText="Cancel"
            isDisabled={isSubmitDisabled}
            onClickNext={() => handleSubmit()}
            onClickCancel={() => {
              if (
                location.pathname ===
                PATHS.APPOINTMENT_RESCHEDULE_FORM_MOBILE.path
              ) {
                mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH);
              } else {
                navigate(-1);
              }
            }}
          />
          <ConfirmationModal
            title="Request Submitted"
            body="We will get back to you with a confirmation for this request shortly."
            hideNextButton={true}
            hideCancelButton={false}
            cancelButtonText="Go to Appointment"
            onClickCancel={() => {
              navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
            }}
            open={openConfirmationModal}
          />
        </>
      )}
    </Box>
  );
};

// ==========================
// HELPER FUNCTIONS
// ==========================
/**
 * Disable submit button depending on whether required fields are present and of the required format,
 * and whether the appointment submission request has completed.
 *
 * @param submitIsLoading: whether the submit API call is still loading
 * @param webApptFormField: input fields on the form, only preferredStartDate and preferredEndDate are mandatory
 * @param personalParticularsFormField : mandatory input fields on the form
 * @param errorDOB: boolean when DOB has error
 * @param isChecked: boolean for agreement checkbox
 * @returns False if all mandatory input fields have been filled in, true otherwise.
 */
const disableSubmit = (
  submitIsLoading: boolean,
  webApptFormField: WebApptFormFieldStruct,
  personalParticularsFormField: PersonalParticularsFormStruct,
  errorDOB: boolean,
  isChecked: boolean,
) => {
  if (submitIsLoading) {
    return true;
  }

  if (!webApptFormField.preferredStartDate) {
    return true;
  }

  if (!webApptFormField.preferredEndDate) {
    return true;
  }

  if (!personalParticularsFormField.name) {
    return true;
  }

  if (!personalParticularsFormField.dateOfBirth) {
    return true;
  }

  if (!validatePhoneNumber(personalParticularsFormField.contact)) {
    return true;
  }

  if (!validateEmail(personalParticularsFormField.email)) {
    return true;
  }

  if (errorDOB) {
    return true;
  }

  if (!isChecked) {
    return true;
  }

  return false;
};

export default WebApptForm;
export const exportForTesting = { disableSubmit };
