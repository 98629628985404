import { RootStore } from "lib/redux/root/redux.types";
import { MobileIntegrationUserState } from "../user.types";
import { initializeUserProfileFromMobileIntegration } from "lib/redux/user";
import { validateOptionalField } from "lib/util/ValidatorUtil/fieldValidator/validateField";

const dispatchToReducer = (
  store: RootStore,
  userObj: MobileIntegrationUserState,
) => {
  validateOptionalField(userObj, "patientMemberIdentifier", true);
  validateOptionalField(userObj, "patientName", true);

  store.dispatch(initializeUserProfileFromMobileIntegration(userObj));
};

export { dispatchToReducer };
