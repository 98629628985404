import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    formTitle: {
      color: theme.palette.text.primary,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      // lineHeight: "19px",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    formContent: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      width: "100%",
      lineHeight: "25px",
    },
    formContentVal: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      width: "100%",
    },
    halfCard: {
      display: "flex",
      width: "100%",
    },
    pickupHrs: {
      backgroundColor: theme.palette.background.paper,
    },
  } satisfies SxObjType;
};

export { sxStyles };
