/**
 * If the relationType is C, return false, otherwise return true.
 * @param {"F" | "C" | null} relationType - "F" | "C" | null
 * @returns A boolean
 */
const shouldDisplayPrescribedMedication = (relationType: "F" | "C" | null) => {
  switch (relationType) {
    case "C":
      return false;
    default:
      return true;
  }
};

/**
 * control prescribedMedicationConsentFlag based on relationType
 * @param relationType
 * @param prescribedMedicationFlag
 */
const prescribedMedicationConsent = (
  relationType: "F" | "C" | null,
  prescribedMedicationConsentFlag: boolean,
) => {
  // for logged in user, consent is implicitly given
  if (!relationType) {
    return true;
  } else {
    return prescribedMedicationConsentFlag;
  }
};

export { shouldDisplayPrescribedMedication, prescribedMedicationConsent };
