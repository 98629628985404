import { SharpNoticePanelProps } from "./SharpNoticePanel.types";
import { sxStyles } from "./SharpNoticePanel.styles";
import ShowMoreLessPanel from "../ShowMoreLessPanel/ShowMoreLessPanel";
import { Box } from "@mui/material";
import IMAGES from "lib/assets/images";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";

const SharpNoticePanel = ({
  /**
   * Renders a notice panel with optional icon and announcement message.
   *
   * @param {string} bgColor - The background color of the panel. Defaults to "info".
   * @param {ReactNode} children - The content to display inside the panel.
   * @param {boolean} showIcon - Determines whether to display an icon. Defaults to true.
   * @param {boolean} announcement - Determines whether the panel is an announcement. Defaults to false.
   * @param {number} linesToDisplay - The number of lines to display in the announcement message. Defaults to 4.
   * @param {string} announcementMessage - The announcement message to display.
   * @param {boolean} showError - Determines whether to display an error icon. Defaults to false.
   * @return {ReactElement} The rendered notice panel component.
   */
  bgColor = "info",
  children,
  showIcon,
  announcement = false,
  linesToDisplay = 4,
  announcementMessage = "",
  showError = false,
  showRaw = true, //by default show string
}: SharpNoticePanelProps) => {
  const classes = sxStyles({ bgColor, announcement });

  return (
    <Box sx={classes.infoBox}>
      {showError ? (
        <Box
          component={"img"}
          src={IMAGES.general.WarningIcon}
          sx={classes.infoIcon}
        />
      ) : (
        showIcon && (
          <Box
            component={"img"}
            src={IMAGES.general.InformationIcon}
            sx={classes.infoIcon}
          />
        )
      )}

      {announcement ? (
        <ShowMoreLessPanel
          message={announcementMessage || ""}
          linesToDisplay={linesToDisplay}
          showRaw={showRaw}
        />
      ) : (
        <Box sx={classes.body}>
          {!showRaw
            ? typeof children === "string" &&
              MapRawStringToReactElement(children)
            : children}
        </Box>
      )}
    </Box>
  );
};

export default SharpNoticePanel;
