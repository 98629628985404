import WebSessionStorage from "redux-persist/lib/storage/session";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

// ========================
// REDUX-PERSIST CONFIGS
// ========================

/**
 * === State Reconcilers ===
 * State reconcilers define how incoming state is merged in with initial state.
 *
 * autoMergeLevel2 makes sure newly added state object property will be retained
 * after merging with persisted state object. This is the recommended state reconciler to use.
 *
 * Please consult the redux-persist documentation to read up more about the technical details.
 */
const REDUX_PERSIST_STATE_RECONCILER = autoMergeLevel2;

/**
 * === Config Options ===
 * - key
 *   The LocalStorage / SessionStorage key name. On LocalStorage / SessionStorage,
 *   redux-persist's key is "persist:[key name]" (check your browser developer console)
 *   where the key name comes directly after the colon.
 *
 * - storage
 *   Storage adapter that redux-persist provides out-of-the-box
 *
 * - stateReconciler
 *   Function compatible with redux-persist
 */

const systemSessionStoragePersistConfig = {
  key: "system",
  storage: WebSessionStorage,
  stateReconciler: REDUX_PERSIST_STATE_RECONCILER,
};

export { systemSessionStoragePersistConfig };
