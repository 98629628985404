import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import { ConfirmationModalProps } from "./ConfirmationModal.types";

const sxStyles = ({
  position,
  enableBGBlur,
  titleBold,
}: {
  position: ConfirmationModalProps["position"];
  enableBGBlur: ConfirmationModalProps["enableBGBlur"];
  titleBold: ConfirmationModalProps["titleBold"];
}) => {
  return {
    dialogContainer: {
      ...(enableBGBlur ? { backdropFilter: "blur(5px)" } : {}),
      "& .MuiDialog-container": {
        ...(position === "top"
          ? { alignItems: "flex-start" }
          : position === "bottom"
            ? { alignItems: "flex-end" }
            : { alignItems: "center" }),
      },
      "& .MuiDialog-paper": {
        ...(position === "top" && { mt: "100px", mx: 2, width: "100%" }),
      },
    },

    dialog: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      my: 3,
      mx: 2,
    },
    dialogTitle: {
      p: 0,
    },

    dialogTitleContent: {
      m: 0,
      p: 0,
      color: theme.palette.text.primary,
      fontSize: size.FONTSIZE_18,
      marginRight: 3,
      wordBreak: "break-word",
      fontWeight: titleBold
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    },
    dialogContent: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      p: 0,
      mt: 1,
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 2,
      mt: 2,
    },
    checkIcon: {
      color: theme.palette.success.main,
      width: 3,
      marginRight: "0.2rem",
    },
    errorIcon: {
      color: theme.palette.error.dark,
      width: 3,
      marginRight: "0.2rem",
    },
    title: {
      m: 0,
      p: 0,
      color: theme.palette.text.primary,
      fontSize: size.FONTSIZE_18,
      marginRight: 3,
      wordBreak: "break-word",
    },
    buttonLabel: {
      px: 1,
      pt: 1,
      typography: theme.typography.body2_regular,
    },
    additionalButtonLabel: {
      m: 0,
      padding: "0.5rem 0.5rem 0 0.5rem",
    },
    closeButton: {
      position: "absolute",
      right: 1,
      top: 1,
      color: theme.palette.grey[600],
      "& .MuiSvgIcon-root": {
        fontSize: size.FONTSIZE_24,
      },
      float: "right",
    },
  } satisfies SxObjType;
};

export { sxStyles };
