import { Box, Button } from "@mui/material";
import { sxStyles } from "./SeeMoreButton.styles";
import { SeeMoreButtonProps } from "./SeeMoreButton.types";
import IMAGES from "lib/assets/images";

const SeeMoreButton = ({
  buttonText,
  disabled = false,
  onClick,
}: SeeMoreButtonProps) => {
  const classes = sxStyles();
  return (
    <Box sx={classes.moreButton}>
      <Button
        sx={classes.button}
        variant="text"
        color="primary"
        disabled={disabled}
        onClick={onClick}
        endIcon={<Box component={"img"} src={IMAGES.general.ArrowRightIcon} />}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

export default SeeMoreButton;
