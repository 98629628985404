import { Theme } from "@mui/material";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = (theme: Theme) => {
  return {
    category: {
      padding: theme.spacing(3, 2, 2, 2),
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
    categoryText: {
      color: theme.palette.text.primary,
      typography: "header4_semibold",
    },
  } satisfies SxObjType;
};

export { sxStyles };
