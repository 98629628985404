import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests/RequestOptions";
import { GET_USERPROFILE_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { GetUserProfilePayload } from "./getUserProfile.fromApi.types";
import { formatGetUserProfileErrorMessage } from "api/resources/messages/ErrorMessage";

/**
 * Fetches the User Profile for a user
 *
 * POST
 * Endpoint: /profile/GetUserProfile
 */
const getUserProfile = async (
  {
    retrievePhoto,
  }: {
    retrievePhoto: boolean;
  } = { retrievePhoto: false },
): Promise<GetUserProfilePayload> => {
  try {
    const response = await axios.post<GetUserProfilePayload>(
      GET_USERPROFILE_URL(),
      createRequestPayload({ retrievePhoto }),
      getRequestOptions(),
    );
    return parseResponse(response, GET_USERPROFILE_URL());
  } catch (error) {
    const msg = formatGetUserProfileErrorMessage(GET_USERPROFILE_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  {
    retrievePhoto,
  }: {
    retrievePhoto: boolean;
  } = { retrievePhoto: false },
) => {
  return { RetrievePhoto: retrievePhoto };
};

export { getUserProfile };
