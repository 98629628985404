import { Box, Typography } from "@mui/material";
import { PersonalParticularsFormProps } from "./PersonalParticularsForm.types";
import Datepicker from "lib/components/formInputs/Datepicker/Datepicker";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import { sxStyles } from "./PersonalParticularsForm.styles";
import nameof from "ts-nameof.macro";
import { validatePhoneNumber } from "lib/util/ValidatorUtil/phoneNumberValidator/phoneNumberValidator";
import { validateEmail } from "lib/util/ValidatorUtil/emailValidator/emailValidator";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import {
  formatDate,
  formatOptions,
} from "lib/util/DateTimeUtil/formatDate/formatDate";

const PersonalParticularsForm = ({
  personalParticularsFormField,
  handlePersonalParticularsFormFieldChange,
  showDOB = true,
  showRequiredForEmail = false,

  onInputError,
}: PersonalParticularsFormProps) => {
  const classes = sxStyles();

  // Redux states

  const handleDateOfBirthChange = (id: string) => (date: Date) => {
    const dateString = date ? formatDateToISO(date) : "";
    var event = { target: { name: id, value: dateString } };
    handlePersonalParticularsFormFieldChange(event);
  };

  return (
    <Box>
      {/* <FormControl component="fieldset" fullWidth> */}
      <Typography sx={classes.personalParticularTitle}>
        Personal Particulars
      </Typography>
      <Box sx={classes.formSameLine}>
        <SingleLineTextField
          type="text"
          name={nameof(personalParticularsFormField.name)}
          value={personalParticularsFormField.name}
          placeholder="Name of Patient"
          required={true}
          helperText={"Name as per NRIC"}
          handleChange={handlePersonalParticularsFormFieldChange}
        />
      </Box>
      <Box sx={classes.formSameLine}>
        <SingleLineTextField
          type="number"
          maxCharLength={8}
          name={nameof(personalParticularsFormField.contact)}
          value={personalParticularsFormField.contact}
          error={
            personalParticularsFormField.contact
              ? !validatePhoneNumber(personalParticularsFormField.contact)
              : false
          }
          errorText="Enter a valid phone number"
          placeholder="Contact"
          handleChange={handlePersonalParticularsFormFieldChange}
        />
      </Box>
      {showDOB && (
        <Box sx={classes.formSameLine}>
          <Datepicker
            label="Date of Birth"
            isKeyboardDatePicker={true}
            format={"dd/MM/yyyy"}
            placeholder={"DD/MM/YYYY"}
            value={personalParticularsFormField.dateOfBirth || null}
            disableFuture={true}
            onSelect={handleDateOfBirthChange(
              nameof(personalParticularsFormField.dateOfBirth),
            )}
            maxDate={formatDate(
              formatDateToISO(new Date()),
              formatOptions.dayMonthYear,
            )}
            onInputError={onInputError}
          />
        </Box>
      )}
      <Box sx={{ ...classes.formSameLine, mt: 2 }}>
        <SingleLineTextField
          type="email"
          required={showRequiredForEmail}
          name={nameof(personalParticularsFormField.email)}
          value={personalParticularsFormField.email}
          placeholder="Email"
          error={!validateEmail(personalParticularsFormField.email, true)}
          errorText="Enter a valid email"
          handleChange={handlePersonalParticularsFormFieldChange}
        />
      </Box>
      {/* <br /> */}
      {/* </FormControl> */}
    </Box>
  );
};

export default PersonalParticularsForm;
