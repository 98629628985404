import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { PATHS } from "lib/routing";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import { StepPageButtonsFooterProps } from "./StepPageButtonsFooter.types";
import { sxStyles } from "./StepPageButtonsFooter.styles";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import CancelRequestConfirmationModal from "../confirmationModals/CancelRequestConfirmationModal/CancelRequestConfirmationModal";
import { useOnBackTriggered } from "lib/routing/messageChannel/hooks/useOnBackTriggered";

// ============================
// Google Analytics Logging Event
// ============================

const logCancelRequestEventToGoogleAnalytics = (
  pathToNextStep: string | undefined,
) => {
  switch (pathToNextStep) {
    // REFILL
    case PATHS.MED_REFILL_QUANTITY.path:
      logEventToGoogleAnalytics(EVENTS.CANCEL_REQUEST_AT_REFILL_PARTICULARS);
      break;
    case PATHS.MED_REFILL_COLLECTION.path:
      logEventToGoogleAnalytics(EVENTS.CANCEL_REQUEST_AT_REFILL_QUANTITY);
      break;
    case PATHS.MED_REFILL_PAYMENT.path:
      logEventToGoogleAnalytics(EVENTS.CANCEL_REQUEST_AT_TOPUP_COLLECTION);
      break;
    case PATHS.MED_REFILL_ORDER_SUMMARY.path:
      logEventToGoogleAnalytics(EVENTS.CANCEL_REQUEST_AT_TOPUP_PAYMENT);
      break;
    // TOPUP
    case PATHS.MED_TOPUP_PRESCRIPTION.path:
      logEventToGoogleAnalytics(EVENTS.CANCEL_REQUEST_AT_TOPUP_PARTICULARS);
      break;
    case PATHS.MED_TOPUP_COLLECTION.path:
      logEventToGoogleAnalytics(EVENTS.CANCEL_REQUEST_AT_TOPUP_PRESCRIPTION);
      break;
    case PATHS.MED_TOPUP_PAYMENT.path:
      logEventToGoogleAnalytics(EVENTS.CANCEL_REQUEST_AT_TOPUP_COLLECTION);
      break;
    case PATHS.MED_TOPUP_ORDER_SUMMARY.path:
      logEventToGoogleAnalytics(EVENTS.CANCEL_REQUEST_AT_TOPUP_PAYMENT);
      break;
    default:
      break;
  }
};

const logConfirmCancelRequestEventToGoogleAnalytics = (
  pathToNextStep: string | undefined,
) => {
  switch (pathToNextStep) {
    // REFILL
    case PATHS.MED_REFILL_QUANTITY.path:
      logEventToGoogleAnalytics(
        EVENTS.CONFIRM_CANCEL_REQUEST_AT_REFILL_PARTICULARS,
      );
      break;
    case PATHS.MED_REFILL_COLLECTION.path:
      logEventToGoogleAnalytics(
        EVENTS.CONFIRM_CANCEL_REQUEST_AT_REFILL_QUANTITY,
      );
      break;
    case PATHS.MED_REFILL_PAYMENT.path:
      logEventToGoogleAnalytics(
        EVENTS.CONFIRM_CANCEL_REQUEST_AT_TOPUP_COLLECTION,
      );
      break;
    case PATHS.MED_REFILL_ORDER_SUMMARY.path:
      logEventToGoogleAnalytics(EVENTS.CONFIRM_CANCEL_REQUEST_AT_TOPUP_PAYMENT);
      break;
    // TOPUP
    case PATHS.MED_TOPUP_PRESCRIPTION.path:
      logEventToGoogleAnalytics(
        EVENTS.CONFIRM_CANCEL_REQUEST_AT_TOPUP_PARTICULARS,
      );
      break;
    case PATHS.MED_TOPUP_COLLECTION.path:
      logEventToGoogleAnalytics(
        EVENTS.CONFIRM_CANCEL_REQUEST_AT_TOPUP_PRESCRIPTION,
      );
      break;
    case PATHS.MED_TOPUP_PAYMENT.path:
      logEventToGoogleAnalytics(
        EVENTS.CONFIRM_CANCEL_REQUEST_AT_TOPUP_COLLECTION,
      );
      break;
    case PATHS.MED_TOPUP_ORDER_SUMMARY.path:
      logEventToGoogleAnalytics(EVENTS.CONFIRM_CANCEL_REQUEST_AT_TOPUP_PAYMENT);
      break;
    // VIRTUALCONSULT
    case PATHS.MED_VIRTUALCONSULT_ORDER_MEDICATION_PARTICULARS_MOBILE.path:
      logEventToGoogleAnalytics(
        EVENTS.CONFIRM_CANCEL_REQUEST_AT_VIRTUALCONSULT_PARTICULARS,
      );
      break;
    default:
      break;
  }
};

// ============================
// COMPONENT
// ============================

const StepPageButtonsFooter = ({
  type = "NEXT_STEP",
  isNextButtonDisabled,
  pathToNextStep,
  pathToCancelRequest = PATHS.MEDICATIONS_MOBILE.path,
  onNextStep,
}: StepPageButtonsFooterProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();
  const location = useLocation();
  const path = location.pathname;

  // Cancel Request Warning Dialog open & close state and handlers
  const [open, setOpen] = useState(false);

  const handleOpenWarningModal = () => {
    logCancelRequestEventToGoogleAnalytics(pathToNextStep);
    setOpen(true);
  };

  const handleCloseWarningModal = () => {
    setOpen(false);
  };

  useOnBackTriggered(() => {
    if (
      path === PATHS.MED_REFILL_PARTICULARS.path ||
      path === PATHS.MED_TOPUP_PARTICULARS.path ||
      path === PATHS.MED_VIRTUALCONSULT_ORDER_MEDICATION_PARTICULARS_MOBILE.path
    ) {
      handleOpenWarningModal();
    }
  });

  return (
    <Box sx={{ mt: 2, mb: 3 }}>
      <Box sx={classes.buttonsFooter}>
        {type === "NEXT_STEP" ? (
          // FOR NORMAL, STEP PAGES
          <ButtonsFooter
            isDisabled={isNextButtonDisabled}
            nextButtonText="Next step"
            cancelButtonText="Cancel request"
            onClickNext={() => {
              onNextStep();
              if (pathToNextStep) {
                navigate(pathToNextStep);
              }
            }}
            onClickCancel={handleOpenWarningModal}
          />
        ) : (
          // FOR EDITABLE PAGES
          <ButtonsFooter
            isDisabled={isNextButtonDisabled}
            nextButtonText="Save"
            cancelButtonText="Cancel"
            onClickNext={() => {
              onNextStep();
              navigate(-1);
            }}
            onClickCancel={() => {
              navigate(-1);
            }}
          />
        )}
      </Box>
      <CancelRequestConfirmationModal
        preventDirectCancel={open}
        onClickConfirm={() =>
          logConfirmCancelRequestEventToGoogleAnalytics(pathToNextStep)
        }
        onClickCancel={handleCloseWarningModal}
      />
    </Box>
  );
};

export default StepPageButtonsFooter;
