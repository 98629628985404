import { MedicationCardProps } from "./MedicationCard.types";
import { Box, Typography, IconButton } from "@mui/material";
import { sxStyles } from "./MedicationCard.styles";
import IMAGES from "lib/assets/images";

const MedicationCard = ({
  index,
  medicationName,
  number,
  uom,
  removeQuantityDetail,
}: MedicationCardProps) => {
  const classes = sxStyles();

  return (
    <Box
      sx={classes.cardBackground}
      display="flex"
      position="relative"
      flexDirection="column"
      mb={1}
    >
      <Box position="absolute" top={0} right={0}>
        <IconButton
          aria-label="delete"
          sx={classes.deleteIconButton}
          onClick={() => removeQuantityDetail(index, medicationName)}
        >
          <img src={IMAGES.general.DeleteIcon} alt="Delete" />
        </IconButton>
      </Box>
      <Box mb={2} mr={4}>
        <Typography sx={classes.label}>Medication Name</Typography>
        <Typography sx={classes.text}>{medicationName}</Typography>
      </Box>
      <Box>
        <Typography sx={classes.label}>Quantity</Typography>
        <Typography sx={classes.text}>{number + " " + uom}</Typography>
      </Box>
    </Box>
  );
};

export default MedicationCard;
