export const convertBase64ToPdfAndOpenInMobile = (
  fileName: string,
  base64: string,
) => {
  try {
    //convert base64 string into byte array
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    const arrayBuffer = bytes.map((byte, i) => binaryString.charCodeAt(i));

    convertByteArrayToPdfAndOpenInMobile(fileName, arrayBuffer);
  } catch (error) {}
};

export const convertByteArrayToPdfAndOpenInMobile = (
  fileName: string,
  fileByte: Uint8Array,
) => {
  try {
    //save byte array
    var blob = new Blob([fileByte], { type: "application/pdf" });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  } catch (error) {}
};
