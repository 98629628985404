import { RootState, RootDispatch } from "lib/redux/root/redux.types";
import {
  fetchAppointments,
  updateSlotDateTimeList,
  updateSelectedLinkedAppointment,
  updateRescheduleLinkedAppointmentVisitedSlotsPage,
} from "ui/appointment/ducks";
import { connect } from "react-redux";
import SelectLinkedAppts from "./SelectLinkedAppts";
import { Appointment } from "api/appointment/GetAppointmentList/getAppointmentList.toUi.types";
import { handleErrorModal } from "lib/redux/notifications";
import { resolveBeforeRescConfirmMsgDocomentType } from "api/shared/GetDocumentByType/getDocumentByType.mapper";
import { fetchDocumentForAllTypes } from "ui/appointment/ducks/thunks";
import { setCustomisedBack } from "lib/redux/navigation";

// ==========================
// MAP STATE TO PROPS
// ==========================

const mapStateToProps = (state: RootState) => {
  const appointmentId = state.appointments.appointmentMeta.appointmentId;
  const appointment = state.appointments.init.lookup[appointmentId || 0];
  const institutionCode = appointment?.institutionCode;
  let preConsultMsgKey = "";
  if (institutionCode) {
    preConsultMsgKey = resolveBeforeRescConfirmMsgDocomentType(institutionCode);
  }

  return {
    memberIdentifier: state.user.memberIdentifier,
    isLoadingAppointments: state.appointments.init.isLoading,
    hasErroredAppointments: state.appointments.init.hasErrored,
    errorMessageAppointments: state.appointments.init.errorMessage,
    visitedSlotsPage:
      state.appointments.rescheduleLinkedAppointment.visitedSlotsPage,
    appointment,
    newSlotDateTimeList:
      state.appointments.rescheduleLinkedAppointment.newSlotList,
    isLoadingPreConsultMsg: state.appointments.documentByType.isLoading,
    preConsultMsg:
      state.appointments.documentByType.documentByTypeMap[preConsultMsgKey],
    preventDirectBackNavigationState: state.navigation.customisedBack,
  };
};

// ==========================
// MAP DISPATCH TO PROPS
// ==========================

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    fetchAppointments: () => {
      // Make API call to fetch appointment lists for this page
      dispatch(fetchAppointments());
    },
    fetchPreConsultMsg: () => {
      dispatch(fetchDocumentForAllTypes());
    },
    initializeNewSlotDateTimeList: (length: number) => {
      const newSlotDateTimeList: null[] = Array(length).fill(null);
      dispatch(updateSlotDateTimeList(newSlotDateTimeList));
    },
    selectAppointment: (appointment: Appointment, index: number) => {
      dispatch(updateSelectedLinkedAppointment({ appointment, index }));
    },
    clearVisitedSlotsPageFlag: () => {
      dispatch(updateRescheduleLinkedAppointmentVisitedSlotsPage(false));
    },
    handleErrorModal: (
      open: boolean,
      message: string | null,
      buttonText?: string | null,
    ) => {
      dispatch(handleErrorModal({ open, message, buttonText }));
    },
    resetPreventDirectBackNavigationState: () => {
      dispatch(setCustomisedBack(false));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectLinkedAppts);
