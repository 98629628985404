import axios from "axios";
import { VALIDATE_PATIENT_MRN_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatValidatePatientMrnErrorMessage } from "api/resources/messages/ErrorMessage";
import { ValidatePatientMrnResponsePayload } from "./validatePatientMrn.fromApi.types";

/**
 * Validates patient MRN with respective institutions by calling source systems
 *
 * POST
 * Endpoint: secure/MRRP/ValidatePatientMrn
 *
 * @param {string} hospitalCode Hospital code to retrieve values for, returns error response if not provided, ADMC | CGH | IMH | KKH | KTPH | NUH | SGH | SKH | TTSH
 * @param {string | null} memberIdentifier Encripted NRIC retrieved from logged in user
 */
const validatePatientMrn = async (
  hospitalCode: string,
  memberIdentifier: string | null,
  oldMemberIdentifier: string | null,
  oldTokenizedId: string | null,
): Promise<ValidatePatientMrnResponsePayload> => {
  try {
    const response = await axios.post<ValidatePatientMrnResponsePayload>(
      VALIDATE_PATIENT_MRN_URL(),
      createRequestPayload(
        hospitalCode,
        memberIdentifier,
        oldMemberIdentifier,
        oldTokenizedId,
      ),
      getRequestOptions(),
    );
    return parseResponse(response, VALIDATE_PATIENT_MRN_URL());
  } catch (error) {
    const msg = formatValidatePatientMrnErrorMessage(
      VALIDATE_PATIENT_MRN_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  hospitalCode: string,
  memberIdentifier: string | null,
  oldMemberIdentifier: string | null,
  oldTokenizedId: string | null,
) => {
  return {
    HospitalCode: hospitalCode,
    MemberIdentifier: memberIdentifier,
    OldMemberIdentifier: oldMemberIdentifier,
    OldTokenizedId: oldTokenizedId,
  };
};

export { validatePatientMrn };
