import axios from "axios";
import { GET_ALL_BY_PLATFORM_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatGetAllByPlatformErrorMessage } from "api/resources/messages/ErrorMessage";
import {
  GetAllByPlatformResponsePayload,
  ViewAllByPlatform,
  ViewFormLabel,
} from "./getAllByPlatform.fromApi.types";

/**
 * Get lables (what we need from this method) and various other configurations
 *
 * GET
 * Endpoint: secure/data/GetAllByPlatform
 *
 */
const getAllByPlatform = async (): Promise<GetAllByPlatformResponsePayload> => {
  try {
    const response = await axios.get<GetAllByPlatformResponsePayload>(
      GET_ALL_BY_PLATFORM_URL(),
      getRequestOptions(),
    );
    return parseResponse(response, GET_ALL_BY_PLATFORM_URL());
  } catch (error) {
    const msg = formatGetAllByPlatformErrorMessage(GET_ALL_BY_PLATFORM_URL());
    console.error(msg);
    throw error;
  }
};

/**
 * Helper function to map API response to ViewFormLabel format
 * Non-specified fields will undefined by default
 *
 * @param response response from the API when it's not undefined or null
 */
export const mapAllByPlatformToFormLabel = (
  response: GetAllByPlatformResponsePayload,
): ViewFormLabel[] => {
  const formLabelByPlatform: ViewAllByPlatform | undefined =
    response.ViewAllByPlatform.find(
      (element) => element.Key === "PersonalHealth-MRRP-Institution-Config",
    );

  if (formLabelByPlatform === undefined) {
    return [];
  } else {
    const JSONObj = JSON.parse(formLabelByPlatform.Value);
    return JSONObj.map((entry: any) => {
      return {
        institutions: entry.Institutions ?? [],
        requestorName: entry.RequestorName,
        contactNo: entry.ContactNo,
        visitDate: entry.VisitDate,
        reportType: entry.ReportType,
        reportDescription: entry.Description,
        relationship: entry.Relationship,
      };
    });
  }
};

export { getAllByPlatform };
