import { Box, TextField } from "@mui/material";
import { useAppSelector } from "lib/redux/hooks";
import { selectSystem } from "lib/redux/system/selectors";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { sxStyles } from "./SingleLineTextField.styles";
import { SingleLineTextFieldProps } from "./SingleLineTextField.types";
import { SYSTEM_DEVICE_PLATFORM_IOS } from "lib/mobileIntegration/constants";

const SingleLineTextField = ({
  type = "text",
  required = true,
  name,
  value,
  maxCharLength,
  placeholder,
  error,
  disabled = false,
  errorText,
  helperText,
  autoFocus = false,
  decimalPlaces,
  isFromMyInfoAndProfile = false,

  handleChange,
  handleKeyDown,
  handleBlur,
}: SingleLineTextFieldProps) => {
  const classes = sxStyles(error);

  const { devicePlatform } = useAppSelector(selectSystem);
  const [inputValue, setInputValue] = useState(value ?? "");

  const constructMessageDisplay = () => {
    if (disabled) {
      return "";
    } else if (error && errorText) {
      if (isFromMyInfoAndProfile) {
        return `${errorText}`;
      }
      return `${errorText}.`;
    } else {
      let text = "";
      if (required && !disabled) {
        text = `*Required`;
        if (helperText) {
          text = `*Required (${helperText})`;
        }
      }
      return text;
    }
  };

  const handleOnChange = (
    id: string,
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const element = event.target;
    element.name = id;

    // if maxCharLength is undefined, slice won't trim the string
    // iOS (tested on iPhone 6 Plus and iPhone SE) allows non-digit to enter even if type props is restricted to number,
    // and the UI styles will be ruined when any non-digit is entered, so we additionally control the input to filter non-digit
    if (type === "number") {
      element.value = element.value.slice(0, maxCharLength);
    } else if (type === "decimal") {
      const splitArr = element.value.split(".");
      const intPart = Number(splitArr[0]).toString().slice(0, maxCharLength);
      if (splitArr[1]) {
        const decPart = "." + splitArr[1].slice(0, decimalPlaces);
        element.value = intPart + decPart;
      }
    } else {
      element.value = element.value.slice(0, maxCharLength);
    }

    if (handleChange) {
      handleChange(event);
    } else {
      setInputValue(element.value);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // if maxCharLength is undefined, slice won't trim the string
    // iOS (tested on iPhone 6 Plus and iPhone SE) allows non-digit to enter even if type props is restricted to number,
    // and the UI styles will be ruined when any non-digit is entered, so we additionally control the input to filter non-digit
    if (type === "number" || type === "decimal") {
      const digitRegExp = new RegExp(/\d/g);

      if (
        digitRegExp.test(event.key) ||
        event.key.toLocaleLowerCase() === "backspace" ||
        event.key === "."
      ) {
        if (type === "number") {
          if (["e", "E", "+", "-", "."].includes(event.key)) {
            event.preventDefault();
          }
        }
        if (type === "decimal") {
          if (["e", "E", "+", "-"].includes(event.key)) {
            event.preventDefault();
          }
        }
      } else {
        event.preventDefault();
      }
    }

    if (handleKeyDown) {
      handleKeyDown(event);
    }
  };

  const handleCutCopyPaste = (event: FormEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleError = (error?: boolean) => {
    if (!inputValue && required) {
      return false;
    } else {
      return error;
    }
  };

  useEffect(() => {
    setInputValue(value ?? "");
  }, [value]);

  return (
    <Box>
      <TextField
        name={name || ""}
        type={
          devicePlatform === SYSTEM_DEVICE_PLATFORM_IOS
            ? type === "decimal" || type === "number" // safari has bug where input number allows any character to input but doesn't return value when invalid
              ? "text" // changing to input text allows us to catch the value and internally validate it
              : type
            : type === "decimal"
              ? "number"
              : type
        }
        InputLabelProps={{
          sx: classes.textFieldLabel,
        }}
        sx={disabled ? classes.textfieldDisabled : classes.textfieldError}
        inputProps={{
          maxLength: type === "decimal" ? undefined : maxCharLength,
          spellCheck: false,
        }}
        InputProps={{ notched: false }}
        inputMode={
          type === "decimal"
            ? "decimal"
            : type === "number"
              ? "numeric"
              : "text"
        }
        fullWidth
        //placeholder={placeholder}
        disabled={disabled}
        //name={name}
        error={handleError(error)}
        value={inputValue}
        onChange={(event) => {
          handleOnChange(name || "", event);
        }}
        onKeyDown={(event) => {
          handleOnKeyDown(event);
        }}
        onBlur={(event) => {
          if (handleBlur) handleBlur(event);
        }}
        onCut={handleCutCopyPaste}
        onCopy={handleCutCopyPaste}
        onPaste={handleCutCopyPaste}
        variant="outlined"
        label={placeholder}
        autoFocus={autoFocus}
      />
      {constructMessageDisplay() && (
        <Box sx={classes.errText}>{constructMessageDisplay()}</Box>
      )}
    </Box>
  );
};

export default SingleLineTextField;
