import { Theme } from "@mui/material";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = (theme: Theme) => {
  return {
    mainContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    topContainer: {},
    bannerContainer: { mb: 3.5 },
    bodyContainer: {
      mx: 2,
    },
    bodyTitle: {
      typography: "header4_semibold",
      color: theme.palette.primary.dark,
      mb: 1.5,
    },
    footerContainer: { my: 4, mx: 2 },
  } satisfies SxObjType;
};

export { sxStyles };
