import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    card: {
      color: theme.palette.grey[600],
      backgroundColor: theme.palette.secondary.light,
      marginBottom: theme.spacing(0.25),
      borderRadius: theme.spacing(0.125),
    },
    cardHeader: {
      padding: theme.spacing(1.5, 0.5, 0, 0.5),
      marginRight: theme.spacing(0),
      textAlign: "center",
      alignItems: "start",
      display: "block",
      "& .MuiCardHeader-action": {
        typography: theme.typography.caption1_regular,
        padding: theme.spacing(1.5, 0),
        alignItems: "start",
        width: "100%",
      },
      "& .MuiCardHeader-title": {
        typography: theme.typography.caption1_regular,
        lineHeight: "18px",
        textTransform: "uppercase",
        margin: theme.spacing(0.5, 0),
      },
      "& .MuiCardHeader-subheader": {
        color: theme.palette.grey[600],
        typography: theme.typography.header4_bold,
        lineHeight: 1,
        textTransform: "uppercase",
        margin: theme.spacing(0),
      },
    },

    cardDetailWrapper: {
      width: "100%",
      padding: theme.spacing(1.5, 1, 1.5, 0),
      "&:last-child": {
        paddingBottom: theme.spacing(1.5), // deactivate default padding
      },
    },
    cardDetails: {
      borderLeft: `1px solid ${theme.palette.grey[100]}`,
      padding: theme.spacing(0, 0, 0, 1),
      minHeight: 65,
      height: "100%",
    },
    cardTitle: {
      typography: theme.typography.body2_semibold,
    },
    cardContent: {
      typography: theme.typography.body2_regular,
      marginBottom: theme.spacing(0),
    },
  } satisfies SxObjType;
};

export { sxStyles };
