import axios from "axios";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { ACTIONS } from "lib/routing";
import { OidcClient } from "@axa-fr/react-oidc";
import { clearAllUserSession } from "lib/util/storage/clearAllUserSession";

/**
 * Intercepts the response of Axios API calls to handle 401 Unauthorized exceptions
 * from the remote server, and re-directs the user to the mobile client for handling.
 * According to the Axios documentation, the interception happens to this response
 * before they are handled by then or catch.
 *
 * For all API calls, if the token in the session has expired or is invalid, the
 * server returns a 401 Unauthorized API Gateway error response. This error can then
 * be used to pinpoint when a user must re-login again on the mobile client to proceed.
 */
const initializeUnauthorizedHttpResponseInterceptor = () => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const oidc = OidcClient.get();
      const isUnauthorised =
        (error.response != null &&
          error.response !== undefined &&
          error.response.status === 401) ||
        (error.response != null &&
          error.response !== undefined &&
          error.response.status === 403) ||
        error.message === "Network Error";

      if (isUnauthorised && oidc.tokens) {
        await oidc.logoutAsync();
        clearAllUserSession();
        mobileNavigate(ACTIONS.MOBILE_UNAUTHORISED_PATH);
        return false;
      }
      return Promise.reject(error);
    },
  );
};

export { initializeUnauthorizedHttpResponseInterceptor };
