import { connect } from "react-redux";
import BillDetail from "./BillDetail";
import { RootDispatch, RootState } from "lib/redux/root/redux.types";
import { setBillDetails, setBillsToPay } from "ui/payment/ducks";
import { fetchBillDetails } from "ui/payment/ducks";
import { PaymentBillsToPayState } from "ui/payment/ducks/payment.types";
import { PAYMENT_BILL_PENDING_TEXT_KEY } from "api/shared/GetDocumentByType/getDocumentByType.mapper";
import { fetchDocumentForAllTypes } from "ui/payment/ducks/thunks";

// helper function to get amount to pay from state if have saved input
const getAmountToPayFromState = (
  billsToPay: PaymentBillsToPayState[],
): number | null => {
  if (billsToPay.length === 0) {
    // don't have saved input on bills to pay yet in this flow
    return null;
  } else {
    // only one bill will be present in this flow if billsToPay has element
    const rawAmountToPay = billsToPay[0].AmountToPay;
    return Number(rawAmountToPay);
  }
};

const mapStateToProps = (state: RootState) => {
  const billInvoiceNumber =
    state.payments.outstandingBills.selectedOutstandingBillsIndex;
  const allSystemBills = state.payments.outstandingBills.allOutstandingBills;
  const selectedSystemBill =
    allSystemBills.filter((bill) => {
      return bill.InvoiceNumber === billInvoiceNumber;
    })[0] || null;

  return {
    memberIdentifier: state.user.memberIdentifier,
    billDetailsState: state.payments.billDetails,
    billInvoiceNumber: billInvoiceNumber,
    systemBill: selectedSystemBill,
    isLoadingPendingMsg: state.payments.documentByType.isLoading,
    pendingMsg:
      state.payments.documentByType.documentByTypeMap[
        PAYMENT_BILL_PENDING_TEXT_KEY
      ],
    amountToPayStateValue: getAmountToPayFromState(state.payments.billsToPay),
  };
};

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    clearPastReports: () => {
      dispatch(setBillDetails(null));
    },
    callGetBillSummaryDetails: (
      billReferenceNumber: string | null,
      institutionCode: string | null,
    ) => {
      dispatch(fetchBillDetails(billReferenceNumber, institutionCode));
    },
    setBillToPay: (payBill: PaymentBillsToPayState) => {
      dispatch(setBillsToPay([payBill]));
    },
    fetchPendingMsg: () => {
      dispatch(fetchDocumentForAllTypes());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillDetail);
