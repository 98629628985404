import {
  ENV_LOC,
  ENV_DEV,
  ENV_DEVSIT,
  ENV_DEVUAT,
  ENV_SIT,
  ENV_UAT,
  appEnv,
} from "lib/configs/configs/config";

const noop = () => {};

const isLogAllowed = (env: string) => {
  return (
    env === ENV_LOC ||
    env === ENV_DEV ||
    env === ENV_SIT ||
    env === ENV_UAT ||
    env === ENV_DEVSIT ||
    env === ENV_DEVUAT
  );
};

const disableLogs = () => {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
};

/**
 * Enables / Disables Console Logging, depending on the environment.
 *
 * Important for obfuscating app behaviour in non-test environments.
 */
export const enableConsoleLoggingForAllowedEnvironments = () => {
  try {
    const env = appEnv();

    if (!isLogAllowed(env)) {
      disableLogs();
    }
  } catch (error) {
    // for safety, always disable logs by default, even during errors so that they do not
    // accidentally get turned on
    disableLogs();
  }
};
