/**
 * == PAGE TITLES ==
 *
 * This file contains all of the page titles for the entire application.
 * These titles are typically shown in the title area of the navigation bar.
 */

const BLANK = "blank"; //when set to "blank", the title will be hidden on mobile c1
export const ROOT = "";
export const LOGIN = "SINGPASS LOGIN";

// === UI Sandbox ===
export const UI_SANDBOX = "-= UI Sandbox =-";
export const LOGOUT = "-= Log Out =-";

// === UI Sandbox ===
export const ADMIN = "-= Admin =-";

// === Profile ===
export const PROFILE = {
  MAIN: "My Particulars",
  MYINFO_VERIFY: "Verify Your Particulars",
  PM_ENTRY: BLANK,
  PREFERRED_MAILING: "Preferred Local Mailing Address",
};

// === Appointments Module ===
export const APPOINTMENTS = {
  MAIN: "Appointment",
  APPOINTMENT_REQUEST: "Appointment Request",
};

// === MedRefill Module ===
export const MEDREFILL = {
  MAIN: "Medication",
  TOPUP: "Repeat Prescription",
  REFILL: "Medication Refill",
  ORDER_MEDICATION: "Order Medication",
  REQUEST: "Medication Requests",
};

// === Payment Module ===
export const PAYMENT = {
  MAIN: "Payment",
  OUTSTANDING_BILL_DETAILS: "Outstanding Bill Details",
  ADDED_BILL_DETAILS: "Added Bill Details",
  RECEIPT: "Receipt",
  PAID_BILLS: "Fully-Paid Bills",
  TRANSACTION_HISTORY: "App Payment Status",
  OTHER_ENQUIRIES: "Other Enquiries",
};

// === My Health Map Module(MHM) ===
export const MHM = {
  MAIN: "My Health Map",
  VERIFY_PARTICULARS: "Verify Particulars",
};

// === Health Together Module(HT) ===
export const HT = {
  HEALTH_TOGETHER: "Health Together",
  HEALTH_LIBRARY: "Health Library",
  SELF_SERVE_RESOURCES: "Self-Serve Resources",
  SELF_HELP_TOOLS: "Self-Help Tools",
  EVENTS: "Events",
  COMMUNITY_CARE_PROGRAMMES: "Community Care Programmes",
};

//  === MRRP ===
export const MRRP = {
  MOBILE: "Requested Reports",
  PAST_REPORT_DETAIL: "Report Details",
  NEW_REQUEST_SELECT_HOSPITAL: "New Request",
  NEW_REQUEST_VERIFY_PARTICULARS: "Verify Particulars",
  NEW_REQUEST_REPORT_DETAILS: "Requested Report Details",
  NEW_REQUEST_COLLECTION: "Collection/Delivery",
  NEW_REQUEST_SUMMARY: "Report Summary",
  NEW_REQUEST_PAYMENT: "Payments",
  NEW_REQUEST_RECEIPT: "Medical Report Receipt",
};
