import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    errorTitleWithIcon: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_semibold,
      wordBreak: "break-word",
      margin: theme.spacing(0, 0, 0, 0.25),
      alignItems: "center",
      display: "flex",
      lineHeight: "1.2",
    },
    errorText: {
      color: theme.palette.grey[400],
      typography: theme.typography.header4_regular,
      margin: theme.spacing(2, 0),
      "& a": {
        color: theme.palette.primary.main,
        textDecoration: "none",
      },
      overflowWrap: "break-word",
    },
    noRecordImage: {
      margin: theme.spacing(1, 0, 2),
    },
    errorIcon: {
      color: theme.palette.error.dark,
      width: theme.spacing(3),
    },
    pageError: {
      padding: theme.spacing(3, 2),
      textAlign: "center",
    },
  } satisfies SxObjType;
};

export { sxStyles };
