import { sxStyles } from "./VCPLink.styles";
import { VCPLinkProps } from "./VCPLink.types";
import { Box } from "@mui/material";

const VCPLink = ({
  iconSrc,
  iconAlt,
  linkDisplayText,
  handleClick,
}: VCPLinkProps) => {
  const classes = sxStyles();

  return (
    <Box sx={classes.cardLink} onClick={handleClick}>
      <Box
        component="img"
        src={iconSrc}
        alt={iconAlt}
        sx={classes.cardLinkIcon}
      />
      {linkDisplayText}
    </Box>
  );
};

export default VCPLink;
