import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    cardDetailWrapper: {
      width: "100%",
      padding: theme.spacing(2, 1, 2, 0),
      "&:last-child": {
        paddingBottom: theme.spacing(1.5), // deactivate default padding
      },
    },
    cardDetails: {
      borderLeft: `2px solid ${theme.palette.error.main}`,
      padding: theme.spacing(0, 0, 0, 1.25),
      minHeight: 65,
      height: "100%",
      wordBreak: "break-word",
    },
    cardTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.body1_semibold,
      paddingRight: theme.spacing(6),
    },
    cardTime: {
      color: theme.palette.warning.main,
      typography: theme.typography.body2_semibold,
      my: 1,
    },

    cardSubtitle: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_bold,
      textTransform: "capitalize",
    },
    cardContent: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      marginBottom: theme.spacing(0),
    },
  } satisfies SxObjType;
};

export { sxStyles };
