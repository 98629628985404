import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[600],
      "& .MuiSvgIcon-root": {
        fontSize: size.FONTSIZE_24,
      },
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      marginLeft: theme.spacing(0.5),
    },
    dropdownBox: {
      margin: theme.spacing(2, 0, 2),
      "& .MuiInputBase-root": {
        borderRadius: theme.spacing(0.5),
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.grey[100]}`,
        },
      },
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
      "& .MuiInputLabel-animated": {
        fontSize: size.FONTSIZE_16,
        color: theme.palette.primary.main,
      },
    },
    formLabel: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
    },
    textBox: {
      my: 2.5,
    },
    errorText: {
      color: theme.palette.error.main,
      typography: theme.typography.body2_regular,
      margin: theme.spacing(2, 0),
    },
    imgTitle: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      "& div": {
        textAlign: "center",
      },
      "& img": {
        width: "100%",
      },
    },
    buttonFooter: {
      display: "block",
      padding: theme.spacing(1, 0),
    },
    cardButtons: {
      margin: theme.spacing(1, 0),

      "& :hover": {
        boxShadow: "none",
      },
    },
    fullButton: {
      display: "block",
      width: "100%",
    },
    cancelButtons: {
      margin: theme.spacing(1.5, 0, 1),
      marginLeft: "0 !important",
      "& :hover": {
        backgroundColor: theme.palette.common.white,
      },
    },
    cancelButton: {
      marginBottom: theme.spacing(1),
    },
  } satisfies SxObjType;
};

export { sxStyles };
