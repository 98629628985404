import { useEffect, useState } from "react";
import { PATHS } from "lib/routing";
import StepPageLayoutContainer from "ui/medrefill/components/common/StepPageLayout/StepPageLayoutContainer";
import Payment from "../../../common/payment/Payment";
import { RefillPaymentPageProps } from "./RefillPaymentPage.types";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "lib/redux/hooks";
import {
  selectIsMultiplePayment,
  selectPayment,
} from "ui/medrefill/ducks/selectors";
import StepPageButtonsFooter from "ui/medrefill/components/common/StepPageButtonsFooter/StepPageButtonsFooter";
import { Box } from "@mui/material";

const RefillPaymentPage = ({
  isEditablePage = false,
  subsidy,
  onSelectSubsidy,
  paymentList,
  paymentInfoText,
  preventDirectBackNavigationState,
  resetPreventDirectBackNavigationState,
}: RefillPaymentPageProps) => {
  const navigate = useNavigate();

  const isMultiplePayment = useAppSelector(selectIsMultiplePayment);
  const { paymentInstructions, subsidyDescription } =
    useAppSelector(selectPayment);

  const [selectedSubsidy, setSelectedSubsidy] = useState<string | null>(
    subsidy,
  );

  const [localPaymentInstructions, setLocalPaymentInstructions] = useState<
    string | null
  >(paymentInstructions);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<Array<string>>(
    subsidy === "" || subsidy === null ? [] : subsidy.split("|"),
  );

  //this is needed for OrderSummary
  const [selectedSubsidyDescription, setSelectedSubsidyDescription] = useState<
    Array<string>
  >(
    subsidyDescription
      ? subsidyDescription.filter((element) => element !== "")
      : [],
  );

  //only occur for multiple option payments
  const handleCheckboxChange = (id: string, value: string) => {
    if (selectedCheckboxes.indexOf(id) === -1) {
      setSelectedCheckboxes([...selectedCheckboxes, id]);
      setSelectedSubsidyDescription([...selectedSubsidyDescription, value]);
    } else {
      setSelectedCheckboxes(selectedCheckboxes.filter((e) => e !== id));
      setSelectedSubsidyDescription(
        selectedSubsidyDescription.filter((e) => e !== value),
      );
    }
  };

  const handlePaymentInstructionsChange = (value: string) => {
    setLocalPaymentInstructions(value);
  };

  useEffect(() => {
    if (isMultiplePayment) {
      setSelectedSubsidy(selectedCheckboxes.join("|"));
    } else {
      setSelectedSubsidyDescription([
        paymentList.find((e) => e.PaymentCode === selectedSubsidy)
          ?.PaymentValue ?? "",
      ]);
    }
  }, [selectedCheckboxes, isMultiplePayment, paymentList, selectedSubsidy]);

  //save form state when back navigation
  useEffect(() => {
    if (!isEditablePage && preventDirectBackNavigationState) {
      onSelectSubsidy(
        selectedSubsidy,
        selectedSubsidyDescription,
        localPaymentInstructions,
      );
      resetPreventDirectBackNavigationState();
      navigate(-1);
    }
    // we only want this hook to fire on back navigation
    // eslint-disable-next-line
  }, [preventDirectBackNavigationState]);

  return (
    <Box mt={isEditablePage ? -9 : 0}>
      <StepPageLayoutContainer
        editableFlag={isEditablePage}
        shouldShowButtonsFooter={false}
        isNextButtonDisabled={
          selectedSubsidy === null || selectedSubsidy === ""
        }
        currentStepIndex={4}
        pathToNextStep={
          !isEditablePage ? PATHS.MED_REFILL_ORDER_SUMMARY.path : ""
        }
        onNextStep={() =>
          onSelectSubsidy(
            selectedSubsidy,
            selectedSubsidyDescription,
            localPaymentInstructions,
          )
        }
      >
        <Payment
          paymentChoice={paymentList}
          selectedSubsidy={selectedSubsidy}
          handleSubsidyChange={setSelectedSubsidy}
          paymentInfoText={paymentInfoText}
          handleCheckboxChange={handleCheckboxChange}
          selectedCheckboxes={selectedCheckboxes}
          handlePaymentInstructionsChange={handlePaymentInstructionsChange}
          localPaymentInstructions={localPaymentInstructions}
        />

        <StepPageButtonsFooter
          type={isEditablePage ? "EDIT" : "NEXT_STEP"}
          isNextButtonDisabled={
            selectedSubsidy === null || selectedSubsidy === ""
          }
          pathToNextStep={
            !isEditablePage ? PATHS.MED_REFILL_ORDER_SUMMARY.path : undefined
          }
          onNextStep={() =>
            onSelectSubsidy(
              selectedSubsidy,
              selectedSubsidyDescription,
              localPaymentInstructions,
            )
          }
        />
      </StepPageLayoutContainer>
    </Box>
  );
};

export default RefillPaymentPage;
