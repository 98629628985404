import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    layout: {
      flex: "1 1 auto",
      overflowY: "auto",
    },
    title: {
      color: theme.palette.text.primary,
      typography: "header4_semibold",
    },
    payValue: {
      color: theme.palette.warning.main,
      typography: "header4_semibold",
      width: "100%",
    },
    cardLabel: {
      color: theme.palette.grey[600],
      typography: "body2_regular",
      pt: 0.5,
    },
    cardValue: {
      color: theme.palette.text.primary,
      typography: "body2_semibold",
    },
    textBox: {
      margin: theme.spacing(2.5, 0, 2.5),
    },
    payMethods: {
      "& img": {
        mr: 1,
      },
    },
    sharpnoticepanel: {
      mt: 1,
      mb: 2,
    },

    summaryContainer: {
      mx: 2,
      py: 2,
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    summaryBox: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    summaryBoxLeft: {
      flex: 1,
    },
    summaryBoxRight: {
      textAlign: "right",
      color: theme.palette.common.black,
      typography: "header1_semibold",
    },
    backLink: {
      color: theme.palette.primary.main,
      typography: "body2_semibold",
      textDecoration: "none",
      lineHeight: 1,
      minWidth: "auto",
      mt: -2.5,
      textAlign: "center",
    },
    transitionPage: {
      m: 3.75,
    },
    transitionText: {
      color: theme.palette.grey[400],
      typography: "title_semibold",
    },
    transitionImage: {
      mt: 6.25,
      width: "100%",
    },
  } satisfies SxObjType;
};

export { sxStyles };
