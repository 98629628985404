import { RootDispatch, RootState } from "lib/redux/root/redux.types";
import { connect } from "react-redux";
import AppRouteRenderer from "./AppRouteRenderer";
import { closeErrorModal } from "lib/redux/notifications";

const mapStateToProps = (state: RootState) => {
  return {
    openFlagErrorModal: state.notifications.error.open,
    messageErrorModal: state.notifications.error.message,
    customizedErrorModalButtonText:
      state.notifications.error.customizedButtonText,
    customizedNavBarDisplayName: state.navigation.navBarDisplayName,
  };
};

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    /**
     * Performs initial loading / API calls required on every time you load the application.
     *
     * Useful for defining API calls / setting up the state that needs to be set up first
     * before any other component can even be rendered on the page.
     */
    closeErrorModal: () => {
      dispatch(closeErrorModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouteRenderer);
