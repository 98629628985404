import { connect } from "react-redux";
import PaymentFooterLinks from "./PaymentFooterLinks";
import { RootState } from "lib/redux/root/redux.types";

const mapStateToProps = (state: RootState) => {
  return {
    isPatient: state.user.isPatient,
  };
};

export default connect(mapStateToProps)(PaymentFooterLinks);
