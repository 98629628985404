import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    checkStatusBox: {
      marginTop: "35%",
    },

    paymentStatusRegion: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(4.5),
      margin: theme.spacing(0, 1.5),
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
    paymentDisclaimer: {
      color: theme.palette.grey[600],
      typography: theme.typography.caption1_semibold,
      padding: theme.spacing(2),
      alignItems: "center",
    },
    label: {
      color: theme.palette.grey[600],
      typography: theme.typography.caption1_semibold,
    },
    detail: {
      color: theme.palette.text.primary,
      typography: theme.typography.caption1_semibold,
    },
    title: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_semibold,
      margin: theme.spacing(3, 0, 0, 0),
    },
    paymentAmount: {
      color: theme.palette.warning.main,
      typography: theme.typography.body2_semibold,
    },
    detailsRegion: {
      margin: theme.spacing(0, 1.5),
      justifyContent: "space-between",
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
    detailRow: {
      display: "flex",
      margin: theme.spacing(1, 0),
      justifyContent: "space",
    },
    detailRegion: {
      width: "100%",
      padding: theme.spacing(0.5, 1),
    },
    notesRegion: {
      margin: theme.spacing(2.5, 4),
    },
    noteRegion: {
      display: "flex",
      marginTop: theme.spacing(1),
    },
    noteNumber: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
      paddingRight: theme.spacing(1),
    },
    note: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
    },
    footer: {
      padding: theme.spacing(3, 2, 2.5),
    },
  } satisfies SxObjType;
};

export { sxStyles };
