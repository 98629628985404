import { UPDATE_PATIENT_MASTER_URL } from "api/resources/configs/URL";
import { UpdatePatientMasterProfileResponsePayload } from "./updatePatientMasterProfile.fromApi.types";
import { formatUpdatePatientMasterProfileErrorMessage } from "api/resources/messages/ErrorMessage";
import { PatientMasterCreateUpdateProfile } from "../patientMasterProfile.types";
// import { MOCK_UPDATE_PATIENT_MASTER_PROFILE_RESPONSE } from "./mock/updatePatientMasterProfile.mock";
import { getRequestOptions } from "api/resources/configs/requests";
import axios from "axios";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";

export const updatePatientMasterProfile = async (
  updatePatientMasterProfile: PatientMasterCreateUpdateProfile,
  isMyInfo: boolean,
): Promise<UpdatePatientMasterProfileResponsePayload> => {
  try {
    const response =
      await axios.post<UpdatePatientMasterProfileResponsePayload>(
        UPDATE_PATIENT_MASTER_URL(),
        updateRequestPayload(updatePatientMasterProfile, isMyInfo),
        getRequestOptions(),
      );
    return parseResponse(response, UPDATE_PATIENT_MASTER_URL(), true);
  } catch (error) {
    const msg = formatUpdatePatientMasterProfileErrorMessage(
      UPDATE_PATIENT_MASTER_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const updateRequestPayload = (
  updatePatientMasterProfile: PatientMasterCreateUpdateProfile,
  isMyInfo: boolean,
) => {
  return {
    ...updatePatientMasterProfile,
    isMyInfo: isMyInfo,
  };
};
