import { useState } from "react";
import { Typography, Box, CircularProgress } from "@mui/material";
import { sxStyles } from "./MammogramRequestFormSubmissionPage.styles";
import { MammogramRequestFormSubmissionProps } from "./MammogramRequestFormSubmissionPage.types";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import {
  Question,
  CreateMammogramAppointmentRequestPayload,
} from "api/appointment/CreateMammogramAppointment/createMammogramAppointment.types";
import { createMammogramAppointment } from "api/appointment/CreateMammogramAppointment/createMammogramAppointment";
import SimpleCheckbox from "lib/components/formInputs/Checkbox/SimpleCheckbox";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { CHECKBOX_QUESTION_INDEX } from "api/appointment/GetMammogramQuestionnaire/getMammogramQuestionnaire";
import { useAppSelector } from "lib/redux/hooks";
import { selectUser } from "lib/redux/user/selectors";
import PersonalParticularsForm from "../../PersonalParticularsForm/PersonalParticularsForm";
import { AxiosError } from "axios";

const MammogramRequestFormSubmissionPage = ({
  currentPage,
  questions,
  ansList,
  location,
  targetSystem,
  service,
  checked,
  disableSubmit,
  handleChange,
  handlePageChange,
  personalParticularsFormField,
  handlePersonalParticularsFormFieldChange,
  handleErrorModal,
  subheader,
  disclaimerNote,
  agreementText,
  successSubmissionText,
}: MammogramRequestFormSubmissionProps) => {
  const classes = sxStyles();
  const navigate = useNavigate();

  // Redux state
  const userProfile = useAppSelector(selectUser);

  // Local state
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const submitMammogramAppointment = async () => {
    try {
      let toBeSubmitted = new Array<Question>(ansList.length);

      const checkBoxAnsList =
        ansList.length > CHECKBOX_QUESTION_INDEX
          ? ansList[CHECKBOX_QUESTION_INDEX].split(",")
          : [];

      toBeSubmitted = ansList.map((ans, index) => {
        return {
          QuestionId: index + 1,
          //Answer: ans,
          Answer:
            index !== CHECKBOX_QUESTION_INDEX
              ? ans
              : questions[CHECKBOX_QUESTION_INDEX].QuestionDetails.map(
                  (d, i) => {
                    return checkBoxAnsList[i] === "1" ? d.Text : "";
                  },
                )
                  .filter((e) => e)
                  .join(","),

          // construct required structure for checkbox question
          QuestionDetails:
            index !== CHECKBOX_QUESTION_INDEX
              ? null
              : questions[CHECKBOX_QUESTION_INDEX].QuestionDetails.map(
                  (d, i) => {
                    //according to API schema, selected checbox choices are submitted with id with text pair, unselected checobox choices are submitted with id and NULL
                    return {
                      Id: d.Id,
                      Answer: checkBoxAnsList[i] === "1" ? d.Text : null,
                    };
                  },
                ),
        };
      });

      if (targetSystem && personalParticularsFormField && location && service) {
        const requestPayload: CreateMammogramAppointmentRequestPayload = {
          TargetSystem: targetSystem,
          PatientDetail: {
            MemberId: userProfile.memberIdentifier || "",
            PatientName: personalParticularsFormField.name || "",
            PolyclinicName: location,
            ServiceName: service,
            ContactEmailAddress: personalParticularsFormField.email || "",
            ContactNo: personalParticularsFormField.contact || "",
            AppointmentPreference: null,
          },
          Questions: toBeSubmitted,
        };
        setIsLoading(true);

        await createMammogramAppointment(requestPayload);

        setOpen(true);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        handleErrorModal(true, error.response?.data.Message);
      } else {
        handleErrorModal(true, null);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Box display="flex" align-items="center" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : (
        currentPage === "submission" && (
          <Box>
            <Box p={2}>
              <Typography variant="body2" sx={classes.title}>
                Request to make an appointment
              </Typography>

              <Typography sx={classes.infoText}>
                {subheader && MapRawStringToReactElement(subheader)}
              </Typography>
            </Box>

            <Box sx={classes.apptInfo}>
              <Typography sx={classes.apptInfoTextLabel}>
                Polyclinic:
              </Typography>
              <Typography sx={classes.apptInfoText}>{location}</Typography>
            </Box>

            <Box sx={classes.apptInfo}>
              <Typography variant="body2" sx={classes.apptInfoTextLabel}>
                Service:
              </Typography>
              <Typography variant="body2" sx={classes.apptInfoText}>
                {service}
              </Typography>
            </Box>
            <Box p={2}>
              <PersonalParticularsForm
                personalParticularsFormField={personalParticularsFormField}
                handlePersonalParticularsFormFieldChange={
                  handlePersonalParticularsFormFieldChange
                }
                showDOB={false}
                showRequiredForEmail={true}
              />

              <Box sx={classes.disclaimerInfo}>
                {disclaimerNote && MapRawStringToReactElement(disclaimerNote)}
              </Box>
              <Box sx={classes.checkboxField}>
                <SimpleCheckbox
                  checked={checked}
                  value="noteCheckbox"
                  label={
                    agreementText
                      ? MapRawStringToReactElement(agreementText)
                      : "I have read and understood the disclaimer notes above."
                  }
                  handleChange={(event) => handleChange(event.target.checked)}
                ></SimpleCheckbox>
              </Box>

              <Box mt={3}>
                <ButtonsFooter
                  nextButtonText="Submit"
                  cancelButtonText="Back"
                  isDisabled={disableSubmit}
                  onClickNext={async () => {
                    await submitMammogramAppointment();
                  }}
                  onClickCancel={() => {
                    handlePageChange();
                  }}
                ></ButtonsFooter>
              </Box>

              <ConfirmationModal
                title="Request Submitted"
                body={
                  successSubmissionText &&
                  MapRawStringToReactElement(successSubmissionText)
                }
                hideNextButton={true}
                nextButtonText=""
                hideCancelButton={false}
                cancelButtonText="Go to Appointment"
                onClickCancel={() => {
                  navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
                }}
                open={open}
                onClose={() => setOpen(false)}
              ></ConfirmationModal>
            </Box>
          </Box>
        )
      )}
    </>
  );
};

export default MammogramRequestFormSubmissionPage;
