import IMAGES from "lib/assets/images";
import { CategoryCodes } from "./Dashboard.types";

const NUHS_POSTALCODE = "119228";
const CHAS_URL = "https://chas.moh.gov.sg/Home/Welcome";

const VACCINATION_CATEGORY_CODE: CategoryCodes = "PR_VAX";
const CNRONIC_DISEASE_SCREENING_CODE: CategoryCodes = "PR_CHS";
const CANCER_SCREENING_CODE: CategoryCodes = "PR_CAN";
const CNRONIC_DISEASE_MANAGEMENT_CODE: CategoryCodes = "PR_CDM";

// constant storing all the images for each category
export const CATEGORY_IMAGE_MAP: CategoryImageMap = {
  [VACCINATION_CATEGORY_CODE]: IMAGES.healthTogetherMHM.Vaccination,
  [CNRONIC_DISEASE_SCREENING_CODE]:
    IMAGES.healthTogetherMHM.ChronicDiseaseScreening,
  [CANCER_SCREENING_CODE]: IMAGES.healthTogetherMHM.CancerScreening,
  [CNRONIC_DISEASE_MANAGEMENT_CODE]:
    IMAGES.healthTogetherMHM.ChronicDiseaseManagement,
};

export interface CategoryImageMap {
  [key: string]: string;
}

const heightCode = (categoryCode: string | null) => {
  switch (categoryCode) {
    case CNRONIC_DISEASE_SCREENING_CODE:
      return "CDSO_1";
    case CNRONIC_DISEASE_MANAGEMENT_CODE:
      return "MMHTM_1";
    default:
      return "";
  }
};

const weightCode = (categoryCode: string | null) => {
  switch (categoryCode) {
    case CNRONIC_DISEASE_SCREENING_CODE:
      return "CDSO_2";
    case CNRONIC_DISEASE_MANAGEMENT_CODE:
      return "MMHTM_2";
    default:
      return "";
  }
};

const BMICode = (categoryCode: string | null) => {
  switch (categoryCode) {
    case CNRONIC_DISEASE_SCREENING_CODE:
      return "CDSO_3";
    case CNRONIC_DISEASE_MANAGEMENT_CODE:
      return "MMHTM_3";
    default:
      return "";
  }
};

export {
  heightCode,
  weightCode,
  BMICode,
  CNRONIC_DISEASE_SCREENING_CODE,
  CNRONIC_DISEASE_MANAGEMENT_CODE,
  NUHS_POSTALCODE,
  CHAS_URL,
};
