import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_semibold,
      padding: theme.spacing(3, 0, 1.5, 0),
    },
  } satisfies SxObjType;
};

export { sxStyles };
