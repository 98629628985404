/**
 * Department codes for each service, that represents whether or not an appointment
 * can be made for same-day or in-advance bookings.
 *
 * These codes may also be found on appointment list objects, because an
 * appointment is related to a service, and can be used to perform the same check.
 */
export const DEPARTMENT_CODE_CONSULTATION = "CON";
export const LOGO_CODE_NUP = "NUP";

/**
 * For create appointment flow,d dtermines if this appointment is one that only allows a same-day booking.
 * Till 21st Jul 2021, the requirement is to enable same day booking when creating NUP Dr Consultation.
 *
 * @param {string | null} departmentCode  The service / appointment's department type
 * @returns {boolean}
 *   True if you can only make a same-day appointment for this service, False otherwise.
 */
const isSameDayCreate = (departmentCode: string | null) => {
  return departmentCode === DEPARTMENT_CODE_CONSULTATION;
};

/**
 * For reschedule appointment flow, determines if this appointment is one that only allows a same-day booking.
 * Till 21tst Jul 2021, the requirement is to only enable same day booking when rescheduling non-NUP Dr Consultation.
 *
 * @param {string | null} departmentCode  The service / appointment's department type
 * @param {string | null} logoCode  possisble value: "NUP"
 * @returns {boolean}
 *   True if you can only make a same-day appointment for this service, False otherwise.
 */
const isSameDayReschedule = (
  departmentCode: string | null,
  logoCode: string | null,
) => {
  return (
    departmentCode === DEPARTMENT_CODE_CONSULTATION &&
    logoCode !== LOGO_CODE_NUP
  );
};

export { isSameDayCreate, isSameDayReschedule };
