import { apiBaseUrl, appApiKey } from "lib/configs";

// ==========================
// NUHS backend / AWS API-related Configurations
//
// For env-specific configs, e.g. those that are read from process.env,
// to avoid making a direct call to process.env scattered throughout
// the codebase, it's advised to only read "process.env" from config-
// related files like this one. This ensures that all of these
// references are kept in 1 place.
// ==========================

// ==================
// COMMON
// ==================

export const CONTENT_TYPE = "application/json";

// ==================
// AWS APIs
// ==================

// Base URL for APIs
export const API_BASE_URL = apiBaseUrl();

// API key sent to all API endpoints, for access to those APIs
export const API_KEY = appApiKey();

// Base URL for secured APIs that require authentication
export const API_BASE_URL_SECURE = `${API_BASE_URL}/secure`;

// Base URL for trusted low risk APIs that require low risk token authentication
export const API_BASE_URL_TRUSTED = `${API_BASE_URL}/trusted`;

// Base URL for public APIs that do not require any sort of authentication
export const API_BASE_URL_PUBLIC = `${API_BASE_URL}/public`;
