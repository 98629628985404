import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";
import { RootState } from "lib/redux/root/redux.types";
import { UserState } from "lib/redux/user/user.types";
import {
  MedRefillState,
  MedRefillPrescriptionFile,
} from "ui/medrefill/ducks/medrefill.types";
import {
  SubmitMedicationRequestPayload,
  Image,
} from "api/medrefill/SubmitMedication/submitMedication.types";
import { submitMedication } from "api/medrefill/SubmitMedication/submitMedication";
import OrderSummarySubmissionButtons from "./OrderSummarySubmissionButtons";
import { handleErrorModal } from "lib/redux/notifications";
import IMAGES from "lib/assets/images";

// =========================
// MAP STATE TO PROPS
// =========================

const mapStateToProps = (state: RootState) => {
  return {
    userState: state.user,
    appointmentId: state.appointments.appointmentMeta.appointmentId,
    regAppointmentId: state.appointments.appointmentMeta.regAppointmentId,
    targetSystem: state.appointments.appointmentMeta.targetSystem,
  };
};

// =========================
// MAP DISPATCH TO PROPS
// =========================

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onSubmitMedication: async (
      userState: UserState,
      medrefillState: MedRefillState,
      apptId: string | null,
      targetSystem: string | null,
      oldMemberIdentifier: string | null,
      oldTokenizedId: string | null,
    ) => {
      const request = mapMedRefillStateToSubmitMedicationRequest(
        userState,
        medrefillState,
        apptId,
        targetSystem,
        oldMemberIdentifier,
        oldTokenizedId,
      );
      return await submitMedication(request);
    },
    handleErrorModal: (open: boolean, message: string | null) => {
      dispatch(handleErrorModal({ open, message }));
    },
  };
};

/**
 * Maps the current User & MedRefill redux state to the request to be made to the /SubmitMedication API.
 *
 * @param {UserState} userState  The User state
 * @param {MedRefillState} medrefillState  The MedRefill state
 * @returns {SubmitMedicationRequestPayload} The request to /SubmitMedication
 */
const mapMedRefillStateToSubmitMedicationRequest = (
  userState: UserState,
  medrefillState: MedRefillState,
  apptId: string | null,
  targetSystem: string | null,
  oldMemberIdentifier: string | null,
  oldTokenizedId: string | null,
): SubmitMedicationRequestPayload => {
  return {
    MemberIdentifier:
      userState.isPatient && !medrefillState.particulars.isPatient
        ? null
        : userState.memberIdentifier,

    // Institutions
    Cluster: medrefillState.institution.cluster,
    FacilityId: medrefillState.institution.facilityId,
    ClinicId: medrefillState.institution.clinicId,

    // Particulars
    RequesterName: userState.requestorName,
    RequesterNric: userState.requestorNric,
    PatientName: medrefillState.particulars.isPatient
      ? userState.patientName
      : medrefillState.particulars.patientName,
    PatientNric: medrefillState.particulars.isPatient
      ? userState.patientNric
      : medrefillState.particulars.patientNric,
    Contact: medrefillState.particulars.contactNumber,
    IsPatient: medrefillState.particulars.isPatient,
    Email: medrefillState.particulars.email,

    // Prescription
    PrescripionRequestType: medrefillState.requestType,
    PrescriptionDate:
      medrefillState.prescription.refillOriginalPrescriptionDate,
    Files: mapPrescriptionFilesToImages(medrefillState.prescription.files),
    TopupReasonId: medrefillState.prescription.topupReason,
    TopupOtherReason: medrefillState.prescription.topupOtherReason,

    // Quantity
    QuantityDetails: medrefillState.quantity.details.map((d) => {
      return {
        PrecriptionItemNo: d.prescriptionItemNo,
        Number: d.number,
        UOMDuration: d.UOMDuration,
        UOMDurationId: d.UOMDurationId,
      };
    }),
    QuantityTypeId: medrefillState.quantity.typeId,

    // Collection
    LocationId: medrefillState.collection.locationId,
    TimeSlot: medrefillState.collection.timeslot,
    CollectionDate: medrefillState.collection.date,
    PostalCode: medrefillState.collection.postalCode,
    UnitNo: medrefillState.collection.unitNo,
    BlockNo: medrefillState.collection.blockNo,
    Address: medrefillState.collection.address,
    SpecialRequest: medrefillState.collection.specialRequest,

    // Payment
    Subsidy: medrefillState.payment.subsidy,
    CollectionTypeId: medrefillState.collection.typeId,
    PaymentInstructions: medrefillState.payment.paymentInstructions,

    // isAlternativeAddress is set to FALSE, because this flag is only used by
    // the backend to determine if the particulars from this request originate from MyInfo (profile service)
    // Since we do not have MyInfo integration yet, this flag should always be FALSE.
    IsAlternativeAddress: false,

    SourceAppointmentId: apptId,
    SourceSystem: targetSystem,
    OldMemberIdentifier: oldMemberIdentifier,
    OldTokenizedId: oldTokenizedId,
  };
};

const mapPrescriptionFilesToImages = (
  files: MedRefillPrescriptionFile[],
): Image[] => {
  const images: Image[] = [];

  files.forEach((file) => {
    // A file may have both a front and back image

    if (file.front.fileName && file.front.fileData) {
      images.push({
        FileName: file.front.fileName,
        FileData: file.front.fileData,
        FileType: "Front",
        TopupQuantity: file.topUp.quantity,
        TopupQuantityUOMId: file.topUp.UOMId,
        TopupQuantityUOM: file.topUp.UOM,
        MedicationName: file.topUp.medicationName,
        Prescription: file.prescription,
      });
    }

    if (file.back.fileName && file.back.fileData) {
      images.push({
        FileName: file.back.fileName,
        FileData: file.back.fileData,
        FileType: "Back",
        TopupQuantity: file.topUp.quantity,
        TopupQuantityUOMId: file.topUp.UOMId,
        TopupQuantityUOM: file.topUp.UOM,
        MedicationName: file.topUp.medicationName,
        Prescription: file.prescription,
      });
    }

    //if the file does not have front or back data
    if (
      !(file.back.fileName && file.back.fileData) &&
      !(file.front.fileName && file.front.fileData)
    ) {
      images.push({
        FileName: "NoImageUploaded.png",
        FileData: IMAGES.medrefill.NoImageUploaded.split(",")[1],
        FileType: "Front",
        TopupQuantity: file.topUp.quantity,
        TopupQuantityUOMId: file.topUp.UOMId,
        TopupQuantityUOM: file.topUp.UOM,
        MedicationName: file.topUp.medicationName,
        Prescription: file.prescription,
      });
    }
  });

  return images;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderSummarySubmissionButtons);
