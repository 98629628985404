import { useCallback, useEffect } from "react";
import { useGetMessageAction } from "./useGetMessageAction";

/**
 * The useOnBackTriggered hook checks for a specific message action and triggers a callback function
 * when it occurs.
 * @param callback - The `callback` parameter in the `useOnBackTriggered` function is a function that
 * takes an optional argument of any type and does not return anything (`void`). This callback function
 * is called when a specific condition is met within the `onBackTriggered` function.
 */
export const useOnBackTriggered = (callback: (arg0?: any) => void) => {
  const { messageActionObj, consumeMessageActionObj } = useGetMessageAction();

  const onBackTriggered = useCallback(() => {
    if (messageActionObj?.action === "backTriggered" && callback) {
      callback();
      consumeMessageActionObj();
    }
  }, [messageActionObj?.action, callback, consumeMessageActionObj]);

  useEffect(() => {
    onBackTriggered();
  }, [messageActionObj, onBackTriggered]);
};
