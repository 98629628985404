import isEmptyObject from "lib/util/IsEmptyObject/isEmptyObject";

const CCDP_SUCCESS_CODE = "S";
const CCDP_WARNING_CODE = "W";
export const AXIOS_WARNING_CODE = "ATT0019";
const EPIC_RETRIEVE_SUCCESS_CODE = 200;
const EPIC_CREATE_UPDATE_SUCCESS_CODE = 201;

export const hasCcdpSuccess = (payload: any) => {
  if (!isEmptyObject(payload)) {
    return (
      payload.Status === CCDP_SUCCESS_CODE ||
      payload.Status === CCDP_WARNING_CODE ||
      payload.HeaderStatus === EPIC_RETRIEVE_SUCCESS_CODE ||
      payload.HeaderStatus === EPIC_CREATE_UPDATE_SUCCESS_CODE
    );
  }

  return false;
};

// !exp Use this helper function to have any special handling for Status "W"
export const hasCcdpWarning = (payload: any) => {
  if (payload) {
    return payload.Status === CCDP_WARNING_CODE;
  } else {
    return false;
  }
};
