import { Typography, Box } from "@mui/material";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { PharmacyDetailSummarySectionProps } from "./PharmacyDetailSummarySection.types";
import { sxStyles } from "./PharmacyDetailSummarySection.styles";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

const PharmacyDetailSummarySection = ({
  facilityName,
  locationName,
  date,
  timeslot,
  specialRequest,
}: PharmacyDetailSummarySectionProps) => {
  const classes = sxStyles();
  const dateObject = date ? getDateFromISOString(date) : null;

  return (
    <>
      <Typography sx={classes.title}>Pick Up at:</Typography>

      <Box>
        {facilityName === "National University Hospital" && (
          <Typography sx={classes.facilityDetail}>{facilityName}</Typography>
        )}
        <Typography sx={classes.locationDetail}>{locationName}</Typography>
      </Box>

      {date && (
        <Box pb={2}>
          <Typography sx={classes.contentLabel}>
            Preferred pickup date
          </Typography>
          <Typography sx={classes.contentDetail}>
            {dateObject
              ? formatDate(formatDateToISO(dateObject), "dd MMM yyyy")
              : null}
          </Typography>
        </Box>
      )}

      {timeslot && (
        <Box pb={2}>
          <Typography sx={classes.contentLabel}>
            Preferred pickup time
          </Typography>
          <Typography sx={classes.contentDetail}>{timeslot}</Typography>
        </Box>
      )}

      <Typography sx={classes.contentLabel}>
        Special Request (if any)
      </Typography>
      <Typography sx={classes.contentDetail}>
        {specialRequest ?? "-"}
      </Typography>
    </>
  );
};

export default PharmacyDetailSummarySection;
