import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    moreButton: {
      margin: theme.spacing(1),
      textAlign: "right",
    },
    button: {
      padding: theme.spacing(2, 0),
      textAlign: "right",
    },
  } satisfies SxObjType;
};

export { sxStyles };
