import { connect } from "react-redux";
import TransactionHistoryProfileHeader from "./TransactionHistoryProfileHeader";
import { RootState } from "lib/redux/root/redux.types";

const mapStateToProps = (state: RootState) => {
  return {
    name: state.user.patientName,
    isLoading: state.user.isLoading,
  };
};

export default connect(mapStateToProps)(TransactionHistoryProfileHeader);
