import { parseISO } from "date-fns";
import { Location } from "api/medrefill/GetLocations/getLocations.types";
import { IterableElement } from "lib/data/data.types";

/**
 * This file contains methods for mapping data from the API or otherwise
 * to object schemas / interfaces supported by this component.
 */

/**
 * Maps a list of locations returned from the remote API to a list of
 * elements compatible with the Preferred Delivery Time dropdown.
 *
 * @param {Location[]} locations  List of locations from the API
 * @param {string | null} date  Preferred delivery date (In ISO DateTime Format)
 *
 *   API Source System's Day of the Week Mapping:
 *     1 = Mon, 2 = Tues, 3 = Wed, 4 = Thurs, 5 = Fri, 6 = Sat, 0 = Sun
 *
 *   Take note that JavaScript's new Date().getDay() function returns the day of the
 *   week (from 0 to 6) for the specified date.
 *
 *   JavaScript's new Date().getDay() Mapping:
 *     1 = Mon, 2 = Tues, 3 = Wed, 4 = Thurs, 5 = Fri, 6 = Sat, 0 = Sun
 *   (see that it wraps and Sun is now 0)
 *
 * @returns {IterableElement[]}  List of elements in the Preferred Delivery Time dropdown selection.
 */
const mapApiLocationsToPreferredDeliveryTimeSlots = (
  location: Location,
  date: string | null,
): IterableElement[] => {
  if (location && date) {
    const chosenDay = getDayBasedOnMapping(parseISO(date));
    const timeslots = location.Timeslots.find((slot) => slot.Day === chosenDay);

    if (timeslots && timeslots.Slots.length > 0) {
      return timeslots.Slots.map((timeslotValue) => {
        return {
          // used by /SubmitMedication to indicate ID of timeslot (that is also the value)
          id: timeslotValue,

          // used by dropdown to display name of location
          text: timeslotValue,
        };
      });
    } else {
      return [];
    }
  } else {
    return [];
  }
};

/**
 * Converts the day of the week from JavaScript's new Date().getDay() mapping
 * to API Source System's Day of the Week Mapping
 *
 * @param {Date} date  A date
 *
 * @returns {number}  Day of the week according to API SOURCE SYSTEM'S mapping
 */
const getDayBasedOnMapping = (date: Date) => {
  const dayIndex = date.getDay();
  return dayIndex;
};

export { mapApiLocationsToPreferredDeliveryTimeSlots };
