import { useEffect, useState } from "react";
import { ReportDetailProps } from "./ReportDetail.types";
import { sxStyles } from "./ReportDetail.styles";
import { Typography, Box } from "@mui/material";
import Dropdown from "lib/components/formInputs/Dropdown/Dropdown";
import { MrrpReportDetailState } from "ui/mrrp/ducks/mrrp.types";
import Datepicker from "lib/components/formInputs/Datepicker/Datepicker";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { IterableElement } from "lib/data/data.types";
import FileUploader from "./FileUploader/FileUploader";
import StepPageButtonsFooter from "../../common/StepPageButtonsFooter/StepPageButtonsFooter";
import { PATHS } from "lib/routing";
import { useNavigate } from "react-router-dom";

const disclaimerText =
  "Please note that all medical report applications prior to June 2018 will not be processed.";

const ReportDetail = ({
  visitDateFormLabel,
  reportTypeFormLabel,
  reportDescriptionFormLabel,
  reportDetail,
  preventDirectBackNavigationState,
  departments,
  purposes,
  devicePlatform,
  selectedHospitalCode,
  onSelectReportDetail,
  resetPreventDirectBackNavigationState,
  onGetReportOptions,
}: ReportDetailProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();

  const [reportDetailsState, setReportDetailsState] =
    useState<MrrpReportDetailState>(reportDetail);

  const reportTypeOptions =
    purposes.find(
      (item) => item.Code === reportDetailsState.purposeCodeOfApplication,
    )?.ReportTypes || [];

  const isAttachmentMandatory = reportTypeOptions.find(
    (d) => d.Code === reportDetailsState.reportTypeCode,
  )?.IsAttachmentMandatory;

  //save form state when back navigation
  useEffect(() => {
    if (preventDirectBackNavigationState) {
      onSelectReportDetail(reportDetailsState);
      resetPreventDirectBackNavigationState();
      navigate(-1);
    }

    // we only want this hook to fire on back navigation
    // eslint-disable-next-line
  }, [preventDirectBackNavigationState]);

  // useEffect hook on page load
  useEffect(() => {
    if (reportDetailsState.reportTypeCode) {
      onGetReportOptions(
        selectedHospitalCode || "",
        reportDetailsState.reportTypeCode || "",
      );
    }

    // eslint-disable-next-line
  }, [reportDetailsState.reportTypeCode]);

  useEffect(() => {
    if (
      reportDetailsState.clinicalDepartmentCode != null &&
      !departments.find(
        (d) => d.Code === reportDetailsState.clinicalDepartmentCode,
      )
    ) {
      setReportDetailsState({
        ...reportDetailsState,
        clinicalDepartment: null,
        clinicalDepartmentCode: null,
      });
    }
  }, [departments, reportDetailsState]);

  const disableNext = () => {
    let disableNext = false;
    if (
      reportDetailsState.visitDate &&
      reportDetailsState.clinicalDepartmentCode &&
      reportDetailsState.purposeCodeOfApplication &&
      reportDetailsState.reportTypeCode
    ) {
      if (isAttachmentMandatory) {
        disableNext = reportDetailsState.reportFileByte ? false : true;
      }
    } else {
      disableNext = true;
    }
    return disableNext;
  };

  return (
    <>
      <Box sx={{ mx: 2 }}>
        <Typography sx={classes.title}>Report Details</Typography>

        <Box sx={classes.textBox}>
          <Datepicker
            label={
              visitDateFormLabel ? visitDateFormLabel : "Admission/Visit Date"
            }
            disableFuture
            value={reportDetailsState.visitDate}
            onSelect={(date) =>
              setReportDetailsState({
                ...reportDetailsState,
                visitDate: formatDateToISO(date),
              })
            }
            required={false}
            minDate={selectedHospitalCode === "AH" ? "2018-06-01" : null}
          />

          {selectedHospitalCode === "AH" && (
            <Typography sx={classes.disclaimer}>{disclaimerText}</Typography>
          )}
        </Box>

        <Box sx={classes.textBox}>
          <Dropdown
            value={reportDetailsState.purposeCodeOfApplication}
            items={commonMapper(purposes)}
            label="Purpose of Application"
            handleChange={(event) => {
              const purposeCode = event.target.value;
              const purpose = purposes.find((d) => d.Code === purposeCode);
              setReportDetailsState({
                ...reportDetailsState,
                purposeCodeOfApplication: event.target.value,
                purposeOfApplication: purpose?.Description || null,
                reportTypeCode: null,
                reportType: null,
                reportDescription: null,
                reportFees: null,
                clinicalDepartmentCode: null,
                clinicalDepartment: null,
              });
            }}
            required={false}
          />
        </Box>

        {reportTypeOptions.length > 0 && (
          <Box sx={classes.textBox}>
            <Dropdown
              value={reportDetailsState.reportTypeCode}
              items={reportTypeMapper(reportTypeOptions)}
              label={reportTypeFormLabel ?? "Report Type"}
              handleChange={(event) => {
                const reportTypeCode = event.target.value;
                const reportType = reportTypeOptions.find(
                  (d) => d.Code === reportTypeCode,
                );
                setReportDetailsState({
                  ...reportDetailsState,
                  reportTypeCode: reportTypeCode,
                  reportType: reportType?.Name || null,
                  reportDescription: reportType?.Description || null,
                  reportFees: reportType?.Fee || 0,
                });
              }}
              required={false}
            />
          </Box>
        )}
        {reportTypeOptions.length > 0 && (
          <Box sx={{ mt: -4, mb: 4 }}>
            <Typography sx={classes.title}>
              {reportDescriptionFormLabel
                ? reportDescriptionFormLabel
                : "Report Description"}
            </Typography>
            <Typography sx={classes.text}>
              {reportDetailsState.reportDescription ?? "-"}
            </Typography>
          </Box>
        )}

        {reportTypeOptions.length > 0 && (
          <Box sx={classes.textBox}>
            <Dropdown
              value={reportDetailsState.clinicalDepartmentCode}
              // items={reportTypeMapper(reportTypeOptions)}
              items={
                !reportDetailsState.reportTypeCode
                  ? []
                  : commonMapper(departments)
              }
              label="Clinical Department"
              handleChange={(event) => {
                const departmentCode = event.target.value;
                const department = departments.find(
                  (d) => d.Code === departmentCode,
                );
                setReportDetailsState({
                  ...reportDetailsState,
                  clinicalDepartmentCode: departmentCode,
                  clinicalDepartment: department?.Description || null,
                });
              }}
              required={false}
            />
          </Box>
        )}

        <Typography sx={classes.title}>Report Fees</Typography>
        <Typography sx={classes.text}>
          {reportDetailsState.reportFees
            ? "$" +
              (Math.round(reportDetailsState.reportFees * 100) / 100).toFixed(2)
            : "$0"}
        </Typography>

        {isAttachmentMandatory ? (
          <Box sx={{ py: 3 }}>
            <FileUploader
              maxFileSize={2}
              disabled={reportDetailsState.reportFileName ? true : false}
              required={isAttachmentMandatory || false}
              fileName={reportDetailsState.reportFileName}
              fileByte={reportDetailsState.reportFileByte}
              fileMIMEType={reportDetailsState.reportFileMIMEType}
              onClickUpload={(fileName, fileData, fileType) => {
                const imgUint8Array = new Uint8Array(fileData);
                setReportDetailsState({
                  ...reportDetailsState,
                  reportFileName: fileName,
                  reportFileByte: imgUint8Array,
                  reportFileMIMEType: fileType,
                });
              }}
              onClickDelete={() => {
                setReportDetailsState({
                  ...reportDetailsState,
                  reportFileName: null,
                  reportFileByte: null,
                  reportFileMIMEType: null,
                });
              }}
            />
          </Box>
        ) : null}
      </Box>

      <StepPageButtonsFooter
        isNextButtonDisabled={disableNext()}
        pathToNextStep={PATHS.MRRP_NEW_REQUEST_COLLECTION.path}
        onNextStep={() => onSelectReportDetail(reportDetailsState)}
      />
    </>
  );
};

export default ReportDetail;

// common mapper is common to mapping department and purpose
const commonMapper = (
  items: {
    Code: string | null;
    Description: string | null;
    [key: string]: any;
  }[],
): IterableElement[] => {
  return items.map(
    (item): IterableElement => ({
      id: item.Code || "",
      text: item.Description || "",
    }),
  );
};

const reportTypeMapper = (
  departments: {
    Code: string | null;
    Name: string | null;
    [key: string]: any;
  }[],
): IterableElement[] => {
  return departments.map(
    (department): IterableElement => ({
      id: department.Code || "",
      text: department.Name || "",
    }),
  );
};
