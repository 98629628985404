import { useState, useEffect } from "react";
import { getInstitutionsByClustersAndGroups } from "api/appointment/GetInstitutionsByClustersAndGroups/getInstitutionsByClustersAndGroups";
import { Institution } from "api/appointment/GetInstitutionsByClustersAndGroups/getInstitutionsByClustersAndGroups.toUi.types";
import { mapApiDataToProps } from "api/appointment/GetInstitutionsByClustersAndGroups/mappers/mapper";
import { AxiosError } from "axios";

/**
 * A custom hook that fetches list of institutions for the appointments module from
 * the remote API and returns the appropriate data payload & loading state of that operation.
 *
 * @param {string[] | null} systemFlowSourceSystems  List of source systems that this patient's
 *   profile exists in, also known as the "Sources" list attribute in CCDP.
 *
 * @returns {[Institution[], boolean, boolean | null, string | null]}
 *   0 - Data mapped into Institution[]
 *   1 - True if the data fetch is complete, false otherwise.
 *   2 - True if the data fetch completed with errors, false otherwise.
 *   3 - Error message returned from API call
 */
const useGetInstitutionsByClustersAndGroups = (
  systemFlowSourceSystems: string[] | null,
  pageFlag: boolean,
): [Institution[], boolean, boolean | null, string | null] => {
  const [data, setData] = useState<Institution[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        if (systemFlowSourceSystems !== null) {
          // need to wait for the systemFlowSourceSystems attribute to be populated first, before calling
          // and mapping the institutions list data.

          const responseData = await getInstitutionsByClustersAndGroups();

          const institutionsList = mapApiDataToProps(
            systemFlowSourceSystems,
            responseData,
          );
          setData(institutionsList);
          setHasErrored(false);
          setErrorMessage(null);
        }
      } catch (error) {
        setData([]);
        setHasErrored(true);
        if (error instanceof AxiosError) {
          setErrorMessage(error.response?.data.Message);
        } else {
          setErrorMessage(null);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [systemFlowSourceSystems, pageFlag]);

  return [data, isLoading, hasErrored, errorMessage];
};

export { useGetInstitutionsByClustersAndGroups };
