import {
  GetInstitutionsByClustersAndGroupsPayload,
  InstitutionPayload,
  PolyclinicPayload,
  InstitutionGroupCode,
} from "../getInstitutionsByClustersAndGroups.fromApi.types";
import { Institution } from "../getInstitutionsByClustersAndGroups.toUi.types";

// =======================
// CONSTANTS & INDICATORS
// =======================

const GROUPCODE_HOSPITAL = "h";
const GROUPCODE_POLYCLINIC = "p";
const GROUPCODE_SPECIALTY_CENTRES = "c";
const DISPLAY_CATEGORY_HOSPITAL = "Hospitals";
const DISPLAY_CATEGORY_POLYCLINICS = "Polyclinics";
const DISPLAY_CATEGORY_SPECIALTY_CENTRES = "Specialty Centres";

// =======================
// MAPPER
// =======================

/**
 * Maps fetched GetInstitutionsByClustersAndGroups API payload to a schema that the
 * UI components can consume.
 *
 * @param {string[] | null} systemFlowSourceSystems  List of source systems that this patient's profile can be found in.
 * @param {GetInstitutionsByClustersAndGroupsPayload} payload  The payload which will be transformed
 *
 * @returns {Institution[]} List of renderable institutions
 */
const mapApiDataToProps = (
  systemFlowSourceSystems: string[] | null,
  payload: GetInstitutionsByClustersAndGroupsPayload,
): Institution[] => {
  let institutionsList: Institution[] = [];

  if (payload.Institutions) {
    payload.Institutions.forEach((inst) => {
      if (inst.GroupCode) {
        if (!isNationalUniversityPolyclinicEntity(inst)) {
          // hospital / specialty centre institutions like NUH, Alexandra Hospital, etc.
          institutionsList.push(mapInstitution(systemFlowSourceSystems, inst));
        }

        if (isNationalUniversityPolyclinicEntity(inst)) {
          // a polyclinic is considered an "institution"
          inst.Polyclinics.forEach((polyclinic) => {
            institutionsList.push(
              mapPolyclinic(inst, systemFlowSourceSystems, polyclinic),
            );
          });
        }
      }
    });
  }

  return institutionsList;
};

// ===========================
// MAP INDIVIDUAL OBJECTS
// ===========================

const mapInstitution = (
  systemFlowSourceSystems: string[] | null,
  institution: InstitutionPayload,
): Institution => {
  const category = institution.GroupCode as InstitutionGroupCode;
  return {
    displayName: institution.Name,
    category: getDisplayCategory(category),
    webflow: isWebflow(systemFlowSourceSystems, institution),
    targetSystem: institution.SourceSystem,
    institutionCode: institution.Code,
  };
};

const mapPolyclinic = (
  inst: InstitutionPayload,
  systemFlowSourceSystems: string[] | null,
  polylclinic: PolyclinicPayload,
): Institution => {
  return {
    displayName: polylclinic.Name,
    category: getDisplayCategory(GROUPCODE_POLYCLINIC),
    webflow: isWebflow(systemFlowSourceSystems, polylclinic),
    targetSystem: polylclinic.SourceSystem,
    institutionCode: polylclinic.Code,
    parentInstitutionCode: inst.Code,
  };
};

const getDisplayCategory = (groupCode: InstitutionGroupCode) => {
  switch (groupCode) {
    case GROUPCODE_HOSPITAL:
      return DISPLAY_CATEGORY_HOSPITAL;
    case GROUPCODE_POLYCLINIC:
      return DISPLAY_CATEGORY_POLYCLINICS;
    case GROUPCODE_SPECIALTY_CENTRES:
      return DISPLAY_CATEGORY_SPECIALTY_CENTRES;
    default:
      return "";
  }
};

/**
 * Determines if this institution's source system is contained in the list of sources where
 * this patient's profile can be found in.
 *
 * If a particular institution's source system is in this list, then an appointment can be made
 * using the system flow (allow slots selection), if not, it must be made using the form flow.
 *
 * @param {string[]} systemFlowSourceSystems  List of source systems that this patient's profile can be found in.
 * @param {InstitutionPayload | PolyclinicPayload} institution  Institution object to check
 *
 * @returns {boolean}  True if Web Flow (in-system flow) can be proceeded with after selecting this institution,
 *   False otherwise which would mean that the user would need to go with the Form Flow.
 */
const isWebflow = (
  systemFlowSourceSystems: string[] | null,
  institution: InstitutionPayload | PolyclinicPayload,
) => {
  if (
    systemFlowSourceSystems &&
    systemFlowSourceSystems.includes(institution.SourceSystem)
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * An institution that's not a polyclinic cluster is one that doesn't have a nested
 * list of polyclinic objects.
 *
 * @param {InstitutionPayload} institution  An Institution object from the remote API
 */
const isNationalUniversityPolyclinicEntity = (
  institution: InstitutionPayload,
) => {
  return institution.Polyclinics && institution.Polyclinics.length >= 1;
};

export { mapApiDataToProps };
