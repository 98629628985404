import { Theme } from "@mui/material";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = (theme: Theme) => {
  return {
    bannerTitle: {
      marginLeft: 2,
      paddingTop: 2.5,
      paddingBottom: 2.5,
      typography: theme.typography.header4_semibold,
      color: theme.palette.text.primary,
    },
    image: {
      width: "100%",
    },
    bannerTextBody: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      margin: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
    },
  } satisfies SxObjType;
};

export { sxStyles };
