import { Card } from "@mui/material";
import { CardWrapperProps } from "./CardWrapper.types";
import { sxStyles } from "./CardWrapper.styles";

const CardWrapper = ({
  isLinkedAppointmentStyled = false,
  children,
}: CardWrapperProps) => {
  const classes = sxStyles(isLinkedAppointmentStyled);
  return <Card sx={classes.card}>{children}</Card>;
};

export default CardWrapper;
