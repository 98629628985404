import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { GET_MOST_RECENT_MEDICATION_SUMMARY_URL } from "api/resources/configs/URL";
import { formatGetMostRecentMedicationSummaryErrorMessage } from "api/resources/messages/ErrorMessage";
import { GetMostRecentMedicationSummaryPayload } from "./getMostRecentMedicationSummary.fromApi.types";

/**
 * Fetches the most recent Medication Summary
 *
 * POST
 * Endpoint: /getMostRecentMedicationSummary
 *
 * @param {string | null} memberIdentifier Identitify to reflect relationship between requestor and patient for Caregiver Integration
 */
const getMostRecentMedicationSummary = async (
  memberIdentifier: string | null,
  oldMemberIdentifier: string | null,
  oldTokenizedId: string | null,
): Promise<GetMostRecentMedicationSummaryPayload> => {
  try {
    const response = await axios.post<GetMostRecentMedicationSummaryPayload>(
      GET_MOST_RECENT_MEDICATION_SUMMARY_URL(),
      createRequestPayload(
        memberIdentifier,
        oldMemberIdentifier,
        oldTokenizedId,
      ),
      getRequestOptions(),
    );
    return parseResponse(response, GET_MOST_RECENT_MEDICATION_SUMMARY_URL());
  } catch (error) {
    const msg = formatGetMostRecentMedicationSummaryErrorMessage(
      GET_MOST_RECENT_MEDICATION_SUMMARY_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  memberIdentifier: string | null,
  oldMemberIdentifier: string | null,
  oldTokenizedId: string | null,
) => {
  return {
    Cluster: null,
    MemberIdentifier: memberIdentifier,
    OldMemberIdentifier: oldMemberIdentifier,
    OldTokenizedId: oldTokenizedId,
  };
};

export { getMostRecentMedicationSummary };
