import React from "react";
import { sxStyles } from "./MultiLineTextField.styles";
import { TextField } from "@mui/material";
import { MultiLineTextFieldProps } from "./MultiLineTextField.types";

const MultiLineTextField = ({
  name,
  value,
  placeholder,
  rowNumber,
  maxNumber,
  maxCharLength,
  handleChange,
}: MultiLineTextFieldProps) => {
  const classes = sxStyles();
  return (
    <TextField
      minRows={rowNumber}
      maxRows={maxNumber}
      multiline
      fullWidth
      variant="outlined"
      // placeholder={placeholder}
      name={name}
      label={placeholder}
      value={value}
      onChange={handleChange}
      sx={classes.multilineTextField}
      InputLabelProps={{
        sx: classes.textFieldLabel,
      }}
      onInput={(e) => {
        if (maxCharLength !== undefined) {
          const element = e.target as HTMLInputElement;
          element.value = element.value.slice(0, maxCharLength);
        }
      }}
      InputProps={{
        notched: false,
      }}
      inputProps={{
        spellCheck: false,
      }}
    />
  );
};

export default MultiLineTextField;
