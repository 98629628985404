import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardActions,
} from "@mui/material";
import { sxStyles } from "./CompactCard.styles";
import { CompactCardProps } from "./CompactCard.types";
import IMAGES from "lib/assets/images";

const CompactCard = ({
  title,
  titleBold = true,
  titleIcon,
  body,
  showChevron = true,
  titleIconSize,
  onClickNextIcon,
  onClickCard,
}: CompactCardProps) => {
  const classes = sxStyles(titleBold, titleIconSize);

  const handleClickNextIcon = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation(); // stopPropagation prevents the parent component's event from bubbling
    if (onClickNextIcon) {
      onClickNextIcon();
    } else if (onClickCard) {
      onClickCard();
    }
  };

  const handleClickCard = () => {
    onClickCard && onClickCard();
  };

  return (
    <Card sx={classes.card} onClick={handleClickCard}>
      <CardActionArea sx={classes.actionArea}>
        <CardContent sx={classes.content}>
          <Box sx={classes.leftContainer}>
            <Box
              component={"img"}
              sx={classes.icon}
              src={titleIcon}
              alt="card icon"
              loading="lazy"
            />
            <Box sx={classes.textContainer}>
              {title && <Typography sx={classes.title}>{title}</Typography>}
              {body && <Typography sx={classes.body}>{body}</Typography>}
            </Box>
          </Box>

          {showChevron && (
            <Box sx={classes.chevronContainer}>
              <CardActions sx={classes.iconCardActions}>
                <Box component="span" onClick={handleClickNextIcon}>
                  <Box
                    component={"img"}
                    src={IMAGES.general.ArrowForward}
                    aria-label="ArrowForwardIcon"
                  />
                </Box>
              </CardActions>
            </Box>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CompactCard;
