import { appUrl } from "lib/configs";
import { stripLeadingSlash } from "lib/util/StringUtil/stripLeadingSlash/stripLeadingSlash";
import { setRedirectToExternalFlag } from "../externalRedirection/externalRedirection";
import { SYSTEM_DEVICE_PLATFORM_ANDROID } from "lib/mobileIntegration/constants";
import { store } from "lib/redux/root";
import { ACTIONS } from "..";

const REACT_APP_URL_LOCATION = appUrl();

/**
 * === NOTE ===
 * DO NOT USE IF YOU ARE USING CLIENT-SIDE ROUTING WITH REACT-ROUTER.
 * The navigation function here "reloads" the web application which means that
 * it actually makes a web call to the specified URL and renders the page, which
 * resets any variables you accumulated during a session.
 *
 * === DESCRIPTION ===
 * Navigates to a specific page from the current window's URL.
 *
 * Uses window.location.assign() to perform the document load,
 * so that the history of the browser is still preserved.
 *
 * This method is used so that the mobile client that embeds this
 * web app into a WebView can control page navigation natively.
 *
 * === USAGE ===
 *
 * Navigate using path without /
 *     navigate("appointmentlist");
 *
 * Navigate using path with /
 *     navigate("/appointmentlist");
 *
 * Navigate using path defined in routes dictionary
 *     navigate(PATHS.ROOT.path);
 *
 * === SECURITY NOTES ===
 *  * Client DOM Open Redirect
 *      * A configured (hard-coded) URL, obtained from environment vars, is used instead
 *        of the window's origin. This helps to mitigate arbitrary URL redirection.
 *
 * @param {string} subPath The sub-path to navigate to
 * @param {boolean} toAddSlashPrefix whether to add slash as prefix to the subpath
 */
export const mobileNavigate = (subPath: string, slash?: boolean) => {
  const URL =
    `${REACT_APP_URL_LOCATION}${slash ? "/" : "?"}` +
    stripLeadingSlash(subPath); // to normalize

  if (window.location.href.includes("localhost")) {
    console.log("Mobile action: ", URL);
  } else {
    window.location.assign(URL);
  }
};

/**
 * "If the device is an Android device, then navigate to the DISABLE_BACK_BUTTON_ACTION."
 *
 * The DISABLE_BACK_BUTTON_ACTION is a special action that is handled by the Android native code
 */
export const disableBackButton = () => {
  // TODO: add disable back for both ios and android through message channel
  if (window.location.href.includes("localhost")) {
    console.log("Disable back button");
  }
  // const isAndroidDevice =
  //   store.getState().system.devicePlatform === SYSTEM_DEVICE_PLATFORM_ANDROID;
  // if (isAndroidDevice) {
  //   mobileNavigate(ACTIONS.DISABLE_BACK_BUTTON_ACTION);
  // }
};

/**
 * If the device is an Android device, then enable the back button.
 */
export const enableBackButton = () => {
  const isAndroidDevice =
    store.getState().system.devicePlatform === SYSTEM_DEVICE_PLATFORM_ANDROID;
  if (isAndroidDevice) {
    mobileNavigate(ACTIONS.ENABLE_BACK_BUTTON_ACTION);
  }
};

/**
 * Providing an external link in plain string format,
 * this method will first set a redirection indicator flag in session storage,
 * then Mobile will redirect to the given link within the same webview in app.
 * This will render with a X within a blue circle at the top right corner
 * @param externalLink (complete) external link to redirect to
 * @param url url for mobile client to redirect to if CLOSE button framed with web view is clicked
 */
export const navigateToExternalInAppWebView = (
  externalLink: string,
  url?: string | null,
  isWithAction = false,
) => {
  setRedirectToExternalFlag();
  if (isWithAction) {
    let toBeRedirected = appendQueryParam(
      externalLink,
      ACTIONS.SHOW_CLOSE_ACTION,
    );
    if (url) {
      toBeRedirected = `${toBeRedirected}&url=${stripLeadingSlash(url)}`;
    }
    window.location.assign(toBeRedirected);
  } else {
    window.location.assign(externalLink);
  }
};

/**
 * Providing an external link in plain string format,
 * this method will change it to a link that mobile is able to intercept and redirect.
 * Mobile will open the link in a mobile browser without refreshing the app webview page.
 *
 * @param externalLink (complete) external link for mobile to intercept
 */
export const navigateToExternalInMobileBrowser = (
  externalLink: string,
  inApp?: boolean | null,
) => {
  if (externalLink.includes(ACTIONS.EXTERNAL_LINK_INTERCEPTION)) {
    window.location.assign(externalLink.trim()); // whitespaces will render as %20 on mobile url and will break navigation
  } else {
    let interceptionUrl = appendQueryParam(
      externalLink.trim(),
      ACTIONS.EXTERNAL_LINK_INTERCEPTION,
    );
    if (inApp === false) {
      interceptionUrl = `${interceptionUrl}&inapp=0`;
    }
    window.location.assign(interceptionUrl);
  }
};

/**
 * The function `navigateToExternalInMobileBrowserForStandardPage` is used to open an external link in
 * a mobile browser with specific parameters.
 * @param {string} externalLink - The externalLink parameter is a string that represents the URL of the external page you want to navigate to in the mobile browser.
 * @param {"ss" | "hh"} target - The "target" parameter is used to determine the type of standard page to open the external link in. It can have two possible values: ss or hh
 * @param {string} [backUrl] - The `backUrl` parameter is an optional parameter that represents the URL to which the user will be redirected after they finish interacting with the external link.
 */
export const navigateToExternalInMobileBrowserForStandardPage = (
  externalLink: string,
  target: "ss" | "hh",
  backUrl?: string,
) => {
  if (!externalLink.includes(ACTIONS.STANDARD_PAGE_INTERCEPTION)) {
    externalLink = appendQueryParam(
      externalLink,
      ACTIONS.STANDARD_PAGE_INTERCEPTION,
    );
  }

  if (backUrl) {
    externalLink = appendQueryParam(externalLink, `url=${backUrl}`);
  }

  if (target === "ss") {
    externalLink = appendQueryParam(externalLink, ACTIONS.SS_DATASERVICES);
  } else if (target === "hh") {
    externalLink = appendQueryParam(externalLink, ACTIONS.HHNG_STANDARD);
  }

  window.location.assign(externalLink);
};

/**
 * A helper function that takes a query param and appends it to a URL.
 *
 * Pre-condition:
 *   You must have "http://" or "https://" as the prefix of the URL
 *
 * Example:
 *   URL         = "http://foo.bar/?x=1&y=2");
 *   Query Param = "x=42"
 * Then your expected result is "http://foo.bar/?x=1&y=2&x=42"
 *
 * @param {string} url URL to append to
 * @param {string} queryParam A set of query params: a=b
 *
 * @returns {string} Appended URL
 */
export const appendQueryParam = (url: string, queryParam: string) => {
  if (!url || !queryParam) {
    return url;
  }

  if (url.includes("?")) {
    // already contains query param, e.g. "http://foo.bar/?x=1&y=2"
    return `${url}&${queryParam}`;
  } else {
    return `${url}?${queryParam}`;
  }
};
