import { Theme } from "@mui/material";
import { color } from "lib/theme/color";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = (theme: Theme) => {
  return {
    mainContainer: {
      mx: 2,
      mb: 2,
    },
    subtitile: {
      typography: "header4_semibold",
      color: theme.palette.text.primary,
    },

    disclaimer: {
      paddingTop: 0.5,
      paddingBottom: 1,
      color: theme.palette.grey[400],
      typography: "body2_regular",
    },

    textFieldMainContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      marginTop: 3,
    },
    textField: {
      width: "80%",
    },
    iconContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "20%",
      marginTop: 0.5,
    },

    deleteIcon: {
      width: "24px",
      justifySelf: "end",
    },
    removeText: {
      typography: "caption2_semibold",
      textAlign: "center",
      color: theme.palette.primary.main,
    },
    queryIcon: {
      width: "20px",
      justifySelf: "end",
      marginTop: 1,
    },
    preferredCard: {
      width: "80%",
      boxShadow: "0px 0px 6px " + color.RGBA_52,
      backgroundColor: "#FFFFFF",
      borderRadius: 0,
    },
    preferredContentContainer: {
      my: 2,
      mx: 1.25,
    },
    preferredMailTitle: {
      typography: "body1_semibold",
      color: theme.palette.grey[400],
      mb: 1,
    },
    preferredMailBlock: {
      mb: 2,
      color: theme.palette.grey[400],
    },
    preferredMailLabel: {
      typography: "body2_regular",
      color: theme.palette.grey[400],
    },
    preferredMailValue: {
      typography: "body1_semibold",
      color: theme.palette.grey[400],
    },
    textContent: {
      typography: "body2_regular",
      color: theme.palette.grey[400],
      mb: 1,
    },
    textButton: {
      typography: "body2_semibold",
      height: "unset",
      lineHeight: "unset",
      p: 0,
    },
  } satisfies SxObjType;
};
export { sxStyles };
