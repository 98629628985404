import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[600],
      "& .MuiSvgIcon-root": {
        fontSize: size.FONTSIZE_24,
      },
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      paddingBottom: theme.spacing(0.75),
    },
    cardContent: {
      color: theme.palette.grey[400],
      fontSize: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
    },

    textBox: {
      margin: theme.spacing(1.5, 0, 2.5),
      "& .MuiInputBase-root": {
        borderRadius: theme.spacing(0.5),
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.grey[100]}`,
        },
      },
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
      "& .MuiInputLabel-animated": {
        fontSize: size.FONTSIZE_16,
        color: theme.palette.primary.main,
      },
    },
    buttonWrapper: {
      display: "block",
      padding: theme.spacing(1, 0),
    },
    cardButtons: {
      margin: theme.spacing(1, 0),
      "& :hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    fullButton: {
      display: "block",
      width: "100%",
    },
    cancelButtons: {
      margin: theme.spacing(1.5, 0, 1),
      marginLeft: "0 !important",
    },
    cancelButton: {
      marginBottom: theme.spacing(1),
      minHeight: theme.spacing(6),
    },
  } satisfies SxObjType;
};

export { sxStyles };
