import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    autocompleteInput: {
      color: theme.palette.text.secondary,
      fontSize: size.FONTSIZE_16,
      borderRadius: size.BORDER_RADIUS,
      zIndex: 90,
    },
    textFieldRoot: {},
    textFieldLabel: {
      color: theme.palette.primary.main,
      typography: theme.typography.body1_regular,
    },
    errText: {
      color: theme.palette.error.main,
      typography: theme.typography.caption1_regular,
      padding: theme.spacing(0.5, 1.5),
    },
  } satisfies SxObjType;
};

export { sxStyles };
