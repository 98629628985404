import { Box, Typography, useTheme } from "@mui/material";
import { SelectionListCategoryProps } from "./SelectionListCategory.types";
import { sxStyles } from "./SelectionListCategory.styles";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { selectAppointmentsSystemSettings } from "ui/appointment/ducks/selectors";
import { useAppSelector } from "lib/redux/hooks";

const SelectionListCategory = ({ category }: SelectionListCategoryProps) => {
  const theme = useTheme();
  const classes = sxStyles(theme);

  const { values: apptSystemSettingsValues } = useAppSelector(
    selectAppointmentsSystemSettings,
  );

  return (
    <Box sx={classes.category}>
      <Typography sx={classes.categoryText}>{category}</Typography>

      {category.toLocaleLowerCase() === "polyclinics" ? (
        <Box sx={{ mt: 2 }}>
          <SharpNoticePanel bgColor="warn" showIcon>
            {MapRawStringToReactElement(
              apptSystemSettingsValues["NUPSDASlotMessage"].Value || "",
            )}
          </SharpNoticePanel>
        </Box>
      ) : null}
    </Box>
  );
};

export default SelectionListCategory;
