import { useState } from "react";
import { Box, Button } from "@mui/material";
import { sxStyles } from "./PrescriptionRecordsSection.styles";
import ShowMoreLessPanel from "lib/components/notice/ShowMoreLessPanel/ShowMoreLessPanel";
import { PrescriptionRecordsSectionProps } from "./PrescriptionRecordsSection.types";
import UserConsentModal from "lib/components/modals/ConfirmationModal/UserConsentModal/UserConsentModal";
import IMAGES from "lib/assets/images";
import { navigateToExternalInMobileBrowserForStandardPage } from "lib/routing/navigate/navigate";
import { prescribedMedicationUrl } from "lib/configs";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";

const PrescriptionRecordsSection = ({
  prescribedMedicationConsentFlag,
}: PrescriptionRecordsSectionProps) => {
  const classes = sxStyles();

  const [openConsentModal, setOpenConsentModal] = useState(false);
  const handleCloseConsentModal = () => setOpenConsentModal(false);

  const handlePrescribedMedicationClick = () => {
    // if consent is given, redirect to prescribed medication page
    if (prescribedMedicationConsentFlag) {
      const url = prescribedMedicationUrl();
      navigateToExternalInMobileBrowserForStandardPage(url, "ss", url);
    }
    // open modal to indicate consent is not given
    else {
      setOpenConsentModal(true);
    }
  };

  return (
    <>
      <Box sx={{ my: 2 }}>
        <SharpNoticePanel bgColor="warn" showIcon>
          <ShowMoreLessPanel
            message={`Medication prescribed during video consultation (VC) will be delivered in full quantity. Please indicate in "Special Instructions" if you do not require all VC prescribed medication.`}
            displayShowMoreInNewLine={true}
          ></ShowMoreLessPanel>
        </SharpNoticePanel>
      </Box>

      <Box sx={classes.linkbox}>
        <Button
          variant="text"
          sx={classes.button}
          onClick={handlePrescribedMedicationClick}
        >
          Prescription records
          <Box
            component={"img"}
            src={IMAGES.general.ArrowRightIcon}
            sx={classes.icon}
          />
        </Button>
      </Box>

      <UserConsentModal
        open={openConsentModal}
        onClickNext={handleCloseConsentModal}
      ></UserConsentModal>
    </>
  );
};

export default PrescriptionRecordsSection;
