import { connect } from "react-redux";
import {
  setViewPastDetail,
  setViewPastDetailsHasErrored,
} from "ui/medrefill/ducks/medrefillSlice";
import { RootState, RootDispatch } from "lib/redux/root/redux.types";
import { fetchMedicationSummaryDetail } from "ui/medrefill/ducks/thunks";
import VirtualConsultViewPastRequestDetailPage from "./VirtualConsultViewPastRequestDetailPage";
import { mapPastRequestVirtualConsultDetailToMedrefillState } from "lib/util/MedrefillMappingUtil/mapPastRequestVirtualConsultDetailToMedrefillState";
import { initialState as medRefillInitialState } from "ui/medrefill/ducks";

const mapStateToProps = (state: RootState) => {
  return {
    isLoadingPastDetail: state.medRefill.viewPastRequest.isLoading,

    hasErroredViewPastRequest: state.medRefill.viewPastRequest.hasErrored,
    errorMessageViewPastRequest: state.medRefill.viewPastRequest.errorMessage,

    pastRequestHistory: mapPastRequestVirtualConsultDetailToMedrefillState(
      state.medRefill.viewPastRequest.detail,
    ),
  };
};

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    onLoad: () => {
      dispatch(setViewPastDetail(medRefillInitialState.viewPastRequest.detail));
      dispatch(setViewPastDetailsHasErrored(null));

      dispatch(fetchMedicationSummaryDetail());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VirtualConsultViewPastRequestDetailPage);
