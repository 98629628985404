import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";
import { RootState } from "lib/redux/root/redux.types";
import {
  MedRefillPrescriptionState,
  MedRefillSystemSetting,
} from "ui/medrefill/ducks/medrefill.types";
import { updatePrescription } from "ui/medrefill/ducks/medrefillSlice";
import { IterableElement } from "lib/data/data.types";
import TopupPrescription from "./TopupPrescription";
import { setCustomisedBack } from "lib/redux/navigation";

const mapper = (
  settings: MedRefillSystemSetting[] | undefined,
  sort: boolean,
): IterableElement[] => {
  if (settings === undefined) {
    return [];
  } else {
    let mapped = settings.map(
      (setting): IterableElement => ({
        id: setting.value || 0,
        text: setting.displayValue || "",
      }),
    );
    if (sort) {
      mapped = mapped.sort((x, y) => (x.text < y.text ? -1 : 1));
    }
    return mapped;
  }
};

const mapStateToProps = (state: RootState) => {
  return {
    defaultPrescribingClinic: state.medRefill.institution.clinicId,
    defaultReasonForTopup: state.medRefill.prescription.topupReason,
    defaultTopupOtherReason: state.medRefill.prescription.topupOtherReason,
    prescriptionState: state.medRefill.prescription,
    institutionState: state.medRefill.institution,
    medicationList: state.medRefill.medications.medicationList,
    clinicList: state.medRefill.clinics.clinicList.map((clinicObject) => {
      return {
        id: clinicObject.ClinicId,
        text: clinicObject.ClinicName,
      };
    }),

    documentList: state.medRefill.documentByCluster.documentByClusterList.map(
      (documentObject) => {
        return {
          type: documentObject.Type,
          content: documentObject.Contents,
        };
      },
    ),

    durationList: mapper(
      state.medRefill.systemSettings.lookup.get("DURATION"),
      false,
    ),
    uomList: mapper(state.medRefill.systemSettings.lookup.get("UOM"), true),
    topupReasonList: mapper(
      state.medRefill.systemSettings.lookup.get("UI-TOPUPPRESCRIPTIONREASON"),
      false,
    ),
    defaultMedicationCardDataList: state.medRefill.prescription.files.map(
      (file) => {
        return {
          fileName: file.front.fileName,
          fileData: file.front.fileData,
          medicationName: file.topUp.medicationName,
          quantity: file.topUp.quantity === null ? null : file.topUp.quantity,
          UOMId: file.topUp.UOMId,
          UOM: file.topUp.UOM,
          quantityValidationMsg: null,
          cardValidationMsg: null,
        };
      },
    ),
    preventDirectBackNavigationState: state.navigation.customisedBack,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onSelectPrescription: (
      prescriptionState: MedRefillPrescriptionState & {
        clinicId: number;
      },
    ) => {
      dispatch(updatePrescription(prescriptionState));
    },
    resetPreventDirectBackNavigationState: () => {
      dispatch(setCustomisedBack(false));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopupPrescription);
