import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    page: {
      zIndex: 100,
    },

    tickImage: {
      marginTop: theme.spacing(1.5),
      textAlign: "center",
    },
    tickIcon: {
      fontSize: size.FONTSIZE_20,
      color: theme.palette.primary.main,
      width: theme.spacing(2.5),
    },
    initialText: {
      paddingTop: theme.spacing(1.5),
      background: "none",
      color: theme.palette.primary.main,
      typography: theme.typography.body2_semibold,
      height: theme.spacing(12),
      textAlign: "center",
    },
    releaseBox: {
      color: theme.palette.primary.main,
      typography: theme.typography.body2_semibold,
      paddingTop: theme.spacing(1.5),
      textAlign: "center",
      backgroundColor: theme.palette.background.paper,
      height: theme.spacing(12),
      borderBottomLeftRadius: "50%",
      borderBottomRightRadius: "50%",
    },
    pullToRefresh: {
      color: theme.palette.primary.main,
      typography: theme.typography.body2_semibold,
      margin: theme.spacing(2.75, "auto"),
      display: "flex",
      alignItems: "center",
      "& .MuiSvgIcon-root": {
        fontSize: size.FONTSIZE_18,
        marginRight: theme.spacing(0.1),
      },
      width: "100%",
      "& div": {
        width: "100%",
        textAlign: "center",
        margin: theme.spacing("auto", 1),
      },
      position: "absolute",
      zIndex: 10,
    },
    line: {
      borderTop: `2px solid ${theme.palette.grey[100]}`,
      borderRadius: theme.spacing(1.25),
    },
  } satisfies SxObjType;
};

export { sxStyles };
