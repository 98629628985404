import { color } from "lib/theme/color";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    mainContainer: { padding: theme.spacing(2) },
    statusContainer: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1),
    },
    icon: {
      width: "46px",
    },
    title: {
      color: color.TEXT_PRIMARY,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(1, 0, 0.5),
      marginLeft: theme.spacing(1.5),
    },
    noticeContainer: { marginTop: theme.spacing(2.5) },
    
    
  } satisfies SxObjType;
};

export { sxStyles };
