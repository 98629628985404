import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    layout: {
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(0.75, 2),
    },
    flex: {
      display: "flex",
    },
    label: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    text: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      lineHeight: 1.5,
    },
    textArea: {
      // need a width here so that long text will not push into the space of
      // the label on the left
      width: "75%",
    },
  } satisfies SxObjType;
};

export { sxStyles };
