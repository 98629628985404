import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    noRecordBox: {
      textAlign: "center",
      margin: theme.spacing(2, 0, 4),
    },
    noRecordText: {
      color: theme.palette.grey[600],
      typography: theme.typography.body2_semibold,
    },
    noRecordImage: {
      marginBottom: theme.spacing(1),
      height: "200px",
    },
    leftSkeletonContainer: {
      display: "flex",
      flexDirection: "column",
      padding: 1,
      marginRight: 1.25,
    },
    leftSkeleton: { width: 40 },
    rightSkeletonContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      padding: 1,
    },
    rightSkeleton: { width: "40%" },
  } satisfies SxObjType;
};

export { sxStyles };
