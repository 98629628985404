import { useCallback, useMemo, useState } from "react";
import { sxStyles } from "./CancelAppointmentPage.styles";
import { CONNECT_TO_LIVE_AGENT_BUTTON_TEXT } from "lib/components/navigation/AppRouteRenderer/ErrorModal/ErrorModal";
import { useLocation, useNavigate } from "react-router-dom";
import { ACTIONS, PATHS } from "lib/routing";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectAppointmentMeta } from "ui/appointment/ducks/selectors";
import { selectMemberIdentifier } from "lib/redux/user/selectors";
import { cancelAppointment } from "api/appointment/CancelAppointment/cancelAppointment";
import { handleErrorModal as handleErrorModalAction } from "lib/redux/notifications";
import IMAGES from "lib/assets/images";
import { Box, Typography } from "@mui/material";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import Page from "lib/components/layout/Page/Page";
import CancelAppointmentPageSkeleton from "./CancelAppointmentPageSkeleton";
import CancelAppointmentConfirmedModal from "../appointmentList/CancelAppointmentModals/CancelAppointmentConfirmedModal";
import { fetchAppointments } from "ui/appointment/ducks";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import PreCancelRescheduleModal from "../common/PreCancelRescheduleModal/PreCancelRescheduleModal";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { AxiosError } from "axios";

// Constants
const TITLE_TEXT = "Cancel Appointment";
const BODY_TEXT =
  "Should you require another appointment, please book a new appointment by returning to 'Home' and tap 'New'. We will not automatically schedule a new appointment for you.";
const NEXT_BUTTON_TEXT = "Back";
const CANCEL_BUTTON_TEXT = "Proceed to cancel";

const CancelAppointmentPage = () => {
  const classes = sxStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  // redux states
  const { linkedAppointments, institutionCode, appointmentId } = useAppSelector(
    selectAppointmentMeta,
  );
  const memberIdentifier = useAppSelector(selectMemberIdentifier);

  /* A memoized function that returns an array of appointment ids. */
  const appointmentIds = useMemo(
    () =>
      linkedAppointments.length > 0
        ? [
            appointmentId ?? "",
            ...linkedAppointments.map((appt) => appt.appointmentId ?? ""),
          ]
        : [appointmentId ?? ""],
    [appointmentId, linkedAppointments],
  );

  // Local states
  const [isCancelling, setIsCancelling] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);
  const [isPreConfirmationOpen, setIsPreConfirmationOpen] = useState(true);

  const handleErrorModal = useCallback(
    (open: boolean, message: string | null, buttonText?: string | null) => {
      dispatch(handleErrorModalAction({ open, message, buttonText }));
    },
    [dispatch],
  );

  const handlePreconfirmationClose = () => {
    logEventToGoogleAnalytics(EVENTS.CLICK_APPT_CANCEL_HEALTHMATTERS_PROCEED);
    setIsPreConfirmationOpen(!isPreConfirmationOpen);
  };

  const handleOnBack = () => {
    location.pathname === PATHS.APPOINTMENT_CANCEL_MOBILE.path
      ? mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH)
      : navigate(-1);
  };

  const handleOnBackPreCancel = () => {
    logEventToGoogleAnalytics(EVENTS.CLICK_APPT_CANCEL_HEALTHMATTERS_BACK);
    location.pathname === PATHS.APPOINTMENT_CANCEL_MOBILE.path
      ? mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH)
      : navigate(-1);
  };

  const sendCancelAppointmentRequest = useCallback(async () => {
    try {
      // Make the cancel request
      setIsCancelling(true);
      setIsButtonsDisabled(true);
      await cancelAppointment({
        memberIdentifier,
        appointmentIds,
        institutionCode,
      });

      setIsConfirmationOpen(!isConfirmationOpen);
    } catch (error) {
      setIsButtonsDisabled(false);

      if (error instanceof AxiosError) {
        handleErrorModal(
          true,
          error.response?.data.Message,
          error.response?.data.IsLiveChatEnabled
            ? CONNECT_TO_LIVE_AGENT_BUTTON_TEXT
            : undefined,
        );
      } else {
        handleErrorModal(true, null);
      }
    } finally {
      setIsCancelling(false);
    }
  }, [
    appointmentIds,
    handleErrorModal,
    institutionCode,
    isConfirmationOpen,
    memberIdentifier,
  ]);

  return (
    <Page>
      <Box sx={classes.mainContainer} aria-label="main-container">
        <CancelAppointmentPageSkeleton isCancelling={isCancelling} />
        <Box sx={classes.topContainer}>
          <Box sx={classes.errorImageContainer}>
            <Box
              component={"img"}
              sx={classes.errorImage}
              src={IMAGES.general.ErrorIcon}
              alt="Error icon"
            />
          </Box>

          <Typography variant="header4_semibold" sx={classes.titleText}>
            {TITLE_TEXT}
          </Typography>

          <Typography variant="body2_regular" sx={classes.bodyText}>
            {BODY_TEXT}
          </Typography>
        </Box>

        <Box sx={classes.buttonsContainer}>
          <ButtonsFooter
            nextButtonText={NEXT_BUTTON_TEXT}
            cancelButtonText={CANCEL_BUTTON_TEXT}
            isDisabled={isButtonsDisabled}
            onClickNext={handleOnBack}
            onClickCancel={sendCancelAppointmentRequest}
          />
        </Box>

        <PreCancelRescheduleModal
          isOpen={isPreConfirmationOpen}
          onClickFirstButton={handleOnBackPreCancel}
          onClickSecondButton={handlePreconfirmationClose}
          type={"cancel"}
        />

        {/* Modal for Cancel Appointment Flow - Cancel has been confirmed */}
        <CancelAppointmentConfirmedModal
          open={isConfirmationOpen}
          onCancel={() => {
            setIsConfirmationOpen(!isConfirmationOpen);
            dispatch(fetchAppointments());
            navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
          }}
        />
      </Box>
    </Page>
  );
};

export default CancelAppointmentPage;
