import { parse, parseISO } from "date-fns";
import { Location } from "api/medrefill/GetLocations/getLocations.types";

// =====================
// INDIVIDUAL DISABLED DATES
// =====================

/**
 * Resolves dates from API payload containing some dates which we should disallow the
 * user from selecting, e.g. holidays, non-working days...
 *
 * Considers the minimum turnover days and maximum collection date this location is able to accommodate.
 *
 * @param {Location} locationObj  The location object that contains the constraints which
 *   will define which days we should disable
 *
 * // param in inner function
 * @param {MaterialUiPickersDate} datepickerDate  Individual dates in the datepicker
 *
 * @return {(date: Date) => boolean}
 *   A function that determines how dates should be disabled in the Datepicker using the
 *   Material UI Datepicker's shouldDisableDates prop. This function is automatically compatible with it.
 *   Function returns TRUE if this date should be disabled, FALSE otherwise.
 */
const computeDisabledDates =
  (locationObj: Location) => (datepickerDate: Date) => {
    if (datepickerDate) {
      // --- Get Reference Lists
      const workingDays = getWorkingDaysList(locationObj);
      const publicHolidays = getPublicHolidayDatesList(locationObj);

      // --- Perform Checks
      const isNonWorkingDay = !workingDays.includes(datepickerDate.getDay());
      const isPublicHoliday = checkIfIsPublicHoliday(
        datepickerDate,
        publicHolidays,
      );

      return isNonWorkingDay || isPublicHoliday;
    } else {
      // unlikely, but still allow this date to passthrough
      return false;
    }
  };

const getNextAvailableDay = (locationObj: Location, today: Date) => {
  // --- Get Reference Lists
  const workingDays = getWorkingDaysList(locationObj);
  const publicHolidays = getPublicHolidayDatesList(locationObj);

  today.setDate(today.getDate() + 1);
  while (
    !workingDays.includes(today.getDay()) ||
    checkIfIsPublicHoliday(today, publicHolidays)
  ) {
    today.setDate(today.getDate() + 1);
  }
  return today;
};

// =====================
// MIN / MAX DAYS (w/ CutOff Time)
// =====================

/**
 * Computes the minimum date accepted by the Datepicker.
 * Defined by the no. of turnover days as well as whether the current date today
 * already exceeded the cutoff time.
 *
 * @param {Location} locationObj  The location object that contains the constraints which
 *   will define which days we should disable
 * @param {Date} date  Today's date
 *
 * @returns {Date}  Minimum date for which the user will be allowed to select a date.
 *   If no turnover days are provided, we assume that the min date starts from today.
 *   If no cut off times are provided, we assume that cut off time considerations will
 *   not be taken into account.
 */
const getMinDate = (locationObj: Location, today: Date): Date => {
  let day = cloneDate(today);
  const turnoverDays = getTurnoverDay(locationObj);
  const cutOffTime = getCutOffTime(locationObj, day);

  if (day > cutOffTime) {
    day = getNextAvailableDay(locationObj, day);
  }

  // add turnover days
  for (var i = 0; i < turnoverDays; i++) {
    day = getNextAvailableDay(locationObj, day);
  }
  day = getNextAvailableDay(locationObj, day);
  return day;
};

/**
 * Computes the maximum date accepted by the Datepicker.
 * Defined as the no. of "max" collection days from the minimum date allowed.
 *
 * @param {Location} locationObj  The location object that contains the constraints which
 *   will define which days we should disable
 * @param {Date} date  Today's date
 *
 * @returns {Date | null}  Maximum date for which the user will be allowed to select a date.
 *   If no maximum collection day is provided, then it is assumed that there is no maximum no. of
 *   days, and any day in the future can be selected.
 */
const getMaxDate = (locationObj: Location, today: Date): Date | null => {
  const day = cloneDate(today);
  const minDate = getMinDate(locationObj, day);
  const maxDays = getMaxCollectionDay(locationObj);
  let maxDate = minDate;

  if (maxDays) {
    // add maximum collection days
    for (var i = 1; i < maxDays; i++) {
      maxDate = getNextAvailableDay(locationObj, maxDate);
    }
    return maxDate;
  } else {
    // there's no maximum
    return null;
  }
};

// =====================
// GETTERS
// =====================

const getTurnoverDay = (locationObj: Location) => {
  const DEFAULT = 0;
  try {
    if (locationObj.TurnOverDay) {
      const parsed = parseInt(locationObj.TurnOverDay);

      if (parsed) {
        // truthy, valid value
        return parsed;
      } else {
        // falsy values, e.g. NaN
        return DEFAULT;
      }
    } else {
      return DEFAULT;
    }
  } catch (error) {
    // Invalid text that cannot be parsed to an integer
    return DEFAULT;
  }
};

const getMaxCollectionDay = (locationObj: Location) => {
  const DEFAULT = null;
  try {
    if (locationObj.MaxCollectionDay) {
      const parsed = parseInt(locationObj.MaxCollectionDay);

      if (parsed) {
        // truthy, valid value
        return parsed;
      } else {
        // falsy values, e.g. NaN
        return DEFAULT;
      }
    } else {
      return DEFAULT;
    }
  } catch (error) {
    // Invalid text that cannot be parsed to an integer
    return DEFAULT;
  }
};

const CUTOFF_TIME_FORMAT = "HH:mm:ss"; // from source system
const getCutOffTime = (locationObj: Location, today: Date) => {
  const DEFAULT = new Date();
  DEFAULT.setHours(23, 59, 59); // current day, late midnight (as if there's no cutoff time)

  try {
    if (locationObj.CutOffTime) {
      const cutoffTime = parse(
        locationObj.CutOffTime,
        CUTOFF_TIME_FORMAT,
        new Date(), // just a reference date (required according to library)
      );

      // set to today's date, because this is just a timestamp
      // set to today's date, because this is just a timestamp
      const cutoffDate = cloneDate(today);
      cutoffDate.setHours(cutoffTime.getHours());
      cutoffDate.setMinutes(cutoffTime.getMinutes());
      cutoffDate.setSeconds(cutoffTime.getSeconds());

      return cutoffDate;
    } else {
      return DEFAULT;
    }
  } catch (error) {
    // Invalid text that cannot be parsed
    return DEFAULT;
  }
};

const getWorkingDaysList = (locationObj: Location): number[] => {
  const DEFAULT: number[] = [];
  if (locationObj.Days) {
    return locationObj.Days;
  } else {
    return DEFAULT;
  }
};

const getPublicHolidayDatesList = (locationObj: Location) => {
  const DEFAULT: Date[] = [];
  if (locationObj.isPublicHolidayAvailable && locationObj.PublicHolidays) {
    return locationObj.PublicHolidays.filter(
      (publicHoliday) => publicHoliday.DateOfEvent !== null,
    ).map((publicHoliday) => {
      return parseISO(publicHoliday.DateOfEvent);
    });
  } else {
    return DEFAULT;
  }
};

const checkIfIsPublicHoliday = (date: Date, publicHolidays: Date[]) => {
  const found = publicHolidays.find((pub) => {
    return (
      pub.getFullYear() === date.getFullYear() &&
      pub.getMonth() === date.getMonth() &&
      pub.getDate() === date.getDate()
    );
  });

  if (found) {
    return true;
  } else {
    return false;
  }
};

// =====================
// HELPERS
// =====================

/**
 * Clones the provided date object. Important as JavaScript's Date object is mutable
 * and its manipulation operations can accidentally mutate it in unexpected ways.
 *
 * It's good practice to always clone the today's date input param.
 *
 * @param {Date} date  Date to make a clone of
 *
 * @returns {Date} Deep Clone of the provided date object
 */
const cloneDate = (date: Date) => {
  return new Date(date.getTime());
};

export { computeDisabledDates, getMinDate, getMaxDate };
