import { useState, useEffect, useMemo } from "react";
import { PATHS } from "lib/routing";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { useNavigate } from "react-router-dom";
import { sxStyles } from "./SelectRequest.styles";
import { SelectRequestProps } from "./SelectRequest.types";
import { Summary } from "api/medrefill/GetMostRecentMedicationSummary/getMostRecentMedicationSummary.fromApi.types";
import { prescribedMedicationConsent } from "lib/util/StandardPageUtil/prescribedMedication";
import { useGetMostRecentMedicationSummary } from "ui/medrefill/hooks/useGetMostRecentMedicationSummary";
import IMAGES from "lib/assets/images";
import FooterIcons from "./FooterIcons/FooterIcons";
import {
  Box,
  Typography,
  CardHeader,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import Page from "lib/components/layout/Page/Page";
import MedicationCardContainer from "../../common/MedicationCard/MedicationCardContainer";
import { sameStringTest } from "lib/util/StringUtil/sameStringTest/sameStringTest";
import { shouldDisplayMedicationRecord } from "lib/util/MyChart/medicationRecord";
import toTitleCase from "lib/util/StringUtil/toTitleCase/toTitleCase";
import { prescribedMedicationUrl } from "lib/configs";
import { navigateToExternalInMobileBrowserForStandardPage } from "lib/routing/navigate/navigate";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { setConsentFlag } from "lib/redux/user";
import { selectUser } from "lib/redux/user/selectors";
import {
  setOldMemberIdentifier,
  setOldTokenizedId,
} from "lib/redux/user/userSlice";
import { useGetMessageAction } from "lib/routing/messageChannel/hooks/useGetMessageAction";

// Titles
const MEDICATION_TITLE = "Medication Requests";
const VIEW_PAST_TITLE = "View past requests/orders";
const OTHER_MED_TITLE = "Other medication services";
const NO_RECORDS_TITLE = "No medication requests";
const NO_RECORDS_BODY =
  "When you have any medication requests it will appear here.";

const SelectRequest = ({
  username,
  isLoadingUsername,
  memberIdentifier,
  relationType,
  isLoadingDetail,
  hasErroredDetail,
  errorMessageDetail,
  isLoadingImages,
  hasErroredImages,
  errorMessageImages,
  medrefillState,
  isLoadingFormData,
  hasErroredSystemSettings,
  errorMessageSystemSettings,
  hasErroredMedications,
  errorMessageMedications,
  hasErroredCollectionPaymentDetails,
  errorMessageCollectionPaymentDetails,
  hasErroredDocByCluster,
  errorMessageDocByCluster,
  hasErroredClinics,
  errorMessageClinics,
  isLoadingPrescribedMedicationConsent,
  prescribedMedicationConsentFlag,
  showMyChartMedication,

  clearPastData,
  onFetchingLatestRequestDetail,
  onFetchingLatestRequestImages,
  onFetchingPrescribedMedicationConsent,
  onReorder,
  onFetchingMyChartSystemSettings,
}: SelectRequestProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();
  const dispatch = useAppDispatch();

  // Redux states
  const { oldMemberIdentifier, oldTokenizedId } = useAppSelector(selectUser);

  // Local states
  const [pageFlag, setPageFlag] = useState(false);
  const [selectedRequestorId, setSelectedRequestorId] = useState<number>(0);

  const { messageActionObj, consumeMessageActionObj } = useGetMessageAction();

  useEffect(() => {
    // clear past detail and images cache only when loading
    clearPastData();

    // fetch user consent against prescribed medication standard page if it is family and friend flow (relationType === 'F')
    // only when it is family and friend flow, we need to fetch user consent to determine if prescribed medication should be display
    // for logged in user flow, no need to fetch consent as consent is implicitly given
    // for relationType 'C', prescribed medication consent is always given, thus true
    if (relationType === "F") {
      onFetchingPrescribedMedicationConsent();
    }

    if (relationType === "C") {
      dispatch(setConsentFlag(true));
    }

    // !exp fetch flag to display My Chart medication
    onFetchingMyChartSystemSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    dataRequest,
    isLoadingSummary,
    hasErroredSummary,
    errorMessageSummary,
    oldMemberIdentifierFromRes,
    oldTokenizedIdFromRes,
  ] = useGetMostRecentMedicationSummary(
    pageFlag,
    memberIdentifier,
    oldMemberIdentifier,
    oldTokenizedId,
  );

  useEffect(() => {
    if (oldMemberIdentifierFromRes) {
      dispatch(setOldMemberIdentifier(oldMemberIdentifierFromRes));
    }

    if (oldTokenizedIdFromRes) {
      dispatch(setOldTokenizedId(oldTokenizedIdFromRes));
    }
  }, [dispatch, oldMemberIdentifierFromRes, oldTokenizedIdFromRes]);

  const isLoadingPageWithSkeleton = useMemo(
    () => isLoadingSummary || isLoadingPrescribedMedicationConsent,
    [isLoadingPrescribedMedicationConsent, isLoadingSummary],
  );

  // call APIs to fetch form data (systemSettings, payment mode, etc) after user chooses to reorder latest request and APIs fetching request detail have been finished successfully
  useEffect(() => {
    if (
      selectedRequestorId &&
      medrefillState.requestType &&
      hasErroredImages === false
    ) {
      onReorder(medrefillState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRequestorId,
    medrefillState.requestType,
    onReorder,
    hasErroredImages,
  ]);

  // do the page redirection to do reordering only after all form data API calls have been finished successfully
  useEffect(() => {
    if (
      !isLoadingFormData &&
      selectedRequestorId &&
      medrefillState.requestType &&
      hasErroredSystemSettings === false &&
      hasErroredMedications === false &&
      hasErroredCollectionPaymentDetails === false &&
      hasErroredDocByCluster === false &&
      hasErroredClinics === false
    ) {
      // This check is for records that had previous error where patient nric and requester nric are the same
      if (medrefillState.requestType === "REFILL") {
        if (
          medrefillState.particulars.isPatient === false &&
          medrefillState.particulars.patientNric &&
          medrefillState.particulars.requesterNric &&
          sameStringTest(
            medrefillState.particulars.patientNric,
            medrefillState.particulars.requesterNric,
          )
        ) {
          logEventToGoogleAnalytics(EVENTS.SELECT_REFILL_NEW_REQUEST);
          navigate(PATHS.MED_REFILL_SELECT_INSTITUTIONS.path);
        } else {
          logEventToGoogleAnalytics(EVENTS.REORDER_LATEST_REFILL_PAST_REQUEST);
          navigate(PATHS.MED_REFILL_QUANTITY.path);
        }
      }
      if (medrefillState.requestType === "TOPUP") {
        logEventToGoogleAnalytics(EVENTS.REORDER_LATEST_TOPUP_PAST_REQUEST);
        navigate(PATHS.MED_TOPUP_PRESCRIPTION.path);
      }
    }
  }, [
    isLoadingFormData,
    selectedRequestorId,
    medrefillState.requestType,
    hasErroredSystemSettings,
    hasErroredMedications,
    hasErroredCollectionPaymentDetails,
    hasErroredDocByCluster,
    hasErroredClinics,
    navigate,
    medrefillState.particulars.isPatient,
    medrefillState.particulars.patientNric,
    medrefillState.particulars.requesterNric,
    medrefillState.viewPastRequest.detail,
  ]);

  const onReOrderClickHandler = (data: Summary) => {
    // Clear state of ViewPastDetail and PastRequestMedicationImages
    clearPastData();

    onFetchingLatestRequestDetail(data.RequesterId);
    onFetchingLatestRequestImages(data.RequesterId);

    setSelectedRequestorId(data.RequesterId);
  };

  const onClickCardHandler = (data: Summary) => {
    if (data.PrescriptionRequestType === "REFILL") {
      navigate(PATHS.MED_REFILL_VIEW_PAST_REQUEST_DETAIL.path);
    }
    if (data.PrescriptionRequestType === "TOPUP") {
      navigate(PATHS.MED_TOPUP_VIEW_PAST_REQUEST_DETAIL.path);
    }
    if (data.PrescriptionRequestType === "VIRTUALCONSULT") {
      navigate(
        PATHS.MED_VIRTUALCONSULT_ORDER_MEDICATION_VIEW_PAST_REQUEST_DETAIL.path,
      );
    }
  };

  useEffect(() => {
    if (messageActionObj?.action === "refreshLanding") {
      consumeMessageActionObj();
      setPageFlag(!pageFlag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumeMessageActionObj, messageActionObj?.action]);

  return (
    <Page>
      {isLoadingDetail || isLoadingFormData || isLoadingImages ? (
        // clicked re-order button, i.e. leaving the landing page, show circular progress instead of skeleton
        <Box display="flex" align-items="center" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : hasErroredDetail ? (
        <ErrorDisplay
          errorMessage={errorMessageDetail}
          onTryAgain={() => onFetchingLatestRequestDetail(selectedRequestorId)}
        />
      ) : hasErroredImages ? (
        <ErrorDisplay
          errorMessage={errorMessageImages}
          onTryAgain={() => onFetchingLatestRequestImages(selectedRequestorId)}
        />
      ) : hasErroredSystemSettings ? (
        <ErrorDisplay
          errorMessage={errorMessageSystemSettings}
          onTryAgain={() => onReorder(medrefillState)}
        />
      ) : hasErroredMedications ? (
        <ErrorDisplay
          errorMessage={errorMessageMedications}
          onTryAgain={() => onReorder(medrefillState)}
        />
      ) : hasErroredCollectionPaymentDetails ? (
        <ErrorDisplay
          errorMessage={errorMessageCollectionPaymentDetails}
          onTryAgain={() => onReorder(medrefillState)}
        />
      ) : hasErroredDocByCluster ? (
        <ErrorDisplay
          errorMessage={errorMessageDocByCluster}
          onTryAgain={() => onReorder(medrefillState)}
        />
      ) : hasErroredClinics ? (
        <ErrorDisplay
          errorMessage={errorMessageClinics}
          onTryAgain={() => onReorder(medrefillState)}
        />
      ) : (
        <Box sx={classes.mainContainer}>
          <Box>
            <Box sx={classes.profileHeader}>
              {isLoadingUsername ? (
                <CardHeader
                  title={
                    <Skeleton variant="text" animation="wave" width="60%" />
                  }
                  sx={classes.root}
                />
              ) : username ? (
                <Typography sx={classes.profileName}>
                  {username && username + "'s medication"}
                </Typography>
              ) : null}
            </Box>

            <Typography sx={classes.title}>{MEDICATION_TITLE}</Typography>

            {isLoadingPageWithSkeleton ? (
              <Box pt={2} mx={1}>
                <Skeleton variant="text" animation="wave" width="40%" />
                <Box marginTop={2} marginBottom={3.8}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={170}
                  />
                </Box>
              </Box>
            ) : hasErroredSummary ? (
              <ErrorDisplay
                errorMessage={errorMessageDocByCluster}
                onTryAgain={() => setPageFlag(!pageFlag)}
              />
            ) : dataRequest ? (
              <>
                {/* Latest medication card */}
                <Box sx={classes.cardContainer}>
                  <MedicationCardContainer
                    data={dataRequest}
                    onReOrder={() => {
                      //something to change here
                      onReOrderClickHandler(dataRequest);
                    }}
                    onClickCard={() => {
                      onClickCardHandler(dataRequest);
                    }}
                  />
                </Box>

                {/* View all past requests */}
                <Box sx={classes.viewPastRequestTextBox}>
                  <Box
                    sx={classes.viewPastContainer}
                    onClick={() =>
                      navigate(PATHS.MEDICATIONS_PAST_REQUESTS.path)
                    }
                  >
                    <Typography sx={classes.buttonText}>
                      {VIEW_PAST_TITLE}
                    </Typography>
                    <Box
                      component={"img"}
                      sx={{ width: "20px" }}
                      src={IMAGES.general.ArrowRightIcon}
                      alt={VIEW_PAST_TITLE}
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <Box>
                <Box sx={classes.noRecordsImageContainer}>
                  <Box
                    component="img"
                    src={IMAGES.medrefill.NoRecords}
                    sx={classes.noRecordsImage}
                    alt={NO_RECORDS_TITLE}
                  />
                </Box>

                <Typography sx={classes.noRecordsTitle}>
                  {NO_RECORDS_TITLE}
                </Typography>
                <Typography sx={classes.noRecordsText}>
                  {NO_RECORDS_BODY}
                  {errorMessageSummary}
                </Typography>
              </Box>
            )}
            <Box sx={classes.dividerBox}></Box>
          </Box>

          {/* Medication Footer Container*/}
          {/* not showing this section before user consent against prescribed medication is determined */}
          {!isLoadingPageWithSkeleton && (
            <Box>
              <Box sx={classes.bottomTitleContainer}>
                <Typography sx={classes.bottomTitle}>
                  {toTitleCase(OTHER_MED_TITLE)}
                </Typography>
              </Box>

              {/* Icons Container */}
              <FooterIcons
                shouldDisplayMedicationRecord={shouldDisplayMedicationRecord(
                  relationType,
                  showMyChartMedication,
                )}
                prescribedMedicationConsentFlag={prescribedMedicationConsent(
                  relationType,
                  prescribedMedicationConsentFlag,
                )}
                prescriptionRedirect={() => {
                  const url = prescribedMedicationUrl();
                  navigateToExternalInMobileBrowserForStandardPage(
                    url,
                    "ss",
                    url,
                  );
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </Page>
  );
};

export default SelectRequest;
