import { RootState } from "lib/redux/root/redux.types";
// import { useAppSelector } from "lib/redux/hooks";
// import { useSelector } from "react-redux";

// === Appointments State Selectors ===
//
// Selector functions that accept Redux state as an argument and return data derived
// from that state. Selectors can provide performance optimizations (e.g. using the
// "reselect" library) and can also encapsulate access to the global state tree.
//
// Use these functions to avoid having to do:
//   state.appointments.init ...
// calls everywhere in the code
//

///////selectAppointmentById won't work as it takes in additional param
// export const selectAppointmentById = (
//   rootState: RootState,
//   id: string
// ) => {
//   return selectAppointmentsLookup(rootState)[id];
// };

export const selectAppointments = (rootState: RootState) =>
  rootState.appointments;

export const selectAppointmentsLookup = (rootState: RootState) =>
  rootState.appointments.init.lookup;

export const selectAppointmentsInit = (rootState: RootState) =>
  rootState.appointments.init;

export const selectAppointmentsSystemSettings = (rootState: RootState) =>
  rootState.appointments.systemSettings;

export const selectAppointmentsAnnouncements = (rootState: RootState) =>
  rootState.appointments.announcements;

export const selectAppointmentMeta = (rootState: RootState) =>
  rootState.appointments.appointmentMeta;

export const selectAppointmentTriage = (rootState: RootState) =>
  rootState.appointments.triage;

export const selectAppointmentMammogram = (rootState: RootState) =>
  rootState.appointments.mammogram;

export const selectAppointmentDocByType = (rootState: RootState) =>
  rootState.appointments.documentByType;

export const selectCheckInSuccess = (rootState: RootState) =>
  rootState.appointments.checkInSuccess;

export const selectApptDetail = (state: RootState) => {
  const apptId = selectApptId(state);
  return state.appointments.register[apptId];
};

export const selectApptId = (rootState: RootState) =>
  rootState.appointments.appointmentMeta.appointmentId || "";

export const selectPersistDetail = (state: RootState) => {
  const apptId = selectApptId(state);
  return state.appointments.persist[apptId];
};

export const selectPopHealthStatus = (rootState: RootState) =>
  rootState.appointments.popHealthStatus;

export const selectRescheduleLinkedAppointments = (state: RootState) =>
  state.appointments.rescheduleLinkedAppointment;
