import { FormControl, Typography, FormLabel, Box } from "@mui/material";
import { sxStyles } from "../../AppointmentForm.styles";
import { CancelApptFormFieldProps } from "./CancelApptFormField.types";
import nameof from "ts-nameof.macro";
import Dropdown from "lib/components/formInputs/Dropdown/Dropdown";
import MultiLineTextField from "lib/components/formInputs/MultiLineTextField/MultiLineTextField";

const CancelApptFormField = ({
  cancellationReasonList,
  name,
  currentApptDateTime,
  cancelApptFormField,
  handleChange,
}: CancelApptFormFieldProps) => {
  const classes = sxStyles();

  return (
    <>
      <Box sx={classes.layout}>
        <Typography variant="subtitle1" sx={classes.intensionText}>
          I want to <strong>cancel</strong> this appointment at:
        </Typography>
        <Typography variant="subtitle1" sx={classes.intensionLocationText}>
          {name}
        </Typography>
      </Box>
      <FormControl component="fieldset" fullWidth>
        <FormLabel sx={classes.currentDateTimeformLabel}>
          Current Appointment
        </FormLabel>

        <Box sx={classes.apptDateTimeField}>
          <Box>
            <Typography variant="subtitle1" sx={classes.intensionText}>
              Date:
            </Typography>
            <Typography variant="subtitle1" sx={classes.intensionLocationText}>
              {currentApptDateTime}
            </Typography>
          </Box>
        </Box>
      </FormControl>
      <Box sx={classes.textBox}>
        <Dropdown
          name={nameof(cancelApptFormField.reasonForCancel)}
          label="Reason for Cancellation"
          value={
            cancellationReasonList.find(
              (reasonObject) =>
                reasonObject.text === cancelApptFormField.reasonForCancel,
            )?.id
          }
          items={cancellationReasonList}
          handleChange={handleChange}
        />
      </Box>

      <FormControl component="fieldset" fullWidth>
        <MultiLineTextField
          name={nameof(cancelApptFormField.remarks)}
          placeholder="Remarks"
          value={cancelApptFormField.remarks}
          rowNumber={3}
          maxNumber={5}
          maxCharLength={200}
          handleChange={handleChange}
        />
      </FormControl>
    </>
  );
};

export default CancelApptFormField;
