import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    footerSectionContainer: {
      borderTop: "1px solid " + theme.palette.secondary.light,
      margin: theme.spacing(0, 2, 4),
    },
    titleContainer: { padding: theme.spacing(1) },

    pageTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_semibold,
      margin: theme.spacing(2, 0, 1),
    },
  } satisfies SxObjType;
};

export { sxStyles };
