import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests/RequestOptions";
import { formatGetAppointmentStatusErrorMessage } from "../../resources/messages/ErrorMessage";
import { GET_POP_HEALTH_STATUS_URL } from "../../resources/configs/URL";
import { GetPopHealthStatus } from "./getPopHealthStatus.fromApi.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";

/**
 * Fetches the appointment status of a particular patient.
 *
 * POST
 * Endpoint: /appointment/GetAppointmentStatus
 */

const getPopHealthStatus = async (
  memberIdentifier: string | null,
  clinicCode: string | null,
): Promise<GetPopHealthStatus> => {
  try {
    const response = await axios.post<GetPopHealthStatus>(
      GET_POP_HEALTH_STATUS_URL(),
      createRequestPayload(memberIdentifier, clinicCode),
      getRequestOptions(),
    );
    return parseResponse(response, GET_POP_HEALTH_STATUS_URL());
  } catch (error) {
    const msg = formatGetAppointmentStatusErrorMessage(
      GET_POP_HEALTH_STATUS_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  memberIdentifier: string | null,
  clinicCode: string | null,
) => {
  return {
    MemberIdentifier: memberIdentifier,
    ClinicCode: clinicCode,
  };
};

export { getPopHealthStatus };
