import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { CANCEL_APPOINTMENT_URL } from "../../resources/configs/URL";
import { formatCancelAppointmentErrorMessage } from "../../resources/messages/ErrorMessage";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";
import { CancelAppointmentPayload } from "./cancelAppointment.types";

/**
 * Cancels an appointment
 *
 * POST
 * Endpoint: /CancelAppointment
 *
 * @param {string | null} memberIdentifier  Member Identifier of the person we are making this request for
 * @param {string} appointmentId  ID of the appointment, only truthy if it is single appointment, else NULL
 * @param {string[]} appointmentIds IDs of the appointment, only non-empty if it is linked appointment
 * @param {string | null} targetSystem  Target System of this appointment
 * @param {string | null} institutionCode  Institution code of this appointment
 * @param {string | null} departmentName Department Name of the appointment
 *
 * @returns {CancelAppointmentPayload} Response payload from the remote API
 */
const cancelAppointment = async ({
  memberIdentifier,
  appointmentIds,
  institutionCode,
}: {
  memberIdentifier: string | null;
  appointmentIds: string[];
  institutionCode: string | null;
}): Promise<CancelAppointmentPayload> => {
  try {
    const response = await axios.post<CancelAppointmentPayload>(
      CANCEL_APPOINTMENT_URL(),
      cancelRequestPayload(memberIdentifier, appointmentIds, institutionCode),
      getRequestOptions(),
    );
    return parseResponse(response, CANCEL_APPOINTMENT_URL());
  } catch (error) {
    const msg = formatCancelAppointmentErrorMessage(CANCEL_APPOINTMENT_URL());
    console.error(msg);
    throw error;
  }
};

const cancelRequestPayload = (
  memberIdentifier: string | null,
  appointmentIds: string[],
  institutionCode: string | null,
) => {
  return {
    MemberIdentifier: memberIdentifier,
    InstitutionCode: institutionCode,
    AppointmentIds: appointmentIds,
  };
};

export { cancelAppointment };
