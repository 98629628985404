import { parseJson } from "lib/util/StringUtil/jsonParser/parseJson";
import { GetSystemSettingsPayload } from "./../../../../../../api/shared/GetSystemSettings/getSystemSettings.fromApi.types";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { getSystemSettings } from "api/shared/GetSystemSettings/getSystemSettings";
import {
  initialState,
  setEpicProfileErrorMessage,
  setEpicProfileHasEpicProfile,
  setEpicProfileHasErrored,
  setEpicProfileIsLoading,
  setEpicProfileState,
  setEpicProfileStateDates,
  setHasFetchedEpicProfile,
  setMyInfoProfileErrorMessage,
  setMyInfoProfileHasErrored,
  setMyInfoProfileIsLoading,
  setMyInfoProfileState,
  setSystemSettingsErrorMessage,
  setSystemSettingsHasErrored,
  setSystemSettingsIsLoading,
  setSystemSettingsValue,
} from ".";
import renderErrorMsg from "lib/util/ConsoleUtil/renderErrorMsg";
import { retrieveMyInfoProfile } from "api/myInfo/RetrieveMyInfoProfile/retrieveMyInfoProfile";

import { getPatientMasterProfile } from "api/patientMaster/GetPatientMasterProfile/getPatientMasterProfile";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { ACTIONS } from "lib/routing";
import { setMyChartUserId } from "lib/redux/user";
import { setOldEpicProfileState } from "./pmSlice";
import { AxiosError } from "axios";

export const fetchSystemSettings =
  () => async (dispatch: Dispatch<AnyAction>) => {
    const { value: initialValue } = initialState.systemSettings;

    try {
      dispatch(setSystemSettingsIsLoading(true));
      const systemSettingList = await getSystemSettings({
        category: "OneNUHS",
        subcategory: "PatientMaster",
        codeName: "StaticContent",
        institutionCode: null,
      });

      dispatch(setSystemSettingsValue(parserAndMapToRedux(systemSettingList)));

      dispatch(setSystemSettingsHasErrored(false));
      dispatch(setSystemSettingsErrorMessage(null));
    } catch (error) {
      console.error(renderErrorMsg(error)); //in the event that JSON parsing throws error
      dispatch(setSystemSettingsValue(initialValue));
      dispatch(setSystemSettingsHasErrored(true));

      if (error instanceof AxiosError) {
        dispatch(setSystemSettingsErrorMessage(error.response?.data.Message));
      } else {
        dispatch(setSystemSettingsErrorMessage(null));
      }
    } finally {
      dispatch(setSystemSettingsIsLoading(false));
    }
  };

export const fetchEpicProfile =
  ({
    myInfoName,
    myInfoDoB,
    myInfoSex,
    uuid,
    codeVerifier,
  }: {
    myInfoName: string | null;
    myInfoDoB: string | null;
    myInfoSex: string | null;
    uuid: string | null | undefined;
    codeVerifier: string | null;
  }) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const { value: initialValue } = initialState.epicProfile;
    try {
      dispatch(setEpicProfileIsLoading(true));
      const epicProfile = await getPatientMasterProfile({
        myInfoName,
        myInfoDoB,
        myInfoSex,
        uuid,
        codeVerifier,
      });

      dispatch(
        setEpicProfileState({
          ...initialValue,
          Sex: epicProfile?.Sex ? epicProfile?.Sex?.toLocaleUpperCase() : "",
          Name: epicProfile?.Name ?? "",
          Nric: epicProfile?.Nric ?? "",
          Title: epicProfile?.Title ?? "",
          DateOfBirth: epicProfile?.DateOfBirth ?? "",
          Nationality: epicProfile?.Nationality
            ? epicProfile.Nationality.toLocaleUpperCase()
            : "",
          NationalityCode: epicProfile?.NationalityCode ?? "",
          Race: epicProfile?.Race ?? "",
          RaceCode: epicProfile?.RaceCode ?? "",
          ResidentialStatus: epicProfile?.ResidentialStatus ?? "",
          ResidentialStatusCode: epicProfile?.ResidentialStatusCode ?? "",
          PassType: epicProfile?.PassType ?? "",
          PassTypeCode: epicProfile?.PassTypeCode ?? "",
          PassExpiryDate: epicProfile?.PassExpiryDate ?? "",
          DocumentTypeCode: epicProfile?.DocumentTypeCode ?? "",
          DocumentTypeDisplay: epicProfile?.DocumentTypeDisplay ?? "",
          DocumentTypeExpiry: epicProfile?.DocumentTypeExpiry ?? "",
          RegisteredPostalCode: epicProfile?.RegisteredPostalCode ?? "",
          RegisteredStreetName: epicProfile?.RegisteredStreetName ?? "",
          RegisteredBlockNumber: epicProfile?.RegisteredBlockNumber ?? "",
          RegisteredUnitNumber:
            registeredUnitNo(
              epicProfile?.RegisteredFloorNumber,
              epicProfile?.RegisteredUnitNumber,
            ) ?? "",
          RegisteredFloorNumber: "",
          PreferredPostalCode: epicProfile?.PreferredPostalCode ?? "",
          PreferredStreetName: epicProfile?.PreferredStreetName ?? "",
          PreferredBlockNumber: epicProfile?.PreferredBlockNumber ?? "",
          PreferredUnitNumber:
            registeredUnitNo(
              epicProfile?.PreferredFloorNumber,
              epicProfile?.PreferredUnitNumber,
            ) ?? "",
          PreferredFloorNumber: "",
          MailingAddressStartDate: epicProfile?.MailingAddressStartDate ?? "",
          MailingAddressExpiryDate: epicProfile?.MailingAddressExpiryDate ?? "",
          ContactNumber: epicProfile?.ContactNumber?.split(" ").join("") ?? "",
          SMSContactNumber:
            epicProfile?.SMSContactNumber?.split(" ").join("") ?? "",
          Email: epicProfile?.Email ?? "",
          HomeNumber: epicProfile?.HomeNumber?.split(" ").join("") ?? "",
          WorkNumber: epicProfile?.WorkNumber?.split(" ").join("") ?? "",
          MismatchStatus: epicProfile?.MismatchStatus ?? null,
          PwrFlag: epicProfile?.PwrFlag ?? null,
          UserId: epicProfile?.UserId ?? null,
        }),
      );
      dispatch(
        setOldEpicProfileState({
          ...initialValue,
          Sex: epicProfile?.Sex ? epicProfile?.Sex?.toLocaleUpperCase() : "",
          Name: epicProfile?.Name ?? "",
          Nric: epicProfile?.Nric ?? "",
          Title: epicProfile?.Title ?? "",
          DateOfBirth: epicProfile?.DateOfBirth ?? "",
          Nationality: epicProfile?.Nationality
            ? epicProfile.Nationality.toLocaleUpperCase()
            : "",
          NationalityCode: epicProfile?.NationalityCode ?? "",
          Race: epicProfile?.Race ?? "",
          RaceCode: epicProfile?.RaceCode ?? "",
          ResidentialStatus: epicProfile?.ResidentialStatus ?? "",
          ResidentialStatusCode: epicProfile?.ResidentialStatusCode ?? "",
          PassType: epicProfile?.PassType ?? "",
          PassTypeCode: epicProfile?.PassTypeCode ?? "",
          PassExpiryDate: epicProfile?.PassExpiryDate ?? "",
          DocumentTypeCode: epicProfile?.DocumentTypeCode ?? "",
          DocumentTypeDisplay: epicProfile?.DocumentTypeDisplay ?? "",
          DocumentTypeExpiry: epicProfile?.DocumentTypeExpiry ?? "",
          RegisteredPostalCode: epicProfile?.RegisteredPostalCode ?? "",
          RegisteredStreetName: epicProfile?.RegisteredStreetName ?? "",
          RegisteredBlockNumber: epicProfile?.RegisteredBlockNumber ?? "",
          RegisteredUnitNumber:
            registeredUnitNo(
              epicProfile?.RegisteredFloorNumber,
              epicProfile?.RegisteredUnitNumber,
            ) ?? "",
          RegisteredFloorNumber: "",
          PreferredPostalCode: epicProfile?.PreferredPostalCode ?? "",
          PreferredStreetName: epicProfile?.PreferredStreetName ?? "",
          PreferredBlockNumber: epicProfile?.PreferredBlockNumber ?? "",
          PreferredUnitNumber:
            registeredUnitNo(
              epicProfile?.PreferredFloorNumber,
              epicProfile?.PreferredUnitNumber,
            ) ?? "",
          PreferredFloorNumber: "",
          MailingAddressStartDate: epicProfile?.MailingAddressStartDate ?? "",
          MailingAddressExpiryDate: epicProfile?.MailingAddressExpiryDate ?? "",
          ContactNumber: epicProfile?.ContactNumber?.split(" ").join("") ?? "",
          SMSContactNumber:
            epicProfile?.SMSContactNumber?.split(" ").join("") ?? "",
          Email: epicProfile?.Email ?? "",
          HomeNumber: epicProfile?.HomeNumber?.split(" ").join("") ?? "",
          WorkNumber: epicProfile?.WorkNumber?.split(" ").join("") ?? "",
          MismatchStatus: epicProfile?.MismatchStatus ?? null,
          PwrFlag: epicProfile?.PwrFlag ?? null,
          UserId: epicProfile?.UserId ?? null,
        }),
      );
      dispatch(
        setEpicProfileStateDates({
          //to change yyyy-MM-dd to yyyy-MM-ddT00:00:00 for the ease of handling of dates
          DoB: formatDateToISO(
            getDateFromISOString(epicProfile?.DateOfBirth ?? ""),
          ),
          PED: formatDateToISO(
            getDateFromISOString(epicProfile?.PassExpiryDate ?? ""),
          ),
          MEED: formatDateToISO(
            getDateFromISOString(epicProfile?.MailingAddressExpiryDate ?? ""),
          ),
          MESD: formatDateToISO(
            getDateFromISOString(epicProfile?.MailingAddressStartDate ?? ""),
          ),
          DED: formatDateToISO(
            getDateFromISOString(epicProfile?.DocumentTypeExpiry ?? ""),
          ),
        }),
      );
      dispatch(setEpicProfileHasErrored(false));
      dispatch(setEpicProfileErrorMessage(null));
      dispatch(setEpicProfileHasEpicProfile(true));
      dispatch(setHasFetchedEpicProfile(true));
      dispatch(setMyChartUserId(epicProfile.UserId));
      mobileNavigate(ACTIONS.MYCHART_UUID(epicProfile.UserId));
    } catch (error) {
      dispatch(setEpicProfileState(initialValue));
      dispatch(setOldEpicProfileState(initialValue));
      if (error instanceof AxiosError) {
        //Status of 404 (No profile found - indicating new user)
        if (error.response?.status === 404) {
          dispatch(setHasFetchedEpicProfile(true));
          dispatch(setEpicProfileHasErrored(false));
          dispatch(setEpicProfileErrorMessage(null));
          //4XX-5XX (any other error)
        } else {
          dispatch(setHasFetchedEpicProfile(false));
          dispatch(setEpicProfileHasErrored(true));
          dispatch(setEpicProfileErrorMessage(error.response?.data.Message));
        }
      } else {
        dispatch(setEpicProfileHasErrored(true));
        dispatch(setEpicProfileErrorMessage(null));
        dispatch(setHasFetchedEpicProfile(false));
      }
    } finally {
      dispatch(setEpicProfileIsLoading(false));
    }
  };

export const fetchMyInfoProfile =
  (
    codeVerifier: string | null,
    authCode: string | null,
    redirectUrl: string | null,
    shouldRetrieve: boolean,
  ) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const { value: initialValue } = initialState.myInfoProfile;
    if (shouldRetrieve) {
      try {
        dispatch(setMyInfoProfileIsLoading(true));
        const responseData = await retrieveMyInfoProfile(
          codeVerifier,
          authCode,
          redirectUrl,
        );

        const profileData = responseData.MyInfoProfile;
        dispatch(
          setMyInfoProfileState({
            Name: profileData.Name ?? "",
            Nric: profileData.DisplayIdentifier ?? "",
            Sex: profileData.Sex ?? "",
            DateOfBirth: formatDateToISO(
              getDateFromISOString(profileData.Dob ?? ""),
            ),
            Nationality: profileData.Nationality?.toLocaleUpperCase() ?? "",
            NationalityCode: profileData.NationalityCode ?? "",
            Race: profileData.Race ?? "",
            RaceCode: profileData.RaceCode ?? "",
            ResidentialStatus: profileData.ResidentialStatus ?? "",
            ResidentialStatusCode: profileData.ResidentialStatusCode ?? "",
            PassType: profileData.PassType ?? "",
            PassTypeCode: profileData.PassTypeCode ?? "",
            PassExpiryDate: formatDateToISO(
              getDateFromISOString(profileData.PassExpiryDate ?? ""),
            ),
            RegisteredPostalCode:
              profileData.RegisteredAddress.PostalCode ?? "",
            RegisteredBlockNumber: profileData.RegisteredAddress.Block ?? "",
            RegisteredStreetName: profileData.RegisteredAddress.Street ?? "",
            RegisteredUnitNumber:
              registeredUnitNo(
                profileData.RegisteredAddress.Floor,
                profileData.RegisteredAddress.Unit,
              ) ?? "",
            RegisteredFloorNumber: "",
          }),
        );
        dispatch(setMyInfoProfileHasErrored(false));
        dispatch(setMyInfoProfileErrorMessage(null));
      } catch (error) {
        dispatch(setMyInfoProfileState(initialValue));
        dispatch(setMyInfoProfileHasErrored(true));
        if (error instanceof AxiosError) {
          dispatch(setMyInfoProfileErrorMessage(error.response?.data.Message));
        } else {
          dispatch(setMyInfoProfileErrorMessage(null));
        }
      } finally {
        dispatch(setMyInfoProfileIsLoading(false));
      }
    }
  };

// Helper functions
export const parserAndMapToRedux = (
  responsePayload: GetSystemSettingsPayload,
) => {
  const { value: initialValue } = initialState.systemSettings;

  const rawValue = responsePayload.Settings[0].Value;
  const parseValue = rawValue
    ? mapValue(parseJson(rawValue))
    : { ...initialValue };

  return parseValue;
};

const mapValue = (parseValue: any) => {
  const { Disclaimers, ErrorMessages, SuccessMessages } = parseValue;

  const {
    ComparePage,
    PersonalDetails,
    ImptToNote,
    VerifyYourParticulars,
    UpToDate,
    UnsyncParticulars,
    MeansTesting,
    EmailAddress,
    UnsavedChanges,
    SpotAnyIssues,
    SyncYourParticulars,
    PreferredDateDisclaimer,
  } = Disclaimers;

  const { SyncParticularsFailed, NoRecordsFound } = ErrorMessages;

  const { SyncParticularsSuccess } = SuccessMessages;

  const mappedValue = {
    disclaimers: {
      comparePage: {
        title: ComparePage.Title ?? "",
        description: ComparePage.Description ?? "",
      },
      personalDetails: {
        title: PersonalDetails.Title ?? "",
        description: PersonalDetails.Description ?? "",
      },
      imptToNote: {
        title: ImptToNote.Title ?? "",
        description: ImptToNote.Description ?? "",
      },
      verifyYourParticulars: {
        title: VerifyYourParticulars.Title ?? "",
        description: VerifyYourParticulars.Description ?? [],
      },
      unsyncParticulars: {
        title: UnsyncParticulars.Title ?? "",
        description: UnsyncParticulars.Description ?? "",
      },
      upToDate: {
        title: UpToDate.Title ?? "",
        description: UpToDate.Description ?? "",
      },
      meansTesting: {
        title: MeansTesting.Title ?? "",
        description: MeansTesting.Description ?? "",
      },
      emailAddress: {
        title: EmailAddress.Title ?? "",
        description: EmailAddress.Description ?? "",
      },
      unsavedChanges: {
        title: UnsavedChanges?.Title ?? "",
        description: UnsavedChanges?.Description ?? "",
      },
      spotAnyIssues: {
        title: SpotAnyIssues?.Title ?? "",
        description: SpotAnyIssues?.Description ?? "",
      },
      syncYourParticulars: {
        title: SyncYourParticulars?.Title ?? "",
        description: SyncYourParticulars?.Description ?? "",
      },
      preferredDateDisclaimer: {
        title: PreferredDateDisclaimer?.Title ?? "",
        description: PreferredDateDisclaimer?.Description ?? "",
      },
    },
    errorMessages: {
      syncParticularsFailed: {
        title: SyncParticularsFailed.Title ?? "",
        description: SyncParticularsFailed.Description ?? "",
      },
      noRecordsFound: {
        title: NoRecordsFound.Title ?? "",
        description: NoRecordsFound.Description ?? "",
      },
    },
    successMessages: {
      syncParticularsSuccess: {
        title: SyncParticularsSuccess.Title ?? "",
        description: SyncParticularsSuccess.Description ?? "",
      },
    },
  };

  return mappedValue;
};

export const registeredUnitNo = (floor: string | null, unit: string | null) => {
  if (!floor && !unit) {
    return null;
  }

  if (!floor) {
    return unit;
  }

  if (!unit) {
    return floor;
  }

  return `#${floor}-${unit}`;
};
