import axios from "axios";
import { GET_HOSPITAL_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatGetHospitalErrorMessage } from "api/resources/messages/ErrorMessage";
import { GetHospitalResponsePayload } from "./getHospital.fromApi.types";

/**
 * Get hospital code, description and payment mode
 *
 * POST
 * Endpoint: secure/MRRP/Data/GetHospital
 *
 * @param {string} hospitalCode Hospital code to retrieve values for, retrieve for all when null, ADMC | CGH | IMH | KKH | KTPH | NUH | SGH | SKH | TTSH
 */
const getHospital = async (
  hospitalCode: string | null,
  oldTokenizedId: string | null,
): Promise<GetHospitalResponsePayload> => {
  try {
    const response = await axios.post<GetHospitalResponsePayload>(
      GET_HOSPITAL_URL(),
      createRequestPayload(hospitalCode, oldTokenizedId),
      getRequestOptions(),
    );
    return parseResponse(response, GET_HOSPITAL_URL());
  } catch (error) {
    const msg = formatGetHospitalErrorMessage(GET_HOSPITAL_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  hospitalCode: string | null,
  oldTokenizedId: string | null,
) => {
  return {
    HospitalCode: hospitalCode,
    OldTokenizedId: oldTokenizedId,
  };
};

export { getHospital };
