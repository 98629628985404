import { MessageActionsType } from "./messageActions.types";

// action values are always sent as camcelCase string
export const MessageActions = {
  acknowledge: () => ({
    action: "acknowledge",
  }),

  rating: () => ({
    action: "rating",
  }),

  backDisable: () => {
    return { action: "backDisable" };
  },

  backEnable: () => ({
    action: "backEnable",
  }),

  landing: (isLanding: boolean) => ({
    action: "landing",
    params: {
      isLanding,
    },
  }),

  staticTitle: ({
    title,
    isEnabled,
  }: {
    title: string;
    isEnabled: boolean;
  }) => ({
    action: "staticTitle",
    params: {
      title,
      isEnabled,
    },
  }),
} satisfies MessageActionsType;
