import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetFacilities } from "ui/medrefill/hooks/useGetFacilities";
import { VirtualConsultEntryPointPageProps } from "./VirtualConsultEntryPointPage.types";
import { PATHS } from "lib/routing";
import { Box, CircularProgress } from "@mui/material";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectUser } from "lib/redux/user/selectors";
import { setOldTokenizedId } from "lib/redux/user/userSlice";

const VirtualConsultEntryPointPage = ({
  relationType,
  institutionCode,
  prescriptionType,
  isLoadingFormData,
  hasErroredSystemSettings,
  errorMessageSystemSettings,
  hasErroredMedications,
  errorMessageMedications,
  hasErroredCollectionPaymentDetails,
  errorMessageCollectionPaymentDetails,
  hasErroredClinics,
  errorMessageClinics,
  hasErroredDocByCluster,
  errorMessageDocByCluster,
  onLoad,
  onSelect,
  onFetchingPrescribedMedicationConsent,
  institutionName,
}: VirtualConsultEntryPointPageProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Redux States
  const { oldTokenizedId } = useAppSelector(selectUser);

  // Local States
  const [pageFlag, setPageFlag] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState<{
    facilityId: number;
    facilityName: string;
    cluster: string;
  } | null>(null);

  const [
    payloadFacilities,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _,
    isLoadingFacilities,
    hasErroredFacilities,
    errorMessageFacilities,
    oldTokenizedIdFromRes,
  ] = useGetFacilities(pageFlag, prescriptionType, oldTokenizedId);

  useEffect(() => {
    if (oldTokenizedIdFromRes) {
      dispatch(setOldTokenizedId(oldTokenizedIdFromRes));
    }
  }, [dispatch, oldTokenizedIdFromRes]);

  useEffect(() => {
    // initialize API status every time this component mounts
    onLoad();

    // fetch user consent against prescribed medication standard page if it is family and friend flow (relationType === 'F')
    // only when it is familty and friend flow, we need to fetch user consent to determine if prescribed medication should be display in collection page
    // for logged in user flow, no need to fetch consent as consent is implicitly given
    // for relationType 'C', prescribed medication is always hidden
    if (relationType === "F") {
      onFetchingPrescribedMedicationConsent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoad]);

  useEffect(() => {
    if (!isLoadingFacilities && hasErroredFacilities === false) {
      // find the facility corresponding to institution code
      const facility = payloadFacilities?.find(
        (p) =>
          p.InstCode === institutionCode && p.FacilityName === institutionName,
      );
      if (facility) {
        // call API to fetch form data (systemSettings, medications, payment mode, etc) based on facility found
        onSelect(facility.FacilityId, facility.FacilityName, facility.Cluster);
        setSelectedFacility({
          facilityId: facility.FacilityId,
          facilityName: facility.FacilityName,
          cluster: facility.Cluster,
        }); // set the state for error handling
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFacilities]);

  // do the page redirection only after all form data API calls have been finished successfully
  useEffect(() => {
    if (
      selectedFacility &&
      !isLoadingFormData &&
      hasErroredSystemSettings === false &&
      hasErroredMedications === false &&
      hasErroredCollectionPaymentDetails === false &&
      hasErroredClinics === false &&
      hasErroredDocByCluster === false
    ) {
      navigate(
        PATHS.MED_VIRTUALCONSULT_ORDER_MEDICATION_PARTICULARS_MOBILE.path,
      );
    }
  }, [
    isLoadingFormData,
    hasErroredSystemSettings,
    hasErroredMedications,
    hasErroredCollectionPaymentDetails,
    hasErroredClinics,
    hasErroredDocByCluster,
    prescriptionType,
    navigate,
    selectedFacility,
  ]);

  return (
    <>
      {isLoadingFacilities || isLoadingFormData ? (
        <Box display="flex" align-items="center" justifyContent="center" pt={4}>
          <CircularProgress />
        </Box>
      ) : hasErroredFacilities ? (
        <ErrorDisplay
          errorMessage={errorMessageFacilities}
          onTryAgain={() => setPageFlag(!pageFlag)}
        />
      ) : hasErroredSystemSettings && selectedFacility ? (
        <ErrorDisplay
          errorMessage={errorMessageSystemSettings}
          onTryAgain={() =>
            onSelect(
              selectedFacility.facilityId,
              selectedFacility.facilityName,
              selectedFacility.cluster,
            )
          }
        />
      ) : hasErroredMedications && selectedFacility ? (
        <ErrorDisplay
          errorMessage={errorMessageMedications}
          onTryAgain={() =>
            onSelect(
              selectedFacility.facilityId,
              selectedFacility.facilityName,
              selectedFacility.cluster,
            )
          }
        />
      ) : hasErroredCollectionPaymentDetails && selectedFacility ? (
        <ErrorDisplay
          errorMessage={errorMessageCollectionPaymentDetails}
          onTryAgain={() =>
            onSelect(
              selectedFacility.facilityId,
              selectedFacility.facilityName,
              selectedFacility.cluster,
            )
          }
        />
      ) : hasErroredClinics && selectedFacility ? (
        <ErrorDisplay
          errorMessage={errorMessageClinics}
          onTryAgain={() =>
            onSelect(
              selectedFacility.facilityId,
              selectedFacility.facilityName,
              selectedFacility.cluster,
            )
          }
        />
      ) : hasErroredDocByCluster && selectedFacility ? (
        <ErrorDisplay
          errorMessage={errorMessageDocByCluster}
          onTryAgain={() =>
            onSelect(
              selectedFacility.facilityId,
              selectedFacility.facilityName,
              selectedFacility.cluster,
            )
          }
        />
      ) : !selectedFacility ? (
        <ErrorDisplay errorMessage={"Institution code not found"} />
      ) : null}
    </>
  );
};

export default VirtualConsultEntryPointPage;
