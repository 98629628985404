import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import { Theme } from "@mui/material";

const sxStyles = (theme: Theme) => {
  return {
    fullButton: {
      width: "100%",
    },
    btnLink: {
      color: theme.palette.primary.main,
      fontSize: size.FONTSIZE_12,
    },
    footerContainer: {
      ml: 1,
      mr: 1,
      mb: 2,
      mt: 2 
    },
  } satisfies SxObjType;
};
export { sxStyles };
