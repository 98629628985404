import { Theme } from "@mui/material";
import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const iconSize = size.FONTSIZE_24;

const sxStyles = (theme: Theme) => {
  return {
    radioButtonItem: {
      alignItems: "flex-start",
      "& .MuiRadio-root": {
        p: 1,
      },
      "& .MuiFormControlLabel-label": {
        mt: `calc((${iconSize}px + ${theme.spacing(2)} - (1.5 * 1em)) / 2)`, // this is to offset first line height when flex-start
      },
    },

    radioIcon: {
      width: iconSize,
      marginLeft: "1px",
    },
    disclaimerText: {
      typography: theme.typography.caption1_regular,
      color: theme.palette.grey[400],
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(-0.6),
    },
  } satisfies SxObjType;
};
export { sxStyles };
