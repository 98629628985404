import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    paymentPage: {
      zIndex: 100,
    },

    profileHeader: {
      backgroundColor: theme.palette.secondary.main,
      width: "100%",
      display: "flex",
      minHeight: theme.spacing(6),
    },
    profileName: {
      color: theme.palette.text.primary,
      typography: theme.typography.body1_semibold,
      padding: theme.spacing(1.5, 0),
      margin: theme.spacing(0, 2),
      wordBreak: "break-word",
      alignItems: "center",
    },

    cardHeader: {
      "& .MuiCardHeader-root": {
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        width: "80%",
      },
      "& .MuiCardHeader-title": {
        color: theme.palette.text.primary,
        typography: theme.typography.body1_semibold,
        padding: theme.spacing(1.5, 0),
        margin: theme.spacing(0, 2),
        wordBreak: "break-word",
        alignItems: "center",
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
