import { Link } from "react-router-dom";
import { ClickHereLinkProps } from "./ClickHereLink.types";
import { enableBackButton } from "lib/routing/navigate/navigate";

const ClickHereLink = ({ linkTo, onClick, linkText }: ClickHereLinkProps) => {
  const handleClick = () => {
    onClick();

    // clickHereLink can be inside modal component
    // when modal is open, there is action to disable Android back button
    // we need to enable Android back button again if user clicks at clickHereLink
    enableBackButton();
  };
  return (
    <Link to={linkTo} onClick={handleClick}>
      {linkText ? linkText : "click here"}
    </Link>
  );
};

export default ClickHereLink;
