import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    layout: {
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(1, 2, 1),
    },
    intensionText: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    intensionLocationText: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
    },
    formLabel: {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: size.LIST_TITLEFONTSIZE,
      marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(1.5),
      "&.Mui-focused": {
        color: theme.palette.text.primary,
      },
    },
    currentDateTimeformLabel: {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: size.LIST_TITLEFONTSIZE,
      marginTop: theme.spacing(3),
    },
    text: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
    },
    formSameLine: {
      display: "flex",
      flexDirection: "row",
    },
    formFixedWidth: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      width: 180,
      marginTop: 4,
      marginBottom: 4,
    },
    formFixedWidthBoldUnderline: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      marginTop: 4,
      marginBottom: 4,
      marginRight: 0,
      marginLeft: "-4.7rem",
      textDecoration: "underline",
    },
    apptDateTimeField: {
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(1, 2),
      display: "flexbox",
      flexDirection: "column",
      marginTop: theme.spacing(2),
      borderRadius: "0.5rem",
    },
    leftDatepickerContainer: {
      width: "50%",
      marginTop: theme.spacing(1),
      margin: theme.spacing(1.5, 2, 1, 0),
    },
    rightDatepickerContainer: {
      width: "50%",
      marginTop: theme.spacing(1),
      margin: theme.spacing(1.5, 0, 1),
    },
    datepicker: {
      marginRight: "45px",
    },
    multiLineTextFieldLabel: {
      color: theme.palette.grey[600],
      fontSize: size.FONTSIZE_14,
      margin: 0,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
    },
    radioGroup: {
      height: "2.2rem",
    },
    radio: {
      "&$checked": {
        color: theme.palette.primary.main,
      },
      color: theme.palette.primary.main,
    },
    checked: {},
    icon: {},
    remarkLabel: {
      color: theme.palette.grey[600],
      fontSize: size.FONTSIZE_14,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    dropdown: {
      margin: theme.spacing(1.5, 0, 2),
    },
    errText: {
      color: theme.palette.error.main,
      typography: theme.typography.caption1_regular,
      padding: theme.spacing(0.5, 1.5),
    },
    textBox: {
      margin: theme.spacing(3, 0, 2),
    },
  } satisfies SxObjType;
};
export { sxStyles };
