import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    title: {
      textAlign: "center",
      color: theme.palette.text.primary,
      typography: theme.typography.header3_semibold,
      marginTop: theme.spacing(8),
    },
    imageContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(3),
    },
    listContainer: {
      margin: theme.spacing(1, 6),
      marginBottom: 0,
    },
    image: {
      height: "184px",
    },
    tickImage: { alignSelf: "baseline" },
    verifyYourParticularsDescription: {
      typography: theme.typography.body1_regular,
      color: theme.palette.grey[600],
      marginLeft: theme.spacing(1),
      alignSelf: "baseline",
    },
    bottomContainer: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      gap: 1,
    },
  } satisfies SxObjType;
};
export { sxStyles };
