import { Box } from "@mui/material";
import IMAGES from "lib/assets/images";
import CompactCard from "lib/components/cards/CompactCard/CompactCard";
import { PATHS } from "lib/routing";
import { navigateToExternalInMobileBrowser } from "lib/routing/navigate/navigate";
import { useNavigate } from "react-router-dom";
import { sxStyles } from "./ExploreHealthTogetherSection.styles";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";

const TITLES = {
  healthLibrary: "Health Library",
  selfHelp: "Self-Help Tools",
  events: "Events",
  ccp: "Community Care Programmes",
};
const HEALTH_LIBRARY_BODY =
  "Learn how to manage your health through our library of curated resources";
const SELF_HELP_BODY =
  "Take charge of your health using our recommended tools and resources";
const EVENTS_BODY =
  "Find health screenings, community health events and talks near you";
const CCP_BODY =
  "Discover community care, medical services, and health resources near you";
const EVENTS_URL =
  "https://www.nuhs.edu.sg/Care-in-the-Community/Enabling-Community-Care/Health-Together/Events/Pages/default.aspx";
const HEALTH_LIBRARY_URL =
  "https://www.nuhs.edu.sg/Care-in-the-Community/Enabling-Community-Care/Health-Together/Health-Library/Pages/default.aspx";
const MHM_CARD_TITLE = "My Health Map";
const MHM_CARD_BODY =
  "Find recommended health screenings and vaccinations based on your age and gender";

const ExploreHealthTogetherSection = () => {
  const classes = sxStyles();
  const navigate = useNavigate();

  const handleOnClickNext = () => {
    logEventToGoogleAnalytics(EVENTS.CLICK_HT_MHM);
    navigate(PATHS.MHM_DASHBOARD.path);
  };

  return (
    <Box sx={classes.mainContainer}>
      <CompactCard
        title={TITLES.events}
        titleIcon={IMAGES.healthTogetherMHM.EventsIcon}
        body={EVENTS_BODY}
        onClickCard={() => {
          logEventToGoogleAnalytics(EVENTS.CLICK_HT_EVENTS);
          navigateToExternalInMobileBrowser(EVENTS_URL);
        }}
      />
      <CompactCard
        title={TITLES.ccp}
        titleIcon={IMAGES.healthTogetherMHM.CommunityCareIcon}
        body={CCP_BODY}
        onClickCard={() => {
          logEventToGoogleAnalytics(EVENTS.CLICK_HT_COMMUNITY_CARE_PROGRAMMES);
          navigate(PATHS.HT_COMMUNITY_CARE.path);
        }}
      />
      <CompactCard
        title={TITLES.selfHelp}
        titleIcon={IMAGES.healthTogetherMHM.SelfAssessmentIcon}
        body={SELF_HELP_BODY}
        onClickCard={() => {
          logEventToGoogleAnalytics(EVENTS.CLICK_HT_SELF_ASSESSMENT_TOOLS);
          navigate(PATHS.HT_SELF_HELP_TOOLS.path);
        }}
      />
      <CompactCard
        title={TITLES.healthLibrary}
        titleIcon={IMAGES.healthTogetherMHM.HealthLibraryIcon}
        body={HEALTH_LIBRARY_BODY}
        onClickCard={() => {
          logEventToGoogleAnalytics(EVENTS.CLICK_HEALTH_LIBRARY_OTHER_ARTICLES);
          navigateToExternalInMobileBrowser(HEALTH_LIBRARY_URL);
        }}
      />

      <CompactCard
        title={MHM_CARD_TITLE}
        titleIcon={IMAGES.healthTogetherMHM.MHMIcon}
        body={MHM_CARD_BODY}
        onClickCard={handleOnClickNext}
      />
    </Box>
  );
};

export default ExploreHealthTogetherSection;
