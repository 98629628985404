export enum size {
  // ========================
  // Fontsize
  // ========================
  FONTSIZE_9 = 9,
  FONTSIZE_10 = 10,
  FONTSIZE_12 = 12,
  FONTSIZE_13 = 13,
  FONTSIZE_14 = 14,
  FONTSIZE_15 = 15,
  FONTSIZE_16 = 16,
  FONTSIZE_18 = 18,
  FONTSIZE_20 = 20,
  FONTSIZE_22 = 22,
  FONTSIZE_24 = 24,
  FONTSIZE_30 = 30,

  // ========================
  // Padding and Margin spacing
  // ========================
  SPACING = 8,

  // ========================
  // Default button height
  // ========================
  BUTTONHEIGHT = 48,

  LIST_TITLEFONTSIZE = "4.75vw",

  TOAST_ICON = "2rem",

  TITLE_FONTSIZE = "5vw",

  BORDER_RADIUS = "8px",

  INPUT_BORDER_RADIUS = "4px",
}
