import { connect } from "react-redux";
import RegisteredAppointmentArea from "./RegisteredAppointmentArea";
import {
  updateDetailsForActualiseAppointment,
  fetchQueueStatusAndJourney,
  setQueueInitializeHasErrored,
} from "ui/appointment/ducks";
import { RootDispatch, RootState } from "lib/redux/root/redux.types";
import { OwnProps } from "./RegisteredAppointmentArea.types";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const apptId = ownProps.appointment.appointmentId;
  const apptDetail = state.appointments.register[apptId];
  return {
    isLoading: apptDetail ? apptDetail.isLoading : false,
    hasErrored: apptDetail ? apptDetail.hasErrored : null,
    errorMessage: apptDetail ? apptDetail.errorMessage : null,
    currentStation: apptDetail ? apptDetail.currentStation : null,
    canProceedToVirtualConsult: apptDetail
      ? apptDetail.canProceedToVirtualConsult
      : null,

    queueStatus: apptDetail ? apptDetail.queueStatus : null,
    patientWaiting: apptDetail ? apptDetail.patientWaiting : null,
    statusMessage: apptDetail ? apptDetail.statusMessage : null,
    queueNumber: apptDetail ? apptDetail.queueNumber : null,
    showStatus: apptDetail ? apptDetail.showStatusText : false,
    showPatientWaiting: apptDetail ? apptDetail.showPatientWaiting : false,
    institutionName: apptDetail ? apptDetail.institutionName : null,
    memberIdentifier: state.user.memberIdentifier,
    hintMsg: apptDetail ? apptDetail.hintMsg : null,
    encounterAction: apptDetail ? apptDetail.encounterAction : [""],
    requireCheckIn: apptDetail ? apptDetail.requireCheckIn : false,
    iHaveArrivedMessage: apptDetail ? apptDetail.ihaveArrivedMessage : null,
    currentClinicCode: apptDetail ? apptDetail.currentClinicCode : null,
    meetingId: apptDetail ? apptDetail.meetingId : null,
    meetingIdFormat: apptDetail ? apptDetail.meetingIdFormat : null,
    passcode: apptDetail ? apptDetail.passcode : null,
    lateThresholdMessage: apptDetail ? apptDetail.lateThresholdMessage : null,
    vcSupportStatus: ownProps.appointment.vcSupportStatus,
  };
};

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    onClick: (
      appointmentId: string,
      regAppointmentId: string,
      regInstitutionCode: string,
      institutionCode: string,
      sourceSystem: string,
      clinicCode: string | null,
      actualizeStatus: string | null,
      queueNumber: string | null,
      institutionName: string | null,
      consultType: string | null,
      encounterId: string | null,
      appointmentTimeToArrive: string | null,
      vcSupportStatus: string | null,
    ) => {
      dispatch(
        updateDetailsForActualiseAppointment({
          appointmentId,
          regAppointmentId,
          regInstitutionCode,
          institutionCode,
          sourceSystem,
          clinicCode,
          actualizeStatus,
          queueNumber,
          institutionName,
          consultType,
          encounterId,
          appointmentTimeToArrive,
          vcSupportStatus,
        }),
      );
    },
    onRefresh: ({
      appointmentId,
      regAppointmentId,
      encounterId,
      appointmentTime,
      vcSupportStatus,
    }: {
      appointmentId: string;
      encounterId: string | null;
      regAppointmentId: string | null;
      appointmentTime: string | null;
      vcSupportStatus: string | null;
    }) => {
      dispatch(
        fetchQueueStatusAndJourney({
          appointmentId,
          regAppointmentId,
          encounterId,
          appointmentTime,
          vcSupportStatus,
        }),
      );
    },
    resetErrorState: (appointmentId: string) => {
      dispatch(
        setQueueInitializeHasErrored({ appointmentId, hasErrored: null }),
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisteredAppointmentArea);
