import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    mainContainer: {
      paddingTop: theme.spacing(2),
    },
    content: {
      textAlign: "left",
      typography: theme.typography.body1_regular,
      color: theme.palette.grey[600],

      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    cardDetails: {
      minHeight: "100%",
      wordBreak: "break-word",
      display: "flex",
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      alignItems: "start",
    },
    cardLeft: {
      width: "47%",
      marginRight: "2%",
    },
    cardRight: {
      textAlign: "left",
      color: theme.palette.common.black,
      typography: theme.typography.body2_semibold,
      width: "49%",
      marginLeft: "2%",
      display: "flex",
    },
    cardTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.body1_semibold,
    },
    imageContainer: {
      paddingRight: theme.spacing(1),
    },
    image: {
      width: "14px",
      verticalAlign: "bottom",
    },
    cardHeader: {
      display: "flex",
      background: theme.palette.secondary.main,
      padding: theme.spacing(0.5, 1),
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      alignItems: "center",
    },
    cardHeaderLeft: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      width: "100%",
      display: "inherit",
    },
    cardSubHeader: {
      color: theme.palette.grey[400],
      typography: theme.typography.body1_semibold,
      width: "100%",
      display: "inherit",
    },
    infoIcon: {
      marginRight: theme.spacing(1),
    },
    cardLabel: {
      color: theme.palette.grey[400],
      typography: theme.typography.body1_semibold,
      opacity: "0.6",
    },
    cardValue: {
      color: theme.palette.grey[400],
      typography: theme.typography.body1_semibold,
    },
    cardDetailLabel: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      opacity: "0.6",
    },
    cardDetailValue: {
      color: theme.palette.grey[400],
      typography: theme.typography.body1_semibold,
      opacity: "0.6",
    },
    spotAnyIssuesLink: {
      color: theme.palette.primary.main,
      typography: theme.typography.caption1_semibold,
    },
    cardRightNoDifferenceContainter: {
      marginLeft: "14px",
    },
  } satisfies SxObjType;
};
export { sxStyles };
