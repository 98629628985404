//To convert yyyy-MM-ddThh:mm:ss to yyyy-MM-dd
const convertISOStringToCustomDateString = (ISOString: string | null) => {
  try {
    if (!ISOString) {
      return "";
    } else {
      var d_reg = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
      if (d_reg.test(ISOString)) {
        var customDateString = ISOString.substring(0, ISOString.indexOf("T"));
        return customDateString;
      }
      return "";
    }
  } catch (error) {
    return "";
  }
};

export { convertISOStringToCustomDateString };
