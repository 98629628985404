import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    listItem: {
      padding: theme.spacing(0),
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
    listTitle: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_semibold,
    },
    button: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(1.75),
    },
    icon: {
      color: theme.palette.primary.main,
      fontSize: size.FONTSIZE_20,
    },
  } satisfies SxObjType;
};

export { sxStyles };
