import { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { sxStyles } from "./ErrorDisplay.styles";
import { ErrorDisplayProps } from "./ErrorDisplay.types";
import IMAGES from "lib/assets/images";
import { renderErrorMessage } from "./renderErrorMessage";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { ACTIONS } from "lib/routing";

const DEFAULT_ERROR =
  "Our application encountered an issue. Please try again later.";

const ErrorDisplay = ({
  showTitle = false,
  errorMessage = null,
  showErrorImage = true,
  currentFlowAction = null,
  showDoItLater = false,
  isLiveChatEnabled = false,
  onTryAgain,
}: ErrorDisplayProps) => {
  const classes = sxStyles();

  const [disabled, setDisabled] = useState(false);

  const handleTryAgain = () => {
    setDisabled(true);
    if (onTryAgain) {
      onTryAgain();
    }
    setDisabled(false);
  };

  const handleGoDashboard = () => {
    mobileNavigate(ACTIONS.PM_SYNC_DO_IT_LATER);
  };

  return (
    <Box>
      {showErrorImage ? (
        <Box sx={classes.pageError}>
          <Box sx={classes.noRecordImage}>
            <Box component={"img"} src={IMAGES.general.PageError} alt="Error" />
          </Box>
          <Box pl={2} pr={2}>
            <Typography sx={classes.errorText} component={"span"}>
              {errorMessage
                ? renderErrorMessage(
                    errorMessage,
                    currentFlowAction,
                    isLiveChatEnabled,
                  )
                : DEFAULT_ERROR}
            </Typography>
          </Box>
          <Box pl={2} pr={2}>
            {onTryAgain && !showDoItLater ? (
              <Button
                variant="text"
                onClick={handleTryAgain}
                disabled={disabled}
              >
                Try again
              </Button>
            ) : (
              onTryAgain && (
                <>
                  <Button
                    variant="text"
                    onClick={handleTryAgain}
                    disabled={disabled}
                  >
                    Try again
                  </Button>
                  <Typography sx={classes.errorText} component="span">
                    or
                  </Typography>
                  <Button
                    variant="text"
                    onClick={handleGoDashboard}
                    disabled={disabled}
                  >
                    Do it later
                  </Button>
                </>
              )
            )}
          </Box>
        </Box>
      ) : (
        <>
          {showTitle && (
            <Typography sx={classes.errorTitleWithIcon}>
              <Box
                component={"img"}
                src={IMAGES.general.ErrorIcon}
                sx={classes.errorIcon}
              />
              <Typography component={"span"} sx={classes.errorTitleWithIcon}>
                {"Service Error"}
              </Typography>
            </Typography>
          )}

          <Box p={0.5}>
            <Typography sx={classes.errorText} component={"span"}>
              {errorMessage
                ? renderErrorMessage(
                    errorMessage,
                    currentFlowAction,
                    isLiveChatEnabled,
                  )
                : DEFAULT_ERROR}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ErrorDisplay;
