import { ExitDialogProps } from "./ExitProfileDialog.types";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { PATHS, ACTIONS } from "lib/routing";
import { useNavigate } from "react-router-dom";
import mapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectPMSettingsValue } from "../../PatientMaster/ducks/selectors";

import { setHasFetchedEpicProfile } from "../../PatientMaster/ducks";

const MODAL_BODY = `This profile is for app-related request only and is
separate from your records with us in NUHS. Do inform
the respective healthcare institution on any change in
your contact information.`;

const ExitProfileDialog = ({
  open,
  profileAccessEntryPoint = "SYSTEM AND PROFILE LISTING",
}: ExitDialogProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Redux states
  const { successMessages } = useAppSelector(selectPMSettingsValue);

  const modalBody = () => {
    let modalBody = MODAL_BODY;
    switch (profileAccessEntryPoint) {
      case "SYSTEM AND PROFILE LISTING":
        modalBody = successMessages.syncParticularsSuccess.description ?? "";
        break;
      case "PM ENTRY":
        modalBody = successMessages.syncParticularsSuccess.description ?? "";
        break;
      case "APPOINTMENT":
        modalBody = successMessages.syncParticularsSuccess.description ?? "";
        break;
      case "MY HEALTH MAP":
        modalBody = MODAL_BODY;
        break;
      case "HEALTH TOGETHER":
        modalBody = MODAL_BODY;
        break;
      default:
        modalBody =
          MODAL_BODY +
          "<br />" +
          "Proceed to the dashboard to continue with eservices.";
        break;
    }
    return mapRawStringToReactElement(modalBody);
  };

  const nextButtonText = () => {
    switch (profileAccessEntryPoint) {
      case "SYSTEM AND PROFILE LISTING":
        return "OK";
      case "PM ENTRY":
        return "OK";
      case "APPOINTMENT":
        return "OK";
      case "MY HEALTH MAP":
        return "OK";
      case "HEALTH TOGETHER":
        return "OK";
      default:
        return "Proceed to Dashboard";
    }
  };

  const onCLickExit = () => {
    switch (profileAccessEntryPoint) {
      case "SYSTEM AND PROFILE LISTING":
        dispatch(setHasFetchedEpicProfile(false));
        mobileNavigate(ACTIONS.PROFILE_SYNC_SUCCESS);
        break;
      case "PM ENTRY":
        dispatch(setHasFetchedEpicProfile(false));
        mobileNavigate(ACTIONS.PROFILE_SYNC_SUCCESS);
        break;
      case "APPOINTMENT":
        dispatch(setHasFetchedEpicProfile(false));
        mobileNavigate(ACTIONS.PROFILE_SYNC_SUCCESS);
        break;
      case "MY HEALTH MAP":
        navigate(PATHS.MHM_DASHBOARD_MOBILE.path, { replace: true });
        break;
      case "HEALTH TOGETHER":
        // history.replace(PATHS.HT_VERIFY_PARTICULARS.path);
        // TODO: need to handle ht verify
        break;
      default:
        mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH);
        break;
    }
  };

  return (
    <ConfirmationModal
      title={
        profileAccessEntryPoint === "SYSTEM AND PROFILE LISTING" ||
        profileAccessEntryPoint === "PM ENTRY" ||
        profileAccessEntryPoint === "APPOINTMENT"
          ? successMessages.syncParticularsSuccess.title
          : "Profile saved successfully!"
      }
      body={modalBody()}
      nextButtonText={nextButtonText()}
      hideCancelButton={true}
      open={open}
      onClickNext={onCLickExit}
      cancelButtonText={"Back to dashboard"}
      showCloseButton={
        profileAccessEntryPoint === "SYSTEM AND PROFILE LISTING" ||
        profileAccessEntryPoint === "PM ENTRY" ||
        profileAccessEntryPoint === "APPOINTMENT"
          ? false
          : true
      }
      onClose={onCLickExit}
    />
  );
};

export default ExitProfileDialog;
