import { PATHS, ACTIONS } from "lib/routing";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { Path } from "lib/routing/paths/paths.types";
import {
  CONNECT_TO_LIVE_AGENT_BUTTON_TEXT,
  INCOMPLETE_PROFILE_ERROR_BUTTON_TEXT,
} from "./ErrorModal";
import { FlowAction } from "lib/redux/navigation/navigation.types";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import {
  setProfileAccessEntryPoint,
  setShouldDirectToMyInfo,
} from "lib/redux/user";
import { NavigateFunction } from "react-router-dom";

export enum ErrorModalTitles {
  oops = "Oops...",
  needHelp = "Need help?",
  approachRegCounter = "Approach registration counter",
  unableMakeAppt = "Unable to make appointment request",
  phoneNotUpdated = "Phone number not updated",
  reqNotSent = "Request not sent",
  incompleteParticulars = "Incomplete particulars",
  unableCreateReqSummary = "Unable to create request summary",
  accessCodeError = "Access code error",
  serviceError = "Service error",
}

/**
 * The function checks if the given path is related to creating, canceling, or rescheduling an
 * appointment.
 * @param {Path | null} path - The `path` parameter is of type `Path | null`. It represents a path in
 * the application.
 * @returns a boolean value.
 */
export const pathCreateCancelRescheduleHelper = (path: Path | null) => {
  return (
    path === PATHS.APPOINTMENT_CREATE_CONFIRM ||
    path === PATHS.APPOINTMENT_RESCHEDULE_CONFIRM ||
    path === PATHS.APPOINTMENT_RESCHEDULE_LINKED_CONFIRM ||
    path === PATHS.APPOINTMENT_RESCHEDULE_LINKED_CONFIRM_MOBILE ||
    path === PATHS.APPOINTMENT_RESCHEDULE_BUNDLED_LINKED_CONFIRM ||
    path === PATHS.APPOINTMENT_CANCEL_MOBILE ||
    path === PATHS.APPOINTMENT_CANCEL
  );
};

export const pathMyInfoHelper = (path: Path | null) => {
  return (
    path === PATHS.MY_INFOPROFILE ||
    path === PATHS.MY_PROFILE ||
    path === PATHS.MY_PROFILE_MOBILE ||
    path === PATHS.MY_PROFILE_MOBILE_ERROR ||
    path === PATHS.MYINFO_AND_PROFILE_CONFIRM
  );
};

/**
 * The function checks if a specific condition is met and if a certain error message contains a
 * specific link, and returns true if both conditions are met.
 * @param {string | null} errorMessage - A string that represents an error message. It can be null if
 * there is no error.
 * @param {boolean} pathCreateCancelReschedule - A boolean value indicating whether the path for
 * creating, canceling, or rescheduling a request is available.
 * @returns a boolean value.
 */
export const shouldDisplayMakeOfflineRequestTextHelper = (
  errorMessage: string | null,
  pathCreateCancelReschedule: boolean,
) => {
  if (pathCreateCancelReschedule) {
    const generalRequestFormLinkReg =
      /<a href\s*=\s*\\*['"]\[REQUESTFORMLINK\]\\*['"][^>]*>([^<]+)<\/a>/g;
    let currentErrorMessage: string | null;

    if (errorMessage != null) {
      currentErrorMessage = errorMessage.replace(
        generalRequestFormLinkReg,
        "Tap on the button 'Make offline request'",
      );
      if (currentErrorMessage !== errorMessage) {
        return true;
      }
      return false;
    }
    return false;
  }
  return false;
};

/**
 * The function checks if a path is for creating, canceling, or rescheduling an appointment and if the
 * customized button text matches a specific value.
 * @param {boolean} pathCreateCancelReschedule - A boolean value indicating whether the path for
 * creating, canceling, or rescheduling is enabled or not.
 * @param {string | null} [customizedButtonText] - The `customizedButtonText` parameter is an optional
 * string that represents the text of a button.
 * @returns a boolean value.
 */
export const liveAgentCreateCancelRescheduleHelper = (
  pathCreateCancelReschedule: boolean,
  customizedButtonText?: string | null,
) => {
  if (
    pathCreateCancelReschedule &&
    customizedButtonText?.toLocaleUpperCase() ===
      CONNECT_TO_LIVE_AGENT_BUTTON_TEXT.toLocaleUpperCase()
  ) {
    return true;
  }
  return false;
};

/**
 * The function `toMapToPath` takes a `currentFlowAction` and a `navigate` function as parameters and
 * navigates to different paths based on the value of `currentFlowAction`.
 * @param {FlowAction | null} currentFlowAction - The `currentFlowAction` parameter is of type
 * `FlowAction | null`. It represents the current flow action that needs to be mapped to a specific
 * path.
 * @param {NavigateFunction} navigate - The `navigate` parameter is a function that is used to navigate
 * to a specific path in the application. It takes a single argument, which is the path to navigate to.
 */
export const toMapToPath = (
  currentFlowAction: FlowAction | null,
  navigate: NavigateFunction,
) => {
  switch (currentFlowAction) {
    case "APPOINTMENT_CREATE":
      navigate(PATHS.APPOINTMENT_CREATE_FORM.path);
      break;
    case "APPOINTMENT_RESCHEDULE":
      navigate(PATHS.APPOINTMENT_RESCHEDULE_FORM.path);
      break;
    case "APPOINTMENT_CANCEL":
      navigate(PATHS.APPOINTMENT_CANCEL_FORM.path);
      break;
    default:
      navigate(PATHS.APPOINTMENT_MOBILE.path);
  }
};

/**
 * The function `renderTitle` returns a string based on the provided `path` parameter, with some
 * additional logic for specific cases.
 * @param {Path | null} path - A string representing a specific path or route. It is used to determine
 * the appropriate title to render based on the path.
 * @param {string | null} [customizedButtonText] - The `customizedButtonText` parameter is an optional
 * string that can be passed to the `renderTitle` function. It is used in the `case
 * PATHS.MHM_FIRST_TIME_USER_VERIFY_PARTICULARS` block to check if the value is equal to
 * `INCOMPLETE_PROFILE_ERROR_BUTTON_TEXT`.
 * @returns The function `renderTitle` returns a string value based on the provided `path` parameter.
 * The returned string represents a title or error message for a specific path.
 */
export const renderTitle = (
  path: Path | null,
  customizedButtonText?: string | null,
) => {
  switch (path) {
    // Appointment
    case PATHS.APPOINTMENT_REGISTER_KIOSK:
    case PATHS.APPOINTMENT_REGISTER_KIOSK_MOBILE:
    case PATHS.APPOINTMENT_CHECKIN:
    case PATHS.APPOINTMENT_CHECKIN_MOBILE:
      return ErrorModalTitles.oops;
    case PATHS.APPOINTMENT_CANCEL:
    case PATHS.APPOINTMENT_CANCEL_MOBILE:
      return ErrorModalTitles.needHelp;
    case PATHS.APPOINTMENT_CREATE_CONFIRM:
      return ErrorModalTitles.needHelp;
    case PATHS.APPOINTMENT_RESCHEDULE_CONFIRM:
      return ErrorModalTitles.needHelp;
    case PATHS.APPOINTMENT_RESCHEDULE_BUNDLED_LINKED_CONFIRM:
    case PATHS.APPOINTMENT_RESCHEDULE_LINKED_CONFIRM:
    case PATHS.APPOINTMENT_RESCHEDULE_LINKED_CONFIRM_MOBILE:
      return ErrorModalTitles.needHelp;
    case PATHS.APPOINTMENT_CREATE_FORM:
    case PATHS.APPOINTMENT_RESCHEDULE_FORM:
    case PATHS.APPOINTMENT_CANCEL_FORM:
    case PATHS.APPOINTMENT_RESCHEDULE_FORM_MOBILE:
    case PATHS.APPOINTMENT_CANCEL_FORM_MOBILE:
      return ErrorModalTitles.unableMakeAppt;
    case PATHS.APPOINTMENT_QUEUECHIT_JOURNEY:
      return ErrorModalTitles.oops;
    case PATHS.APPOINTMENT_QUEUECHIT_JOURNEY_MOBILE:
    case PATHS.APPOINTMENT_QUEUECHIT_JOURNEY_NO_BACK:
      return ErrorModalTitles.phoneNotUpdated;

    // Medrefill
    case PATHS.MED_REFILL_ORDER_SUMMARY:
    case PATHS.MED_TOPUP_ORDER_SUMMARY:
      return ErrorModalTitles.reqNotSent;

    // My Health Map
    case PATHS.MHM_FIRST_TIME_USER_VERIFY_PARTICULARS:
      if (customizedButtonText) {
        if (
          customizedButtonText.toLocaleUpperCase() ===
          INCOMPLETE_PROFILE_ERROR_BUTTON_TEXT.toLocaleUpperCase()
        )
          return ErrorModalTitles.incompleteParticulars;
      }
      break;
    // Mrrp
    case PATHS.MRRP_NEW_REQUEST_PAYMENT:
      return ErrorModalTitles.unableCreateReqSummary;

    //HT
    case PATHS.HT_MOBILE:
      return ErrorModalTitles.accessCodeError;

    //MyInfoProfile
    case PATHS.MY_INFOPROFILE:
      return ErrorModalTitles.oops;
    case PATHS.MYINFO_AND_PROFILE_CONFIRM:
      return ErrorModalTitles.oops;

    //MyProfile
    case PATHS.MY_PROFILE:
      return ErrorModalTitles.oops;
    case PATHS.MY_PROFILE_MOBILE:
      return ErrorModalTitles.oops;
    case PATHS.MY_PROFILE_MOBILE_ERROR:
      return ErrorModalTitles.oops;

    // Default
    default:
      return ErrorModalTitles.serviceError;
  }
};

/**
 * The function `toShowBody` takes in several parameters and returns an error display container
 * component with a modified error message if certain conditions are met.
 * @param {string | null} errorMessage - A string that represents an error message. It can be null if
 * there is no error.
 * @param {boolean} pathCreateCancelReschedule - A boolean value indicating whether the user is on the
 * path to create, cancel, or reschedule something.
 * @param {boolean} shouldDisplayMakeOfflineRequestText - A boolean value indicating whether the "MAKE
 * OFFLINE REQUEST" button should be displayed.
 * @param {string | null} customizedButtonText - A string that represents the customized button text.
 * @returns a JSX element.
 */
export const toShowBody = (
  errorMessage: string | null,
  pathCreateCancelReschedule: boolean,
  pathMyInfo: boolean,
  pathIHA: boolean,
  shouldDisplayMakeOfflineRequestText: boolean,
  customizedButtonText: string | null,
) => {
  const requestFormLinkReg =
    /<a href\s*=\s*\\*['"]\[REQUESTFORMLINK\]\\*['"][^>]*>click here<\/a>/g; //click here
  const requestFormLinkReg2 =
    /<a href\s*=\s*\\*['"]\[REQUESTFORMLINK\]\\*['"][^>]*>here<\/a>/g; //here
  const requestFormLinkReg3 =
    /<a href\s*=\s*\\*['"]\[REQUESTFORMLINK\]\\*['"][^>]*>submit a request([^<]+)<\/a>/g; //submit a request online
  let newErrorMessage: string | null;
  if (pathCreateCancelReschedule) {
    if (shouldDisplayMakeOfflineRequestText) {
      if (
        customizedButtonText?.toLocaleUpperCase() ===
        CONNECT_TO_LIVE_AGENT_BUTTON_TEXT.toLocaleUpperCase()
      ) {
        newErrorMessage = errorMessage!.replace(
          requestFormLinkReg,
          "tap on the button 'Chat with us'",
        );
        newErrorMessage = newErrorMessage.replace(
          requestFormLinkReg2,
          "on the button 'Chat with us'",
        );
        newErrorMessage = newErrorMessage.replace(
          requestFormLinkReg3,
          "tap on the button 'Chat with us'",
        );
      } else {
        newErrorMessage = errorMessage!.replace(
          requestFormLinkReg,
          "tap on the button 'Make offline request'",
        );
        newErrorMessage = newErrorMessage.replace(
          requestFormLinkReg2,
          "on the button 'Make offline request'",
        );
        newErrorMessage = newErrorMessage.replace(
          requestFormLinkReg3,
          "tap on the button 'Make offline request' to submit a request online",
        );
      }
      if (newErrorMessage !== errorMessage) {
        return (
          <ErrorDisplayContainer
            errorMessage={newErrorMessage}
            showErrorImage={false}
          />
        );
      }
    }
  } else if (pathMyInfo) {
    newErrorMessage =
      "We are unable to update your particulars. You may wish to try again later.";
    return (
      <ErrorDisplayContainer
        errorMessage={newErrorMessage}
        showErrorImage={false}
      />
    );
  } else if (pathIHA) {
    newErrorMessage =
      "We need more information from you to complete your registration. Please proceed to the counter for assistance.";
    return (
      <ErrorDisplayContainer
        errorMessage={newErrorMessage}
        showErrorImage={false}
      />
    );
  }
  return (
    <ErrorDisplayContainer errorMessage={errorMessage} showErrorImage={false} />
  );
};

/**
 * The function `renderNextButtonText` returns a string based on the provided parameters.
 * @param {string | null} customizedButtonText - A string that represents a customized button text. It
 * can be null if there is no customized text.
 * @param {boolean} pathCreateCancelReschedule - A boolean value indicating whether the user is on a
 * path to create, cancel, or reschedule an appointment.
 * @param {boolean} shouldDisplayMakeOfflineRequestText - A boolean value indicating whether the "Make
 * Offline Request" text should be displayed.
 * @returns a string value. The specific string value that is returned depends on the conditions
 * specified in the code.
 */
export const renderNextButtonText = (
  customizedButtonText: string | null,
  pathCreateCancelReschedule: boolean,
  pathMyInfo: boolean,
  shouldDisplayMakeOfflineRequestText: boolean,
) => {
  if (customizedButtonText) {
    //can be "CHAT WITH US or Complete My Profile"
    return customizedButtonText;
  } else if (pathCreateCancelReschedule) {
    if (shouldDisplayMakeOfflineRequestText) {
      return "Make offline request";
    } else {
      return "Go to Appointment";
    }
  } else if (pathMyInfo) {
    return "OK";
  } else return "Close";
};

/**
 * The function handles the logic for different scenarios when the next button is clicked in a
 * TypeScript React application.
 * @param closeErrorModal - A function that closes the error modal.
 * @param {string | null} customizedButtonText - A string that represents the customized text for the
 * button.
 * @param {NavigateFunction} navigate - The `navigate` parameter is a function that is used to navigate
 * to a different page or route in the application. It takes two arguments: the path or route to
 * navigate to, and an optional object with additional options for the navigation.
 * @param mapToPath - A function that maps the current path to a new path.
 * @param {Path | null} path - The `path` parameter is a string that represents the current path or
 * route in the application. It is used to determine the next action to be taken when the next button
 * is clicked.
 * @param {boolean} pathCreateCancelReschedule - A boolean value indicating whether the user is in a
 * path where they can create, cancel, or reschedule appointments.
 * @param {boolean} liveAgentCreateCancelReschedule - A boolean value indicating whether the user wants
 * to create, cancel, or reschedule an appointment with a live agent.
 * @param {boolean} shouldDisplayMakeOfflineRequestText - A boolean value indicating whether the "Make
 * Offline Request" text should be displayed.
 * @param {any} dispatch - The `dispatch` parameter is a function that is used to dispatch actions to
 * the Redux store. It is typically provided by the Redux library and is used to update the state of
 * the application.
 * @param {any} userProfile - The `userProfile` parameter is an object that contains information about
 * the user's profile. It may include properties such as `cancellationPath`, which represents the path
 * to navigate to when the user cancels an appointment.
 */
export const toHandleNextButtonClick = (
  closeErrorModal: () => void,
  customizedButtonText: string | null,
  navigate: NavigateFunction,
  mapToPath: () => void,
  path: Path | null,
  pathCreateCancelReschedule: boolean,
  liveAgentCreateCancelReschedule: boolean,
  shouldDisplayMakeOfflineRequestText: boolean,
  dispatch: any,
  userProfile: any,
) => {
  closeErrorModal();
  if (path === PATHS.APPOINTMENT_REGISTER_KIOSK) {
    //"Close"
    logEventToGoogleAnalytics(EVENTS.CLICK_CLOSE_APPT_QUESTIONNAIRE_ERROR);
    navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
  } else if (path === PATHS.APPOINTMENT_REGISTER_KIOSK_MOBILE) {
    //"Close"
    logEventToGoogleAnalytics(EVENTS.CLICK_CLOSE_APPT_QUESTIONNAIRE_ERROR);
    mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH);
  } else if (path === PATHS.MYINFO_AND_PROFILE_CONFIRM) {
    dispatch(
      setShouldDirectToMyInfo({
        shouldDirectToMyInfo: false,
        cancellationPath: userProfile.cancellationPath ?? undefined,
      }),
    );
    // history.push(PATHS.MY_INFOPROFILE.path);
  } else if (path === PATHS.MHM_FIRST_TIME_USER_VERIFY_PARTICULARS) {
    if (
      customizedButtonText?.toLocaleUpperCase() ===
      INCOMPLETE_PROFILE_ERROR_BUTTON_TEXT.toLocaleUpperCase()
    ) {
      dispatch(
        setShouldDirectToMyInfo({
          shouldDirectToMyInfo: true,
          cancellationPath: PATHS.MHM_DASHBOARD_MOBILE.path,
        }),
      );
      dispatch(setProfileAccessEntryPoint("MY HEALTH MAP"));
      setTimeout(
        () => navigate(PATHS.MY_INFOPROFILE.path, { replace: true }),
        120,
      );
    } else {
      //"Close"
      mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH);
    }
  } else if (liveAgentCreateCancelReschedule) {
    logEventToGoogleAnalytics(EVENTS.SELECT_LIVECHAT_APPOINTMENT_MODULE);
    //"CHAT WITH US"
    mobileNavigate(
      ACTIONS.LIVECHAT_PAGE_INTERCEPTION(PATHS.APPOINTMENT_MOBILE.path),
    );
  } else if (
    pathCreateCancelReschedule &&
    shouldDisplayMakeOfflineRequestText
  ) {
    //"Make Offline Request"
    mapToPath();
  } else if (pathCreateCancelReschedule) {
    //"Go to Appointment"
    navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
  } //else just close only
};

/**
 * The function `concealCancelButton` returns `false` if `pathCreateCancelReschedule` is `true` or
 * `customizedButtonText` is not `null`, otherwise it returns `true`.
 * @param {boolean} pathCreateCancelReschedule - A boolean value indicating whether the path to create
 * a cancel/reschedule action exists or not.
 * @param {string | null} [customizedButtonText] - A string that represents a customized text for the
 * cancel button. It is optional and can be null.
 * @returns a boolean value. If either `pathCreateCancelReschedule` is true or `customizedButtonText`
 * is not null or undefined, then it returns false. Otherwise, it returns true.
 */
export const concealCancelButton = (
  pathCreateCancelReschedule: boolean,
  customizedButtonText: string | null,
) => {
  if (pathCreateCancelReschedule || customizedButtonText != null) {
    return false;
  } else {
    return true;
  }
};

/**
 * The function returns a specific text based on the values of two boolean parameters.
 * @param {boolean} shouldDisplayMakeOfflineRequestText - A boolean value indicating whether the "Make
 * Offline Request" text should be displayed.
 * @param {boolean} liveAgentCreateCancelReschedule - A boolean value indicating whether the user has
 * the ability to cancel and reschedule appointments with a live agent.
 * @returns a string value. The specific string value that is returned depends on the values of the
 * input parameters `shouldDisplayMakeOfflineRequestText` and `liveAgentCreateCancelReschedule`.
 */
export const renderCancelButtonText = (
  liveAgentCreateCancelReschedule: boolean,
  shouldDisplayMakeOfflineRequestText: boolean,
) => {
  if (shouldDisplayMakeOfflineRequestText || liveAgentCreateCancelReschedule) {
    return "Go to Appointment";
  } else {
    return "Close";
  }
};

/**
 * The function handles the cancel button click event and performs different actions based on certain
 * conditions.
 * @param {Path | null} path - The `path` parameter is of type `Path` or `null`. It represents the
 * current path or route in the application.
 * @param {string | null} customizedButtonText - A string that represents the text of a customized
 * button.
 * @param closeErrorModal - A function that closes the error modal.
 * @param {boolean} shouldDisplayMakeOfflineRequestText - A boolean value indicating whether the "Make
 * Offline Request" text should be displayed.
 * @param {boolean} liveAgentCreateCancelReschedule - A boolean value indicating whether the
 * cancellation or rescheduling of a live agent appointment is being performed.
 * @param {NavigateFunction} navigate - The `navigate` parameter is a function that is used to navigate
 * to a different page or route in the application. It takes two arguments: the path or route to
 * navigate to, and an optional configuration object. The configuration object can include options such
 * as whether to replace the current route in the navigation history
 * @param mapToPath - A function that maps the current path to a new path.
 */
export const toHandleCancelButtonClick = (
  path: Path | null,
  customizedButtonText: string | null,
  closeErrorModal: () => void,
  pathCreateCancelReschedule: boolean,
  navigate: NavigateFunction,
) => {
  closeErrorModal();
  if (pathCreateCancelReschedule) {
    //"Go to Appointment"
    navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
  } else if (
    path === PATHS.MHM_FIRST_TIME_USER_VERIFY_PARTICULARS &&
    customizedButtonText?.toLocaleUpperCase() ===
      INCOMPLETE_PROFILE_ERROR_BUTTON_TEXT.toLocaleUpperCase()
  ) {
    //"COMPLETE MY PROFILE" is on the 'next' button
    mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH);
  }
};

/**
 * The function shouldShowCloseIcon returns true if either pathCreateCancelReschedule is true or
 * customizedButtonText is not null or undefined, otherwise it returns false.
 * @param {boolean} pathCreateCancelReschedule - A boolean value indicating whether the path is for
 * creating, canceling, or rescheduling something.
 * @param {string | null} [customizedButtonText] - The `customizedButtonText` parameter is an optional
 * parameter of type `string` or `null`. It represents a customized button text that can be provided as
 * an argument to the function `shouldShowCloseIcon`.
 * @returns a boolean value. If either `pathCreateCancelReschedule` is true or `customizedButtonText`
 * is not null or undefined, then the function will return true. Otherwise, it will return false.
 */

export const shouldShowCloseIcon = (
  pathCreateCancelReschedule: boolean,
  customizedButtonText?: string | null,
) => {
  if (pathCreateCancelReschedule || customizedButtonText) {
    return true;
  } else {
    return false;
  }
};

/**
 * The function `toHandleModalClose` closes an error modal and performs additional actions based on the
 * provided parameters.
 * @param closeErrorModal - A function that closes the error modal.
 * @param {Path | null} path - The `path` parameter is of type `Path | null`. It represents a specific
 * path or route in the application. It can be either a valid `Path` value or `null`.
 * @param {string | null} [customizedButtonText] - The `customizedButtonText` parameter is an optional
 * string that represents the text of a customized button. It is used to check if the button text
 * matches a specific value (`INCOMPLETE_PROFILE_ERROR_BUTTON_TEXT`) in order to determine the logic
 * for navigating to a specific path (`PATHS.MHM_FIRST_TIME
 */
export const toHandleModalClose = (
  closeErrorModal: () => void,
  path: Path | null,
  customizedButtonText?: string | null,
) => {
  closeErrorModal();
  if (
    path === PATHS.MHM_FIRST_TIME_USER_VERIFY_PARTICULARS &&
    customizedButtonText?.toLocaleUpperCase() ===
      INCOMPLETE_PROFILE_ERROR_BUTTON_TEXT.toLocaleUpperCase()
  ) {
    //"COMPLETE MY PROFILE" is on the 'next' button
    mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH);
  }
};

/**
 * The function shouldNotDisplayAdditionalButton returns true if neither
 * liveAgentCreateCancelReschedule nor shouldDisplayMakeOfflineRequestText is true, otherwise it
 * returns false.
 * @param {boolean} liveAgentCreateCancelReschedule - A boolean value indicating whether the live agent
 * has the ability to create, cancel, or reschedule an appointment.
 * @param {boolean} shouldDisplayMakeOfflineRequestText - A boolean value indicating whether the "Make
 * Offline Request" text should be displayed.
 * @returns a boolean value. If either `liveAgentCreateCancelReschedule` or
 * `shouldDisplayMakeOfflineRequestText` is true, then the function returns false. Otherwise, it
 * returns true.
 */
export const shouldNotDisplayAdditionalButton = (
  liveAgentCreateCancelReschedule: boolean,
  shouldDisplayMakeOfflineRequestText: boolean,
) => {
  if (liveAgentCreateCancelReschedule || shouldDisplayMakeOfflineRequestText)
    return false;
  return true;
};

/**
 * The function returns a specific text based on the values of two boolean parameters.
 * @param {boolean} liveAgentCreateCancelReschedule - A boolean value indicating whether the user can
 * create, cancel, or reschedule appointments with a live agent.
 * @param {boolean} shouldDisplayMakeOfflineRequestText - A boolean value indicating whether the "Make
 * Offline Request" text should be displayed.
 * @returns The function will return either "GO TO APPOINTMENT" or "CLOSE" based on the values of the
 * parameters `liveAgentCreateCancelReschedule` and `shouldDisplayMakeOfflineRequestText`.
 */
export const renderAdditionalButtonText = () =>
  // liveAgentCreateCancelReschedule: boolean,
  // shouldDisplayMakeOfflineRequestText: boolean,
  {
    // if (liveAgentCreateCancelReschedule && shouldDisplayMakeOfflineRequestText)
    //   return "Go to Appointment";
    return "Close";
  };

/**
 * The function closes an error modal and navigates to the appointment page if certain conditions are
 * met.
 * @param closeErrorModal - A function that closes the error modal.
 * @param {boolean} liveAgentCreateCancelReschedule - A boolean value indicating whether the live agent
 * has the ability to create, cancel, or reschedule appointments.
 * @param {boolean} shouldDisplayMakeOfflineRequestText - A boolean value indicating whether the "Make
 * Offline Request" text should be displayed.
 * @param {NavigateFunction} navigate - The `navigate` parameter is a function that is used for
 * navigating to different screens or routes in the application. It typically takes two arguments: the
 * path or route to navigate to, and an optional configuration object that can specify additional
 * options for the navigation.
 */
export const toHandleAdditionalButtonClick = (
  closeErrorModal: () => void,
  // liveAgentCreateCancelReschedule: boolean,
  // shouldDisplayMakeOfflineRequestText: boolean,
  // navigate: NavigateFunction,
) => {
  closeErrorModal();
  // if (liveAgentCreateCancelReschedule && shouldDisplayMakeOfflineRequestText)
  //   navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true }); //"Go to Appointment"
};
