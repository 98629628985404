import { useState, useEffect } from "react";
import { getQueueStatusAndJourney } from "api/appointment/GetQueueStatusAndJourney/getQueueStatusAndJourney";
import { QueueStatusAndJourney } from "api/appointment/GetQueueStatusAndJourney/getQueueStatusAndJourney.toUi.types";
import { AxiosError } from "axios";

/**
 * A custom hook that fetches user's queue status & journey data from
 * the remote API and returns the appropriate data payload & loading
 * state of that operation.
 *
 * @param {boolean} pageFlag helper flag to trigger the hook
 * @param {string | null} memberIdentifier  Member Identifier of the person we are making this request for
 * @param {string} targetSystem  Target system (Source system) this actualized appointment
 * @param {string} regInstitutionCode  Reg Institution code of the actualized appointment
 * @param {string} queueNumber  Queue number of the actualized appointment
 * @param {string} consultType Consult Type of the actualized appointment
 *
 * @returns {{
 * queueStatusAndJourneyPayload: QueueStatusAndJourney | null,
 * queueStatusAndJourneyIsLoading: boolean,
 * queueStatusAndJourneyHasErrored: boolean | null,
 * queueStatusAndJourneyErrorMessage: string | null}}
 *   0 - Data mapped into QueueStatusAndJourney[]
 *   1 - True if the data fetch is complete, false otherwise.
 *   2 - True if the data fetch completed with errors, false otherwise.
 *   3 - Error message returned from API call
 */
const useGetQueueStatusAndJourney = ({
  memberIdentifier,
  encounterId,
  regAppointmentId,
  appointmentTimeToArrive,
  vcSupportStatus,
}: {
  memberIdentifier: string | null;
  encounterId: string | null;
  regAppointmentId: string | null;
  appointmentTimeToArrive: string | null;
  vcSupportStatus: string | null;
}): {
  queueStatusAndJourneyPayload: QueueStatusAndJourney | null;
  queueStatusAndJourneyIsLoading: boolean;
  queueStatusAndJourneyHasErrored: boolean;
  queueStatusAndJourneyErrorMessage: string | null;
} => {
  const [data, setData] = useState<QueueStatusAndJourney | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const queueStatusAndJourneyResponseData =
          await getQueueStatusAndJourney({
            memberIdentifier,
            regAppointmentId,
            encounterId,
            appointmentTime: appointmentTimeToArrive,
            vcSupportStatus,
          });

        setData(queueStatusAndJourneyResponseData);
        setHasErrored(false);
        setErrorMessage(null);
      } catch (error) {
        setData(null);
        setHasErrored(true);
        if (error instanceof AxiosError) {
          setErrorMessage(error.response?.data.Message);
        } else {
          setErrorMessage(null);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    memberIdentifier,
    encounterId,
    vcSupportStatus,
    appointmentTimeToArrive,
    regAppointmentId,
  ]);

  return {
    queueStatusAndJourneyPayload: data,
    queueStatusAndJourneyIsLoading: isLoading,
    queueStatusAndJourneyHasErrored: hasErrored,
    queueStatusAndJourneyErrorMessage: errorMessage,
  };
};

export { useGetQueueStatusAndJourney };
