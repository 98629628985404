import { SxObjType } from "lib/theme/types/SxObjType.type";
import { Theme } from "@mui/material";

const sxStyles = (theme: Theme) => {
  return {
    mainContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    contenContainer: {},
    buttonsFooterContainer: {
      paddingLeft: 2,
      paddingRight: 2,
      paddingTop: 4,
      paddingBottom: 2,
    },
    expandAllButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    expandAllButton: {
      height: "unset",
      lineHeight: "unset",
      p: 0,
      my: 1,
      mx: 2,
      typography: "body2_semibold",
    },
    summaryContainer: { display: "flex", flexDirection: "column" },
    summaryTitle: {
      typography: "header4_semibold",
      color: theme.palette.grey[400],
      mb: 1,
    },
    summaryValue: {
      typography: "body2_regular",
      color: theme.palette.grey[400],
    },
  } satisfies SxObjType;
};
export { sxStyles };
