import { SxObjType } from "lib/theme/types/SxObjType.type";
import { Theme } from "@mui/material";

const sxStyles = (theme: Theme) => {
  return {
    textBox: {
      margin: theme.spacing(1.5, 0, 0),
    },
    addressGroup: {
      display: "flex",
      "& > div:nth-of-type(1)": {
        marginLeft: 0,
        marginRight: theme.spacing(1),
      },
      "& > div:nth-of-type(2)": {
        // marginRight: 0,
        width: "37%",
        marginRight: theme.spacing(1),
      },
      "& > div:nth-of-type(3)": {
        marginRight: 0,
        width: "37%",
      },
    },
    // formAddress: {
    //   width: "50%",
    //   margin: theme.spacing(1, 1),
    // },
    formLabel: {
      color: theme.palette.grey[400],
      typography: "body2_regular",
    },
    formValue: {
      color: theme.palette.grey[400],
      typography: "body1_semibold",
    },
    streetUnitBlock: {
      marginTop: theme.spacing(2.5),
      display: "flex",
    },
  } satisfies SxObjType;
};
export { sxStyles };
