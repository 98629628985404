import React from "react";
import { RefillPrescriptionBodyProps } from "./RefillPrescriptionBody.types";
import { FormLabel, Box, Typography } from "@mui/material";
import { sxStyles } from "./RefillPrescriptionBody.styles";
import MedicationCard from "./MedicationCard/MedicationCard";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import IconLink from "lib/components/links/IconLink/IconLink";

const RefillPrescriptionBody = ({
  medicationCardDataList,
  prescriptionDisclaimerText,
  setMedicationCardData,
  onClickAddMedicationCard,
  onClickDeleteMedicationCard,
  onImageProcessing,
  disableMedicationCardRegion = false,
}: RefillPrescriptionBodyProps) => {
  const classes = sxStyles();

  return (
    <Box m={2}>
      <Box mt={3} mb={2}>
        <FormLabel sx={classes.formTitle}>Details of Prescription</FormLabel>
        {prescriptionDisclaimerText.map((disclaimerText, index) => {
          return (
            <Box mt={1} key={disclaimerText.content + index}>
              <Typography sx={classes.formContent} component={"span"}>
                {MapRawStringToReactElement(disclaimerText.content)}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box mt={1} mb={2}>
        {medicationCardDataList.map((medicationCardData, currentIndex) => {
          return (
            <MedicationCard
              key={currentIndex}
              index={currentIndex}
              title={`Prescription Sheet ${currentIndex + 1}`}
              medicationCardData={medicationCardData}
              hideDeleteIcon={medicationCardDataList.length > 1 ? false : true}
              setMedicationCardData={(currentMedicationCardData) => {
                setMedicationCardData(currentMedicationCardData, currentIndex);
              }}
              onClickDeleteMedicationCard={onClickDeleteMedicationCard}
              onImageProcessing={(loading, imagePairIndex) => {
                onImageProcessing(loading, currentIndex, imagePairIndex);
              }}
              disabled={disableMedicationCardRegion}
            />
          );
        })}
        {medicationCardDataList && medicationCardDataList.length < 3 ? (
          <Box sx={classes.buttonBox}>
            <IconLink
              title={"Add another sheet"}
              disabled={
                disableMedicationCardRegion ||
                medicationCardDataList[0].frontFileName === null
              }
              icon="addCircleOutline"
              iconPosition="start"
              onClick={
                !disableMedicationCardRegion
                  ? onClickAddMedicationCard
                  : () => {}
              }
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default RefillPrescriptionBody;
