import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    requireBox: {
      border: `1px solid ${theme.palette.grey[100]}`,
      background: theme.palette.common.white,
      padding: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      borderRadius: theme.spacing(0.5),
    },
    requireText: {
      color: theme.palette.grey[400],
      fontSize: size.FONTSIZE_12,
      paddingLeft: theme.spacing(1),
    },
    datePickerDisclaimerText: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
      padding: theme.spacing(0.5),
    },
    checkboxDisclaimerText: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
      margin: theme.spacing(-0.75, 0.5, 0.5),
    },
    questionText: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      wordBreak: "break-word",
    },
    formSameLine: {
      color: theme.palette.grey[400],
      display: "flex",
      flexDirection: "row",
    },
    questionNo: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    menuItemContainer: {
      mt: 1,
      "& .MuiOutlinedInput-input": {
        marginTop: "8px",
      },
      width: "100%",
      maxWidth: "calc(100% - 24px)",
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: size.LIST_TITLEFONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      margin: theme.spacing(2, 0),
    },
  } satisfies SxObjType;
};

export { sxStyles };
