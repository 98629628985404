import { useState, useEffect } from "react";
import { getServiceList } from "api/appointment/GetServiceList/getServiceList";
import { Service } from "api/appointment/GetServiceList/getServiceList.toUi.types";
import {
  mapApiDataToInstitutionId,
  mapApiDataToProps,
} from "api/appointment/GetServiceList/mappers/mapper";
import { AxiosError } from "axios";

/**
 * A custom hook that fetches list of services for the appointments module from
 * the remote API and returns the appropriate data payload & loading state of that operation.
 * 
 * @param {boolean} pageFlag helper flag to trigger the hook
 * @param {string | null} clinicCode  Clinic code of the selected institution location
 * @param {string | null} targetSystem  Source system of the selected institution location

 * @returns {[Service[], boolean, boolean | null, string | null]}
 *   0 - Data mapped into Service[]
 *   1 - True if the data fetch is complete, false otherwise.
 *   2 - True if the data fetch completed with errors, false otherwise.
 *   3 - Error message returned from API call
 */
const useGetServiceList = (
  institutionCode: string | null,
): {
  data: Service[];
  institutionId: string;
  isLoading: boolean;
  hasErrored: boolean | null;
  errorMessage: string | null;
  dentalBlockedMessage: string | null;
} => {
  const [data, setData] = useState<Service[]>([]);
  const [institutionId, setInstitutionId] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [dentalBlockedMessage, setDentalBlockedMessage] = useState<
    string | null
  >(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const responseData = await getServiceList(institutionCode);

        const serviceList = mapApiDataToProps(responseData);
        const institutionID = mapApiDataToInstitutionId(responseData);

        const { DentalBlockedMessage } = responseData;
        setInstitutionId(institutionID);
        setData(serviceList);
        setDentalBlockedMessage(DentalBlockedMessage);
        setHasErrored(false);
        setErrorMessage(null);
      } catch (error) {
        setData([]);
        setHasErrored(true);
        if (error instanceof AxiosError) {
          setErrorMessage(error.response?.data.Message);
        } else {
          setErrorMessage(null);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [institutionCode]);

  return {
    data,
    institutionId,
    isLoading,
    hasErrored,
    errorMessage,
    dentalBlockedMessage,
  };
};

export { useGetServiceList };
