import { useEffect, useState } from "react";
import MammogramRequestFormSubmissionPageContainer from "../MammogramRequestFormSubmissionPage/MammogramRequestFormSubmissionPageContainer";
import { MammogramRequestFormProps } from "./MammogramRequestForm.types";
import { PersonalParticularsFormStruct } from "../../Form/Form.types";
import { validatePhoneNumber } from "lib/util/ValidatorUtil/phoneNumberValidator/phoneNumberValidator";
import { validateEmail } from "lib/util/ValidatorUtil/emailValidator/emailValidator";
import MammogramRequestFormQuestionnairePageContainer from "../MammogramRequestFormQuestionnairePage/MammogramRequestFormQuestionnairePageContainer";
import { useNavigate } from "react-router-dom";
import {
  CHECKBOX_QUESTION_INDEX,
  DROPDOWN_QUESTION_INDEX,
} from "api/appointment/GetMammogramQuestionnaire/getMammogramQuestionnaire";

// ==========================
// HELPER FUNCTIONS
// ==========================
/**
 * Disable Next button depending on whether YES/NO radio button fields are all answered.
 *
 * @param ansList list containing user answers
 * @returns false if all answers have been filled in, true otherwise.
 */
const disableNext = (ansList: Array<string>) => {
  if (ansList.slice(0, 5).every((ans) => ans !== "")) {
    return false;
  } else {
    return true;
  }
};
/**
 * Disable submit button depending on whether required fields are present and of the required format.
 *
 * @param checked : checkbox status on the form
 * @param personalParticularsFormField : mandatory input fields on the form
 * @returns False if all mandatory input fields have been filled in and checkbox is checked, true otherwise.
 */
const disableSubmit = (
  checked: boolean,
  personalParticularsFormField: PersonalParticularsFormStruct,
) => {
  if (
    checked &&
    personalParticularsFormField.name &&
    validatePhoneNumber(personalParticularsFormField.contact) &&
    validateEmail(personalParticularsFormField.email)
  ) {
    return false;
  } else {
    return true;
  }
};

// ==========================
// COMPONENT
// ==========================

const MammogramRequestForm = ({
  questions,
  personalParticularsFormField,
  handlePersonalParticularsFormFieldChange,
  preventDirectBackNavigationState,
  resetPreventDirectBackNavigationState,
}: MammogramRequestFormProps) => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState<
    "questionnaire" | "submission"
  >("questionnaire");

  // helper function to construct checkbox initial answer
  const constructCheckBoxInitialAns = () => {
    var initial = new Array<string>(
      questions[CHECKBOX_QUESTION_INDEX].QuestionDetails.length,
    ).fill("0"); // initialize each checkbox answer with "0" for unchecked status

    initial[0] = "1"; //select the first option
    return initial.join(",");
  };

  // helper function to construct dropdown initial answer
  const constructDropdownInitialAns = () => {
    return questions[DROPDOWN_QUESTION_INDEX].QuestionDetails[0].Text || ""; //select the first option
  };

  // set up the iniital answer list
  const initialAnsList = new Array<string>(questions.length).fill("");
  const [ansList, setAnsList] = useState(
    questions.length > CHECKBOX_QUESTION_INDEX
      ? initialAnsList.map((ans, i) => {
          if (i === CHECKBOX_QUESTION_INDEX) {
            return constructCheckBoxInitialAns();
          } else if (i === DROPDOWN_QUESTION_INDEX) {
            return constructDropdownInitialAns();
          } else {
            return ans;
          }
        })
      : initialAnsList,
  );

  const [openNotRecommended, setOpenNotRecommended] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleQuestionnaireChange = (event: {
    target: { name: string; value: string };
  }) => {
    const { name, value } = event.target;
    var updated = [...ansList];
    updated[Number(name.slice(1))] = value;
    setAnsList(updated);
  };

  const handleSubmissionChange = (checked: boolean) => {
    setChecked(checked);
  };

  const goToSubmissionPage = () => {
    // if first five yes/no question contains answer 'yes'
    if (ansList.slice(0, 5).some((ans) => ans === "Yes")) {
      setOpenNotRecommended(true);
    } else {
      let updated = [...ansList];
      // automatically ticks first checkbox for checkbox question if user has not answered it before proceeding to submission page
      if (ansList.length > CHECKBOX_QUESTION_INDEX) {
        const checkBoxAnsList = ansList[CHECKBOX_QUESTION_INDEX].split(",");
        if (checkBoxAnsList.every((ans) => ans === "0")) {
          let updatedCheckBoxAnsList: string[] = [...checkBoxAnsList];
          updatedCheckBoxAnsList[0] = "1";

          updated[6] = updatedCheckBoxAnsList.join(",");
        }
      }
      //automatically selects first option for dropdown question if user has not answered it before proceeding to submission page
      if (ansList.length > DROPDOWN_QUESTION_INDEX) {
        const dropdownAns = ansList[DROPDOWN_QUESTION_INDEX];
        if (!dropdownAns) {
          updated[7] =
            questions[DROPDOWN_QUESTION_INDEX].QuestionDetails[0].Text || "";
        }
      }
      setAnsList(updated);
      setCurrentPage("submission");
      window.scrollTo(0, 0);
    }
  };

  const goToQuestionnairePage = () => {
    setCurrentPage("questionnaire");
    window.scrollTo(0, 0);
  };

  //back navigation
  useEffect(() => {
    if (preventDirectBackNavigationState) {
      resetPreventDirectBackNavigationState();
      if (currentPage === "submission") {
        goToQuestionnairePage();
      } else {
        navigate(-1);
      }
    }
    // we only want this hook to fire on back navigation
    // eslint-disable-next-line
  }, [preventDirectBackNavigationState]);

  return (
    <>
      <MammogramRequestFormQuestionnairePageContainer
        questions={questions}
        currentPage={currentPage}
        ansList={ansList}
        handleQuestionnaireChange={handleQuestionnaireChange}
        disableNext={disableNext(ansList)}
        handlePageChange={goToSubmissionPage}
        openNotRecommended={openNotRecommended}
        setOpenNotRecommended={setOpenNotRecommended}
      />
      <MammogramRequestFormSubmissionPageContainer
        currentPage={currentPage}
        questions={questions}
        ansList={ansList}
        checked={checked}
        disableSubmit={disableSubmit(checked, personalParticularsFormField)}
        handleChange={handleSubmissionChange}
        handlePageChange={goToQuestionnairePage}
        personalParticularsFormField={personalParticularsFormField}
        handlePersonalParticularsFormFieldChange={
          handlePersonalParticularsFormFieldChange
        }
      />
    </>
  );
};

export default MammogramRequestForm;
