/* istanbul ignore file */

/**
 * Indicate redirection with a flag in session storage, so that React
 * is able to call renderReactOnDOM() based on this flag at callback
 * regardless of the environment it's running on
 *
 * Usage:
 * If a React component needs to redirect to external link within same webview,
 * it needs to call navigateToExternalInAppWebView. Within navigateToExternalInAppWebView,
 * setRedirectionToExternalFlag() is called before doing navigation to set a
 * flag in session storage.
 *
 * When a React page is launched, index.tsx will check session storage with
 * hasRedirectToExternal(), if this page is launched by a callback, then the
 * flag will be removed by removeRedirectionToExternalFlag(), and renderReactOnDOM()
 * will be called.
 */

const HAS_REDIRECTED = "hasRedirected";
const FLAGGED = "flagged";

/**
 * add a "flag" (key-value pair) in session storage, indicating the page is going to
 * redirect to external link right after this
 */
export const setRedirectToExternalFlag = () => {
  window.sessionStorage.setItem(HAS_REDIRECTED, FLAGGED);
};

/**
 * @returns true when the page is launched by a callback after redirecting to an
 * external link, false otherwise
 */
export const hasRedirectToExternal = (): boolean => {
  return window.sessionStorage.getItem(HAS_REDIRECTED) === FLAGGED;
};

/**
 * removes the "flag" (key-value pair) in session storage created by setRedirectToExternalFlag()
 */
export const removeRedirectionToExternalFlag = () => {
  window.sessionStorage.removeItem(HAS_REDIRECTED);
};
