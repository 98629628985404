import { sxStyles } from "./LinkedAppointmentCardSeparator.styles";
import { Box } from "@mui/material";

const LinkedAppointmentCardSeparator = () => {
  const classes = sxStyles();
  return (
    <Box sx={classes.linkedCardDivider} aria-label="linkedCardDivider"></Box>
  );
};

export default LinkedAppointmentCardSeparator;
