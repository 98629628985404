/*
  The collection codes from the backend to match to determine what type of collection choice this is.
*/

export const COLLECTION_TYPES = {
  PHARMACY: {
    COLLECTION_CODE: 1,
    TYPE: "pharmacy",
  },
  HOME_DELIVERY: {
    COLLECTION_CODE: 3,
    TYPE: "homedelivery",
  },
  MEDLOCKER: {
    COLLECTION_CODE: 4,
    TYPE: "locker",
  },
};

// !exp The collection of request type codes used
export const REQUEST_TYPES = {
  REFILL: "REFILL",
  TOPUP: "TOPUP",
  VCP: "VIRTUALCONSULT",
};
