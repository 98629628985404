import { color } from "lib/theme/color";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = (titleBold: boolean, titleIconSize: number | undefined) => {
  return {
    
    card: {
      display: "flex",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      justifyContent: "space-between",
      boxShadow: "0px 0px 6px " + color.RGBA_52,
      "&:not(:last-child)": {
        marginBottom: theme.spacing(2),
      },
      borderRadius: theme.spacing(1),
    },

    actionArea: {
      padding: theme.spacing(1.75),
      "&:hover $focusHighlight": { opacity: 0 },
    },
    

    content: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 0,
      "&:last-child": {
        paddingBottom: theme.spacing(2),
      },
    },
    leftContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    icon: {
      width: titleIconSize ? titleIconSize : 40,
      height: titleIconSize ? titleIconSize : 40,
    },
    textContainer: {
      marginLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1.5),
    },

    title: {
      lineHeight: 1,
      color: theme.palette.grey[400],
      typography: titleBold
        ? theme.typography.body1_semibold
        : theme.typography.body1_regular,

      marginBottom: theme.spacing(0.5),
    },
    body: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
    },
    chevronContainer: {
      display: "grid",
      placeItems: "center",
    },
    iconCardActions: { padding: 0 },
    nextIcon: {
      color: theme.palette.primary.main,
      fontSize: 20,
    },
  } satisfies SxObjType;
};

export { sxStyles };
