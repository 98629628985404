import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_semibold,
      paddingBottom: theme.spacing(2),
    },

    medicationDetailBoxOption3: {
      display: "flex",
    },
    medicationDetailBoxOption1And2: {
      display: "flex",
    },
    medicationDetail: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      marginBottom: theme.spacing(1),
    },
    medicationDetailValue: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      marginLeft: theme.spacing(0.5),
    },
  } satisfies SxObjType;
};

export { sxStyles };
