import { ACTIONS } from "lib/routing";

/**
 * Format the URL for the Order Med action
 * @param institutionCode The institution code
 * @param appointmentId The appointment ID
 * @param regAppointmentId The registration appointment ID
 * @param targetSystem The target system
 * @param institutionName The institution name
 * @param mobileRegQNo The mobile registration queue number
 * @returns The formatted URL
 */
export const formatOrderMedUrl = ({
  institutionCode,
  appointmentId,
  regAppointmentId,
  targetSystem,
  institutionName,
  mobileRegQNo,
}: {
  institutionCode: string;
  appointmentId: string;
  regAppointmentId: string;
  targetSystem: string;
  institutionName: string;
  mobileRegQNo: string;
}): string =>
  `${ACTIONS.ORDER_MED_ACTION}&institutionCode=${institutionCode}&appointmentId=${appointmentId}&regAppointmentId=${regAppointmentId}&targetSystem=${targetSystem}&institutionName=${institutionName}&mobileRegQNo=${mobileRegQNo}`;
