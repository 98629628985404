import { Box, Typography, useTheme } from "@mui/material";
import { useAutocomplete } from "@mui/base";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import SimpleAutocomplete from "lib/components/formInputs/SimpleAutocomplete/SimpleAutocomplete";
import Page from "lib/components/layout/Page/Page";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { ACTIONS, PATHS } from "lib/routing";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  selectEpicProfile,
  selectPMSettings,
  selectPMSettingsValue,
} from "ui/app/components/myProfile/PatientMaster/ducks/selectors";
import {
  fetchEpicProfile,
  fetchSystemSettings,
} from "ui/app/components/myProfile/PatientMaster/ducks/thunks";
import { useGetInstitutionsByClustersAndGroups } from "ui/appointment/hooks/useGetInstitutionsByClustersAndGroups";
import SelectionInstructions from "../../../../common/selection-listing/SelectionInstructions/SelectionInstructions";
import SelectionList from "../../../../common/selection-listing/SelectionList/SelectionList";
import { sxStyles } from "./InstitutionsPage.styles";
import {
  fetchDependentParticulars,
  setProfileAccessEntryPoint,
} from "lib/redux/user";
import {
  selectAppointments,
  selectAppointmentsSystemSettings,
} from "ui/appointment/ducks/selectors";
import InstitutionsPageSkeleton from "./InstitutionsPageSkeleton";
import { Institution } from "api/appointment/GetInstitutionsByClustersAndGroups/getInstitutionsByClustersAndGroups.toUi.types";
import { selectUser } from "lib/redux/user/selectors";
import {
  clearAppointmentMetaState,
  setParentInstitutionCode,
  updateAppointmentMetaFromInstitution,
  updateInstitutionCodeForCreate,
  updateInstitutionForCreate,
} from "ui/appointment/ducks/appointmentsSlice";
import { selectApptUserPreference } from "lib/redux/apptUserPreference/selectors";
import { setCurrentFlowAction } from "lib/redux/navigation";
import { updateInsUserPreference } from "lib/redux/apptUserPreference";
import { fetchSystemSettings as fetchAppointmentSystemSettings } from "ui/appointment/ducks";
import { PM_ERROR_422 } from "api/patientMaster/GetPatientMasterProfile/getPatientMasterProfile";

const nextButtonText = "Create now";
const cancelButtonText = "Make offline request";

const InstitutionsPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = sxStyles(theme);
  const dispatch = useAppDispatch();

  // Redux states
  const {
    isLoading: settingsIsLoading,
    hasErrored: settingsHasErrored,
    errorMessage: settingsErrorMessage,
  } = useAppSelector(selectPMSettings);

  const {
    isLoading: isSystemSettingsLoading,
    hasErrored: systemSettingsHasErrored,
    errorMessage: systemSettingsErrorMessage,
  } = useAppSelector(selectAppointmentsSystemSettings);

  const { errorMessages } = useAppSelector(selectPMSettingsValue);
  const {
    hasEpicProfile,
    isLoading: epicIsLoading,
    hasErrored: epicHasErrored,
    errorMessage: epicErrorMessage,
    hasFetchedEpicProfile,
  } = useAppSelector(selectEpicProfile);
  const { isCreateFromAppointmentLanding } =
    useAppSelector(selectAppointments).create;
  const uuid = useAppSelector(selectUser)?.myChartUuid || null;
  const {
    isPatient,
    sources: systemFlowSourceSystems,
    dependentIsLoading: isDependentProfileApiLoading,
    dependentHasErrored: hasDependentProfileApiErrored,
    dependentErrorMessage: dependentProfileApiErrorMessage,
  } = useAppSelector(selectUser);
  const { lastFiveInstitutionSearchedKeywords: lastFiveSearchedKeywords } =
    useAppSelector(selectApptUserPreference);

  // Local states
  const [pageFlag, setPageFlag] = useState(true);
  const [isNoRecordsOpen, setIsNoRecordsOpen] = useState(false);

  const onSelect = (
    institution: string,
    targetSystem: string | null,
    institutionCode: string | null,
  ) => {
    dispatch(updateInstitutionForCreate(institution));
    dispatch(updateInstitutionCodeForCreate(institutionCode));
    dispatch(
      updateAppointmentMetaFromInstitution({
        targetSystem,
        institutionCode,
      }),
    );
  };
  const onSaveInstitutionKeyword = (keyword: string) => {
    dispatch(updateInsUserPreference(keyword));
  };

  const [rawInstitutions, isLoading, hasErrored, errorMessage] =
    useGetInstitutionsByClustersAndGroups(systemFlowSourceSystems, pageFlag);

  // As of Aug 2021, filter out JCH for form flow
  const institutions = rawInstitutions.filter((institution, index) => {
    return institution.institutionCode !== "JCH";
  });

  const { getInputProps, groupedOptions, inputValue, focused } =
    useAutocomplete({
      options: institutions,
      getOptionLabel: (option) => option.displayName,
    });

  const handleNoRecordsNextClick = () => {
    logEventToGoogleAnalytics(
      EVENTS.SELECT_CREATE_APPOINTMENT_PARTICULARS_CREATE_NOW,
    );

    if (isCreateFromAppointmentLanding) {
      mobileNavigate(
        `${ACTIONS.MOBILE_PM_SYNC}&successUrl=${PATHS.APPOINTMENT_CREATE_INSTITUTIONS.path}`,
      );
    } else {
      mobileNavigate(
        `${ACTIONS.MOBILE_PM_SYNC}&successUrl=${PATHS.APPOINTMENT_CREATE_INSTITUTIONS_MOBILE.path}`,
      );
    }
  };

  const handleNoRecordsCancelClick = () => {
    logEventToGoogleAnalytics(
      EVENTS.SELECT_CREATE_APPOINTMENT_PARTICULARS_OFFLINE_REQUEST,
    );
    setIsNoRecordsOpen(false);
  };

  useEffect(() => {
    dispatch(clearAppointmentMetaState());
    dispatch(setCurrentFlowAction("APPOINTMENT_CREATE"));
    dispatch(fetchSystemSettings()); //retrieve tnc, disclaimers, error messages etc
    dispatch(
      fetchAppointmentSystemSettings(
        "APPOINTMENT",
        "NUP",
        "NUPSDASlotMessage",
        null,
      ),
    );

    dispatch(setProfileAccessEntryPoint("APPOINTMENT"));

    if (isPatient) {
      dispatch(
        fetchEpicProfile({
          myInfoName: null,
          myInfoDoB: null,
          myInfoSex: null,
          uuid,
          codeVerifier: null,
        }),
      );
    } else {
      dispatch(fetchDependentParticulars());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMemo(() => {
    if (hasFetchedEpicProfile && !hasEpicProfile) {
      setIsNoRecordsOpen(true);
    }
  }, [hasEpicProfile, hasFetchedEpicProfile]);

  return (
    <Page>
      <SelectionInstructions text="Select a NUHS Institution" />
      {isLoading ||
      isDependentProfileApiLoading ||
      epicIsLoading ||
      settingsIsLoading ||
      isSystemSettingsLoading ? (
        <InstitutionsPageSkeleton />
      ) : hasErrored ? (
        <ErrorDisplayContainer
          errorMessage={errorMessage}
          onTryAgain={() => {
            setPageFlag(!pageFlag);
          }}
        />
      ) : hasDependentProfileApiErrored ? (
        <ErrorDisplayContainer
          errorMessage={dependentProfileApiErrorMessage}
          onTryAgain={
            dependentProfileApiErrorMessage?.includes(PM_ERROR_422)
              ? undefined
              : () => {
                  dispatch(fetchDependentParticulars());
                }
          }
        />
      ) : settingsHasErrored ? (
        <ErrorDisplayContainer
          errorMessage={settingsErrorMessage}
          onTryAgain={() => {
            dispatch(fetchSystemSettings());
          }}
        />
      ) : systemSettingsHasErrored ? (
        <ErrorDisplayContainer
          errorMessage={systemSettingsErrorMessage}
          onTryAgain={() => {
            dispatch(
              fetchAppointmentSystemSettings(
                "APPOINTMENT",
                "NUP",
                "NUPSDASlotMessage",
                null,
              ),
            );
          }}
        />
      ) : epicHasErrored ? (
        <ErrorDisplayContainer
          errorMessage={epicErrorMessage}
          onTryAgain={
            epicErrorMessage?.includes(PM_ERROR_422)
              ? undefined
              : () => {
                  dispatch(
                    fetchEpicProfile({
                      myInfoName: null,
                      myInfoDoB: null,
                      myInfoSex: null,
                      uuid,
                      codeVerifier: null,
                    }),
                  );
                }
          }
        />
      ) : institutions ? (
        <>
          <Box sx={{ mt: 3, mb: 1, px: 2, zIndex: 100 }}>
            <SimpleAutocomplete
              placeholder="Search institutions"
              showHistoryKeyword={focused && inputValue.length === 0}
              items={lastFiveSearchedKeywords}
              inputProps={getInputProps}
              onSelect={(selectedValue) => {
                const selectedInstitutionObj = institutions.find(
                  (ins) => ins.displayName === selectedValue,
                );

                if (selectedInstitutionObj) {
                  onSelect(
                    selectedValue,
                    selectedInstitutionObj.targetSystem,
                    selectedInstitutionObj.institutionCode,
                  );
                  onSaveInstitutionKeyword(selectedValue);

                  logEventToGoogleAnalytics(
                    EVENTS.VIEW_APPOINTMENT_INSTITUTION,
                  );

                  if (selectedInstitutionObj.parentInstitutionCode) {
                    dispatch(
                      setParentInstitutionCode(
                        selectedInstitutionObj.parentInstitutionCode,
                      ),
                    );
                  }

                  if (selectedInstitutionObj.webflow) {
                    navigate(PATHS.APPOINTMENT_CREATE_SERVICES.path);
                  } else {
                    navigate(PATHS.APPOINTMENT_CREATE_FORM.path);
                  }
                }
              }}
            />
          </Box>
          {inputValue.length !== 0 && groupedOptions.length === 0 ? (
            <Box sx={classes.notFoundTextBox}>
              <Typography sx={classes.notFoundText}>
                The institution you are looking for is not in this list.
              </Typography>
            </Box>
          ) : (
            <Box sx={classes.selectionListBox}>
              <SelectionList
                items={
                  groupedOptions.length === 0
                    ? institutions
                    : (groupedOptions as Institution[])
                }
                onSelect={(selectedValue) => {
                  const items =
                    groupedOptions.length === 0
                      ? institutions
                      : (groupedOptions as Institution[]);

                  const selectedInstitutionObj = items.find(
                    (ins) => ins.displayName === selectedValue,
                  );

                  if (selectedInstitutionObj) {
                    onSelect(
                      selectedValue,
                      selectedInstitutionObj.targetSystem,
                      selectedInstitutionObj.institutionCode,
                    );
                    onSaveInstitutionKeyword(selectedValue);

                    logEventToGoogleAnalytics(
                      EVENTS.VIEW_APPOINTMENT_INSTITUTION,
                    );

                    if (selectedInstitutionObj.parentInstitutionCode) {
                      dispatch(
                        setParentInstitutionCode(
                          selectedInstitutionObj.parentInstitutionCode,
                        ),
                      );
                    }

                    if (selectedInstitutionObj.webflow) {
                      navigate(PATHS.APPOINTMENT_CREATE_SERVICES.path);
                    } else {
                      navigate(PATHS.APPOINTMENT_CREATE_FORM.path);
                    }
                  }
                }}
              />
            </Box>
          )}
          <ConfirmationModal
            title={errorMessages.noRecordsFound.title}
            body={MapRawStringToReactElement(
              errorMessages.noRecordsFound.description ?? "",
            )}
            nextButtonText={nextButtonText}
            onClickNext={handleNoRecordsNextClick}
            cancelButtonText={cancelButtonText}
            onClickCancel={handleNoRecordsCancelClick}
            open={isNoRecordsOpen}
            onClose={() => {}}
            showCloseButton={false}
            hideCancelButton={false}
          />
        </>
      ) : null}
    </Page>
  );
};

export default InstitutionsPage;
