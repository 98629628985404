import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    skeletonHeader: {
      marginBottom: 1.25,
      width: 200,
    },
    leftSkeletonContainer: {
      display: "flex",
      flexDirection: "column",
      padding: 1,
      marginRight: 1.25,
    },
    leftSkeleton: { width: 60 },
    rightSkeletonContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      padding: 1,
    },
    rightSkeleton: { width: "60%" },
  } satisfies SxObjType;
};

export { sxStyles };
