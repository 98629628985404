import { sxStyles } from "./MedicationCard.styles";
import { MedicationCardProps } from "./MedicationCard.types";
import IMAGES from "lib/assets/images";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import {
  Card,
  CardContent,
  Box,
  Typography,
  CardActionArea,
  CardActions,
} from "@mui/material";
import IconTextButton from "lib/components/buttons/IconTextButton/IconTextButton";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

const MedicationCard = ({
  data,
  updateRequesterId,
  onReOrder,
  onClickCard,
}: MedicationCardProps) => {
  const classes = sxStyles();
  const date = data.Date ? getDateFromISOString(data.Date) : null;

  return (
    <Card
      key={data.RequesterId}
      sx={classes.card}
      onClick={() => {
        updateRequesterId(data.RequesterId);
        onClickCard();
      }}
    >
      <Box sx={classes.leftContainer}>
        <Box sx={classes.dateContainer}>
          <Typography sx={classes.dateMonth}>
            {date ? formatDate(formatDateToISO(date), "MMM") : null}
          </Typography>
          <Typography sx={classes.dateYear}>
            {date ? formatDate(formatDateToISO(date), "yyyy") : null}
          </Typography>
          <Typography sx={classes.dateDay}>
            {date ? formatDate(formatDateToISO(date), "dd") : null}
          </Typography>
          <Typography sx={classes.dateDayOfWeek}>
            {date ? formatDate(formatDateToISO(date), "EEE") : null}
          </Typography>
        </Box>
      </Box>
      <CardActionArea>
        <CardContent sx={classes.cardDetailWrapper}>
          <Box component="div" sx={classes.cardDetails}>
            <Box sx={classes.leftContentContainer}>
              <Typography sx={classes.requestText}>
                {data.RequestType}
              </Typography>
              <Typography sx={classes.cardTitle}>{data.Facility}</Typography>

              <Typography sx={classes.cardTime}>
                {date &&
                  formatDate(formatDateToISO(date), "hh:mma").toLowerCase()}
              </Typography>

              <Typography sx={classes.cardPatientTitle}>Patient</Typography>
              <Typography sx={classes.cardPatientName}>
                {data.PatientName}
              </Typography>
            </Box>

            <Box sx={classes.rightContentContainer}>
              {/* Reorder Icon */}
              {data.CanReOrderMedication ? (
                <CardActions>
                  <Box sx={classes.reorderContainer}>
                    <IconTextButton
                      icon={IMAGES.medrefill.ReorderMedicationIcon}
                      label={["Reorder", "medication"]}
                      ariaLabel={"Reorder medication"}
                      onClick={(event: { stopPropagation: () => void }) => {
                        // stopPropagation prevents the parent component's event from bubbling
                        event.stopPropagation();

                        onReOrder();
                      }}
                    />
                  </Box>
                </CardActions>
              ) : null}

              <Box sx={classes.arrowContainer}>
                <Box
                  component={"img"}
                  src={IMAGES.general.ArrowForward}
                  sx={classes.icon}
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default MedicationCard;
