/**
 *  Helper function to check if the institute code belongs in the JHC cluster
 * @param institutionCode institution code to be checked
 * @returns true if institute belongs under the JHC, else false
 */
export const isJHC = (institutionCode: string | null) => {
  return institutionCode === "NTFGH" || //Ng Teng Fong General Hospital
    institutionCode === "JCH" || //Jurong Community Hospital
    institutionCode === "JMC" //Jurong Medical Centre
    ? true
    : false;
};
