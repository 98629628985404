import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import PageLayout from "lib/components/layout/Page/Page";
import AvailableSlotsHeader from "../../../../common/availableSlots/AvailableSlotsHeader/AvailableSlotsHeader";
import AvailableSlotsSectionContainer from "../../../../common/availableSlots/AvailableSlotsSection/AvailableSlotsSectionContainer";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { Box, CircularProgress, useTheme } from "@mui/material";
import RescheduleNotice from "ui/appointment/components/rescheduleAppointment/pages/availableSlots/RescheduleNotice/RescheduleNotice";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import { sxStyles } from "./CreateApptAvailableSlots.styles";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import {
  selectAppointmentDocByType,
  selectAppointmentMeta,
  selectAppointments,
} from "ui/appointment/ducks/selectors";
import { CREATE_APPOINTMENT_SLOT_MSG_KEY } from "api/shared/GetDocumentByType/getDocumentByType.mapper";
import { setCurrentFlowAction } from "lib/redux/navigation";
import { updateSlotDetailsForCreate } from "ui/appointment/ducks";
import { fetchDocumentForAllTypes } from "ui/appointment/ducks/thunks";

const CreateApptAvailableSlotsPage = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const classes = sxStyles(theme);
  const navigate = useNavigate();
  const { isSameDayBooking, appointmentType } = useAppSelector(
    selectAppointmentMeta,
  );
  const {
    selectedInstitution: location,
    selectedService: service,
    lastVisitDateTime,
    isUrti,
  } = useAppSelector(selectAppointments).create;
  const isLoadingSlotAndPreConsultMsg = useAppSelector(
    selectAppointmentDocByType,
  ).isLoading;
  const slotMsg = useAppSelector(selectAppointmentDocByType).documentByTypeMap[
    CREATE_APPOINTMENT_SLOT_MSG_KEY
  ];
  //fetch pre-consultation to be displayed in open appointment confirmation page
  // check isLoadingPreConsultMsg to ensure only fetch once in the open appointment creation flow
  // fetch in this page to avoid displaying loading spinner on loading of both slots page and confirmation page

  useEffect(() => {
    dispatch(setCurrentFlowAction("APPOINTMENT_CREATE"));
    if (isLoadingSlotAndPreConsultMsg === null) {
      dispatch(fetchDocumentForAllTypes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //log event to google analytics
    if (isSameDayBooking) {
      logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENT_SAMEDAY);
    } else {
      logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENT_ADVANCE);
    }
  }, [isSameDayBooking]);

  return (
    <PageLayout>
      {isLoadingSlotAndPreConsultMsg ? (
        <Box sx={classes.loadingContainer}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <AvailableSlotsHeader
            location={location ?? ""}
            service={service ?? ""}
            lastVisitDatetime={lastVisitDateTime}
            appointmentType={appointmentType}
            inAdvanceNoticeComponent={
              isSameDayBooking ? (
                <Box sx={classes.noticeContainer}>
                  <SharpNoticePanel bgColor="warn" showRaw={false}>
                    {slotMsg}
                  </SharpNoticePanel>
                </Box>
              ) : (
                <RescheduleNotice />
              )
            }
          />

          <AvailableSlotsSectionContainer
            centerContactNo={null}
            isNewAppointment={true}
            isSameDayBooking={isSameDayBooking}
            isUrti={isUrti}
            shouldGetAvailableSlots={true}
            onSelect={(slotId, slotDateTime) => {
              dispatch(updateSlotDetailsForCreate({ slotId, slotDateTime }));
              logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENT_DETAILS);
              navigate(PATHS.APPOINTMENT_CREATE_CONFIRM.path);
            }}
            onCancel={() => {
              navigate(-1);
            }}
          />
        </>
      )}
    </PageLayout>
  );
};

export default CreateApptAvailableSlotsPage;
