import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    requireBox: {
      border: `1px solid ${theme.palette.grey[100]}`,
      background: theme.palette.common.white,
      padding: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      borderRadius: theme.spacing(0.5),
    },
    requireText: {
      color: theme.palette.grey[400],
      fontSize: size.FONTSIZE_14,
      paddingLeft: theme.spacing(1),
    },
  } satisfies SxObjType;
};

export { sxStyles };
