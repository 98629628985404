import { color } from "lib/theme/color";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    cardRegion: {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "0px 0px 4px " + color.RGBA_0,
      borderRadius: theme.spacing(0.5),
      margin: theme.spacing(0, 1.5, 2),
    },
    linkedCardHeader: {
      padding: theme.spacing(1.5, 1.5, 0),
    },
    setNewCard: {
      backgroundColor: theme.palette.secondary.main,
    },
    setNewCardImg: {
      height: theme.spacing(10),
      padding: theme.spacing(2),
    },
    cardtitle: {
      textAlign: "center",
      width: "50%",
    },
    institutionText: {
      typography: theme.typography.body1_semibold,
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(1),
    },
    serviceDisplayName: {
      typography: theme.typography.body1_bold,
      color: theme.palette.grey[400],
    },
    departmentName: {
      typography: theme.typography.body1_bold,
      color: theme.palette.grey[400],
    },
    location: {
      typography: theme.typography.body1_regular,
      color: theme.palette.grey[400],
    },
    visitType: {
      typography: theme.typography.body1_regular,
      color: theme.palette.grey[400],
      paddingBottom: theme.spacing(1.5),
      borderBottom: "1px solid " + theme.palette.grey[100],
    },
    layout: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(0),
    },
    cardSectionLayout: {
      justifyContent: "space-between",
    },
    cardLayout: {
      width: "100%",
    },
    card: {
      margin: theme.spacing(1.5, 0, 0),
      boxShadow: "none",
      backgroundColor: theme.palette.secondary.main,
      width: "100%",
      display: "flex",
      "& .MuiCard-root": {
        boxShadow: "none",
        "& .MuiCardHeader-root": {
          padding: theme.spacing(1, 2),
        },
        "& .MuiCardContent-root": {
          "& div.MuiBox-root": {
            display: "flex",
            alignItems: "center",
            paddingRight: 0,
          },
        },
      },
    },
    titleLeftText: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_semibold,
      margin: "auto",
      width: "50%",
    },
    titleRightText: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_semibold,
      margin: "auto",
      width: "50%",
    },
    arrow: {
      marginTop: theme.spacing(3.75),
    },
  } satisfies SxObjType;
};

export { sxStyles };
