import { Theme } from "@mui/material";
import { SxObjType } from "lib/theme/types/SxObjType.type";

export const sxStyles = (theme: Theme) => {
  return {
    button: {
      py: "5px",
      px: 1,
      height: "unset",
      lineHeight: "unset",
      typography: "body1_semibold",
    },
  } satisfies SxObjType;
};
