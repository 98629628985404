import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { Appointment } from "api/appointment/GetAppointmentList/getAppointmentList.toUi.types";
import {
  updateApptDetailForReschedule,
  updateRescheduleLinkedAppointmentVisitedSlotsPage,
  updateAppointmentMetaAppointmentType,
} from "ui/appointment/ducks/appointmentsSlice";
import { setCurrentFlowAction } from "lib/redux/navigation";
import AppointmentCardFooterBtns from "./AppointmentCardFooterBtns";
import { isSameDayReschedule } from "api/appointment/GetAppointmentList/mappers/isSameDayBooking";
import { RootState } from "lib/redux/root/redux.types";

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onCancel: () => {
      dispatch(setCurrentFlowAction("APPOINTMENT_CANCEL"));
    },
    onReschedule: () => {
      dispatch(setCurrentFlowAction("APPOINTMENT_RESCHEDULE"));
    },
    onSelectRescheduleOrCancel: (appointment: Appointment) => {
      const {
        appointmentId,
        institutionName,
        location,
        serviceDisplayName: service,
        datetime,
        sourceSystem,
        institutionCode,
        consultType,
        departmentName,
        logoCode,
        calendarTitle,
        calendarLocation,
        visitTypeId,
        rescheduleToDays,
      } = appointment;

      const slotSearchDateRangeFrom = appointment.slotSearchDateRangeFrom;

      const slotSearchDateRangeTo = appointment.slotSearchDateRangeTo;

      const expectedOrderDate = appointment.expectedOrderDate;

      const slotId = appointment.slotId;

      const serializedDateTime = formatDateToISO(datetime);
      const isSameDay = isSameDayReschedule(consultType, logoCode);

      const linkedAppointments = appointment.linkedAppointments;

      const linkedAppointmentsMetaData =
        linkedAppointments !== null
          ? [
              ...linkedAppointments.map((appt) => {
                return {
                  appointmentId: appt.appointmentId,
                  regAppointmentId: appt.regAppointmentId,
                };
              }),
            ]
          : [];

      dispatch(
        updateApptDetailForReschedule({
          appointmentId,
          institutionName,
          location,
          serviceName: service,
          dateTime: serializedDateTime,
          institutionCode,
          sourceSystem,
          departmentCode: consultType,
          departmentName,
          logoCode,
          slotSearchDateRangeFrom,
          slotSearchDateRangeTo,
          slotId: slotId,
          isSameDayBooking: isSameDay,
          calendarTitle,
          calendarLocation,
          visitTypeId,
          expectedOrderDate,
          linkedAppointments: linkedAppointmentsMetaData,
          rescheduleToDays,
        }),
      );
      dispatch(updateRescheduleLinkedAppointmentVisitedSlotsPage(false));
      dispatch(updateAppointmentMetaAppointmentType("upcoming"));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppointmentCardFooterBtns);
