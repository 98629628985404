import { convertFromBase64 } from "lib/util/StringUtil/base64/convertFromBase64";
import { parseJson } from "lib/util/StringUtil/jsonParser/parseJson";

/**
 * Parses the payload received from the Mobile Client.
 *
 * @param {string | null} rawPayload  Raw payload passed directly from the mobile client
 * @returns {Object}  Parsed object
 */
const parsePayload = <T>(rawPayload: string | null): T => {
  if (rawPayload) {
    const rawJson = convertFromBase64(rawPayload);
    if (rawJson == null) {
      throw new Error(
        `The Base64 encoded payload could not be decoded. Raw payload: ${rawPayload}.`,
      );
    } else {
      return parseJson<T>(rawJson);
    }
  } else {
    throw new Error("The raw payload is NULL or UNDEFINED.");
  }
};

export { parsePayload };
