import { RootState } from "lib/redux/root/redux.types";
import { connect } from "react-redux";
import MammogramRequestForm from "./MammogramRequestForm";
import { Dispatch, AnyAction } from "redux";
import { setCustomisedBack } from "lib/redux/navigation";

const mapStateToProps = (state: RootState) => {
  return {
    questions: state.appointments.mammogram.questions,
    preventDirectBackNavigationState: state.navigation.customisedBack,
  };
};

// =======================
// MAP DISPATCH TO PROPS
// =======================

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    resetPreventDirectBackNavigationState: () => {
      dispatch(setCustomisedBack(false));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MammogramRequestForm);
