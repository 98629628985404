/**
 *
 * validate if the input contact is a valid Singapore Phone Number
 * return true if the input contact is valid, false otherwise
 *
 * @param {string} contact input string to be validated
 */
export const validatePhoneNumber = (
  contact: string | null,
  isMobileNumber?: boolean,
) => {
  try {
    if (contact) {
      if (contact.length === 8) {
        let startingChar = contact.charAt(0);
        if (isMobileNumber) {
          if (startingChar === "8" || startingChar === "9") {
            for (let i = 0; i < 8; i++) {
              let c = contact.charAt(i);
              if (c < "0" || c > "9") {
                return false;
              }
            }
            return true;
          }
          return false;
        } else {
          if (
            startingChar === "3" ||
            startingChar === "6" ||
            startingChar === "8" ||
            startingChar === "9"
          ) {
            for (let i = 0; i < 8; i++) {
              let c = contact.charAt(i);
              if (c < "0" || c > "9") {
                return false;
              }
            }
            return true;
          }
          return false;
        }
      }
    }
    return false;
  } catch (error) {
    return false;
  }
};
