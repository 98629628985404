import { Box } from "@mui/material";
import { SelectionListingHeaderProps } from "./SelectionListingHeader.types";
import { sxStyles } from "./SelectionListingHeader.styles";

const SelectionListingHeader = ({ children }: SelectionListingHeaderProps) => {
  const classes = sxStyles();
  return <Box sx={classes.layout}>{children}</Box>;
};

export default SelectionListingHeader;
