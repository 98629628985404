import axios from "axios";
import { GET_BILL_SUMMARY_DETAILS_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatGetBillSummaryDetailsErrorMessage } from "api/resources/messages/ErrorMessage";
import { GetBillSummaryDetailsResponsePayload } from "./getBillSummaryDetails.fromApi.types";

/**
 * Get Bill Summary Details from multiple bills
 *
 * POST
 * Endpoint: secure/payment/GetBillSummaryDetails
 *
 * @param {string} memberIdentifier Encrypted NRIC. e.g. "d030644b48cdbf04f4f87e6dfd02939f" (this one is invalid)
 * @param {string} InvoiceRefNo Invoice Reference Number to retrieve values for selected bill
 *
 */
const getBillSummaryDetails = async (
  memberIdentifier: string | null,
  invoiceRefNo: string | null,
  institutionCode: string | null,
): Promise<GetBillSummaryDetailsResponsePayload> => {
  try {
    const response = await axios.post<GetBillSummaryDetailsResponsePayload>(
      GET_BILL_SUMMARY_DETAILS_URL(),
      createRequestPayload(memberIdentifier, invoiceRefNo, institutionCode),
      getRequestOptions(),
    );
    return parseResponse(response, GET_BILL_SUMMARY_DETAILS_URL());
  } catch (error) {
    const msg = formatGetBillSummaryDetailsErrorMessage(
      GET_BILL_SUMMARY_DETAILS_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  memberIdentifier: string | null,
  invoiceRefNo: string | null,
  institutionCode: string | null,
) => {
  return {
    MemberIdentifier: memberIdentifier,
    InvoiceRefNo: invoiceRefNo,
    InstitutionCode: institutionCode,
  };
};

export { getBillSummaryDetails };
