import { Box, Typography } from "@mui/material";
import { sxStyles } from "./Payment.styles";
import { PaymentProps } from "./Payment.types";
import CommonRadioButton from "lib/components/formInputs/CommonRadioButton/CommonRadioButton";
import mapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { useAppSelector } from "lib/redux/hooks";
import {
  selectIsMultiplePayment,
  selectIsPayInstructionEnabled,
} from "ui/medrefill/ducks/selectors";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import MultiLineTextField from "lib/components/formInputs/MultiLineTextField/MultiLineTextField";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import SimpleCheckbox from "lib/components/formInputs/Checkbox/SimpleCheckbox";

const Payment = ({
  paymentChoice,
  selectedSubsidy,
  handleSubsidyChange,
  paymentInfoText,
  handleCheckboxChange,
  selectedCheckboxes,
  localPaymentInstructions,
  handlePaymentInstructionsChange,
}: PaymentProps) => {
  const classes = sxStyles();
  const isMultiplePayment = useAppSelector(selectIsMultiplePayment);
  const isPayInstructionEnabled = useAppSelector(selectIsPayInstructionEnabled);

  return (
    <Box mt={3} ml={2} mr={2}>
      <Typography sx={classes.title}>
        How would you like to make payment for this medication?
      </Typography>

      {paymentChoice.length !== 0 && !isMultiplePayment ? (
        <Box mt={1}>
          <CommonRadioButton
            radioButtonSelectionList={paymentChoice.map((paymentOption) => ({
              id: paymentOption.PaymentCode,
              text: paymentOption.PaymentValue,
              disclaimerText: paymentOption.PaymentDisclaimer,
            }))}
            selectedRadioButton={selectedSubsidy}
            handleRadioButtonChange={handleSubsidyChange}
          ></CommonRadioButton>
        </Box>
      ) : (
        paymentChoice.length !== 0 && (
          <Box mt={2}>
            {paymentChoice.map((paymentOption) => {
              return (
                <Box mt={1} mb={1.5}>
                  <SimpleCheckbox
                    checked={selectedCheckboxes.includes(
                      paymentOption.PaymentCode,
                    )}
                    label={MapRawStringToReactElement(
                      paymentOption.PaymentValue,
                    )}
                    value={paymentOption.PaymentCode}
                    handleChange={() => {
                      handleCheckboxChange(
                        paymentOption.PaymentCode,
                        paymentOption.PaymentValue,
                      );
                    }}
                    disclaimerText={paymentOption.PaymentDisclaimer}
                  />
                </Box>
              );
            })}
          </Box>
        )
      )}

      {isPayInstructionEnabled && (
        <Box pt={1.5} sx={classes.formTitle}>
          <MultiLineTextField
            value={localPaymentInstructions}
            placeholder="Special payment remarks"
            rowNumber={3}
            maxNumber={5}
            maxCharLength={200}
            handleChange={(event) => {
              handlePaymentInstructionsChange(event.target.value);
            }}
          />
        </Box>
      )}

      {paymentInfoText !== undefined && (
        <Box mt={1}>
          <SharpNoticePanel bgColor="warn" showIcon>
            {mapRawStringToReactElement(paymentInfoText)}
          </SharpNoticePanel>
        </Box>
      )}
    </Box>
  );
};

export default Payment;
