import axios from "axios";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { ValidateConsentPayload } from "./validateConsent.fromApi.types";
import { VALIDATE_CONSENT_URL } from "api/resources/configs/URL";
import { formatValidateConsentErrorMessage } from "api/resources/messages/ErrorMessage";

/**
 * Validate user consent for assessing a specific module
 *
 * POST
 * Endpoint: /profile/ValidateConsent
 */
const validateConsent = async (
  memberIdentifer: string | null,
  consentCode: string,
): Promise<ValidateConsentPayload> => {
  try {
    const response = await axios.post<ValidateConsentPayload>(
      VALIDATE_CONSENT_URL(),
      createRequestPayload(memberIdentifer, consentCode),
      getRequestOptions(),
    );
    return parseResponse(response, VALIDATE_CONSENT_URL());
  } catch (error) {
    const msg = formatValidateConsentErrorMessage(VALIDATE_CONSENT_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  memberIdentifer: string | null,
  consentCode: string,
) => {
  return { CareReceiverId: memberIdentifer, ConsentCode: consentCode };
};

export { validateConsent };
