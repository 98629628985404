import { SxObjType } from "lib/theme/types/SxObjType.type";
import { Theme } from "@mui/material";

const sxStyles = (theme: Theme) => {
  return {
    notFoundText: {
      color: theme.palette.grey[600],
      typography: "body2_regular",
    },
    notFoundTextBox: {
      marginTop: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    selectionListBox: {
      top: 20,
      width: "100%",
    },
  } satisfies SxObjType;
};
export { sxStyles };
