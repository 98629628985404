import { Box, List } from "@mui/material";
import { DisplayableItem } from "lib/data/data.types";
import {
  SelectionListProps,
  CategoryList,
  GroupingsPerCategory,
} from "./SelectionList.types";
import SelectionListCategory from "../SelectionListCategory/SelectionListCategory";
import SelectionListItem from "../SelectionListItem/SelectionListItem";
import { sxStyles } from "./SelectionList.styles";
import { MedInstitution } from "ui/medrefill/ducks/medrefill.types";

const SelectionList = ({ items, onSelect }: SelectionListProps) => {
  const classes = sxStyles();
  const [categories, groupings] = group(items);

  return (
    <List sx={classes.list}>
      {categories.map((category) => {
        return (
          <Box key={category}>
            <SelectionListCategory category={category}>
              {category}
            </SelectionListCategory>

            <Box>
              {groupings[category].map((displayName) => {
                return (
                  <SelectionListItem
                    key={`${category}_${displayName}`}
                    itemName={displayName}
                    onClick={() => onSelect(displayName)}
                  />
                );
              })}
            </Box>
          </Box>
        );
      })}
    </List>
  );
};

/**
 * Groups a list of displayable items into a shape that can be rendered into a
 * SelectionCateogry and SelectionListItem easily. If the list is falsy or empty, a pair
 * containing an empty list and empty object will be returned.
 *
 * @param {DisplayableItem[]} items  List of items with a display name and category
 * @returns {[CategoryList, GroupingsPerCategory]}
 *   Pair containing:
 *   - An ordered list of categories
 *   - An object whose key is the name of the category and value is the list of items contained in that category.
 *
 *   Default empty list and object pair is returned if the input is empty.
 */
const group = (
  items: DisplayableItem[] | MedInstitution[],
): [CategoryList, GroupingsPerCategory] => {
  if (!items || items.length === 0) {
    return [[], {}];
  } else {
    const categories: string[] = [];
    const groupings: GroupingsPerCategory = {};

    items.forEach(({ displayName, category }) => {
      /* Accumulate all unique categories */
      if (!categories.includes(category)) {
        categories.push(category);
      }

      /* Accumulate all unique display names into their category buckets */
      if (!Object.keys(groupings).includes(category)) {
        groupings[category] = [];
      }
      groupings[category].push(displayName);
    });

    return [categories, groupings];
  }
};

export default SelectionList;
