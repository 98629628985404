import { MHMState } from "./mhm.types";

// Initial state representing the time when mhm is not initialized yet
export const initialState: MHMState = {
  selectedCategory: {
    code: null,
  },

  systemSettings: {
    isLoading: false,
    hasErrored: null,
    errorMessage: null,
    systemSettingMap: {},
  },
  showMyChartPreventiveCare: false,

  categoryList: {
    value: null,
    isLoading: false,
    hasErrored: null,
    errorMessage: null,
  },
};
