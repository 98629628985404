import React from "react";
import { PickUpAtPharmacyProps } from "./PickUpAtPharmacy.types";
import { Box, Typography } from "@mui/material";
import { mapLocationsListFromApiToOptions } from "./mapper";
import { sxStyles } from "./PickUpAtPharmacy.styles";
import PreferredDatepicker from "../PreferredDatepicker/PreferredDatepicker";
import Dropdown from "lib/components/formInputs/Dropdown/Dropdown";
import PharmacyLabel from "./PharmacyLabel/PharmacyLabel";
import PharmacyOperatingHours from "./PharmacyOperatingHours/PharmacyOperatingHours";
import mapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { COLLECTION_TYPES } from "../Collection/CollectionCodes";

const PickUpAtPharmacy = ({
  title,
  type,
  chosenLocation,
  locations,
  timeslotOptions,
  preferredCollectionDate,
  preferredCollectionTimeslot,
  onSelectLocation,
  onSelectPreferredCollectionDate,
  onSelectPreferredCollectionTimeslot,
}: PickUpAtPharmacyProps) => {
  const classes = sxStyles();
  const locationDropdownOptions = mapLocationsListFromApiToOptions(locations);

  return (
    <Box>
      <Box mt={3} mb={2}>
        <Typography sx={classes.formTitle}>{title}</Typography>
        <Box mt={3} mb={2} sx={classes.textBox}>
          {locationDropdownOptions.length === 1 ? (
            // Just display the pharmacy location if it's just 1 element
            <Box sx={classes.singlePharmacyLabel}>
              <Typography sx={classes.formContent}>
                {type === COLLECTION_TYPES.PHARMACY.TYPE
                  ? "Pharmacy: "
                  : "Locker: "}
              </Typography>
              <PharmacyLabel>{locationDropdownOptions[0].text}</PharmacyLabel>
            </Box>
          ) : (
            // Make the user choose a pharmacy location if there's more than 1 element
            <>
              <Dropdown
                label={
                  type === COLLECTION_TYPES.PHARMACY.TYPE
                    ? "Pharmacy"
                    : "Locker"
                }
                value={chosenLocation?.LocationId}
                handleChange={(event) => onSelectLocation(event.target.value)}
                items={locationDropdownOptions}
              />
            </>
          )}
        </Box>
      </Box>

      <Box mt={2} mb={2} sx={classes.textBox}>
        {chosenLocation ? (
          <>
            <PreferredDatepicker
              label="Preferred Collection Date"
              date={preferredCollectionDate}
              location={chosenLocation}
              onSelectDate={onSelectPreferredCollectionDate}
            />

            {chosenLocation?.DisclaimerText && (
              <Typography sx={classes.formLabel}>
                {mapRawStringToReactElement(chosenLocation.DisclaimerText)}
              </Typography>
            )}

            {preferredCollectionDate &&
              timeslotOptions &&
              timeslotOptions?.length > 0 && (
                <Box mt={2}>
                  <Dropdown
                    label="Preferred Collection Time"
                    value={preferredCollectionTimeslot}
                    handleChange={(event) =>
                      onSelectPreferredCollectionTimeslot(event.target.value)
                    }
                    items={timeslotOptions}
                  />
                </Box>
              )}

            <PharmacyOperatingHours location={chosenLocation} />
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default PickUpAtPharmacy;
