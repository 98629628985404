import { Box, Typography } from "@mui/material";
import { AppointmentCardProps } from "../types/AppointmentCard.types";
import CardWrapper from "../../../common/cards/CardWrapper/CardWrapper";
import AppointmentCardHeader from "ui/appointment/components/common/cards/AppointmentCardHeader/AppointmentCardHeader";
import AppointmentCardBody from "ui/appointment/components/common/cards/AppointmentCardBody/AppointmentCardBody";
import AppointmentCardFooterSingleBtn from "../../cardButtons/AppointmentCardFooterSingleBtn/AppointmentCardFooterSingleBtn";
import { sxStyles } from "./OpenAppointmentCard.styles";
import ShowMoreLessPanel from "lib/components/notice/ShowMoreLessPanel/ShowMoreLessPanel";
import mapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { useAppSelector } from "lib/redux/hooks";
import { selectAppointments } from "ui/appointment/ducks/selectors";

const OpenAppointmentCard = ({ appointment }: AppointmentCardProps) => {
  const {
    datetime,
    institutionName,
    location,
    zoneInfo,
    serviceDisplayName,
    visitType,
    departmentName,
    clinician,
    reminder,
    formFlowDisclaimer,
    actionMessage,
    sourceSystem,
    hasTeleconsultmessage,
    isVirtualConsultAppointment,
  } = appointment;
  const classes = sxStyles();

  const teleconsultMessage =
    useAppSelector(selectAppointments).teleconsultMessage;

  return (
    <CardWrapper isLinkedAppointmentStyled={false}>
      <Box display="flex">
        <AppointmentCardHeader datetime={datetime} />
        <AppointmentCardBody
          institution={institutionName}
          datetime={datetime}
          isDateTimeHighlighted={false}
          departmentName={departmentName}
          clinician={clinician}
          location={location}
          zoneInfo={zoneInfo}
          serviceDisplayName={serviceDisplayName}
          visitType={visitType}
          isVcp={isVirtualConsultAppointment === true ? true : false}
        />
      </Box>

      {hasTeleconsultmessage && (
        <Box p={1} sx={classes.rvText}>
          <ShowMoreLessPanel message={teleconsultMessage} />
        </Box>
      )}

      {reminder && (
        <Box p={1} sx={classes.reminderText}>
          <ShowMoreLessPanel message={reminder} />
        </Box>
      )}

      {
        // !exp formFlowDisclaimer value is created by lambda
        formFlowDisclaimer && (
          <Typography sx={classes.shouldSubmitRequestDisclaimerText}>
            {mapRawStringToReactElement(formFlowDisclaimer)}
          </Typography>
        )
      }

      {sourceSystem === "NGEMR" && actionMessage && (
        <Typography sx={classes.actionMsg}>
          {mapRawStringToReactElement(actionMessage)}
        </Typography>
      )}

      <AppointmentCardFooterSingleBtn
        appointment={appointment}
        btnText="Book your next appointment"
      />
    </CardWrapper>
  );
};

export default OpenAppointmentCard;
