import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    tabsPanel: {
      boxShadow: "none",
      userSelect: "none",
    },
    tabs: {
      minHeight: 45,
      "& div.MuiTabs-scroller": {
        "& .MuiTabs-flexContainer": {
          borderBottom: `0px solid ${theme.palette.grey[100]}`,
        },
      },
      "& .MuiTabs-indicator": {
        backgroundColor: theme.palette.text.primary,
        height: 2,
      },
    },
    unSelectedTab: {
      color: theme.palette.grey[100],
      border: `0px solid ${theme.palette.grey[100]}`,
      typography: theme.typography.body2_bold,
      width: "33.33%",
      borderBottom: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderRadius: 0,
      textTransform: "capitalize",
      minHeight: 45,
      padding: 0,
      "&.Mui-selected": {
        color: theme.palette.text.primary,
        typography: theme.typography.body2_bold,
        marginBottom: "-1px",
      },
    },

    lastVisitTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.body1_semibold,
      margin: theme.spacing(0, 2, 2),
    },
    infoBox: {
      background: theme.palette.info.light,
      padding: theme.spacing(1),
      display: "flex",
      lineHeight: theme.typography.body1.lineHeight,
      borderRadius: theme.spacing(0.5),
      border: `1px solid ${theme.palette.error.dark}`,
      margin: theme.spacing(3, 1, 2),
    },
  } satisfies SxObjType;
};

export { sxStyles };
