// Appointment

export const resolveBeforeRescConfirmMsgDocomentType = (
  institutionCode: string,
) => {
  return `LBL.BeforeRescConfirmMsg.` + institutionCode;
};

export const CREATE_APPOINTMENT_SLOT_MSG_KEY =
  "LBL.NewAppointment.Consultation.Nup";
export const CANCELLATION_REASON_LIST_KEY = "LBL.ReasonForCancellationOptions";
export const MAMMOGRAM_SUB_HEADER_KEY = "LBL.Mammogram.TitleSubText";
export const MAMMOGRAM_DISCLAIMER_NOTE_KEY =
  "ONENUHS.LBL.Mammogram.DisclaimerText";
export const MAMMOGRAM_AGREEMENT_TEXT_KEY =
  "LBL.Mammogram.DisclaimerAgreementText";
export const MAMMOGRAM_SUCCESS_SUBMISSION_KEY =
  "LBL.Mammogram.Success.DescriptionText";
export const MAMMOGRAM_QUESTION_TITLE_TEXT_KEY =
  "ONENUHS.LBL.Mammogram.AppointmentQuestionTitleText";

// Payment
export const PAYMENT_BILL_DASHBOARD_TEXT_KEY = "DT.Payment.Bill.Header.ONENUHS";
export const PAYMENT_BILL_PENDING_TEXT_KEY = "DT.Payment.Bill.Pending.ONENUHS";
export const PAYMENT_BILL_PAIDBILLS_TEXT_KEY =
  "DT.Payment.PaidBills.Header.ONENUHS";
