import axios from "axios";
import { getRequestOptions } from "api/resources/configs/requests";
import { GET_MEDICATION_SUMMARY_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import {
  GetMedicationSummaryResponsePayload,
  PrescriptionRequestType,
} from "./getMedicationSummary.types";
import { formatGetMedicationSummaryErrorMessage } from "api/resources/messages/ErrorMessage";

/**
 * Fetches the Medicaton Summary for all clusters based on some parameters used to control pagination
 *
 * POST
 * Endpoint: /GetMedicationSummary
 *
 * @param {string | null} memberIdentifier encrypted identity belong to the summary requested for
 * @param {number | null} offset No. of records to offset by (only if pagination needs to be implemented)
 * @param {number | null} noOfItems Total no. of records to retrieve after the offset  (only if pagination needs to be implemented)
 * @param {"REFILL" | "TOPUP"} prescriptionRequestType type of records to retrieve
 */
const getMedicationSummary = async (
  memberIdentifier: string | null,
  offset: number | null,
  noOfItems: number | null,
  prescriptionRequestType: PrescriptionRequestType,
  oldMemberIdentifier: string | null,
  oldTokenizedId: string | null,
): Promise<GetMedicationSummaryResponsePayload> => {
  try {
    const response = await axios.post<GetMedicationSummaryResponsePayload>(
      GET_MEDICATION_SUMMARY_URL(),
      createRequestPayload(
        memberIdentifier,
        offset,
        noOfItems,
        prescriptionRequestType,
        oldMemberIdentifier,
        oldTokenizedId,
      ),
      getRequestOptions(),
    );
    return parseResponse(response, GET_MEDICATION_SUMMARY_URL());
  } catch (error) {
    const msg = formatGetMedicationSummaryErrorMessage(
      GET_MEDICATION_SUMMARY_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (
  memberIdentifier: string | null,
  offset: number | null,
  noOfItems: number | null,
  prescriptionRequestType: PrescriptionRequestType,
  oldMemberIdentifier: string | null,
  oldTokenizedId: string | null,
) => {
  return {
    MemberIdentifier: memberIdentifier,
    Offset: offset,
    NoOfItems: noOfItems,
    Cluster: null, // leave empty for this: backend is not filtering records based on this
    PrescriptionRequestType: prescriptionRequestType,
    OldMemberIdentifier: oldMemberIdentifier,
    OldTokenizedId: oldTokenizedId,
  };
};

export { getMedicationSummary };
