import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    formTitle: {
      color: theme.palette.text.primary,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: "19px",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),

      "& .MuiCheckbox-colorPrimary": {
        padding: 0,
        color: theme.palette.primary.main,
        marginRight: "8px",
      },
      "& .MuiSvgIcon-root": {
        fontSize: size.FONTSIZE_24,
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
