import { color } from "lib/theme/color";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    card: {
      boxShadow: "0px 0px 6px " + color.RGBA_52,
      backgroundColor: theme.palette.common.white,
      display: "flex",
      marginTop: theme.spacing(2),
      borderRadius: 0,
    },
    cardDate: {
      padding: theme.spacing(2, 2, 0, 2),
      marginRight: theme.spacing(0),
      textAlign: "center",
      alignItems: "start",
      display: "block",
      "& .MuiCardHeader-action": {
        color: theme.palette.grey[400],
        typography: theme.typography.body2_regular,
        padding: theme.spacing(1.5, 0),
        alignItems: "start",
        width: "100%",
      },
    },
    cardDay: {
      color: theme.palette.warning.main,
      fontSize: 30,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1,
      textTransform: "uppercase",
      margin: theme.spacing(0.5, 0),
    },
    cardMY: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      lineHeight: "18px",
      textTransform: "uppercase",
      margin: theme.spacing(0, 0, 0.5),
    },
    cardDayHighlight: {
      color: theme.palette.warning.main,
      fontSize: 30,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1,
    },
    cardDetailWrapper: {
      width: "100%",
      padding: theme.spacing(1.5, 0, 1.5, 0),
      "&:last-child": {
        paddingBottom: theme.spacing(1.5), // deactivate default padding
      },
    },
    cardDetails: {
      borderLeft: `2px solid ${theme.palette.error.main}`,
      padding: theme.spacing(0, 0, 0, 1.5),
      minHeight: "100%",
      wordBreak: "break-word",
    },
    cardTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_bold,
    },
    cardLabel: {
      color: theme.palette.warning.main,
      typography: theme.typography.body2_bold,
      paddingTop: theme.spacing(1),
    },
    cardContent: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      paddingTop: theme.spacing(1),
    },

    cardHeader: {
      padding: theme.spacing(2, 2, 0, 2),
      marginRight: theme.spacing(0),
      textAlign: "center",
      alignItems: "start",
      display: "block",
      "& .MuiCardHeader-action": {
        color: theme.palette.grey[400],
        typography: theme.typography.body2_regular,
        padding: theme.spacing(1.5, 0),
        alignItems: "start",
        width: "100%",
      },
      "& .MuiCardHeader-title": {
        color: theme.palette.grey[400],
        typography: theme.typography.body2_regular,
        lineHeight: "18px",
        textTransform: "uppercase",
        margin: theme.spacing(0, 0, 0.5),
      },
      "& .MuiCardHeader-subheader": {
        color: theme.palette.warning.main,
        fontSize: 30,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: 1,
        textTransform: "uppercase",
        margin: theme.spacing(0.5, 0),
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
