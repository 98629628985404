import { color } from "lib/theme/color";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    borderBox: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(2, 1, 0),
      backgroundColor: theme.palette.secondary.main,
      borderRadius: theme.spacing(0.5),
      position: "relative",
    },

    QueueDetailBox: {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "0px 0px 4px " + color.RGBA_0,
      borderRadius: theme.spacing(0.5),
      position: "relative",
      margin: theme.spacing(1, 2),
    },
    errorContainer: {
      margin: theme.spacing(1, 2),
    },
  } satisfies SxObjType;
};

export { sxStyles };
