import { Box, Skeleton } from "@mui/material";
import CardWrapper from "../../common/cards/CardWrapper/CardWrapper";
import { sxStyles } from "./AppointmentCardList.styles";

export const AppointmentCardSkeletons = () => {
  const classes = sxStyles();
  return (
    <>
      {Array(2)
        .fill("")
        .map((item, index) => {
          return (
            <Box sx={{ mb: 2 }} key={`box${index}`}>
              <CardWrapper>
                <Box sx={{ display: "flex", p: 1 }}>
                  <Box sx={classes.leftSkeletonContainer}>
                    <Skeleton animation="wave" sx={classes.leftSkeleton} />
                  </Box>
                  <Box sx={classes.rightSkeletonContainer}>
                    {Array(3)
                      .fill("")
                      .map((item, index) => {
                        return (
                          <Skeleton
                            animation="wave"
                            sx={classes.rightSkeleton}
                            key={`skeleton${index}`}
                          />
                        );
                      })}
                  </Box>
                </Box>
              </CardWrapper>
            </Box>
          );
        })}
      <Box
        marginBottom={1}
        display="flex"
        align-items="center"
        justifyContent="center"
        mt={2}
        textAlign="center"
      >
        <Skeleton width={"70%"} animation="wave" />
      </Box>
    </>
  );
};
