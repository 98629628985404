import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    journeyDataRegionBox: {
      margin: theme.spacing(1, 2),
      display: "flex",
      flexDirection: "column",
    },
  } satisfies SxObjType;
};

export { sxStyles };
