import { color } from "lib/theme/color";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    paidBillCard: {
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    card: {
      boxShadow: "0px 0px 6px " + color.RGBA_52,
      margin: theme.spacing(3, 1, 2),
    },
    cardTitle: {
      background: theme.palette.secondary.main,
      padding: theme.spacing(1.75, 2),
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
    },
    noRecordBox: {
      textAlign: "center",
      margin: theme.spacing(2, 1),
    },
    noRecordImage: {
      margin: theme.spacing(3, 0),
      "& img": {
        maxWidth: 200,
      },
    },
    noRecordTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_regular,
      margin: theme.spacing(2, 0),
    },

    viewPastRequestTextBox: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(0),
    },
    button: {
      padding: theme.spacing(0),
      margin: theme.spacing(1, 0, 3),
    },
  } satisfies SxObjType;
};

export { sxStyles };
