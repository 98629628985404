import { useNavigate } from "react-router-dom";
import { PATHS, ACTIONS } from "lib/routing";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { CancelRequestConfirmationModalProps } from "./CancelRequestConfirmationModal.types";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectMedRefill } from "ui/medrefill/ducks/selectors";
import { setCustomisedBack, setCustomisedClose } from "lib/redux/navigation";

const CancelRequestConfirmationModal = ({
  preventDirectCancel,
  onClickConfirm,
  onClickCancel,
}: CancelRequestConfirmationModalProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Redux state
  const { requestType } = useAppSelector(selectMedRefill);
  const displayText = requestType === "VIRTUALCONSULT" ? "order" : "request";

  const resetNavigationState = () => {
    dispatch(setCustomisedBack(false));
    dispatch(setCustomisedClose(false));
  };

  const handleClickConfirm = () => {
    if (onClickConfirm) {
      // optional event handler in case more actions need to be performed before navigating the page away
      onClickConfirm();
      resetNavigationState();
    }

    // if (preventDirectCancel) {
    // virtual consult flow
    if (requestType === "VIRTUALCONSULT") {
      mobileNavigate(ACTIONS.BACK_ACTION);
    }
    // refill or topup flow
    else {
      navigate(PATHS.MEDICATIONS_MOBILE.path, { replace: true });
    }
    // } else if (backTriggered || preventDirectBackNavigationState) {
    //   // virtual consult flow
    //   if (requestType === "VIRTUALCONSULT") {
    //     mobileNavigate(ACTIONS.BACK_ACTION);
    //   }
    //   // refill or topup flow
    //   else {
    //     navigate(-1);
    //   }
    // } else if (!isPatient) {
    //   // does a default to dependent dashboard for non-patients
    //   mobileNavigate(ACTIONS.BACK_ACTION);
    // } else {
    //   mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH);
    // }
  };

  const handleClickCancel = () => {
    onClickCancel();
    resetNavigationState();
  };

  return (
    <ConfirmationModal
      open={preventDirectCancel}
      title={`Cancel ${displayText}?`}
      body={
        <p>
          Are you sure you want to cancel this {displayText}?
          <br />
          Your progress will be lost and you will need to start over.
        </p>
      }
      nextButtonText={`Yes, cancel ${displayText}`}
      cancelButtonText={`No, continue this ${displayText}`}
      onClose={handleClickCancel}
      onClickNext={handleClickConfirm}
      onClickCancel={handleClickCancel}
    />
  );
};

export default CancelRequestConfirmationModal;
