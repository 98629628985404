import { GetAppointmentStatusPayload } from "api/appointment/GetAppointmentStatus/getAppointmentStatus.fromApi.types";
import { AppointmentStatus } from "../getAppointmentStatus.toUi.types";

/**
 * Maps fetched GetAppointmentStatus API payload to a schema that the
 * UI components can consume.
 *
 * @param {GetAppointmentStatusPayload | null} payload
 *   The payload which will be transformed
 * @returns {AppointmentStatus}
 *   AppointmentStatus data in a schema the UI can support
 */
const mapApiDataToProps = (
  payload: GetAppointmentStatusPayload | null,
): AppointmentStatus => {
  if (!payload) {
    return {
      maxslot: null,
      chronicApptId: "",
      hsgApptId: "",
      popOutMessage: "",
    };
  } else {
    return {
      maxslot: payload.MaxSlotTiming,
      chronicApptId: payload.ChronicApptId,
      hsgApptId: payload.HSGApptId,
      popOutMessage: payload.PopOutMessage,
    };
  }
};

export { mapApiDataToProps };
