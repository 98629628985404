import { useEffect } from "react";
import IMAGES from "lib/assets/images";
import { Typography, Box, CircularProgress } from "@mui/material";
import { sxStyles } from "./MedicalReportReceipt.styles";
import { MedicalReportReceiptProps } from "./MedicalReportReceipt.types";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import Page from "lib/components/layout/Page/Page";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatPayment } from "lib/util/StringUtil/formatPayment/formatPayment";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import { useAppDispatch } from "lib/redux/hooks";
import { setMessageToSend } from "lib/redux/navigation/navigationSlice";
import { MessageActions } from "lib/routing/messageChannel/messageActions";
import { useGetMessageAction } from "lib/routing/messageChannel/hooks/useGetMessageAction";

const MedicalReportReceipt = ({
  receiptPageLanding,
  getReportStatusInfoApiStatus,
  paymentStatusInfo,
  mrrpPersist,
  onSetReceiptPageLanding,
  onGetPaymentStatusInfo,
  onResetPaymentApiStatus,
}: MedicalReportReceiptProps) => {
  const classes = sxStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const institutionName = paymentStatusInfo.InstitutionName;
  const transactionDateTime = formatDate(paymentStatusInfo.TransactionDateTime);
  const transactionRefNo = paymentStatusInfo.TransactionRefNo;
  const mrrpReportNo = paymentStatusInfo.ReportNo;
  const reportType = mrrpPersist.reportType;
  const reportFees = formatPayment(paymentStatusInfo.AmountDeducted);
  const paymentStatusCode = paymentStatusInfo.PaymentStatus;
  const dateTimeStatusCode = paymentStatusInfo.TransactionStatus;
  const paymentStatusString =
    "Payment " +
    (paymentStatusCode === "S"
      ? "Successful"
      : paymentStatusCode === "F"
        ? "Unsuccessful"
        : "Pending");
  const paymentDisclaimer =
    paymentStatusCode === "S"
      ? "We will get back to you within 8 weeks"
      : paymentStatusCode === "F"
        ? "Your request will be cancelled"
        : null;
  const paymentStatusSvg =
    paymentStatusCode === "S"
      ? IMAGES.general.Successful
      : paymentStatusCode === "F"
        ? IMAGES.general.Unsuccessful
        : IMAGES.general.Pending;

  const { messageActionObj, consumeMessageActionObj } = useGetMessageAction();

  // only eNETS / Direct Debit will show the page landing ("Check Payment Status" button page)
  // for Credit Card payment, will get PaymentInfo on load of the page
  useEffect(() => {
    // restrict loading of the receipt page only after persisted data is stored back to redux, and avoid multiple calling
    if (
      mrrpPersist.paymentToken != null &&
      mrrpPersist.selectedPaymentMode === "CC" &&
      !getReportStatusInfoApiStatus.isLoading
    ) {
      onGetPaymentStatusInfo(
        mrrpPersist.paymentToken,
        mrrpPersist.reportNo,
        mrrpPersist.reportType,
        mrrpPersist.memberIdentifier,
        mrrpPersist.emailAddress,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mrrpPersist.selectedPaymentMode]);

  useEffect(() => {
    if (messageActionObj?.action === "backTriggered") {
      consumeMessageActionObj();
      onResetPaymentApiStatus();
      navigate(PATHS.MRRP_MOBILE.path, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageActionObj?.action]);

  useEffect(() => {
    // After returning from payment gateway, to unfreeze c1 header
    dispatch(
      setMessageToSend(
        MessageActions.staticTitle({ title: "", isEnabled: false }),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      {!mrrpPersist.selectedPaymentMode ||
      getReportStatusInfoApiStatus.isLoading ? (
        <Box display="flex" align-items="center" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : receiptPageLanding && mrrpPersist.selectedPaymentMode === "DD" ? (
        <Box px={2} sx={classes.checkStatusBox}>
          <SharpNoticePanel bgColor="warn">
            Return to this page to check the status of your payment
          </SharpNoticePanel>

          <ButtonsFooter
            isDisabled={!mrrpPersist.paymentToken} // renable the button only after persisted data is stored back to redux
            nextButtonText="Check payment status"
            onClickNext={() => {
              onSetReceiptPageLanding(false);
              onGetPaymentStatusInfo(
                mrrpPersist.paymentToken,
                mrrpPersist.reportNo,
                mrrpPersist.reportType,
                mrrpPersist.memberIdentifier,
                mrrpPersist.emailAddress,
              );
            }}
            hideCancelButton={true}
            cancelButtonText=""
          />
        </Box>
      ) : getReportStatusInfoApiStatus.hasErrored ? (
        <ErrorDisplayContainer
          errorMessage={getReportStatusInfoApiStatus.errorMessage}
          onTryAgain={() => {
            onResetPaymentApiStatus();
            onGetPaymentStatusInfo(
              mrrpPersist.paymentToken,
              mrrpPersist.reportNo,
              mrrpPersist.reportType,
              mrrpPersist.memberIdentifier,
              mrrpPersist.emailAddress,
            );
          }}
        />
      ) : (
        <>
          <Box sx={classes.paymentStatusRegion}>
            <img src={paymentStatusSvg} alt={paymentStatusString} />
            <Typography sx={classes.title}>{paymentStatusString}</Typography>
            <Typography sx={classes.paymentDisclaimer} align="center">
              {paymentDisclaimer}
            </Typography>
          </Box>

          <Box sx={classes.detailsRegion}>
            <Box sx={classes.detailRow}>
              <Box sx={classes.detailRegion}>
                <Typography sx={classes.label}>Institution Name</Typography>
                <Typography sx={classes.detail}>{institutionName}</Typography>
              </Box>

              <Box sx={classes.detailRegion}>
                <Typography sx={classes.label}>
                  Transaction Date Time
                </Typography>
                <Typography sx={classes.detail}>
                  {dateTimeStatusCode === "0" ? transactionDateTime : "-"}
                </Typography>
              </Box>
            </Box>

            <Box sx={classes.detailRow}>
              <Box sx={classes.detailRegion}>
                <Typography sx={classes.label}>Transaction Ref No</Typography>
                <Typography sx={classes.detail}>
                  {transactionRefNo || "-"}
                </Typography>
              </Box>
              <Box sx={classes.detailRegion}>
                <Typography sx={classes.label}>MRRP Report No</Typography>
                <Typography sx={classes.detail}>{mrrpReportNo}</Typography>
              </Box>
            </Box>

            <Box sx={classes.detailRow}>
              <Box sx={classes.detailRegion}>
                <Typography sx={classes.label}>Report Type</Typography>
                <Typography sx={classes.detail}>{reportType}</Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={classes.detailsRegion}>
            <Box sx={classes.detailRow}>
              <Box sx={classes.detailRegion}>
                <Typography sx={classes.label}>Total Payment Paid:</Typography>
              </Box>
              <Box sx={classes.detailRegion}>
                <Typography sx={classes.paymentAmount}>{reportFees}</Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={classes.notesRegion}>
            <Typography sx={classes.detail}>Note:</Typography>
            <Box sx={classes.noteRegion}>
              <Typography sx={classes.noteNumber}>1. </Typography>
              <Typography sx={classes.note}>
                For Specialist Medical Reports and Workmen Compensation cases,
                more processing time is required as a review at the Specialist
                Outpatient Clinic may be required. The patient will be notified
                if an appointment is required.
              </Typography>
            </Box>

            <Box sx={classes.noteRegion}>
              <Typography sx={classes.noteNumber}>2. </Typography>
              <Typography sx={classes.note}>
                Completed reports for Workmen Compensation cases will be
                forwarded directly to the Ministry of Manpower.
              </Typography>
            </Box>
          </Box>

          <Box sx={classes.footer}>
            {paymentStatusCode === "S" || paymentStatusCode === "F" ? (
              <ButtonsFooter
                nextButtonText="OK"
                onClickNext={() => {
                  onResetPaymentApiStatus();
                  navigate(PATHS.MRRP_MOBILE.path, { replace: true });
                }}
                hideCancelButton={true}
                cancelButtonText=""
              />
            ) : (
              <ButtonsFooter
                nextButtonText="Check status"
                onClickNext={() =>
                  onGetPaymentStatusInfo(
                    mrrpPersist.paymentToken,
                    mrrpPersist.reportNo,
                    mrrpPersist.reportType,
                    mrrpPersist.memberIdentifier,
                    mrrpPersist.emailAddress,
                  )
                }
                hideCancelButton={true}
                cancelButtonText=""
              />
            )}
          </Box>
        </>
      )}
    </Page>
  );
};

export default MedicalReportReceipt;
