import { Theme } from "@mui/material";
import { color } from "lib/theme/color";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = (theme: Theme) => {
  return {
    linkedHeader: {
      p: 1.5,
      backgroundColor: theme.palette.secondary.light,
      boxShadow: "0px 0px 6px " + color.RGBA_52,
      display: "flex",
      alignItems: "center",
    },
    linkedTitle: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_semibold,
    },
    helpIcon: {
      margin: theme.spacing(-0.2, 1),
    },
  } satisfies SxObjType;
};

export { sxStyles };
