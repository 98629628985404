import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    menulist: {
      width: "100%",

      "& .MuiOutlinedInput-root": {
        "&.Mui-disabled": {
          backgroundColor: theme.palette.secondary.light,
        },
        "& .MuiSelect-select": {
          marginRight: theme.spacing(1.5),
        },
      },

      // "& .MuiOutlinedInput-notchedOutline": {
      //   border: `1px solid ${theme.palette.grey[100]}`,
      // },
      // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      //   border: `1px solid ${theme.palette.primary.main} !important`,
      // },
      // "& .MuiFormLabel-root": { marginTop: 0, color: "red" },
      "& .MuiFormLabel-root": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        paddingLeft: "2px",
        paddingRight: "2px",
        marginTop: "-4px",
        "&.Mui-disabled": {
          color: theme.palette.grey[600],
        },
        "&.MuiInputLabel-shrink": {
          marginTop: "0px",
        },
      },
    },
    menuIem: {
      color: theme.palette.grey[400],
      fontSize: size.FONTSIZE_16,
      whiteSpace: "normal",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      width: "100%",
      "& MuiButtonBase-root": {
        padding: 0,
      },
      "& .MuiMenuItem-root": {
        color: theme.palette.grey[400],
        fontSize: size.FONTSIZE_16,
        whiteSpace: "normal",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        width: "100%",
      },
    },
    menuItemContainer: {
      color: theme.palette.grey[400],
      borderRadius: theme.spacing(0.5),
      // "&:before": {
      //   borderColor: theme.palette.primary.main,
      // },
      // "& .MuiSelect-outlined": {
      //   color: theme.palette.grey[400],
      //   fontSize: size.FONTSIZE_16,
      //   whiteSpace: "normal",
      //   overflowWrap: "break-word",
      //   wordWrap: "break-word",
      //   width: "100%",
      // },
      "& .MuiSelect-icon": {
        right: "0px",
        fontSize: "24px",
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
      },
    },

    errText: {
      color: theme.palette.error.main,
      typography: theme.typography.caption1_regular,
      padding: theme.spacing(0.5, 1.5),
    },
  } satisfies SxObjType;
};
export { sxStyles };
