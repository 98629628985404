import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import { Box, Card, CircularProgress, Typography } from "@mui/material";
import { ConfirmationLinkedApptPageProps } from "./ConfirmationBundledLinkedApptPage.types";
import Page from "lib/components/layout/Page/Page";
import VerticalSpreadLayout from "lib/components/layout/VerticalSpreadLayout/VerticalSpreadLayout";
import UserProfileContainer from "ui/appointment/components/common/user/UserProfile/UserProfileContainer";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { formatAddToCalendarURI } from "lib/mobileIntegration/addToCalendar/formatUrl";
import ConfirmationAppointmentCard from "../ConfirmationCard/ConfirmationCard";
import { CONNECT_TO_LIVE_AGENT_BUTTON_TEXT } from "lib/components/navigation/AppRouteRenderer/ErrorModal/ErrorModal";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { sxStyles } from "./ConfirmationBundledLinkedApptPage.styles";
import { getAppointmentStatus } from "api/appointment/GetAppointmentStatus/getAppointmentStatus";
import { useAppSelector } from "lib/redux/hooks";
import { selectUser } from "lib/redux/user/selectors";
import { selectPopHealthStatus } from "ui/appointment/ducks/selectors";
import { renderErrorMessage } from "lib/components/error/ErrorDisplay/renderErrorMessage";
import { ErrorModalTitles } from "lib/components/navigation/AppRouteRenderer/ErrorModal/HelperFunctions";
import { AxiosError } from "axios";

const ConfirmationLinkedApptPage = ({
  institution,
  slotDateTime,
  slotDetails,
  memberIdentifier,
  targetSystem,
  institutionCode,
  location,
  zoneInfo,
  calendarLocation,
  calendarTitle,
  onBook,
  handleErrorModal,
  isBundled,
}: ConfirmationLinkedApptPageProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = sxStyles();

  const { relationType } = useAppSelector(selectUser);
  const popHealth = useAppSelector(selectPopHealthStatus);

  // Dialog open & close handlers
  const [afterCareMessage, setAfterCareMessage] = useState<string | null>(null);
  const [isAppointmentStatusLoading, setAppointmentStatusIsLoading] =
    useState(false);
  const [openBeforeCareMessageErrorModal, setOpenBeforeCareMessageErrorModal] =
    useState(false);
  const [beforeCareMessageErrorText, setBeforeCareMessageErrorText] = useState<
    string | React.ReactNode
  >("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Request Handler
  const sendCreateAppointmentRequest = useCallback(async () => {
    try {
      // make the booking
      setAppointmentStatusIsLoading(true);
      const appointmentStatusResponseData = await getAppointmentStatus({
        memberIdentifier,
        relationType,
        institutionCode,
        targetSystem,
        enrolmentDate: popHealth.enrolmentDate,
        isHpCompleted: popHealth.isHpCompleted,
        subsequentStartDate: popHealth.subsequentStartDate,
        healthPlanCompletionDate: popHealth.healthPlanCompletionDate,
      });

      if (
        appointmentStatusResponseData.ChronicApptId === "" &&
        appointmentStatusResponseData.HSGApptId === ""
      ) {
        const slotIdList: (string | null)[] = slotDetails.map((slot, index) => {
          if (slot != null) {
            return slot.slotId;
          } else {
            // user did not pick a slot to reschedule to for this appointment, so use original appointment object's metadata
            return null;
          }
        });

        setIsLoading(true);
        try {
          const response = await onBook(
            memberIdentifier,
            institutionCode,
            slotIdList,
          );

          if (response.PatientInstruction) {
            setAfterCareMessage(response.PatientInstruction);
          }
          handleOpen();
        } catch (error) {
          if (error instanceof AxiosError) {
            handleErrorModal(
              true,
              error.response?.data.Message,
              error.response?.data.IsLiveChatEnabled
                ? CONNECT_TO_LIVE_AGENT_BUTTON_TEXT
                : undefined,
            );
          } else {
            handleErrorModal(true, null);
          }
        } finally {
          setIsLoading(false);
        }
      } else {
        setOpenBeforeCareMessageErrorModal(true);
        setBeforeCareMessageErrorText(
          renderErrorMessage(
            appointmentStatusResponseData.PopOutMessage || "",
            "APPOINTMENT_CONFIRM",
          ),
        );
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        handleErrorModal(true, error.response?.data.Message);
      } else {
        handleErrorModal(true, null);
      }
    } finally {
      setAppointmentStatusIsLoading(false);
    }
  }, [
    memberIdentifier,
    relationType,
    institutionCode,
    targetSystem,
    popHealth.enrolmentDate,
    popHealth.isHpCompleted,
    popHealth.subsequentStartDate,
    popHealth.healthPlanCompletionDate,
    slotDetails,
    onBook,
    handleErrorModal,
  ]);

  return (
    <Page>
      {isLoading || isAppointmentStatusLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Confirmation Page & Details */}
          <VerticalSpreadLayout>
            <Box>
              <UserProfileContainer showMakeAppointmentButton={false} />
              <Box sx={classes.detailsLayout}>
                <Typography sx={classes.titleText}>
                  Appointment Details
                </Typography>
                {slotDetails.map((slot, index) => (
                  <Card sx={classes.root}>
                    <ConfirmationAppointmentCard
                      institution={institution}
                      location={location}
                      zoneInfo={zoneInfo}
                      service={slot?.resource || ""}
                      slotDateTime={new Date(slot?.datetime || "") || ""}
                    />
                  </Card>
                ))}
              </Box>
            </Box>
            <Box sx={{ m: 2 }}>
              <ButtonsFooter
                nextButtonText="Book this appointment"
                cancelButtonText="Back"
                onClickNext={sendCreateAppointmentRequest}
                onClickCancel={() => navigate(-1)}
              />
            </Box>
          </VerticalSpreadLayout>

          {/* Save to Calendar Modal */}
          <ConfirmationModal
            open={open}
            title="Appointment Booked"
            body={
              afterCareMessage !== null
                ? MapRawStringToReactElement(afterCareMessage)
                : null
            }
            hideNextButton={!calendarLocation && !location}
            nextButtonText="Add to calendar"
            cancelButtonText="Go to Appointment"
            onClickNext={() => {
              mobileNavigate(
                formatAddToCalendarURI(
                  "0",
                  slotDateTime,
                  calendarLocation ? calendarLocation : location,
                  institution || "",
                  calendarTitle,
                ),
              );
              navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
            }}
            onClickCancel={() => {
              navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
            }}
            onClose={handleClose}
          />
          {/* Before Care Message Unable to schedule appointment */}
          <ConfirmationModal
            title={ErrorModalTitles.needHelp}
            body={beforeCareMessageErrorText}
            open={openBeforeCareMessageErrorModal}
            showCloseButton={false}
            hideNextButton={true}
            hideCancelButton={true}
            hideAdditionalButton={false}
            additionalButtonText="Go to Appointment"
            onClickAdditional={() => {
              navigate(PATHS.APPOINTMENT_MOBILE.path);
            }}
            onClose={() => {}}
          />
        </>
      )}
    </Page>
  );
};

export default ConfirmationLinkedApptPage;
