import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SystemState } from "./system.types";
import { initialState } from "./initialState";

/**
 * The slice reducer for the part of state representing app-wide system information.
 */
const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    initializeFromMobileIntegration(state, action: PayloadAction<SystemState>) {
      state.devicePlatform = action.payload.devicePlatform;
      state.deviceId = action.payload.deviceId;
      state.deviceInfo = action.payload.deviceInfo;
      state.devicePublicIp = action.payload.devicePublicIp;
      state.authType = action.payload.authType;
    },
  },
});

export const { initializeFromMobileIntegration } = systemSlice.actions;

export default systemSlice.reducer;
