import axios from "axios";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { UpdateUserProfilePayload } from "./updateUserProfile.fromApi.types";
import { UPDATE_USERPROFILE_URL } from "api/resources/configs/URL";
import { formatUpdateUserProfileErrorMessage } from "api/resources/messages/ErrorMessage";
import { UserProfile } from "./updateUserProfile.types";

/**
 * Update the User Profile for a user
 *
 * POST
 * Endpoint: /profile/UpdateUserProfile
 */
const updateUserProfile = async (
  userProfile: UserProfile,
): Promise<UpdateUserProfilePayload> => {
  try {
    const response = await axios.post<UpdateUserProfilePayload>(
      UPDATE_USERPROFILE_URL(),
      createRequestPayload(userProfile),
      getRequestOptions(),
    );
    return parseResponse(response, UPDATE_USERPROFILE_URL());
  } catch (error) {
    const msg = formatUpdateUserProfileErrorMessage(UPDATE_USERPROFILE_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = (userProfile: UserProfile) => {
  return { ...userProfile };
};

export { updateUserProfile };
