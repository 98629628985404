import axios from "axios";
import { CREATE_MEDICAL_REPORT_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatGetConfigurationErrorMessage } from "api/resources/messages/ErrorMessage";
import { CreateMedicalReportResponsePayload } from "./createMedicalReport.fromApi.types";
import { arrayBufferToBase64 } from "lib/util/StringUtil/base64/arrayBufferToBase64";

/**
 * Retrieves details for populating view on frontend, e.g. TnC
 * Get active configurations stored in DB based on paramName and optional hospitalCode
 *
 * POST
 * Endpoint: secure/mrrp/CreateMedicalReport
 *
 * @param {string} hospitalCode Hospital code to retrieve values for, retrieve for all when null, ADMC | CGH | IMH | KKH | KTPH | NUH | SGH | SKH | TTSH
 * @param {string} memberIdentifier
 * @param {boolean} isSelf
 * @param {string} requesterContactPerson
 * @param {string} requesterContactPhoneNo
 * @param {string} requesterMailingAdd1 // {No./Blk}, {Unit} // Unit is in the format of {Floor}-{Unit}
 * @param {string | null} requesterMailingAdd2 // {Street}
 * @param {string | null} requesterMailingAdd3 // null
 * @param {string | null} requesterMailingAdd4 // {PostalCode}
 * @param {string} requesterEmail
 * @param {string} reportTypeCode
 * @param {string} reportTypeName
 * @param {string} reportFee
 * @param {string} deptCode
 * @param {Uint8Array | null} reportFileByte
 * @param {string | null} reportFileName
 * @param {string | null} relationshipWithPatient
 * @param {string} modeOfDelivery
 * @param {string} purposeState // Purpose Description stored as purposeOfApplication (not purposeCodeOfApplication)
 * @param {string | null} remarks
 * @param {string} mailPhoneNo // Alternative phone number
 * @param {string} visitDate
 * @param {string} typeOfVisit
 */
const createMedicalReport = async (
  hospitalCode: string,
  memberIdentifier: string,
  isSelf: boolean,
  requesterContactPerson: string,
  requesterContactPhoneNo: string,
  requesterAlternatePhoneNo: string | null,
  requesterMailingAdd1: string,
  requesterMailingAdd2: string | null,
  requesterMailingAdd3: string | null,
  requesterMailingAdd4: string | null,
  requesterEmail: string,
  reportTypeCode: string,
  reportTypeName: string,
  reportFee: number,
  deptCode: string,
  reportFileByte: Uint8Array | null,
  reportFileName: string | null,
  relationWithPatient: string | null,
  modeOfDelivery: string,
  purposeState: string,
  remarks: string | null,
  visitDate: string,
  typeOfVisit: string,
  oldMemberIdentifier: string | null,
  oldTokenizedId: string | null,
): Promise<CreateMedicalReportResponsePayload> => {
  try {
    const response = await axios.post<CreateMedicalReportResponsePayload>(
      CREATE_MEDICAL_REPORT_URL(),
      {
        HospitalCode: hospitalCode,
        MemberIdentifier: memberIdentifier,
        // Whether the request is for the requestor or another party, 0 for others and 1 for self
        IsSelf: isSelf ? 1 : 0,
        RequesterContactPerson: requesterContactPerson,
        RequesterContactPhoneNo: requesterContactPhoneNo,
        MailPhoneNo: requesterAlternatePhoneNo,
        RequesterMailingAdd1: requesterMailingAdd1,
        RequesterMailingAdd2: requesterMailingAdd2,
        RequesterMailingAdd3: requesterMailingAdd3,
        RequesterMailingAdd4: requesterMailingAdd4,
        RequesterEmail: requesterEmail,
        // will be the same value as RequesterContactPerson
        MailContactPerson: requesterContactPerson,
        ReportTypeCode: reportTypeCode,
        ReportTypeName: reportTypeName,
        ReportFee: reportFee,
        DeptCode: deptCode,
        ReportFileByte: reportFileByte
          ? arrayBufferToBase64(reportFileByte)
          : null,
        ReportFileName: reportFileName,
        // Sent when request is not for self. null when sent for self.
        RelationWithPatient: relationWithPatient,
        ModeOfDelivery: modeOfDelivery,
        PurposeState: purposeState,
        Remarks: remarks,
        // ISO DateTiem string
        VisitDate: visitDate,
        // Currently only for CGH, will be "N/A" for other institutions
        TypeOfVisit: typeOfVisit,

        OldMemberIdentifier: oldMemberIdentifier,
        OldTokenizedId: oldTokenizedId,
      },
      getRequestOptions(),
    );
    return parseResponse(response, CREATE_MEDICAL_REPORT_URL());
  } catch (error) {
    const msg = formatGetConfigurationErrorMessage(CREATE_MEDICAL_REPORT_URL());
    console.error(msg);
    throw error;
  }
};

export { createMedicalReport };
