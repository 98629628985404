import { ReportDetailsSummarySectionProps } from "./ReportDetailSummarySection.types";
import { Typography } from "@mui/material";
import { sxStyles } from "./ReportDetailsSummarySection.styles";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { formatPayment } from "lib/util/StringUtil/formatPayment/formatPayment";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

const ReportDetailsSummarySection = ({
  labels,
  reportDetails,
}: ReportDetailsSummarySectionProps) => {
  const classes = sxStyles();

  const visitDateLabel = labels?.visitDate ?? "Admission Visit Date";
  const reportTypeLabel = labels?.reportType ?? "Report Type";
  const reportDescriptionLabel =
    labels?.reportDescription ?? "Report Description";
  const visitDate = getDateFromISOString(reportDetails.visitDate);

  return (
    <>
      <Typography sx={classes.title}>Report Details</Typography>

      <Typography sx={classes.subtitle}>{visitDateLabel}</Typography>
      <Typography sx={classes.content}>
        {visitDate && formatDate(formatDateToISO(visitDate), "dd/MM/yyyy")}
      </Typography>

      <Typography sx={classes.subtitle}>Purpose of Application</Typography>
      <Typography sx={classes.content}>
        {reportDetails.purposeOfApplication}
      </Typography>

      <Typography sx={classes.subtitle}>{reportTypeLabel}</Typography>
      <Typography sx={classes.content}>{reportDetails.reportType}</Typography>

      <Typography sx={classes.subtitle}>{reportDescriptionLabel}</Typography>
      <Typography sx={classes.content}>
        {reportDetails.reportDescription
          ? reportDetails.reportDescription
          : "-"}
      </Typography>

      <Typography sx={classes.subtitle}>Clinical Department</Typography>
      <Typography sx={classes.content}>
        {reportDetails.clinicalDepartment}
      </Typography>

      <Typography sx={classes.subtitle}>Report Fee</Typography>
      <Typography sx={classes.content}>
        {formatPayment(reportDetails.reportFees)}
      </Typography>
    </>
  );
};

export default ReportDetailsSummarySection;
