import axios from "axios";
import { GET_PATIENT_MASTER_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { getRequestOptions } from "api/resources/configs/requests";
import { GetPatientMasterProfileResponsePayload } from "./getPatientMasterProfile.fromApi.types";
import { formatGetPatientMasterProfileErrorMessage } from "api/resources/messages/ErrorMessage";

// Backend  will include "PAT0001" in error message when 422 is detected for get patient master
export const PM_ERROR_422 = "PAT0001";

/**
 * The function `getPatientMasterProfile` retrieves a patient's master profile using provided
 * information and returns a response payload.
 * @param  - The `getPatientMasterProfile` function is an asynchronous function that takes in an object
 * with the following parameters:
 * @returns The function `getPatientMasterProfile` is returning a `Promise` that resolves to a
 * `GetPatientMasterProfileResponsePayload`.
 */
export const getPatientMasterProfile = async ({
  myInfoName,
  myInfoDoB,
  myInfoSex,
  uuid,
  codeVerifier,
}: {
  myInfoName: string | null;
  myInfoDoB: string | null;
  myInfoSex: string | null;
  uuid: string | null | undefined;
  codeVerifier: string | null;
}): Promise<GetPatientMasterProfileResponsePayload> => {
  try {
    const response = await axios.post<GetPatientMasterProfileResponsePayload>(
      GET_PATIENT_MASTER_URL(),
      createRequestPayload({
        myInfoName,
        myInfoDoB,
        myInfoSex,
        uuid,
        codeVerifier,
      }),
      getRequestOptions(),
    );
    return parseResponse(response, GET_PATIENT_MASTER_URL(), true);
  } catch (error) {
    const msg = formatGetPatientMasterProfileErrorMessage(
      GET_PATIENT_MASTER_URL(),
    );
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = ({
  myInfoName,
  myInfoDoB,
  myInfoSex,
  uuid,
  codeVerifier,
}: {
  myInfoName: string | null;
  myInfoDoB: string | null;
  myInfoSex: string | null;
  uuid: string | null | undefined;
  codeVerifier: string | null;
}) => ({
  MyInfoName: myInfoName,
  MyInfoDoB: myInfoDoB,
  MyInfoSex: myInfoSex,
  UserId: uuid,
  CodeVerifier: codeVerifier,
});
