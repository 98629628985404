import { OidcClient } from "@axa-fr/react-oidc";
import { clearAllUserSession } from "lib/util/storage/clearAllUserSession";
import { useEffect } from "react";
const LogOut = () => {
  const oidc = OidcClient.get();
  useEffect(() => {
    clearAllUserSession();
    oidc.logoutAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default LogOut;
