/**
 * Base64 decodes the input string (assumed to be in Base64 encoding).
 *
 * @param {string | null} base64Encoded  String encoded in base64
 * @returns {string | null}  The decoded base64 string. If an exception occurred,
 *   or if the input string is NULL, then NULL will be returned.
 */
const convertFromBase64 = (base64Encoded: string | null) => {
  if (!base64Encoded) {
    return null;
  }

  try {
    // decodeURIComponent escape is needed to handle encoded special characters in utf-16. e.g. u2019, u2018
    // these characters can be passed via patient name in mobileinit
    return decodeURIComponent(escape(atob(base64Encoded)));
  } catch (error) {
    return null;
  }
};

export { convertFromBase64 };
