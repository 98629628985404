import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_semibold,
      margin: theme.spacing(2),
      marginTop: theme.spacing(2.5),
    },
    bottomTitle: {
      typography: theme.typography.body1_semibold,
      color: theme.palette.grey[400],
      mb: 1,
    },
    bottomTitleContainer: {
      ml: 2,
      mr: 2,
      mb: 2,
      marginTop: 2,
      alignItems: "center",
    },
  } satisfies SxObjType;
};

export { sxStyles };
