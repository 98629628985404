import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    dialog: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },

    dialogContent: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },

    topButtonLayout: {
      marginBottom: theme.spacing(2),
    },

    buttonLabel: {
      padding: "0.5rem",
      backgroundColor: theme.palette.info.light,
      marginTop: "1rem",
      fontSize: size.FONTSIZE_14,
    },
  } satisfies SxObjType;
};

export { sxStyles };
