import React from "react";
import { Box } from "@mui/material";
import { PageWithFooterProps } from "./VerticalSpreadLayout.types";

/**
 * A div that allows its children to be vertically spread out on the screen.
 * Useful for a mobile screen layout with a footer anchored to the bottom.
 *
 * E.g. If [Top Element] and [Btm Element] are wrapped in <Box/> or <div/>
 *     -----------------
 *     | [Top Element] |
 *     |               |
 *     |               |
 *     |               |
 *     |               |
 *     | [Btm Element] |
 *     -----------------
 */
const VerticalSpreadLayout = ({ children }: PageWithFooterProps) => {
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {children}
    </Box>
  );
};

export default VerticalSpreadLayout;
