// ==========================
// URLs Resource
//
// Contains the URLs to specific API endpoints.
//
// Depending on the environment this application is built from, the
// appropriate URLs are resolved into environment-specific configurations.
// ==========================

import {
  API_BASE_URL_SECURE,
  API_BASE_URL_TRUSTED,
  API_BASE_URL_PUBLIC,
} from "./Config";
import { store } from "lib/redux/root";
import { getAuthType } from "lib/mobileIntegration/auth/systemKey/getSystemKeys";

export const getBaseUrl = () => {
  const state = store.getState();
  let API_BASE_URL: string = "";

  switch (getAuthType(state)) {
    case "SECURED":
      API_BASE_URL = API_BASE_URL_SECURE;
      break;
    case "TRUSTED":
      API_BASE_URL = API_BASE_URL_TRUSTED;
      break;
    case "PUBLIC":
      API_BASE_URL = API_BASE_URL_PUBLIC;
      break;
    default:
      break;
  }
  return API_BASE_URL;
};

// ==========================
// HHCS general Patient Services
// ==========================

export const GET_INSTITUTIONS_BY_CLUSTERS_AND_GROUPS_URL = () =>
  `${getBaseUrl()}/data/GetInstitutionsByClusterAndGroup`;
export const GET_SYSTEMSETTINGS_URL = () =>
  `${getBaseUrl()}/data/GetSystemSettings`;
export const GET_BARCODE_IMAGE_URL = () =>
  `${getBaseUrl()}/data/GetBarCodeImage`;
export const GET_DOCUMENT_BY_CLUSTER_URL = () =>
  `${getBaseUrl()}/data/GetDocumentByCluster`;
export const GET_DOCUMENT_BY_TYPE_URL = () =>
  `${getBaseUrl()}/data/GetDocumentByType`;

export const GET_USERPROFILE_URL = () =>
  `${getBaseUrl()}/profile/GetUserProfile`;
export const GET_DEPENDENTPROFILES_URL = () =>
  `${getBaseUrl()}/profile/GetDependentProfiles`;
export const GET_PATIENTPROFILE_URL = () =>
  `${getBaseUrl()}/profile/GetPatientProfile`;
export const UPDATE_USERPROFILE_URL = () =>
  `${getBaseUrl()}/profile/UpdateUserProfile`;
export const VALIDATE_CONSENT_URL = () =>
  `${getBaseUrl()}/profile/ValidateConsent`;

// ==========================
// HHCS Patient Services (Appointments)
// ==========================

export const GET_APPOINTMENT_LIST_URL = () =>
  `${getBaseUrl()}/appointment/GetAppointmentList`;
export const GET_APPOINTMENT_STATUS_URL = () =>
  `${getBaseUrl()}/appointment/GetAppointmentStatus`;
export const GET_POP_HEALTH_STATUS_URL = () =>
  `${getBaseUrl()}/appointment/GetPopHealthStatus`;
export const GET_HEALTH_PLAN_COMPLETION_DATE_URL = () =>
  `${getBaseUrl()}/appointment/GetHealthPlanCompletionDate`;
export const CREATE_APPOINTMENT_URL = () =>
  `${getBaseUrl()}/appointment/CreateAppointment`;
export const CANCEL_APPOINTMENT_URL = () =>
  `${getBaseUrl()}/appointment/CancelAppointment`;
export const RESCHEDULE_APPOINTMENT_URL = () =>
  `${getBaseUrl()}/appointment/RescheduleAppointment`;
export const RESCHEDULE_LINKED_APPOINTMENT_URL = () =>
  `${getBaseUrl()}/appointment/RescheduleLinkedAppointment`;
export const CREATE_LINKED_APPOINTMENT_URL = () =>
  `${getBaseUrl()}/appointment/CreateLinkedAppointment`;
export const SUBMIT_APPOINTMENT_REQUEST_URL = () =>
  `${getBaseUrl()}/appointment/SubmitAppointmentRequest`;
export const CREATE_MAMMOGRAM_APPOINTMENT_REQUEST_URL = () =>
  `${getBaseUrl()}/appointment/CreateMammogramAppointment`;
export const GET_SLOT_SEARCH_DATES_URL = () =>
  `${getBaseUrl()}/appointment/GetSlotSearchDates`;
export const GET_AVAILABLE_SLOTS_URL = () =>
  `${getBaseUrl()}/appointment/GetAvailableSlots`;
export const GET_SERVICE_LIST_URL = () =>
  `${getBaseUrl()}/appointment/GetServiceList`;
export const GET_TRIAGE_QUESTIONNAIRE_URL = () =>
  `${getBaseUrl()}/appointment/GetTriageQuestionnaire`;
export const SUBMIT_TRIAGE_QUESTIONNAIRE_URL = () =>
  `${getBaseUrl()}/appointment/SubmitTriageQuestionnaire`;
export const GET_KIOSK_QUESTIONNAIRE_URL = () =>
  `${getBaseUrl()}/appointment/GetKioskQuestionnaire`;
export const GET_MAMMOGRAM_QUESTIONNAIRE_URL = () =>
  `${getBaseUrl()}/appointment/GetMammogramQuestionnaire`;
export const GET_BARCODE_URL = () =>
  `${getBaseUrl()}/appointment/GetServiceList`;
export const ACTUALISE_APPOINTMENT_URL = () =>
  `${getBaseUrl()}/appointment/ActualiseAppointment`;
export const GET_QUEUE_STATUS_AND_JOURNEY_URL = () =>
  `${getBaseUrl()}/appointment/GetQueueStatusAndJourney`;
export const UPDATE_HANDPHONE_NUMBER_URL = () =>
  `${getBaseUrl()}/appointment/UpdateHandphoneNumber`;
export const CHECK_IN_URL = () => `${getBaseUrl()}/appointment/CheckIn`;

// ==========================
// HHCS Patient Services (Encounter, aka Virtual Consult Platform)
// ==========================

export const ATTEND_CONSULTATION_URL = () =>
  `${getBaseUrl()}/encounter/AttendConsultation`;
export const REQUEUE_FOR_CONSULTATION_URL = () =>
  `${getBaseUrl()}/encounter/RequeueForConsultation`;

// ==========================
// HHCS Patient Services (Med-Refill)
// ==========================

export const GET_FACILITIES_URL = () =>
  `${getBaseUrl()}/medication/GetFacilities`;
export const SUBMIT_MEDICATION_URL = () =>
  `${getBaseUrl()}/medication/SubmitMedication`;
export const GET_MEDICATIONS_URL = () =>
  `${getBaseUrl()}/medication/GetMedications`;
export const GET_CLINICS_URL = () => `${getBaseUrl()}/medication/GetClinics`;
export const GET_COLLECTION_PAYMENT_DETAILS_URL = () =>
  `${getBaseUrl()}/medication/GetCollectionPaymentDetails`;
export const GET_MEDICATION_SUMMARY_DETAIL_URL = () =>
  `${getBaseUrl()}/medication/GetMedicationSummaryDetail`;
export const GET_MEDICATION_IMAGES_URL = () =>
  `${getBaseUrl()}/medication/GetMedicationImages`;
export const GET_MEDICATION_SUMMARY_URL = () =>
  `${getBaseUrl()}/medication/GetMedicationSummary`;
export const GET_MOST_RECENT_MEDICATION_SUMMARY_URL = () =>
  `${getBaseUrl()}/medication/GetMostRecentMedicationSummary`;
export const GET_LOCATIONS_URL = () =>
  `${getBaseUrl()}/medication/GetLocations`;

// ==========================
// HHCS Patient Services (MRRP)
// ==========================

export const GET_REPORT_HISTORY_URL = () =>
  `${getBaseUrl()}/mrrp/GetReportHistory`;
export const GET_CONFIGURATION_URL = () =>
  `${getBaseUrl()}/mrrp/GetConfiguration`;
export const GET_REPORT_OPTIONS_URL = () =>
  `${getBaseUrl()}/mrrp/GetReportOptions`;
export const GET_HOSPITAL_URL = () => `${getBaseUrl()}/mrrp/GetHospital`;
export const VALIDATE_PATIENT_MRN_URL = () =>
  `${getBaseUrl()}/mrrp/ValidatePatientMrn`;
export const CREATE_MEDICAL_REPORT_URL = () =>
  `${getBaseUrl()}/mrrp/CreateMedicalReport`;
export const DOWNLOAD_MEDICAL_REPORT_URL = () =>
  `${getBaseUrl()}/mrrp/DownloadMedicalReport`;
export const SUBMIT_PAYMENT_URL = () => `${getBaseUrl()}/mrrp/SubmitPayment`;
export const GET_PAYMENT_STATUS_INFO_URL = () =>
  `${getBaseUrl()}/mrrp/GetPaymentStatusInfo`;
export const GET_ALL_BY_PLATFORM_URL = () =>
  `${getBaseUrl()}/data/GetAllByPlatform`;

// ==========================
// HHCS Patient Services, Common Service (Payments)
// ==========================

export const ADD_MANUAL_BILL_URL = () =>
  `${getBaseUrl()}/payment/AddManualBill`;
export const GET_PDF_BILL_URL = () => `${getBaseUrl()}/payment/GetPdfBill`;
export const GET_PAYMENT_INFO_URL = () =>
  `${getBaseUrl()}/payment/GetPaymentInfo`;
export const SUBMIT_PAYMENT_REQUEST_URL = () =>
  `${getBaseUrl()}/payment/SubmitPaymentRequest`;
export const GET_OUTSTANDING_BILLS_URL = () =>
  `${getBaseUrl()}/payment/GetOutstandingBills`;
export const GET_PAID_BILLS_HISTORY_URL = () =>
  `${getBaseUrl()}/payment/GetPaidBillsHistory`;
export const GET_BILL_SUMMARY_DETAILS_URL = () =>
  `${getBaseUrl()}/payment/GetBillSummaryDetails`;
export const GET_AR_INSTITUTIONS_URL = () =>
  `${getBaseUrl()}/data/GetArInstitutions`;
export const GET_TRANSACTION_HISTORY_URL = () =>
  `${getBaseUrl()}/payment/GetTransactionHistory`;
export const GET_RECEIPT_PDF_URL = () =>
  `${getBaseUrl()}/payment/GetReceiptPdf`;
export const GET_AVAILABLE_PAYMENT_MODES_URL = () =>
  `${getBaseUrl()}/payment/GetAvailablePaymentModes`;

// ==========================
// HHCS Patient Services (MHM)
// ==========================

export const GET_CARE_PLAN_PACKAGE_ENROLLMENT_URL = `${API_BASE_URL_SECURE}/careplan/GetCarePlanPackageEnrollment`;
export const ENROLL_IN_CAREPLAN_URL = `${API_BASE_URL_SECURE}/careplan/EnrollInCarePlanPackage`;
export const GET_CAREPLAN_CATEGORY_LIST_URL = `${API_BASE_URL_SECURE}/careplan/GetCarePlanCategoryList`;
export const GET_CAREPLAN_LIST_URL = `${API_BASE_URL_SECURE}/careplan/GetCarePlanList`;
export const GET_QUESTIONNAIRE_BY_CATEGORY_URL = `${API_BASE_URL_SECURE}/careplan/GetQuestionnaireByCategory`;
export const UPDATE_QUESTIONNAIRE_BY_CATEGORY_URL = `${API_BASE_URL_SECURE}/careplan/UpdateQuestionnaireByCategory`;

// ==============================
// HHCS Patient Services (MyInfo)
// ==============================
export const GET_MY_INFO_AUTH_URL = () =>
  `${getBaseUrl()}/myinfo/GetMyInfoAuthUrl`;
export const RETRIEVE_MY_INFO_PROFILE_URL = () =>
  `${getBaseUrl()}/myinfo/RetrieveMyInfoProfile`;

// ==============================
// Patient Master CCDP Lambda
// ==============================
export const CREATE_PATIENT_MASTER_URL = () =>
  `${getBaseUrl()}/profile/CreatePatientMasterProfile`;
export const GET_PATIENT_MASTER_URL = () =>
  `${getBaseUrl()}/profile/GetPatientMasterProfile`;
export const UPDATE_PATIENT_MASTER_URL = () =>
  `${getBaseUrl()}/profile/UpdatePatientMasterProfile`;

// =============================
// HHCS Patient Services (data)
// =============================
export const GET_ANNOUNCEMENTS_URL = () =>
  `${getBaseUrl()}/data/GetAnnouncements`;
export const GET_LIVE_CHAT_FLAG_URL = () =>
  `${getBaseUrl()}/data/GetLiveChatFlag`;

// =============================
// HHCS Patient Services (HEALTH TOGETHER)
// =============================
export const GET_STATIC_CONTENTS_BY_MODULE_URL = () =>
  `${getBaseUrl()}/data/GetStaticContentsByModule`;
export const ENROLL_IN_HEALTHTOGETHER_CAREPLAN_URL = `${API_BASE_URL_SECURE}/careplan/EnrollInHealthTogetherCarePlanPackage`;
