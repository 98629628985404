import { useState, useEffect } from "react";
import { PopHealthStatus } from "api/appointment/GetPopHealthStatus/getPopHealthStatus.toUi.types";
import { mapApiDataToProps as popHealthMapper } from "api/appointment/GetPopHealthStatus/mappers/mapper";
import { mapApiDataToProps as apptStatusMapper } from "api/appointment/GetAppointmentStatus/mappers/mapper";
import { getPopHealthStatus } from "api/appointment/GetPopHealthStatus/getPopHealthStatus";
import { getAppointmentStatus } from "api/appointment/GetAppointmentStatus/getAppointmentStatus";
import { RelationType } from "lib/redux/user/user.types";
import { AppointmentStatus } from "api/appointment/GetAppointmentStatus/getAppointmentStatus.toUi.types";
import { AxiosError } from "axios";

interface popHealthandAppointmentStatusRequest {
  memberIdentifier: string | null;
  clinicCode: string | null;
  relationType: RelationType;
  institutionCode: string | null;
  targetSystem: string | null;
  trigger: boolean;
}

const useGetPopHealthandAppointmentStatus = ({
  memberIdentifier,
  clinicCode,
  relationType,
  institutionCode,
  targetSystem,
  trigger,
}: popHealthandAppointmentStatusRequest): [
  [PopHealthStatus | null, boolean, boolean | null, string | null],
  [AppointmentStatus | null, boolean, boolean | null, string | null],
] => {
  const [popHealthData, setPopHealthData] = useState<PopHealthStatus | null>(
    null,
  );
  const [isPopHealthLoading, setPopHealthIsLoading] = useState(false);
  const [hasPopHealthErrored, setHasPopHealthErrored] = useState<
    boolean | null
  >(null);
  const [popHealthErrorMessage, setPopHealthErrorMessage] = useState<
    string | null
  >(null);

  const [appointmentStatusData, setAppointmentStatusData] =
    useState<AppointmentStatus | null>(null);
  const [isAppointmentStatusLoading, setAppointmentStatusIsLoading] =
    useState(false);
  const [hasAppointmentStatusErrored, setHasAppointmentStatusErrored] =
    useState<boolean | null>(null);
  const [appointmentStatusErrorMessage, setAppointmentStatusErrorMessage] =
    useState<string | null>(null);

  useEffect(() => {
    if (trigger) {
      (async () => {
        setPopHealthIsLoading(true);

        try {
          const popHealthResponseData = await getPopHealthStatus(
            memberIdentifier,
            institutionCode,
          );

          const popHealth = popHealthMapper(popHealthResponseData);
          setPopHealthData(popHealth);
          setHasPopHealthErrored(false);
          setPopHealthErrorMessage(null);

          setAppointmentStatusIsLoading(true);
          try {
            const appointmentStatusResponseData = await getAppointmentStatus({
              memberIdentifier,
              relationType,
              institutionCode,
              targetSystem,
              enrolmentDate: popHealth.enrolmentDate,
              isHpCompleted: popHealth.isHpCompleted,
              subsequentStartDate: popHealth.subsequentStartDate,
              healthPlanCompletionDate: popHealth.healthPlanCompletionDate,
            });

            const appointmentStatus = apptStatusMapper(
              appointmentStatusResponseData,
            );
            setAppointmentStatusData(appointmentStatus);
            setHasAppointmentStatusErrored(false);
            setAppointmentStatusErrorMessage(null);
          } catch (error) {
            setAppointmentStatusData(null);
            setHasAppointmentStatusErrored(true);

            if (error instanceof AxiosError) {
              setAppointmentStatusErrorMessage(error.response?.data.Message);
            } else {
              setAppointmentStatusErrorMessage(null);
            }
          } finally {
            setAppointmentStatusIsLoading(false);
          }
        } catch (error) {
          setPopHealthData(null);
          setHasPopHealthErrored(true);
          if (error instanceof AxiosError) {
            setPopHealthErrorMessage(error.response?.data.Message);
          } else {
            setPopHealthErrorMessage(null);
          }
        } finally {
          setPopHealthIsLoading(false);
        }
      })();
    }
  }, [
    clinicCode,
    memberIdentifier,
    relationType,
    institutionCode,
    targetSystem,
    trigger,
  ]);

  return [
    [
      popHealthData,
      isPopHealthLoading,
      hasPopHealthErrored,
      popHealthErrorMessage,
    ],
    [
      appointmentStatusData,
      isAppointmentStatusLoading,
      hasAppointmentStatusErrored,
      appointmentStatusErrorMessage,
    ],
  ];
};

export { useGetPopHealthandAppointmentStatus };
