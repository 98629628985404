import React from "react";
import { ReactNode } from "react";
import ReactStringReplace from "react-string-replace";

/**
 * Replaces all occurrences of a specific string with a React Router Link component.
 *
 * Useful for error messages like:
 *   'There is no appointment available at the moment. Please try again later.If you still encounter the same issue
 *    please call the Appointment Hotline at <a class="ma-tel" href="tel:65558828">65558828</a> or
 *    [REQUESTFORMLINK] to make a request via the offline form'
 *
 * which will get replaced to
 *
 *   'There is no appointment available at the moment. Please try again later.If you still encounter the same issue
 *   please call the Appointment Hotline at <a class="ma-tel" href="tel:65558828">65558828</a> or
 *   {<React Component inserted here />} to make a request via the offline form'
 *
 * @param {string | React.ReactNodeArray} inputMessage  Input message to replace
 * @param {string | RegExp} replaceWith  The token to replace with
 * @param {string} RedirectLinkComponent  React link component to replace the token with
 *
 * @returns {React.ReactNodeArray}  React components with replaced elements
 */
export const replaceStringWithReactLinkElement = (
  inputMessage: string | ReactNode[],
  replaceWith: string | RegExp,
  RedirectLinkComponent: ReactNode,
): ReactNode[] => {
  try {
    return ReactStringReplace(inputMessage, replaceWith, (match, index) => {
      return (
        <React.Fragment key={index}>{RedirectLinkComponent}</React.Fragment>
      );
    });
  } catch (error) {
    // shouldn't crash entire UI if string replacement doesn't work.
    return typeof inputMessage === "string" ? [inputMessage] : inputMessage;
  }
};
