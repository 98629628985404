import { Box, Typography, useTheme } from "@mui/material";
import { AddressFieldsProps } from "./AddressFields.types";
import { sxStyles } from "./AddressFields.styles";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import { PATHS, retrievePath } from "lib/routing";
import { useLocation } from "react-router-dom";

const AddressFields = ({
  postalCode,
  block,
  unitNo,
  street,
  onChangePostalCode,
  onChangeBlock,
  onChangeUnitNo,
  onChangeStreet,
  showRequiredText = true,
  disabled = false,
  errorPostalCode = false,
}: AddressFieldsProps) => {
  const theme = useTheme();
  const classes = sxStyles(theme);
  const { pathname } = useLocation();
  const path = retrievePath(pathname);

  return (
    <Box>
      {path?.path === PATHS.MY_PROFILE_MOBILE.path ||
      path?.path === PATHS.MY_PROFILE_MOBILE_ERROR.path ||
      path?.path === PATHS.MY_PROFILE.path ||
      path?.path === PATHS.MYINFO_AND_PROFILE_CONFIRM.path ||
      path?.path === PATHS.MY_INFOPROFILE.path ? (
        <>
          <Box mt={2.5}>
            <Typography sx={classes.formLabel}>Postal Code</Typography>
            <Typography sx={classes.formValue}>{postalCode ?? "-"}</Typography>
          </Box>
          <Box mt={2.5}>
            <Typography sx={classes.formLabel}>Street</Typography>
            <Typography sx={classes.formValue}>{street ?? "-"}</Typography>
          </Box>
          <Box sx={classes.streetUnitBlock}>
            <Box>
              <Typography sx={classes.formLabel}>No. / Blk</Typography>
              <Typography sx={classes.formValue}>{block ?? "-"}</Typography>
            </Box>
            <Box ml={6}>
              <Typography sx={classes.formLabel}>Unit</Typography>
              <Typography sx={classes.formValue}>{unitNo ?? "-"}</Typography>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box sx={classes.addressGroup}>
            <Box>
              <SingleLineTextField
                type={disabled ? "text" : "number"}
                value={disabled ? postalCode || "-" : postalCode || ""}
                placeholder="Postal Code"
                maxCharLength={6}
                handleChange={(event) => onChangePostalCode(event.target.value)}
                error={errorPostalCode}
                errorText={"Invalid postal code"}
                required={showRequiredText}
              />
            </Box>
            <Box>
              <SingleLineTextField
                type="text"
                value={disabled ? block || "-" : block || ""}
                placeholder="No. / Blk"
                maxCharLength={10}
                handleChange={(event) => onChangeBlock(event.target.value)}
                required={showRequiredText}
              />
            </Box>
            <Box>
              <SingleLineTextField
                type="text"
                value={disabled ? unitNo || "-" : unitNo || ""}
                placeholder="Unit"
                maxCharLength={10}
                handleChange={(event) => onChangeUnitNo(event.target.value)}
                required={false}
              />
            </Box>
          </Box>
          <Box sx={classes.textBox}>
            <SingleLineTextField
              type="text"
              value={disabled ? street || "-" : street || ""}
              placeholder="Street"
              maxCharLength={50}
              handleChange={(event) => onChangeStreet(event.target.value)}
              required={showRequiredText}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default AddressFields;
