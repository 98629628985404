import { connect } from "react-redux";
import PayorInfo from "./PayorInfo";
import { RootDispatch, RootState } from "lib/redux/root/redux.types";
import {
  clearState,
  resetApiStatus,
  setPaymentPerist,
  getSubmitPaymentRequest,
  fetchAvailablePaymentModes,
  setShouldUpdate,
} from "ui/payment/ducks";
import {
  ApiEnum,
  PaymentBillsToPayState,
  PaymentPersist,
} from "ui/payment/ducks/payment.types";
import { setCustomisedBack } from "lib/redux/navigation";

// Function to calculate total amount to pay on the go
const totalAmountToPayReducer = (
  accumulator: number,
  currentBill: PaymentBillsToPayState,
): number => {
  const currentValue = currentBill.Selected
    ? Number(currentBill.AmountToPay)
    : 0;
  return accumulator + currentValue;
};

const mapStateToProps = (state: RootState) => {
  const rawAmountToPay = state.payments.billsToPay.reduce(
    totalAmountToPayReducer,
    0,
  );
  return {
    submitPaymentRequestApiStatus:
      state.payments.apiStatus[ApiEnum.SubmitPaymentRequest],
    amountToPay: Number(rawAmountToPay.toFixed(2)),
    payorName: state.user.requestorName,
    contact: state.user.contact,
    email: state.user.email,
    submitPaymentResponse: state.payments.payment.submitPaymentRequest,
    memberIdentifier: state.user.memberIdentifier,
    preventDirectBackNavigationState: state.navigation.customisedBack,

    billsToPay: state.payments.billsToPay,
    allSystemBills: state.payments.outstandingBills.allOutstandingBills,
    allAddedBills: state.payments.addedBills.allAddedBills,
    isLoadingAvailablePaymentModes:
      state.payments.availablePaymentModes.isLoading,
    hasErroredAvailablePaymentModes:
      state.payments.availablePaymentModes.hasErrored,
    errorMessageAvailablePaymentModes:
      state.payments.availablePaymentModes.errorMessage,
    IsCcAvailable: state.payments.availablePaymentModes.IsCcAvailable,
    IsDdAvailable: state.payments.availablePaymentModes.IsDdAvailable,
    IsAmexAvailable: state.payments.availablePaymentModes.IsAmexAvailable,
    CcDisclaimer: state.payments.availablePaymentModes.CcDisclaimer,
    DdDisclaimer: state.payments.availablePaymentModes.DdDisclaimer,
    AmexDisclaimer: state.payments.availablePaymentModes.AmexDisclaimer,
  };
};

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    callAvailablePaymentModes: () => {
      dispatch(fetchAvailablePaymentModes());
    },
    onResetDashboardShouldRefresh: () => {
      dispatch(setShouldUpdate(true));
    },
    onResetPaymentApiStatus: () => {
      dispatch(resetApiStatus());
    },
    onSetPaymentPersist: (paymentPersist: PaymentPersist) => {
      dispatch(setPaymentPerist(paymentPersist));
    },
    onSubmitPaymentRequest: (
      institutionCode: string | null,
      paymentMethod: string | null,
      totalAmount: number | null,
      payorName: string | null,
      mobileNo: string | null,
      email: string | null,
      systemSetting?: boolean | null,
    ) => {
      dispatch(
        getSubmitPaymentRequest(
          institutionCode,
          paymentMethod,
          totalAmount,
          payorName,
          mobileNo,
          email,
          systemSetting,
        ),
      );
    },
    onExitCurrentFlow: () => {
      dispatch(clearState());
    },
    resetPreventDirectBackNavigationState: () => {
      dispatch(setCustomisedBack(false));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayorInfo);
