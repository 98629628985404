import { useEffect, useState } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { BillDetailProps } from "./BillDetail.types";
import { sxStyles } from "./BillDetail.styles";
import IMAGES from "lib/assets/images";
import ProfileHeaderContainer from "../../common/ProfileHeader/ProfileHeaderContainer";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import { formatPayment } from "lib/util/StringUtil/formatPayment/formatPayment";
import { useNavigate } from "react-router-dom";
import { ACTIONS, PATHS } from "lib/routing";
import {
  MAX_AMOUNT_PAYABLE_PER_TRANSACTION,
  MAX_DIGIT_OF_FRACTIONAL_PART,
  MAX_DIGIT_OF_WHOLE_NUMBER_PART,
  TRANSACTION_LIMIT_ERROR,
} from "../../Constants";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import IconTextButton from "lib/components/buttons/IconTextButton/IconTextButton";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

const BillDetail = ({
  memberIdentifier,
  billDetailsState,
  billInvoiceNumber,
  systemBill,
  isLoadingPendingMsg,
  pendingMsg,
  clearPastReports,
  callGetBillSummaryDetails,
  setBillToPay,
  fetchPendingMsg,
  amountToPayStateValue,
}: BillDetailProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();

  const isLoadingBillDetails = billDetailsState?.isLoading;
  const hasErroredBillDetails = billDetailsState?.hasErrored;
  const errorMessageBillDetails = billDetailsState?.errorMessage;
  const billDetails = billDetailsState?.billDetails;
  // const otherSubsidies = billDetails?.OtherSubsidies;

  const isBeingProceesed = systemBill.HasPendingArPosting;

  const downloadPdfForThisBill = () => {
    if (billDetails) {
      mobileNavigate(
        `${ACTIONS.GET_PDF_BILL_ACTION}&BillDate=${systemBill.RequestedDateTime}&BillingSystem=${systemBill.BillingSystem}&InstitutionCode=${systemBill.InstitutionCode}&DownloadId=${systemBill.DownloadId}&MemberIdentifier=${memberIdentifier}&BillCategory=Outstanding`,
      );
    }
  };

  useEffect(() => {
    // onLoad of the page
    callGetBillSummaryDetails(billInvoiceNumber, systemBill.InstitutionCode);
    fetchPendingMsg();
    logEventToGoogleAnalytics(EVENTS.VIEW_PAYMENT_LANDING);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let dateTimeObj: Date | null = null;
  dateTimeObj = getDateFromISOString(billDetails?.BillDate || null);
  const [amountToPay, setAmountToPay] = useState<string | null>(null);

  // TODO: change useEffect to function approach
  useEffect(() => {
    const amount =
      amountToPayStateValue !== null
        ? amountToPayStateValue
        : billDetails?.FinalAmountPayable || null;
    const formattedAmount = formatPayment(amount).slice(2);
    setAmountToPay(formattedAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billDetails]);

  const disablePayment = () => {
    if (isBeingProceesed) {
      return true;
    } else if (amountToPay && billDetails) {
      const payment = Number(amountToPay);
      if (
        payment <= billDetails.FinalAmountPayable &&
        payment <= MAX_AMOUNT_PAYABLE_PER_TRANSACTION &&
        payment > 0
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <ProfileHeaderContainer title="'s Bills" />
      {isLoadingBillDetails || isLoadingPendingMsg ? (
        <Box
          display="flex"
          align-items="center"
          justifyContent="center"
          mt={4}
          p={5}
        >
          <CircularProgress />
        </Box>
      ) : hasErroredBillDetails ? (
        <ErrorDisplayContainer
          errorMessage={errorMessageBillDetails}
          onTryAgain={() => {
            clearPastReports();
            callGetBillSummaryDetails(
              billInvoiceNumber,
              systemBill.InstitutionCode,
            );
          }}
        />
      ) : (
        <>
          <Box sx={classes.card}>
            <Box sx={classes.cardHeader}>
              <Typography sx={classes.cardMainTitle}>
                Final Amount Payable
              </Typography>
              <Typography sx={classes.cardAmountValue}>
                {formatPayment(billDetails?.FinalAmountPayable || null)}
              </Typography>
            </Box>
            <Box sx={classes.cardDetailWrapper}>
              <Box sx={classes.cardHeadDetails}>
                <Box sx={classes.cardLeft}>
                  <Typography sx={classes.cardLabel}>
                    Bill Date
                    <Typography sx={classes.cardDate}>
                      {dateTimeObj
                        ? formatDate(
                            formatDateToISO(dateTimeObj),
                            "dd MMM yyyy",
                          )
                        : null}
                    </Typography>
                  </Typography>
                </Box>
                <Box sx={classes.cardRight}>
                  <IconTextButton
                    icon={IMAGES.general.DownloadIcon}
                    label={["Download", "bill"]}
                    onClick={async () => downloadPdfForThisBill()}
                    ariaLabel={"Download bill"}
                  />
                </Box>
              </Box>
              <Box pt={0.5}>
                <Box sx={classes.cardDetails}>
                  <Box>
                    <Typography sx={classes.cardTitle}>
                      {systemBill.InstitutionName}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={classes.cardDetails}>
                  <Box>
                    <Typography sx={classes.cardLabel}>
                      Bill Reference No: {billDetails?.InvoiceRefNo}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* <Box pt={0.5}>
                <Box sx={classes.cardDetails}>
                  <Box sx={classes.cardLeft}>
                    <Typography sx={classes.cardTitle}>
                      Summary
                    </Typography>
                  </Box>
                </Box>
                <Box sx={classes.cardDetails}>
                  <Box sx={classes.cardHeadLeft}>
                    <Typography sx={classes.cardLabel}>
                      Bill for
                    </Typography>
                  </Box>
                  <Box sx={classes.cardHeadRight}>
                    <Typography sx={classes.cardTitle}>
                      {systemBill.InstitutionName}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={classes.cardDetails}>
                  <Box sx={classes.cardHeadLeft}>
                    <Typography sx={classes.cardLabel}>
                      Bill Reference No
                    </Typography>
                  </Box>
                  <Box sx={classes.cardHeadRight}>
                    <Typography sx={classes.cardTitle}>
                      {billDetails?.InvoiceRefNo}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={classes.cardDetails}>
                  <Box sx={classes.cardHeadLeft}>
                    <Typography sx={classes.cardLabel}>
                      Invoice Type
                    </Typography>
                  </Box>
                  <Box sx={classes.cardHeadRight}>
                    <Typography sx={classes.cardTitle}>
                      {billDetails?.InvoiceType}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box pt={1}>
                {!billDetails?.GovtSubsidyAmount &&
                otherSubsidies?.length === 0 &&
                !billDetails?.DiscountSummary &&
                !billDetails?.SpecialDiscount &&
                !billDetails?.WaiverSummary &&
                !billDetails?.Discount ? null : (
                  <>
                    <Box sx={classes.cardDetails}>
                      <Box sx={classes.cardLeft}>
                        <Typography sx={classes.cardTitle}>
                          Total Amount
                        </Typography>

                        {billDetails?.GovtSubsidyAmount ? (
                          <Typography sx={classes.cardSubTitle}>
                            (Before Govt Subsidy)
                          </Typography>
                        ) : null}
                      </Box>
                      <Box sx={classes.cardRight}>
                        <Typography sx={classes.cardAmount}>
                          {formatPayment(
                            billDetails?.AmountBeforeGovtSubsidy || null
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}
                {billDetails?.GovtSubsidyAmount ? (
                  <Box sx={classes.cardDetails}>
                    <Box sx={classes.cardLeft}>
                      <Typography sx={classes.cardLabel}>
                        Subsidised by Govt
                      </Typography>
                    </Box>
                    <Box sx={classes.cardRight}>
                      <Typography sx={classes.cardValue}>
                        {formatPayment(billDetails?.GovtSubsidyAmount)}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                {otherSubsidies &&
                  otherSubsidies.map((item) => {
                    return (
                      <Box sx={classes.cardDetails}>
                        <Box sx={classes.cardLeft}>
                          <Typography sx={classes.cardLabel}>
                            {item?.Type}
                          </Typography>
                        </Box>
                        <Box sx={classes.cardRight}>
                          <Typography sx={classes.cardValue}>
                            {formatPayment(item?.Amount || null)}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                {billDetails?.DiscountSummary ? (
                  <Box sx={classes.cardDetails}>
                    <Box sx={classes.cardLeft}>
                      <Typography sx={classes.cardLabel}>
                        Discount
                      </Typography>
                    </Box>
                    <Box sx={classes.cardRight}>
                      <Typography sx={classes.cardValue}>
                        {formatPayment(billDetails?.DiscountSummary)}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                {billDetails?.SpecialDiscount ? (
                  <Box sx={classes.cardDetails}>
                    <Box sx={classes.cardLeft}>
                      <Typography sx={classes.cardLabel}>
                        Discount (Special)
                      </Typography>
                    </Box>
                    <Box sx={classes.cardRight}>
                      <Typography sx={classes.cardValue}>
                        {formatPayment(billDetails?.SpecialDiscount)}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                {billDetails?.WaiverSummary ? (
                  <Box sx={classes.cardDetails}>
                    <Box sx={classes.cardLeft}>
                      <Typography sx={classes.cardLabel}>
                        Waiver
                      </Typography>
                    </Box>
                    <Box sx={classes.cardRight}>
                      <Typography sx={classes.cardValue}>
                        {formatPayment(billDetails?.WaiverSummary)}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                <Box sx={classes.cardDetails}>
                  <Box sx={classes.cardLeft}>
                    <Typography sx={classes.cardTitle}>
                      Total Amount
                    </Typography>
                    <Typography sx={classes.cardSubTitle}>
                      (Before GST)
                    </Typography>
                  </Box>
                  <Box sx={classes.cardRight}>
                    <Typography sx={classes.cardAmount}>
                      {formatPayment(
                        billDetails?.TotalAmountExcludingGst || null
                      )}
                    </Typography>
                  </Box>
                </Box>
                {billDetails?.GstAdjustments ? (
                  <Box sx={classes.cardDetails}>
                    <Box sx={classes.cardLeft}>
                      <Typography sx={classes.cardLabel}>
                        Rounding Adjustments for GST
                      </Typography>
                    </Box>
                    <Box sx={classes.cardRight}>
                      <Typography sx={classes.cardValue}>
                        {formatPayment(billDetails?.GstAdjustments)}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                {billDetails?.GstPercent && billDetails.GstAmount ? (
                  <Box sx={classes.cardDetails}>
                    <Box sx={classes.cardLeft}>
                      <Typography sx={classes.cardLabel}>
                        {billDetails.GstPercent}% GST
                      </Typography>
                    </Box>
                    <Box sx={classes.cardRight}>
                      <Typography sx={classes.cardValue}>
                        {formatPayment(billDetails?.GstAmount)}
                      </Typography>
                    </Box>
                  </Box>
                ) : billDetails?.GstList ? (
                  billDetails?.GstList.map((gstItem) => {
                    return (
                      <Box sx={classes.cardDetails}>
                        <Box sx={classes.cardLeft}>
                          <Typography sx={classes.cardLabel}>
                            {gstItem.GstPercent}% GST
                          </Typography>
                        </Box>
                        <Box sx={classes.cardRight}>
                          <Typography sx={classes.cardValue}>
                            {formatPayment(gstItem?.GstAmount)}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })
                ) : null}
                {billDetails?.GstAbsorbedByGovtAmount ? (
                  <Box sx={classes.cardDetails}>
                    <Box sx={classes.cardLeft}>
                      <Typography sx={classes.cardLabel}>
                        GST absorbed by Govt
                      </Typography>
                    </Box>
                    <Box sx={classes.cardRight}>
                      <Typography sx={classes.cardValue}>
                        {formatPayment(billDetails?.GstAbsorbedByGovtAmount)}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                {billDetails?.TransferredInChargesTotalAmount ? (
                  <Box sx={classes.cardDetails}>
                    <Box sx={classes.cardLeft}>
                      <Typography sx={classes.cardLabel}>
                        Transferred In Charges
                      </Typography>
                    </Box>
                    <Box sx={classes.cardRight}>
                      <Typography sx={classes.cardValue}>
                        {formatPayment(
                          billDetails?.TransferredInChargesTotalAmount
                        )}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
              </Box>

              <Box pt={1}>
                <Box sx={classes.cardDetails}>
                  <Box sx={classes.cardLeft}>
                    <Typography sx={classes.cardTitle}>
                      Total Amount
                    </Typography>

                    {billDetails?.GovtSubsidyAmount ? (
                      <Typography sx={classes.cardSubTitle}>
                        (After Govt Subsidy)
                      </Typography>
                    ) : null}
                  </Box>

                  <Box sx={classes.cardRight}>
                    <Typography sx={classes.cardAmount}>
                      {formatPayment(
                        billDetails?.AmountAfterGovtSubsidy || null
                      )}
                    </Typography>
                  </Box>
                </Box>
                {billDetails?.MediShieldAmount ? (
                  <Box sx={classes.cardDetails}>
                    <Box sx={classes.cardLeft}>
                      <Typography sx={classes.cardLabel}>
                        Paid by Medishield Life
                      </Typography>
                    </Box>
                    <Box sx={classes.cardRight}>
                      <Typography sx={classes.cardValue}>
                        {formatPayment(billDetails?.MediShieldAmount)}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}

                {billDetails?.IntegratedShieldPlanAmount ? (
                  <Box sx={classes.cardDetails}>
                    <Box sx={classes.cardLeft}>
                      <Typography sx={classes.cardLabel}>
                        Paid by Integrated Shield Plan
                      </Typography>
                    </Box>
                    <Box sx={classes.cardRight}>
                      <Typography sx={classes.cardValue}>
                        {formatPayment(billDetails?.IntegratedShieldPlanAmount)}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                {billDetails?.MedishieldWithoutLifeAmount ? (
                  <Box sx={classes.cardDetails}>
                    <Box sx={classes.cardLeft}>
                      <Typography sx={classes.cardLabel}>
                        Paid by Medishield
                      </Typography>
                    </Box>
                    <Box sx={classes.cardRight}>
                      <Typography sx={classes.cardValue}>
                        {formatPayment(
                          billDetails?.MedishieldWithoutLifeAmount
                        )}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}

                {billDetails?.MediSaveAmount ? (
                  <Box sx={classes.cardDetails}>
                    <Box sx={classes.cardLeft}>
                      <Typography sx={classes.cardLabel}>
                        Paid by Medisave
                      </Typography>
                    </Box>
                    <Box sx={classes.cardRight}>
                      <Typography sx={classes.cardValue}>
                        {formatPayment(billDetails?.MediSaveAmount)}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                {billDetails?.OtherSchemesAmount ? (
                  <Box sx={classes.cardDetails}>
                    <Box sx={classes.cardLeft}>
                      <Typography sx={classes.cardLabel}>
                        Payable by OTHER SCHEMES
                      </Typography>
                    </Box>
                    <Box sx={classes.cardRight}>
                      <Typography sx={classes.cardValue}>
                        {formatPayment(billDetails?.OtherSchemesAmount)}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}

                <Box sx={classes.cardDetails}>
                  <Box sx={classes.cardLeft}>
                    <Typography sx={classes.cardTitle}>
                      Total Amount Payable
                    </Typography>
                  </Box>
                  <Box sx={classes.cardRight}>
                    <Typography sx={classes.cardAmount}>
                      {formatPayment(billDetails?.AmountPayable || null)}
                    </Typography>
                  </Box>
                </Box>
                {billDetails?.NetPaymentMade ? (
                  <Box sx={classes.cardDetails}>
                    <Box sx={classes.cardLeft}>
                      <Typography sx={classes.cardLabel}>
                        Net Payment made
                      </Typography>
                    </Box>
                    <Box sx={classes.cardRight}>
                      <Typography sx={classes.cardValue}>
                        {formatPayment(billDetails?.NetPaymentMade)}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                {billDetails?.Adjustments ? (
                  <Box sx={classes.cardDetails}>
                    <Box sx={classes.cardLeft}>
                      <Typography sx={classes.cardLabel}>
                        Adjustment(s)
                      </Typography>
                    </Box>
                    <Box sx={classes.cardRight}>
                      <Typography sx={classes.cardValue}>
                        {formatPayment(billDetails?.Adjustments)}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
              </Box> */}

              {!isBeingProceesed ? (
                <Box sx={classes.cardFooter}>
                  <Box sx={classes.cardLeft}>
                    <Typography sx={classes.cardTitle}>
                      Final Amount Payable:
                    </Typography>
                  </Box>
                  <Box sx={classes.cardRight}>
                    <Box sx={classes.cardValueBox}>
                      $
                      <SingleLineTextField
                        type="decimal"
                        decimalPlaces={MAX_DIGIT_OF_FRACTIONAL_PART}
                        maxCharLength={MAX_DIGIT_OF_WHOLE_NUMBER_PART}
                        value={amountToPay}
                        disabled={false}
                        required={false}
                        error={
                          Number(amountToPay) >
                          MAX_AMOUNT_PAYABLE_PER_TRANSACTION
                        }
                        errorText={TRANSACTION_LIMIT_ERROR}
                        handleChange={(event) =>
                          setAmountToPay(event.target.value)
                        }
                      ></SingleLineTextField>
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
          <Box sx={classes.cardButtons}>
            {isBeingProceesed ? (
              <Box sx={classes.noticePanel}>
                <SharpNoticePanel bgColor="extremeWarn">
                  {pendingMsg}
                </SharpNoticePanel>
              </Box>
            ) : null}
            <Button
              variant="contained"
              color="primary"
              sx={classes.fullButton}
              disabled={disablePayment()}
              onClick={() => {
                setBillToPay({
                  InvNo: billDetails?.InvoiceRefNo ?? "",
                  Selected: true,
                  AmountToPay: amountToPay ?? "0",
                });
                if (Number(amountToPay) === billDetails?.FinalAmountPayable) {
                  logEventToGoogleAnalytics(
                    EVENTS.SELECT_PAY_OUTSTANDING_BILL_UNEDITED,
                  );
                } else {
                  logEventToGoogleAnalytics(
                    EVENTS.SELECT_PAY_OUTSTANDING_BILL_EDITED,
                  );
                }
                navigate(PATHS.PAYMENT_PAYOR_INFO.path);
              }}
            >
              Pay
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default BillDetail;
