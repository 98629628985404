import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    contactBox: {
      display: "flex",
      padding: theme.spacing(1, 0),
    },
    title: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
      margin: theme.spacing(0, 0.5),
      marginBottom: theme.spacing(1.5),
    },
    explaination: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
      margin: theme.spacing(0, 0.5),
    },

    buttonPadding: {
      marginRight: "1%",

      paddingLeft: theme.spacing(1.25),
    },

    textBox: {
      width: "50%",

      // "& .MuiFormLabel-root.Mui-error": {
      //   fontSize: size.FONTSIZE_14,
      //   lineHeight: 0.5,
      // },
      // "& .MuiInputBase-root": {
      //   borderRadius: theme.spacing(0.5),
      //   "& .MuiOutlinedInput-notchedOutline": {
      //     // border: `1px solid ${theme.palette.primary.main}`,
      //   },
      // },
      // "& .MuiButton-containedPrimary:hover": {
      //   backgroundColor: theme.palette.primary.main,
      // },
    },
  } satisfies SxObjType;
};

export { sxStyles };
