import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    infoText: {
      color: theme.palette.grey[400],
      fontSize: size.FONTSIZE_12,
      paddingLeft: theme.spacing(1),
    },
  } satisfies SxObjType;
};

export { sxStyles };
