import { size } from "lib/theme/size";
import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    layout: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(0, 2, 0),
      color: theme.palette.text.primary,
      fontSize: size.LIST_TITLEFONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
    },
    children: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(0, 2, 0),
    },
  } satisfies SxObjType;
};

export { sxStyles };
