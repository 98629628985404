import { color } from "lib/theme/color";
import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    card: {
      boxShadow: "0px 0px 6px " + color.RGBA_52,
      backgroundColor: theme.palette.secondary.main,
      display: "flex",
      marginTop: theme.spacing(2),
      borderRadius: 0,
    },
    leftContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    dateContainer: {
      display: "flex",
      flexDirection: "column",
      margin: theme.spacing(0, 1.5),
    },
    dateMonth: {
      color: theme.palette.grey[400],
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: size.FONTSIZE_14,
      textTransform: "uppercase",
      textAlign: "center",
    },
    dateYear: {
      color: theme.palette.grey[400],
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: size.FONTSIZE_14,
      textTransform: "uppercase",
      textAlign: "center",
    },
    dateDay: {
      color: theme.palette.warning.main,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 30,
      textAlign: "center",
      lineHeight: 1,
    },
    dateDayOfWeek: {
      color: theme.palette.grey[400],
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: size.FONTSIZE_14,
      textTransform: "uppercase",
      textAlign: "center",
    },

    cardDetailWrapper: {
      width: "100%",
      padding: theme.spacing(1, 0),
      "&:last-child": {
        paddingBottom: theme.spacing(1.5), // deactivate default padding
      },
    },
    cardDetails: {
      borderLeft: `2px solid ${theme.palette.error.main}`,
      paddingLeft: theme.spacing(1),
      wordBreak: "break-word",
      display: "flex",
      flexDirection: "row",
    },
    cardTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      minHeight: 50,
      overflow: "hidden",
    },
    cardPatientTitle: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_bold,
    },
    cardPatientName: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },

    cardTime: {
      typography: theme.typography.body2_semibold,
      lineHeight: 1.5,
      color: theme.palette.warning.main,
    },

    icon: {
      paddingLeft: theme.spacing(1),
    },
    reorderContainer: {
      position: "absolute",
      top: 0,
      right: theme.spacing(1),
    },

    leftContentContainer: {
      flex: 1,
    },

    requestText: {
      color: theme.palette.warning.main,
      typography: theme.typography.caption1_regular,
    },
    rightContentContainer: {
      position: "relative",
      minWidth: theme.spacing(6),
      padding: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    arrowContainer: {
      position: "absolute",
      bottom: 0,
      right: theme.spacing(4),
    },
  } satisfies SxObjType;
};

export { sxStyles };
