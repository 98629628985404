import axios from "axios";
import { getRequestOptions } from "../../resources/configs/requests";
import { SUBMIT_APPOINTMENT_REQUEST_URL } from "api/resources/configs/URL";
import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import {
  SubmitAppointmentRequestRequestPayload,
  SubmitAppointmentRequestResponsePayload,
} from "./submitAppointmentRequest.types";
import { formatSubmitAppointmentRequestErrorMessage } from "api/resources/messages/ErrorMessage";

/**
 * Submits a request (Form Flow), for Creating / Rescheduling / Cancelling an appointment
 *
 * POST
 * Endpoint: /appointment/SubmitAppointmentRequest
 */
const submitAppointmentRequest = async (
  request: SubmitAppointmentRequestRequestPayload,
): Promise<SubmitAppointmentRequestResponsePayload> => {
  try {
    const response = await axios.post<SubmitAppointmentRequestResponsePayload>(
      SUBMIT_APPOINTMENT_REQUEST_URL(),
      request,
      getRequestOptions(),
    );
    return parseResponse(response, SUBMIT_APPOINTMENT_REQUEST_URL());
  } catch (error) {
    const msg = formatSubmitAppointmentRequestErrorMessage(
      SUBMIT_APPOINTMENT_REQUEST_URL(),
    );
    console.error(msg);
    throw error;
  }
};

export { submitAppointmentRequest };
