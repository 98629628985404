import React from "react";
import { PATHS } from "lib/routing";
import Page from "lib/components/layout/Page/Page";
import { SummaryPageLayoutProps } from "./SummaryPageLayout.types";
import OrderSummary from "../orderSummary/OrderSummary/OrderSummary";

const SummaryPageLayout = ({
  children,
  summary,
  pathToCancelRequest = PATHS.MEDICATIONS_MOBILE.path,
  preventDirectBackNavigationState,
  resetPreventDirectBackNavigationState,
  onSelectParticulars,
}: SummaryPageLayoutProps) => {
  return (
    <Page>
      <OrderSummary
        summary={summary}
        onSelectParticulars={onSelectParticulars}
        preventDirectBackNavigationState={preventDirectBackNavigationState}
        resetPreventDirectBackNavigationState={
          resetPreventDirectBackNavigationState
        }
      />
    </Page>
  );
};

export default SummaryPageLayout;
