import { RootState, RootDispatch } from "lib/redux/root/redux.types";
import { connect } from "react-redux";
import MammogramRequestFormSubmissionPage from "./MammogramRequestFormSubmissionPage";
import { handleErrorModal } from "lib/redux/notifications";
import {
  MAMMOGRAM_SUB_HEADER_KEY,
  MAMMOGRAM_DISCLAIMER_NOTE_KEY,
  MAMMOGRAM_AGREEMENT_TEXT_KEY,
  MAMMOGRAM_SUCCESS_SUBMISSION_KEY,
} from "api/shared/GetDocumentByType/getDocumentByType.mapper";

const mapStateToProps = (state: RootState) => {
  return {
    location: state.appointments.create?.selectedInstitution || "",
    targetSystem: state.appointments.appointmentMeta.targetSystem,
    service: state.appointments.create?.selectedService || "",

    subheader:
      state.appointments.documentByType.documentByTypeMap[
        MAMMOGRAM_SUB_HEADER_KEY
      ],
    disclaimerNote:
      state.appointments.documentByType.documentByTypeMap[
        MAMMOGRAM_DISCLAIMER_NOTE_KEY
      ],
    agreementText:
      state.appointments.documentByType.documentByTypeMap[
        MAMMOGRAM_AGREEMENT_TEXT_KEY
      ],
    successSubmissionText:
      state.appointments.documentByType.documentByTypeMap[
        MAMMOGRAM_SUCCESS_SUBMISSION_KEY
      ],
  };
};

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    handleErrorModal: (open: boolean, message: string | null) => {
      dispatch(handleErrorModal({ open, message }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MammogramRequestFormSubmissionPage);
