import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_semibold,
      paddingBottom: theme.spacing(2),
    },
    homeDeliveryLabel: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    homeDeliveryDetail: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      paddingBottom: theme.spacing(1.5),
    },
    contentLabel: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      paddingTop: theme.spacing(1),
    },
    contentDetail: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
    },
  } satisfies SxObjType;
};

export { sxStyles };
