import { Box, Button, Typography, useTheme } from "@mui/material";
import { sxStyles } from "./SelfHelpToolsPage.styles";
import BannerCard from "lib/components/cards/BannerCard/BannerCard";
import {
  mobileNavigate,
  navigateToExternalInMobileBrowser,
} from "lib/routing/navigate/navigate";
import IMAGES from "lib/assets/images";
import CompactCard from "lib/components/cards/CompactCard/CompactCard";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { ACTIONS, PATHS } from "lib/routing";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { useNavigate } from "react-router-dom";
import { NUHS_POSTALCODE } from "ui/mhm/components/Dashboard/Constants";
import Page from "lib/components/layout/Page/Page";

const BODY_TITLE = "Navigate to Better Health";
const cards = {
  champ: {
    title: "CHAMP",
    body: "Better manage your blood pressure and more with our WhatsApp chatbot",
  },
  mhm: {
    title: "My Health Map",
    body: "Find recommended health screenings and vaccinations based on your age and gender",
  },
  dos_clinic: { title: "Find a screening clinic near you", body: "" },
  dos_dining: { title: "Find healthier eating options near you", body: "" },
};
const CHAMPS_URL = "https://for.sg/enrol-nuhs-champ";
const BUTTON_TEXT = "View other tools ";
const BUTTON_URL =
  "https://www.nuhs.edu.sg/Care-in-the-Community/Enabling-Community-Care/Health-Together/self-help-tools/Pages/default.aspx";
const CLINIC_CATEGORY_CODE = "PR_CDM";

const SelfHelpToolsPage = () => {
  const theme = useTheme();
  const classes = sxStyles(theme);
  const navigate = useNavigate();

  const handleViewOtherToolsOnClick = () => {
    navigateToExternalInMobileBrowser(BUTTON_URL);
  };

  const handleCHAMPSOnClick = () => {
    logEventToGoogleAnalytics(EVENTS.CLICK_SELF_SERVE_CHAMP);
    navigateToExternalInMobileBrowser(CHAMPS_URL);
  };

  const handleMHMOnClick = () => {
    logEventToGoogleAnalytics(EVENTS.CLICK_HT_MHM);
    navigate(PATHS.MHM_DASHBOARD.path);
  };

  const handleDOSClinicOnClick = () => {
    logEventToGoogleAnalytics(EVENTS.CLICK_SCREENING_CLINIC_NEAR_YOU);
    mobileNavigate(
      ACTIONS.DOS_CLINICS_ACTION(NUHS_POSTALCODE, CLINIC_CATEGORY_CODE),
    );
  };

  const handleDOSDiningOnClick = () => {
    logEventToGoogleAnalytics(EVENTS.SELECT_HEALTHIER_DINING_OPTIONS);
    mobileNavigate(ACTIONS.DOS_DINING_ACTION(NUHS_POSTALCODE));
  };

  return (
    <Page>
      <Box sx={classes.mainContainer}>
        <Box sx={classes.topContainer}>
          <Box sx={classes.bannerContainer}>
            <BannerCard
              bannerImage={IMAGES.healthTogetherMHM.OtherToolsImage}
            />
          </Box>
          <Box sx={classes.bodyContainer}>
            <Typography sx={classes.bodyTitle}>{BODY_TITLE}</Typography>
            <CompactCard
              title={cards.champ.title}
              body={cards.champ.body}
              onClickCard={handleCHAMPSOnClick}
              titleIcon={IMAGES.healthTogetherMHM.CHAMPIcon}
            />
            <CompactCard
              title={cards.mhm.title}
              titleIcon={IMAGES.healthTogetherMHM.MHMIcon}
              body={cards.mhm.body}
              onClickCard={handleMHMOnClick}
            />
            <CompactCard
              title={cards.dos_clinic.title}
              titleIcon={IMAGES.healthTogetherMHM.FindADoctorIcon}
              onClickCard={handleDOSClinicOnClick}
            />
            <CompactCard
              title={cards.dos_dining.title}
              titleIcon={IMAGES.healthTogetherMHM.HDO_Logo}
              onClickCard={handleDOSDiningOnClick}
            />
          </Box>
        </Box>
        <Box sx={classes.footerContainer}>
          <Button
            onClick={handleViewOtherToolsOnClick}
            variant="outlined"
            fullWidth
          >
            {BUTTON_TEXT}
          </Button>
        </Box>
      </Box>
    </Page>
  );
};

export default SelfHelpToolsPage;
