import { color } from "lib/theme/color";
import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    buttonBox: {
      display: "grid",
      justifyContent: "flex-end",
    },

    formLabel: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
    },
    formContent: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    formTitle: {
      color: theme.palette.text.primary,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: "19px",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    formBoxShadow: {
      margin: theme.spacing(2.5, 0, 3),
      boxShadow: "0px 0px 6px " + color.RGBA_52,
      padding: theme.spacing(1, 1.75),
    },
    textBox: {
      margin: theme.spacing(2, 0, 2),
      "& .MuiInputBase-root": {
        borderRadius: theme.spacing(0.5),
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.grey[100]}`,
        },
      },
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
      "& .MuiInputLabel-animated": {
        fontSize: size.FONTSIZE_16,
        color: theme.palette.primary.main,
      },
    },

    formAddress: {
      width: "33%",
      margin: theme.spacing(1, 1),
      "& .MuiInputBase-root": {
        borderRadius: theme.spacing(0.5),
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.grey[100]}`,
        },
      },
    },
    infoBox: {
      background: theme.palette.common.white,
      padding: theme.spacing(0.5),
      display: "flex",
      lineHeight: theme.typography.body1.lineHeight,
      borderRadius: theme.spacing(0.5),
      border: `1px solid ${theme.palette.secondary.light}`,
      marginTop: theme.spacing(1),
    },

    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
  } satisfies SxObjType;
};

export { sxStyles };
