import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    grandTotalLabel: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_bold,
    },
    grandTotalValue: {
      color: theme.palette.common.black,
      typography: theme.typography.header4_semibold,
    },
    amountBox: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
      border: `1px solid ${theme.palette.secondary.light}`,
    },
    paymentModeLabel: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_semibold,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1.5),
    },
    payMethods: {
      "& img": {
        marginRight: theme.spacing(1),
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
