import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  IconButton,
  CardHeader,
  Skeleton,
} from "@mui/material";
import { PATHS } from "lib/routing/paths/paths";
import Page from "lib/components/layout/Page/Page";
import { sxStyles } from "./QueueChitAndJourney.styles";
import NonVCQueueDetail from "ui/appointment/components/common/registration-queue/NonVCQueueDetail/NonVCQueueDetail";
import JourneyCardList from "../JourneyCardList/JourneyCardList";
import { useGetQueueStatusAndJourney } from "ui/appointment/hooks/useGetQueueStatusAndJourney";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { useGetBarCodeImage } from "ui/appointment/hooks/useGetBarCodeImage";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import UpdateContact from "../UpdateContact/UpdateContact";
import VerticalSpreadLayout from "lib/components/layout/VerticalSpreadLayout/VerticalSpreadLayout";
import mapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import PullToRefreshWrapper from "lib/components/pullToRefreshWrapper/PullToRefreshWrapper";
import getMsFromMinutes from "lib/util/DateTimeUtil/getMsFromMinutes/getMsFromMinutes";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import {
  disableBackButton,
  mobileNavigate,
} from "lib/routing/navigate/navigate";
import { useAppSelector } from "lib/redux/hooks";
import { useAppDispatch } from "lib/redux/hooks";
import {
  clearCheckInSuccessState,
  setHasPromptedRegTutorial,
  updateCurrentClinicCode,
} from "ui/appointment/ducks/appointmentsSlice";
import {
  selectApptDetail,
  selectApptId,
  selectAppointmentMeta,
  selectCheckInSuccess,
  selectAppointments,
} from "ui/appointment/ducks/selectors";
import { selectUser } from "lib/redux/user/selectors";
import IMAGES from "lib/assets/images";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import IconTextButton from "lib/components/buttons/IconTextButton/IconTextButton";
import QueueCardSkeleton from "ui/appointment/components/appointmentList/RegisteredAppointmentArea/QueueCardSkeleton";
import { ACTIONS } from "lib/routing";
import { useGetMessageAction } from "lib/routing/messageChannel/hooks/useGetMessageAction";

const barcodeModalBody = "Scan this barcode at the kiosk.";
const tutorialTitle = "Do this when you reach the clinic";
const tutorialBody =
  "Tap the 'I have arrived' button upon reaching the clinic.";

const QueueChitAndJourney = () => {
  const classes = sxStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Redux states
  const appointmentId = useAppSelector(selectApptId);
  const { memberIdentifier, contact: profileContact } =
    useAppSelector(selectUser);
  const {
    targetSystem,
    regInstitutionCode,
    institutionCode,
    clinicCode,
    regAppointmentId,
    vcSupportStatus,
  } = useAppSelector(selectAppointmentMeta);
  const {
    checkInSuccessStatus,
    checkInSuccessTitleIcon,
    title: checkInSuccessTitle,
    message: checkInSuccessMessage,
  } = useAppSelector(selectCheckInSuccess);
  const apptDetail = useAppSelector(selectApptDetail);
  const { hasPromptedRegTutorial } = useAppSelector(selectAppointments);

  const institutionName = apptDetail?.institutionName || null;
  const consultType = apptDetail?.consultType || null;
  const appointmentTimeToArrive = apptDetail?.appointmentTimeToArrive || null;
  const encounterId = apptDetail?.encounterId || null;

  // Local states
  const [queueStatusAndJourneyFlag, setQueueChitAndJourneyFlag] =
    useState(false); // controls the queue chit refresh
  const [imageFlag, setImageFlag] = useState(false); // controls the bar code image refresh

  const { messageActionObj, consumeMessageActionObj } = useGetMessageAction();

  const {
    queueStatusAndJourneyPayload,
    queueStatusAndJourneyIsLoading,
    queueStatusAndJourneyHasErrored,
    queueStatusAndJourneyErrorMessage,
  } = useGetQueueStatusAndJourney({
    memberIdentifier,
    encounterId,
    regAppointmentId,
    appointmentTimeToArrive,
    vcSupportStatus,
  });

  const queueStatusAndJourneyPayloadMemo = useMemo(
    () => queueStatusAndJourneyPayload,
    [queueStatusAndJourneyPayload],
  );

  useEffect(() => {
    dispatch(
      updateCurrentClinicCode({
        appointmentId,
        currentClinicCode: queueStatusAndJourneyPayloadMemo?.CurrentClinicCode
          ? queueStatusAndJourneyPayloadMemo.CurrentClinicCode
          : null,
      }),
    );
    if (checkInSuccessStatus === true) {
      handleOpenConfirmation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueStatusAndJourneyPayloadMemo]);

  const [
    imagePayload,
    imagePayloadIsLoading,
    imagePayloadHasErrored,
    imagePayloadErrorMessage,
  ] = useGetBarCodeImage(memberIdentifier, imageFlag);

  // Autorefresh
  useEffect(() => {
    const refreshTimer = setTimeout(() => {
      onRefresh();
    }, getMsFromMinutes(5));

    return () => {
      setQueueChitAndJourneyFlag(!queueStatusAndJourneyFlag);
      clearTimeout(refreshTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueStatusAndJourneyFlag]);

  const onRefresh = () => {
    return new Promise((resolve) => {
      setQueueChitAndJourneyFlag(!queueStatusAndJourneyFlag);
      setImageFlag(!imageFlag);
      setTimeout(resolve, 0);
    });
  };

  const [contact, setContact] = useState(profileContact);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleOpenConfirmation = () => setOpenConfirmation(true);
  const handleCloseConfirmation = () => setOpenConfirmation(false);
  const [isBarcodeOpen, setIsBarcodeOpen] = useState(false);
  const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);
  const toggleIsBarcodeOpen = () => {
    setIsBarcodeOpen(!isBarcodeOpen);
    if (isBarcodeOpen) {
      logEventToGoogleAnalytics(EVENTS.CLICK_SHOW_BARCODE_Q_JOURNEY);
    }
  };

  useEffect(() => {
    logEventToGoogleAnalytics(EVENTS.VIEW_REGISTRATION_QUEUECHIT);
  }, []);

  // This is to prompt user on "I have arrived" tutorial when requireCheckin and user has not been prompted before.
  useEffect(() => {
    if (
      queueStatusAndJourneyPayloadMemo?.RequireCheckIn &&
      !hasPromptedRegTutorial
    ) {
      setIsTutorialModalOpen(true);
      mobileNavigate(ACTIONS.REG_TUTORIAL_PROMPTED); //Upon prompting, this flag is cached on mobile for elapsed threshold; mobileinit will also send this flag back as hasPromptedRegTutorial

      dispatch(setHasPromptedRegTutorial(true));
    }
  }, [dispatch, hasPromptedRegTutorial, queueStatusAndJourneyPayloadMemo]);

  useEffect(() => {
    if (messageActionObj?.action === "backTriggered") {
      consumeMessageActionObj();
      navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
    }
  }, [consumeMessageActionObj, messageActionObj?.action, navigate]);

  return (
    <Page>
      <PullToRefreshWrapper refreshData={onRefresh}>
        <Box sx={{ mt: 5.5 }}>
          <CardHeader
            title="Journey Details"
            action={
              <IconTextButton
                icon={IMAGES.appointment.BarcodeIcon}
                label={["Display NRIC", "Barcode"]}
                ariaLabel={"Display NRIC Barcode"}
                onClick={toggleIsBarcodeOpen}
              />
            }
            sx={classes.header}
          />

          {queueStatusAndJourneyIsLoading || imagePayloadIsLoading ? (
            <QueueCardSkeleton />
          ) : queueStatusAndJourneyHasErrored ? (
            <Page>
              <VerticalSpreadLayout>
                <ErrorDisplay
                  errorMessage={queueStatusAndJourneyErrorMessage}
                />
                <Box sx={{ p: 1, pt: 6 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth={true}
                    onClick={() =>
                      navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true })
                    }
                  >
                    View my appointments
                  </Button>
                </Box>
              </VerticalSpreadLayout>
            </Page>
          ) : queueStatusAndJourneyPayload ? (
            <>
              <Box sx={classes.journeyDetailBox}>
                <Box sx={classes.borderBox}>
                  <NonVCQueueDetail
                    queueNumber={queueStatusAndJourneyPayload.MobileRegQNo}
                    institutionName={institutionName}
                    currentStation={queueStatusAndJourneyPayload.CurrentStation}
                    queueStatus={queueStatusAndJourneyPayload.StatusText}
                    statusMessage={queueStatusAndJourneyPayload.StationMessage}
                    patientWaiting={queueStatusAndJourneyPayload.PatientWaiting}
                    showStatus={queueStatusAndJourneyPayload.ShowStatusText}
                    showPatientWaiting={
                      queueStatusAndJourneyPayload.ShowPatientWaiting
                    }
                    showRightArrow={false}
                    requireCheckIn={queueStatusAndJourneyPayload.RequireCheckIn}
                    iHaveArrivedMessage={
                      queueStatusAndJourneyPayload.IhaveArrivedMessage
                    }
                    regAppointmentId={regAppointmentId}
                    appointmentId={appointmentId}
                    institutionCode={institutionCode}
                    regInstitutionCode={regInstitutionCode}
                    sourceSystem={targetSystem}
                    clinicCode={clinicCode}
                    consultType={consultType}
                    currentClinicCode={
                      queueStatusAndJourneyPayload.CurrentClinicCode ?? ""
                    }
                    lateThresholdMessage={
                      queueStatusAndJourneyPayload.LateThresholdMessage
                    }
                    encounterId={encounterId}
                    appointmentTime={appointmentTimeToArrive}
                  />
                </Box>
              </Box>

              {!queueStatusAndJourneyPayload.RequireCheckIn && (
                <>
                  {queueStatusAndJourneyPayload.QueueDisclaimer && (
                    <Box sx={{ m: 2 }}>
                      <SharpNoticePanel bgColor="warn" showIcon>
                        {mapRawStringToReactElement(
                          queueStatusAndJourneyPayload.QueueDisclaimer,
                        )}
                      </SharpNoticePanel>
                    </Box>
                  )}

                  {/* Update Contact Details for Queue Status Notifications */}
                  {queueStatusAndJourneyPayload.IsSmsNotificationAvailable && (
                    <Box sx={{ m: 2 }}>
                      <UpdateContact
                        memberIdentifier={memberIdentifier}
                        regInstitutionCode={regInstitutionCode}
                        encounterId={encounterId}
                        contact={contact}
                        setContact={setContact}
                      />
                    </Box>
                  )}

                  <IconButton
                    onClick={onRefresh}
                    sx={classes.refreshIconButton}
                  >
                    <Box
                      component={"img"}
                      src={IMAGES.general.RefreshIcon}
                      sx={classes.refreshIcon}
                    />
                  </IconButton>

                  {/* Patient's Journey List */}
                  <JourneyCardList
                    journeyList={queueStatusAndJourneyPayload.Journey}
                  />
                </>
              )}

              <Box sx={{ m: 2 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth={true}
                  onClick={() =>
                    navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true })
                  }
                >
                  View my appointments
                </Button>
              </Box>
            </>
          ) : null}
        </Box>
      </PullToRefreshWrapper>

      {/* After Successful Check In */}
      <ConfirmationModal
        open={openConfirmation}
        hideCancelButton={true}
        title={checkInSuccessTitle || ""}
        body={MapRawStringToReactElement(checkInSuccessMessage || "")}
        nextButtonText="OK"
        onClickNext={() => {
          if (checkInSuccessTitleIcon === "checkIcon") {
            logEventToGoogleAnalytics(EVENTS.SELECT_OK_I_HAVE_ARRIVED);
          }

          if (checkInSuccessTitleIcon === "errorIcon") {
            logEventToGoogleAnalytics(
              EVENTS.SELECT_OK_I_HAVE_ARRIVED_UNSUCCESSFUL,
            );
          }

          dispatch(clearCheckInSuccessState());
          // clearCheckInSuccessState();
          disableBackButton(); // need to add disableBack action because ConfirmationModal renenables Back button by default
          handleCloseConfirmation();
        }}
        onClose={handleCloseConfirmation}
      />

      {/* OnClick of Barcode */}
      <ConfirmationModal
        title={null}
        open={isBarcodeOpen}
        onClickNext={toggleIsBarcodeOpen}
        nextButtonText="OK"
        hideCancelButton
        position="top"
        isNextButtonDisabled={imagePayloadIsLoading}
        body={
          imagePayloadIsLoading ? (
            <>
              <Skeleton
                animation="wave"
                height={100}
                sx={{ margin: "0 auto" }}
              />
              <Skeleton
                animation="wave"
                sx={{ margin: "0 auto", width: "80%" }}
              />
            </>
          ) : imagePayloadHasErrored ? (
            <Typography sx={classes.errorText}>
              {imagePayloadErrorMessage}
            </Typography>
          ) : (
            <>
              <Box sx={classes.barcodeImgBox}>
                <Box
                  component={"img"}
                  src={"data:image/png;base64," + imagePayload}
                  alt="barcode"
                  sx={classes.barcodeImg}
                  loading="lazy"
                />
              </Box>
              <Typography sx={classes.barcodeText}>
                {barcodeModalBody}
              </Typography>
            </>
          )
        }
      />

      {/* Tutorial Modal */}
      <ConfirmationModal
        title={null}
        open={isTutorialModalOpen}
        onClickNext={() => setIsTutorialModalOpen(false)}
        nextButtonText="Dismiss"
        hideCancelButton
        body={
          <Box sx={classes.tutorialContainer}>
            <Box
              sx={classes.tutorialImage}
              component={"img"}
              src={IMAGES.appointment.IHaveArrivedTutorial}
              loading="lazy"
              alt="IHATutorial"
            />
            <Typography sx={classes.tutorialTitle}>{tutorialTitle}</Typography>
            <Typography sx={classes.tutorialBody}>{tutorialBody}</Typography>
          </Box>
        }
      />
    </Page>
  );
};

export default QueueChitAndJourney;
