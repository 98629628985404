import { RootState } from "lib/redux/root/redux.types";
import { connect } from "react-redux";
import ErrorDisplay from "./ErrorDisplay";

const mapStateToProps = (state: RootState) => {
  return {
    currentFlowAction: state.navigation.currentFlowAction,
  };
};

export default connect(mapStateToProps)(ErrorDisplay);
