import React from "react";
import { Box, CardHeader, Typography, Skeleton } from "@mui/material";
import { sxStyles } from "./ProfileHeader.styles";
import { ProfileHeaderProps } from "./ProfileHeader.types";

const ProfileHeader = ({ name, isLoading, title }: ProfileHeaderProps) => {
  const classes = sxStyles();

  return (
    <Box sx={classes.paymentPage}>
      <Box sx={classes.profileHeader}>
        {isLoading ? (
          <CardHeader
            title={<Skeleton variant="text" animation="wave" width="60%" />}
            sx={classes.cardHeader}
          />
        ) : name ? (
          <Typography sx={classes.profileName}>
            {name && name}
            {title}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export default ProfileHeader;
