import { UploadState } from "./ImageUploader.types";

// =======================
// ERROR MESSAGES
// =======================
const ERROR_MESSAGE_UNEXPECTED =
  "An error occurred while processing your image. Please try again.";
const ERROR_MESSAGE_WRONG_FORMAT = "Unrecognised file format. Try another one?";
const ERROR_MESSAGE_CORRUPT_FILE = "Corrupted file format. Try another one?";

// =======================
// STATES
// =======================

const getInitialUploadState = (): UploadState => {
  return {
    hasUploadedSuccessfully: false,
    isLoading: false,
    fileName: null,
    fileData: null,
    errorMessage: null,
  };
};

const getIsLoadingUploadState = (): UploadState => {
  return {
    hasUploadedSuccessfully: false,
    isLoading: true,
    fileName: null,
    fileData: null,
    errorMessage: null,
  };
};

const getSuccessfulUploadState = (
  fileName: string,
  fileData: string,
): UploadState => {
  return {
    hasUploadedSuccessfully: true,
    isLoading: false,
    fileName,
    fileData,
    errorMessage: null,
  };
};

const getUnexpectedErrorState = (): UploadState => {
  return {
    hasUploadedSuccessfully: false,
    isLoading: false,
    fileName: null,
    fileData: null,
    errorMessage: ERROR_MESSAGE_UNEXPECTED,
  };
};

const getFileTooBigErrorState = (size: number): UploadState => {
  return {
    hasUploadedSuccessfully: false,
    isLoading: false,
    fileName: null,
    fileData: null,
    errorMessage: `The image's size exceeds ${size}MB. Please upload one with a smaller size.`,
  };
};

const getInvalidFileFormatErrorState = (): UploadState => {
  return {
    hasUploadedSuccessfully: false,
    isLoading: false,
    fileName: null,
    fileData: null,
    errorMessage: ERROR_MESSAGE_WRONG_FORMAT,
  };
};

const getCorruptedFileFormatErrorState = (): UploadState => {
  return {
    hasUploadedSuccessfully: false,
    isLoading: false,
    fileName: null,
    fileData: null,
    errorMessage: ERROR_MESSAGE_CORRUPT_FILE,
  };
};

export {
  getInitialUploadState,
  getIsLoadingUploadState,
  getSuccessfulUploadState,
  getUnexpectedErrorState,
  getFileTooBigErrorState,
  getInvalidFileFormatErrorState,
  getCorruptedFileFormatErrorState,
};
