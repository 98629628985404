import { SxObjType } from "lib/theme/types/SxObjType.type";
import theme from "lib/theme/theme";

const sxStyles = () => {
  return {
    formSameLine: {
      color: theme.palette.grey[400],
      display: "flex",
      flexDirection: "row",
    },
    questionNo: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      marginRight: theme.spacing(1.5),
      marginTop: theme.spacing(1.75),
      marginBottom: theme.spacing(1.5),
    },
    questionBody: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(1.75),
      marginLeft: theme.spacing(1.5),
    },
    fullWidth: {
      // paddingLeft: theme.spacing(1.75),
      width: "100%",
    },

    radioButtons: {
      display: "flex",
      flexDirection: "row",
    },
  } satisfies SxObjType;
};

export { sxStyles };
