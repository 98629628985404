import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    notFoundText: {
      color: theme.palette.grey[600],
      fontSize: size.FONTSIZE_14,
    },
    notFoundTextBox: {
      marginTop: theme.spacing(20),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    selectionListBox: {
      // position: "absolute",
      top: theme.spacing(20),
      width: "100%",
    },
  } satisfies SxObjType;
};

export { sxStyles };
