import {
  TextField,
  InputAdornment,
  List,
  ListItemText,
  Box,
  ListItemButton,
} from "@mui/material";
import { SimpleAutocompleteProps } from "./SimpleAutocomplete.types";
import { sxStyles } from "./SimpleAutocomplete.styles";
import IMAGES from "lib/assets/images";

const SimpleAutocomplete = ({
  placeholder,
  items,
  showHistoryKeyword,
  onSelect,
  inputProps,
}: SimpleAutocompleteProps) => {
  const classes = sxStyles();

  return (
    <>
      <TextField
        label={placeholder}
        fullWidth
        variant="outlined"
        InputLabelProps={{
          sx: classes.textFieldLabel,
        }}
        InputProps={{
          // preserves InputProps, pass down useAutoComplete hook
          inputProps: { ...inputProps() },
          endAdornment: (
            <InputAdornment position="end">
              <Box component={"img"} src={IMAGES.general.SearchIcon} />
            </InputAdornment>
          ),
        }}
      />
      {showHistoryKeyword && items.length > 0 && (
        <List sx={classes.dropdownRegion}>
          {/* read from end so that the most recent institution chosen is displayed at the top */}
          {items
            .slice(0)
            .reverse()
            .map((option, index) => (
              <ListItemButton key={option} onMouseDown={() => onSelect(option)}>
                <ListItemText primary={option} sx={classes.dropdownText} />
              </ListItemButton>
            ))}
        </List>
      )}
    </>
  );
};

export default SimpleAutocomplete;
