import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    profileHeader: {
      backgroundColor: theme.palette.secondary.main,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      minHeight: theme.spacing(8),
    },

    cardHeader: {
      "& .MuiCardHeader-root": {
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        width: "100%",
        "& .MuiCardHeader-avatar": {
          marginRight: theme.spacing(1.5),
        },
      },
      "& .MuiCardHeader-title": {
        color: theme.palette.text.primary,
        typography: theme.typography.body1_semibold,
        wordBreak: "break-word",
      },
    },
    iconButton: {
      display: "flex",
      padding: theme.spacing(1),
    },
  } satisfies SxObjType;
};

export { sxStyles };
