import { useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SummarySectionFrame from "lib/components/summary/SummarySectionFrame/SummarySectionFrame";
import PatientOrContactDetailSummarySection from "lib/components/summary/PatientOrContactDetailSummarySection/PatientOrContactDetailSummarySection";
import { sxStyles } from "./VirtualConsultViewPastRequestDetailPage.styles";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import { VirtualConsultViewPastRequestDetailProps } from "./VirtualConsultViewPastRequestDetailPage.types";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import { maskNric } from "ui/appointment/components/common/user/UserProfile/maskNric";
import DeliveryDetailSummarySection from "lib/components/summary/DeliveryDetailSummarySection/DeliveryDetailSummarySection";
import PharmacyDetailSummarySection from "ui/medrefill/components/common/orderSummary/PharmacyDetailSummarySection/PharmacyDetailSummarySection";

const VirtualConsultViewPastRequestDetailPage = ({
  isLoadingPastDetail,
  hasErroredViewPastRequest,
  errorMessageViewPastRequest,
  pastRequestHistory,
  onLoad,
}: VirtualConsultViewPastRequestDetailProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();

  useEffect(() => {
    // Load data every time this component mounts
    onLoad();
    logEventToGoogleAnalytics(EVENTS.VIEW_VIRTUAL_CONSULT_PAST_REQUEST_DETAIL);
  }, [onLoad]);

  return (
    <Box sx={{ mx: 2 }}>
      {isLoadingPastDetail ? (
        <Box display="flex" align-items="center" justifyContent="center" pt={3}>
          <CircularProgress />
        </Box>
      ) : hasErroredViewPastRequest ? (
        <ErrorDisplay
          errorMessage={errorMessageViewPastRequest}
          onTryAgain={() => onLoad()}
        />
      ) : (
        <>
          <Typography sx={classes.title}>Order Summary</Typography>
          <SummarySectionFrame>
            <Typography sx={classes.facilityTitle}>
              Prescribing Institution
            </Typography>
            <Typography sx={classes.facilityDetail}>
              {pastRequestHistory.institution.facilityName}
            </Typography>
          </SummarySectionFrame>
          <SummarySectionFrame>
            <PatientOrContactDetailSummarySection
              title="Patient's Detail"
              upperLabel="Name"
              upperDetail={pastRequestHistory.particulars.patientName || ""}
              lowerLabel="NRIC / Birth Certificate / FIN"
              lowerDetail={maskNric(pastRequestHistory.particulars.patientNric)}
            />
          </SummarySectionFrame>
          <SummarySectionFrame>
            <PatientOrContactDetailSummarySection
              title="Contact Details"
              upperLabel="Contact Number"
              upperDetail={pastRequestHistory.particulars.contactNumber}
              lowerLabel="Email"
              lowerDetail={pastRequestHistory.particulars.email}
            />
          </SummarySectionFrame>
          <SummarySectionFrame>
            {pastRequestHistory.collection.typeId === 3 ? (
              <DeliveryDetailSummarySection
                postalCode={pastRequestHistory.collection.postalCode}
                blockNo={pastRequestHistory.collection.blockNo}
                unitNo={pastRequestHistory.collection.unitNo}
                address={pastRequestHistory.collection.address}
                showSpecialRequest={true}
                specialRequest={pastRequestHistory.collection.specialRequest}
              />
            ) : (
              <PharmacyDetailSummarySection
                facilityName={pastRequestHistory.institution.facilityName}
                locationName={pastRequestHistory.collection.locationName}
                specialRequest={pastRequestHistory.collection.specialRequest}
                date={pastRequestHistory.collection.date}
                timeslot={pastRequestHistory.collection.timeslot}
              />
            )}
          </SummarySectionFrame>
          <Box pb={2} pt={3}>
            <ButtonsFooter
              hideNextButton={true}
              nextButtonText=""
              cancelButtonText="Back"
              onClickCancel={() => navigate(-1)}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default VirtualConsultViewPastRequestDetailPage;
