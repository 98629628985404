import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import {
  MobileIntegrationMedrefillState,
  MedRefillRequestType,
  MedRefillState,
  MedRefillParticularsState,
  MedRefillPrescriptionState,
  MedRefillQuantityDetail,
  MedRefillCollectionState,
  MedRefillQuantityDetailOption3Temp,
} from "./medrefill.types";
import { Setting } from "api/shared/GetSystemSettings/getSystemSettings.fromApi.types";
import { DocumentByCluster } from "api/medrefill/GetDocumentByCluster/getDocumentByCluster.fromApi.types";
import { Medication } from "api/medrefill/GetMedications/getMedications.fromApi.types";
import { Clinic } from "api/medrefill/GetClinics/getClinics.fromApi.types";
import { Detail } from "api/medrefill/GetMedicationSummaryDetail/getMedicationSummaryDetail.fromApi.types";
import { MedImage } from "api/medrefill/GetMedicationImages/getMedicationImages.fromApi.types";
import {
  Payment,
  Collection,
} from "api/medrefill/GetCollectionPaymentDetails/getCollectionPaymentDetails.fromApi.types";
import { Summary } from "api/medrefill/GetMostRecentMedicationSummary/getMostRecentMedicationSummary.fromApi.types";
import { UploadState } from "lib/components/upload/ImageUploader/ImageUploader.types";

/**
 * The slice reducer for the part of state that represents the user's medication refill information.
 */
const medrefillSlice = createSlice({
  name: "medrefill",
  initialState,
  reducers: {
    initializeMedrefillFromMobileIntegration(
      state,
      action: PayloadAction<MobileIntegrationMedrefillState>,
    ) {},
    clearState(state) {
      state.reorder = false;
      state.institution = initialState.institution;
      state.particulars = initialState.particulars;
      state.prescription = initialState.prescription;
      state.quantity = initialState.quantity;
      state.collection = initialState.collection;
      state.payment = initialState.payment;
      state.fileUpload = initialState.fileUpload;
    },
    updateState(state, action: PayloadAction<MedRefillState>) {
      state.requestType = action.payload.requestType;
      state.institution = action.payload.institution;
      state.particulars = action.payload.particulars;
      state.prescription = action.payload.prescription;
      state.quantity = action.payload.quantity;
      state.collection = action.payload.collection;
      state.payment = action.payload.payment;
      state.fileUpload = action.payload.fileUpload;
    },
    updateReorderFlag(state, action: PayloadAction<boolean>) {
      state.reorder = action.payload;
    },
    updateRequestType(state, action: PayloadAction<MedRefillRequestType>) {
      state.requestType = action.payload;
    },
    updateFacility(
      state,
      action: PayloadAction<{
        facilityId: number;
        facilityName: string;
        cluster: string;
      }>,
    ) {
      state.institution.facilityId = action.payload.facilityId;
      state.institution.facilityName = action.payload.facilityName;
      state.institution.cluster = action.payload.cluster;
    },
    updateParticulars(state, action: PayloadAction<MedRefillParticularsState>) {
      state.particulars.requesterId = action.payload.requesterId;
      state.particulars.requesterNric = action.payload.requesterNric;
      state.particulars.requesterName = action.payload.requesterName;
      state.particulars.patientName = action.payload.patientName;
      state.particulars.patientNric = action.payload.patientNric;
      state.particulars.contactNumber = action.payload.contactNumber;
      state.particulars.isPatient = action.payload.isPatient;
      state.particulars.email = action.payload.email;
      state.particulars.postalCode = action.payload.postalCode;
      state.particulars.unitNo = action.payload.unitNo;
      state.particulars.blockNo = action.payload.blockNo;
      state.particulars.address = action.payload.address;
    },
    updatePrescription(
      state,
      action: PayloadAction<MedRefillPrescriptionState & { clinicId: number }>,
    ) {
      state.prescription.refillOriginalPrescriptionDate =
        action.payload.refillOriginalPrescriptionDate;
      state.prescription.files = action.payload.files;
      state.prescription.topupReason = action.payload.topupReason;
      state.prescription.topupReasonText = action.payload.topupReasonText;
      state.prescription.topupOtherReason = action.payload.topupOtherReason;
      state.institution.clinicId = action.payload.clinicId;
    },
    updateQuantity(
      state,
      action: PayloadAction<{
        typeId: number | null;
        details: MedRefillQuantityDetail[];
        temp: MedRefillQuantityDetailOption3Temp;
      }>,
    ) {
      state.quantity.typeId = action.payload.typeId;
      state.quantity.details = action.payload.details;
      state.quantity.temp = { ...action.payload.temp };
    },
    updateCollection(state, action: PayloadAction<MedRefillCollectionState>) {
      state.collection = action.payload;
    },
    updatePayment(
      state,
      action: PayloadAction<{
        subsidy: string | null;
        subsidyDescription: string[] | null;
        paymentInstructions: string | null;
      }>,
    ) {
      state.payment.subsidy = action.payload.subsidy;
      state.payment.subsidyDescription = action.payload.subsidyDescription;
      state.payment.paymentInstructions = action.payload.paymentInstructions;
    },
    setFileUploadPrescriptionError(state, action: PayloadAction<boolean[][]>) {
      state.fileUpload.fileUploadPrescriptionErrorArray = action.payload;
    },
    setFileUploadDetails(state, action: PayloadAction<UploadState[][]>) {
      state.fileUpload.fileUploadDetails = action.payload;
    },
    setSystemSettingsIsLoading(state, action: PayloadAction<boolean>) {
      state.systemSettings.isLoading = action.payload;
    },
    setSystemSettingsHasErrored(state, action: PayloadAction<boolean | null>) {
      state.systemSettings.hasErrored = action.payload;
    },
    setSystemSettingsErrorMessage(state, action: PayloadAction<string | null>) {
      state.systemSettings.errorMessage = action.payload;
    },
    setSystemSettingsLookup(state, action: PayloadAction<Setting[]>) {
      state.systemSettings.lookup.clear();
      for (let system of action.payload) {
        let mapValue = state.systemSettings.lookup.get(system.SubCategory);
        let itemToAdd = {
          value: Number(system.Value),
          displayValue: system.DisplayValue,
        };
        if (mapValue !== undefined) {
          state.systemSettings.lookup.set(system.SubCategory, [
            ...mapValue,
            itemToAdd,
          ]);
        } else {
          state.systemSettings.lookup.set(system.SubCategory, [itemToAdd]);
        }
      }
    },
    setShowMyChartMedication(state, action: PayloadAction<boolean>) {
      state.showMyChartMedication = action.payload;
    },
    setDocumentByClusterIsLoading(state, action: PayloadAction<boolean>) {
      state.documentByCluster.isLoading = action.payload;
    },
    setDocumentByClusterHasErrored(
      state,
      action: PayloadAction<boolean | null>,
    ) {
      state.documentByCluster.hasErrored = action.payload;
    },
    setDocumentByClusterErrorMessage(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.documentByCluster.errorMessage = action.payload;
    },
    setDocumentByClusterList(
      state,
      action: PayloadAction<DocumentByCluster[]>,
    ) {
      state.documentByCluster.documentByClusterList = action.payload;
    },
    setMedicationsIsLoading(state, action: PayloadAction<boolean>) {
      state.medications.isLoading = action.payload;
    },
    setMedicationsHasErrored(state, action: PayloadAction<boolean | null>) {
      state.medications.hasErrored = action.payload;
    },
    setMedicationsErrorMessage(state, action: PayloadAction<string | null>) {
      state.medications.errorMessage = action.payload;
    },
    setMedicationList(state, action: PayloadAction<Medication[]>) {
      state.medications.medicationList = action.payload;
    },
    setClinicsIsLoading(state, action: PayloadAction<boolean>) {
      state.clinics.isLoading = action.payload;
    },
    setClinicsHasErrored(state, action: PayloadAction<boolean | null>) {
      state.clinics.hasErrored = action.payload;
    },
    setClinicsErrorMessage(state, action: PayloadAction<string | null>) {
      state.clinics.errorMessage = action.payload;
    },
    setClinicList(state, action: PayloadAction<Clinic[]>) {
      state.clinics.clinicList = action.payload;
    },
    setCollectionPaymentDetailsIsLoading(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.collectionAndPayments.isLoading = action.payload;
    },
    setCollectionPaymentDetailsHasErrored(
      state,
      action: PayloadAction<boolean | null>,
    ) {
      state.collectionAndPayments.hasErrored = action.payload;
    },
    setCollectionPaymentDetailsErrorMessage(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.collectionAndPayments.errorMessage = action.payload;
    },
    setPaymentList(state, action: PayloadAction<Payment[]>) {
      state.collectionAndPayments.paymentOptionList = action.payload;
    },
    setCollectionList(state, action: PayloadAction<Collection[]>) {
      state.collectionAndPayments.collectionOptionList = action.payload;
    },
    setIsMultiplePayment(state, action: PayloadAction<boolean>) {
      state.collectionAndPayments.isMultiplePayment = action.payload;
    },
    setIsPayInstructionEnabled(state, action: PayloadAction<boolean>) {
      state.collectionAndPayments.isPayInstructionEnabled = action.payload;
    },
    setViewPastDetailsIsLoading(state, action: PayloadAction<boolean>) {
      state.viewPastRequest.isLoading = action.payload;
    },
    setViewPastDetailsHasErrored(state, action: PayloadAction<boolean | null>) {
      state.viewPastRequest.hasErrored = action.payload;
    },
    setViewPastDetailErrorMessage(state, action: PayloadAction<string | null>) {
      state.viewPastRequest.errorMessage = action.payload;
    },
    setViewPastDetail(state, action: PayloadAction<Detail | null>) {
      state.viewPastRequest.detail = action.payload;
    },
    setPastRequestMedicationImagesIsLoading(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.pastRequestMedicationImages.isLoading = action.payload;
    },
    setPastRequestMedicationImagesHasErrored(
      state,
      action: PayloadAction<boolean | null>,
    ) {
      state.pastRequestMedicationImages.hasErrored = action.payload;
    },
    setPastRequestMedicationImagesErrorMessage(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.pastRequestMedicationImages.errorMessage = action.payload;
    },
    setPastRequestMedicationImages(
      state,
      action: PayloadAction<MedImage[] | null>,
    ) {
      state.pastRequestMedicationImages.images = action.payload;
    },
    setPastRequestsIsLoading(state, action: PayloadAction<boolean>) {
      state.pastRequest.isLoading = action.payload;
    },
    setPastRequestsHasErrored(state, action: PayloadAction<boolean>) {
      state.pastRequest.hasErrored = action.payload;
    },
    setPastRequestsErrorMessge(state, action: PayloadAction<string | null>) {
      state.pastRequest.errorMessage = action.payload;
    },
    updatePastRequests(state, action: PayloadAction<Summary[] | null>) {
      state.pastRequest.summaryList = action.payload;
    },
    setPastRequestsHasMoreRecords(state, action: PayloadAction<boolean>) {
      state.pastRequest.hasMoreRecords = action.payload;
    },
    setPastRequestsOffset(state, action: PayloadAction<number>) {
      state.pastRequest.offset = action.payload;
    },
    updatePastRequestRequestorId(state, action: PayloadAction<number>) {
      state.pastRequest.selectedRequesterId = action.payload;
    },
  },
});

export const {
  updateRequestType,
  updateReorderFlag,
  initializeMedrefillFromMobileIntegration,
  clearState,
  updateState,
  updateFacility,
  updateParticulars,
  updatePrescription,
  updateQuantity,
  updateCollection,
  updatePayment,
  setFileUploadPrescriptionError,
  setFileUploadDetails,
  setSystemSettingsIsLoading,
  setSystemSettingsHasErrored,
  setSystemSettingsErrorMessage,
  setSystemSettingsLookup,
  setMedicationsIsLoading,
  setMedicationsHasErrored,
  setMedicationsErrorMessage,
  setMedicationList,
  setCollectionPaymentDetailsIsLoading,
  setCollectionPaymentDetailsHasErrored,
  setCollectionPaymentDetailsErrorMessage,
  setPaymentList,
  setCollectionList,
  setIsMultiplePayment,
  setIsPayInstructionEnabled,
  setViewPastDetailsIsLoading,
  setViewPastDetailsHasErrored,
  setViewPastDetailErrorMessage,
  setViewPastDetail,
  setPastRequestMedicationImagesIsLoading,
  setPastRequestMedicationImagesHasErrored,
  setPastRequestMedicationImagesErrorMessage,
  setPastRequestMedicationImages,
  setPastRequestsIsLoading,
  setPastRequestsHasErrored,
  setPastRequestsErrorMessge,
  setPastRequestsHasMoreRecords,
  setPastRequestsOffset,
  updatePastRequests,
  updatePastRequestRequestorId,
  setClinicList,
  setClinicsHasErrored,
  setClinicsErrorMessage,
  setClinicsIsLoading,
  setDocumentByClusterList,
  setDocumentByClusterHasErrored,
  setDocumentByClusterErrorMessage,
  setDocumentByClusterIsLoading,
  setShowMyChartMedication,
} = medrefillSlice.actions;

export default medrefillSlice.reducer;
