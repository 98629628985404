import { useState } from "react";
import { MobileDatePicker as MuiDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DatepickerProps } from "./Datepicker.types";
import { sxStyles } from "./Datepicker.styles";
import IMAGES from "lib/assets/images";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import {
  formatDate,
  formatOptions,
} from "lib/util/DateTimeUtil/formatDate/formatDate";
import { DateField } from "@mui/x-date-pickers/DateField";
import ActionBar from "./ActionBar/ActionBar";
import {
  customActions,
  getHelperText,
  getISOString,
  getNextDateISO,
  getShouldShowToday,
  getViews,
} from "./HelperFunctions";

const CLEAR_BUTTON_LABEL = "Clear";
export const KEYBOARD_DATE_ERROR = "Please provide a valid date";
const comparisonDateFormat = "yyyy-MM-dd";

// pass this to minDate and maxDate field when they are passed in as falsy values
// because @mui/x-date-pickers will disable go to prev/next month arrow when pass in null to this two fields
const DATE_RANGE_FLAG_UNDEFINED = undefined;

const Datepicker = ({
  isClearEnabled = false,
  showClearButton = false,
  showCancelButton = true,
  showAcceptButton = true,
  isCheckingInitialValue = false,
  required = true,
  isKeyboardDatePicker = false,
  label,
  value,
  format,
  placeholder,
  autoOk = true,
  disabled = false,
  disablePast = false,
  disableFuture = false,
  minDate,
  maxDate,
  customHelperText,
  isChangingInitialValue = false,
  onSelect,
  shouldDisableDates,
  onInputError,
  onClearDate,
  shouldRespectLeadingZeros = false,
}: DatepickerProps) => {
  const theme = useTheme();
  const keyboardPlaceholder = placeholder
    ? placeholder
    : formatOptions.datePickerPlaceholder;
  const datePickerLabel = (label?.length || 0) > 30 ? null : label;
  const dateFormat = formatOptions.datePickerDefault;
  const views = getViews(dateFormat);
  const isDateExpired =
    formatDate(formatDateToISO(new Date()), comparisonDateFormat) >
    formatDate(formatDateToISO(new Date(value as Date)), comparisonDateFormat);

  const [errorText, setErrorText] = useState(
    isCheckingInitialValue
      ? value
        ? isDateExpired
          ? KEYBOARD_DATE_ERROR
          : ""
        : ""
      : "",
  );

  const classes = sxStyles({
    theme,
    errorText,
    isDisabled: disabled,
    isNoActions: customActions.length === 0,
  });

  const CustomCalIcon = (
    <Box
      component="img"
      src={IMAGES.general.DatePickerIcon}
      sx={classes.icon}
    />
  );

  // control the booolean flag for Today button in dialog date picker
  const shouldShowToday = getShouldShowToday({ minDate, maxDate });

  // restrain the selected date to the range of valid date in dialog format
  // not to be used in keyboard format, when shown date needs to be the same as user input
  const restrainDateForDialog = (selectedDate: Date): Date => {
    const selected = formatDateToISO(selectedDate);

    const today = new Date();
    const current = formatDateToISO(today) ?? "";

    // minDate and maxDate are not guaranteed to be ISO strings, using default date constructor
    if (minDate && selected < getISOString(minDate)) {
      return new Date(minDate);
    } else if (maxDate && selected > getNextDateISO(maxDate)) {
      return new Date(maxDate);
    } else if (
      (disableFuture && selected > current) ||
      (disablePast && selected < current)
    ) {
      return today;
    }
    return selectedDate;
  };

  // controls if need to show error text in keyboard format
  // not to be used in dialog format, when all the selection is valid
  const shouldShowErrorKeyboard = (selectedDate: Date): boolean => {
    if (isNaN(selectedDate.getTime())) return true;

    const selectedISO = formatDateToISO(selectedDate);
    const selected = formatDate(selectedISO, "yyyy-MM-dd");

    const today = new Date();
    const current = formatDate(formatDateToISO(today), "yyyy-MM-dd") ?? "";
    if (
      (disableFuture && selected > current) ||
      (disablePast && selected < current) ||
      (minDate && selectedISO < getISOString(minDate)) ||
      (maxDate && selectedISO > getNextDateISO(maxDate))
    ) {
      return true;
    } else {
      return false;
    }
  };
  const shouldShowError = (selectedDate: Date) => {
    const today = new Date();
    if (
      isChangingInitialValue &&
      formatDate(formatDateToISO(today), "yyyy-MM-dd") <=
        formatDate(formatDateToISO(selectedDate), "yyyy-MM-dd")
    ) {
      setErrorText("");
    }
  };

  return (
    <Box sx={classes.mainContainer}>
      {!datePickerLabel && (
        <Typography sx={classes.upperLabel}>{label}</Typography>
      )}

      {isKeyboardDatePicker ? (
        <DateField
          sx={classes.root}
          label={label}
          InputLabelProps={{
            sx: classes.textFieldLabel,
          }}
          variant="outlined"
          value={value ? new Date(value) : null}
          disabled={disabled}
          disablePast={disablePast}
          disableFuture={disableFuture}
          format={format ?? dateFormat}
          inputProps={{
            placeholder: keyboardPlaceholder,
          }}
          slotProps={{
            textField: {
              InputProps: {
                // sx: classes.textFieldLabel,
                notched: false,
              },
            },
          }}
          onChange={(date) => {
            const selectedDate = date as Date;

            if (selectedDate && shouldShowErrorKeyboard(selectedDate)) {
              setErrorText(KEYBOARD_DATE_ERROR);
              if (onInputError) onInputError();
            } else {
              setErrorText("");
              onSelect(selectedDate);
            }
          }}
        />
      ) : (
        <Box sx={classes.datepickerContainer}>
          <MuiDatePicker
            onAccept={(date) => {
              if (date === null && onClearDate) {
                onClearDate();
              } else {
                const selectedDate = date as Date;
                shouldShowError(selectedDate);
                const processedDate = restrainDateForDialog(selectedDate);
                onSelect(processedDate);
              }
            }}
            closeOnSelect={autoOk}
            label={datePickerLabel}
            sx={classes.root}
            disabled={disabled}
            disablePast={disablePast}
            disableFuture={disableFuture}
            format={"d MMM yyyy"}
            slots={{
              actionBar: ActionBar,
            }}
            slotProps={{
              layout: {
                sx: classes.layout,
              },
              calendarHeader: { sx: classes.calendarHeader },
              day: {
                sx: classes.day,
              },
              textField: {
                InputProps: {
                  endAdornment: CustomCalIcon,
                  placeholder: formatOptions.datePickerPlaceholder,
                  // sx: classes.textFieldLabel,
                  notched: false,
                },
              },
              actionBar: {
                actions: customActions({
                  showToday: shouldShowToday,
                  showAccept: showAcceptButton,
                  showCancel: showCancelButton,
                  showClear: false, //To use external button rather than built in clear button on picker
                }),
              },
              mobilePaper: { sx: classes.mobilePaper },
              field: { shouldRespectLeadingZeros: true },
            }}
            minDate={minDate ? new Date(minDate) : DATE_RANGE_FLAG_UNDEFINED}
            maxDate={maxDate ? new Date(maxDate) : DATE_RANGE_FLAG_UNDEFINED}
            value={
              value && typeof value === "string"
                ? new Date(value as string)
                : value instanceof Date
                  ? value
                  : null
            }
            // onChange={(date) => {
            //   const selectedDate = date as Date;

            //   const processedDate = restrainDateForDialog(selectedDate);
            //   onSelect(processedDate);
            // }}
            shouldDisableDate={shouldDisableDates}
            views={views}
          />
          {showClearButton && (
            <Button
              variant="text"
              fullWidth={false}
              onClick={onClearDate}
              disabled={!isClearEnabled}
            >
              {CLEAR_BUTTON_LABEL}
            </Button>
          )}
        </Box>
      )}
      {getHelperText(errorText, required, disabled, customHelperText) && (
        <Box
          sx={errorText || required ? classes.errText : classes.helperText}
          id={"helper-text"}
        >
          {getHelperText(errorText, required, disabled, customHelperText)}
        </Box>
      )}
    </Box>
  );
};

export default Datepicker;
