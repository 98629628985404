/**
 * Base64 decodes the input string (assumed to be in Base64 encoding).
 *
 * @param {string | null} rawJson  Raw JSON string
 *
 * @returns {Object}  Parsed object
 */
const parseJson = <T>(rawJson: string | null): T => {
  if (rawJson) {
    try {
      return JSON.parse(rawJson);
    } catch (error) {
      throw new Error(
        `An error occurred while parsing the stringified Raw JSON. Raw JSON: ${rawJson}.`,
      );
    }
  } else {
    throw new Error("The Raw JSON is NULL or UNDEFINED.");
  }
};

export { parseJson };
