import { RootState } from "lib/redux/root/redux.types";

export const selectPayments = (state: RootState) => state.payments;
export const selectPaymentsTransactionHistory = (state: RootState) =>
  state.payments.transactionHistory;
export const selectPaymentsPaidBills = (state: RootState) =>
  state.payments.paidBills;
export const selectPaymentsArInstitutions = (state: RootState) =>
  state.payments.arInstitutions;
export const selectPaymentsAllOutstandingBills = (state: RootState) =>
  state.payments.outstandingBills;
export const selectPaymentsAllAddedBills = (state: RootState) =>
  state.payments.addedBills.allAddedBills;
export const selectPaymentsBillsToPay = (state: RootState) =>
  state.payments.billsToPay;
