import { RootState } from "lib/redux/root/redux.types";
import { connect } from "react-redux";
import ConfirmationDetails from "./ConfirmationDetails";
import { resolveBeforeRescConfirmMsgDocomentType } from "api/shared/GetDocumentByType/getDocumentByType.mapper";

const mapStateToProps = (state: RootState) => {
  const institutionCode = state.appointments.appointmentMeta.institutionCode;
  var preConsultMsgKey = "";
  if (institutionCode) {
    preConsultMsgKey = resolveBeforeRescConfirmMsgDocomentType(institutionCode);
  }
  return {
    institutionCode,
    preConsultMsg:
      state.appointments.documentByType.documentByTypeMap[preConsultMsgKey],
  };
};

export default connect(mapStateToProps)(ConfirmationDetails);
