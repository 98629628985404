import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    title: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_semibold,
    },
    disclaimer: {
      color: theme.palette.grey[400],
      typography: theme.typography.caption1_regular,
      marginBottom: theme.spacing(1),
    },
    text: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    TermsAndConditionsLink: {
      color: theme.palette.primary.main,
      fontSize: size.FONTSIZE_14,
      textTransform: "none",
      padding: 0,
      marginTop: theme.spacing(-0.3125),
    },
    termsBox: {
      mx: 2,
      mb: 1.25,
      "& .MuiFormControlLabel-root": {
        alignItems: "start",
        marginRight: 0,
      },
      "& span": {
        marginTop: 0,
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
