import { useState } from "react";
import { FooterIconsProps } from "./FooterIcons.types";
import { sxStyles } from "./FooterIcons.styles";
import IMAGES from "lib/assets/images";
import { Box } from "@mui/material";
import UserConsentModal from "lib/components/modals/ConfirmationModal/UserConsentModal/UserConsentModal";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { useNavigate } from "react-router-dom";
import OtherProvidersSection from "./OtherProvidersSection";
import { ACTIONS, PATHS } from "lib/routing";
import CompactCard from "lib/components/cards/CompactCard/CompactCard";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import ExternalLinkCard from "lib/components/cards/ExternalLinkCard/ExternalLinkCard";

const CARDS = [
  {
    title: "Medication Refill",
    body: "Choose this if your prescription has not expired <strong>AND</strong> you still have some medication balance",
  },
  {
    title: "Repeat Prescription",
    body: "Choose this if your prescription has expired <strong>OR</strong> you have insufficient medication to last you till your next appointment",
  },
  {
    title: "Prescription List",
    body: "View your medication list, prescribed dosage and instructions",
  },
];

const MED_RECORD_LINK_TITLE = "Medication Record ";

/* This is a functional component that is used to display the icons at the bottom of the page. */
const FooterIcons = ({
  prescribedMedicationConsentFlag,
  shouldDisplayMedicationRecord,

  prescriptionRedirect,
}: FooterIconsProps) => {
  const classes = sxStyles();
  const navigate = useNavigate();

  const [openConsentModal, setOpenConsentModal] = useState(false);

  const eventHandlers = {
    medrefill: () => {
      logEventToGoogleAnalytics(EVENTS.SELECT_REFILL_NEW_REQUEST);
      navigate(PATHS.MED_REFILL_SELECT_INSTITUTIONS.path);
    },

    repeatPrescription: () => {
      logEventToGoogleAnalytics(EVENTS.SELECT_TOPUP_NEW_REQUEST);
      navigate(PATHS.MED_TOPUP_SELECT_INSTITUTIONS.path);
    },

    myChart: () => {
      logEventToGoogleAnalytics(EVENTS.SELECT_MY_CHART);
      mobileNavigate(ACTIONS.MY_CHART.MEDICATION);
    },

    userConsent: () => {
      setOpenConsentModal(false);
    },
  };

  return (
    <Box sx={classes.footerIconsContainer}>
      {/* Conditionally display My Chart medication */}
      {shouldDisplayMedicationRecord && (
        <CompactCard
          title={CARDS[2].title}
          body={CARDS[2].body}
          titleIcon={IMAGES.medrefill.PrescriptionListIcon}
          onClickCard={eventHandlers.myChart}
        />
      )}

      {/* MedRefill icon */}
      <CompactCard
        title={CARDS[0].title}
        body={MapRawStringToReactElement(CARDS[0].body)}
        titleIcon={IMAGES.medrefill.MedRefillIcon}
        onClickCard={eventHandlers.medrefill}
      />

      {/* Repeat Prescription icon */}
      <CompactCard
        title={CARDS[1].title}
        body={MapRawStringToReactElement(CARDS[1].body)}
        titleIcon={IMAGES.medrefill.RepeatPrescriptionIcon}
        onClickCard={eventHandlers.repeatPrescription}
      />

      <OtherProvidersSection>
        <ExternalLinkCard
          externalLinkObject={[
            {
              title: MED_RECORD_LINK_TITLE,
              endIcon: "externalLink",
              onClickCard: () => {
                // if consent is given, redirect to prescribed medication page
                if (prescribedMedicationConsentFlag) {
                  prescriptionRedirect();
                }
                // open modal to indicate consent is not given
                else {
                  setOpenConsentModal(true);
                }
              },
            },
          ]}
        />
      </OtherProvidersSection>

      <UserConsentModal
        open={openConsentModal}
        onClickNext={eventHandlers.userConsent}
      />
    </Box>
  );
};

export default FooterIcons;
