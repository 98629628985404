import { PATHS } from "lib/routing";
import ParticularsContainer from "../../../common/particulars/ParticularsContainer";

const TopupParticularsPage = () => {
  return (
    <ParticularsContainer pathToNextStep={PATHS.MED_TOPUP_PRESCRIPTION.path} />
  );
};

export default TopupParticularsPage;
